<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="gs-profile-page body__content"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="back-to-search-section">
      <div class="container">
        <router-link
          v-if="showBackToSearch"
          :to="{ name: 'Members' }"
          class="back-to-search-link"
        >
          <span><svg-white-arrow-left class="mr-1"></svg-white-arrow-left> Back to search</span>
        </router-link>
        <a
          v-else-if="showBackToEvent"
          aria-label="go back to event page"
          href="javascript:void(0)"
          class="back-to-search-link linkified"
          @click="goBackToEventPage"
        >
          <span><svg-white-arrow-left class="mr-1"></svg-white-arrow-left> Back to event</span>
        </a>
      </div>
    </div>
    <div v-if="nonGlobalScot">
      <div class="white-background-section">
        <div class="container profile-card-container">
          <el-card class="profile-card">
            <div class="profile-card-body">
              <el-row>
                <el-col
                  :xs="5"
                  :sm="5"
                  :md="5"
                  :lg="4"
                >
                  <el-avatar
                    v-if="nonGlobalScot.avatar"
                    shape="circle"
                    class="avatar"
                    :src="nonGlobalScot.avatar"
                    alt="Profile picture"
                  ></el-avatar>
                  <el-avatar
                    v-else
                    shape="circle"
                    class="avatar"
                    src="/assets/img/default-image.png"
                    alt="Profile picture"
                  ></el-avatar>
                </el-col>
                <el-col
                  :xs="19"
                  :sm="19"
                  :md="19"
                  :lg="13"
                >
                  <div class="user-info">
                    <div class="d-flex justify-content-between">
                      <h1>{{ nonGlobalScot.full_name }}</h1>
                      <div class="followers-section d-flex hidden-md-and-down">
                        <!--                         <p><span>649</span> Followers</p> <a href="#" class="view-followers-link" v-if="isProfileOwner">View</a> -->
                      </div>
                    </div>
                    <div class="d-flex">
                      <p>
                        {{ primaryBusiness.role }}
                        <svg-vertical-line
                          class="vertical-line"
                          aria-labeledby="vertical line"
                        ></svg-vertical-line>
                        {{ primaryBusiness.name }}
                      </p>
                    </div>
                    <div class="location d-flex">
                      <p class="d-flex">
                        <svg-map-pin-gradient class="mr-2 map-pin"></svg-map-pin-gradient>{{ location }}
                      </p>
                      <!--  <span class="ml-3 mr-3">|</span>-->
                      <div class="vertical-line d-none d-md-block">
                        <svg-vertical-line aria-labeledby="vertical line"></svg-vertical-line>
                      </div>
                      <p class="d-flex">
                        <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for, {{ memberFor }}
                      </p>
                    </div>
                  </div>
                </el-col>
                <!-- <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="7"
                >
                  <div class="followers-section d-flex justify-content-end">
                    <p
                      v-if="isProfileOwner"
                      class="clickable"
                      @click="$router.push({name:'Following'})"
                    >
                      <span>{{ nonGlobalScot.following_count }}</span> Following
                    </p>
                    <div
                      v-if="isProfileOwner"
                      class="separator"
                    >
                      |
                    </div>
                    <p
                      :class="{'clickable' : isProfileOwner}"
                      @click="isProfileOwner ? $router.push({name:'Followers'}) : null"
                    >
                      <span>{{ nonGlobalScot.followers_count }}</span> {{ nonGlobalScot.followers_count > 1 ? 'Followers' : 'Follower' }}
                    </p>
                  </div>
                </el-col> -->
                <el-col :sm="24">
                  <div class="profile-card-actions">
                    <el-button
                      v-if="!isProfileOwner"
                      type="primary"
                      @click="sendMessage()"
                    >
                      Send message
                    </el-button>
    
                    <!--                   <el-button type="primary" v-if="isProfileOwner">
                      Invite colleague
                    </el-button> -->
                    <!-- <el-button
                      v-if="!isProfileOwner"
                      v-loading="startAction"
                      type="button"
                      @click="followUnfollowProfile()"
                    >
                      {{ nonGlobalScot.has_followed ? 'Unfollow' : 'Follow' }}
                    </el-button> -->
                    <el-button
                      v-if="isProfileOwner"
                      type="primary"
                      @click="editProfile"
                    >
                      Edit profile
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
        <div class="container main-content">
          <el-row>
            <el-col :lg="12">
              <div class="about-section">
                <h1>Company information and ambitions</h1>
                <p>{{ nonGlobalScot.traderProfile.ambitions }}</p>
              </div>
            </el-col>
            <el-col :lg="12">
              <div class="companies-connections">
                <h1>Current Companies</h1>
                <list-show-more :items="businesses">
                  <template slot-scope="{ item }">
                    <span>{{ item.name }} | {{ item.role }}</span>
                  </template>
                </list-show-more>
              </div>
            </el-col>
          </el-row>
          <hr class="content-separator" />
          <div class="sectors-section">
            <h1>Sectors</h1>
            <el-row>
              <el-col
                v-for="sector in sectors"
                :key="sector.id"
                :sm="24"
                :md="12"
              >
                <div class="sector-pill sector">
                  {{ sector.name }}
                </div>
                <div v-if="sector.subGroups.data.length > 0">
                  <div
                    v-for="subSector in sector.subGroups.data"
                    :key="subSector.id"
                    class="sector-pill sub-sector"
                  >
                    {{ subSector.name }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <hr class="content-separator" />
          <el-row>
            <el-col
              v-if="regions.length > 0"
              :md="24"
              :lg="12"
            >
              <h1>Areas of international growth</h1>
              <list-show-more :items="regions">
                <template slot-scope="{ item }">
                  <span>{{ item.slug }}</span>
                </template>
              </list-show-more>
            </el-col>
            <el-col
              :md="24"
              :lg="12"
            >
              <h1>Support required</h1>
              <list-show-more :items="aspects">
                <template slot-scope="{ item }">
                  <span>{{ item.slug }}</span>
                </template>
              </list-show-more>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="container">
        <div class="activity-section">
          <el-row>
            <el-col :sm="24">
              <!--h1 v-if="isProfileOwner">My activity history</!--h1>
              <h1-- v-else>{{ nonGlobalScot.first_name }}'s activity history</h1-->
              <div v-if="isProfileOwner">
                <member-activity :is-profile-owner="isProfileOwner" />
              </div>
              <div v-else>
                <h1>{{ nonGlobalScot.first_name }}'s activity history</h1>
                <recent-activity :is-profile-owner="isProfileOwner"></recent-activity>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import { mapGetters, mapState } from 'vuex'
import gsApi from '@/services/gs.api'
import ListShowMore from '@/components/partials/list-show-more'
import RecentActivity from '@/components/partials/recent-activity'
import MemberActivity from '@/components/partials/member-activity'
import SvgMemberProfileIcon from '@/components/assets/svg-member-profile-icon'
import RolePermissionMixin from '@/mixins/RolePermissionMixin'
import WhiteArrowLeft from '@/components/assets/svg-white-arrow-left'
import MapPinGradient from '@/components/assets/svg-map-pin'
import DocumentGradient from '@/components/assets/svg-document-gradient'
import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
import VerticalLine from '@/components/assets/svg-vertical-line'
import enumHelper from "@/helpers/enumHelper";

export default {
  name: 'NgsProfile',
  metaInfo: {
    title: "Business member profile"
  },
  components: {
    'svg-member-profile-icon': SvgMemberProfileIcon,
    'svg-white-arrow-left': WhiteArrowLeft,
    'svg-map-pin-gradient': MapPinGradient,
    'svg-document-gradient': DocumentGradient,
    'svg-purple-arrow-right': PurpleArrowRight,
    'svg-vertical-line': VerticalLine,
    ListShowMore,
    RecentActivity,
    MemberActivity
  },
  mixins: [RolePermissionMixin],
  data() {
    return {
      isNonGlobalScotViewing: false,
      isGlobalScotViewing: false,
      isProfileOwner: false,
      nonGlobalScot: null,
      mainLoader: true,
      showBackToSearch: false,
      startAction:false
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['navigatedFrom']),
    primaryBusiness() {
      let primary = this.nonGlobalScot.businesses.data.find(b => (b.is_primary))
      return primary ? primary : this.nonGlobalScot.businesses.data[0]
    },
    businesses() {
      let businesses = this.nonGlobalScot.businesses.data.filter(business => business.type !== 3)
      return businesses
    },
    location() {
      let area = this.primaryBusiness.address.county ? this.primaryBusiness.address.county : this.primaryBusiness.address.town
      return area + ', ' + this.primaryBusiness.address.country.name
    },
    sectors() {
      let sectors = this.nonGlobalScot.groups.data
      return sectors
    },
    regions() {
      let regions = this.nonGlobalScot.traderProfile.regions.data
      return regions
    },
    aspects() {
      let aspects = this.nonGlobalScot.traderProfile.aspects.data
      return aspects
    },
    memberFor() {
      let memberFor = moment(this.nonGlobalScot.created_at).fromNow(true)
      return memberFor
    },
    showBackToEvent() {
      return this.navigatedFrom.name && this.navigatedFrom.name === 'event-view';
    },
  },
  watch: {
    $route(to, from) {
      if (from.path !== to.path && this.user.isLogged) {
        this.mainLoader = true
        this.checkRole()
        this.fetchNgsProfile()
      }
    }
  },
  created() {
    if (this.$route.params.showBackToSearch) {
      this.showBackToSearch = true
    }
    if(store.state.user.isLogged){
      this.fetchNgsProfile()
    }
  },
  mounted() {
    this.checkRole();
  },
  beforeDestroy () {
    this.$store.dispatch("updateNavigatedFrom", {
      name: null
    });
  },
  methods: {
    sendMessage() {
      this.mainLoader = true
      gsApi.chat.create({
        type: "single",
        members: [this.$route.params.id]
      })
        .then((response) => {
          if (response && response.data) {
            this.mainLoader = false
            this.$store.commit('UPDATE_SELECTED_CHAT', response.data)
            this.$router.push({name: 'My Chats'})
          }
        }).catch(error => {
          this.mainLoader = false
          if (error && error.response && error.response.data && error.response.data.existingChat) {
            this.$store.commit('UPDATE_SELECTED_CHAT', error.response.data.existingChat)
            this.$router.push({name: 'My Chats'})
          }
        })
    },
    editProfile() {
      this.$router.push({name: 'Edit Profile'})
    },
    fetchNgsProfile() {
      gsApi.users.profile(this.$route.params.id, {})
        .then(response => {
          this.mainLoader = false

          if (!response.data.traderProfile || !response.data.roles.includes('trader')) { // Not a non-globalscot profile
            this.$alert('Unable to load users profile. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            this.$router.push({name: 'Members'})
          }
          else if (response.data.suspended){
            this.$alert(
              "User is not currently active.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
            this.$router.push({ name: "Members" });
          }
          else {
            this.nonGlobalScot = response.data
            if (this.$route.params.showBackToSearch) {
              this.$store.commit('SET_SCROLL_TO_MEMBER', `member_${response.data.id}`)
            }
          }
        }).catch(error => {
          this.mainLoader = false
          this.$alert('Unable to load users profile. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.$router.push({name: 'Members'})
        })
    },
    checkRole() {
      this.isGlobalScotViewing = false
      this.isProfileOwner = false
      this.isNonGlobalScotViewing = false

      if (this.isRole('expert') && this.user.id !== this.$route.params.id) {
        this.isGlobalScotViewing = true
      }

      if (this.user.id == this.$route.params.id) {
        this.isProfileOwner = true
        return
      }

      if (this.isRole('trader') && this.user.id !== this.$route.params.id) {
        this.isNonGlobalScotViewing = true
      }
    },
    followUnfollowProfile() {
      this.startAction = true;
      let action = this.nonGlobalScot.has_followed ? enumHelper.FollowAction.UNFOLLOW : enumHelper.FollowAction.FOLLOW;
      const data = {
        id : this.$route.params.id,
        module : "User",
        entity : "User"
      }
      gsApi.followable.followUnfollow(data, action).then((response)=>{
        this.nonGlobalScot.has_followed = response.data.has_followed;
        this.nonGlobalScot.followers_count = response.data.followers_count;
        if(action == enumHelper.FollowAction.FOLLOW){
          this.$alert(`Your are now successfully following ${this.nonGlobalScot.full_name}.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }else{
          this.$alert(`Your have successfully unfollowed ${this.nonGlobalScot.full_name}.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }

      }).catch((response)=>{
        if (response.status == 422) {
          let firstError =
            response.data.errors[Object.keys(response.data.errors)[0]]
          this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        } else {
          this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }
      }).finally(()=>{
        this.startAction = false;
      })
    },
    goBackToEventPage() {
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="scss">
.vertical-line {
  top: 4px;
  margin-top: 0px !important;
  margin: 0 10px !important;
  position: relative;
  height: 18px;
}
</style>
