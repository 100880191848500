<template>
  <section id="modal-dialog">
    <Trap>
      <el-dialog
        v-if="modals.pulse.post"
        ref="postDialog"
        :visible="modals.pulse.isOpen"
        custom-class="pulse-dialog-from-notification pulse-dialog pulse"
        fullscreen
        @opened="dialogOpen('postDialog')"
        @close="closePulseModal"
      >
        <pulse-post
          :id="`header-post-${modals.pulse.post.id}`"
          :post="modals.pulse.post"
          :pulsepost-id="modals.pulse.pulsepostId"
          :show-comments="true"
        ></pulse-post>
      </el-dialog>

      <el-dialog
        v-if="modals.request.request"
        ref="requestDialog"
        :visible="modals.request.isOpen"
        fullscreen
        custom-class="request-dialog"
        @opened="dialogOpen('requestDialog')"
        @close="closeRequestModal"
      >
        <help-request
          ref="helpRequestRef"
          :help-request="modals.request.request"
          @on-decline="openRequestDeclinedDialog($event)"
          @clicked="closeRequestModal"
        >
        </help-request>
      </el-dialog>

      <el-dialog
        v-if="modals.project.post"
        ref="projectPostDialog"
        :visible="modals.project.isOpen"
        custom-class="pulse-dialog-from-notification pulse-dialog pulse"
        fullscreen
        @opened="dialogOpen('projectPostDialog')"
        @close="closeProjectPostModal"
      >
        <project-post
          :id="`post-${modals.project.post.id}`"
          :post="modals.project.post"
          :open-reply-for-post="modals.project.post.id"
          :project-post-id="modals.project.projectPostId"
          :show-comments="true"
        ></project-post>
      </el-dialog>
    </Trap>
    <focus-lock>
      <el-dialog
        v-if="modals.requestDeclined.isOpen"
        ref="requestDeclineDialog"
        :show-close="false"
        custom-class="request-decline-modal"
        :visible="modals.requestDeclined.isOpen"
        :title="`Request declined. We’ll let ${modals.requestDeclined.member_name} know you can’t offer support for this request.They’ll still be able to request support from you in the future.`"
        @opened="dialogOpen('requestDeclineDialog')"
      >
        <div class="text-center success-dialog">
          <svg-success-border></svg-success-border>
          <h2 class="mt-3">
            Request declined
          </h2>
          <p class="mt-4 mb-1">
            We’ll let {{ modals.requestDeclined.member_name }} know you can’t offer support for this request.
          </p>
          <p class="mt-1 mb-4">
            They’ll still be able to request support from you in the future.
          </p>
          <el-button
            class="mx-auto mt-5"
            @click="closeRequestDeclinedDialog"
          >
            Close
          </el-button>
        </div>
      </el-dialog>
    </focus-lock>
    <focus-lock>
      <el-dialog
        v-if="modals.endHelpRequest.helpRequest && modals.endHelpRequest.helpRequest.expert"
        ref="endRequestDialog"
        v-loading="endRequestLoader"
        :show-close="false"
        custom-class="end-request-modal"
        :visible.sync="modals.endHelpRequest.isOpen"
        @opened="dialogOpen('endRequestDialog')"
      >
        <div class="text-center success-dialog">
          <h2 class="mt-3">
            End this request?
          </h2>
          <p class="mt-4 mb-1">
            Once the request has ended, you’ll be given chance to review your experience with {{
              modals.endHelpRequest.helpRequest.expert.full_name }}. If you’d like their support again in the future, just submit
            another help request.
          </p>
          <el-row>
            <el-col
              :sm="24"
              :md="{span: 16, offset: 4}"
            >
              <el-form
                id="endRequestForm"
                ref="endRequestForm"
                :model="endRequestForm"
                class="mt-4"
                @submit.native.prevent
              >
                <el-form-item
                  prop="edit_sub_group_ids"
                  class="flex-grow-1 flex-shrink-0 mr-3 w-100 select-form-item"
                >
                  <template slot="label">
                    <span aria-hidden="true">Reason for ending request</span>
                    <span class="sr-only">Please select a reason for ending this request</span>
                  </template>
                  <v-select
                    v-model="endRequestForm.reason"
                    :value="endRequestForm.reason"
                    :options="endRequestReasons"
                    :searchable="false"
                    :clearable="false"
                    autocomplete="false"
                    :reduce="reasonOption => reasonOption"
                    :select-on-tab="true"
                    :close-on-select="true"
                  />
                </el-form-item>
                <el-form-item
                  v-if="endRequestForm.reason === 'Other'"
                  label="Please add details (Optional)"
                >
                  <el-input v-model="endRequestForm.otherReason"></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <div class="d-flex actions justify-content-center mb-4 mt-3">
            <el-button
              @click="closeEndHelpRequestModal"
            >
              Cancel
            </el-button>
            <el-button
              type="primary"
              @click="endRequest"
            >
              End request
            </el-button>
          </div>
        </div>
      </el-dialog>
    </focus-lock>

      <el-dialog
        :visible.sync="modals.nominateGSModal.isOpen"
        fullscreen
        custom-class="nominate-gs-dialog"
        :show-close="false"
        :close-on-press-escape="false">
        <nominate-global-scot @close-nominate-gs-modal="onCloseNominateGsModal" />
      </el-dialog>

  </section>
</template>

<script>
import store from "@/store";
import gsApi from '@/services/gs.api'
import { mapState } from "vuex";
import Trap from 'vue-focus-lock';
import PulsePost from '@/components/platform/pulse/pulse-post';
import ProjectPost from "@/components/platform/projects/project-post";
import HelpRequest from '@/components/partials/help-request.vue';
import SuccessBorder from '@/components/assets/svg-success-border';
import NominateGlobalScot from '@/components/partials/nominate-globalscot.vue';
import NotificationsNav from '@/components/slide-panel/panels/notifications-panel'

export default {
  components: {
    'Trap': Trap,
    'pulse-post': PulsePost,
    'project-post': ProjectPost,
    'help-request': HelpRequest,
    'notifications-nav': NotificationsNav,
    'svg-success-border': SuccessBorder,
    NominateGlobalScot

  },

  data () {
    return {
      memberName: null,
      requestDeclinedDialogVisible: false,
      endRequestLoader:false,
      endRequestForm: {
        reason: null,
        otherReason: ''
      },
      endRequestReasons: []
      

    }
  },

  computed: {
    ...mapState(['modals']),
  },
  mounted(){
    this.endRequestReasons = this.modals.endHelpRequest?.helpRequest?.ending_reasons
  },

  methods: {
    openRequestDeclinedDialog(memberName) {
      store.commit('OPEN_REQUEST_DECLINED_MODAL', {
        member_name: memberName,
        isOpen: true
      });
      document.getElementById('content').setAttribute("aria-hidden", "true");
    },
    closePulseModal() {
      store.dispatch('closePulseModal');
      this.handleClosed();
    },
    closeProjectPostModal() {
      store.dispatch('closeProjectPostModal');
      this.handleClosed();
    },
    closeRequestModal() {
      store.dispatch('closeRequestModal');
      this.$refs.helpRequestRef.resetAcceptForm()
      this.handleClosed();
    },
    closeRequestDeclinedDialog() {
      store.dispatch('closeRequestDeclinedModal');
      this.handleClosed();
    },
    handleClosed() {
      document.getElementById('content').removeAttribute("aria-hidden");
    },
    closeEndHelpRequestModal() {
      store.dispatch('closeEndHelpRequestModal');
      this.handleClosed();
    },
    endRequest() {
      this.endRequestLoader = true
      const chatId = this.modals.endHelpRequest.helpRequest.chat_id;
      gsApi.member.requestHelpEnd({
        help_request_id: this.modals.endHelpRequest.helpRequest.id,
        ending_reason: this.endRequestForm.reason === 'Other' ? 'Other - ' + this.endRequestForm.otherReason : this.endRequestForm.reason
      }).then((response) => {
 
        this.endRequestLoader = false
        this.closeEndHelpRequestModal()
        if(chatId){
          this.$emit('new-message', {
            chat_id: chatId,
            message: {
              author: {
                first_name: response.data.last_message.author,
              },
              body: response.data.last_message.body,
            }
          });
        }

      }).catch((error) => {

        this.$alert(
          error?.response?.data?.error?.message ? error.response.data.error.message : 'Something went wrong, please try again later.',
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          }
        );
        this.closeEndHelpRequestModal()
        this.endRequestLoader = false
      }) 
    },
    onCloseNominateGsModal(){
      store.dispatch('closeNominateGSModel');
    }
  }
}
</script>
