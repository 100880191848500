<template>
  <div class="formatted">
    <ckeditor v-model="model" :config="editorConfig" :editor="editor"  />
    <span class="characters-length" :class="{'max-character-reached' : this.maxLength - model?.length < 0}" >You have {{this.maxLength - model?.length}} characters remaining</span>
  </div>
  
</template>

<script>
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default {
  name: "ExperienceEditor",
  model: {
    prop: "experience",
    event: "input",
  },
  props: {
    experience: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxLength:{
      type:Number,
      default: 5000
    }
  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
        allowedContent: true,
        placeholder: this.placeholder,
        basicEntities: false,
        entities_greek: false,
        entities_latin: false,
        entities_additional: "",
        toolbar: {
          items: [
            "bold",
            "italic",
            "link",
            "underline",
            "bulletedList",
            "numberedList",
          ],
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
          },
        },
        link: {
          addTargetToExternalLinks: true,
        },
        language: "en",
        removePlugins: ["MediaEmbedToolbar"]
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.experience;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">

.formatted p,.formatted ul,.formatted ol,.formatted blockquote,.formatted pre {
    font-size: 1em;
    line-height: 1.8em;
    padding-top: .2em;
    margin-bottom: .2em
}

.formatted h1 {
    font-size: 2.36em;
    line-height: 1.33em;
    padding-top: 1em;
    margin-bottom: 1.67em
}

.formatted h1+dl {
    margin-top: 1em
}

@media only screen and (max-width: 640px) {
    .formatted h1 {
        font-size:1.9em
    }
}

.formatted dd {
    margin-bottom: 1em
}

.formatted h1:first-of-type {
    width: 100%;
    padding-top: .5em;
    margin-bottom: 1.17em
}

.formatted h1:first-of-type+h2 {
    padding-top: 0
}

.formatted h2 {
    font-size: 1.68em;
    line-height: 1.68em;
    padding-top: .8em;
    margin-bottom: .4em;
    padding-bottom: .2em;
    border-bottom: 1px solid #e9e9e9;
    font-weight: 400
}

@media only screen and (max-width: 640px) {
    .formatted h2 {
        font-size:1.5em
    }
}

.formatted h2:first-of-type {
    clear: both
}

.formatted h3 {
    font-size: 1.36em;
    line-height: 1.5em;
    padding-top: .8em;
    margin-bottom: .2em;
    font-weight: 400
}

.formatted h4 {
    font-size: 1.2em;
    line-height: 1.4em;
    padding-top: .8em;
    margin-bottom: .2em;
    margin-bottom: .2em;
    padding-top: .8em;
    font-weight: 400
}

.formatted h5 {
    font-size: 1em;
    line-height: 1.6em;
    padding-top: .2em;
    margin-bottom: .8em;
    font-weight: 400
}

.formatted .info-box>h2,.formatted .info-box>h3,.formatted .info-box>h4 {
    padding-top: 0
}

.formatted strong,.formatted b {
    font-weight: 600
}

.formatted i,.formatted em {
    font-style: italic
}

.formatted pre {
    overflow: hidden
}

.formatted code {
    font-family: Monaco,Menlo,Consolas,"Roboto Mono","Courier New","Ubuntu Mono",monospace;
    font-size: .866666em;
    padding: 1.333em
}

.formatted :not(pre)>code:not(.highlight) {
    background: rgba(202,205,207,.3);
    padding: .1em .25em;
    border-radius: 3px
}

.formatted :not(pre)>code:not(.highlight)::after {
    letter-spacing: -1em;
    content: " "
}

.formatted :not(pre)>code:not(.highlight)::before {
    letter-spacing: -1em;
    content: " "
}

.formatted a code:not(.highlight) {
    color: #1b3af2
}

.formatted .highlight {
    background: #2b2c26;
    color: #f8f8f2
}

.formatted .highlight a {
    color: #fff
}

.formatted .highlight code {
    background: none;
    padding: 0;
    font-size: 1em
}

.formatted blockquote {
    border-left: 1px solid #bdbdbd;
    padding-left: 10px;
    padding-top: 0;
    font-style: italic
}

.formatted ul,.formatted ol {
    margin-left: 2.666em;
    margin-bottom: .8em
}

@media only screen and (max-width: 640px) {
    .formatted ul,.formatted ol {
        margin-left:1.333em
    }
}

.formatted ul ul,.formatted ul ol,.formatted ol ul,.formatted ol ol {
    padding-top: 0;
    margin-bottom: 0
}

.formatted ul ul:last-of-type,.formatted ul ol:last-of-type,.formatted ol ul:last-of-type,.formatted ol ol:last-of-type {
    margin-bottom: .3333333333em
}

.formatted ul li:last-of-type,.formatted ol li:last-of-type {
    margin-bottom: 0
}

.formatted p img {
    display: block;
    margin: 1.5em auto;
    box-sizing: content-box
}

.formatted iframe:not(.cke_wysiwyg_frame) {
    display: block;
    margin: 1.5em auto
}

.formatted ol {
    list-style-type: decimal
}

.formatted [dir=rtl] ul,.formatted [dir=rtl] ol {
    margin-left: 0;
    margin-right: 2.666em
}

@media only screen and (max-width: 640px) {
    .formatted [dir=rtl] ul,.formatted [dir=rtl] ol {
        margin-right:1.333em
    }
}

.formatted{
  .max-character-reached{
    color: red !important;
  }
  .ck.ck-editor__editable_inline{
    min-height:200px
  }
}

</style>
