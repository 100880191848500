<!-- EXPERIENCE AND EXPERTISE -->
<template>
  <div class="section-experience-expertise" v-loading="mainLoader || metaLoader" element-loading-background="rgba(255, 255, 255, 1)">
    <h2>Experience and expertise</h2>
    <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
    <el-form id="form" :model="form" class="experiense-expertise-form" ref="submitForm" :rules="rules" @submit.native.prevent v-if="!metaLoader" @validate="handleSubmitFormFieldInput">
      <el-form-item prop="group_ids" class="w-60">
        <template slot="label">
          <span aria-hidden="true">Which sectors do you have experience in?</span>
          <span class="sr-only">Please select one or more sectors</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('group_ids')"
                v-text="this.getInlineValidationErrorFor('group_ids')"></span>
        </template>
        <el-checkbox-group
          v-model="form.group_ids"
          aria-label="Please check one or more sectors">
          <div class="row">
            <div class="col-md-24 col-lg-12 mb-4" v-for="group in groups">
                <el-checkbox
                name="group_ids"
                :label="group.id"
                :key="group.id"
                @change="experienceSectorsChanged(group.id)">
                {{ group.name }}</el-checkbox>
            </div>
          </div>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item prop="sub_group_ids" v-if="subGroups.length > 0" class="w-60">
        <template slot="label">
          <span aria-hidden="true">Which sub-sectors do you have experience in? - Optional</span>
          <span class="sr-only">Please select one or more sub sectors</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('sub_group_ids')"
                v-text="this.getInlineValidationErrorFor('sub_group_ids')"></span>
        </template>
        <v-select
          v-model="form.sub_group_ids"
          :options="subGroups"
          :multiple="true"
          :clearable="false"
          label="name"
          :reduce="subGroup => subGroup.id"
          inputId="sub_group_ids"
          autocomplete="false"
          :select-on-tab="true"
          :close-on-select="false" />
      </el-form-item>
      <el-form-item prop="connected_countries" class="w-60">
        <template slot="label">
          <span aria-hidden="true">Which countries do you have connections in or knowledge of?</span>
          <span class="sr-only">Please select one or more countries you have a connection with</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('connected_countries')"
                v-text="this.getInlineValidationErrorFor('connected_countries')"></span>
        </template>
        <v-select
            v-model="form.connected_countries"
            :options="countries"
            :multiple="true"
            :clearable="false"
            label="name"
            :reduce="country => country.id"
            inputId="connected_countries"
            autocomplete="false"
            :select-on-tab="true"
            :close-on-select="false" />
      </el-form-item>
      <el-form-item prop="expertise" class="w-60">
        <template slot="label">
          <span aria-hidden="true">Your business expertise and skills</span>
          <span class="sr-only">Please select one or more of your business expertise and skills</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('expertise')"
                v-text="this.getInlineValidationErrorFor('expertise')"></span>
        </template>
        <v-select
          v-model="form.expertise"
          :options="expertise"
          :multiple="true"
          :clearable="false"
          label="name"
          :reduce="expertise => expertise.id"
          inputId="expertises"
          autocomplete="false"
          :select-on-tab="true"
          :close-on-select="false" />
      </el-form-item>
      <el-form-item prop="activities" class="activities is-no-asterisk">
        <template slot="label">
          <span aria-hidden="true">What types of activities are you interested in?<span class="is-custom-asterisk">*</span></span><br>
          <span class="sr-only">Please select one or more activities</span>
          <span class="el-form-item__info d-block">This will help us match you to GlobalScots in your target areas. You can change these at any time.</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('activities')"
                v-text="this.getInlineValidationErrorFor('activities')"></span>
        </template>
        <el-checkbox-group
          v-model="form.activities"
          aria-label="Please check one or more types of activities you are interested in">
          <div class="row">
            <div class="col-md-24 col-lg-12 mb-4" v-for="activity in activities">
              <el-checkbox
                name="form3_activity_ids"
                :label="activity.id"
                :key="activity.id">
                {{ activity.label }}
              </el-checkbox>
            </div>
          </div>
        </el-checkbox-group>
      </el-form-item>
      <div class="step-hr mb-4"></div>
      <div class="d-flex form-actions">
        <el-button
          class="btn-cancel"
          @click="$router.push({name: 'GlobalScot Profile', params: { id: user.id }})">
          Cancel
        </el-button>
        <el-button
          type="primary"
          class="btn-submit"
          @click="submitForm($event)">
          Save changes
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  import gsApi from '@/services/gs.api'
  import validationErrors from '@/components/partials/validation-errors'
  import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";

  export default {
    mixins: [inlineValidationErrorsMixin],
    name: "experience-expertise",

    metaInfo: {
      title: "Experience and expertise - Edit profile",
    },

    components: {
      validationErrors
    },

    computed: {
      ...mapState(['user']),
    },

    data() {
      return {
        validationErrors: [],
        mainLoader: false,
        metaLoader: true,
        countries: [],
        groups: [],
        subGroups: [],
        expertise: [],
        activities: [],
        form: {
          group_ids: [],
          sub_group_ids: [],
          connected_countries: [],
          expertise: [],
          activities: []
        },
        rules: {
          group_ids: [
            { type: 'array', required: true, message: 'Select at least one sector', trigger: 'change' },
          ],
          connected_countries: [
            { type: 'array', required: true, message: 'Select at least one connection', trigger: 'change' },
          ],
          expertise: [
            { type: 'array', required: true, message: 'Select at least one expertise', trigger: 'change' },
          ],
          activities: [
            { type: 'array', required: true, message: 'Select at least one activity you are interested in', trigger: 'change' },
          ]
        }
      }
    },
    created() {
      if(this.$store.state.user.isLogged){
        this.getRegisterMeta();
        this.fetchCurrentCountries();
        this.fetchCurrentGroups();
        this.fetchCurrentExpertises();
        this.fetchCurrentActivities();
      }
    },
    methods: {
      experienceSectorsChanged(groupId) {
        if (this.form.group_ids.includes(groupId)) {
          this.mainLoader = true

          gsApi.group.subGroups(groupId)
          .then(response => {
            this.mainLoader = false

            response.data.data.forEach((subgroup) => {
              this.subGroups.push(subgroup)
            })
          })
        } else {
          // Removing selected sub groups where sub group is about to be removed below
          let subGroupsToBeRemoved = this.subGroups.filter(item => item.group_id == groupId)
          subGroupsToBeRemoved.forEach((subgroup) => {
            if (this.form.sub_group_ids.includes(subgroup.id)) {
              let index = this.form.sub_group_ids.indexOf(subgroup.id)
              this.form.sub_group_ids.splice(index, 1)
            }
          })

          // Removing sub groups with group_id of selected group
          let newSubgroups = this.subGroups.filter(item => item.group_id !== groupId)
          this.subGroups = newSubgroups
        }
      },
      submitForm() {
        this.$refs['submitForm'].validate((valid) => {
          if (valid) {
            const data = {};

            data.group_ids = this.form.group_ids;
            data.sub_group_ids = this.form.sub_group_ids;
            data.connected_countries = this.form.connected_countries;
            data.expertise = this.form.expertise;
            data.activities = this.form.activities;

            this.mainLoader = true

            gsApi.globalscot.user.updateExperienceExpertise(data)
              .then(response => {
                this.$store.commit('UPDATE_GS_EXPERIENCE_EXPERTISE', response.data);
                this.mainLoader = false;
                this.validationErrors = [];
                this.$alert('You successfully updated your experience and expertise.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              })
              .catch(error => {
                this.validationErrors = [];
                this.mainLoader = false

                if (error.response && error.response.status == 422 && error.response.data) {
                  let errors = error.response.data.errors;
                  for (let [key, value] of Object.entries(errors)) {
                    let obj = {}
                    obj.id = key;
                    obj.message = value[0];
                    this.validationErrors.push(obj);
                  }
                  window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                  this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
                }
              })
            } else {
                this.validationErrors = [];
                this.$refs.submitForm.fields.forEach(field => {
                  if(field.validateState == 'error') {
                    let obj = {}
                    obj.id = field.labelFor;
                    obj.message = field.validateMessage;
                    this.validationErrors.push(obj);
                  }
                });
                this.$nextTick(() => {
                  document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
                  let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
                  this.$announcer.set(text)
                })
            }
        })
      },
      getRegisterMeta() {
        gsApi.globalscot.meta.index()
          .then(response => {
            setTimeout(()=>{
              this.metaLoader = false
            }, 500)
            this.countries = response.data.countries.data
            this.groups = response.data.groups.data
            this.expertise = response.data.expertise.data
            this.activities = response.data.activities.data
          }).catch(() => {
            this.mainLoader = false
            this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
      },
      fetchCurrentCountries() {
        let user = this.$store.state.user;

        if(user.expertProfile.countries.data) {
          user.expertProfile.countries.data.forEach(item => {
            this.form.connected_countries.push(item.id)
          })
        }
      },
      fetchCurrentGroups() {
        let user = this.$store.state.user;

        if (user.groups) {
          user.groups.forEach(group => {
            this.form.group_ids.push(group.id)
            group.subGroups.data.forEach(subGroup => {
              this.form.sub_group_ids.push(subGroup.id)
            })
            this.experienceSectorsChanged(group.id)
          })
        }
      },
      fetchCurrentExpertises() {
        let user = this.$store.state.user;

        if(user.expertProfile.expertise.data) {
          user.expertProfile.expertise.data.forEach(item => {
            this.form.expertise.push(item.id)
          })
        }
      },
      fetchCurrentActivities() {
        let user = this.$store.state.user;

        if(user.expertProfile.activities.data) {
          user.expertProfile.activities.data.forEach(item => {
            this.form.activities.push(item.id)
          })
        }
      },
      handleSubmitFormFieldInput() {
        this.handleSingleFormFieldInput('submitForm');
      },
    }
  }
</script>
