<template>
  <focus-lock>
    <el-dialog
      :show-close="true"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div v-loading="isLoading" class="body">
        <h2 class="mb-2 text-left w-100">Enter Code</h2>
        <p class="mt-4 mb-2 text-left w-100">
          {{ getDescriptionText }}
        </p>
        <el-form
          id="multiFactorForm"
          ref="multiFactorForm"
          :model="form"
          autocomplete="false"
          class="mt-0"
          :rules="rules"
          @submit.native.prevent
        >
          <el-form-item prop="code" class="is-no-asterisk mt-2 mb-0">
            <template slot="label">
              <slot name="textarea-label"> Verification Code </slot>
              <br />
              <span
                class="sr-only"
                aria-live="polite"
                :hidden="!hasInlineValidationErrorFor('code')"
                v-text="getInlineValidationErrorFor('code')"
              ></span>
            </template>
            <el-input
              id="code"
              v-model="form.code"
              name="verification code"
              placeholder="Verification code"
              :max-length="6"
              aria-required="true"
              @keyup.enter.native="submitCodeConfirmation"
            ></el-input>

            <template #error="{ error }">
              <div aria-describedby="code" class="el-form-item__error">{{ error }}</div>
            </template>
          </el-form-item>
          <el-row class="d-flex mt-4 row">
            <el-col :span="12">
              <el-button
                class="mt-3 mb-3 submit-btn"
                type="primary"
                @click="submitCodeConfirmation"
              >
                Login
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <p
          v-if="method.two_factor_type != enumHelper.MFAType.GOOGLE"
          class="my-2 text-left w-100 small"
        >
          <a
            :disabled="isLoading"
            role="button"
            class="cursor-pointer linkified-underline"
            tabindex="0"
            @click.stop="sendCode"
            >Send Again</a
          >
        </p>
        <p v-if="availableMethodsCount > 1" class="my-2 text-left w-100 small">
          <a
            role="button"
            class="cursor-pointer linkified-underline"
            tabindex="0"
            @click.self="() => $emit('on-select-different-method')"
            >Use another authentication method</a
          >
        </p>
        <p class="my-2 text-left w-100 small">
          <a class="linkified-underline" :href="getCMSRoute('contact-us')"> Need Help? </a>
        </p>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import enumHelper from "@/helpers/enumHelper";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import gsApi from "@/services/gs.api";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    method: {
      type: Object,
      default: () => {},
    },
    availableMethodsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      enumHelper,
      form: {
        code: null,
        two_factor_type: this.method ? this.method.two_factor_type : null,
      },
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
          },
        ],
      },
      isLoading: false,
      selectDifferentMethod: false,
    };
  },
  computed: {
    getDescriptionText() {
      return this.method.two_factor_type == enumHelper.MFAType.GOOGLE
        ? "Enter the 6-digit verification code found in your Google Authenticator app"
        : this.method.two_factor_type == enumHelper.MFAType.PHONE
        ? `A text message with a 6-digit verification code was just sent to this phone number ending in ${this.method.two_factor_method}`
        : `An email with a 6-digit verification code was sent to this email address ${this.method.two_factor_method}`;
    },
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },
  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    submitCodeConfirmation() {
      this.$refs.multiFactorForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true;

          const action =
            this.method.two_factor_type == enumHelper.MFAType.GOOGLE
              ? gsApi.mfa.confirmedTwoFactorGoogleAuthentication
              : gsApi.mfa.confirmedMultiFactorPhoneAuthentication;

          let message = null;
          const twoFactorConfirmedResponse = await action(this.form).catch(
            (error) => {
              message = error?.response?.data?.error?.message;
              return false;
            }
          );

          if (!twoFactorConfirmedResponse) {
            this.isLoading = false;

            this.$alert(
              `
                  <p class='title mb-5 mt-2'>Unsuccessful attempt</p>
                  <p class='message'>${
                    message ||
                    "There were some problems verifying your code. Please try again"
                  }</p>
                  `,
              "",
              {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "Try again",
                confirmButtonClass: "primary",
                showClose: true,
                customClass: "notification-modal message-modal",
              }
            );

            return;
          }

          this.verificationCodeError = null;
          this.onClose();
          this.$store.commit("USER_LOGIN_FOR_MULTI_FACTOR");
          this.$emit("continue-with-login");
        }
      });
    },
    async sendCode() {
      this.isLoading = true;
      const response = await gsApi.mfa
        .generateMultiFactorCode({
          two_factor_type: this.method.two_factor_type,
        })
        .catch(() => {
          return false;
        });

      this.$alert(
        response
          ? `<p class='title mb-5 mt-2'>Code Sent</p>
        <p class='message'>The email has been resent successfully</p>`
          : `<p class='title mb-5 mt-2'>Unsuccessful attempt</p>
        <p class='message'>There were some problems sending your code. Please try again</p>`,
        "",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: response ? "OK, Thanks" : "Try again",
          confirmButtonClass: "primary",
          showClose: true,
          customClass: "notification-modal message-modal",
        }
      );
      this.isLoading = false;
    },
  },
};
</script>
