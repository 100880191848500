<template>
  <div class="mfa-select-page page">
    <div :class="`mfa-select-page__header`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div id="main-content" class="header__content__wrapper">
              <h2 class="header__title">
                “GlobalScot has had such a huge impact on our business -
                frankly, it could have been the difference between us making it
                or not." David Capaldi, Opinurate
              </h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="mfa-select-page__body" v-loading="loadingTwoFactorMethods">
      <div class="container">
        <el-row>
          <el-col :md="16" :lg="12" :xl="12">
            <div class="body__content">
              <h1 class="se-heading-m step-title">
                <h1 class="se-heading-m step-title">
                  {{ `Secure your account${isAdmin || isStaff ? "" : "?"}` }}
                </h1>
              </h1>

              <p class="mb-5">
                {{
                  isAdmin || isStaff
                    ? `You must provide an extra level of security before continuing. 
                Please enable an authentication method below:`
                    : `You now have the option to add Multi Factor Authentication (MFA)
                to this account. We recommend you do this for security reasons
                but you can choose to opt out and sign in with a username and
                password only. If you would like to decline MFA, please click on
                ‘Continue without MFA’ below.`
                }}
              </p>

              <!-- Start Email MFA -->
              <h3>
                {{
                  `You have ${
                    isMFAMethodAvailable(enumHelper.MFAType.EMAIL) ? "" : "not "
                  } enabled email backup authentication.`
                }}
              </h3>
              <p>
                When you enable email backup authentication, the system will
                prompt you for a secure, random token during authentication. You
                may retrieve this token from an email sent to the backup email
                address provided.
              </p>
              <div class="submit-button-wrapper my-4">
                <el-button
                  v-if="!isMFAMethodAvailable(enumHelper.MFAType.EMAIL)"
                  type="default"
                  class="mfa-button"
                  @click="showEmailAuthentication = true"
                  >Set up backup email</el-button
                >
                <p v-else class="font-weight-bold text-success">
                  <i class="el-icon-circle-check text-success mr-1"></i>Backup
                  email enabled
                </p>
              </div>

              <!-- End Email MFA -->

              <!-- Start Google MFA -->
              <h3>
                {{
                  `You have ${
                    isMFAMethodAvailable(enumHelper.MFAType.GOOGLE)
                      ? ""
                      : "not "
                  }enabled the Google Authenticator.`
                }}
              </h3>
              <p>
                When you enable the Google Authenticator, will prompt you for a
                secure, random token during authentication. You may retrieve
                this token from your phone’s Google Authenticator application.
              </p>
              <div class="submit-button-wrapper my-4">
                <el-button
                  v-if="!isMFAMethodAvailable(enumHelper.MFAType.GOOGLE)"
                  type="default"
                  class="mfa-button"
                  @click="showGoogleAuthentication = true"
                  >Set up Google authentication</el-button
                >
                <p v-else class="font-weight-bold text-success">
                  <i class="el-icon-circle-check text-success mr-1"></i>Google
                  authentication enabled
                </p>
              </div>

              <!-- End Google MFA -->

              <!-- Start Phone MFA -->
              <h3>
                {{
                  `You have ${
                    isMFAMethodAvailable(enumHelper.MFAType.PHONE) ? "" : "not "
                  }enabled SMS authentication. (UK mobile numbers only)`
                }}
              </h3>
              <p>
                When you enable SMS authentication, the system will prompt you
                to enter a secure, random token during authentication. You may
                retrieve this token via a SMS message sent to the phone number
                provided.
              </p>

              <div class="submit-button-wrapper my-4">
                <div v-if="!isMFAMethodAvailable(enumHelper.MFAType.PHONE)">
                  <el-button
                    type="default"
                    class="mfa-button"
                    @click="showPhoneAuthentication = true"
                  >
                    Set up SMS authentication
                  </el-button>
                  <span
                    >Please note: SMS authentication is available to UK mobile
                    numbers only</span
                  >
                </div>

                <p v-else class="font-weight-bold text-success">
                  <i class="el-icon-circle-check text-success mr-1"></i>SMS
                  authentication enabled
                </p>
              </div>
              <!-- End Phone MFA -->

              <div class="submit-button-wrapper my-4 align-items-center">
                <el-button
                  type="default"
                  class="mfa-button"
                  :disabled="!user.has_mfa_enabled"
                  @click="completeMfaSetup()"
                >
                  Save MFA choice and continue
                </el-button>

                <el-button
                  type="primary"
                  v-if="!isAdmin && !isStaff && !user.has_mfa_enabled"
                  @click="completeMfaSetup(true)"
                  >Continue without MFA</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <setup-email-authentication
      v-if="showEmailAuthentication"
      :visible="showEmailAuthentication"
      @closed="showEmailAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />
    <setup-google-authentication
      v-if="showGoogleAuthentication"
      :visible="showGoogleAuthentication"
      @closed="showGoogleAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />
    <setup-phone-authentication
      v-if="showPhoneAuthentication"
      :visible="showPhoneAuthentication"
      :contact-number="user?.expertProfile?.contact_number"
      @closed="showPhoneAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import gsApi from "@/services/gs.api";
import SetupGoogleAuthentication from "@/components/landing/SetupGoogleAuthentication";
import SetupPhoneAuthentication from "@/components/landing/SetupPhoneAuthentication";
import SetupEmailAuthentication from "@/components/landing/SetupEmailAuthentication";
import enumHelper from "@/helpers/enumHelper";
export default {
  name: "MFASelectPage",
  metaInfo: {
    title: "Multi-Factor Authentication",
    meta: [
      {
        name: "description",
        content: "",
      },
    ],
  },
  components: {
    SetupGoogleAuthentication,
    SetupPhoneAuthentication,
    SetupEmailAuthentication,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin", "isStaff"]),
    isMFAMethodAvailable() {
      return (type) => {
        return !!this.user?.availableMfaMethods?.data?.find(
          (mfa) => mfa.two_factor_type == type
        );
      };
    },
  },
  data() {
    return {
      enumHelper,
      showGoogleAuthentication: false,
      showPhoneAuthentication: false,
      showEmailAuthentication: false,
      loadingTwoFactorMethods: false,
    };
  },
  methods: {
    onRefreshUserMultifactorMethods() {
      // refresh the user available methods
      this.loadingTwoFactorMethods = true;
      gsApi.auth
        .validateToken(this.user.token)
        .then((response) => {
          this.$store.commit("USER_LOGIN", response.data);
        })
        .catch(() => {})
        .finally(() => {
          this.loadingTwoFactorMethods = false;
        });
    },
    completeMfaSetup(skip = false) {
      this.loadingTwoFactorMethods = true
      gsApi.mfa
        .mfaSetupCompleted()
        .then(() => {
          this.$store.commit("UPDATE_USER_MFA_SETUP_STATUS");
          if (!skip) {
            this.$store.commit("USER_LOGIN_FOR_MULTI_FACTOR");
          }
          this.$router.push({ name: "Feed" });
        })
        .catch(() => {})
        .finally(()=>{
          this.loadingTwoFactorMethods = false
        });
    },
  },
};
</script>
