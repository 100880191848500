<template>
  <div
    v-loading="mainLoader"
    class="gs-personal-details-container"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div
      v-if="!mainLoader"
      class="personal-details"
    >
      <h2>Personal details</h2>
      <el-form
        id="submitForm"
        ref="submitForm"
        :model="form"
        :rules="rules"
        @submit.prevent.native="submitStaffForm"
        @validate="handleSubmitFormFieldInput"
      >
        <el-row>
          <el-col :sm="24">
            <el-form-item
              v-loading="removeAvatarLoader"
              prop="image"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <div
                v-if="currentAvatar"
                class="d-flex upload-photo"
              >
                <el-avatar
                  v-if="currentAvatar"
                  shape="circle"
                  :size="140"
                  :src="currentAvatar"
                  alt="Profile picture"
                ></el-avatar>

                <el-upload
                  ref="upload"
                  action=""
                  :multiple="false"
                  list-type="picture-card"
                  class="selected-upload"
                  :auto-upload="false"
                  :on-change="onFileChange"
                  :disabled="avatarLoader"
                >
                  <div slot="default">
                    <el-button class="replace-button">
                      {{ customAvatar ? 'Replace' : 'Browse files' }}
                    </el-button>
                  </div>

                  <div
                    slot="file"
                    slot-scope="{file}"
                  ></div>
                </el-upload>
                <span
                  v-if="customAvatar"
                  class="primary-text-underline"
                  @click="removeSelectedAvatar"
                >Remove</span>
              </div>
              <span class="upload-photo-text">Upload a profile photo - Optional</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="avatarLoader">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
          >
            <el-progress
              :percentage="percentage"
              color="#AF006E"
            ></el-progress>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col
            :xl="12"
            :lg="12"
            :md="18"
            :sm="18"
          >
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
            <el-form-item
              prop="title"
              class="is-no-asterisk form-group title"
            >
              <template slot="label">
                <span aria-hidden="true">Title<span class="is-custom-asterisk">*</span></span><br />
                <span class="sr-only">Please select a title</span>
                <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('title')"
                  v-text="getInlineValidationErrorFor('title')"
                ></span>
              </template>
              <v-select
                v-model="form.title"
                placeholder="Please select"
                :options="titles"
                :searchable="false"
                :clearable="false"
                :reduce="title => title.id"
                input-id="title"
                class="half-content"
                autocomplete="false"
              />
            </el-form-item>
            <el-form-item
              prop="first_name"
              class="form-group first-name"
            >
              <template slot="label">
                First name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('first_name')"
                  v-text="getInlineValidationErrorFor('first_name')"
                ></span>
              </template>
              <el-input
                id="first_name"
                v-model="form.first_name"
                placeholder="Your first name"
                maxlength="64"
                auto-complete="no"
                label="Please enter your first name in this field"
                aria-required="true"
                @blur="form.first_name = form.first_name.trim()"
              />
            </el-form-item>
            <el-form-item
              prop="last_name"
              class="form-group last-name"
            >
              <template slot="label">
                Last name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('last_name')"
                  v-text="getInlineValidationErrorFor('last_name')"
                ></span>
              </template>
              <el-input
                id="last_name"
                v-model="form.last_name"
                placeholder="Your last name"
                maxlength="64"
                auto-complete="no"
                label="Please enter your last name in this field"
                aria-required="true"
                @blur="form.last_name = form.last_name.trim()"
              />
            </el-form-item>
            <el-form-item
              prop="contact_number"
              class="is-no-asterisk form-group contact-number"
            >
              <template slot="label">
                Contact number<span class="is-custom-asterisk">*</span><br />
                <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('contact_number')"
                  v-text="getInlineValidationErrorFor('contact_number')"
                ></span>
              </template>
              <el-input
                id="contact_number"
                v-model="form.contact_number"
                placeholder="Contact number"
                maxlength="20"
                auto-complete="no"
                label="Please enter your contact number in this field"
                aria-required="true"
                @blur="form.contact_number = form.contact_number.trim()"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <div class="step-hr mb-4"></div>

        <el-row>
          <el-col
            :md="24"
            :lg="16"
          >
            <el-form-item
              prop="organization_id"
              class="is-no-asterisk"
            >
              <template slot="label">
                <span aria-hidden="true">Which organisation are you part of? <span class="is-custom-asterisk">*</span></span><br />
                <span class="sr-only">Please select which organisation you are part of</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('organization_id')"
                  v-text="getInlineValidationErrorFor('organization_id')"
                ></span>
              </template>
              <single-select
                v-model="form.organization_id"
                :options="organisations"
                name="organization_id"
                @input="onOrganisationChange($event)"
              ></single-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="showCustomOrganisationField">
          <el-col
            :md="24"
            :lg="16"
          >
            <el-form-item
              prop="custom_organization"
              class="is-no-asterisk"
            >
              <template slot="label">
                Organisation<span class="is-custom-asterisk">*</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('custom_organization')"
                  v-text="getInlineValidationErrorFor('custom_organization')"
                ></span>
              </template>
              <el-input
                id="custom_organization"
                v-model="form.custom_organization"
                autocomplete="false"
                label="Please enter your organisation name"
                aria-required="true"
                @blur="form.custom_organization = form.custom_organization.trim()"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col
            :md="24"
            :lg="17"
          >
            <el-form-item
              prop="expectations"
              class="is-no-asterisk"
            >
              <template slot="label">
                Tell the community about your role and what you are looking for from the network <span class="is-custom-asterisk">*</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('expectations')"
                  v-text="getInlineValidationErrorFor('expectations')"
                ></span>
              </template>
              <el-input
                id="expectations"
                v-model="form.expectations"
                type="textarea"
                :rows="5"
                maxlength="500"
                label="Tell the community about your role and what you are looking for from the network"
                aria-required="true"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="24"
            class="mt-n4"
          >
            <span class="se-body-l-screen color-light-gray">You have {{ 500 - form.expectations.length }} characters remaining</span>
          </el-col>
        </el-row>

        <div class="step-hr mb-4"></div>

        <el-row>
          <el-col
            :xl="12"
            :lg="12"
            :md="18"
            :sm="18"
          >
            <span class="group-header">Where are you primarily based for work?</span>
            <el-form-item
              prop="country_id"
              class="is-no-asterisk form-group"
            >
              <template slot="label">
                <span aria-hidden="true">Select a country<span class="is-custom-asterisk">*</span></span>
                <span class="sr-only">Please select a country</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('country_id')"
                  v-text="getInlineValidationErrorFor('country_id')"
                ></span>
              </template>
              <v-select
                v-model="form.country_id"
                placeholder="Please select"
                :options="countries"
                :searchable="true"
                :clearable="false"
                :reduce="country => country.id"
                label="name"
                input-id="country_id"
                autocomplete="false"
                @input="getStatesFromCountry"
              />
            </el-form-item>
            <el-form-item
              prop="state_id"
              class="is-no-asterisk form-group"
            >
              <template slot="label">
                <span aria-hidden="true">Select a City/State<span class="is-custom-asterisk">*</span></span>
                <span class="sr-only">Please select city or state in this field</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('state_id')"
                  v-text="getInlineValidationErrorFor('state_id')"
                ></span>
              </template>
              <v-select
                v-model="form.state_id"
                placeholder="Please select"
                :options="states"
                :searchable="true"
                :clearable="false"
                :reduce="state => state.id"
                label="name"
                input-id="state_id"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row
          v-loading.fullscreen.lock="addressesLoading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <el-col
            :xl="12"
            :lg="12"
            :md="18"
            :sm="18"
          >
            <span class="group-header">Company listed address (Optional)</span>
            <span class="address-visible">This won’t be publicly displayed on your profile.</span>
            <el-form-item
              label="Post code/Zip code"
              class="mt-4"
              prop="mailing_post_code"
            >
              <div class="d-flex">
                <el-input
                  id="mailing_post_code"
                  v-model="form.address.post_code"
                  class="half-flex-content"
                  maxlength="30"
                  autocomplete="false"
                  label="Please enter mailing post code in this field"
                  aria-required="true"
                  @blur="form.address.post_code = form.address.post_code.trim()"
                  @input="disableAddressLookup = false"
                  @keypress.enter.native="onFindAddress($event)"
                />

                <div class="mr-3"></div>

                <el-form-item class="half-flex-content">
                  <el-button
                    size="medium"
                    :disabled="disableAddressLookup || form.address.post_code.trim().length == 0"
                    @click="onFindAddress($event)"
                  >
                    Find address
                  </el-button>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item v-if="availableMailingAddresses.length > 0">
              <template slot="label">
                <span aria-hidden="true">Addresses Found</span>
                <span class="sr-only">Please select your address</span>
              </template>
              <v-select
                v-model="selectedAvailableMailingAddress"
                placeholder="Please select"
                :options="availableMailingAddresses"
                :searchable="true"
                :clearable="false"
                label="Text"
                input-id="available_mailing_addresses"
                autocomplete="false"
                @input="onAvailableMailingAddressSelected($event)"
              />
            </el-form-item>
            <el-form-item prop="address.address_1">
              <template slot="label">
                Address line 1
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('address.address_1')"
                  v-text="getInlineValidationErrorFor('address.address_1')"
                ></span>
              </template>
              <el-input
                id="address.address_1"
                v-model="form.address.address_1"
                maxlength="255"
                autocomplete="false"
                label="Please enter mailing address line 1 in this field"
                aria-required="true"
                @blur="form.address.address_1 = form.address.address_1.trim()"
              />
            </el-form-item>
            <el-form-item prop="address.address_2">
              <template slot="label">
                Address line 2 - Optional
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('address.address_2')"
                  v-text="getInlineValidationErrorFor('address.address_2')"
                ></span>
              </template>
              <el-input
                id="mailing_address_2"
                v-model="form.address.address_2"
                maxlength="255"
                autocomplete="false"
                label="Please enter mailing address line 2 in this field"
                @blur="form.address.address_2 = form.address.address_2.trim()"
              />
            </el-form-item>
            <el-form-item prop="address.town">
              <template slot="label">
                Town, City or State
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('address.town')"
                  v-text="getInlineValidationErrorFor('address.town')"
                ></span>
              </template>
              <el-input
                id="address.town"
                v-model="form.address.town"
                maxlength="100"
                autocomplete="false"
                label="Please enter mailing town, city or state in this field"
                aria-required="true"
                @blur="form.address.town = form.address.town.trim()"
              />
            </el-form-item>
            <el-form-item prop="address.county">
              <template slot="label">
                Region - Optional
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('address.county')"
                  v-text="getInlineValidationErrorFor('address.county')"
                ></span>
              </template>
              <el-input
                id="address.county"
                v-model="form.address.county"
                maxlength="100"
                autocomplete="false"
                label="Please enter mailing region in this field"
                @blur="form.address.county = form.address.county.trim()"
              />
            </el-form-item>
            <el-form-item prop="address.country_id">
              <template slot="label">
                <span aria-hidden="true">Country</span>
                <span class="sr-only">Please select a country</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="! hasInlineValidationErrorFor('address.country_id')"
                  v-text="getInlineValidationErrorFor('address.country_id')"
                ></span>
              </template>
              <v-select
                v-model="form.address.country_id"
                placeholder="Please select"
                :options="countries"
                :searchable="true"
                :clearable="false"
                :reduce="country => country.id"
                label="name"
                input-id="address.country_id"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>

        <div class="d-flex form-actions">
          <el-button
            class="btn-cancel"
            @click="$router.push({name: 'Staff Profile', params: { id: user.id }})"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            class="btn-submit"
          >
            Save changes
          </el-button>
        </div>
      </el-form>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from "vuex"
import gsApi from '@/services/gs.api'
import loqateApi from '@/services/loqate.api'
import CropperUpload from '@/components/cropper-upload/cropper-upload'
import validationErrors from '@/components/partials/validation-errors'
import helpers from '@/helpers/index'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

export default {
  name: "PersonalDetails",

  components: {
    validationErrors,
    'cropper-upload': CropperUpload,
  },
  mixins: [inlineValidationErrorsMixin],
  data() {
    let validateContactNumber = (rule, value, callback) => {
      let reg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

      let length = value.length;

      if (value==='') {
        callback(new Error('Contact number is required'))
      } else if (length < 7) {
        callback(new Error('Contact number may not be less than 7 characters'))
      } else if (length > 20) {
        callback(new Error('Contact number may not be greater than 20 characters'))
      } else if (reg.test(value)==false) {
        callback(new Error('Contact number field must be valid'))
      } else {
        callback()
      }
    };

    return {
      percentage:1,
      cropperFile: null,
      showCropperDialog: false,
      validationErrors: [],
      mainLoader: false,
      avatarLoader: false,
      removeAvatarLoader : false,
      currentAvatar: store.state.user.avatar ? store.state.user.avatar : '',
      customAvatar: store.state.user.hasCustomAvatar,
      nationalities: [],
      countries: [],
      states: [],
      organisations: [],
      showCustomOrganisationField: (store.state.user.staffProfile.organization.name == "Other" && store.state.user.staffProfile.custom_organization !== '') ? true : false,
      form: {
        title: store.state.user.title ? store.state.user.title : null,
        first_name: store.state.user.firstName ? store.state.user.firstName : '',
        last_name: store.state.user.lastName ? store.state.user.lastName : '',
        avatar: null,
        contact_number: store.state.user.staffProfile ? store.state.user.staffProfile.contact_number : null,
        organization_id: store.state.user.staffProfile.organization ? store.state.user.staffProfile.organization.id : null,
        custom_organization: (store.state.user.staffProfile.custom_organization && store.state.user.staffProfile.custom_organization !== '') ? store.state.user.staffProfile.custom_organization : '',
        expectations: store.state.user.staffProfile ? store.state.user.staffProfile.expectations : null,
        country_id: null,
        state_id: null,
        address: {
          post_code: store.state.user.address?.post_code ? store.state.user.address.post_code : '',
          address_1: store.state.user.address?.address_1 ? store.state.user.address.address_1 : '',
          address_2: store.state.user.address?.address_2 ? store.state.user.address.address_2 : '',
          town: store.state.user.address?.town ? store.state.user.address.town : '',
          county: store.state.user.address?.county ? store.state.user.address.county : '',
          country_id: store.state.user.address?.country ? store.state.user.address.country.id : null
        },
      },
      rules: {
        title: [
          { required: true, message: 'Please select a title', trigger: 'change' },
        ],
        first_name: [
          { required: true, message: 'First name is required', trigger: 'blur' },
          { max: 64, message: 'First name may not be greater than 64 characters', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Last name is required', trigger: 'blur' },
          { max: 64, message: 'Last name may not be greater than 64 characters', trigger: 'blur' },
        ],
        contact_number: [
          { validator: validateContactNumber, required: true },
        ],
        organization_id: [
          { required: true, message: 'Select an organisation from the dropdown', trigger: 'change' },
        ],
        custom_organization: [
          { required: true, message: 'Enter your organisation', trigger: 'blur' },
        ],
        expectations: [
          {
            required: true,
            message:
                "Enter a description of what you are looking for from the network",
            trigger: "blur"
          },
          {
            max: 500,
            message:
                "May not be greater than 500 characters",
            trigger: "change"
          }
        ],
        country_id: [
          { required: true, message: 'Country is required', trigger: 'change' },
        ],
        state_id: [
          { required: true, message: 'State is required', trigger: 'change' },
        ]
      },
      titles: [
        {id: 1, label: 'Mr'},
        {id: 2, label: 'Mrs'},
        {id: 3, label: 'Ms'},
        {id: 4, label: 'Miss'},
        {id: 6, label: 'Dr'},
        {id: 7, label: 'Prof'},
        {id: 8, label: 'Sir'},
        {id: 9, label: 'Dame'},
        {id: 10, label: 'Reverend'},
        {id: 11, label: 'Duke'},
        {id: 12, label: 'Duchess'},
        {id: 5, label: 'Other'},
        {id: 13, label: "Mx"},
      ],
      selectedAvailableMailingAddress: null,
      availableMailingAddresses:[],
      disableAddressLookup: false,
      addressesLoading:false
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getRegisterMeta();

    if (store.state.user.staffProfile) {
      this.getStatesFromCountry(store.state.user.staffProfile.country.id, false);
    }
  },
  methods: {
    onOrganisationChange(event) {
      let organisation = this.organisations.find(o => (o.id == event))

      if (organisation.name == 'Other') {
        this.showCustomOrganisationField = true
      } else {
        this.form.custom_organization = ''
        this.showCustomOrganisationField = false
      }
    },

    // Start of upload / cropper methods
    removeSelectedAvatar() {
      this.removeAvatarLoader = true

      gsApi.member.user.removeAvatar()
        .then(response => {
          this.removeAvatarLoader = false

          this.currentAvatar = response.data.avatar
          this.customAvatar = response.data.has_custom_avatar

          store.commit('USER_AVATAR_CHANGE', response.data)
          this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch(error => {
          this.removeAvatarLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file
        this.showCropperDialog = true
      }
    },
    onCropperUpload(croppedFileData) {
      this.onAvatarUpload(croppedFileData.file)
    },
    onAvatarUpload (file) {
      const formData = new FormData()

      formData.append("avatar", file)

      this.percentage = 1;
      let interval = 0;
      this.avatarLoader = true

      this.showCropperDialog = false
      this.cropperFile = null

      gsApi.member.user.updateAvatar(formData,(progress) => {
        interval = setInterval(()=>{
          if(this.percentage < helpers.randomNumber(94,99)){
            this.percentage+= 1;
          }
        },120 )
      })
        .then(response => {
          this.currentAvatar = response.data.avatar
          this.customAvatar = response.data.has_custom_avatar

          store.commit('USER_AVATAR_CHANGE', response.data)
          this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch(error => {
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .finally(() => {
          this.avatarLoader = false;
          clearInterval(interval);
          this.percentage = 1;
        });
    },
    onCropperDialogClose() {
      this.showCropperDialog = false
      this.cropperFile = null
    },
    validateAvatarUpload(file) {
      let type = (file.raw && file.raw.type) ? file.raw.type : file.type
      let size = (file.raw && file.raw.size) ? file.raw.size : file.size

      const isJPG = type === 'image/jpeg';
      const isPNG = type === 'image/png';
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert('Avatar must be in a jpeg or png format.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      } else if (!isLt6M) {
        this.$alert('Avatar size can not exceed 6MB.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    // End of upload / cropper methods

    getRegisterMeta() {
      this.mainLoader = true

      gsApi.globalscot.meta.index()
        .then(response => {
          if (store.state.user.staffProfile.country) {
            this.form.country_id = store.state.user.staffProfile.country.id;
          }
          if (store.state.user.staffProfile.state) {
            this.form.state_id = store.state.user.staffProfile.state.id;
          }
          this.nationalities = response.data.nationalities.data;
          this.countries = response.data.countries.data;

          this.getOrganisations()
        }).catch(() => {
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    getOrganisations() {
      gsApi.staff.organisations()
        .then(response => {
          this.mainLoader = false
          this.organisations = response.data.data
        }).catch(error => {
          this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.gotoLink('/home')
        })
    },
    submitStaffForm() {
      let self = this;
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          const formData = new FormData()

          formData.append('title', this.form.title)
          formData.append('first_name', this.$options.filters.domPurify(this.form.first_name, {ALLOWED_TAGS: []}))
          formData.append('last_name', this.$options.filters.domPurify(this.form.last_name, {ALLOWED_TAGS: []}))
          formData.append('contact_number', this.form.contact_number)
          formData.append('organization_id', this.form.organization_id)
          formData.append('expectations', this.$options.filters.domPurify(this.form.expectations, {ALLOWED_TAGS: []}))
          formData.append('country_id', this.form.country_id)
          formData.append('state_id', this.form.state_id)

          if (this.form.custom_organization && this.form.custom_organization !== '') {
            formData.append('custom_organization', this.form.custom_organization)
          }

          if(this.form.address.post_code !== '')
          {
            formData.append('post_code', this.form.address.post_code)
            formData.append('address_1', this.form.address.address_1)
            formData.append('address_2', this.form.address.address_2)
            formData.append('town', this.form.address.town)
            formData.append('county', this.form.address.county)
            formData.append('mailing_country_id', this.form.address.country_id)
          }

          this.mainLoader = true

          gsApi.staff.user.updatePersonalInfo(formData)
            .then(response => {
              self.$store.commit('UPDATE_STAFF_PERSONAL_DETAILS', response.data);
              this.mainLoader = false;
              this.validationErrors = [];
              this.$alert('You successfully updated your profile', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {}
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
              } else {
                this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
        } else {
          this.validationErrors = [];
          this.$refs.submitForm.fields.forEach(field => {
            if(field.validateState == 'error') {
              let obj = {}
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
            let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
            this.$announcer.set(text)
          })
        }
      });
    },
    getStatesFromCountry(countryId, eraseStateId = true) {
      if (eraseStateId) {
        this.form.state_id = null
      }

      this.mainLoader = true

      gsApi.state.index(countryId)
        .then(response => {
          this.mainLoader = false
          this.states = response.data.data
        })
    },
    handleSubmitFormFieldInput() {
      this.handleSingleFormFieldInput('submitForm');
    },
    onFindAddress (e) {
      e.preventDefault()
      this.findAddresses(this.form.address.post_code)
    },
    findAddresses (value, containerId='') {
      this.addressesLoading = true
      loqateApi.find(value, containerId, '')
        .then(response => {
          this.addressesLoading = false

          if (response.data && response.data.Items && response.data.Items.length > 0) {
            if (response.data.Items[0].Error) {
              this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id)
            } else if (!response.data.Items[0].Error) {
              this.form.address.selectedAvailableMailingAddress = null
              this.form.address.address_1 = ''
              this.form.address.address_2 = ''
              this.form.address.town = ''
              this.form.address.county = ''
              this.form.address.country_id = null

              this.disableAddressLookup = true
              this.availableMailingAddresses = response.data.Items
            }
          } else {
            this.availableMailingAddresses = []
            this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .catch(error => {
          this.addressesLoading = false
          this.availableMailingAddresses = []
          this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
    onAvailableMailingAddressSelected (address) {
      this.retrieveAddress(address.Id)
    },
    retrieveAddress (id) {
      loqateApi.retrieve(id)
        .then(response => {
          if (response.data && response.data.Items && response.data.Items.length > 0 && !response.data.Items[0].Error) {
            this.disableAddressLookup = true

            let address = response.data.Items[0]
            this.form.address.address_1 = address.Line1
            this.form.address.address_2 = address.Line2
            this.form.address.post_code = address.PostalCode
            this.form.address.town = address.City
            this.form.address.county = address.AdminAreaName

            let country = this.countries.find(c => (c.code == address.CountryIso2 || c.code == address.CountryIso3))
            if (country) {
              this.form.address.country_id = country.id
            }
          } else {
            this.$alert('No Postcode found. Please add it manually.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .catch(error => {
          this.$alert('Failed to automatically use the selected address. Please enter mailing address details instead.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
    },
  }
}
</script>
