<template>
  <div class="pulse-page">
    <div class="container pulse mt-3 mt-sm-5">
      <el-row>
        <el-col class="hidden-md-and-down mb-5 filters" :span="5">
          <a
            v-if="filtersActive"
            class="gs-link my-3 clickable d-block"
            @click="resetAllFiltersAndSorting"
          >
            Reset all filters
          </a>
          <h2>Category</h2>
          <div
            v-loading.lock="loadingCategories"
            class="categories mt-4"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <button
              v-for="(category, index) in categories"
              :key="index"
              :class="{ active: category.id === filters.category_id }"
              class="pulse-option min-width-100 py-2 px-3 mt-1 d-block"
              @click="selectCategory(category.id)"
            >
              {{ category.name }}
            </button>
          </div>
          <!-- TODO: extract to Tags component -->
          <h2 class="mt-4">Sectors</h2>
          <div
            v-loading.lock="loadingSectors"
            class="sectors mt-4"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <el-checkbox-group v-model="filters.sectors">
              <el-checkbox
                v-for="(sector, index) in sectors"
                v-if="isPublicSector(sector.id)"
                v-show="index < showSectorsIndex"
                :key="index"
                class="mt-3"
                :label="sector.id"
                @change="fetchPosts"
              >
                {{ sector.name }}
              </el-checkbox>
            </el-checkbox-group>
            <div v-if="sectors.length" class="show-links">
              <a
                v-if="showSectorsIndex <= sectors.length"
                class="gs-link mt-3 d-block clickable"
                @click="showMoreSectors"
              >
                Show more
              </a>
              <a
                v-if="showSectorsIndex > sectors.length"
                class="gs-link mt-3 d-block clickable"
                @click="showLessSectors"
                >Show less</a
              >
            </div>
          </div>
          <!-- TODO: extract to Tags component -->
          <h2 class="mt-4">Expertise</h2>
          <div
            v-loading.lock="loadingExpertise"
            class="expertise mt-4"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <el-checkbox-group v-model="filters.expertise">
              <el-checkbox
                v-for="(expert, index) in expertise"
                v-show="index <= showExpertiseIndex"
                :key="index"
                class="mt-3"
                :label="expert.id"
                @change="fetchPosts"
              >
                {{ expert.name }}
              </el-checkbox>
            </el-checkbox-group>
            <div v-if="expertise.length" class="show-links">
              <a
                v-if="showExpertiseIndex <= expertise.length + 1"
                class="gs-link mt-3 d-block clickable"
                @click="showMoreExpertise"
              >
                Show more
              </a>
              <a
                v-if="showExpertiseIndex > expertise.length + 1"
                class="gs-link mt-3 d-block clickable"
                @click="showLessExpertise"
                >Show less</a
              >
            </div>
            <a
              class="gs-link text-dark my-3 clickable d-inline-block"
              @click="resetAllFiltersAndSorting"
            >
              Reset all filters
            </a>
          </div>
          <hr class="mt-4" />
          <h2 class="mt-3 fs-24">
            <strong>Need to report a post?</strong>
          </h2>
          <a :href="getCMSRoute('contact-us')" class="gs-link mt-3 d-block">
            Contact GlobalScot team
          </a>
        </el-col>
        <el-col class="px-3 mb-3 hidden-lg-and-up mt-3" :span="24">
          <slide-panel
            v-if="slideIsOpen"
            title="Filters"
            @panelclose="slideIsOpen = false"
          >
            <div
              v-loading.lock="loadingCategories"
              class="filters position-relative"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <span class="fs-20">Network</span>
              <v-select
                v-model="selectedGlobalFilter"
                placeholder="Select"
                :options="globalFilters"
                :searchable="false"
                :clearable="false"
                class="my-2"
                :reduce="(filter) => filter.value"
                autocomplete="false"
              />
              <span class="fs-20">Categories</span>
              <v-select
                v-model="filters.category_id"
                placeholder="Select"
                :options="categories"
                :searchable="false"
                :clearable="false"
                class="my-2"
                :reduce="(category) => category.id"
                label="name"
                autocomplete="false"
              />
              <span class="d-block fs-20">Sort by</span>
              <v-select
                v-model="selectedSort"
                placeholder="Select"
                :options="sortOptions"
                :searchable="false"
                :clearable="false"
                class="my-2"
                :reduce="(option) => option.value"
                autocomplete="false"
              />
            </div>

            <!-- TODO: extract to Tags component -->
            <h2 class="mt-4">Sectors</h2>
            <div
              v-loading.lock="loadingSectors"
              class="sectors mt-4"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <el-checkbox-group v-model="filters.sectors">
                <el-checkbox
                  v-for="(sector, index) in sectors"
                  v-if="isPublicSector(sector.id)"
                  v-show="index < showSectorsIndex"
                  :key="index"
                  class="mt-3"
                  :label="sector.id"
                  @change="fetchPosts"
                >
                  {{ sector.name }}
                </el-checkbox>
              </el-checkbox-group>
              <a
                v-if="showSectorsIndex <= sectors.length"
                class="gs-link mt-3 d-block clickable"
                @click="showMoreSectors"
              >
                Show more
              </a>
              <a
                v-if="showSectorsIndex > sectors.length"
                class="gs-link mt-3 d-block clickable"
                @click="showLessSectors"
                >Show less</a
              >
            </div>
            <!-- TODO: extract to Tags component -->
            <h2 class="mt-4">Expertise</h2>
            <div
              v-loading.lock="loadingExpertise"
              class="sectors mt-4"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <el-checkbox-group v-model="filters.expertise">
                <el-checkbox
                  v-for="(expert, index) in expertise"
                  v-show="index <= showExpertiseIndex"
                  :key="index"
                  class="mt-3"
                  :label="expert.id"
                  @change="fetchPosts"
                >
                  {{ expert.name }}
                </el-checkbox>
              </el-checkbox-group>
              <a
                v-if="showExpertiseIndex <= expertise.length + 1"
                class="gs-link mt-3 d-block clickable"
                @click="showMoreExpertise"
              >
                Show more
              </a>
              <a
                v-if="showExpertiseIndex > expertise.length + 1"
                class="gs-link mt-3 d-block clickable"
                @click="showLessExpertise"
                >Show less</a
              >
            </div>
            <div
              class="filter-actions d-flex align-items-center justify-content-between"
            >
              <a
                class="gs-link text-dark my-3 clickable d-inline-block"
                @click="resetAllFiltersAndSorting"
              >
                Reset
              </a>
              <el-button
                type="primary"
                class="px-5 py-2 d-inline-block my-3 ml-5 min-height-initial"
                @click="refineSearch"
              >
                Refine
              </el-button>
            </div>
          </slide-panel>
          <el-row v-if="!submittingPost" :gutter="1">
            <el-col :span="12">
              <p class="fs-19">
                <strong>Noticeboard</strong>
              </p>
            </el-col>
            <el-col :span="12" class="text-right">
              <a class="gs-link clickable" @click="slideIsOpen = true"
                >Filters</a
              >
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :lg="12" :xl="13" class="mb-3">
          <el-card
            v-show="!submittingPost"
            class="mx-sm-0 mx-3 mt-2 mt-sm-0 clickable p-1 p-sm-2"
            @click.native="
              submittingPost = true;
              editMode = false;
            "
          >
            <el-row class="d-sm-flex align-items-center">
              <el-col class="d-flex align-items-center">
                <div class="avatar d-flex">
                  <user-avatar
                    shape="circle"
                    :is-global-scot="helpers.isExpert(user)"
                    width="45px"
                    :size="45"
                    :src="user.avatar"
                    :author-exist="!!user"
                    :alt="`${user.fullName} Profile picture`"
                  ></user-avatar>
                </div>
                <div class="content ml-3 w-100">
                  <p class="d-block fs-17">
                    Ask a question, start a discussion or share something with
                    the network. Click
                    <a
                      href="#"
                      class="gs-link fs-16 ml-1"
                      aria-label="click to create new post"
                      >create new post</a
                    >
                  </p>
                </div>
              </el-col>
              <!-- <el-col :span="8" :xs="24" :sm="8" :xl="5">
                                <hr class="hidden-sm-and-up my-3">
                                <el-row :gutter="0" class="d-flex align-items-center">
                                    <el-col class="text-center d-flex align-items-center justify-content-center" :xs="3" :sm="12">
                                        <svg-add-media class="w-100"></svg-add-media>
                                    </el-col>
                                    <el-col class="hidden-sm-and-up" :span="9">
                                        <a class="gs-link fs-16 ml-1" href="#">Photo/Video</a>
                                    </el-col>

                                    <el-col class="text-center d-flex align-items-center justify-content-center" :xs="3" :sm="12">
                                        <svg-add-post class="w-75 w-sm-100"></svg-add-post>
                                    </el-col>
                                    <el-col class="hidden-sm-and-up" :span="9">
                                        <a class="gs-link fs-16 ml-1" href="#">Document</a>
                                    </el-col>
                                </el-row>
                            </el-col> -->
            </el-row>
          </el-card>
          <el-row class="pt-3 mx-sm-0 mx-3 mt-2 mt-sm-0">
            <el-col :xs="17" :sm="19" :md="19" :lg="19" class="pl-1 pr-2">
              <el-input
                ref="pulse-search-input"
                v-model="filters.search"
                class="w-100"
                placeholder="Search"
                :clearable="true"
                @clear="fetchPosts"
              />
            </el-col>
            <el-col :xs="7" :sm="5" :md="5" :lg="5">
              <el-button
                v-loading="loadingFeed"
                class="pull-right w-100"
                :disabled="loadingFeed"
                size="mini"
                type="primary"
                @click="fetchPosts"
              >
                Search
              </el-button>
            </el-col>
          </el-row>
          <div
            v-show="!submittingPost"
            class="pulse-post-filter mt-4 hidden-sm-and-down"
          >
            <button
              v-for="(filter, index) in globalFilters"
              :key="index"
              :class="{ active: selectedGlobalFilter === filter.value }"
              class="pulse-option pulse-option-tab py-2 px-3"
              @click="selectGlobalFilter(filter.value)"
            >
              {{ filter.label }}
            </button>
          </div>
          <el-row v-show="!submittingPost" class="hidden-sm-and-down">
            <el-col :span="24">
              <div class="pulse-sort mt-3 float-right">
                <span class="mr-2 fs-20">Sort by</span>
                <v-select
                  v-model="selectedSort"
                  placeholder="Select"
                  :options="sortOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="(option) => option.value"
                  class="d-inline-block min-width-200"
                  autocomplete="false"
                  @input="fetchPosts"
                />
              </div>
            </el-col>
          </el-row>
          <div
            id="main-content"
            v-loading.lock="loadingFeed"
            class="pulse-feed"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <div
              v-show="submittingPost"
              class="overlay vh-100 vw-100 position-fixed"
              @click="confirmCloseForm"
            ></div>
            <pulse-form
              v-if="submittingPost"
              :categories="categories"
              :sectors="sectors"
              :expertise="expertise"
              class="px-3"
              :submitting-post="submittingPost"
              :edit-mode="editMode"
              :post="postToEdit"
              @closing="closeForm"
              @submitted="resetAllFiltersAndSorting"
            >
            </pulse-form>

            <pulse-post
              v-for="(post, index) in posts"
              :id="`post-${post.id}`"
              :ref="`pulse-post-${post.id}`"
              :key="`${post.id}-${index}`"
              :post="post"
              :index="index"
              :open-reply-for-post="replyPostId"
              @editPost="onEditPost"
              @deletePost="onRemovePost"
            >
            </pulse-post>

            <el-row v-if="!loadingFeed && !posts.length" :gutter="1">
              <el-col :span="24" class="p-5">
                <h2>No results found for the given search criteria.</h2>
              </el-col>
            </el-row>
            <el-row v-if="nextLink" :gutter="1">
              <el-col class="mt-4 text-center" :span="24">
                <hr class="d-block" />
                <a
                  class="fs-19 gs-link mt-3 d-inline-block clickable"
                  @click="showMore"
                  >Show more</a
                >
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col class="pl-3 hidden-md-and-down" :span="7" :xl="6">
          <h2>Recent members:</h2>
          <div
            v-loading.lock="loadingMembers"
            class="recent-members"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <member-card
              v-for="(member, index) in recentMembers"
              :key="index"
              class="mt-3"
              :member="member"
            ></member-card>
          </div>
        </el-col>
        <el-col class="px-3 hidden-lg-and-up bg-gray-light" :span="24">
          <h2 class="mt-3">Recent members:</h2>
        </el-col>
        <el-col
          v-loading.lock="loadingMembers"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          class="p-3 mb-5 hidden-lg-and-up bg-gray-light overflow-scroll"
          :span="24"
        >
          <div class="carousel d-flex">
            <div
              v-for="(member, index) in recentMembers"
              :key="index"
              class="item members-card-display mt-3 mx-2"
            >
              <member-card :member="member"></member-card>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SvgAddMedia from "@/components/assets/svg-add-media";
import SvgAddPost from "@/components/assets/svg-add-post";
import PulsePost from "@/components/platform/pulse/pulse-post";
import PulseForm from "@/components/platform/pulse/pulse-form";
import MemberCard from "@/components/platform/pulse/member-card";
import SlidePanel from "@/components/slide-panel/slide-panel";
import store from "@/store";
import _ from "lodash";
import gsApi from "@/services/gs.api";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
import { mapGetters } from "vuex";
export default {
  name: "Pulse",
  metaInfo: {
    title: "Noticeboard",
  },
  components: {
    "svg-add-media": SvgAddMedia,
    "svg-add-post": SvgAddPost,
    "pulse-post": PulsePost,
    "pulse-form": PulseForm,
    "member-card": MemberCard,
    "slide-panel": SlidePanel,
    UserAvatar,
  },
  data() {
    return {
      helpers,
      posts: [],
      sectors: [],
      expertise: [],
      recentMembers: [],
      categories: [
        {
          name: "All",
          id: 0,
        },
      ],
      sortCombinations: {
        newest: { order_by: "created_at", order: "descending" },
        oldest: { order_by: "created_at", order: "ascending" },
        popularity: { order_by: "comments_count", order: "descending" },
        least_popular: { order_by: "comments_count", order: "ascending" },
      },
      sortOptions: [
        {
          value: "newest",
          label: "Newest",
        },
        {
          value: "oldest",
          label: "Oldest",
        },
        {
          value: "popularity",
          label: "Popularity",
        },
        {
          value: "least_popular",
          label: "Least Popular",
        },
      ],
      selectedSort: "newest",
      globalFilters: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Featured",
          value: "featured",
        },
        /* {
          label: "Following",
          value: "following",
        }, */
        {
          label: "GlobalScots",
          value: "globalscots",
        },
      ],
      selectedGlobalFilter: "all",
      filters: {
        sectors: [],
        expertise: [],
        category_id: 0,
        search: "",
      },
      showSectorsIndex: 4,
      showExpertiseIndex: 3,
      nextLink: null,
      loadingFeed: false,
      loadingCategories: true,
      loadingSectors: true,
      loadingExpertise: true,
      loadingMembers: true,
      submittingPost: false,
      slideIsOpen: false,
      editMode: false,
      nonPublicSectors: [21, 22, 23],
      //user: {},
      fethedPostsFirstTime: false,
      replying: false,
      replyPostId: this.$route.params.id ? this.$route.params.id : 0,
      postToEdit: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["hashtagFilter"]),
    filtersActive() {
      for (let key in this.filters) {
        if (key === "category_id") {
          if (this.filters[key]) return true;
        } else if (this.filters[key].length) {
          return true;
        }
      }

      return false;
    },
    selectedSortCombination() {
      return this.sortCombinations[this.selectedSort];
    },
  },
  watch: {
    hashtagFilter(hashtag) {
      if (hashtag === null) {
        return;
      }

      this.$refs["pulse-search-input"].$el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.filters.search = hashtag;
      this.fetchPosts();
      store.dispatch("clearHashtagFilter");
    },
  },
  mounted() {
    if (store.state.user.isLogged) {
      //this.user = store.state.user
      if (this.hashtagFilter !== null) {
        this.filters.search = this.hashtagFilter;
      }

      this.fetchPosts();

      gsApi.post.category.index().then((response) => {
        this.categories = this.categories.concat(response.data.data);
        this.loadingCategories = false;
      });

      let self = this;
      gsApi.report.reason.index().then((response) => {
        self.$store.commit(
          "REPORT_REASONS",
          response.data ? response.data.data : []
        );
      });

      gsApi.group.index({ per_page: 100 }).then((response) => {
        this.sectors = _.remove(response.data.data, (n) => {
          return !this.nonPublicSectors.includes(n.id);
        });
        this.loadingSectors = false;
      });

      gsApi.expertise.index().then((response) => {
        this.expertise = response.data.data;
        this.loadingExpertise = false;
      });

      gsApi.members
        .search({ per_page: 10, member_type: "All" })
        .then((response) => {
          this.recentMembers = response.data.data;
          this.loadingMembers = false;
        });
    }
  },
  created() {
    /*if (!store.state.user.isLogged) {
                this.$router.push('/login')
            }*/
    this.updatePostEventListner();
    window.addEventListener("scroll", () => {
      // this.bottom = this.bottomVisible()
    });
  },
  methods: {
    updatePostEventListner() {
      this.$root.$off("POST_COMMENT_RELOAD");
      this.$root.$on("POST_COMMENT_RELOAD", (post) => {
        if (this.posts.findIndex((p) => p.id == post.id) > -1) {
          let componenet = this.$refs[`pulse-post-${post.id}`];
          if (componenet.length) {
            componenet[0].fetchComments();
          }
        }
      });
    },
    selectCategory(categoryId) {
      this.filters.category_id = categoryId;
      this.fetchPosts();
    },
    isPublicSector(sectorId) {
      return !this.nonPublicSectors.includes(sectorId);
    },
    fetchPosts() {
      this.loadingFeed = true;
      this.posts = [];

      gsApi.post
        .index({
          order_by: this.selectedSortCombination.order_by,
          order: this.selectedSortCombination.order,
          user_role:
            this.selectedGlobalFilter === "globalscots" ? "expert" : null,
          is_featured: this.selectedGlobalFilter === "featured" ? true : null,
          //is_following: this.selectedGlobalFilter === "following" ? true : null,
          category_id: this.filters.category_id
            ? this.filters.category_id
            : null,
          expertise: this.filters.expertise,
          sectors: this.filters.sectors,
          search: this.filters.search,
        })
        .then((response) => {
          this.posts = response.data.data;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
            if (
              response.data.meta.pagination.current_page ===
              response.data.meta.pagination.total_pages
            ) {
              this.nextLink = null;
            }
          }
          this.getPostById();
          this.loadingFeed = false;
        })
        .catch((error) => {
          this.loadingFeed = false;
        });
    },
    getPostById() {
      if (this.$route.params.id && !this.fethedPostsFirstTime) {
        this.fethedPostsFirstTime = true;
        let postFromReply = this.posts.find(
          (p) => p.id == this.$route.params.id
        );
        this.replying = true;
      }
    },
    selectGlobalFilter(filter) {
      this.selectedGlobalFilter = filter;
      this.fetchPosts();
    },
    showMoreSectors() {
      this.showSectorsIndex += 8;
    },
    showMoreExpertise() {
      this.showExpertiseIndex += 8;
    },
    showLessSectors() {
      this.showSectorsIndex = 3;
    },
    showLessExpertise() {
      this.showExpertiseIndex = 3;
    },
    showMore() {
      if (this.nextLink) {
        this.loadingFeed = true;
        gsApi.post
          .index(
            {
              order_by: this.selectedSortCombination.order_by,
              order: this.selectedSortCombination.order,
              user_role:
                this.selectedGlobalFilter === "globalscots" ? "expert" : null,
              is_featured:
                this.selectedGlobalFilter === "featured" ? true : null,
              category_id: this.filters.category_id
                ? this.filters.category_id
                : null,
              expertise: this.filters.expertise,
              sectors: this.filters.sectors,
            },
            this.nextLink
          )
          .then((response) => {
            this.posts = this.posts.concat(response.data.data);
            if (response.data.meta.pagination.links) {
              this.nextLink = response.data.meta.pagination.links.next;
              if (
                response.data.meta.pagination.current_page ===
                response.data.meta.pagination.total_pages
              ) {
                this.nextLink = null;
              }
            }
            this.loadingFeed = false;
          });
      }
    },
    resetFilters() {
      this.filters = {
        sectors: [],
        expertise: [],
        category_id: 0,
        search: this.filters.search,
      };
      this.fetchPosts();
    },
    refineSearch() {
      this.fetchPosts();
      this.slideIsOpen = false;
    },
    resetAllFiltersAndSorting() {
      this.resetFilters();
      this.selectedGlobalFilter = "all";
      this.selectedSort = "newest";
      this.fetchPosts();
    },
    confirmCloseForm() {
      this.$confirm(" Are you sure you want to cancel this post?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.closeForm();
        })
        .catch(() => {});
    },
    closeForm() {
      this.submittingPost = false;
      window.scrollTo(0, 0);
    },
    onEditPost(post) {
      this.postToEdit = post;
      this.editMode = true;
      this.submittingPost = true;
    },
    //--- Remove the post from the list
    onRemovePost(index) {
      this.$delete(this.posts, index);
    },
  },
};
</script>
<style lang="scss">
.members-card-display {
  min-width: 400px;
}
.vertical-line {
  margin: 10px;
}
.pulse-page {
  .el-button--mini {
    padding: 7px 25px;
  }
}
</style>
