<template>
  <div id="beta-bar" v-if="!closed">
    <div class="container">
      <el-row>
          <el-col :md="24" :lg="2"><span class="beta mr-3">BETA</span></el-col>
          <el-col :md="24" :lg="21">
            <p class="first-p">This is a newly launched platform
              <a href="https://www.surveymonkey.co.uk/r/GSBETA" target="_blank" class="feedback-link" aria-label="Opens in a new tab">
                your feedback
                <!-- <svg-link class="ml-1" /> --> <!-- BUGGED - NO HOVER STATE BEING APPLIED WHEN USING SVG COMPONENT -->
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1" role="presentation">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            will help us improve it</p>
          </el-col>
          <el-col :md="24" :lg="1">
            <button class="btn-close" @click="closeBetaBar($event)" @keyup.enter="closeBetaBar($event)" tabindex="0">
              <span class="sr-only">Close beta notice</span>
              <svg-white-close/>
            </button>
          </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import cookieMixin from '@/mixins/cookie-mixin'
import svgLink from '@/components/assets/svg-link'
import svgWhiteClose from '@/components/assets/svg-white-close'

export default {
  data() {
    return {
      closed: true
    };
  },
  components: {
    svgLink,
    svgWhiteClose
  },
  mixins: [cookieMixin],
  mounted() {
    this.closed = this.getCookie('globalscot-beta-bar-closed')
  },
  methods: {
    closeBetaBar () {
      this.setCookie('globalscot-beta-bar-closed', true, 365 * 100);
      this.closed = true
    }
  },
};
</script>

<style lang="scss" scoped>
#beta-bar {
  position: relaitve;
  background: #ffffff;
  color: #000000;
  border-bottom: 1px solid #D9D9D9;
  z-index: 30;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 20px;
  font-weight: 330;
  padding: 10px 0;

  .beta {
    background-color: $primary;
    color: $white;
    font-weight: 420;
    padding: 1px 0px;
    text-align: center;
    width: 74px;
    display: block;
    @media (max-width: 1081px) {
      margin-bottom: 8px;
      display: block;
    }
  }

  p {
    font-size: 19px;
    @media (max-width: 1339px) {
      font-size: 17px;
      margin-top: 2px;
    }
    @media (max-width: 1081px) {
      margin-bottom: 6px;
    }
  }

  .feedback-link {
    color: $primary;
    text-decoration: underline;
    font-weight: 420;
    svg > path {
      stroke: #af006e !important;
    }
  }
  .help {
    a {
      color: $black;
      &:hover {
        text-decoration: underline;
        color: #af006e;
      }
    }
  }
}

.btn-close {
  position: absolute;
  top: 2px;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
  background: transparent;
  @media (max-width: 1081px) {
    top: -66px;
  }
  @media (max-width: 574px) {
    top: -90px;
  }
}
.btn-close:focus {
  svg {
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.first-p {
  @media (max-width: 1339px) {
    margin-left: 10px;
  }
  @media (max-width: 1081px) {
    margin-left: 0px;
  }
}
</style>
