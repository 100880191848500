<template>
  <el-form
    v-loading="loader"
    class="company-form"
    id="companyForm"
    ref="companyForm"
    :model="form"
    :rules="rules"
    autocomplete="off"
    @validate="handleCompanyFormFieldInput"
  >
    <el-form-item
      prop="input_company_house_no"
      class="mb-4 company-house-form-item"
      v-if="!editMode"
    >
      <template slot="label">
        Companies House registration number<span class="is-custom-asterisk"
          >*</span
        ><br />
        <span class="el-form-item__info">
          This will be 2 letters followed by 6 numbers. It must be for an active
          Scottish company, and must start with ‘S’.<br />
          Can’t remember your Companies House registration number? You can look
          up your company on the
          <a
            href="https://beta.companieshouse.gov.uk/search"
            target="_blank"
            class="text__link"
            >Companies House website
            <span class="sr-only">Opens in a new tab</span
            ><svg-link-purple class="ml-1"
          /></a>
          If you don’t have a Companies House number, <a :href="getCMSRoute('contact-us')" class="text__link">get in touch.</a>
        </span>
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('input_company_house_no')"
              v-text="this.getInlineValidationErrorFor('input_company_house_no')"></span>
      </template>

      <div class="d-flex">
        <el-input
          v-model="form.input_company_house_no"
          @blur="
            form.input_company_house_no = form.input_company_house_no.trim()
          "
          id="company_house_no"
          class="half-flex-content"
          maxlength="8"
          label="Please enter your company house number in this field"
          aria-required="true"
          @keyup.enter.native="searchForCompany"
        />
        <div class="mr-3"></div>
        <el-form-item class="half-flex-content">
          <el-button
            size="medium"
            :disabled="
              form.input_company_house_no.length !== 8 ||
                ![
                  'SA',
                  'SC',
                  'SF',
                  'SI',
                  'SL',
                  'SO',
                  'SP',
                  'SR',
                  'SZ'
                ].includes(
                  form.input_company_house_no.substring(0, 2).toUpperCase()
                )
            "
            @click="searchForCompany"
          >
            Find Company
          </el-button>
        </el-form-item>
      </div>
    </el-form-item>
    <el-row v-if="company_found">
      <el-col :md="24" :lg="20" class="mb-4">
        <div class="address-details se-lead-paragraph-l">
          <span class="color-primary">
            {{ form.name }}
          </span>
          <span>
            Incorporated on
            {{ form.incorporation_date | moment("D MMMM YYYY") }}
          </span>
          <span>
            <span v-if="form.address_1">{{ form.address_1 }},</span>
            <span v-if="form.address_2">{{ form.address_2 }},<br></span>
            <span v-if="form.town">{{ form.town }},</span>
            <span v-if="form.county">{{ form.county }},</span>
            <span v-if="form.post_code">{{ form.post_code }}</span>
          </span>
        </div>
      </el-col>
    </el-row>
        <el-form-item v-if="company_found"
          class="mb-4"
          prop="isAddressCorrect"
        >
          <template slot="label">
            Is the above address correct?
          </template>
          <el-radio-group
            v-model="isAddressCorrect"
            aria-label="Is the above address correct? Select Yes or No."
          >
            <el-radio :label="true">
              Yes
            </el-radio>
            <el-radio :label="false">
              No
            </el-radio>
          </el-radio-group>

    </el-form-item>
    <div v-if="!isAddressCorrect">
      <el-form-item prop="address_1" class="mb-4">
        <template slot="label">
          Address line 1
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('address_1')"
                v-text="this.getInlineValidationErrorFor('address_1')"></span>
        </template>
        <el-input
          v-model="form.address_1"
          @blur="form.address_1 = form.address_1.trim()"
          id="address_1"
          maxlength="255"
          autocomplete="off"
          label="Please enter your company address line 1 in this field"
          aria-required="true"
        />
      </el-form-item>
      <el-form-item prop="address_2" class="mb-4">
        <template slot="label">
          Address line 2
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('address_2')"
                v-text="this.getInlineValidationErrorFor('address_2')"></span>
        </template>
        <el-input
          v-model="form.address_2"
          @blur="form.address_2 = form.address_2.trim()"
          id="address_2"
          maxlength="255"
          autocomplete="off"
          label="Please enter your company address line 2 in this field"
        />
      </el-form-item>
      <el-form-item prop="town" class="mb-4">
        <template slot="label">
          Town or City
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('town')"
                v-text="this.getInlineValidationErrorFor('town')"></span>
        </template>
        <el-input
          v-model="form.town"
          @blur="form.town = form.town.trim()"
          id="town"
          maxlength="100"
          autocomplete="off"
          label="Please enter your company town or city in this field"
          aria-required="true"
        />
      </el-form-item>
      <el-form-item prop="county" class="mb-4">
        <template slot="label">
          County
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('county')"
                v-text="this.getInlineValidationErrorFor('county')"></span>
        </template>
        <el-input
          v-model="form.county"
          @blur="form.county = form.county.trim()"
          id="county"
          maxlength="100"
          autocomplete="off"
          label="Please enter your company county in this field"
        />
      </el-form-item>
      <el-form-item prop="post_code" class="mb-4">
        <template slot="label">
          Postcode
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('post_code')"
                v-text="this.getInlineValidationErrorFor('post_code')"></span>
        </template>
        <el-input
          v-model="form.post_code"
          @blur="form.post_code = form.post_code.trim()"
          id="post_code"
          maxlength="10"
          autocomplete="off"
          label="Please enter your company postcode in this field"
          aria-required="true"
        />
      </el-form-item>
    </div>
    <el-form-item prop="role" class="mb-4">
      <template slot="label">
        Your role at the company
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('role')"
              v-text="this.getInlineValidationErrorFor('role')"></span>
      </template>
      <el-input
        v-model="form.role"
        @blur="form.role = form.role.trim()"
        id="role"
        autocomplete="off"
        label="Please enter your role at the company"
        aria-required="true"
      />
    </el-form-item>
    <el-form-item prop="group_ids" class="is-no-asterisk">
      <template slot="label">
        <span class="el-form-item__label"
          >Your company's sector<span class="is-custom-asterisk">*</span></span
        ><br />
        <span class="se-form-label-l-screen color-light-gray d-block mb-4">
          You can select multiple sectors
        </span>
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('group_ids')"
              v-text="this.getInlineValidationErrorFor('group_ids')"></span>
      </template>
      <el-checkbox-group
        v-model="form.group_ids"
        aria-label="Please check one or more sectors"
      >
        <el-row>
          <el-col
            :md="24"
            :lg="12"
            class="mb-4"
            :key="index"
            v-for="(group, index) in groups"
          >
            <el-checkbox
              name="group_ids"
              :label="group.id"
              @change="selectedGroupsUpdated(group.id)"
            >
              {{ group.name }}
            </el-checkbox>
          </el-col>
        </el-row>
      </el-checkbox-group>
    </el-form-item>
    <el-row v-if="availableSubgroups.length > 0">
      <el-col :md="24" :lg="20">
        <el-form-item prop="sub_group_ids">
          <template slot="label">
            <span class="el-form-item__label" aria-hidden="true"
              >Your company's sub sectors - Optional</span
            >
            <span class="sr-only">Please select one or more sub sectors</span>
            <span class="sr-only"
                  aria-live="polite"
                  :hidden="! this.hasInlineValidationErrorFor('sub_group_ids')"
                  v-text="this.getInlineValidationErrorFor('sub_group_ids')"></span>
          </template>
          <v-select
            v-model="form.sub_group_ids"
            :options="availableSubgroups"
            :multiple="true"
            :clearable="false"
            label="name"
            :reduce="subGroup => subGroup.id"
            inputId="sub_group_ids"
            autocomplete="off"
            :select-on-tab="true"
            :close-on-select="false"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <div class="d-flex form-actions">
      <el-button class="el-button btn-cancel" @click="onCancel">
        Cancel
      </el-button>
      <el-button type="primary" class="btn-submit" @click="onSubmit">
        Save changes
      </el-button>
    </div>
  </el-form>
</template>
<script>
import gsApi from "@/services/gs.api";
import { mapGetters } from "vuex";
import svgLinkPurple from "@/components/assets/svg-link-purple";
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  mixins: [inlineValidationErrorsMixin],
  components: {
    svgLinkPurple
  },
  props: {
    editMode: Boolean,
    form: Object,
    groups: {
      type: Array,
      default: () => []
    },
    subGroups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let validateCompanyHouseNo = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Enter a valid Companies House registration number. It should contain 2 letters and 6 numbers."
          )
        );
      } else if (
        !["SA", "SC", "SF", "SI", "SL", "SO", "SP", "SR", "SZ"].includes(
          value.substring(0, 2).toUpperCase()
        )
      ) {
        callback(
          new Error(
            "It looks like that number is for a company based outside of Scotland. Check the number you’ve entered or add a different company."
          )
        );
      } else {
        callback();
      }
    };
    return {
      company_found: false,
      loader: false,
      isAddressCorrect: true,
      availableSubgroups: [],
      rules: {
        name: [
          {
            required: true,
            message: "Enter your company name",
            trigger: "blur"
          }
        ],
        post_code: [
          { required: true, message: "Enter a postcode", trigger: "blur" },
          {
            max: 10,
            message: "Postcode may not be greater than 10 characters",
            trigger: "blur"
          }
        ],
        address_1: [
          {
            required: true,
            message: "Enter the first line of your address",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Address line 1 may not be greater than 255 characters",
            trigger: "blur"
          }
        ],
        address_2: [
          {
            max: 255,
            message: "Address line 2 may not be greater than 255 characters",
            trigger: "blur"
          }
        ],
        town: [
          { required: true, message: "Enter a town or city", trigger: "blur" },
          {
            max: 100,
            message: "Town or City may not be greater than 100 characters",
            trigger: "blur"
          }
        ],
        county: [
          {
            max: 100,
            message: "County may not be greater than 100 characters",
            trigger: "blur"
          }
        ],
        role: [
          {
            required: true,
            message: "Enter your role within the company",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Role may not be greater than 255 characters",
            trigger: "change"
          }
        ],
        group_ids: [
          {
            required: true,
            type: "array",
            message: "Select a sector",
            trigger: "change"
          }
        ],
        input_company_house_no: [
          { validator: validateCompanyHouseNo, required: true }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["businesses"])
  },
  methods: {
    onSubmit() {
      this.$refs.companyForm.validate(valid => {
        if (valid) {
          this.loader = true;
          if (this.editMode) {
            gsApi.member.user
              .updateCompany(this.form)
              .then(({ data }) => {
                this.$store.commit("UPDATE_BUSINESSES", data.businesses.data);
                this.$alert(
                  "You successfully updated your company",
                  "Success",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
                this.onCancel();
              })
              .catch(response => {
                if (response.status == 422) {
                  let firstError =
                    response.data.errors[Object.keys(response.data.errors)[0]];
                  this.$alert(firstError[0], "Error", {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  });
                } else {
                  this.$alert(
                    "Sorry, your request could not be processed",
                    `Error ${error.response.status} - ${error.response.data.message}`,
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal"
                    }
                  );
                }
              })
              .finally(() => {
                this.loader = false;
              });
          } else {
            gsApi.member.user
              .addCompany({ businesses: [this.form] })
              .then(({ data }) => {
                this.$store.commit("UPDATE_BUSINESSES", data.businesses.data);
                this.$alert("You successfully added a new company", "Success", {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                });
                this.onCancel();
              })
              .catch(error => {
                if (error.response.status == 422) {
                  let firstError =
                    error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ];
                  this.$alert(firstError[0], "Error", {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  });
                } else {
                  this.$alert(
                    "Sorry, your request could not be processed",
                    `Error ${error.response.status} - ${error.response.data.message}`,
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal"
                    }
                  );
                }
              })
              .finally(() => {
                this.loader = false;
              });
          }
        } else {
          this.$nextTick(() => {
            document
              .getElementById("companyForm")
              .getElementsByClassName("is-error")[0]
              .scrollIntoView();

            let text = document
              .getElementById("companyForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;

            this.$announcer.set(text);
          });
        }
      });
    },
    onCancel() {
      this.$emit("onCancel");
    },
    selectedGroupsUpdated(groupId) {
      if (this.form.group_ids.includes(groupId)) {
        this.availableSubgroups = this.subGroups.filter(subGroup =>
          this.form.group_ids.includes(subGroup.group_id)
        );
      } else {
        // Removing selected sub groups where sub group is about to be removed below
        let subGroupsToBeRemoved = this.subGroups.filter(
          item => item.group_id == groupId
        );
        subGroupsToBeRemoved.forEach(subgroup => {
          if (this.form.sub_group_ids.includes(subgroup.id)) {
            let index = this.form.sub_group_ids.indexOf(subgroup.id);
            this.form.sub_group_ids.splice(index, 1);
          }
        });

        // Removing sub groups with group_id of selected group
        let newSubgroups = this.availableSubgroups.filter(
          item => item.group_id !== groupId
        );
        this.availableSubgroups = newSubgroups;
      }
    },
    formatCompanyHouseNoForRequest() {
      const prefix1 = this.form.input_company_house_no.charAt(0);
      const prefix2 = this.form.input_company_house_no.charAt(1);

      return this.form.input_company_house_no.replace(
        `${prefix1}${prefix2}`,
        `${prefix1.toUpperCase()}${prefix2.toUpperCase()}`
      );
    },
    searchForCompany() {
      if (
        this.businesses.length > 0 &&
        this.businesses.find(
          b => b.company_house_no === this.form.input_company_house_no
        )
      ) {
        this.$alert("You have already added this company.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
        return;
      }

      this.loader = true;

      gsApi.companyHouse
        .getForNumber(this.formatCompanyHouseNoForRequest())
        .then(response => {
          if (
            (response.data.status && response.data.status == "active") ||
            (response.data.status && response.data.status == "Active")
          ) {
            this.loader = false;

            this.form.name = response.data.name ? response.data.name : "";
            this.form.address_1 = response.data.address_line_1
              ? response.data.address_line_1
              : "";
            this.form.address_2 = response.data.address_line_2
              ? response.data.address_line_2
              : "";
            this.form.post_code = response.data.postcode
              ? response.data.postcode
              : "";
            this.form.town = response.data.city ? response.data.city : "";
            this.form.county = response.data.region ? response.data.region : "";
            this.form.company_house_no = response.data.company_number
              ? response.data.company_number
              : "";
            this.form.input_company_house_no = response.data.company_number
              ? response.data.company_number
              : "";

            this.form.incorporation_date = response.data.date_of_creation
              ? response.data.date_of_creation
              : "";
            (this.isAddressCorrect = true), (this.company_found = true);
          } else {
            this.loader = false;
            this.form.company_house_no = response.data.company_number
              ? response.data.company_number
              : "";
            this.form.input_company_house_no = response.data.company_number
              ? response.data.company_number
              : "";

            this.company_found = false;
            this.$alert(
              "We couldn’t find a company matching that registration number.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        })
        .catch(error => {
          this.company_found = false;
          this.loader = false;
          this.form.company_house_no = "";
          this.form.input_company_house_no = "";

          if (
            error.response &&
            error.response.status == 404 &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
          ) {
            this.$alert(
              "We couldn’t find a company matching that registration number.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          } else {
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        });
    },
    handleCompanyFormFieldInput() {
      this.handleSingleFormFieldInput('companyForm')
    }
  },
  created() {
    this.availableSubgroups = this.subGroups.filter(subGroup =>
      this.form.group_ids.includes(subGroup.group_id)
    );
  }
};
</script>
<style lang="scss">
.address-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-left: 1px solid #000000;
  padding-left: 25px;
}
</style>
