<template>
  <div class="verify-email" v-loading="emailExpired == null || isLoading">
    <div class="red-banner">
      <h1>ACTIVATE YOUR ACCOUNT</h1>
    </div>
    <div class="verify-block">
      <div class="email-icon">
        <svg-app-email></svg-app-email>
        <div class="notifications-count" v-if="notifications_count > 0">
          {{ notifications_count }}
        </div>
      </div>

      <p class="wrong-email-txt">
        Please provide your current password and confirm-it
      </p>
      <el-row type="flex" justify="center">
        <el-col :xl="17" :lg="17" :md="18" :sm="24" :xs="24">
          <el-form
            :model="activateForm"
            :rules="rules"
            ref="activateForm"
            v-if="emailExpired == false"
          >
            <el-form-item>
              <el-col :span="24">
                <el-form-item
                  label="Enter your current password"
                  prop="password"
                >
                  <el-input
                    type="password"
                    id="password"
                    placeholder="Enter your current password"
                    v-model="activateForm.password"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item class="mb-3">
              <el-col :span="24">
                <el-form-item
                  label="Please confirm your password"
                  prop="confirmPassword"
                >
                  <el-input
                    type="password"
                    id="password"
                    placeholder="Please confirm your password"
                    v-model="activateForm.confirmPassword"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-row>
              <el-col :span="24">
                <el-button
                  type="button"
                  id="submit"
                  @click="submitForm('activateForm')"
                  :loading="isLoading"
                  >Verify my new email</el-button
                >
              </el-col>
            </el-row>
          </el-form>
          <div v-else-if="emailExpired == true" class="email-expired">
            {{ message }}
            <a :href="getCMSHome()">
              <el-button
                type="button"
                class="mt-3"
                >Go to home</el-button>
            </a>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import Email from "../../components/assets/svg-blue-email";
import { mapState } from "vuex";
export default {
  name: "verify-email",
  metaInfo: {
    title: "Verify email"
  },
  computed: {
    ...mapState(["user"])
  },
  components: {
    "svg-app-email": Email
  },
  created() {
    this.checkActivityEmailExpiration();
  },
  data() {
    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter your current password"));
      } else {
        callback();
      }
    };
    let validatePasswordConfirmation = (rule, value, callback) => {
      if (value === "" || value !== this.activateForm.password) {
        callback(
          new Error(
            "This doesn't match the password you entered. Try confirming your password again."
          )
        );
      } else {
        callback();
      }
    };

    return {
      emailExpired: null,
      notifications_count: 1,
      activateForm: {
        password: "",
        confirmPassword: ""
      },
      rules: {
        password: [{ validator: validatePassword, required: true }],
        confirmPassword: [
          { validator: validatePasswordConfirmation, required: true }
        ]
      },
      isLoading: false,
      message: "Error when trying to get your activation code"
    };
  },
  methods: {
    checkActivityEmailExpiration() {
      if (!this.$route.query.code) {
        this.emailExpired = true;
        return;
      }
      this.isLoading = true;
      gsApi.users
        .checkActivationEmailExpiration({ code: this.$route.query.code })
        .then(response => {
          this.emailExpired = response.data.success;
          if (response.data.message) {
            this.message = response.data.message;
          }
        })
        .catch(exception => {
          this.emailExpired = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitForm() {
      this.isLoading = true;
      this.$refs.activateForm.validate(valid => {
        if (valid) {
          gsApi.users
            .activateNewEmail({
              code: this.$route.query.code,
              password: this.activateForm.password
            })
            .then(response => {
              this.isLoading = false;
              if (response.data.success) {
                this.$alert(response.data.message, "Success", {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                });
                this.redirectToLogin();
              } else {
                this.$alert(response.data.message, "Error", {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                });
              }
            })
            .catch(exception => {
              this.isLoading = false;
              this.$alert(
                "Please , try again in few seconds or contact us",
                "Sorry, your request could not be processed",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            });
        } else {
          this.isLoading = false;
          return false;
        }
      });
    },
    redirectToLogin() {
      if (this.user.isLogged) {
        gsApi.auth.logout(this.user.token).then(() => {
          store.commit("USER_LOGOUT");
          this.$nextTick(() => {
            this.$router.push("/login");
          });
        });
      } else {
        this.$router.push("/login");
      }
    }
  }
};
</script>
<style scoped>
.email-expired {
  padding: 30px 0;
  font-size: 20px;
  color: #db0011;
}
</style>
