<template>
  <slide-panel title="Menu" @panelclose="closeMenu">
    <div class="hr mb-3"></div>
    <div
      class="profile-section justify-content-between d-flex"
      @click="goToProfile()"
    ></div>

    <ul class="menu-items mt-3">
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Home', params: { isActiveFilter: 1 } }">
          {{ user.isLogged ? "Feed" : "Home" }}
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'About Network' }">
          About Network
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Become GlobalScot' }">
          Become a GlobalScot
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'About' }">
          About our organisation
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Case Studies Public' }">
          Case studies
        </router-link>
      </li>

      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Business Events Webinars' }">
          Events & webinars
        </router-link>
      </li>

      <li class="el-menu-item" @click="closeMenu">
        <a :href="getCMSRoute('contact-us')"> Contact us </a>
      </li>
    </ul>

    <div class="hr mt-3 mb-3"></div>

    <ul class="menu-links">
      <li @click="closeMenu">
        <router-link
          v-if="
            !user.isLogged &&
            !userRequiresVerification &&
            $route.path !== '/sign-up-select' &&
            $route.name !== 'NotFound' &&
            $route.name !== 'Reset Password'
          "
          class="el-button el-button--primary el-button--medium"
          to="/sign-up-select"
          tabindex="0"
        >
          Register
        </router-link>
      </li>
      <li @click="closeMenu">
        <a
          v-if="
            !user.isLogged &&
            !userRequiresVerification &&
            $route.path !== '/login' &&
            $route.name !== 'NotFound' &&
            $route.name !== 'Reset Password'
          "
          role="button"
          class="el-button el-button--medium"
          tabindex="0"
          @click="redirectToLogin"
        >
          Log in
        </a>
      </li>
      <li @click="closeMenu">
        <el-button
          v-if="
            (user.isLogged || userRequiresVerification) &&
            $route.name !== 'Reset Password'
          "
          class="el-button el-button--medium"
          tabindex="0"
          @click="logout($event)"
        >
          Logout
        </el-button>
      </li>
    </ul>
  </slide-panel>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Feed from "@/components/assets/svg-feed";
import Event from "@/components/assets/svg-event";
import News from "@/components/assets/svg-news";
import NewsPurple from "@/components/assets/svg-news-purple";
import Pulse from "@/components/assets/svg-pulse";
import Chat from "@/components/assets/svg-chat-menu";
import FindExpert from "@/components/assets/svg-find-expert";
import SlidePanel from "@/components/slide-panel/slide-panel";
import Challenges from "@/components/assets/svg-challenges-menu";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";

export default {
  name: "MobileMenu",
  components: {
    "svg-feed": Feed,
    "svg-chat": Chat,
    "svg-event": Event,
    "svg-news": News,
    "svg-news-purple": NewsPurple,
    "svg-pulse": Pulse,
    "svg-challenges": Challenges,
    "svg-find-expert": FindExpert,
    "svg-purple-arrow-right": PurpleArrowRight,
    "slide-panel": SlidePanel,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["userRequiresVerification"]),
    isGlobalScot() {
      return this.user.expertProfile && this.user.roles.includes("expert");
    },
  },
  methods: {
    goToProfile() {
      if (this.isGlobalScot) {
        this.closeMenu();
        this.$router.push({
          name: "GlobalScot Profile",
          params: { id: this.user.id },
        });
      } else {
        this.closeMenu();
        this.$router.push({
          name: "NonGlobalScot Profile",
          params: { id: this.user.id },
        });
      }
    },
    closeMenu() {
      this.$emit("close");
    },
    redirectToLogin() {
      const redirectTo =
        this.$route.name == "Public Events"
          ? `/events/${this.$route.params.slug}`
          : this.$route.name == "Public Articles"
          ? `/news/${this.$route.params.slug}`
          : null;
      this.$router.push({
        name: "Login",
        query: redirectTo ? { redirect: redirectTo } : null,
      });
    },
  },
};
</script>
