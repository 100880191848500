<template>
    <div class="el-row m-0">
        <div class="members-list-bar el-col-24 el-col-lg-7 px-3">
            <div class="el-row m-0 h-100">
                <div class="el-col-24 el-col-lg-7 manage-chat-members m-0 py-1">
                    <span class="pt-1 d-inline-block manage-chat-members-title">Manage chat members</span>
                    <span @click="resetAll(); $parent.hideMembersBar()" class="svg-close float-right pr-4"><svg-close/></span>
                </div>
                <div class="el-col-24 search-add-member">
                    <span class="font-bold add-member-title">Add member from your following list</span>

                    <div class="search-member">
                        <el-form class="mt-2">
                            <el-form-item>
                                <el-input @input="filterMemberListByName" placeholder="" v-model="search_member"/>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="add-member d-flex flex-column px-2">
                        <div class="add-member-item d-flex align-items-center py-2"
                             v-for="(member, index) in filtered_non_member_arr" :key="index">
                            <member-avatar :member="member"></member-avatar>
                            <div class="member-item-name-position pl-3">
                                <div class="name">{{member.first_name}} {{member.last_name}}</div>
                                <div class="position" v-if="member.primary_company">{{member.primary_company.name}}
                                </div>
                            </div>
                            <div @click="addMember({member_id: member.id, role: 1})"
                                 class="member-item-add ml-auto pb-4">
                                Add
                            </div>
                        </div>
                    </div>
                </div>

                <div class="existing-members el-col-24">
                    <div class="existing-members-title mb-4">Existing members</div>

                    <div class="existing-member-items">
                        <div class="existing-member-item d-flex align-items-center py-3" v-for="member in member_arr">
                            <member-avatar :member="member"></member-avatar>
                            <div class="member-item-name pl-3">{{member.first_name}} {{member.last_name}}</div>
                            <div @click="removeMember(member.id)" class="member-item-remove ml-auto">Remove</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    import gsApi from '@/services/gs.api'
    import MemberAvatar from './avatars/member-avatar'
    import SvgCloseCross from '@/components/assets/svg-close-cross'

    export default {
        components: {
            'svg-close': SvgCloseCross,
            'member-avatar': MemberAvatar,
        },
        data: () => ({
            search_member: '',
            non_member_arr: [],
            member_arr: [],
            filtered_non_member_arr: [],
        }),
        created() {
            this.getNonMembers();
            this.getMembers();
        },
        methods: {
            getNonMembers() {
              gsApi.chat.getNonMembersList(this.$parent.$parent.selectedChat.id)
              .then((response) => {
                  if (response && response.data) {
                      this.non_member_arr = response.data.data
                      this.filtered_non_member_arr = response.data.data
                  }
              })
            },

            getMembers() {
              gsApi.chat.getNonMembersList(this.$parent.$parent.selectedChat.id)
              .then((response) => {
                  if (response && response.data) {
                      this.member_arr = response.data.data
                  }
              })
            },

            addMember(member) {
              gsApi.chat.addMember(this.$parent.$parent.selectedChat.id, {...member})
              .then((response) => {
                  if (response && response.statusText === 'OK') {
                      const index = _.findIndex(this.non_member_arr, {id: member.member_id})
                      const memberItem = _.head(this.non_member_arr.splice(index, 1))
                      this.member_arr.push(memberItem)
                      this.$parent.$parent.addChatMemberItem(memberItem)
                  }
              })
            },

            removeMember(member_id) {
              gsApi.chat.removeMember(this.$parent.$parent.selectedChat.id, member_id)
              .then((response) => {
                  if (response && response.statusText === 'OK') {
                      const index = _.findIndex(this.member_arr, {id: member_id})
                      const memberItem = _.head(this.member_arr.splice(index, 1))
                      this.non_member_arr.push(memberItem)
                      this.$parent.$parent.removeChatMemberItem(memberItem)
                  }
              })
            },

            filterMemberListByName() {
                let filtered_member_arr = [];

                _.each(this.non_member_arr, (memberObj) => {
                    if (_.includes(memberObj.first_name, this.search_member) || _.includes(memberObj.last_name, this.search_member)) {
                        filtered_member_arr.push(memberObj);
                    }
                });

                this.filtered_non_member_arr = filtered_member_arr;
            },

            resetAll() {
                this.search_member = '';
                this.member_arr = [];
                this.non_member_arr = [];
            },
        }
    }
</script>
