<template>
  <div class="user-avatar" :style="{ width: width }" :class="onlyChevron ? 'only-chevron' : 'banner'">
    <div
      v-if="isGlobalScot && !onlyChevron"
      class="gs-chevron d-flex align-items-center"
      :class="chevronClass"
      :style="{ transform: transform }"
    >
      <svg-gs-chevron></svg-gs-chevron>GLOBALSCOT<span class="sr-only">GLOBALSCOT</span>
    </div>
    <el-avatar
      :shape="shape"
      :size="size"
      :src="src ? src : authorExist ? defaultSrc : '/assets/img/no-avatar.png'"
      :alt="alt"
      :class="[avatarClasses, { 'global-scot': isGlobalScot }]"
    >
      <img :src="defaultSrc" />
    </el-avatar>
    <span
      v-if="isGlobalScot && onlyChevron"
      class="gs-chevron"
      :class="chevronClass"
      :style="{ transform: transform }"
      ><svg-gs-chevron></svg-gs-chevron
    ></span>
  </div>
</template>
<script>
import SvgGsChevron from "@/components/assets/svg-gs-chevron";
export default {
  components: {
    SvgGsChevron,
  },
  props: {
    authorExist: { type: Boolean, default: true },
    shape: String,
    size: Number,
    src: String,
    alt: String,
    defaultSrc: {
      type: String,
      default: "/assets/img/default-image.png",
    },
    isGlobalScot: { type: Boolean, default: false },
    avatarClasses: String,
    chevronClass: String,
    width: String,
    onlyChevron: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    transform() {
      if (this.onlyChevron)
        return `translateY(${this.size * 3}%) translateX(-74%)`;
      return `translateY(${this.size / 4.3}%) translateX(-13%)`;
    },
  },
};
</script>