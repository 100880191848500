<template>
  <div
    class="nominate-globalscot-form"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <Trap>
      <div class="container d-flex align-items-center justify-content-between">
        <h2 id="title" class="title focus" autofocus tabindex="0">Nominate a GlobalScot</h2>
      </div>

      <hr class="mt-5" />

      <div class="container">
        <el-row>
          <el-col :lg="24">
            <h3 class="nominate-globalscot-details-title">Nomination details</h3>
          </el-col>
        </el-row>

        
          <el-row>
            <el-col :lg="12">
              <p class="mb-3">
                If you know someone you think would be a good candidate to be a
                GlobalScot, please provide their details below for the team to
                review and possibly get in touch with them.
              </p>
              <validation-errors
                v-if="validationErrors.length"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                :model="nominateForm.form"
                :rules="nominateForm.rules"
                ref="nominateForm"
                id="nominateForm"
                @submit.native.prevent
                @validate="handleNominateFormFieldInput"
              >
                <el-form-item prop="name">
                  <template slot="label">
                    <span>Nomination name</span>
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!this.hasInlineValidationErrorFor('name')"
                      v-text="this.getInlineValidationErrorFor('name')"
                    ></span>
                  </template>

                  <el-input
                    v-model="nominateForm.form.name"
                    id="name"
                    placeholder
                    aria-required="true"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="email">
                  <template slot="label">
                    <span>Email address</span>
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!this.hasInlineValidationErrorFor('email')"
                      v-text="this.getInlineValidationErrorFor('email')"
                    ></span>
                  </template>

                  <el-input
                    v-model="nominateForm.form.email"
                    id="email"
                    placeholder
                    aria-required="true"
                  ></el-input>
                </el-form-item>

                <el-form-item prop="about_globalscot">
                  <template slot="label">
                    <span>Tell us why you think they’d be a good GlobalScot</span>
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!this.hasInlineValidationErrorFor('about_globalscot')"
                      v-text="this.getInlineValidationErrorFor('about_globalscot')"
                    ></span>
                  </template>

                  <el-input
                    v-model="nominateForm.form.about_globalscot"
                    placeholder
                    id="about_globalscot"
                    type="textarea"
                    :rows="5"
                    maxlength="1000"
                    aria-required="true"
                  ></el-input>
                </el-form-item>
                <div class="col-24 mt-n4 mb-4">
                  <span class="se-body-l-screen color-light-gray"
                    >You have
                    {{ 1000 - nominateForm.form.about_globalscot.length }} characters
                    remaining</span
                  >
                </div>

                <el-form-item prop="locations" class="is-no-asterisk form-group">
                  <template slot="label">
                    <span>Location</span><span class="is-custom-asterisk">*</span><br />
                    <span class="el-form-item__info"
                      >You can select more than one</span
                    >
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!this.hasInlineValidationErrorFor('locations')"
                      v-text="this.getInlineValidationErrorFor('locations')"
                    ></span>
                  </template>

                  <v-select
                    v-model="nominateForm.form.locations"
                    :options="countries"
                    :multiple="true"
                    :clearable="false"
                    label="name"
                    :reduce="country => country.id"
                    inputId="locations"
                    autocomplete="false"
                    :select-on-tab="true"
                    :close-on-select="false"
                    id="select-locations"
                  />
                </el-form-item>

                <el-form-item prop="linkdin_profile">
                  <template slot="label">
                    <span>LinkedIn profile</span><br />
                    <span class="el-form-item__info"
                      >Please provide a link to their LinkedIn profile</span
                    >
                  </template>

                  <el-input
                    v-model="nominateForm.form.linkdin_profile"
                    placeholder
                    id="linkdin_profile"
                  ></el-input>
                </el-form-item>
                <div class="d-flex justify-content-center">
                  <el-button @click="onDialogClose()" class="submit-btn">
                    Cancel
                  </el-button>
                  <el-button
                    @click="submitNomination()"
                    class="submit-btn"
                    type="primary"
                    >Submit nomination</el-button
                  >
                </div>
              </el-form>
            </el-col>
          </el-row>
        
      </div>
    </Trap>
  </div>
</template>

<script>
import Trap from "vue-focus-lock";
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import {validate} from 'email-validator'
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";

export default {
  mixins: [inlineValidationErrorsMixin],
  name: "nominate-globalscot-component",
  components: {
    Trap: Trap,
    validationErrors,
  },
  watch: {
    'nominateForm.form.locations'(val) {      
      const selections = []
      val.forEach((id) => {
        selections.push(this.countries[id - 1].name)
      })

      if(val.length > 0) {
        this.$announcer.set(`You have selected the following options for locations: ${selections.join(', ')}`, 'polite')
      }
      else {
        this.$announcer.set('You have selected no location options', 'polite')
      }
    }
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Enter a valid email address'))
      } else if (validate(value) == false) {
        callback(new Error('Enter a valid email address'))
      } else {
        callback()
      }
    }
    const validateLinkdinProfile = (rule, value, callback) => {
      let reg = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm

      if ( value!='' && reg.test(value)==false) {
        callback(new Error('LinkedIn profile address must follow this structure: https://www.linkedin.com/in/username/'))
      } else {
        callback()
      }
    }
    return {
      validationErrors: [],
      countries:[],
      nominateForm: {
        form:{
          name: "",
          email: "",
          about_globalscot: "",
          locations: [],
          linkdin_profile:'',
        },

        rules: {
          name: [
            { required: true, message: 'Enter the globalscot full name', trigger: 'blur' },
            { max: 128, message: 'full name may not be greater than 64 characters', trigger: 'blur' },
          ],
          email: [
            { validator: validateEmail, required: true },
          ],
          about_globalscot: [
            {
              required: true,
              message: "Please enter your message",
              trigger: "blur"
            },
            {
              max: 1000,
              message: "Message should not be longer than 1000 characters",
              trigger: "change"
            }
          ],
          locations:[
            { type: 'array', required: true, message: 'Select a location from the dropdown', trigger: 'change' }
          ],
          linkdin_profile: [
            { validator: validateLinkdinProfile },
          ],
        }
      },
      mainLoader: false
    };
  },
  created(){
    this.getRegisterMeta()
  },
  methods: {
    submitNomination() {
      this.$refs.nominateForm.validate(valid => {
        if (valid) {
          this.mainLoader = true;

          gsApi.globalscot.nominateGlobalscot(this.nominateForm.form)
          .then((response) => {
            this.mainLoader = false;
            this.validationErrors = [];

            this.onDialogClose();

            this.$alert(
              response.data.message,
                "Success",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
            )
          })
          .catch(error => {
            this.validationErrors = [];
            this.mainLoader = false;

            if (error.response && error.response.status == 422 && error.response.data) {
              let errors = error.response.data.errors;
              for (let [key, value] of Object.entries(errors)) {
                let obj = {};
                obj.id = key;
                obj.message = value[0];
                this.validationErrors.push(obj);
              }
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else if (error.response && error.response.status == 403 && error.response.data) {
              this.onDialogClose();
              this.$alert(
                error.response.data.error.message,
                "Error",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            } else {
              this.$alert(
                "There are errors in the form, please fix these errors and try again.",
                "Error",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            }
          });
        } else {
          this.validationErrors = [];
          this.$refs.nominateForm.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("nominateForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    onDialogClose() {
      this.validationErrors= [];
      this.$refs.nominateForm.resetFields();
      this.$emit("close-nominate-gs-modal");
    },

    getRegisterMeta() {
        this.mainLoader = true

        gsApi.member.meta.index()
        .then(response => {
          this.mainLoader = false
          this.countries = response.data.countries.data
        }).catch(error => {
          this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.gotoLink('/home')
        })
    },
    handleNominateFormFieldInput() {
      this.handleSingleFormFieldInput('nominateForm');
    }  

  }
};
</script>
<style>
  .focus:focus {
    outline: black solid 1px;
    padding: 15px 0;
  }
</style>