<template>
  <el-card class="mb-5 relative">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="hrc-pill">Help Req.</div>
        <p class="sent-to-text" v-text="activity.type_id === '1'? 'received' : 'sent to'"></p>
      </div>
      <activity-menu-dropdown v-if="isProfileOwner" classes="activity-menu-position" @delete="$emit('deleteActivity', activity.id)" />
    </div>

    <div class="d-flex mt-4" v-if="activity.type_id === '1'">
      <user-avatar
        shape="circle"
        :isGlobalScot="isGlobalScot(activity.trackable.trader)"
        :authorExist="true"
        width="40px"
        :size="40"
        :src="activity.trackable.trader.avatar"
        alt="Profile picture"
      ></user-avatar>
      <div>
        <p class="name-text ml-3" v-line-clamp="1">{{ activity.trackable.trader.full_name }}</p>
        <p class="role-text" v-line-clamp="1">
          {{ getNgsRole(activity.trackable) }}
          <span class="vertical-line">
            <svg-vertical-line aria-labeledby="vertical line"></svg-vertical-line>
          </span>
          {{ getNgsBusiness(activity.trackable) }}
        </p>
      </div>
    </div>
    <div class="d-flex mt-4" v-if="activity.type_id === '0'">
      <user-avatar
        shape="circle"
        :isGlobalScot="isGlobalScot(activity.trackable.expert)"
        :authorExist="true"
        width="40px"
        :size="40"
        :src="activity.trackable.expert.avatar"
        alt="Profile picture"
      ></user-avatar>
      <div>
        <p class="name-text ml-3" v-line-clamp="1">{{ activity.trackable.expert.full_name }}</p>
        <p class="role-text" v-line-clamp="1">
          {{ primaryBusiness(activity.trackable.expert).role }}
          <span class="vertical-line">
            <svg-vertical-line aria-labeledby="vertical line"></svg-vertical-line>
          </span>
          {{ primaryBusiness(activity.trackable.expert).name }}
        </p>
      </div>
    </div>
    <div class="activity-hr-card-footer mt-5">
      <p class="ago-text">{{ agoTime }} ago</p>
      <p>
        <a v-if="activity.trackable.accepted_at !== null" href="#" @click.prevent="handleViewClick"
           class=" mt-5 mb-3 view-link">
          View details
          <svg-purple-arrow-right class="arrow ml-2"></svg-purple-arrow-right>
        </a>
        <span v-else-if="activity.trackable.declined_at !== null" class="float-right view-link text-decoration-none d-block">Declined</span>
        <span v-else class="float-right view-link text-decoration-none d-block">Awaiting response</span>
      </p>
    </div>

  </el-card>
</template>

<script>
import UserAvatar from '@/components/core/user-avatar'
import moment from 'moment'
import store from '@/store'
import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
import VerticalLine from '@/components/assets/svg-vertical-line'
import ActivityMenuDropdown from "@/components/partials/recent-activity-cards/activity-menu-dropdown.vue";

export default {
  name: 'help-request-card',
  components: {
    'svg-purple-arrow-right': PurpleArrowRight,
    'svg-vertical-line': VerticalLine,
    UserAvatar,
    'activity-menu-dropdown': ActivityMenuDropdown
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    isProfileOwner: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    agoTime () {
      return moment(this.activity.created_at).fromNow(true)
    },
  },
  methods: {
    handleViewClick () {
      this.$store.commit('UPDATE_SELECTED_CHAT', this.activity.trackable.chat);
      this.$emit('navigatedToChat');
      this.$router.push({name: 'My Chats'})
    },
    isGlobalScot (profile) {
      return profile.roles.includes('expert')
    },
    primaryBusiness (expert) {
      return expert.businesses.data.filter(b => b.is_primary === 1)[0]
    },
    getNgsRole (request) {
      if (request.trader.roles.includes('staff')) {
        return 'Team Scotland'
      }

      return request.business.role
    },
    getNgsBusiness (request) {
      if (request.trader.roles.includes('staff')) {
        return request.team_scotland_business
      }

      return request.business.name
    },
  }
}
</script>

<style lang="scss" scoped>
.view-link {
  font-size: 19px;
  font-weight: 420;
  text-decoration: underline;
  color: $primary;
  @media screen and (max-width: 425px) {
    font-size: 1rem;
  }
}

.name-text {
  font-size: 30px;
  font-weight: 420;
}

.role-text {
  margin-left: 88px;
  margin-top: -32px;
  font-size: 19px;
  font-weight: 390;
}

.recent-activity-section {
  .role-text {
    margin-left: 1rem;
    margin-top: 0;
    font-size: 1.1875rem;
    font-weight: 390;
    @media screen and (max-width: 425px) {
      font-size: .875rem;
    }

    .view-link {
      @media screen and (max-width: 425px) {
        font-size: 1rem;
      }
    }
  }

  .vertical-line {
    margin: 0 5px;
  }
}

/*.hrc-avatar {
  width: 74px;
  height: 74px;
}*/

.ago-text {
  font-size: 17px;
  font-weight: 390;
  color: #585858;
  margin-top: -2px;
}

.sent-to-text {
  font-size: 19px;
  font-weight: 400;
  margin-left: 10px;
  color: $black;
  margin-top: -2px;
}

.hrc-pill {
  font-size: 16px;
  font-weight: 420;
  padding: 1px 12px;
  background-color: #F7F248;
  border-radius: 25px;
}

.vertical-line {
  margin-top: 0px;
}
.name-text {
  font-size: 1.875rem;
  font-weight: 420;
  @media screen and (max-width: 425px) {
    font-size: 1rem;
  }
}
</style>
