<template>
  <div class="footer-join">
    <div class="container">
      <el-row>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="head">
          <div class="text-white footer-text">
            {{ footerText.heading }}
          </div>
        </el-col>
        <a :href="getCMSRoute('contact-us')">
          <el-button class="join-btn-bottom" type="primary"
            >Contact GlobalScot team</el-button
          >
        </a>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "Need to get in touch with the GlobalScot Team? ",
      },
    };
  },
  mounted() {
    // this.getRoute();
  },
  methods: {
    getRoute() {
      // console.log(this.$route)
    },
  },
};
</script>
<style lang="scss">
.footer-text {
  text-align: center;
  margin-top: 30px;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}

.footer-text a {
  text-decoration: underline;
  color: white;
}
</style>
