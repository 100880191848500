<template>
  <el-col :md="24" :lg="7" class="chats-list h-100 p-0">
    <el-row class="chat-list-header m-0">
      <el-col :sm="24" class="p-0">
        <div class="d-flex mb-2">
          <h4 class="flex-grow-1">Messages</h4>
          <span
            class="cursor-pointer single-chat-icon"
            @click="$emit('new-chat')"
          >
            <span class="sr-only">click to start new chat</span>
            <a href="javascript:void(0)">
              <span class="sr-only">Click to start new chat</span>
              <svg-add-chat
            /></a>
          </span>
          <span
            class="cursor-pointer group-chat-icon"
            @click="$emit('new-chat')"
          >
            <!--<span class="sr-only">add member to chat</span> -->
            <a href="javascript:void(0)">
              <svg-add-member />
              <span class="sr-only">Click to add member to chat</span>
            </a>
          </span>
        </div>
      </el-col>

      <el-col :sm="24" class="p-0">
        <div class="d-flex">
          <div class="search-chat flex-grow-1 pr-0">
            <el-form @submit.native.prevent="updateChatSearch()">
              <el-form-item>
                <el-input
                  id="search-box-members"
                  v-model="searchString"
                  placeholder="Search..."
                  :disabled="disableFilters"
                />
              </el-form-item>
            </el-form>
          </div>
          <div class="chat-filter-list-wrapper text-center ml-2">
            <el-button
              type="button"
              class="filter-btn"
              :disabled="disableFilters"
              @click="showChatFilterList"
            >
              <span class="sr-only">filter button</span>
              <svg-search-filter
                aria-label="search filter button icon"
                class="svg-search-filter"
              ></svg-search-filter>
            </el-button>

            <div
              v-if="show_chat_filter_list"
              class="chat-filter-list text-left py-3"
            >
              <div
                v-for="(value, key) in chatTypeObj"
                :key="key"
                tabindex="0"
                class="cursor-pointer chat-filter-list-item"
                @keyup.enter="
                  filterChatListByType(key);
                  hideChatFilterList();
                "
                @click="
                  filterChatListByType(key);
                  hideChatFilterList();
                "
              >
                <span class="px-3 chat-filter-list-item-name">{{ value }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div id="chat-list" class="p-0 chat-list-body h-100">
      <el-row v-if="!noResult" v-loading="loadingChats" class="m-0">
        <el-col
          v-for="(chat, index) in chats"
          :key="index"
          :sm="24"
          class="p-0"
        >
          <div
            class="d-flex flex-column chat-list-body-item"
            tabindex="0"
            :class="{ 'active-room': isSelectedChat(chat.id) }"
            @click="chatSelected(chat)"
            @keyup.enter="chatSelected(chat)"
          >
            <div class="sr-only">
              Previous message from {{ chatName(chat) }}
            </div>
            <div v-if="isSelectedChat(chat.id)" class="right-border"></div>
            <div class="d-flex">
              <el-badge
                :value="chat.unread_count"
                :class="{'zero-notifications': !chat.unread_count}"
                :max="99"
                class="item"
              >
                <user-avatar
                  v-if="chat.type === 'challenge'"
                  shape="circle"
                  :is-global-scot="false"
                  :author-exist="true"
                  :size="42"
                  width="42px"
                  avatar-classes="flex-shrink-0"
                  :src="chat.challenge.media"
                  alt="Profile picture"
                ></user-avatar>
                <user-avatar
                  v-else-if="isHelpRequest(chat)"
                  shape="circle"
                  :is-global-scot="displayHelpRequestChevron(chat)"
                  :author-exist="displayHelpRequestChevron(chat)"
                  :size="42"
                  width="42px"
                  avatar-classes="flex-shrink-0"
                  :src="getHelpRequestMemberAvatar(chat)"
                  alt="Profile picture"
                ></user-avatar>
                <members-avatar
                  v-else
                  :members="chat.members"
                  :size="42"
                  class="flex-shrink-0"
                  alt="member avatar"
                ></members-avatar>
              </el-badge>

              <div class="chat-name">
                {{ chatName(chat) }}
              </div>
              <div class="chat-list-time ml-auto flex-shrink-0">
                {{ chat.updated_at }}
              </div>
            </div>
            <div v-if="chat.last_message" class="chat-list-last-message mt-2">
              <span v-text="formattedLastMessage(chat)"></span>
            </div>
            <div v-if="isHelpRequest(chat)" class="p-0 text-right">
              <div
                v-if="chatHelpRequestIs(chat, 'active')"
                class="chat-type d-inline-block mt-3"
              >
                Help Req.
              </div>
              <div
                v-if="chatHelpRequestIs(chat, 'reassigned')"
                class="badge badge-warning d-inline-block mt-3"
              >
                Reassigned
              </div>
              <div
                v-if="isHavingEngagementPartner(chat)"
                class="badge badge-dark d-inline-block mt-3"
              >
                Engagement Partner
              </div>
            </div>
            <div v-if="chatTypeIs(chat, 'challenge')" class="p-0 text-right">
              <div class="badge badge-danger d-inline-block mt-3">Project</div>
            </div>
          </div>
        </el-col>
        <infinite-loading :identifier="infiniteId" @infinite="fetchChats">
          <div slot="spinner"></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </el-row>
      <el-row v-else class="m-0">
        <el-col :sm="24" class="p-0">
          <div class="mt-5 text-center empty-chat-list">
            Your chat tray is empty
          </div>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import MembersListBar from "./chats-update-bar";
import ChatsCreateBar from "./chats-create-bar";
import MembersAvatar from "./avatars/members-avatar";
import { onOutsideClick } from "../../../helpers/style";
import SvgAddChat from "@/components/assets/svg-add-chat";
import SvgAddMember from "@/components/assets/svg-add-member";
import SvgFilter from "@/components/assets/svg-search-filter";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
import _ from "lodash";

export default {
  name: 'chat-list-bar',

  components: {
    "members-list-bar": MembersListBar,
    "chats-create-bar": ChatsCreateBar,
    "svg-add-chat": SvgAddChat,
    "svg-add-member": SvgAddMember,
    "svg-search-filter": SvgFilter,
    "members-avatar": MembersAvatar,
    UserAvatar,
  },
  props: {
    existingChat: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    infiniteId: +new Date(),
    chats: [],
    atMobileRes: false,
    hadChatsFromServer: false,
    selectedSort: "",
    show_chat_filter_list: false,
    searchString: "",
    firstLoad: true,
    loadingChats: false,
    chatTypeObj: {
      "": "All",
      "single_gs single_ngs": "Message",
      "group_gs group_ngs": "Groups",
      help_request: "Help requests",
      engagement_partner: "Challenge groups",
    },
    current_page: 1,
    noResult: false,
  }),
  computed: {
    isHelpRequest() {
      return (chat) => {
        return chat.type == "help_request";
      };
    },
    isChallenge() {
      return (chat) => {
        return chat.type == "challenge";
      };
    },
    isMobile() {
      return this.atMobileRes;
    },
    disableFilters() {
      return !this.chats.length && !this.hadChatsFromServer;
    },
    isSelectedChat() {
      return (chat_id) => {
        return this.$store.state.selectedChat.id === chat_id;
      };
    },
    isAllowedAddMember() {
      return ["group_gs", "group_ngs"].includes(this.$parent.selectedChat.type);
    },
    chatHelpRequestIs() {
      return (chat, check) => {
        //TODO: add statuses to vuex store
        if (check === "reassigned") {
          return chat.help_request.status === 4;
        }

        /*if(check === 'engagement-partner'){
                        return chat.help_request.status === 5
                    }*/

        if (check === "active") {
          return chat.help_request.status !== 4; //&& chat.help_request.status !== 5
        }
      };
    },
    isHavingEngagementPartner() {
      return (chat) => {
        if (this.isHelpRequest(chat)) {
          return (
            chat.help_request &&
            chat.help_request.engagement_partners.length > 0
          );
        }
        return false;
      };
    },
    getHelpRequestMemberAvatar() {
      return (chat) => {
        if (
          chat.help_request &&
          chat.help_request.trader &&
          chat.help_request.trader.id === store.state.user.id
        ) {
          return chat.help_request.expert.avatar;
        }
        return chat.help_request && chat.help_request.trader
          ? chat.help_request.trader.avatar
          : "";
      };
    },
    displayHelpRequestChevron() {
      return (chat) => {
        if (
          chat.help_request &&
          chat.help_request.trader &&
          chat.help_request.trader.id === store.state.user.id
        ) {
          return helpers.isExpert(chat.help_request.expert);
        }
        return chat.help_request && chat.help_request.trader
          ? helpers.isExpert(chat.help_request.trader)
          : false;
      };
    },
  },
  watch: {
    chats: function (value) {
      if (!value.length && this.noResult) {
        this.noResult = true;
        this.$store.commit("UPDATE_SELECTED_CHAT", {
          id: null,
          name: null,
          type: null,
          members: [],
        });
        this.$emit("chats-loaded", false);
      }
    },
  },
  created() {
    this.checkRes();
    window.onresize = this.checkRes;
    if (store.state.user.isLogged) {
      //--->this.fetchChats();
    }
  },
  mounted() {
    if (store.state.user.isLogged) {
      this.$events.on("push-latest-chat-to-top", (message) =>
        this.pushLatestActiveChatToTop(message)
      );
      this.$events.on("chatCreated", (chat) => this.updateChatSearch());
      this.$events.on("refreshChatList", (event) => this.refreshChatList());
      this.$events.on("updateSelectedChatList", (chat) =>
        this.refreshSelectedChat(chat)
      );
    }
  },
  beforeDestroy() {
    this.$events.off("push-latest-chat-to-top");
    this.$events.off("chatCreated");
    this.$events.off("refreshChatList");
    this.$events.off("updateSelectedChatList");
  },
  methods: {
    chatTypeIs(chat, check) {
      return chat.type === check;
    },
    checkRes() {
      this.atMobileRes = window.innerWidth < 1082;
    },
    updateChatSearch() {
      this.noResult = false;
      this.current_page = 1;
      this.chats = [];
      this.infiniteId += 1;
    },
    filterChatListByType(type) {
      this.selectedSort = type;
      this.updateChatSearch();
    },
    refreshChatList() {
      this.searchString = "";
      this.selectedSort = "";
      this.updateChatSearch();
    },
    refreshSelectedChat(chat) {
      let index = this.chats.findIndex((c) => c.id === chat.id);

      if (index > -1) {
        this.$set(this.chats, index, chat);
      }
    },
    fetchChats($state) {
      if (!store.state.user.isLogged) return;

      let params = {
        members_search: this.searchString,
        per_page: 5, // Need to add pagination
        page: this.current_page,
      };

      if (this.selectedSort) {
        params.chat_type = this.selectedSort;
      }
      this.loadingChats = true;
      gsApi.chat
        .index(params)
        .then((response) => {
          if (
            response.data &&
            response.data.meta &&
            response.data.meta.pagination.total <= 0
          ) {
            this.noResult = true;
            this.$store.commit("UPDATE_SELECTED_CHAT", {
              id: null,
              name: null,
              type: null,
              members: [],
            });
            this.$emit("chats-loaded", false);
            $state.complete();
          } else if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            this.hadChatsFromServer = true;
            this.$emit("chats-loaded", true);
            this.chats.push(...response.data.data);
            this.current_page += 1;

            if (
              response.data.meta.pagination.current_page >=
              response.data.meta.pagination.total_pages
            ) {
              $state.complete();
            } else {
              $state.loaded();
            }

            if (!this.isMobile) {
              if (this.existingChat && this.firstLoad) {
                let chat = this.chats.find((c) => c.id === this.existingChat);

                if (chat) {
                  this.chatSelected(chat);
                } else {
                  this.chatSelected(this.chats[0]);
                }
              } else if (
                !this.$store.state.selectedChat ||
                !this.$store.state.selectedChat.id
              ) {
                this.chatSelected(this.chats[0]);
              }
            }

            this.firstLoad = false;
          } else {
            $state.complete();
            this.chats = [];
          }
        })
        .finally(() => {
          this.loadingChats = false;
        });
    },
    chatName(chat) {
      let name = "";
      if (
        chat.type == "single_ngs" ||
        chat.type == "single_gs" ||
        chat.type == "help_request" ||
        chat.type == "challenge"
      ) {
        if (chat.type == "help_request") {
          if (
            //chat.help_request.accepted_at &&
            chat.help_request.expert &&
            chat.help_request.expert.id !== store.state.user.id
          ) {
            name = `${chat.help_request.expert.first_name} ${chat.help_request.expert.last_name}`;
          } else if (
            //chat.help_request.accepted_at &&
            chat.help_request.expert &&
            chat.help_request.expert.id == store.state.user.id
          ) {
            name = `${chat.help_request.trader.first_name} ${chat.help_request.trader.last_name}`;
          } else {
            name = `${chat.members[0].first_name} ${chat.members[0].last_name}`;
          }
        } else if (chat.type == "challenge") {
          name = chat.challenge.title;
        } else {
          name = chat.members[0].first_name + " " + chat.members[0].last_name;
        }
      } else if (chat.type == "group_ngs" || chat.type == "group_gs") {
        name = chat.members[0].first_name + " " + chat.members[0].last_name;
        if (chat.members.length > 2) {
          name =
            chat.members[0].first_name +
            " " +
            chat.members[0].last_name +
            ", " +
            chat.members[1].first_name +
            " " +
            chat.members[1].last_name +
            " +" +
            (chat.members.length - 2) +
            " more";
        } else if (chat.members.length > 1) {
          name =
            chat.members[0].first_name +
            " " +
            chat.members[0].last_name +
            ", " +
            chat.members[1].first_name +
            " " +
            chat.members[1].last_name;
        }
      }

      return name;
    },
    chatSelected(chat) {
      //update the global notification counter, if the loaded message has unread messages
      if(chat.unread_count > 0) {

        this.$store.commit('UPDATE_CHATS', this.$store.state.unreadChats - 1)
      }

      chat.unread_count = 0

      this.$store.commit("UPDATE_SELECTED_CHAT", chat)

    },
    showChatFilterList() {
      this.show_chat_filter_list = true;

      if (!document.body.getAttribute("has-event-listener")) {
        document.body.addEventListener("click", this.hideWhenOutsideClick);
      }
    },
    hideChatFilterList() {
      this.show_chat_filter_list = false;
    },
    hideWhenOutsideClick(event) {
      document.body.setAttribute("has-event-listener", "true");

      const class_name_arr = [
        "filter-btn",
        "svg-search-filter",
        "chat-filter-list",
        "chat-filter-list-item",
        "chat-filter-list-item-name",
      ];

      const callback = () => {
        this.hideChatFilterList();
        document.body.removeAttribute("has-event-listener");
        document.body.removeEventListener("click", callback);
      };

      onOutsideClick(class_name_arr, event, callback);
    },
    pushLatestActiveChatToTop(payload) {
      const chatWithLatestActivity = this.chats.find(
        (i) => i.id === payload.chat_id
      );
      if (
        chatWithLatestActivity === undefined ||
        chatWithLatestActivity === null ||
        !chatWithLatestActivity ||
        chatWithLatestActivity.members.filter(
          (i) => i.id === this.$store.state.user.id
        ).length
      ) {
        // Set timeout is required
        // as pusher sometimes sends messages faster,
        // than they get persisted in DB.
        setTimeout(() => {
          this.searchString = "";
          this.selectedSort = "";
          this.noResult = false;
          this.current_page = 1;
          this.chats = [];
          this.infiniteId = +new Date();
        }, 1000);
        return;
      }

      const chat = _.cloneDeep(chatWithLatestActivity);

      if (chat.last_message === null) {
        chat.last_message = {
          author: payload.message.author.first_name,
          body: payload.message.body,
          has_file: payload.message.has_file,
        };
        chat.updated_at = payload.message.created_at
          ? payload.message.created_at
          : payload.message.updated_at;
      } else {
        if (payload.message.type === "help_request_end_requested") {
          chat.help_request.has_been_requested_to_end = true;
        }

        if (payload.message.type === "help_request_end") {
          chat.help_request.has_ended = true;
        }

        chat.last_message.author = payload.message.author.first_name;
        chat.last_message.body = payload.message.body;
        chat.last_message.has_file = payload.message.has_file;
        chat.updated_at = payload.message.created_at
          ? payload.message.created_at
          : payload.message.updated_at;
      }

      // remove chat from the current position.
      const oldIndex = this.chats.findIndex((i) => i.id === payload.chat_id);
      this.chats.splice(oldIndex, 1);

      // push it to the first position.
      this.chats.unshift(chat);
      this.scrollToFirstChat();
    },

    scrollToFirstChat() {
      const chatList = document.getElementById("chat-list");
      chatList.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    formattedLastMessage(chat) {
      if (chat.last_message.body !== null) {
        return `${chat.last_message.author}: ${chat.last_message.body}`;
      }

      if (
        chat.last_message.has_file &&
        !this.isHelpRequest(chat) &&
        !this.isChallenge(chat)
      ) {
        return `${chat.last_message.author}: Uploaded a file.`;
      }

      if (this.isHelpRequest(chat)) {
        if (
          !chat.help_request.has_been_requested_to_end &&
          !chat.help_request.has_ended &&
          !chat.last_message.has_file
        ) {
          return `${chat.last_message.author}: ${chat.help_request.subject}`;
        }

        if (
          chat.help_request.has_been_requested_to_end &&
          !chat.help_request.has_ended &&
          !chat.last_message.has_file
        ) {
          return `${chat.last_message.author}: Prompted to end this help request.`;
        }

        if (
          chat.help_request.has_ended &&
          !chat.last_message.has_file &&
          chat.help_request.ending_reasons.includes(
            chat.help_request.ending_reason
          )
        ) {
          return `${chat.last_message.author}: Closed this help request.`;
        }

        if (chat.help_request.has_ended && !chat.last_message.has_file) {
          return "Closed by an Admin";
        }

        if (chat.last_message.has_file) {
          return `${chat.last_message.author}: Uploaded a file.`;
        }
      }

      if (this.isChallenge(chat)) {
        if (chat.challenge.has_ended) {
          return "This project has ended.";
        }

        if (chat.last_message.has_file) {
          return `${chat.last_message.author}: Uploaded a file.`;
        }
      }
    },
  },
};
</script>
