<template>
  <div>
    <el-form
      ref="form"
      class="submit-post position-relative"
      :model="form"
      label-width="120px"
    >
      <el-card
        v-loading.lock="loading"
        class="box-card"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      >
        <div slot="header" class="clearfix text-right">
          <strong class="fs-24 text-white clickable" @click="confirmCloseForm"
            >&#10799;</strong
          >
        </div>
        <el-row>
          <el-col :span="24" class="px-2 py-2">
            <pulse-editor
              v-model="form.content"
              :project-id="parseInt(projectId)"
              placeholder="Post an update on the project"
              @characters="updateCharCount"
            ></pulse-editor>
            <span class="float-right">{{ charCount }} / {{ maxChars }}</span>
          </el-col>
        </el-row>
        <el-row v-if="errors.content.length">
          <el-col :span="24" class="text-right mt-2">
            <p
              v-for="(error, index) in errors.content"
              :key="index"
              class="text-danger"
            >
              {{ error }}
              <span aria-live="polite" class="sr-only">{{ error }}</span>
            </p>
          </el-col>
        </el-row>
        <!-- start media file block -->
        <el-row>
          <el-col>
            <div
              v-if="mediaFile.id"
              class="media-file clickable d-inline-block p-2 rounded w-100"
            >
              <div class="d-flex align-items-center">
                <div class="document-info w-100">
                  <div
                    class="download d-flex align-items-center position-relative"
                  >
                    <el-image :src="getDocumentPreview(mediaFile)">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div
                      class="d-flex align-items-center justify-content-between w-100"
                    >
                      <a
                        class="gs-link fs-19 file-name ml-2"
                        role="button"
                        @click="download(mediaFile)"
                        v-loading="isDownloading(mediaFile.id)"
                      >
                        {{ mediaFile.filename }}
                      </a>
                      <p class="media-type">
                        {{ `Post ${mediaFile.is_image ? "image" : "video"}` }}
                      </p>
                    </div>

                    <p
                      class="d-inline-block float-right remove"
                      @click="removeCurrentFile(enumHelper.FileTypeEnum.FILE)"
                    >
                      &times;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- end media file block -->
        <!-- start attachments block -->
        <el-row>
          <el-col
            v-if="attachedDocuments.length"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            class="mt-2"
          >
            <div
              v-for="(attachedDocument, index) in attachedDocuments"
              :key="index"
              v-loading="loadingAttachments.includes(index)"
              class="attached-document clickable d-inline-block p-2 rounded w-100"
              :class="{ 'has-error': attachedDocument.hasError }"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="document-info w-100">
                  <div
                    class="download d-flex align-items-center position-relative"
                  >
                    <el-image
                      class="mr-2"
                      :class="{ 'is-svg': !attachedDocument.is_image }"
                      :src="getDocumentPreview(attachedDocument)"
                    ></el-image>
                    <div v-if="attachedDocument.isNew" class="mr-2 w-100">
                      <p>
                        {{
                          attachedDocument.file
                            ? attachedDocument.file.name
                            : ""
                        }}
                      </p>
                      <el-progress
                        :percentage="attachedDocument.percentage"
                        color="#AF006E"
                      ></el-progress>
                    </div>
                    <a
                      v-else
                      class="gs-link fs-19 mr-2 w-100"
                      role="button"
                      @click="download(attachedDocument)"
                      v-loading="isDownloading(attachedDocument.id)"
                    >
                      {{ attachedDocument.filename }}
                    </a>
                    <p
                      class="d-inline-block fs-20 float-right remove"
                      @click="
                        removeCurrentFile(
                          enumHelper.FileTypeEnum.DOCUMENT,
                          index,
                          attachedDocument.isNew
                        )
                      "
                    >
                      &times;
                    </p>
                  </div>
                  <!-- <p>{{ mediaFile.media_type.toUpperCase() }}</p> -->
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- end attachments block -->

        <el-row class="mt-3">
          <el-col :span="24">
            <hr />
          </el-col>
        </el-row>
        <el-row class="mt-4 mt-sm-3">
          <el-col
            v-loading.lock="loadingMedia"
            element-loading-background="rgba(255, 255, 255, 0.8)"
            :xs="24"
            :sm="16"
            class="d-md-flex"
          >
            <el-upload
              v-if="!mediaFile.id"
              ref="upload"
              action=""
              :on-change="onFileChange"
              :multiple="false"
              list-type="picture-card"
              class="selected-upload"
              :auto-upload="false"
              :disabled="loadingMedia"
            >
              <div
                class="add-media clickable d-flex align-items-center justify-content-center justify-content-start"
              >
                <svg-add-media class="w-30-px"></svg-add-media>
                <span class="gs-link ml-2">Add post image/video</span>
              </div>
            </el-upload>

            <el-upload
              ref="upload"
              action=""
              :http-request="onDocumentUpload"
              :multiple="true"
              list-type="picture-card"
              class="selected-upload ml-md-3"
              :auto-upload="true"
            >
              <div
                class="add-media clickable d-flex align-items-center justify-content-center justify-content-start"
              >
                <svg-add-post width="30" height="39"></svg-add-post>
                <span class="gs-link ml-2">Attachments</span>
              </div>
            </el-upload>
          </el-col>
          <el-col class="text-right" :xs="24" :sm="8">
            <el-button
              :disabled="loadingMedia || loadingAttachments.length > 0"
              plain
              type="primary px-5 py-2 d-inline min-height-initial w-100 w-sm-auto mt-4 mb-3 mb-sm-0 mt-sm-0"
              @click.prevent="submitPost"
            >
              <p>Post</p>
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="errors.media.length">
          <el-col :span="24" class="text-right mt-2">
            <p
              v-for="(error, index) in errors.media"
              :key="index"
              class="text-danger"
            >
              {{ error }}
            </p>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <cropper-upload
      v-if="cropperFile"
      preview-class="preview-outer rectangle"
      :visible="showCropperDialog"
      :aspect-ratio="16 / 9"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
//TODO: loading state, validation
import { mapGetters } from "vuex";
import gsApi from "@/services/gs.api";
import SvgAddMedia from "@/components/assets/svg-add-media";
import PulseEditor from "@/components/platform/pulse/pulse-editor";
import CropperUpload from "@/components/cropper-upload/cropper-upload";
import SvgAddPost from "@/components/assets/svg-add-post";
import helpers from "@/helpers/index";
import enumHelper from "@/helpers/enumHelper";
const ALLOWED_DOCUMENT_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "image/webp",
  "video/ogg",
  "video/mpeg",
  "video/webm",
  "video/mp4",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
];
const ALLOWED_MEDIA_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "video/mpeg",
  "video/webm",
  "video/mp4",
  "video/ogg",
];
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb

export default {
  name: "ProjectPostForm",
  components: {
    SvgAddMedia,
    PulseEditor,
    CropperUpload,
    SvgAddPost,
  },
  props: ["submittingPost", "projectId", "editMode", "post"],
  data() {
    return {
      loadingMedia: false,
      loading: false,
      addingTags: false,
      form: {
        category_id: null,
        content: "",
        sectors: [],
        expertise: [],
        media_file_id: null,
        document_files: [],
        challenge_id: this.projectId,
        links: [],
      },
      errors: {
        category_id: [],
        content: [],
        media: [],
      },
      mediaFile: {},
      attachedDocuments: [],
      loadingAttachments: [],
      tributeOptions: {
        values: (text, cb) => {
          this.searchUsers(text, (users) => cb(users));
        },
        selectTemplate: (item) => {
          return "@" + item.original.value;
        },
      },
      tribute: null,
      selectedTags: [],
      maxChars: 1500,
      charCount: 0,
      isMounted: false,
      enumHelper,
      cropperFile: null,
      showCropperDialog: false,
      downloading: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    getDocumentPreview() {
      return (document) => {
        return document.is_image && document.medium_thumb
          ? document.medium_thumb
          : helpers.getFileIcon(
              document.file ? document.file.name : document.filename,
              false
            );
      };
    },
    isDownloading() {
      return (id) => this.downloading.includes(id);
    },
  },
  created() {
    if (this.editMode) {
      this.initalizeForm();
    }
  },
  methods: {
    updateCharCount(value) {
      this.charCount = value;
      if (this.charCount > this.maxChars) {
        this.errors.content = [
          "Content must be less than or equal to " +
            this.maxChars +
            " characters long.",
        ];
      } else {
        this.errors.content = [];
      }
    },
    searchUsers(text, cb) {
      gsApi.members
        .search({
          search: text,
          per_page: 4,
        })
        .then((response) => {
          let users = [];
          for (var i = response.data.data.length - 1; i >= 0; i--) {
            let user = response.data.data[i];
            users.push({
              key: user.full_name,
              value: user.full_name,
            });
          }
          cb(users);
        });
    },
    onFileChange(file) {
      if (!this.validateMediaUpload(file.raw)) return;

      if (this.isImage(file.raw)) {
        this.cropperFile = file;
        this.showCropperDialog = true;
      } else {
        this.onFileUpload(file.raw);
      }
    },
    isImage(file) {
      return ["image/jpeg", "image/png"].indexOf(file.type) >= 0;
    },
    onCropperUpload(croppedFileData) {
      this.onFileUpload(croppedFileData.file);
    },
    onCropperDialogClose() {
      this.showCropperDialog = false;
      this.cropperFile = null;
    },
    onFileUpload(file) {
      this.errors.media = [];

      this.loadingMedia = true;
      this.percentage = 1;
      let interval = 0;

      const formData = new FormData();
      if (this.cropperFile) {
        formData.append(
          "file",
          file,
          this.cropperFile ? this.cropperFile.name : null
        ); // to upload blob file after cropping used for images
      } else {
        formData.append("file", file); // used for videos
      }

      gsApi.post.media
        .create(formData, (progress) => {
          interval = setInterval(() => {
            if (this.percentage < 100) {
              this.percentage += 1;
            }
          }, 15);
        })
        .then((response) => {
          this.mediaFile = response.data.data;
          this.form.media_file_id = response.data.data.id;
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again later.";

          if (error.code === "ECONNABORTED") {
            (message =
              "The upload took too long at the current time, please try again later."),
              "Error";
          } else if (error.status == 422) {
            let errorData = error.data.errors;
            let errors = [];
            for (let item in errorData) {
              errors.push(errorData[item][0]);
            }
            message = errors.join(" ");
          } else if (error.status == 413) {
            message = `File is too large. Must be below 200MB!`;
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .finally(() => {
          this.onCropperDialogClose();
          this.loadingMedia = false;
          clearInterval(interval);
        });
    },
    onDocumentUpload(request) {
      this.errors.media = [];

      if (this.attachedDocuments.filter((doc) => !doc.hasError).length > 4) {
        this.$alert("Warning! No more than 5 uploads per post", "Warning", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
        return;
      }
      if (!this.validateDocumentUpload(request.file)) return;

      const currentIndex =
        this.attachedDocuments.push({
          file: request.file,
          percentage: 1,
          interval: 0,
          isNew: true,
        }) - 1;

      this.loadingAttachments.push(currentIndex);

      const formData = new FormData();
      formData.append("file", request.file);

      gsApi.post.media
        .create(formData, (progress) => {
          this.attachedDocuments[currentIndex].interval = setInterval(() => {
            if (this.attachedDocuments[currentIndex]?.percentage < 100) {
              this.attachedDocuments[currentIndex].percentage += 1;
            }
          }, 15);
        })
        .then((response) => {
          this.attachedDocuments[currentIndex] = response.data.data;
          this.form.document_files.push(response.data.data.id);
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again later.";
          this.attachedDocuments[currentIndex].hasError = true;
          if (error.code === "ECONNABORTED") {
            (message =
              "The upload took too long at the current time, please try again later."),
              "Error";
          } else if (error.status == 422) {
            let errorData = error.data.errors;
            let errors = [];
            for (let item in errorData) {
              errors.push(errorData[item][0]);
            }
            message = errors.join(" ");
          } else if (error.status == 413) {
            message = `File is too large. Must be below 200MB!`;
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .finally(() => {
          this.loadingAttachments = this.loadingAttachments.filter(
            (i) => i != currentIndex
          );
          clearInterval(this.attachedDocuments[currentIndex].interval);
        });
    },
    validateDocumentUpload(file) {
      if (ALLOWED_DOCUMENT_TYPES.indexOf(file.type) == -1) {
        this.errors.media = [
          "The file must be a file of type: jpg, png, jpeg, webp, pdf, mp4, ogv, webm, ppt, pptx, doc, docx, xls, xlsx, odt, odp, ods.",
        ];
        return false;
      }
      if (MAX_FILE_SIZE < file.size) {
        this.errors.media = ["File size must be less than 200MB."];
        return false;
      }
      return true;
    },
    validateMediaUpload(file) {
      if (ALLOWED_MEDIA_TYPES.indexOf(file.type) == -1) {
        this.errors.media = [
          "The file must be a file of type: jpg, png, jpeg, webp, mp4, ogv, webm",
        ];
        return false;
      }
      if (MAX_FILE_SIZE < file.size) {
        this.errors.media = ["File size must be less than 200MB."];
        return false;
      }
      return true;
    },
    validateForm() {
      let passesValidation = true;

      if (this.charCount > this.maxChars) {
        this.errors.content = [
          "Content must be less than or equal to " +
            this.maxChars +
            " characters long.",
        ];
        passesValidation = false;
      }

      if (!this.form.content) {
        this.errors.content = ["The content field is required."];
        passesValidation = false;
      }

      return passesValidation;
    },
    submitPost() {
      if (!this.validateForm()) return;

      this.loading = true;

      this.form.links = this.extractLinks();
      this.form.content = this.$options.filters.domPurify(this.form.content, {
        ALLOWED_TAGS: ["p", "strong", "i", "span", "u", "a"],
      });
      
      if (this.editMode) {
        gsApi.post
          .update(this.form.id, this.form)
          .then((response) => {
            setTimeout(() => {
              this.closeForm();
              this.loading = false;
              this.submitted();
              window.scrollTo(0, 0);
            }, 500);
          })
          .catch((error) => {
            this.closeForm();
            this.loading = false;
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          });
      } else {
        gsApi.post.create(this.form).then((response) => {
          setTimeout(() => {
            this.closeForm();
            this.submitted();
            this.loading = false;
          }, 500);
        });
      }
    },
    confirmCloseForm() {
      if (
        this.form.content ||
        this.form.category_id ||
        this.form.expertise.length ||
        this.form.sectors.length
      ) {
        this.$confirm(
          " Are you sure you want to cancel this post?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.closeForm();
          })
          .catch(() => {});
      } else {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("closing");
    },
    submitted() {
      this.$emit("submitted");
    },
    removeCurrentFile(type, index = null, newItem = false) {
      if (newItem && type == enumHelper.FileTypeEnum.DOCUMENT) {
        this.attachedDocuments.splice(index, 1);
        return;
      }

      this.$confirm(" Are you sure you want to remove this file?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          if (type == enumHelper.FileTypeEnum.FILE) {
            (this.form.media_file_id = null), (this.mediaFile = {});
          } else if (type == enumHelper.FileTypeEnum.DOCUMENT) {
            this.$delete(this.attachedDocuments, index);
            this.form.document_files = this.attachedDocuments
              .filter((doc) => doc.id)
              .map((doc) => doc.id);
          }
        })
        .catch(() => {});
    },
    initalizeForm() {
      this.form = {
        id: this.post.id,
        category_id: this.post.category ? this.post.category.id : 0,
        content: this.post.content,
        sectors: this.post.sectors.data
          ? this.post.sectors.data.map((x) => x.id)
          : [],
        expertise: this.post.expertise.data
          ? this.post.expertise.data.map((x) => x.id)
          : [],
        media_file_id: this.post.mediaFile ? this.post.mediaFile.id : null,
        document_files: this.post.documentFiles.data.length
          ? this.post.documentFiles.data.map((x) => x.id)
          : [],
      };
      if (this.post.mediaFile) {
        this.mediaFile = {
          id: this.post.mediaFile.id,
          medium_thumb: this.post.mediaFile.medium_thumb,
          filename: this.post.mediaFile.original_name,
          is_image: this.post.mediaFile.is_image,
          path: this.post.mediaFile.path,
        };
      }
      if (this.post.documentFiles.data.length) {
        //--- include document information
        this.attachedDocuments = this.post.documentFiles.data.map(
          (documentFile) => {
            return {
              id: documentFile.id,
              medium_thumb: documentFile.medium_thumb,
              filename: documentFile.original_name,
              is_image: documentFile.is_image,
              path: documentFile.path,
            };
          }
        );
      }
      this.isMounted = true;
    },
    extractLinks() {
      const regex =
        /((http|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
      const matched = [...this.form.content.matchAll(regex)];
      const links = matched.map((i) => {
        if (i[0].startsWith("https://www.")) {
          i[0] = i[0].replace("https://www.", "");
        }

        if (i[0].startsWith("https://")) {
          i[0] = i[0].replace("https://", "");
        }

        if (i[0].startsWith("www.")) {
          i[0] = i[0].replace("www.", "");
        }

        if (i[0].includes("&nbsp;")) {
          i[0] = i[0].replace("&nbsp;", "");
        }

        return `https://${i[0]}`;
      });

      const filteredLinks = links.reduce((accumulator, item) => {
        const newUri =
          accumulator.findIndex(
            (element) =>
              element.substring(element.indexOf("//") + 2, element.length) ===
              item.substring(item.indexOf("//") + 2, item.length)
          ) < 0;

        if (newUri) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);

      return [...new Set(filteredLinks)].slice(0, 3);
    },
    download(file) {
      let self = this;
      this.downloading.push(file.id);

      const url = `${process.env.VUE_APP_API_URL.replace(
        /\/$/,
        ""
      )}/media/file/download/${file.id}`;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "arraybuffer";
      xhr.setRequestHeader("ApiKey", process.env.VUE_APP_API_KEY);

      if (this.user.isLogged) {
        xhr.setRequestHeader("Authorization", this.user.token);
      }

      xhr.send();

      xhr.onload = function () {
        if (this.status === 200) {
          const filename = file.filename || file.original_name;

          let blob = new Blob([xhr.response], {
            type: xhr.getResponseHeader("content-type"),
          });
          const objectUrl = URL.createObjectURL(blob);

          let link = document.createElement("a");
          link.href = objectUrl;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(blob);
        }
        self.downloading = self.downloading.filter((id) => id != file.id);
      };
    },
  },
};
</script>
