<template>
  <div class="purple-top-section">
    <div class="purple-bg"></div>
    <div
      class="container"
      v-loading.fullscreen.lock="mainLoader"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-row class="hello-container">
        <el-col :sm="24" :md="9" :lg="13" :xl="15">
          <div id="main-content">
            <h1 class="hello-text">Hello {{ this.name }}</h1>
            <h2 class="hello-text mt-1">What would you like to do?</h2>
          </div>
        </el-col>
        <el-col :sm="24" :md="15" :lg="11" :xl="9">
          <div class="hello-user-buttons">
            <!--             <el-button class="invite-btn mr-3" size="small">Invite Colleague</el-button>
            <el-button type="primary" style="margin:0px" size="small">View My Activity</el-button> -->
          </div>
        </el-col>
      </el-row>

      <div class="container-help-cards">
        <el-row :gutter="12">
          <div>
            <carousel
              :perPageCustom="[
                [1082, 3],
                [798, 2],
                [320, 1]
              ]"
              :mouse-drag="false"
              class="card-carousel"
            >
              <slide v-for="(card, index) in boxCards" :key="index">
                <el-col>
                  <el-card class="box-card help-card" shadow="always">
                    <div class="help-card-icon">
                      <svg-globe-large v-if="index === 0"></svg-globe-large>
                      <svg-chat-large
                        class="mt-2"
                        v-if="index === 1"
                      ></svg-chat-large>
                      <svg-events-large v-if="index === 2"></svg-events-large>
                    </div>
                    <div>
                      <h2>{{ card.title }}</h2>
                      <p class="sub-text">{{ card.description }}</p>
                    </div>
                    <div class="help-btn">
                      <el-button
                        class="btn-text"
                        size="medium"
                        @click.native="goTo(card.button.route)"
                        >{{ card.button.text }}</el-button
                      >
                    </div>
                  </el-card>
                </el-col>
              </slide>
            </carousel>
          </div>
        </el-row>
      </div>
    </div>
    <div>
      <new-members :members="users"></new-members>
    </div>
    <div>
      <recent-pulse-questions :posts="posts"></recent-pulse-questions>
    </div>
    <div>
      <news-and-events :articles="articles"></news-and-events>
    </div>
    <div>
      <events-and-webinars :events="events"></events-and-webinars>
    </div>
    <div v-if="!isNGS">
      <challenge-yourself :projects="projects"></challenge-yourself>
    </div>
    <!--     <div>
        <get-in-touch></get-in-touch>
      </div>-->
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
import store from "@/store";
import router from "@/router";
import newMembers from "./new-members.vue";
import recentPulseQuestions from "./recent-pulse-questions";
import newsAndEvents from "./news-and-events";
import challengeYourself from "./challenge-yourself";
import eventsAndWebinars from "./events-and-webinars";
import getInTouch from "./get-in-touch-gs";
import gsApi from "@/services/gs.api";
import { Loading } from "element-ui";
import GlobeLarge from "@/components/assets/svg-globe-large";
import EventsLarge from "@/components/assets/svg-events-large";
import ChatLarge from "@/components/assets/svg-chat-large";

export default {
  components: {
    Carousel,
    Slide,
    newMembers,
    recentPulseQuestions,
    newsAndEvents,
    challengeYourself,
    getInTouch,
    Loading,
    eventsAndWebinars,
    "svg-globe-large": GlobeLarge,
    "svg-events-large": EventsLarge,
    "svg-chat-large": ChatLarge
  },
  computed: {
    ...mapState(["user"]),
    isGlobalScot() {
      return this.user.expertProfile && this.user.roles.includes("expert");
    },
    isNGS() {
      return this.user.traderProfile && this.user.roles.includes("trader");
    }
  },
  data() {
    return {
      name: "",
      boxCards: [
        {
          title: "Looking for support from a GlobalScot?",
          description:
            "Search our network of GlobalScots to find someone who can help you",
          button: {
            route: "Members",
            text: "Find a GlobalScot"
          }
        },
        {
          title: "Got a question for the network?",
          description:
            "Ask a question, start a discussion or share something with the network.",
          button: {
            route: "Pulse",
            text: "View the noticeboard"
          }
        },
        {
          title: "Interested in GlobalScot events?",
          description:
            "Events are a great way to network, learn from others and connect in person",
          button: {
            route: "Events and Webinars",
            text: "Find an event"
          }
        }
      ],
      articles: [],
      events: [],
      users: [],
      posts: [],
      projects: [],
      mainLoader: false
    };
  },
  methods: {
    goTo(route) {
      router.push({ name: route });
    },
    fetchDashboard() {
      if (!this.mainLoader) {
        this.mainLoader = true;
      }
      gsApi.users
        .dashboard()
        .then(response => {
          this.articles = response.data.articles.data.slice(0, 3);
          this.events = response.data.events.data.slice(0, 3);
          this.users = response.data.users.data.slice(0, 4);
          this.posts = response.data.posts.data.slice(0, 3);
          this.projects = response.data.projects.data.slice(0, 3);
          this.mainLoader = false;
        })
        .catch(error => {
          this.mainLoader = false;
        });
    }
  },
  created() {
    /*if (!store.state.user.isLogged) {
      router.push("/login");
    } else {
      this.name = store.state.user.firstName;
    }
    this.fetchDashboard();*/
    if (store.state.user.isLogged) {
      this.name = store.state.user.firstName;
      this.fetchDashboard();
    }
  }
};
</script>
