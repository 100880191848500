<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="signup-ngs-page page"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-${currentStep}`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div id="main-content" class="header__content__wrapper">
              <h2 class="header__title" v-html="heroText"></h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <el-row>
          <el-col :md="24" :lg="20" :xl="16">
            <!-- Step 0 Intro -->
            <div v-show="currentStep === 0" class="body__content">
              <!--h1 class="body__title">
                20 years of ambition and impact. One shared passion for Scotland’s success.
              </h1-->

              <p class="se-body-l-screen mb-4">
                Plug your business into the advice, guidance and support that’ll
                set you ahead in international markets – register today.
              </p>
              <p class="se-body-l-screen mb-4">You’ll need:</p>

              <ul class="se-ul-large-dots">
                <li>
                  Your Companies House registration number (find this on the
                  <a
                    href="https://beta.companieshouse.gov.uk/search"
                    target="_blank"
                    class="text__link"
                    >Companies House website.<span class="sr-only"
                      >Opens in a new tab</span
                    ><svg-link-purple class="ml-1" /></a
                  >)
                </li>
                <li>
                  Details about your company, ambitions and expectations of
                  GlobalScot
                </li>
              </ul>

              <p class="se-body-l-screen mb-4">
                Fields are mandatory, unless noted as optional
              </p>

              <p class="se-body-l-screen mb-4">
                Not registered with Companies House? , or don’t have a Scottish
                Companies House number?
                <router-link :to="{ name: 'Signup NCHN' }" class="text__link">
                  Complete our manual registration form </router-link
                >. We’ll need to review your details before you can complete
                registration to make sure you’re eligible for support.
              </p>

              <p class="se-body-l-screen mb-4">
                All the information you provide will be publicly visible on your
                profile, except for your contact details. Once you’ve
                registered, you can update your profile any time.
              </p>

              <p class="se-body-l-screen mb-4">
                If you’ve got any questions or need help registering, just get
                in touch.
              </p>

              <p class="se-body-l-screen mb-4">
                In order to comply with UK government Sanctions legislation and
                Scottish Government guidance in relation to trading with Russia,
                Scottish Enterprise will carry out sanctions checks on your
                company, shareholders and in some cases directors and may ask
                you about the nature of your trading and/or investment links
                with Russia and Belarus. Depending on the outcome of these
                checks Scottish Enterprise may decide not to support you or your
                company or to terminate existing support. Useful information on
                how to comply with UK government sanctions and Scottish
                Government guidance in relation to trade with Russia are given
                on Find Business Support
                <a
                  href="https://findbusinesssupport.gov.scot/russia-sanctions"
                  target="_blank"
                  class="text__link"
                  >Find out more about the Russia sanctions
                  <span class="sr-only">Opens in a new tab</span
                  ><svg-link-purple class="ml-1" /></a
                >.
              </p>

              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="gotoStep(1)"
                >
                  Continue
                </el-button>
              </div>

              <div class="contact-phone hidden-sm-and-down">
                <h2 class="se-heading-m mb-2">Need to speak to someone?</h2>
                <p class="se-body-l-screen mb-3">
                  If you’ve got any questions or need help registering, just get
                  in touch.
                </p>
                <div class="pt-3">
                  <a :href="getCMSRoute('contact-us')">
                    <el-button
                      type="primary"
                      class="idea-contact-btn mt-md-0 mt-4"
                    >
                      Contact Us
                    </el-button>
                  </a>
                </div>
              </div>
            </div>
            <!-- Step 0 Intro End -->

            <!-- Step 1 Personal and Company details -->
            <div v-show="currentStep === 1" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(0)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 1"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step1Form"
                ref="step1Form"
                :model="step1Form.form"
                :rules="step1FormRules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step1Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Personal and company details
                </h1>

                <p class="se-body-l-screen mb-4">
                  In this section, tell us about you and the companies that
                  you'd like our GlobalScots to support.
                </p>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-4">Personal details</h2>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="title">
                      <span aria-hidden="true"
                        >Title<span class="is-custom-asterisk">*</span></span
                      ><br />
                      <label class="sr-only" for="title"
                        >Please select a title
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor('step1Form', 'title')
                          "
                          v-text="
                            getInlineValidationErrorFor('step1Form', 'title')
                          "
                        ></span>
                      </label>
                      <span class="el-form-item__info"
                        >This won't be publicly displayed on your profile</span
                      >
                    </label>
                    <el-form-item prop="title" class="is-no-asterisk">
                      <single-select
                        id="title"
                        v-model="step1Form.form.title"
                        :options="step1Form.titles"
                        name="title"
                        class="half-content"
                      ></single-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="first_name">
                      First name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'first_name'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'first_name')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="first_name">
                      <el-input
                        id="first_name"
                        v-model="step1Form.form.first_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your first name in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.first_name =
                            step1Form.form.first_name.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="last_name">
                      Last name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'last_name')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'last_name')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="last_name">
                      <el-input
                        id="last_name"
                        v-model="step1Form.form.last_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your last name in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.last_name =
                            step1Form.form.last_name.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-4">
                    <label for="contact_number">
                      Contact number<span class="is-custom-asterisk">*</span
                      ><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'contact_number'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step1Form',
                            'contact_number'
                          )
                        "
                      ></span>
                    </label>
                    <span class="el-form-item__info"
                      >This won't be publicly displayed on your profile</span
                    >
                    <el-form-item prop="contact_number" class="is-no-asterisk">
                      <el-input
                        id="contact_number"
                        v-model="step1Form.form.contact_number"
                        class="w-lg-75"
                        maxlength="20"
                        autocomplete="false"
                        label="Please enter your contact number in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.contact_number =
                            step1Form.form.contact_number.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="step-hr"></div>

                <div
                  v-if="step1Form.form.businesses.length > 0"
                  id="businesses-section"
                >
                  <h2 class="se-heading-m mb-4">Edit company details</h2>

                  <el-row>
                    <manage-business
                      v-for="(business, index) in step1Form.form.businesses"
                      :key="index"
                      :business="business"
                      :groups="newCompanyForm.groups"
                      @update-business="onUpdateBusiness(business, $event)"
                      @remove-business="onRemoveBusiness(business, $event)"
                    />
                  </el-row>
                  <div class="step-hr mt-4"></div>
                </div>

                <el-form
                  id="newCompanyForm"
                  ref="newCompanyForm"
                  :model="newCompanyForm.company"
                  :rules="newCompanyFormRules"
                  autocomplete="false"
                  @submit.native.prevent
                  @validate="handleFormFieldInput('newCompanyForm')"
                >
                  <h2
                    v-if="step1Form.form.businesses.length == 0"
                    class="se-heading-m mb-4"
                  >
                    Add a current company
                  </h2>
                  <h2
                    v-if="step1Form.form.businesses.length > 0"
                    class="se-heading-m mb-4"
                  >
                    Details for additional company
                  </h2>

                  <div
                    v-if="
                      step1Form.form.businesses.length > 0 &&
                      !newCompanyForm.additionalCompanyFormOpen
                    "
                  >
                    <p class="se-body-l-screen mb-4">
                      If you want add more than one company, you can add
                      additional companies here.
                    </p>
                    <el-row class="mt-2 mb-4">
                      <el-col :md="24" :lg="15" class="d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="openAdditionalCompanyForm"
                        >
                          Add another company
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>

                  <div
                    v-if="
                      step1Form.form.businesses.length == 0 ||
                      newCompanyForm.additionalCompanyFormOpen
                    "
                  >
                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item
                          class="company-house-form-item is-no-asterisk"
                          prop="input_company_house_no"
                        >
                          <template slot="label">
                            Companies House registration number<span
                              class="is-custom-asterisk"
                              >*</span
                            ><br />
                            <span class="el-form-item__info">
                              This will be 2 letters followed by 6 numbers. It
                              must be for an active Scottish company, and must
                              start with ‘S’.<br />
                              Can’t remember your Companies House registration
                              number? You can look up your company on the
                              <a
                                href="https://beta.companieshouse.gov.uk/search"
                                target="_blank"
                                class="text__link"
                                >Companies House.<span class="sr-only"
                                  >Opens in a new tab</span
                                ><svg-link-purple class="ml-1"
                              /></a> </span
                            ><br />
                            <span class="el-form-item__info">
                              If you don’t have a Companies House number, or
                              your Companies House number doesn’t start with
                              ‘S’, you can
                              <router-link
                                :to="{ name: 'Signup NCHN' }"
                                class="text__link"
                                >complete our manual registration
                                form.</router-link
                              >We’ll need to review your details to make sure
                              you’re eligible.
                            </span>
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'input_company_house_no'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'input_company_house_no'
                                )
                              "
                            ></span>
                          </template>

                          <div
                            v-loading="newCompanyForm.companyLoading"
                            class="d-flex"
                          >
                            <el-input
                              id="company_house_no"
                              v-model="
                                newCompanyForm.company.input_company_house_no
                              "
                              class="half-flex-content"
                              maxlength="8"
                              label="Please enter your company house number in this field"
                              aria-required="true"
                              @blur="
                                newCompanyForm.company.input_company_house_no =
                                  newCompanyForm.company.input_company_house_no.trim()
                              "
                              @keyup.enter.native="
                                onCompanyHouseNoEnter($event)
                              "
                            />
                            <div class="mr-3"></div>
                            <el-form-item class="half-flex-content">
                              <el-button
                                id="btnCompanySearch"
                                ref="btnCompanySearch"
                                size="medium"
                                :disabled="
                                  newCompanyForm.company.input_company_house_no
                                    .length !== 8 ||
                                  ![
                                    'SA',
                                    'SC',
                                    'SF',
                                    'SI',
                                    'SL',
                                    'SO',
                                    'SP',
                                    'SR',
                                    'SZ',
                                  ].includes(
                                    newCompanyForm.company.input_company_house_no
                                      .substring(0, 2)
                                      .toUpperCase()
                                  )
                                "
                                @click="searchForCompany"
                              >
                                Find Company
                              </el-button>
                            </el-form-item>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row v-if="newCompanyForm.company.company_found">
                      <el-col :md="24" :lg="20" class="mb-4">
                        <div class="address-details se-lead-paragraph-l">
                          <span class="color-primary">
                            {{ newCompanyForm.company.name }}
                          </span>
                          <span>
                            Incorporated on
                            {{
                              newCompanyForm.company.incorporation_date
                                | moment("D MMMM YYYY")
                            }}
                          </span>
                          <span>
                            <span v-if="newCompanyForm.company.address_1"
                              >{{ newCompanyForm.company.address_1 }},</span
                            >
                            <span v-if="newCompanyForm.company.address_2"
                              >{{ newCompanyForm.company.address_2 }},<br
                            /></span>
                            <span v-if="newCompanyForm.company.town"
                              >{{ newCompanyForm.company.town }},</span
                            >
                            <span v-if="newCompanyForm.company.county"
                              >{{ newCompanyForm.company.county }},</span
                            >
                            <span v-if="newCompanyForm.company.post_code">{{
                              newCompanyForm.company.post_code
                            }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row v-if="newCompanyForm.company.company_found">
                      <el-col :md="24" :lg="20" class="mb-4">
                        <el-form-item
                          id="isAddressCorrect"
                          class="mb-0"
                          prop="isAddressCorrect"
                        >
                          <template slot="label">
                            Is the above address correct?
                          </template>
                          <el-radio-group
                            v-model="newCompanyForm.isAddressCorrect"
                            aria-label="Is the above address correct? Select Yes or No."
                            @change="onCompanyAddressCorrectChange"
                          >
                            <el-radio :label="true"> Yes </el-radio>
                            <el-radio :label="false"> No </el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <!-- if address given is not correct-->

                    <div
                      v-if="!newCompanyForm.isAddressCorrect"
                      class="address-details mb-3"
                    >
                      <el-row>
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="address_1">
                            <template slot="label">
                              Address line 1
                              <span
                                class="sr-only"
                                aria-live="polite"
                                :hidden="
                                  !hasInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'address_1'
                                  )
                                "
                                v-text="
                                  getInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'address_1'
                                  )
                                "
                              ></span>
                            </template>
                            <el-input
                              id="address_1"
                              v-model="newCompanyForm.company.address_1"
                              maxlength="255"
                              autocomplete="false"
                              label="Please enter your company address line 1 in this field"
                              aria-required="true"
                              @blur="
                                newCompanyForm.company.address_1 =
                                  newCompanyForm.company.address_1.trim()
                              "
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="address_2">
                            <template slot="label">
                              Address line 2
                              <span
                                class="sr-only"
                                aria-live="polite"
                                :hidden="
                                  !hasInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'address_2'
                                  )
                                "
                                v-text="
                                  getInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'address_2'
                                  )
                                "
                              ></span>
                            </template>
                            <el-input
                              id="address_2"
                              v-model="newCompanyForm.company.address_2"
                              maxlength="255"
                              autocomplete="false"
                              label="Please enter your company address line 2 in this field"
                              @blur="
                                newCompanyForm.company.address_2 =
                                  newCompanyForm.company.address_2.trim()
                              "
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="town">
                            <template slot="label">
                              City
                              <span
                                class="sr-only"
                                aria-live="polite"
                                :hidden="
                                  !hasInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'town'
                                  )
                                "
                                v-text="
                                  getInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'town'
                                  )
                                "
                              ></span>
                            </template>
                            <el-input
                              id="town"
                              v-model="newCompanyForm.company.town"
                              maxlength="100"
                              autocomplete="false"
                              label="Please enter your company city in this field"
                              aria-required="true"
                              @blur="
                                newCompanyForm.company.town =
                                  newCompanyForm.company.town.trim()
                              "
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="county">
                            <template slot="label">
                              County
                              <span
                                class="sr-only"
                                aria-live="polite"
                                :hidden="
                                  !hasInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'county'
                                  )
                                "
                                v-text="
                                  getInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'county'
                                  )
                                "
                              ></span>
                            </template>
                            <el-input
                              id="county"
                              v-model="newCompanyForm.company.county"
                              maxlength="100"
                              autocomplete="false"
                              label="Please enter your company county in this field"
                              @blur="
                                newCompanyForm.company.county =
                                  newCompanyForm.company.county.trim()
                              "
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :md="24" :lg="18">
                          <el-form-item prop="post_code">
                            <template slot="label">
                              Postcode
                              <span
                                class="sr-only"
                                aria-live="polite"
                                :hidden="
                                  !hasInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'post_code'
                                  )
                                "
                                v-text="
                                  getInlineValidationErrorFor(
                                    'newCompanyForm',
                                    'post_code'
                                  )
                                "
                              ></span>
                            </template>
                            <el-input
                              id="post_code"
                              v-model="newCompanyForm.company.post_code"
                              class="half-content"
                              maxlength="10"
                              autocomplete="false"
                              label="Please enter your company postcode in this field"
                              aria-required="true"
                              @blur="
                                newCompanyForm.company.post_code =
                                  newCompanyForm.company.post_code.trim()
                              "
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                    <el-row>
                      <el-col :md="24" :lg="20">
                        <label for="role">
                          Your role at company
                          <span
                            class="sr-only"
                            aria-live="polite"
                            :hidden="
                              !hasInlineValidationErrorFor(
                                'newCompanyForm',
                                'role'
                              )
                            "
                            v-text="
                              getInlineValidationErrorFor(
                                'newCompanyForm',
                                'role'
                              )
                            "
                          ></span>
                        </label>
                        <el-form-item prop="role">
                          <el-input
                            id="role"
                            v-model="newCompanyForm.company.role"
                            autocomplete="false"
                            maxlength="255"
                            label="Please enter your company role in this field"
                            aria-required="true"
                            @blur="
                              newCompanyForm.company.role =
                                newCompanyForm.company.role.trim()
                            "
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <div v-loading="newCompanyForm.groupsLoading">
                      <el-row>
                        <el-col :md="24" :lg="20" class="d-flex flex-column">
                          <label for="id-group-label">
                            <span class="se-form-label-l-screen">
                              Your company's sector<span
                                class="is-custom-asterisk"
                                >*</span
                              >
                            </span>
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'group_ids'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'group_ids'
                                )
                              "
                            ></span>
                          </label>
                          <span
                            class="se-form-label-l-screen color-light-gray mb-4"
                          >
                            You can select multiple sectors
                          </span>

                          <el-form-item prop="group_ids" class="is-no-asterisk">
                            <checkbox-group
                              id="id-group-label"
                              v-model="newCompanyForm.company.group_ids"
                              aria-label="Your company's sector, Please check one or more sectors"
                              aria-labelledby="id-group-label"
                              name="group_ids"
                              :groups="newCompanyForm.groups"
                              @input="updateGroupIds"
                              @clicked="selectedGroupsUpdated"
                            ></checkbox-group>
                            <!--                            <el-checkbox-group-->
                            <!--                              v-model="newCompanyForm.company.group_ids"-->
                            <!--                              aria-label="Please check one or more sectors">-->
                            <!--                              <el-row>-->
                            <!--                                <el-col :md="24" :lg="12" class="mb-4" :key="index" v-for="(group, index) in newCompanyForm.groups">-->
                            <!--                                  <el-checkbox-->
                            <!--                                    name="group_ids"-->
                            <!--                                    :label="group.id"-->
                            <!--                                    @change="selectedGroupsUpdated(group.id)">-->
                            <!--                                    {{ group.name }}-->
                            <!--                                  </el-checkbox>-->
                            <!--                                </el-col>-->
                            <!--                              </el-row>-->
                            <!--                            </el-checkbox-group>-->
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row
                        v-if="newCompanyForm.company.edit_sub_groups.length > 0"
                      >
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="sub_group_ids">
                            <template slot="label">
                              <span aria-hidden="true"
                                >Your company's sub sectors - Optional</span
                              >
                              <span class="sr-only"
                                >Please select one or more sub sectors</span
                              >
                            </template>
                            <v-select
                              v-model="newCompanyForm.company.sub_group_ids"
                              :options="newCompanyForm.company.edit_sub_groups"
                              :multiple="true"
                              :clearable="false"
                              label="name"
                              :reduce="(subGroup) => subGroup.id"
                              input-id="sub_group_ids"
                              autocomplete="false"
                              :select-on-tab="true"
                              :close-on-select="false"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                    <span class="warning"
                      >Please submit your company details by selecting the Add
                      Company button before proceeding.</span
                    >

                    <el-row class="mt-4 mb-4">
                      <el-col :md="24" :lg="15" class="d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="submitNewCompanyForm"
                        >
                          <span> Add Company </span>
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-form>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-2">Mailing Address</h2>
                <p class="se-body-l-screen mb-4">
                  Please select your preferred mailing address. We'll use this
                  if we need to get in touch by post.
                </p>

                <el-row>
                  <el-radio-group
                    v-model="step1Form.mailingOption"
                    aria-label="Select an address"
                    @change="onMailingOptionSelected"
                  >
                    <el-col
                      v-for="(business, index) in step1Form.form.businesses"
                      :key="index"
                      :md="24"
                      class="mb-4"
                    >
                      <el-radio :label="business.company_house_no">
                        <span class="color-primary">{{ business.name }}</span
                        ><br />
                        <span v-if="business.address_1"
                          >{{ business.address_1 }},</span
                        >
                        <span v-if="business.address_2"
                          >{{ business.address_2 }},<br
                        /></span>
                        <span v-if="business.town">{{ business.town }},</span>
                        <span v-if="business.county"
                          >{{ business.county }},</span
                        >
                        <span v-if="business.post_code">{{
                          business.post_code
                        }}</span>
                      </el-radio>
                    </el-col>
                    <el-col :md="24" class="mb-4">
                      <el-radio label="other"> Other </el-radio>
                    </el-col>
                  </el-radio-group>
                </el-row>

                <div
                  v-if="step1Form.mailingOption == 'other'"
                  class="d-flex flex-column align-items-stretch"
                >
                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_post_code">
                        Post code
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_post_code'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_post_code'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_post_code">
                        <div class="d-flex">
                          <el-input
                            id="mailing_post_code"
                            v-model="step1Form.form.mailing_post_code"
                            class="half-flex-content"
                            maxlength="10"
                            autocomplete="false"
                            label="Please enter mailing post code in this field"
                            aria-required="true"
                            @blur="
                              step1Form.form.mailing_post_code =
                                step1Form.form.mailing_post_code.trim()
                            "
                            @input="step1Form.disableAddressLookup = false"
                            @keyup.enter.native="onFindAddress($event)"
                          />

                          <div class="mr-3"></div>

                          <el-form-item class="half-flex-content">
                            <el-button
                              size="medium"
                              :disabled="
                                step1Form.disableAddressLookup ||
                                step1Form.form.mailing_post_code.trim()
                                  .length == 0
                              "
                              @click="onFindAddress($event)"
                            >
                              Find address
                            </el-button>
                          </el-form-item>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div
                    v-if="step1Form.addressesLoading"
                    v-loading="step1Form.addressesLoading"
                    class="address-loader"
                  ></div>

                  <el-row v-if="step1Form.availableMailingAddresses.length > 0">
                    <el-col :md="24" :lg="20">
                      <el-form-item>
                        <template slot="label">
                          <span aria-hidden="true">Addresses Found</span>
                          <span class="sr-only">Please select an address</span>
                          <span
                            class="sr-only"
                            aria-live="polite"
                            :hidden="
                              !hasInlineValidationErrorFor(
                                'step1Form',
                                'available_mailing_addresses'
                              )
                            "
                            v-text="
                              getInlineValidationErrorFor(
                                'step1Form',
                                'available_mailing_addresses'
                              )
                            "
                          ></span>
                        </template>
                        <single-select
                          v-model="step1Form.selectedAvailableMailingAddress"
                          :options="step1Form.availableMailingAddresses"
                          name="available_mailing_addresses"
                          @input="onAvailableMailingAddressSelected($event)"
                        ></single-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_address_1">
                        Address line 1
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_1'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_1'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_address_1">
                        <el-input
                          id="mailing_address_1"
                          v-model="step1Form.form.mailing_address_1"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 1 in this field"
                          aria-required="true"
                          @blur="
                            step1Form.form.mailing_address_1 =
                              step1Form.form.mailing_address_1.trim()
                          "
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_address_2">
                        Address line 2
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_2'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_2'
                            )
                          "
                        ></span>
                      </label>
                      <el-form-item prop="mailing_address_2">
                        <el-input
                          id="mailing_address_2"
                          v-model="step1Form.form.mailing_address_2"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 2 in this field"
                          @blur="
                            step1Form.form.mailing_address_2 =
                              step1Form.form.mailing_address_2.trim()
                          "
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="18">
                      <label for="mailing_town">
                        Town or city
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_town'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_town'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_town">
                        <el-input
                          id="mailing_town"
                          v-model="step1Form.form.mailing_town"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing town or city in this field"
                          aria-required="true"
                          @blur="
                            step1Form.form.mailing_town =
                              step1Form.form.mailing_town.trim()
                          "
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="18">
                      <label for="mailing_county">
                        County
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_county'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_county'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_county">
                        <el-input
                          id="mailing_county"
                          v-model="step1Form.form.mailing_county"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing county in this field"
                          @blur="
                            step1Form.form.mailing_county =
                              step1Form.form.mailing_county.trim()
                          "
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="18">
                      <label for="mailing_country">
                        <span aria-hidden="true">Country</span>
                        <span class="sr-only"
                          >Please select mailing country</span
                        >
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_country_id'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_country_id'
                            )
                          "
                        ></span>
                      </label>
                      <el-form-item prop="mailing_country_id">
                        <single-select
                          id="mailing_country"
                          v-model="step1Form.form.mailing_country_id"
                          :options="step1Form.countries"
                          name="mailing_country_id"
                        ></single-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep1Form"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 1 Personal and Company details End -->

            <!-- Step 2 Plans and ambitions -->
            <div v-show="currentStep === 2" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(1)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 2"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step2Form"
                ref="step2Form"
                :model="step2Form.form"
                :rules="step2FormRules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step2Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Plans and ambitions
                </h1>

                <el-row>
                  <el-col :md="24" :lg="18">
                    <p class="se-body-l-screen mb-4">
                      In this section, we want to you tell us more about your
                      businesses and what you’re looking for from the network.
                      This will help our GlobalScots understand how they can
                      help. If you’ve added more than one company, you should
                      cover all of them in these questions.
                    </p>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4"></div>

                <el-row>
                  <el-col :md="24" :lg="17">
                    <label for="ambitions">
                      Tell us a bit more about what your company (or companies)
                      does and what your ambitions are.<span
                        class="is-custom-asterisk"
                        >*</span
                      ><br />
                      <span class="el-form-item__info"
                        >For example, you could tell us about your product or
                        service, company size, achievements, and what you want
                        to do next.</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'ambitions')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'ambitions')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="ambitions" class="is-no-asterisk">
                      <el-input
                        id="ambitions"
                        v-model="step2Form.form.ambitions"
                        type="textarea"
                        :rows="5"
                        maxlength="500"
                        label="Please enter a bit more about what your company or companies does and what your ambitions are in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :md="24" class="mt-n4 mb-4">
                    <span class="se-body-l-screen color-light-gray"
                      >You have
                      {{ 500 - step2Form.form.ambitions.length }} characters
                      remaining</span
                    >
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="14">
                    <label for="aspects">
                      <span aria-hidden="true"
                        >What aspect(s) of business do you need help with?</span
                      >
                      <span class="sr-only"
                        >Please select one or more aspects your business needs
                        help with</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'aspects')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'aspects')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="aspects">
                      <v-select
                        id="aspects"
                        v-model="step2Form.form.aspects"
                        :options="step2Form.aspects"
                        :multiple="true"
                        :clearable="false"
                        label="slug"
                        :reduce="(aspect) => aspect.id"
                        input-id="aspects"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="17">
                    <label for="regions">
                      Which regions would you like to expand into? - Optional<br />
                      <span class="sr-only"
                        >Please select one or more regions you would like to
                        expand into</span
                      >
                      <span class="el-form-item__info">
                        You can select up to 5. This will help us match you to
                        GlobalScots in your target areas.<br />
                        You can change these at any time.
                      </span>
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'regions')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'regions')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="regions">
                      <v-select
                        id="regions"
                        v-model="step2Form.form.regions"
                        :options="step2Form.regions"
                        :multiple="true"
                        :clearable="false"
                        label="slug"
                        :reduce="(region) => region.id"
                        input-id="regions"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                        :selectable="() => step2Form.form.regions.length < 5"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep2Form"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 2 Plans and ambitions End -->

            <!-- Step 3 Create your account -->
            <div v-show="currentStep === 3" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(2)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 3"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step3Form"
                ref="step3Form"
                :model="step3Form.form"
                :rules="step3FormRules"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step3Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Create your account
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-4">
                  Upload a profile photo - Optional
                </h2>

                <div v-if="step3Form.avatarFile" class="uploaded-file d-flex">
                  <el-avatar
                    shape="circle"
                    :size="140"
                    :src="avatarUrl"
                    alt="Profile picture"
                    class="step-3-avatar"
                  ></el-avatar>

                  <div class="flex-fill">
                    <div class="upload-actions">
                      <el-upload
                        ref="upload"
                        action=""
                        :multiple="false"
                        :drag="true"
                        list-type="picture-card"
                        class="selected-upload"
                        :auto-upload="false"
                        :on-change="onFileChange"
                      >
                        <div slot="default">
                          <el-button class="replace-button">
                            Replace
                          </el-button>
                        </div>

                        <div
                          v-if="step3Form.avatarFile"
                          slot="file"
                          slot-scope="{ file }"
                        ></div>
                      </el-upload>

                      <span
                        href="#"
                        class="remove-file-link"
                        @click="removeSelectedAvatar"
                        >Remove</span
                      >
                    </div>
                  </div>
                </div>

                <el-upload
                  ref="upload"
                  action=""
                  :multiple="false"
                  :drag="true"
                  list-type="picture-card"
                  class="initial-upload"
                  :class="{ 'upload-show': step3Form.avatarFile }"
                  :auto-upload="false"
                  :on-change="onFileChange"
                >
                  <div slot="default">
                    <div class="se-file-upload">
                      <img
                        src="/assets/img/file-drag-drop.svg"
                        alt="Drag & drop files here"
                        class="drag-drop-img"
                      />
                      <span class="or-text se-body-s-screen-16 m-4">or</span>
                      <el-button class="browse-button">
                        Browse files
                      </el-button>
                    </div>
                  </div>

                  <div
                    v-if="step3Form.avatarFile"
                    slot="file"
                    slot-scope="{ file }"
                  ></div>
                </el-upload>

                <div class="step-hr upload-hr"></div>

                <h2 class="se-heading-m mb-4">Login details</h2>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="email">
                      Email address<span class="is-custom-asterisk">*</span
                      ><br />
                      <span class="el-form-item__info"
                        >This will not be displayed on your profile</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step3Form', 'email')
                        "
                        v-text="
                          getInlineValidationErrorFor('step3Form', 'email')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="email" class="is-no-asterisk">
                      <el-input
                        id="email"
                        v-model="step3Form.form.email"
                        type="email"
                        autocomplete="false"
                        label="Please enter your desired email address in this field"
                        aria-required="true"
                        @blur="
                          step3Form.form.email = step3Form.form.email.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="email_confirmation"
                      >Confirm email address
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step3Form',
                            'email_confirmation'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step3Form',
                            'email_confirmation'
                          )
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="email_confirmation">
                      <el-input
                        id="email_confirmation"
                        v-model="step3Form.form.email_confirmation"
                        type="email"
                        autocomplete="false"
                        label="Please confirm the desired email address in this field"
                        aria-required="true"
                        @blur="
                          step3Form.form.email_confirmation =
                            step3Form.form.email_confirmation.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="password"
                      >Password
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step3Form', 'password')
                        "
                        v-text="
                          getInlineValidationErrorFor('step3Form', 'password')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="password">
                      <el-input
                        id="password"
                        v-model="step3Form.form.password"
                        :show-password="true"
                        autocomplete="false"
                        label="Please enter your desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="password_confirmation">
                      Password confirmation
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step3Form',
                            'password_confirmation'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step3Form',
                            'password_confirmation'
                          )
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="password_confirmation">
                      <el-input
                        id="password_confirmation"
                        v-model="step3Form.form.password_confirmation"
                        :show-password="true"
                        autocomplete="false"
                        label="Please confirm the desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="18">
                    <el-form-item
                      id="is_russian_self_declared"
                      prop="is_russian_self_declared"
                    >
                      <el-checkbox-group
                        v-for="(
                          russianSelfDeclaration, index
                        ) in step3Form.russianSelfDeclarations"
                        :key="index"
                        v-model="step3Form.form.is_russian_self_declared"
                      >
                        <el-checkbox
                          class="pb-3"
                          :name="russianSelfDeclaration.key"
                          :label="russianSelfDeclaration.key"
                        >
                          {{ russianSelfDeclaration.text }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-5">
                    <label for="terms_agreed">
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step3Form',
                            'terms_agreed'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step3Form',
                            'terms_agreed'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item id="terms_agreed" prop="terms_agreed">
                      <el-checkbox-group v-model="step3Form.form.terms_agreed">
                        <el-checkbox
                          id="terms_agreed"
                          label="confirmed"
                          name="terms_agreed"
                        >
                          I agree to the
                          <a
                            :href="getCMSRoute('terms-conditions')"
                            class="text__link"
                            target="_blank"
                          >
                            Terms & conditions </a
                          >(including the GlobalScot acceptable use policy),
                          <a
                            :href="getCMSRoute('privacy-policy')"
                            class="text__link"
                            target="_blank"
                          >
                            privacy policy
                          </a>
                          and
                          <a
                            :href="getCMSRoute('cookie-policy')"
                            class="text__link"
                            target="_blank"
                          >
                            cookies policy
                          </a>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep3Form"
                >
                  Complete registration
                </el-button>
              </div>
            </div>
            <!-- Step 3 Create your account End -->

            <!-- Step 4 Verify your account -->
            <div v-show="currentStep === 4" class="body__content">
              <h1 class="se-heading-xl-40 mb-4">Check your email</h1>

              <el-row>
                <el-col :md="24" :lg="18">
                  <p class="se-body-l-screen-19 mb-4">
                    Thanks for registering with the GlobalScot network. We’ve
                    emailed you a link to verify your email address, which you
                    need to click to access the full network.<br />
                    <br />
                    Didn’t receive the email? Try checking your junk mail or we
                    can resend the email.
                  </p>
                </el-col>
              </el-row>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  :loading="isResendingEmailLoading"
                  @click="onResendEmail()"
                >
                  Resend email
                </el-button>
              </div>

              <p class="se-body-l-screen-19 mb-4">
                If you don’t receive your email within 1 hour,
                <a :href="getCMSRoute('contact-us')" class="text__link">
                  <b>contact us</b> </a
                >.
              </p>
            </div>
            <!-- Step 4 Verify your account End -->
          </el-col>
        </el-row>
      </div>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import loqateApi from "@/services/loqate.api";
import svgLinkPurple from "@/components/assets/svg-link-purple";
import CropperUpload from "@/components/cropper-upload/cropper-upload";
import manageBusiness from "@/components/partials/nongs-register-manage-business";
import validationErrors from "@/components/partials/validation-errors";
import { validate } from "email-validator";

export default {
  name: "SignupNgs",
  metaInfo: {
    title: "Join as a business member",
    meta: [
      {
        name: "description",
        content:
          "Are you a business, entrepreneur or professional based in Scotland and looking for support? Join the free GlobalScot network as a business member.",
      },
    ],
  },
  components: {
    svgLinkPurple,
    manageBusiness,
    validationErrors,
    "cropper-upload": CropperUpload,
  },
  data: function () {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else {
        callback();
      }
    };
    const validateEmailConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else if (value !== this.step3Form.form.email) {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      let reg =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[a-zA-Z0-9#?!@$%^&*-]{8,}$/;
      if (value === "") {
        callback(new Error("Enter a password"));
      } else if (reg.test(value) == false) {
        callback(
          new Error(
            "Password must be more than 8 characters long, should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character (#?!@$%^&*-)."
          )
        );
      } else {
        callback();
      }
    };
    const validatePasswordConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Password doesn’t match. Make sure the password entered in both password fields is the same"
          )
        );
      } else if (value !== this.step3Form.form.password) {
        callback(
          new Error(
            "Password doesn’t match. Make sure the password entered in both password fields is the same"
          )
        );
      } else {
        callback();
      }
    };
    const validateContactNumber = (rule, value, callback) => {
      let reg =
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 7) {
        callback(new Error("Contact number may not be less than 7 characters"));
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Contact number field must be valid"));
      } else {
        callback();
      }
    };
    const validateCompanyHouseNo = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Enter a valid Companies House registration number. It should contain 2 letters and 6 numbers."
          )
        );
      } else if (
        !["SA", "SC", "SF", "SI", "SL", "SO", "SP", "SR", "SZ"].includes(
          value.substring(0, 2).toUpperCase()
        )
      ) {
        callback(
          new Error(
            "It looks like that number is for a company based outside of Scotland. Check the number you’ve entered or add a different company."
          )
        );
      } else {
        callback();
      }
    };
    const validateAgreement = (rule, value, callback) => {
      if (value.length < 3) {
        callback(new Error("You cannot continue without checking all boxes"));
      } else {
        callback();
      }
    };

    return {
      currentStep: 0,
      validationErrors: [],
      mainLoader: false,
      isResendingEmailLoading: false,
      signedUpId: null,
      signedUpEmail: null,

      cropperFile: null,
      showCropperDialog: false,

      newCompanyForm: {
        company: {
          input_company_house_no: "",
          company_house_no: "",
          name: "",
          address_1: "",
          address_2: "",
          post_code: "",
          town: "",
          county: "",
          role: "",
          group_ids: [],
          sub_group_ids: [],
          incorporation_date: "",
          company_found: false,
          edit_sub_groups: [],
          is_mailing_address: false,
          groups_loading: false,
        },
        additionalCompanyFormOpen: false,
        isAddressCorrect: true,
        companyLoading: false,
        groupsLoading: false,
        groups: [],
        subGroups: [],
        inlineValidationErrors: [],
      },

      newCompanyFormRules: {
        address_1: [
          {
            required: true,
            message: "Enter the first line of your address",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Address line 1 may not be greater than 255 characters",
            trigger: "change",
          },
        ],
        address_2: [
          {
            max: 255,
            message: "Address line 2 may not be greater than 255 characters",
            trigger: "change",
          },
        ],
        town: [
          { required: true, message: "Enter a city", trigger: "blur" },
          {
            max: 100,
            message: "City may not be greater than 100 characters",
            trigger: "change",
          },
        ],
        county: [
          {
            max: 100,
            message: "County may not be greater than 100 characters",
            trigger: "change",
          },
        ],
        post_code: [
          { required: true, message: "Enter a postcode", trigger: "blur" },
          {
            max: 10,
            message: "Postcode may not be greater than 10 characters",
            trigger: "change",
          },
        ],
        role: [
          { required: true, message: "Enter a role", trigger: "blur" },
          {
            max: 255,
            message: "Role may not be greater than 255 characters",
            trigger: "change",
          },
        ],
        group_ids: [
          {
            type: "array",
            required: true,
            message: "Select a sector",
            trigger: "change",
          },
        ],
        input_company_house_no: [
          { validator: validateCompanyHouseNo, required: true },
        ],
      },

      step1Form: {
        // Step 1
        form: {
          title: "",
          first_name: "",
          last_name: "",
          contact_number: "",
          businesses: [],
          has_mailing_address: true,
          mailing_address_1: "",
          mailing_address_2: "",
          mailing_post_code: "",
          mailing_town: "",
          mailing_county: "",
          mailing_country_id: null,
        },

        currentCompanyHouseAddress: {
          name: "",
          address_1: "",
          address_2: "",
          post_code: "",
          town: "",
          county: "",
        },

        mailingOption: "other",

        titles: [
          { id: 1, label: "Mr" },
          { id: 2, label: "Mrs" },
          { id: 3, label: "Ms" },
          { id: 4, label: "Miss" },
          { id: 6, label: "Dr" },
          { id: 7, label: "Prof" },
          { id: 8, label: "Sir" },
          { id: 9, label: "Dame" },
          { id: 10, label: "Reverend" },
          { id: 11, label: "Duke" },
          { id: 12, label: "Duchess" },
          { id: 5, label: "Other" },
          { id: 13, label: "Mx" },
        ],
        usePrimaryMailingAddress: false,
        useOtherMailingAddress: true,
        countries: [],
        addressesLoading: false,
        disableAddressLookup: false,
        selectedAvailableMailingAddress: null,
        availableMailingAddresses: [],
        inlineValidationErrors: [],
      },

      step1FormRules: {
        title: [
          {
            required: true,
            message: "Select a title from the dropdown",
            trigger: "change",
          },
        ],
        first_name: [
          { required: true, message: "Enter your first name", trigger: "blur" },
          {
            max: 64,
            message: "First name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Enter your last name", trigger: "blur" },
          {
            max: 64,
            message: "Last name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        contact_number: [{ validator: validateContactNumber, required: true }],
        mailing_post_code: [
          { required: true, message: "Enter a postcode", trigger: "blur" },
          {
            max: 10,
            message: "Postcode may not be greater than 10 characters",
            trigger: "blur",
          },
        ],
        mailing_address_1: [
          {
            required: true,
            message: "Enter the first line of your address",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Address line 1 may not be greater than 255 characters",
            trigger: "blur",
          },
        ],
        mailing_address_2: [
          {
            max: 255,
            message: "Address line 2 may not be greater than 255 characters",
            trigger: "blur",
          },
        ],
        mailing_town: [
          { required: true, message: "Enter a town or city", trigger: "blur" },
          {
            max: 100,
            message: "Town or City may not be greater than 100 characters",
            trigger: "blur",
          },
        ],
        mailing_county: [
          { required: true, message: "Enter a county", trigger: "blur" },
          {
            max: 100,
            message: "County may not be greater than 100 characters",
            trigger: "blur",
          },
        ],
        mailing_country_id: [
          { required: true, message: "Enter a country", trigger: "change" },
        ],
      },

      step2Form: {
        // Step 2
        form: {
          ambitions: "",
          aspects: [],
          regions: [],
        },
        aspects: [],
        regions: [],
        inlineValidationErrors: [],
      },

      step2FormRules: {
        ambitions: [
          {
            required: true,
            message: "Tell us more about your company",
            trigger: "blur",
          },
          {
            max: 500,
            message: "Ambitions may not be greater than 500 characters",
            trigger: "change",
          },
        ],
        aspects: [
          {
            type: "array",
            required: true,
            message: "Select an aspect of business from the dropdown",
            trigger: "change",
          },
        ],
      },

      // Step 3
      step3Form: {
        form: {
          avatar: null,
          email: "",
          email_confirmation: "",
          password: "",
          password_confirmation: "",
          is_russian_self_declared: [],
          terms_agreed: [],
        },
        russianSelfDeclarations: [
          {
            key: "code_conduct_readed",
            text: "I have read the Code of Conduct.",
          },
          {
            key: "ask_invertment_with",
            text: "I also understand that as part of my registration Scottish Enterprise may ask about the nature of trading and/or investment links with Russia and Belarus.",
          },
          {
            key: "no_subject_to_uk_sanctions",
            text: "I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.",
          },
        ],
        avatarFile: null,
        inlineValidationErrors: [],
      },

      step3FormRules: {
        email: [{ validator: validateEmail, required: true }],
        email_confirmation: [
          { validator: validateEmailConfirmation, required: true },
        ],
        password: [{ validator: validatePassword, required: true }],
        password_confirmation: [
          { validator: validatePasswordConfirmation, required: true },
        ],
        is_russian_self_declared: [
          { validator: validateAgreement, required: true },
        ],
        terms_agreed: [
          {
            type: "array",
            required: true,
            message:
              "Check the box to confirm you agree to our terms and conditions.",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    heroText: function () {
      if (this.currentStep == 1) {
        return '"GlobalScot has had such a huge impact on our business - frankly, it could have been the difference between us making it or not." <p class="mt-3">David Capaldi – Opinurate</p>';
      } else if (this.currentStep == 2) {
        return "From AI and green tech to sculpture and space exploration, our community’s knowledge is always growing.";
      } else if (this.currentStep == 3) {
        return "The GlobalScot network has been supporting businesses in Scotland for almost 20 years";
      } else if (this.currentStep == 4) {
        return "Registration complete! You're almost there.";
      } else {
        return "Over 20 years of ambition and impact. One shared passion for Scotland’s success.";
      }
    },
    avatarUrl: function () {
      return this.step3Form.avatarFile.url;
    },
  },
  created() {
    this.getRegisterMeta();
  },
  methods: {
    onCompanyAddressCorrectChange(correct) {
      if (correct) {
        this.newCompanyForm.company.address_1 =
          this.currentCompanyHouseAddress.address_1;
        this.newCompanyForm.company.address_2 =
          this.currentCompanyHouseAddress.address_2;
        this.newCompanyForm.company.town = this.currentCompanyHouseAddress.town;
        this.newCompanyForm.company.county =
          this.currentCompanyHouseAddress.county;
        this.newCompanyForm.company.post_code =
          this.currentCompanyHouseAddress.post_code;
      }
    },
    openAdditionalCompanyForm() {
      this.newCompanyForm.additionalCompanyFormOpen = true;
    },
    onUpdateBusiness(business, updatedBusiness) {
      business.address_1 = updatedBusiness.address_1;
      business.address_2 = updatedBusiness.address_2;
      business.post_code = updatedBusiness.post_code;
      business.town = updatedBusiness.town;
      business.county = updatedBusiness.county;
      business.role = updatedBusiness.role;
      business.group_ids = updatedBusiness.group_ids;
      business.sub_group_ids = updatedBusiness.sub_group_ids;
      business.edit_sub_groups = updatedBusiness.edit_sub_groups;
    },
    onRemoveBusiness(business) {
      this.$confirm(
        "Are you sure you want to remove this company?",
        "Remove company",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "modal-confirm-button",
          cancelButtonClass: "modal-cancel-button",
          type: "warning",
        }
      )
        .then(() => {
          if (business.is_mailing_address) {
            this.step1Form.mailingOption = "other";
            this.step1Form.form.has_mailing_address = true;
          }

          this.step1Form.form.businesses.splice(
            this.step1Form.form.businesses.indexOf(business),
            1
          );

          if (this.step1Form.form.businesses.length === 0) {
            this.newCompanyForm.additionalCompanyFormOpen = false;
          }

          this.$alert("Company has been removed.", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .catch(() => {});
    },
    onMailingOptionSelected() {
      if (this.step1Form.mailingOption == "other") {
        this.step1Form.form.has_mailing_address = true;

        if (this.step1Form.form.businesses.length > 0) {
          this.step1Form.form.businesses.forEach((business) => {
            business.is_mailing_address = false;
          });
        }
      } else {
        this.step1Form.form.has_mailing_address = false;

        let company = this.step1Form.form.businesses.find(
          (business) =>
            business.company_house_no == this.step1Form.mailingOption
        );
        if (company) {
          company.is_mailing_address = true;
        }
      }
    },

    // Start of upload / cropper methods
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file;
        this.showCropperDialog = true;
      }
    },
    onCropperUpload(croppedFileData) {
      this.step3Form.avatarFile = croppedFileData;
      this.step3Form.form.avatar = croppedFileData.file;
      this.onCropperDialogClose();
    },
    onCropperDialogClose() {
      this.showCropperDialog = false;
      this.cropperFile = null;
    },
    removeSelectedAvatar() {
      this.step3Form.avatarFile = null;
      this.step3Form.form.avatar = null;
      this.cropperFile = null;
      this.$refs.upload.clearFiles();
    },
    validateAvatarUpload(file) {
      let type = file.raw && file.raw.type ? file.raw.type : file.type;
      let size = file.raw && file.raw.size ? file.raw.size : file.size;

      const isJPG = type === "image/jpeg";
      const isPNG = type === "image/png";
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert("Avatar must be in a jpeg or png format.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      } else if (!isLt6M) {
        this.$alert("Avatar size can not exceed 6MB.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    // End of upload / cropper methods

    submitStep1Form() {
      this.$refs["step1Form"].validate((valid) => {
        if (valid) {
          if (this.step1Form.form.businesses.length === 0) {
            document.getElementById("newCompanyForm").scrollIntoView();
            this.$alert(
              "Please add at least one company before proceeding.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
            return;
          }

          this.mainLoader = true;

          gsApi.member
            .validateStepOne(this.step1Form.form)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.newCompanyForm.additionalCompanyFormOpen = false;
              this.gotoStep(2);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step1Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step1Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitNewCompanyForm() {
      if (this.step1Form.form.businesses.length < 3) {
        this.$refs["newCompanyForm"].validate((valid) => {
          if (valid) {
            if (!this.newCompanyForm.company.company_found) {
              this.$alert(
                "Please find your company in companies house.",
                "Error",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal",
                }
              );
              return;
            }

            this.newCompanyForm.company.sub_groups = JSON.parse(
              JSON.stringify(this.newCompanyForm.company.edit_sub_groups)
            );

            this.step1Form.form.businesses.push(this.newCompanyForm.company);

            if (this.step1Form.form.businesses.length == 1) {
              this.step1Form.mailingOption =
                this.step1Form.form.businesses[0].company_house_no;
              this.step1Form.form.businesses[0].is_mailing_address = true;
              this.step1Form.form.has_mailing_address = false;
            }

            this.newCompanyForm.company = {
              company_house_no: "",
              input_company_house_no: "",
              name: "",
              address_1: "",
              address_2: "",
              post_code: "",
              town: "",
              county: "",
              role: "",
              group_ids: [],
              sub_group_ids: [],
              incorporation_date: "",
              company_found: false,
              edit_sub_groups: [],
              groups_loading: false,
              is_mailing_address: false,
            };

            this.currentCompanyHouseAddress = {
              name: "",
              address_1: "",
              address_2: "",
              post_code: "",
              town: "",
              county: "",
            };

            this.newCompanyForm.isAddressCorrect = true;
            this.newCompanyForm.additionalCompanyFormOpen = false;

            this.$nextTick(() => {
              this.$refs["newCompanyForm"].resetFields();
              document.getElementById("businesses-section").scrollIntoView();
              this.$alert("Company has been added.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
            });
          } else {
            this.$nextTick(() => {
              document
                .getElementById("newCompanyForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView();
              let text = document
                .getElementById("newCompanyForm")
                .getElementsByClassName("el-form-item__error")[0].textContent;
              this.$announcer.set(text);
            });
          }
        });
      } else {
        this.$alert("You can only add a maximum of 3 companies.", "Warning", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      }
    },
    submitStep2Form() {
      this.$refs["step2Form"].validate((valid) => {
        if (valid) {
          this.mainLoader = true;

          gsApi.member
            .validateStepTwo(this.step2Form.form)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(3);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step2Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step2Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitStep3Form() {
      this.$refs["step3Form"].validate((valid) => {
        if (valid) {
          const formData = new FormData();

          // Step 1
          formData.append("title", this.step1Form.form.title);
          formData.append(
            "first_name",
            this.$options.filters.domPurify(this.step1Form.form.first_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append(
            "last_name",
            this.$options.filters.domPurify(this.step1Form.form.last_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append("contact_number", this.step1Form.form.contact_number);

          this.step1Form.form.businesses.forEach((business, index) => {
            formData.append(
              `businesses[${index}][company_house_no]`,
              business.company_house_no
            );
            formData.append(`businesses[${index}][name]`, business.name);
            formData.append(`businesses[${index}][role]`, business.role);
            formData.append(
              `businesses[${index}][address_1]`,
              business.address_1
            );
            formData.append(
              `businesses[${index}][address_2]`,
              business.address_2
            );
            formData.append(
              `businesses[${index}][post_code]`,
              business.post_code
            );
            formData.append(`businesses[${index}][town]`, business.town);
            formData.append(`businesses[${index}][county]`, business.county);
            formData.append(
              `businesses[${index}][is_mailing_address]`,
              business.is_mailing_address
            );

            business.group_ids.forEach((groupId) => {
              formData.append(`businesses[${index}][group_ids][]`, groupId);
            });
            formData.append(`businesses[${index}][sub_group_ids]`, []);
            business.sub_group_ids.forEach((groupId) => {
              formData.append(`businesses[${index}][sub_group_ids][]`, groupId);
            });
          });

          formData.append(
            "has_mailing_address",
            this.step1Form.form.has_mailing_address
          );

          if (this.step1Form.form.has_mailing_address) {
            formData.append(
              "mailing_address_1",
              this.step1Form.form.mailing_address_1
            );
            formData.append(
              "mailing_address_2",
              this.step1Form.form.mailing_address_2
            );
            formData.append(
              "mailing_post_code",
              this.step1Form.form.mailing_post_code
            );
            formData.append("mailing_town", this.step1Form.form.mailing_town);
            formData.append(
              "mailing_county",
              this.step1Form.form.mailing_county
            );
            formData.append(
              "mailing_country_id",
              this.step1Form.form.mailing_country_id
            );
          }

          // Step 2
          formData.append("ambitions", this.step2Form.form.ambitions);

          formData.append("aspects", []);
          this.step2Form.form.aspects.forEach((aspect) => {
            formData.append("aspects[]", aspect);
          });
          formData.append("regions", []);
          this.step2Form.form.regions.forEach((region) => {
            formData.append("regions[]", region);
          });

          // Step 3
          formData.append("email", this.step3Form.form.email);
          formData.append(
            "email_confirmation",
            this.step3Form.form.email_confirmation
          );
          formData.append("password", this.step3Form.form.password);
          formData.append(
            "password_confirmation",
            this.step3Form.form.password_confirmation
          );

          if (this.step3Form.form.avatar) {
            formData.append("avatar", this.step3Form.form.avatar);
          }

          this.step3Form.form.is_russian_self_declared.forEach((item) => {
            formData.append("is_russian_self_declared[]", item);
          });

          this.mainLoader = true;

          gsApi.member
            .register(formData)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.signedUpId = response.data.id;
              this.signedUpEmail = response.data.email;
              this.gotoStep(4);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step3Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step3Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    onResendEmail() {
      this.isResendingEmailLoading = true;
      gsApi.auth
        .resendVerification(this.signedUpId, this.signedUpEmail)
        .then((response) => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "A new email has been sent. Check your inbox.",
            "Success",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        })
        .catch(() => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.member.meta
        .index()
        .then((response) => {
          this.mainLoader = false;

          this.newCompanyForm.groups = response.data.groups.data;
          this.step2Form.aspects = response.data.aspects.data;
          this.step2Form.regions = response.data.regions.data;
          this.step1Form.countries = response.data.countries.data;
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.gotoLink("/home");
        });
    },
    updateGroupIds(groupIds) {
      this.$refs.newCompanyForm.validateField("group_ids");
      this.newCompanyForm.company.group_ids = groupIds;
    },
    selectedGroupsUpdated(groupId) {
      if (this.newCompanyForm.company.group_ids.includes(groupId)) {
        this.newCompanyForm.groupsLoading = true;

        gsApi.group.subGroups(groupId).then((response) => {
          this.newCompanyForm.groupsLoading = false;

          response.data.data.forEach((subgroup) => {
            this.newCompanyForm.company.edit_sub_groups.push(subgroup);
          });
        });
      } else {
        // Removing selected sub groups where sub group is about to be removed below
        let subGroupsToBeRemoved =
          this.newCompanyForm.company.edit_sub_groups.filter(
            (item) => item.group_id == groupId
          );
        subGroupsToBeRemoved.forEach((subgroup) => {
          if (this.newCompanyForm.company.sub_group_ids.includes(subgroup.id)) {
            let index = this.newCompanyForm.company.sub_group_ids.indexOf(
              subgroup.id
            );
            this.newCompanyForm.company.sub_group_ids.splice(index, 1);
          }
        });

        // Removing sub groups with group_id of selected group
        let newSubgroups = this.newCompanyForm.company.edit_sub_groups.filter(
          (item) => item.group_id !== groupId
        );
        this.newCompanyForm.company.edit_sub_groups = newSubgroups;
      }
    },
    onCompanyHouseNoEnter(e) {
      this.$refs.btnCompanySearch.$el.click();
    },
    formatCompanyHouseNoForRequest() {
      const prefix1 =
        this.newCompanyForm.company.input_company_house_no.charAt(0);
      const prefix2 =
        this.newCompanyForm.company.input_company_house_no.charAt(1);

      return this.newCompanyForm.company.input_company_house_no.replace(
        `${prefix1}${prefix2}`,
        `${prefix1.toUpperCase()}${prefix2.toUpperCase()}`
      );
    },
    searchForCompany() {
      if (
        this.step1Form.form.businesses.length > 0 &&
        this.step1Form.form.businesses.find(
          (b) =>
            b.company_house_no ===
            this.newCompanyForm.company.input_company_house_no
        )
      ) {
        this.$alert("You have already added this company.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
        return;
      }

      this.newCompanyForm.companyLoading = true;

      this.newCompanyForm.company.input_company_house_no
        .charAt(0)
        .toUpperCase();
      this.newCompanyForm.company.input_company_house_no
        .charAt(1)
        .toUpperCase();

      gsApi.companyHouse
        .getForNumber(this.formatCompanyHouseNoForRequest())
        .then((response) => {
          if (
            (response.data.status && response.data.status == "active") ||
            (response.data.status && response.data.status == "Active")
          ) {
            this.newCompanyForm.companyLoading = false;

            this.newCompanyForm.company.name = response.data.name
              ? response.data.name
              : "";
            this.newCompanyForm.company.address_1 = response.data.address_line_1
              ? response.data.address_line_1
              : "";
            this.newCompanyForm.company.address_2 = response.data.address_line_2
              ? response.data.address_line_2
              : "";
            this.newCompanyForm.company.post_code = response.data.postcode
              ? response.data.postcode
              : "";
            this.newCompanyForm.company.town = response.data.city
              ? response.data.city
              : "";
            this.newCompanyForm.company.county = response.data.region
              ? response.data.region
              : "";
            this.newCompanyForm.company.company_house_no = response.data
              .company_number
              ? response.data.company_number
              : "";
            this.newCompanyForm.company.input_company_house_no = response.data
              .company_number
              ? response.data.company_number
              : "";
            this.newCompanyForm.company.incorporation_date = response.data
              .date_of_creation
              ? response.data.date_of_creation
              : "";

            this.newCompanyForm.company.company_found = true;

            this.newCompanyForm.isAddressCorrect = true;

            this.currentCompanyHouseAddress = {
              name: response.data.name ? response.data.name : "",
              address_1: response.data.address_line_1
                ? response.data.address_line_1
                : "",
              address_2: response.data.address_line_2
                ? response.data.address_line_2
                : "",
              post_code: response.data.postcode ? response.data.postcode : "",
              town: response.data.city ? response.data.city : "",
              county: response.data.region ? response.data.region : "",
            };
          } else {
            this.newCompanyForm.companyLoading = false;
            this.newCompanyForm.company.company_house_no = "";
            this.newCompanyForm.company.input_company_house_no = "";
            this.$alert(
              "We couldn’t find a company matching that registration number.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.newCompanyForm.companyLoading = false;
          this.newCompanyForm.company.company_house_no = "";
          this.newCompanyForm.company.input_company_house_no = "";

          if (
            error.response &&
            error.response.status == 404 &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.message
          ) {
            this.$alert(
              "We couldn’t find a company matching that registration number.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        });
    },
    onFindAddress(e) {
      this.findAddresses(this.step1Form.form.mailing_post_code);
    },
    findAddresses(value, containerId = "") {
      this.step1Form.addressesLoading = true;
      loqateApi
        .find(value, containerId, "")
        .then((response) => {
          this.step1Form.addressesLoading = false;
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal",
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.step1Form.disableAddressLookup = true;
              this.step1Form.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.step1Form.addressesLoading = false;
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    onAvailableMailingAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then((response) => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {
            this.step1Form.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.step1Form.form.mailing_address_1 = address.Line1;
            this.step1Form.form.mailing_address_2 = address.Line2;
            this.step1Form.form.mailing_post_code = address.PostalCode;
            this.step1Form.form.mailing_town = address.City;
            this.step1Form.form.mailing_county = address.AdminAreaName;

            let country = this.step1Form.countries.find(
              (c) =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.step1Form.form.mailing_country_id = country.id;
            }
          } else {
            this.$alert(
              "No Postcode found. Please add it manually.",
              "Warning",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Warning",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    gotoStep(step) {
      this.currentStep = step || 0;
      if (this.currentStep == 1) {
        this.$announcer.set("Step 1 of 3 - Personal and company details");
      } else if (this.currentStep == 2) {
        this.$announcer.set("Step 2 of 3 - Plans and ambitions");
      } else if (this.currentStep == 3) {
        this.$announcer.set("Step 3 of 3 -  Create your account");
      } else if (this.currentStep == 4) {
        this.$announcer.set("Verify your account");
      }
      window.scrollTo(0, 0);
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    handleFormFieldInput(formName) {
      this[formName].inlineValidationErrors = [];
      this.$refs[formName].fields.forEach((field) => {
        if (field.validateState === "error") {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this[formName].inlineValidationErrors.push(obj);
        }
      });
    },
    hasInlineValidationErrorFor(formName, fieldName) {
      return this.getInlineValidationErrorFor(formName, fieldName).length > 0;
    },
    getInlineValidationErrorFor(formName, fieldName) {
      const validationError = this[formName].inlineValidationErrors.find(
        (error) => error.id === fieldName
      );
      if (!validationError) {
        return "";
      }

      return validationError.message;
    },
  },
};
</script>

<style>
.step-3-avatar img {
  width: 140px;
  height: 140px;
}

.warning {
  color: red;
}
</style>
