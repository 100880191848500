<template>
  <div class="personal-details" v-loading="mainLoader" element-loading-background="rgba(255, 255, 255, 1)">
    <h2>Personal details</h2>
    <el-form id="memberForm" :model="form" :rules="rules" ref="memberForm" @submit.prevent.native="onSubmit($event)" @validate="handleNonGsPersonalDetailsInput">
      <el-row>
        <el-col :sm="24">
          <el-form-item prop="image" v-loading="removeAvatarLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
            <div class="d-flex upload-photo" v-if="currentAvatar">
              <el-avatar v-if="currentAvatar" shape="circle" :size="140" :src="currentAvatar" alt="Profile picture"></el-avatar>

              <el-upload
                action=""
                ref="upload"
                :multiple="false"
                list-type="picture-card"
                class="selected-upload"
                :auto-upload="false"
                :on-change="onFileChange"
                :disabled="avatarLoader"
                >

                <div slot="default">
                  <el-button class="replace-button">{{ customAvatar ? 'Replace' : 'Browse files' }}</el-button>
                </div>

                <div slot="file" slot-scope="{file}"></div>
              </el-upload>
              <span class="primary-text-underline" @click="removeSelectedAvatar" v-if="customAvatar">Remove</span>
            </div>
            <span class="upload-photo-text">Upload a profile photo - Optional</span>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row v-if="avatarLoader">
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <el-progress :percentage="percentage" color="#AF006E"></el-progress>
          </el-col>
        </el-row>
      <div class="step-hr mb-4"></div>
      <el-row>
        <el-col :xl="12" :lg="12" :md="18" :sm="18">
          <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
          <el-form-item prop="title" class="is-no-asterisk form-group title">
            <template slot="label">
              <span aria-hidden="true">Title<span class="is-custom-asterisk">*</span></span><br />
              <span class="sr-only">Please select a title</span>
              <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('title')"
                    v-text="this.getInlineValidationErrorFor('title')"></span>
            </template>
            <v-select
            id="title"
              v-model="form.title"
              placeholder="Please select"
              :options="titles"
              :searchable="false"
              :clearable="false"
              :reduce="title => title.id"
              inputId="title"
              class="half-content"
              autocomplete="false" />
          </el-form-item>
          <el-form-item prop="first_name" class="form-group first-name">
            <template slot="label">
              First name
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('first_name')"
                    v-text="this.getInlineValidationErrorFor('first_name')"></span>
            </template>
            <el-input
              v-model="form.first_name"
              @blur="form.first_name = form.first_name.trim()"
              placeholder="Your first name"
              id="first_name"
              maxlength="64"
              auto-complete="no"
              label="Please enter your first name in this field"
              aria-required="true" />
          </el-form-item>
          <el-form-item prop="last_name" class="form-group last-name">
            <template slot="label">
              Last name
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('last_name')"
                    v-text="this.getInlineValidationErrorFor('last_name')"></span>
            </template>
            <el-input
              v-model="form.last_name"
              @blur="form.last_name = form.last_name.trim()"
              placeholder="Your last name"
              id="last_name"
              maxlength="64"
              auto-complete="no"
              label="Please enter your last name in this field"
              aria-required="true" />
          </el-form-item>
          <el-form-item prop="contact_number" class="is-no-asterisk form-group contact-number">
            <template slot="label">
              Contact number<span class="is-custom-asterisk">*</span><br />
              <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('contact_number')"
                    v-text="this.getInlineValidationErrorFor('contact_number')"></span>
            </template>
            <el-input
              v-model="form.contact_number"
              @blur="form.contact_number = form.contact_number.trim()"
              placeholder="Contact number"
              id="contact_number"
              maxlength="20"
              auto-complete="no"
              label="Please enter your contact number in this field"
              aria-required="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="step-hr mb-4"></div>
      <el-form-item id="is_russian_self_declared" prop="is_russian_self_declared">
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('is_russian_self_declared')"
                    v-text="this.getInlineValidationErrorFor('is_russian_self_declared')"></span>

                      <el-checkbox-group v-model="form.is_russian_self_declared" 
                      v-for="(russianSelfDeclaration,index) in russianSelfDeclarations" :key="index">
                        <el-checkbox class="pb-3"
                          :name="russianSelfDeclaration.key"
                          :label="russianSelfDeclaration.key"
                          >
                          {{russianSelfDeclaration.text}}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
      <div class="step-hr mb-4"></div>
      <div class="d-flex form-actions">
        <el-button
          class="btn-cancel"
          @click="$router.push({name: 'NonGlobalScot Profile', params: { id: user.id }})">
          Cancel
        </el-button>
        <el-button
          type="primary"
          native-type="submit"
          class="btn-submit">
          Save changes
        </el-button>
      </div>
    </el-form>

    <cropper-upload v-if="cropperFile" :visible="showCropperDialog" :cropperFile="cropperFile" @upload="onCropperUpload" @close="onCropperDialogClose"></cropper-upload>
  </div>
</template>

<script>
  import store from '@/store'
  import { mapState } from "vuex"
  import gsApi from '@/services/gs.api'
  import CropperUpload from '@/components/cropper-upload/cropper-upload'
  import validationErrors from '@/components/partials/validation-errors'
  import helpers from '@/helpers/index'
  import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin';

  export default {
    mixins: [inlineValidationErrorsMixin],
    name: "non-gs-personal-details-form",

    components: {
      validationErrors,
      'cropper-upload': CropperUpload,
    },

    computed: {
      ...mapState(['user']),
    },

    data() {
      let validateContactNumber = (rule, value, callback) => {
        let reg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

        let length = value.length;

        if (value==='') {
          callback(new Error('Contact number is required'))
        } else if (length < 7) {
          callback(new Error('Contact number may not be less than 7 characters'))
        } else if (length > 20) {
          callback(new Error('Contact number may not be greater than 20 characters'))
        } else if (reg.test(value)==false) {
          callback(new Error('Contact number field must be valid'))
        } else {
          callback()
        }
      };

      const validateAgreement = (rule, value, callback) => {
          if(value.length < 2) {
            callback(new Error("You cannot continue without checking all boxes"));
          }
          else{
            callback();
          }
      }

      return {
        percentage:1,
        cropperFile: null,
        showCropperDialog: false,
        validationErrors: [],
        mainLoader: false,
        avatarLoader: false,
        removeAvatarLoader : false,
        currentAvatar: this.$store.state.user.avatar ? this.$store.state.user.avatar : '',
        customAvatar: this.$store.state.user.hasCustomAvatar,
        form: {
          title: this.$store.state.user.title ? this.$store.state.user.title : null,
          first_name: this.$store.state.user.firstName ? this.$store.state.user.firstName : '',
          last_name: this.$store.state.user.lastName ? this.$store.state.user.lastName : '',
          contact_number: this.$store.state.user.traderProfile.contact_number ? this.$store.state.user.traderProfile.contact_number : null,
          is_russian_self_declared: this.$store.state.user.is_russian_self_declared ? 
            ['ask_invertment_with','no_subject_to_uk_sanctions'] : []
          
        },
        rules: {
          title: [
            { required: true, message: 'Please select a title', trigger: 'change' },
          ],
          first_name: [
            { required: true, message: 'First name is required', trigger: 'blur' },
            { max: 64, message: 'First name may not be greater than 64 characters', trigger: 'blur' },
          ],
          last_name: [
            { required: true, message: 'Last name is required', trigger: 'blur' },
            { max: 64, message: 'Last name may not be greater than 64 characters', trigger: 'blur' },
          ],
          contact_number: [
            { validator: validateContactNumber, required: true },
          ],
          is_russian_self_declared: [
              { validator: validateAgreement, required: true }
          ],
        },
        titles: [
          {id: 1, label: 'Mr'},
          {id: 2, label: 'Mrs'},
          {id: 3, label: 'Ms'},
          {id: 4, label: 'Miss'},
          {id: 6, label: 'Dr'},
          {id: 7, label: 'Prof'},
          {id: 8, label: 'Sir'},
          {id: 9, label: 'Dame'},
          {id: 10, label: 'Reverend'},
          {id: 11, label: 'Duke'},
          {id: 12, label: 'Duchess'},
          {id: 5, label: 'Other'},
          {id: 13, label: "Mx"},
        ],
        russianSelfDeclarations:[
            {
              key:'ask_invertment_with',
              text:'I also understand that as part of my registration Scottish Enterprise may ask about the nature of trading and/or investment links with Russia and Belarus.'
            },
            {
              key:'no_subject_to_uk_sanctions',
              text:'I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.'
            },
        ],
      }
    },

    methods: {
      // Start of upload / cropper methods
      removeSelectedAvatar() {
        this.removeAvatarLoader = true

        gsApi.member.user.removeAvatar()
          .then(response => {
            this.removeAvatarLoader = false

            this.currentAvatar = response.data.avatar
            this.customAvatar = response.data.has_custom_avatar

            store.commit('USER_AVATAR_CHANGE', response.data)

            this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
          .catch(error => {
            this.removeAvatarLoader = false
            this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
      },
      onFileChange(file) {
        if (this.validateAvatarUpload(file)) {
          this.cropperFile = file
          this.showCropperDialog = true
        }
      },
      onCropperUpload(croppedFileData) {
        this.onAvatarUpload(croppedFileData.file)
      },
      onAvatarUpload (file) {
        const formData = new FormData()

        formData.append("avatar", file)

        this.showCropperDialog = false
        this.cropperFile = null

        this.percentage = 1;
        this.avatarLoader = true
        let interval = 0;
        gsApi.member.user.updateAvatar(formData,(progress) => {
            interval = setInterval(()=>{
              if(this.percentage < helpers.randomNumber(94,99)){
                this.percentage+= 1;
              }
            },15 )
          })
          .then(response => {
            this.currentAvatar = response.data.avatar
            this.customAvatar = response.data.has_custom_avatar

            store.commit('USER_AVATAR_CHANGE', response.data)
            this.$alert(response.data.message, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
          .catch(error => {
            this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
          .finally(() => {
            this.avatarLoader = false;
            clearInterval(interval);

          });
      },
      onCropperDialogClose() {
        this.showCropperDialog = false
        this.cropperFile = null
      },
      validateAvatarUpload(file) {
        let type = (file.raw && file.raw.type) ? file.raw.type : file.type
        let size = (file.raw && file.raw.size) ? file.raw.size : file.size

        const isJPG = type === 'image/jpeg';
        const isPNG = type === 'image/png';
        const isLt6M = size / 1024 / 1024 < 6;

        if (!isJPG && !isPNG) {
          this.$alert('Avatar must be in a jpeg or png format.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        } else if (!isLt6M) {
          this.$alert('Avatar size can not exceed 6MB.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }

        return (isJPG || isPNG) && isLt6M
      },
      // End of upload / cropper methods

      onSubmit(e) {
        this.$refs['memberForm'].validate((valid) => {
          if (valid) {
            const formData = new FormData()

            formData.append('title', this.form.title)
            formData.append('first_name', this.$options.filters.domPurify(this.form.first_name, {ALLOWED_TAGS: []}))
            formData.append('last_name', this.$options.filters.domPurify(this.form.last_name, {ALLOWED_TAGS: []}))
            formData.append('contact_number', this.form.contact_number)
            this.form.is_russian_self_declared.forEach(item => {
              formData.append("is_russian_self_declared[]", item);
            })

            this.mainLoader = true

            gsApi.member.user.updatePersonalInfo(formData)
              .then(response => {
                this.$store.commit('UPDATE_MEMBER_PERSONAL_DETAILS', response.data)
                this.mainLoader = false
                this.validationErrors = [];
                this.$alert('You\'ve successfully updated your profile', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              })
              .catch(error => {
                this.validationErrors = [];
                this.mainLoader = false

                if (error.response && error.response.status == 422 && error.response.data) {
                  let errors = error.response.data.errors;
                  for (let [key, value] of Object.entries(errors)) {
                    let obj = {}
                    obj.id = key;
                    obj.message = value[0];
                    this.validationErrors.push(obj);
                  }
                  window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                  this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
                }
              })
          } else {
            this.validationErrors = [];
            this.$refs.memberForm.fields.forEach(field => {
              if(field.validateState == 'error') {
                let obj = {}
                obj.id = field.labelFor;
                obj.message = field.validateMessage;
                this.validationErrors.push(obj);
              }
            });
            this.$nextTick(() => {
              document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
              let text = document.getElementById("memberForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        })
      },
      handleNonGsPersonalDetailsInput() {
        this.handleSingleFormFieldInput('memberForm')
      }
    }
  }
</script>
