<template>
  <div class="white-background-section">
    <div class="container">
      <div class="heading-text d-flex justify-content-between">
        <h2>Latest events & webinars</h2>
        <h2 class="view-pulse-feed view-page-link hidden-sm-and-down">
          <router-link :to="{ name: 'Events and Webinars' }">
            View all events & webinars
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </h2>
      </div>
      <div>
        <div class="results-section">
          <div class="mt-4">
            <el-row v-if="events.length > 0">
              <events-card v-for="event in events" :key="event.id" :event="event" />
            </el-row>
            <el-row v-else>
              <el-col>
                <div class="no-event-text">
                  <h1>No events or webinars are available yet</h1>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <h2 class="view-pulse-feed view-page-link hidden-md-and-up mt-3">
        <router-link :to="{ name: 'Events and Webinars' }">
          View all events & webinars
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </h2>
    </div>
  </div>
</template>
<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import EventsCard from "@/components/platform/events-webinars/events-card";

export default {
  components: {
    EventsCard,
    "svg-purple-arrow-right": PurpleArrowRight
  },
  props: ['events'],
  data() {
    return {};
  }
};
</script>
