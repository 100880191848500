<template>
  <div>
    <div class="header-white hidden-lg-and-up">
      <!-- burger menu -->
      <div class="platform-navbar mobile-nav hidden-lg-and-up container">
        <el-row>
          <el-col :md="4" :sm="5" :xs="1">
            <a
              href="javascript:void(0)"
              class="burger"
              @keyup.enter="$emit('onOpenMobileMenuLanding')"
              @click="$emit('onOpenMobileMenuLanding')"
              ><svg-collapse></svg-collapse
            ></a>
          </el-col>
          <el-col :md="16" :sm="14" :xs="24" class="block-logo text-center">
            <a :href="getCMSHome()" class="navbar-brand">
              <div class="site-logo-2">
                <svg-logo-small />
              </div>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- white header appears in larger screens-->
    <div id="header" class="l-header hidden-md-and-down" role="banner">
      <div class="container l-header__content">
        <a :href="getCMSHome()" tabindex="0">
          <div class="site-logo-2">
            <svg-logo />
            <span class="sr-only">Back to Home</span>
          </div>
        </a>

        <div class="l-header__buttons" role="navigation">
          <router-link
            v-if="
              !user.isLogged &&
              !userRequiresVerification &&
              $route.path !== '/sign-up-select' &&
              $route.name !== 'NotFound' &&
              $route.name !== 'Reset Password'
            "
            class="el-button el-button--primary el-button--medium join-home"
            to="/sign-up-select"
            tabindex="0"
          >
            Register
          </router-link>

          <router-link
            v-if="
              !user.isLogged &&
              !userRequiresVerification &&
              $route.path !== '/login' &&
              $route.name !== 'NotFound' &&
              $route.name !== 'Reset Password'
            "
            class="el-button el-button--medium login-home"
            to="/login"
            tabindex="0"
          >
            Sign in
          </router-link>

          <el-button
            v-if="
              (user.isLogged || userRequiresVerification) &&
              $route.name !== 'Reset Password'
            "
            class="el-button el-button--medium"
            tabindex="0"
            @click="logout($event)"
          >
            Logout
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import svgLogo from "../components/assets/svg-gs-logo-full";
import { mixin as onClickOutside } from "vue-on-click-outside";
import RolePermissionMixin from "../mixins/RolePermissionMixin";
import Collapse from "@/components/assets/svg-mobile-menu";
import LogoSmall from "@/components/assets/svg-gs-logo-only";
import gsApi from "@/services/gs.api";

export default {
  name: "LHeader2",
  components: {
    svgLogo,
    "svg-collapse": Collapse,
    "svg-logo-small": LogoSmall,
  },
  mixins: [onClickOutside, RolePermissionMixin],
  data() {
    return {
      isCollapsed: true,
      isCollapsedNot: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["userRequiresVerification"]),
  },
  methods: {
    logout() {
      gsApi.auth.logout(this.user.token).then(() => {
        store.commit("USER_LOGOUT");
        this.$nextTick(() => {
          this.$router.push({ name: "Login" });
        });
      });
    },
    showNot() {
      this.isCollapsedNot = true;
      // document.getElementById("notSidenav").style.transform = "translate(0)";
    },
    closeNot() {
      if (this.isCollapsedNot === true) {
        this.isCollapsedNot = false;
        // document.getElementById("notSidenav").style.transform = "translate(100%)";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(10px);
  opacity: 0;
}
.header-white {
  background: #fff;
  padding-top: 0.5rem;
  .mobile-nav {
    height: auto;
    min-height: 80px;
    .site-logo-2 {
      margin-top: 10px;
    }
  }
}
.burger {
  cursor: pointer;
  svg {
    margin-top: 28px;
  }
}

// .el-button.login-home:hover, .el-button.join-home:hover {
//   background: #0A2A3F;
//   color:white;
// }
</style>
