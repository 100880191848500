<template>
  <div
    class="resources-page body__content"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="search-section">
      <el-header class="d-flex align-items-center top-header">
        <div class="container d-flex align-items-center">
          <router-link class="top-header-link" :to="{ name: 'Resources' }">
            Resources</router-link
          >
          <p class="mx-2 my-0">></p>
          <div v-if="folder && hasParents" class="d-flex align-items-center">
            <div
              v-for="(parent, index) in folder.parents"
              :key="index"
              class="d-flex align-items-center"
            >
              <router-link
                class="top-header-link"
                :to="{
                  name: 'Resource',
                  params: { id: parent.id }
                }"
                >{{ parent.title }}</router-link
              >
              <p class="mx-2 my-0">></p>
            </div>
          </div>
          <p v-if="folder" class="top-header-current-link mb-0">
            {{ folder.title }}
          </p>
        </div>
      </el-header>
      <div class="container">
        <h1 class="pt-3" v-if="folder">{{ folder.title }}</h1>
        <el-form
          id="searchForm"
          :model="search"
          ref="searchForm"
          @submit.native.prevent
        >
          <el-row>
            <el-col :sm="24" :md="12">
              <el-form-item
                label="Keyword search"
                prop="keyword"
                class="hidden-sm-and-down"
              >
                <el-input
                  v-model="search.keyword"
                  placeholder="Search for file name, folder..."
                  id="keyword"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchSharedFiles"
                />
              </el-form-item>
              <el-form-item label="" prop="keyword" class="hidden-md-and-up">
                <el-input
                  v-model="search.keyword"
                  @keyup.enter.native="searchSharedFiles"
                  placeholder="Search for file name, folder..."
                  id="keyword"
                  auto-complete="no"
                  maxlength="255"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <div class="search-resources">
                <el-button
                  class="search-resources-button"
                  type="primary"
                  @click="searchSharedFiles"
                >
                  Search
                </el-button>
              </div>
            </el-col>
            <el-col :sm="24" class="hidden-md-and-up">
              <el-button
                class="more-filters-btn"
                size="medium"
                @click="openResourcesFilter"
                >View More filters</el-button
              >
            </el-col>
          </el-row>
          <el-row class="hidden-sm-and-down">
            <el-col :sm="24" :md="8" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a sector</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="6">
              <el-form-item prop="type_id" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">File type</span>
                  <span class="sr-only">Please select an file type</span>
                </template>
                <v-select
                  v-model="search.type_id"
                  placeholder="Choose from the following"
                  :options="fileTypes"
                  :reduce="fileType => fileType.id"
                  inputId="type_id"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sort by</span>
                  <span class="sr-only">Please select a sort by</span>
                </template>
                <v-select
                  v-model="search.sortBy"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :clearable="true"
                  inputId="sortBy"
                  autocomplete="false"
                  label="name"
                  :reduce="sort => sort.val"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="6">
              <el-button
                type="primary"
                class="float-right update-results-btn"
                @click="searchSharedFiles"
              >
                Update results
              </el-button>
              <el-button
                type="text"
                class="reset-btn float-right"
                @click="resetSearch"
                >Reset</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <hr />
    <div
      class="results-section d-flex align-items-center justify-content-center">
      <div class="container" v-if="!mainLoader && !foldersLoader">
        <el-row v-if="folder && !folder.total_items">
          <el-col :sm="24" class="zero-data d-flex align-items-center text-center justify-content-center">
            <h2>This folder is empty</h2>
            <h3 class="pt-4">
              There are currently no files in this folder. Please check
              again at a later date.
            </h3>
          </el-col>  
        </el-row>  
        <el-row v-else-if="!sharedFiles.length && !sharedFolders.length">
          <el-col :sm="24" class="zero-data d-flex align-items-center text-center justify-content-center">
            <h3>
               We could not find any files with your search criteria. Please
                  update your search and try again.
            </h3>
          </el-col>  
        </el-row>
        <div  v-else>
          <div v-if="sharedFolders.length">
            <h2>Folders</h2>
            <div class="result-section-category">
              <el-row>
                <el-col
                  :xs="24"
                  :sm="12"
                  class="category-folder"
                  v-for="(folder, index) in sharedFolders"
                  :key="index"
                >
                  <folder-item :item="folder" />
                </el-col>
              </el-row>
            </div>
          </div>
          <div v-if="sharedFiles.length">
            <h2>Files</h2>
            <div class="result-files-section">
              <el-row>
                <el-col
                  :xs="24"
                  :sm="12"
                  class="mb-4"
                  v-for="(file, index) in sharedFiles"
                  :key="index"
                >
                  <file-item :item="file" />
                </el-col>
              </el-row>
            </div>
            <div class="d-flex justify-content-center">
              <el-pagination
                v-if="meta.total > 0"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="meta.current_page"
                :page-size="parseInt(meta.per_page)"
                layout="prev, pager, next"
                :total="meta.total"
                prev-text="< Previous"
                next-text="Next >"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <resources-filter
      v-if="resourceFilterOpen"
      @close="closeResourcesFilter"
      @search="searchSharedFiles(true)"
      @reset="resetSearch(true)"
      v-model="search"
      :sectors="sectors"
      :fileTypes="fileTypes"
      :sortOptions="sortOptions"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import gsApi from "@/services/gs.api";
import FolderItem from "@/components/platform/resources/folder-item";
import FileItem from "@/components/platform/resources/file-item";
import ResourcesFilter from "@/components/slide-panel/panels/resources-filter";
export default {
  name: "resources",
  metaInfo: {
    title: "Resources"
  },
  components: {
    FolderItem,
    FileItem,
    ResourcesFilter
  },
  data() {
    return {
      mainLoader: false,
      foldersLoader: false,
      search: {},
      sharedFiles: [],
      sharedFolders: [],
      sectors: [],
      fileTypes: [],
      folder: {},
      meta: {
        per_page: 6,
        current_page: 1,
        total: 0
      },
      sortOptions: [
        {
          val: "a-z",
          name: "A-Z",
          order: "asc",
          order_by: "title"
        },
        {
          val: "z-a",
          name: "Z-A",
          order: "desc",
          order_by: "title"
        },
        {
          val: "lastest",
          name: "Lastest",
          order: "desc",
          order_by: "created_at"
        }
      ],
      resourceFilterOpen: false
    };
  },
  computed: {
    ...mapState(["user"]),
    folders() {
      return this.folder ? this.folder.children : [];
    },
    hasParents() {
      return !!this.folder.parents;
    }
  },
  created() {
    if (this.user.isLogged) {
      this.fetchParentFolder()
      this.getRegisterMeta();

    }
  },
  watch: {
    search: {
      handler(val) {
        let params = _.pickBy(val, function(o) {
          return o !== "" && o !== null && o !== undefined;
        });
        this.addParamsToLocation(params);
      },
      deep: true
    },
    "$route.params.id": {
      handler: function() {
        this.fetchParentFolder()
        this.initFilters();
        this.searchSharedFiles();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initFilters() {
      let search = {
        keyword: null,
        sector: null,
        type_id: null,
        sortBy: "a-z"
      };

      if (this.$route.query.hasOwnProperty("keyword")) {
        search.keyword = this.$route.query.keyword;
      }
      if (this.$route.query.hasOwnProperty("sector")) {
        search.sector = parseInt(this.$route.query.sector);
      }
      if (this.$route.query.hasOwnProperty("type_id")) {
        search.type_id = parseInt(this.$route.query.type_id);
      }
      if (this.$route.query.hasOwnProperty("page")) {
        search.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.hasOwnProperty("sortBy")) {
        search.sortBy = this.$route.query.sortBy;
      }
      this.search = search;
    },
    handleCurrentChange(page) {
      this.search.page = page;
      this.fetchSharedFiles();
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then(response => {
          this.sectors = response.data.groups.data;
          this.fileTypes = response.data.fileTypes.data;
        })
        .catch(error => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    resetSearch(isMobile) {
      if (isMobile) {
        this.closeResourcesFilter();
      }
      this.search = {
        keyword: null,
        sector: null,
        type_id: null,
        page: 1,
        sortBy: null
      };

      this.searchSharedFiles();
    },
    searchSharedFiles(isMobile){
      this.fetchSharedFiles(isMobile)
      this.fetchSharedFolders()
      
    },
    fetchSharedFiles(isMobile) {
      if (isMobile) {
        this.closeResourcesFilter();
      }
      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      let params = {
        ...{
          per_page: this.meta.per_page,
          type_id: this.search.type_id,
          group: this.search.sector,
          search: this.search.keyword,
          page: this.search.page || 1,
          parent_id: this.$route.params.id
        },
        ...this.resolveSortBy()
      };

      gsApi.resource
        .search(params)
        .then(response => {
          this.sharedFiles = response.data.data;
          this.meta = response.data.meta;
        })
        .catch(() => {})
        .finally(() => {
          this.mainLoader = false;
        });
    },
    fetchSharedFolders() {
      this.foldersLoader = true;
      let params = {
        ...{
          type_id: this.search.type_id,
          group: this.search.sector,
          search: this.search.keyword,
          parent_id: this.$route.params.id,
          index:true,
          includeChildren:true
        },
        ...this.resolveSortBy()
      };
      gsApi.resource
        .search(params)
        .then(response => {
          this.sharedFolders = response.data.data;
        })
        .catch(() => {}).finally(() => {
          this.foldersLoader = false;
        });
    },
    fetchParentFolder() {
      gsApi.resource
        .show(this.$route.params.id)
        .then(response => {
          this.folder = response.data.data;
        })
        .catch(() => {});
    },
    resolveSortBy() {
      if (!this.search.sortBy) return {};
      return _.first(
        this.sortOptions
          .filter(opt => opt.val == this.search.sortBy)
          .map(res => {
            return {
              order_by: res.order_by,
              order: res.order
            };
          })
      );
    },
    openResourcesFilter() {
      this.resourceFilterOpen = true;
    },
    closeResourcesFilter() {
      this.resourceFilterOpen = false;
    }
  }
};
</script>
