<template>
  <div class="py-3 right-message message-item" v-if="message.is_my === true">
    <div
      class="d-flex ml-auto justify-content-end"
      :class="{
        'center-mesage':
          isHelpRequestEndedMessage || isHelpRequestPromptedEndMessage
      }"
    >
      <div tabindex="0" class="message-inner d-inline-block mr-3">
        <span class="sr-only">Message on right from {{ message.author.first_name }} {{ message.author.last_name }}</span>
        <div
          class="p-3 message-context"
          v-if="
            !message.file &&
              !message.isHelpRequestMessage &&
              !isHelpRequestEndedMessage &&
              !isHelpRequestPromptedEndMessage
          "
          v-linkified
        >
          <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name} (You)` }}</span>
          <p v-html="$options.filters.domPurify(message.body)"></p>
        </div>

        <div
          class="p-3 message-context mb-3"
          v-if="isHelpRequestPromptedEndMessage"
        >
          <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name} (You)` }}</span>
          <p>You have prompted to end this help request.</p>
        </div>

        <div class="p-3 message-context" v-if="isHelpRequestEndedMessage">
          <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name} (You)` }}</span>
          <p>This help request is now closed.</p>
        </div>
        <a
          href="#"
          class="message-link"
          v-if="
            isHelpRequestEndedMessage && !selectedChat.helpRequest.has_feedback
          "
          @click.prevent="$emit('leave-feedback')"
        >
          Leave feedback
        </a>

        <div class="p-3 message-context" v-if="isHelpRequestMessage">
          <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name} (You)` }}</span>
          <p class="help-message-title">Type of help:</p>
          <p class="help-message-attribute">
            {{ selectedChat.helpRequest.subject }}
          </p>
          <p class="help-message-title mt-2">Details of support needed:</p>
          <p class="help-message-attribute">
            {{ selectedChat.helpRequest.purpose }}
          </p>
        </div>

        <div class="upload-card" v-if="message.fileToBeUploaded">
          <div class="d-flex">
            <div class="flex-shrink-0"><svg-file></svg-file></div>
            <div class="d-flex flex-column ml-3 text-content">
              <div class="file-name">{{ message.file.name }}</div>
              <div id="status" class="status">{{ message.state }}</div>
            </div>
          </div>
          <div class="d-flex mt-1">
            <div class="progress-bar-conatiner flex-grow-1">
              <div id="progress" class="progress"></div>
            </div>
            <div class="progress-percent flex-shrink-0">
              {{ message.progress }}%
            </div>
          </div>
        </div>

        <div
          class="upload-card complete"
          v-if="message.file && !message.fileToBeUploaded"
        >
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img :src="helpers.getFileIcon(message.file.name)" />
            </div>
            <div class="d-flex flex-column ml-3 text-content">
              <div class="file-name">
                <a
                  :href="message.file.url"
                  class="svg-download-file"
                  download
                  target="_blank"
                  >{{ message.file.name }}</a
                >
              </div>
              <div id="status" class="status">{{ covertFileSize }}</div>
            </div>
            <a
              :href="message.file.url"
              class="svg-download-file"
              download
              target="_blank"
            >
              <svg-download-file />
            </a>
          </div>
        </div>

        <span
          class="d-block float-right pt-2"
          v-if="!isHelpRequestEndedMessage && !isHelpRequestPromptedEndMessage"
          >{{ message.created_at }}</span
        >
      </div>
      <member-avatar
        :member="message.author"
        :size="42"
        v-if="!isHelpRequestEndedMessage && !isHelpRequestPromptedEndMessage"
      ></member-avatar>
    </div>
  </div>
  <div class="left-message py-3 message-item" v-else>
    <div
      class="d-flex"
      :class="{
        'center-mesage':
          isHelpRequestEndedMessage ||
          isHelpRequestPromptedEndMessage ||
          message.author.suspended ||
          message.author.deleted
      }"
    >
      <member-avatar
        :member="message.author"
        :size="42"
        v-if="
          !isHelpRequestEndedMessage &&
            !isHelpRequestPromptedEndMessage &&
            !isEngagementPartner &&
            !message.author.suspended &&
            !message.author.deleted
        "
      ></member-avatar>

      <div tabindex="0" class="message-inner d-inline-block ml-3">
        <span class="sr-only">message on left from member {{ message.author.first_name }} {{ message.author.last_name }}</span>
        <div v-if="message.author.suspended || message.author.deleted">
          <p>{{ message.body }}</p>
        </div>
        <div v-else>
          <div
            class="p-3 message-context"
            v-if="
              !message.file &&
                !message.isHelpRequestMessage &&
                !isHelpRequestEndedMessage &&
                !isHelpRequestPromptedEndMessage &&
                !isEngagementPartner
            "
            v-linkified
          >
            <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name}` }}</span>
            <p v-html="$options.filters.domPurify(message.body)"></p>
          </div>
          <fieldset
            class="border message-context-engagement-partner px-2 pt-3 pb-2"
            v-else-if="isEngagementPartner && message.body"
          >
            <legend class="badge badge-dark text-left ml-3">
              Engagement Partner
            </legend>
            <div class="px-3 pb-2" v-html="message.body"></div>
          </fieldset>

          <div
            class="p-3 message-context blue-message-context mb-3"
            v-if="isHelpRequestPromptedEndMessage"
          >
            <p>Has this helped? You can end this request if it’s complete.</p>
          </div>
          <a
            href="#"
            class="message-link"
            v-if="
              isHelpRequestPromptedEndMessage &&
                !selectedChat.helpRequest.has_ended
            "
            @click.prevent="$emit('end-request')"
            >End request</a
          >

          <div
            class="p-3 message-context blue-message-context mb-3"
            v-if="isHelpRequestEndedMessage"
          >
            <p>This help request is now closed.</p>
          </div>

          <div
            class="p-3 message-context blue-message-context mb-3"
            v-if="isChallengeEnded"
          >
            <p>This project has ended.</p>
          </div>

          <div class="p-3 message-context" v-if="isHelpRequestMessage">
            <span class="message-author">{{ `${message.author.first_name} ${message.author.last_name}` }}</span>
            <p class="help-message-title">Type of help:</p>
            <p class="help-message-attribute">
              {{ selectedChat.helpRequest.subject }}
            </p>
            <p class="help-message-title mt-2">Details of support needed:</p>
            <p class="help-message-attribute">
              {{ selectedChat.helpRequest.purpose }}
            </p>
          </div>

          <div
            class="upload-card complete"
            v-if="
              message.file && !message.fileToBeUploaded && !isEngagementPartner
            "
          >
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img :src="helpers.getFileIcon(message.file.name)" />
              </div>
              <div class="d-flex flex-column ml-3 text-content">
                <div class="file-name">
                  <a
                    :href="message.file.url"
                    class="svg-download-file"
                    download
                    target="_blank"
                    >{{ message.file.name }}</a
                  >
                </div>
                <div id="status" class="status">{{ covertFileSize }}</div>
              </div>
              <a
                :href="message.file.url"
                class="svg-download-file"
                download
                target="_blank"
              >
                <svg-download-file />
              </a>
            </div>
          </div>
          <fieldset
            class="border message-context-engagement-partner upload-card complete px-2 pt-3 pb-2"
            v-else-if="message.file && !message.fileToBeUploaded"
          >
            <legend class="badge badge-dark text-left ml-3">
              Engagement Partner
            </legend>
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img :src="helpers.getFileIcon(message.file.name)" />
              </div>
              <div class="d-flex flex-column ml-3 text-content">
                <div class="file-name">
                  <a
                    :href="message.file.url"
                    class="svg-download-file"
                    download
                    target="_blank"
                    >{{ message.file.name }}</a
                  >
                </div>
                <div id="status" class="status">{{ covertFileSize }}</div>
              </div>
              <a
                :href="message.file.url"
                class="svg-download-file"
                download
                target="_blank"
              >
                <svg-download-file />
              </a>
            </div>
          </fieldset>
          <span
            class="d-block pt-2"
            v-if="
              !isHelpRequestEndedMessage && !isHelpRequestPromptedEndMessage
            "
            >{{ message.created_at }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gsApi from "@/services/gs.api";
import SvgViewFile from "@/components/assets/svg-view-file";
import SvgFile from "@/components/assets/svg-file";
import SvgDownloadFile from "@/components/assets/svg-download-file";
import MemberAvatar from "@/pages/platform/chats/avatars/member-avatar";
import helpers from "@/helpers/index";
export default {
  name: "message",
  components: {
    "svg-view-file": SvgViewFile,
    "svg-download-file": SvgDownloadFile,
    "member-avatar": MemberAvatar,
    "svg-file": SvgFile,
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    engagementPartner: { type: Array }
  },
  data: () => ({
    helpers,
    image_mime_type_arr: ["image/jpeg", "image/png", "image/svg+xml"]
  }),
  computed: {
    ...mapState(["selectedChat"]),
    isHelpRequestPromptedEndMessage() {
      return this.message.type == "help_request_end_requested";
    },
    isHelpRequestEndedMessage() {
      return this.message.type == "help_request_ended";
    },
    isChallengeEnded() {
      return this.message.type == "challenge_ended";
    },
    isHelpRequestMessage() {
      return this.message.isHelpRequestMessage;
    },
    covertFileSize() {
      let bytes = this.message.file.size;
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      if (bytes == 0) return "0 Byte";

      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    isImage() {
      return mime_type => {
        return this.image_mime_type_arr.includes(mime_type);
      };
    },
    isEngagementPartner() {
      return this.message.author.is_engagement_partner;
    }
  },
  methods: {
    startUpload() {
      const formData = new FormData();
      formData.append("file", this.message.file);

      gsApi.chat.file
        .upload(this.message.selectedChatId, formData, progress => {
          this.message.progress = Math.round(
            (progress.loaded * 100) / progress.total
          );
          document.getElementById(
            "progress"
          ).style.width = `${this.message.progress}%`;

          if (this.message.progress == 100) {
            this.message.state = "Upload Successful";
            document.getElementById("status").style.color = "#700E57";
          }
        })
        .then(response => {
          this.$emit("file-message-uploaded", response.data);
        })
        .catch(error => {
          this.$emit("file-message-uploaded-error", error);

          let message = "Something went wrong. Please try again later.";

          if (error.code === "ECONNABORTED") {
            message =
              "The upload took too long at the current time, please try again later.";
          } else if (error.status == 413) {
            message = "File is too large. Must be below 200MB!";
          }

          this.$alert(message, "Error", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        });
    }
  },
  created() {
    if (this.message.fileToBeUploaded) {
      this.startUpload();
    }
  }
};
</script>
