<template>
  <div
    class="request-help-form"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="container">
      <h2 class="title">Request help from</h2>
      <el-row class="hidden-sm-and-up">
        <el-col :xs="5" :lg="4">
      <user-avatar
        shape="circle"
        :isGlobalScot="helpers.isExpert(globalScot)"
        :authorExist="!!globalScot"
        width="60px"
        :size="60"
        :src="globalScot ? globalScot.avatar : null"
        :alt="`${globalScot.full_name} 'Profile picture'`"
        avatarClasses="mt-2"
      ></user-avatar>
        </el-col>
        <el-col :xs="19" :lg="20">
          <div class="user-info flex">
            <div class="d-flex justify-content-between">
              <p>{{ globalScot.full_name }}</p>
            </div>
            <p>{{ primaryBusiness.role }} | {{ primaryBusiness.name }}</p>
            <div class="location d-flex">
              <p class="d-flex">
                <svg-map-pin-gradient class="mr-2 map-pin"></svg-map-pin-gradient>
                {{ location }}
              </p>
              <span class="ml-3 mr-3">|</span>
              <p class="d-flex" v-if="helpers.isExpert(globalScot)" >
                <svg-gs-profile-icon class="mr-2 gs-globe"></svg-gs-profile-icon>GlobalScot for, {{ memberFor }}
              </p>
              <p class="d-flex" v-else-if="helpers.isMember(globalScot)" >
                <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for, {{ memberFor }}
              </p>
              <p class="d-flex" v-else >
                <svg-staff-profile-icon class="mr-2 gs-globe"></svg-staff-profile-icon> Team Scotland for, {{ memberFor }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="d-flex hidden-xs-only">
      <user-avatar
        shape="circle"
        :isGlobalScot="helpers.isExpert(globalScot)"
        :authorExist="!!globalScot"
        :size="74"
        width="120px"
        avatarClasses="mt-2"
        :src="globalScot ? globalScot.avatar : null"
        :alt="`${globalScot.full_name} 'Profile picture'`"
      ></user-avatar>
        <div class="user-info">
          <div class="d-flex justify-content-between title-name">
            <p>{{ globalScot.full_name }}</p>
          </div>
          <p>{{ primaryBusiness.role }} | {{ primaryBusiness.name }}</p>
          <div class="location d-flex">
            <p class="d-flex">
              <svg-map-pin-gradient
                class="mr-2 map-pin"
              ></svg-map-pin-gradient>
              {{ location }}
            </p>
            <span class="ml-3 mr-3">|</span>
              <p class="d-flex" v-if="helpers.isExpert(globalScot)" >
                <svg-gs-profile-icon class="mr-2 gs-globe"></svg-gs-profile-icon>GlobalScot for, {{ memberFor }}
              </p>
              <p class="d-flex" v-else-if="helpers.isMember(globalScot)" >
                <svg-member-profile-icon class="mr-2 gs-globe"></svg-member-profile-icon> Member for, {{ memberFor }}
              </p>
              <p class="d-flex" v-else >
                <svg-staff-profile-icon class="mr-2 gs-globe"></svg-staff-profile-icon> Team Scotland for, {{ memberFor }}
              </p>
          </div>
        </div>
      </div>
      <hr />
      <el-form
        :model="requestHelp"
        :rules="requestHelp.rules"
        ref="requestHelp"
        id="requestHelp"
        autocomplete="false"
        @submit.native.prevent
        @validate="handleRequestHelpFormFieldInput"
      >
        <el-row class="form-header">
          <el-col :sm="24" :md="18" :lg="13" class="d-flex flex-column">
            <h3 class="request-title">Request details</h3>
            <span
              class="form-details"
            >Please complete the form to provide information about what support you’re looking for. {{ globalScot.full_name }} will also be able to see your profile for some more information about you.</span>
            <span class="form-details font-weight-bold">Please be aware that you may only have 10 active help requests at any one time.</span>
          </el-col>
        </el-row>
        <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
        <el-row>
          <el-col :sm="24" :md="18" :lg="13">
            <el-form-item prop="subject" class="is-no-asterisk">
              <template slot="label">
                
                <span aria-hidden="true">
                  Request subject
                  <span class="is-custom-asterisk">*</span>
                </span>
                <br />
                <span class="el-form-item__info">This will be seen by the GlobalScot to help them decide whether they can help you. Please be as informative as possible.</span>
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('subject')"
                      v-text="this.getInlineValidationErrorFor('subject')"></span>
              </template>
              <el-input
                v-model="requestHelp.subject"
                id="subject"
                auto-complete="no"
                label="Please enter your request subject in this field"
                aria-required="true"
                maxlength="64"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="24" :md="18" :lg="13">
            <el-form-item prop="purpose" class="form-group">
              <span
                class="characters-length"
              >You have {{1000 - (requestHelp.purpose.length)}} characters remaining</span>
              <template
                slot="label"
              >Tell {{ globalScot.first_name }} what you’re looking to do and how you’re hoping they can help
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('purpose')"
                      v-text="this.getInlineValidationErrorFor('purpose')"></span>
              </template>
              <el-input
                v-model="requestHelp.purpose"
                type="textarea"
                id="purpose"
                aria-required="true"
                auto-complete="no"
                :rows="5"
                maxlength="1000"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="24" :md="18" :lg="13">
            <el-form-item prop="groups" class="is-no-asterisk">
              <template slot="label">
                <span aria-hidden="true">
                  Which sector does this relate to?
                  <span class="is-custom-asterisk">*</span>
                </span>
                <br />
                <span
                  class="sr-only"
                >Please select one or more aspects your business needs help with</span>
                <span class="el-form-item__info">You can select more than one</span>
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('groups')"
                      v-text="this.getInlineValidationErrorFor('groups')"></span>
              </template>
              <v-select
                v-model="requestHelp.groups"
                :options="groups"
                placeholder="Please select"
                :multiple="true"
                :clearable="false"
                label="name"
                inputId="groups"
                autocomplete="false"
                :select-on-tab="true"
                :close-on-select="false"
                @search:blur="$refs.requestHelp.validateField('groups')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="24" :md="18" :lg="13">
            <el-form-item prop="expertise" class="is-no-asterisk">
              <template slot="label">
                <span aria-hidden="true">
                  Expertise you’re looking for
                  <span class="is-custom-asterisk">*</span>
                </span>
                <br />
                <span class="sr-only">Please select one or more expertise you needs help with</span>
                <span class="el-form-item__info">You can select more than one</span>
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('expertise')"
                      v-text="this.getInlineValidationErrorFor('expertise')"></span>
              </template>
              <v-select
                v-model="requestHelp.expertise"
                :options="expertise"
                placeholder="Please select"
                :multiple="true"
                :clearable="false"
                label="name"
                inputId="expertise"
                autocomplete="false"
                :select-on-tab="true"
                :close-on-select="false"
                @search:blur="$refs.requestHelp.validateField('expertise')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="business" class="mt-4" v-if="$store.state.user.roles[0] != 'staff'">
          <template slot="label">
            <span aria-hidden="true">Which business is this request for?</span>
            <span class="is-custom-asterisk">*</span>
            <br />
            <span class="sr-only">Please select business you needs help with</span>
            <span class="sr-only"
                  aria-live="polite"
                  :hidden="! this.hasInlineValidationErrorFor('user_business_id')"
                  v-text="this.getInlineValidationErrorFor('user_business_id')"></span>
          </template>
          <el-radio-group v-model="requestHelp.user_business_id" aria-label="Select a business">
            <div class="col-md-24 mt-3 px-0" v-for="(business, index) in businesses" :key="index">
              <el-radio :label="business.id">
                <span>{{ business.name }}</span>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-row v-if="$store.state.user.roles[0] == 'staff'">
          <el-col :sm="24" :md="18" :lg="13">
            <el-form-item prop="team_scotland_business">
              <template slot="label">
                Which business is this request for?
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('team_scotland_business')"
                      v-text="this.getInlineValidationErrorFor('team_scotland_business')"></span>
              </template>
              <el-input
                v-model="requestHelp.team_scotland_business"
                id="team_scotland_business"
                auto-complete="no"
                label="Which business is this request for?"
                aria-required="true"
                maxlength="500"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="terms_agreed" class="terms-conditions" >
        <label for="terms_agreed">
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('terms_agreed')"
                v-text="this.getInlineValidationErrorFor('terms_agreed')">
          </span>
          <input type="checkbox" tabindex="0" v-model="requestHelp.terms_agreed" id="terms_agreed" name="terms_agreed" />
          I’ve read and accepted the
          <a :href="getCMSRoute('terms-conditions')" class="text__link" target="_blank">terms and conditions</a>
          and
          <a :href="getCMSRoute('code-of-conduct-for-users')" class="text__link" target="_blank">the code of conduct</a>
        </label>
        </el-form-item>
        <el-row>
          <el-col :sm="24" :md="18" :lg="12">
            <el-form-item prop="is_russian_self_declared">
            <label for="is_russian_self_declared">
              <span class="sr-only"
                    aria-live="polite"
                    :hidden="! this.hasInlineValidationErrorFor('is_russian_self_declared')"
                    v-text="this.getInlineValidationErrorFor('is_russian_self_declared')"></span>
              <input type="checkbox" tabindex="0" v-model="requestHelp.is_russian_self_declared" id="is_russian_self_declared" name="is_russian_self_declared" />
              I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.
                
            </label>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-actions">
          <el-col :sm="24" :md="18" :lg="13">
            <el-button type="primary" native-type="submit" @click="submitForm">Submit request</el-button>
            <el-button @click="onDialogClose">Cancel</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>


  </div>
</template>

<script>
import moment from "moment";
import MapPinGradient from "@/components/assets/svg-map-pin";
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
import SvgGsProfileicon from '@/components/assets/svg-gs-profile-icon';
import SvgMemberProfileIcon from '@/components/assets/svg-member-profile-icon'
import SvgStaffProfileIcon from "@/components/assets/svg-staff-profile-icon";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
export default {
  mixins: [inlineValidationErrorsMixin],
  name: "request-help-component",
  props: ["globalScot"],
  components: {
    "svg-map-pin-gradient": MapPinGradient,
    "svg-staff-profile-icon": SvgStaffProfileIcon,
    'svg-member-profile-icon': SvgMemberProfileIcon,
    'svg-gs-profile-icon':SvgGsProfileicon,
    validationErrors,
    UserAvatar
  },
  data() {
    return {
      helpers,
      validationErrors: [],
      mainLoader: false,
      groups: [],
      expertise: [],
      businesses: this.$store.state.user.roles[0] == 'staff' ? null : this.$store.state.user.businesses,
      requestHelp: {
        groups: "",
        group_ids: [],
        expertise: "",
        expertise_ids: [],
        subject: "",
        purpose: "",
        user_business_id: this.$store.state.user.roles[0] == 'staff' ? null : this.$store.state.user.businesses[0].id,
        terms_agreed: [],
        is_russian_self_declared:[],
        team_scotland_business: null,
        rules: {
          subject: [
            { required: true, message: "Add a request subject", trigger: "blur" },
            {
              max: 64,
              message: "Subject may not be greater than 255 characters",
              trigger: "blur"
            }
          ],
          team_scotland_business: [
            { required: true, message: "Enter the name of the business you're requesting help for", trigger: "blur" },
            {
              max: 500,
              message: "Business may not be greater than 500 characters",
              trigger: "blur"
            }
          ],
          purpose: [
            { required: true, message: "Tell the GlobalScot more about the support you're looking for", trigger: "blur" },
            {
              max: 1000,
              message: "Purpose may not be greater than 1000 characters",
              trigger: "blur"
            }
          ],
          groups: [
            {
              type: "array",
              required: true,
              message: "Select the sector this request relates to",
              trigger: "change"
            }
          ],
          expertise: [
            {
              type: "array",
              required: true,
              message: "Select the expertise you're looking for from the GlobalScot ",
              trigger: "change"
            }
          ],
          terms_agreed: [
            {
              type: "array",
              required: true,
              message: "Check the box to accept the terms and conditions ",
              trigger: "change"
            }
          ],
          is_russian_self_declared: [
            {
              type: "array",
              required: true,
              message: "Check the box to accept the Russian self declaration",
              trigger: "change"
            }
          ]
        }
      }
    };
  },
  created() {
    this.getRegisterMeta();
  },
  methods: {
    onDialogClose() {
      this.$emit("clicked");
    },

    getRegisterMeta() {
      gsApi.globalscot.meta
        .index()
        .then(response => {
          this.groups = response.data.groups.data;
          this.expertise = response.data.expertise.data;
        })
        .catch(() => {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    submitForm() {
      this.$refs["requestHelp"].validate(valid => {
        if (valid) {
          const data = {};

          this.requestHelp.groups.forEach(item => {
            this.requestHelp.group_ids.push(item.id);
          });

          this.requestHelp.expertise.forEach(item => {
            this.requestHelp.expertise_ids.push(item.id);
          });

          data.subject = this.requestHelp.subject;
          data.purpose = this.requestHelp.purpose;
          data.group_ids = this.requestHelp.group_ids;
          data.expertise_ids = this.requestHelp.expertise_ids;
          data.expert_id = this.globalScot.id;
          data.user_business_id = this.requestHelp.user_business_id;
          data.team_scotland_business = this.requestHelp.team_scotland_business;

          this.mainLoader = true;

          gsApi.member
            .requestHelp(data)
            .then(response => {
              this.mainLoader = false;
              (this.validationErrors = []), this.$emit("clicked");
              this.$emit("success");
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.requestHelp.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("requestHelp")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    handleRequestHelpFormFieldInput() {
      this.handleSingleFormFieldInput('requestHelp')
    }
  },
  computed: {
    primaryBusiness() {
      let primary = this.globalScot.businesses.data.find(b => b.is_primary);
      return primary ? primary : this.globalScot.businesses.data[0];
    },
    location() {
      if (this.globalScot.expertProfile.state && this.globalScot.expertProfile.country) {
        return this.globalScot.expertProfile.state.name + ', ' + this.globalScot.expertProfile.country.name
      } else if (this.globalScot.expertProfile.country) {
        return this.globalScot.expertProfile.country.name
      } else if (this.globalScot.expertProfile.state) {
        return this.globalScot.expertProfile.state.name
      }
    },
    memberFor() {
      let memberFor = moment(this.globalScot.created_at.date).fromNow(true);
      return memberFor;
    }
  }
};
</script>
<style lang="scss">
.user-info .title-name p,
.request-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 43px;
  color: black;
}
</style>
