<!-- EDIT PROFILE -->

<template>
  <div class="edit-profile">
    <div class="container">
      <div class="edit-profile-header d-flex">
        <h1>Edit Your Profile</h1>
        <div class="d-flex">
          <span>Want to update account info?</span>
          <router-link to="/settings">Go to settings</router-link>
        </div>
      </div>
      <hr>
      <el-row>
        <el-col :md="6" class="profile-sections">
          <h2>Profile Sections</h2>
          <ul class="section-tabs">
            <li>
              <router-link to="/edit-profile/personal-details">Personal details
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li v-if="!isStaff">
              <router-link to="/edit-profile/companies">Companies
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li v-if="isTrader">
              <router-link to="/edit-profile/plans-ambitions">Plans and ambitions
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li v-if="isExpert">
              <router-link to="/edit-profile/experience-expertise">Experience and expertise
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/edit-profile/following">Following
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li>
            <li>
              <router-link to="/edit-profile/followers">Followers
                <svg-white-arrow-left class="arrow"></svg-white-arrow-left>
              </router-link>
            </li> -->
          </ul>
        </el-col>
        <el-col :md="2" class="profile-divider">
          <hr class="vertical-divider">
        </el-col>
        <el-col :md="16" class="profile-forms">
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import WhiteArrowLeft from "@/components/assets/svg-white-arrow-left";
  export default {
    name: "edit-profile-page",

    components: {
      "svg-white-arrow-left": WhiteArrowLeft,
    },


    metaInfo: {
      title: "Edit profile",
    },
    computed:{
      ...mapGetters(["isExpert", "isTrader", "isStaff"]),
    },
    methods: {

      /*isGsUser() {
        return this.$store.state.user.isLogged && this.$store.state.user.roles[0] == 'expert' ? true : false
      },
      isStaffUser() {
        return this.$store.state.user.isLogged && this.$store.state.user.roles[0] == 'staff' ? true : false
      }*/
    }

  }
</script>

<!-- <style lang="scss">
.company-item {
  margin-top: 25px;
  margin-bottom: 30px;
  .company-header {
    justify-content: space-between;
    align-items: center;
    .company-details {
      display: grid;
      line-height: 28px;
      .company-name {
        color: $primary;
      }
    }
    button {
      font-family: brandon-grotesque;
      &.is-primary {
        font-weight: 420;
        font-size: 16px;
        color: $white;
        background-color: #EA6576;
        border-radius: 19px;
        padding: 1px 16px;
        margin-right: 75px;
      }
      &.mark-as-primary {
        font-weight: 420;
        font-size: 19px;
        text-decoration: underline;
        color: #700E57;
        margin-right: 55px;
      }
      &.details-hidden {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.details-show {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.remove {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
</style> -->
