<template>
  <div class="footer-join">
    <div class="container">
      <el-row>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="head">
          <h1 class="text-white footer-text">{{footerText.heading}}</h1>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <p class="content-std text-white footer-text join-sub-text">{{footerText.sub}}</p>
        </el-col>
        <el-button
          class="join-btn-bottom"
          type="primary"
          @click="$router.push({ name: 'Signup Select Type' })"
        >Get support for your business</el-button>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footerText: {
        heading: "Not sure becoming a GlobalScot is right for you?",
        sub:
          "If you're a business in Scotland looking for support, you can register now to access the support, advice and insights available from our GlobalScots."
      }
    };
  }
};
</script>
<style>
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-weight: 400;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}
</style>
