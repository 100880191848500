<template>
  <div class="footer-join">
    <div class="container">
      <el-row>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="head">
          <h1 class="text-white footer-text">{{ heading }}</h1>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <p class="content-std text-white footer-text join-sub-text">
            {{ sub }}
          </p>
        </el-col>
        <el-button
          class="join-btn-bottom"
          type="primary"
          @click="$router.push({ name: buttonCallToAction.name })"
          >{{buttonCallToAction.text}}</el-button
        >
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String
    }
  },
  computed: {
    heading() {

      if (this.$route.name == "Home") {
        return "Register today for free business support";
      }
      if (this.$route.name == "Get Business Support") {
        return "Ready to access support?";
      }
      if( ['Case Studies Public', 'Business Events Webinars','Public Events', 'Public Articles'].includes(this.$route.name) ){
        return "Register today for free business support"
      }

      return "Join the network today"
    },
    sub() {
      if (this.$route.name == "Home") {
        return "GlobalScot support is free to access for Scottish businesses. Register with us to access new connections, insights, advice, events and more.";
      }
      if (this.$route.name == "Get Business Support") {
        return "It doesn't matter how big or small your business is - as long as you have ambitions to grow and trade internationally, our GlobalScots can help.";
      }

      if( ['Case Studies Public', 'Business Events Webinars','Public Events', 'Public Articles'].includes(this.$route.name) ){
        return "GlobalScot support is free to access to Scottish businesses. Register for our online platform to access new connections, insights, advice, events and more."
      }

      return "The GlobalScot community is free to join. Whether you're looking for support for your business, or have the skills and experience to help businesses in Scotland, we look forward to welcoming you."
    },
    buttonCallToAction() {
      if (this.$route.name == "Become GlobalScot") {
        return {
          text: "Get support for your business",
          name: "About Network"
        };
      }
      if (this.$route.name == "Get Business Support") {
        return {
          text: "Get started",
          name: "Signup NGS"
        };
      }
      return {
        text: "Get started",
        name: "Signup Select Type"
      };
    }
  }
};
</script>

<style>
.footer-text {
  text-align: center;
  margin-top: 30px;
}
h1 {
  font-weight: 400;
}
.footer-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.join-btn-bottom {
  margin: auto;
}
.give-feedback p {
  color: white;
  margin-left: 10px;
  /* float: left; */
  text-decoration: underline;
}
.give-feedback {
  text-align: center;
  padding-top: 45px;
}
</style>
