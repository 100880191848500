<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="signup-gs-complete-page page"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-complete`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <div class="header__content__wrapper">
              <h2 class="header__title">
                Welcome to the network. Ready to get started?
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-20 col-xl-16">
            <div
              v-if="!hideContent"
              class="body__content"
            >
              <el-form
                ref="completeForm"
                :model="form"
                :rules="rules"
                @submit.native.prevent
              >
                <h1 class="se-heading-m step-title">
                  Set up your Member account
                </h1>
                <span class="el-form-item__info">Welcome {{ name }} to the GlobalScot network. Before you access the network, you just need to set up a password and upload your profile picture.</span>

                <div class="step-hr mt-5"></div>

                <h2 class="se-heading-m mb-3">
                  Password
                </h2>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <el-form-item prop="password">
                      <template slot="label">
                        Password
                      </template>
                      <el-input
                        id="password"
                        v-model="form.password"
                        :show-password="true"
                        auto-complete="no"
                        label="Please enter your desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                    <el-form-item prop="password_confirmation">
                      <template slot="label">
                        Confirm password
                      </template>
                      <el-input
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        :show-password="true"
                        auto-complete="no"
                        label="Please confirm the desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-4">
                  Upload a profile photo - Optional
                </h2>

                <div
                  v-if="avatarFile"
                  class="uploaded-file d-flex"
                >
                  <el-avatar
                    shape="circle"
                    :size="140"
                    :src="avatarUrl"
                    alt="Profile picture"
                    class="step-3-avatar"
                  ></el-avatar>

                  <div class="flex-fill">
                    <div class="upload-actions">
                      <el-upload
                        ref="upload"
                        action=""
                        :multiple="false"
                        :drag="true"
                        list-type="picture-card"
                        class="selected-upload"
                        :auto-upload="false"
                        :on-change="onFileChange"
                      >
                        <div slot="default">
                          <el-button class="replace-button">
                            Replace
                          </el-button>
                        </div>

                        <div
                          v-if="avatarFile"
                          slot="file"
                          slot-scope="{file}"
                        >
                        </div>
                      </el-upload>
                      <span
                        class="remove-file-link"
                        @click="removeSelectedAvatar"
                      >Remove</span>
                    </div>
                  </div>
                </div>

                <el-upload
                  ref="upload"
                  action=""
                  :multiple="false"
                  :drag="true"
                  list-type="picture-card"
                  class="initial-upload"
                  :class="{ 'upload-show': avatarFile }"
                  :auto-upload="false"
                  :on-change="onFileChange"
                >
                  <div slot="default">
                    <div class="se-file-upload">
                      <img
                        src="/assets/img/file-drag-drop.svg"
                        alt="Drag & drop files here"
                        class="drag-drop-img"
                      />
                      <span class="or-text se-body-s-screen-16 m-4">or</span>
                      <el-button class="browse-button">
                        Browse files
                      </el-button>
                    </div>
                  </div>

                  <div
                    v-if="avatarFile"
                    slot="file"
                    slot-scope="{file}"
                  >
                  </div>
                </el-upload>

                <div class="submit-button-wrapper mt-5">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="submitCompleteForm"
                  >
                    Continue
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import gsApi from '@/services/gs.api'
import CropperUpload from '@/components/cropper-upload/cropper-upload'

export default {
  name: 'SignupNgsComplete',
  metaInfo: {
    title: "Finish signup",
  },
  components: {
    'cropper-upload': CropperUpload,
  },
  data: function () {
    let validatePassword = (rule, value, callback) => {
      let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[a-zA-Z0-9#?!@$%^&*-]{8,}$/
      if (value==='') {
        callback(new Error('Enter a password'))
      } else if (reg.test(value)==false) {
        callback(new Error('Password must be more than 8 characters long, should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character (#?!@$%^&*-).'))
      } else {
        callback()
      }
    }
    let validatePasswordConfirmation = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Password doesn’t match. Make sure the password entered in both password fields is the same'))
      } else if (value !== this.form.password) {
        callback(new Error("Password doesn’t match. Make sure the password entered in both password fields is the same"))
      } else {
        callback()
      }
    }

    return {
      mainLoader: false,
      hideContent: false,
      form: {
        code: '',
        password: '',
        password_confirmation: '',
        avatar: null
      },
      avatarFile: null,
      name: '',

      cropperFile: null,
      showCropperDialog: false,

      rules: {
        password: [
          { validator: validatePassword, required: true },
        ],
        password_confirmation: [
          { validator: validatePasswordConfirmation, required: true },
        ],
      }
    }
  },
  computed: {
    avatarUrl: function () {
      return this.avatarFile.url
    }
  },
  created () {
    if (this.form.code = this.$route.query.code) {
      this.mainLoader = true
      this.hideContent = true

      gsApi.member.checkCode({code: this.form.code})
        .then(response => {
          this.mainLoader = false
          this.hideContent = false
          this.name = response.data.first_name
        })
        .catch(error => {
          this.$alert('The approval code is not valid. Please contact the GlobalScot team for further assistance.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.$router.push("/home");
        })
    } else {
      this.$alert('The approval code is not valid.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      this.$router.push("/home");
    }
  },
  methods: {
    submitCompleteForm() {
      this.$refs['completeForm'].validate((valid) => {
        if (valid) {
          const formData = new FormData()

          formData.append('code', this.form.code)
          formData.append('password', this.form.password)
          formData.append('password_confirmation', this.form.password_confirmation)

          if (this.form.avatar) {
            formData.append("avatar", this.form.avatar)
          }

          this.mainLoader = true

          gsApi.member.complete(formData)
            .then(response => {
              this.$alert('You can now login.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              this.$router.push('/login')
            })
            .catch(error => {
              this.mainLoader = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let firstError = error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                this.$alert('Sorry, your request could not be processed', firstError[0], { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              } else {
                this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
        } else {
          this.$nextTick(() => {
            document.getElementsByClassName('is-error')[0].scrollIntoView()
            let text = document.getElementsByClassName('el-form-item__error')[0].textContent
            this.$announcer.set(text)
          })
        }
      });
    },

    // Start of upload / cropper methods
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file
        this.showCropperDialog = true
      }
    },
    onCropperUpload(croppedFileData) {
      this.avatarFile = croppedFileData
      this.form.avatar = croppedFileData.file
      this.onCropperDialogClose()
    },
    onCropperDialogClose() {
      this.showCropperDialog = false
      this.cropperFile = null
    },
    removeSelectedAvatar() {
      this.avatarFile = null
      this.form.avatar = null
      this.cropperFile = null
      this.$refs.upload.clearFiles()
    },
    validateAvatarUpload(file) {
      let type = (file.raw && file.raw.type) ? file.raw.type : file.type
      let size = (file.raw && file.raw.size) ? file.raw.size : file.size

      const isJPG = type === 'image/jpeg';
      const isPNG = type === 'image/png';
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert('Avatar must be in a jpeg or png format.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      } else if (!isLt6M) {
        this.$alert('Avatar size can not exceed 6MB.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    // End of upload / cropper methods
  }
}
</script>

<style>
  .step-3-avatar img {
    width: 140px;
    height: 140px;
  }
</style>
