<template>
  <div v-loading="loading" class="mb-5">
    <div v-if="helpRequests.length > 0">
      <el-table :data="helpRequests" style="width: 100%">
        <el-table-column prop="start_date" label="Date Started" width="180">
        </el-table-column>
        <el-table-column prop="gs_requested" label="GS Requested" width="180">
        </el-table-column>
        <el-table-column prop="subject" label="Subject"> </el-table-column>
        <el-table-column prop="last_active" label="Last Active" width="180">
        </el-table-column>
        <el-table-column label="Status" width="120">
          <template slot-scope="scope">
            <div
              class="help-request-status"
              :class="`status-${resolveStatusTagClass(scope.row.status)}`"
              :type="resolveStatusTagClass(scope.row.status)"
              close-transition
            >
              {{ scope.row.status_name }}
            </div>
          </template>
        </el-table-column>
      </el-table>

    <el-row class="mt-4">
      <el-col class="d-flex justify-content-center " :span="24">
        <el-button class="load-more-btn load-more-activities-btn" @click.native="fetchMemberHelpRequests" v-if="!onLastPage && !loading">Load more</el-button>
      </el-col>
    </el-row>
    </div>
    <div class="activity-zero-data" v-else>
      <br class="d-block" />
      <p v-if="!loading && !helpRequests.length">No help requests.</p>
    </div>
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
export default {
  name: "member-helprequest-table",

  created() {
    this.fetchMemberHelpRequests();
  },
  data() {
    return {
      loading: false,
      helpRequests: [],
      onLastPage: false,
      page: 1
    };
  },
  methods: {
    fetchMemberHelpRequests() {
      this.loading = true;
      gsApi.member
        .helpRequests({
          page: this.page,
          per_page: 5
        })
        .then(response => {
          if (
            response.data.meta.pagination.current_page !==
            response.data.meta.pagination.total_pages
          ) {
            this.onLastPage = false;
            this.page++;
          } else {
            this.onLastPage = true;
          }

          const currentHelpRequests = _.map(this.members, "id");

          this.helpRequests = this.helpRequests.concat(
            response.data.data.filter(el => {
              return !currentHelpRequests.includes(el.id);
            })
          );

          this.showZeroData = response.data.meta.pagination.total <= 0;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resolveStatusTagClass(statusId) {
      switch (statusId) {
        case 1: {
          return "pending";
        }
        case 2: {
          return "open";
        }
        case 3: {
          return "closed";
        }
        default: {
          return "other";
        }
      }
    },
    
  }
};
</script>
<style lang="scss">
.el-table{
  .el-table__header-wrapper{
    th{
      background: #828282;
      color:#FFF;
      
    }
    > .is-leaf ~ .is-leaf {
        border: none;
    }
  }
}
</style>
