<template>
  <div class="member-card info-window-card box-card text-center">
    <div v-if="member">
      <user-avatar
        shape="circle"
        :isGlobalScot="helpers.isExpert(member)"
        :authorExist="!!member"
        :size="74"
        :src="member ? member.avatar : null"
        :alt="member ? member.full_name : '' + 'Profile picture'"
      ></user-avatar>
      <div class="name-position">
        <h2 class="text-truncate">{{ member.full_name }}</h2>
        <div class="d-flex justify-content-center">
          <p class="text-truncate">{{ member.primary_business.role }}</p>
          <div class="vertical-line">
            <svg-vertical-line
              aria-labeledby="vertical line"
            ></svg-vertical-line>
          </div>
          <p class="text-truncate">{{ member.primary_business.name }}</p>
        </div>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="location-sectors">
        <div class="d-flex justify-content-center">
          <div>
            <svg-map-pin-gradient class="svg-map-pin-gradient"
            ></svg-map-pin-gradient>
          </div>
          <p class="text-truncate">{{ member.location }}</p>
        </div>
        <div class="sectors d-flex justify-content-center">
          <p class="text-truncate">{{ member.sectors.text }}</p>
          <span v-if="member.sectors.plus_more"
            >+{{ member.sectors.plus_more }} more</span
          >
        </div>
      </div>
      <div class="view-profile-link">
        <div @click="goToProfile()">
          View profile
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </div>
      </div>
      <div class="info-close" @click="closeInfoWindow()">
        <svg-info-close></svg-info-close>
      </div>
    </div>
    <div v-else>
      loading...
    </div>
  </div>
</template>

<script>
import router from "@/router";
import InfoClose from "@/components/assets/svg-info-close";
import MapPinGradient from "@/components/assets/svg-map-pin";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import gsApi from "@/services/gs.api";
import VerticalLine from "@/components/assets/svg-vertical-line";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
export default {
  name: "member-infowindow",
  components: {
    "svg-info-close": InfoClose,
    "svg-map-pin-gradient": MapPinGradient,
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-vertical-line": VerticalLine,
    UserAvatar
  },
  props: {
    memberId: {
      required: true
    }
  },
  data() {
    return {
      member: null,
      helpers
    };
  },
  computed: {},
  methods: {
    closeInfoWindow() {
      this.$emit("closeinfowindow");
    },
    goToProfile() {
      if (this.member.suspended) {
        this.$alert("User is not currently active.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
        return;
      } else {
        router.push({
          name: "GlobalScot Profile",
          params: { id: this.member.id, showBackToSearch: true }
        });
      }
    },
    fetchMember() {
      this.loading = true;
      gsApi.members
        .singleMember(this.memberId)
        .then(response => {
          this.member = response.data;
          this.$emit("realigninfowindow");
        })
        .catch(error => {
          //console.log(error.response)
          this.$emit("closeinfowindow");
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchMember();
  }
};
</script>
<style lang="scss">
.vertical-line {
  margin: 10px;
}
.svg-map-pin-gradient{
  width: 18px; 
  position: relative; 
  top: -4px;
}
</style>
