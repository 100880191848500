<template>
  <slide-panel @panelclose="closeMenu" title="Filter">
    <el-form id="mobileSearchForm" :model="value" ref="mobileSearchForm" class="members-filter" @submit.native.prevent>
      <el-row>
        <el-col :sm="24" :md="12" :lg="6">
          <el-form-item prop="sector" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">Sector</span>
              <span class="sr-only">Please select a sector</span>
            </template>
            <v-select
              v-model="value.sector"
              placeholder="Choose from the following"
              :options="sectors"
              :searchable="true"
              :clearable="false"
              :reduce="sector => sector.id"
              inputId="sector"
              autocomplete="false"
              label="name"
              class="half-content" />
          </el-form-item>
        </el-col>
        <el-col :sm="24">
          <el-form-item prop="type_id" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">File type</span>
              <span class="sr-only">Please select a type</span>
            </template>
            <v-select
              v-model="value.type_id"
              placeholder="Choose from the following"
              :options="fileTypes"
              :searchable="true"
              :clearable="false"
              :reduce="fileTypes => fileTypes.id"
              inputId="type_id"
              autocomplete="false"
              label="name"
              class="half-content" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" v-if="sortOptions">
          <el-form-item prop="sortby" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">Sort by</span>
              <span class="sr-only">Please select a filter</span>
            </template>
            <v-select
                  v-model="value.sortBy"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :clearable="true"
                  inputId="sortBy"
                  autocomplete="false"
                  label="name"
                  :reduce="sort => sort.val"
                  class="half-content"
                />
          </el-form-item>
        </el-col>
        <el-col :sm="24">
          <div class="d-flex mt-4">
            <span class="reset-btn float-right" @click="$emit('reset')">Reset</span>
            <el-button type="primary" class="float-right update-results-btn flex-fill ml-4" @click="onSearch">
              Search
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </slide-panel>
</template>

<script>
  import SlidePanel from '@/components/slide-panel/slide-panel'

  export default {
    name: 'resources-filter',
    props: {
      value: {
        type: Object,
        required: true
      },
      sectors: {
        type: Array,
        required: true
      },
      fileTypes: {
        type: Array,
        required: true
      },
      sortOptions: {
        type: Array,
        required: false,
        default: null
      },
    },
    components: {
      'slide-panel': SlidePanel
    },
    methods: {
      onSearch() {
        this.$emit('search')
      },
      closeMenu() {
        this.$emit('close')
      },
    },
  }
</script>
