<template>
  <el-dialog
    :show-close="true"
    :visible.sync="dialogVisible"
    custom-class="multi-factor-dialog new-ui"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose()"
  >
    <div class="body">
      <h2 class="mb-5">
        {{ step1 ? "Verify code" : "Enter Code" }}
      </h2>
      <p v-if="step2" class="mb-3">
        A text message with a 6-digit verification code was sent to the mobile
        ending in ***{{
          phoneForm.phoneNumber ? phoneForm.phoneNumber.slice(-3) : ""
        }}
      </p>
      <el-row v-if="step1">
        <el-col>
          <p class="mb-3">
            Multi-factor authentication is pending verification. Before we add
            this phone to your account, we need to verify that it can receive
            verification codes.
          </p>
        </el-col>
        <el-col>
          
          <el-form
            class="w-100 phone-form"
            :model="phoneForm"
            :rules="phoneFormRules"
            ref="phoneNumberForm"
          >
            <el-form-item prop="prefix" class="mb-0 mt-2 mr-1" required>
              <template slot="label">
                Country Code
              </template>
              <el-input
                  id="prefix"
                  v-model="phoneForm.prefix"
                  class="ms-1"
                  auto-complete="off"
                  :disabled="true"
                ></el-input>
            </el-form-item>
            <el-form-item
              prop="phoneNumber"
              class=" w-100 mb-0 mt-2 ml-1"
              required
            >
              <template slot="label">
                Phone Number
              </template>
              <el-input
                ref="phoneInput"
                id="phoneNumber"
                v-model="phoneForm.phoneNumber"
                class="ms-1"
                aria-required="true"
                auto-complete="off"
                type="number"
                @keyup.enter.native="sendCode"
              ></el-input>
              <template #error="{ error }">
                <div aria-describedby="phoneInput" class="el-form-item__error">{{ error }}</div>
              </template>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col class="mt-5">
          <el-row>
            <el-col :xs="24" :sm="12">
              <el-button
                :disabled="codeLoading"
                class="mb-2 w-100 mx-0 mr-1"
                @click="onClose()"
              >
                Cancel
              </el-button>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-button
                :disabled="codeLoading"
                :loading="codeLoading"
                type="primary"
                class="mb-2 w-100 ml-1 mr-0"
                @click="sendCode()"
              >
                Send Code
              </el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row v-if="step2" class="w-100">
        <el-form
          id="phoneAuthenticatorForm"
          ref="phoneAuthenticatorForm"
          v-loading="codeLoading"
          :model="form"
          autocomplete="false"
          :rules="rules"
          class="mt-0"
          @submit.native.prevent
        >
          <el-form-item
            prop="code"
            class="is-no-asterisk mt-2 mb-0"
            :error="verificationCodeError"
          >
            <template slot="label">
              <slot name="textarea-label"> Verification Code </slot>
              <br />
              <span
                class="sr-only"
                aria-live="polite"
                :hidden="!hasInlineValidationErrorFor('code')"
                v-text="getInlineValidationErrorFor('code')"
              ></span>
            </template>
            <el-input
              id="code"
              v-model="form.code"
              name="verification code"
              placeholder="verification code"
              :max-length="6"
              aria-required="true"
              @keyup.enter.native="submitConfirmation()"
            ></el-input>
          </el-form-item>
          <el-row class="mt-4">
            <el-col :xs="24" :sm="12">
              <el-button
                class="mt-3 mb-3 w-100 submit-btn mx-auto"
                @click="sendCode"
              >
                Send again
              </el-button></el-col
            >
            <el-col :xs="24" :sm="12">
              <el-button
                class="mt-3 mb-3 w-100 submit-btn mx-auto"
                type="primary"
                @click="submitConfirmation"
              >
                Submit
              </el-button></el-col
            >
          </el-row>
        </el-form>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
import gsApi from "@/services/gs.api";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import enumHelper from "@/helpers/enumHelper";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hasMultiFactorEnabled: {
      type: Boolean,
      default: false,
    },
    contactNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      step1: true,
      step2: false,
      phoneForm: {
        prefix: "+44",
        phoneNumber: this.contactNumber,
      },
      form: {
        code: null,
        enable: true,
        two_factor_type: enumHelper.MFAType.PHONE,
      },
      codeLoading: false,
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
            trigger: "blur",
          },
        ],
      },
      phoneFormRules: {
        phoneNumber: [
          {
            required: true,
            message: "Phone number is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.phoneInput.focus();
    });
  },
  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    async sendCode(sendAgain = false) {
      // Enable Two Factor Phone Authentication
      let isValid = false;
      if (!sendAgain) {
        this.$refs.phoneNumberForm.validate(async (valid) => {
          isValid = valid;
        });
      }

      if (!isValid && !sendAgain) return;

      this.codeLoading = true;
      const codeReponse = await gsApi.mfa
        .multiFactorAuthentification({
          two_factor_method: `${this.phoneForm.prefix}${this.phoneForm.phoneNumber}`,
          two_factor_type: enumHelper.MFAType.PHONE,
        })
        .catch((error) => {
          this.codeLoading = false;

          this.$alert(
            `<div>
              <p class='message-box-title mb-3'>Unsuccessful attempt</P>
              <p>${error.response.data.error.message}</p>  
              </div>`,
            "",
            {
              confirmButtonText: "Try again",
              dangerouslyUseHTMLString: true,
              customClass: "notification-modal message-modal",
            }
          );

          return false;
        });

      this.codeLoading = false;
      if (!codeReponse) {
        this.step1 = true;
        this.step2 = false;
        return;
      }

      this.step1 = false;
      this.step2 = true;
    },
    submitConfirmation() {
      this.$refs.phoneAuthenticatorForm.validate(async (valid) => {
        if (valid) {
          this.codeLoading = true;

          const twoFactorConfirmedResponse = await gsApi.mfa
            .confirmedMultiFactorPhoneAuthentication(this.form)
            .catch((error) => {
              //console.log(error?.response?.data?.error?.message);
              this.verificationCodeError =
                error?.response?.data?.error?.message;

              return false;
            });

          if (!twoFactorConfirmedResponse) {
            this.codeLoading = false;
            return;
          }

          this.$emit("refresh-user-multifactor-methods");

          this.verificationCodeError = null;
          // refresh the user data to get all available methods
          this.onClose();

          this.$alert(
            `<i class="el-icon el-icon-circle-check"></i>
                <p class='title mb-5 mt-2'>Multi-factor authentication enabled</p>
                <p class='method-name mb-2' >MOBILE</p>
                <p class='message'>Verification has successfully been added to your account</p>
                `,
            "",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "Close",
              showClose: true,
              customClass: "notification-modal message-modal",
            }
          );
        }
      });
    },
  },
};
</script>
