import { isMobile, isIOS, isSafari } from 'mobile-device-detect';
import moment from "moment";
export function generateGoogleUrl(event) 
{
  const url = [];
  url.push("https://calendar.google.com/calendar/render?action=TEMPLATE");

  const date = {
    startDate: event.begin_date,
    startTime: event.begin_time,
    endDate: event.begin_date,
    endTime: event.stop_time,
    repeatUntil: event.stop_date,
    timezone:
      event.user_timezone_abbreviation ?? event.local_timezone_abbreviation,
  };

  const formattedDate = generateDateTime(date, 'google', 'clean');

  url.push(
    "dates=" +
      encodeURIComponent(formattedDate.start) +
      "%2F" +
      encodeURIComponent(formattedDate.end)
  );

  url.push("ctz=" + date.timezone);

  url.push("text=" + event.title);

  const tmpDataDescription = [];
  if (event.description != null && event.description != "") {
    tmpDataDescription.push(event.description);
  }

  const location = getCalendarLocation(event);
  if (location) {
    url.push("location=" + encodeURIComponent(location));

    if (tmpDataDescription.length > 0) {
      tmpDataDescription.push("<br><br>");
    }
    tmpDataDescription.push("&#128205;: " + location);
  }

  if (tmpDataDescription.length > 0) {
    url.push("details=" + encodeURIComponent(tmpDataDescription.join("")));
  }

  if(formattedDate.repeatUntil)
  {
    url.push(`recur=RRULE:FREQ=DAILY;UNTIL=${formattedDate.repeatUntil}`);
  }

  return url.join("&");
}

export function generateMicrosoft(event, type = "365") {
  const date = {
    startDate: event.begin_date,
    startTime: event.begin_time,
    endDate: event.begin_date,
    endTime: event.stop_time,
    repeatUntil: event.stop_date,
    timezone:
      event.user_timezone_abbreviation ?? event.local_timezone_abbreviation,
  };
  const url = [];
  const basePath = isMobile ?  '/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent' : "/calendar/action/compose?rru=addevent"
  
  const baseUrl = type == 'outlook' ? `https://outlook.live.com${basePath}` : `https://outlook.office.com${basePath}`
  
  url.push(baseUrl);

  const formattedDate = generateDateTime(date, type);

  url.push("startdt=" + formattedDate.start);
  url.push("enddt=" + formattedDate.end);


  url.push(
    "subject=" + encodeURIComponent(event.title.replace(/&/g, "&#xFF06;"))
  );

  const location = getCalendarLocation(event);

  if (location) {
    url.push("location=" + encodeURIComponent(location));
  }

  url.push("body=" + encodeURIComponent(event.description));

  url.push("ctz=" + date.timezone);

  return url.join("&");
}

// will be used for apple type
export function generateIcal(event, host) {

  const date = {
    startDate: event.begin_date,
    endDate: event.begin_date,
    startTime: event.begin_time,
    endTime: event.stop_time,
    repeatUntil: event.stop_date,
  }

  const filename = event.slug;
    // otherwise, generate one on the fly
    const now = new Date();
    const formattedDate = generateDateTime(date, 'ical', 'clean');

    const ics_lines = ['BEGIN:VCALENDAR', 'VERSION:2.0']
    ics_lines.push('PRODID:-//EMBER LTD//GlobalScot//EN')
    ics_lines.push('CALSCALE:GREGORIAN')
    ics_lines.push('METHOD:PUBLISH')  
 
    ics_lines.push('BEGIN:VEVENT')
    ics_lines.push(`UID:${generateUUID().toUpperCase()}`)
    ics_lines.push(`DTSTAMP:${formatDateTime(now, 'clean', true)}`)
    ics_lines.push(`DTSTART:${formattedDate.start}`)
    ics_lines.push(`DTEND:${formattedDate.end}`)
    ics_lines.push(`SUMMARY:${rewriteIcalText(event.title, true)}`)

    if (event.description && event.description !== '') {
      ics_lines.push(`DESCRIPTION:${rewriteIcalText(event.description, true)}`);
      ics_lines.push('X-ALT-DESC;FMTTYPE=text/html:\r\n <!DOCTYPE HTML PUBLIC ""-//W3C//DTD HTML 3.2//EN"">\r\n <HTML><BODY>\r\n ' + rewriteIcalText(event.description, true) + '\r\n </BODY></HTML>');
    }

    const location = getCalendarLocation(event)
    if(location)
      ics_lines.push(`LOCATION:${rewriteIcalText(location, true)}`);
    
    if(event.url)
      ics_lines.push(`URL:${event.url}`);

    const count = moment(event.stop_date).diff(moment(event.begin_date), 'days') + 1
    if (count > 1) 
      ics_lines.push(`RRULE:FREQ=DAILY;COUNT=${count}`);

    ics_lines.push('SEQUENCE:0');
    ics_lines.push('STATUS:CONFIRMED');
    ics_lines.push(`CREATED:${formatDateTime(now, 'clean', true)}`);
    ics_lines.push(`LAST-MODIFIED:${formatDateTime(now, 'clean', true)}`);
    ics_lines.push('END:VEVENT');
    ics_lines.push('END:VCALENDAR');

    const dataUrl = `data:text/calendar;charset=utf-8,${encodeURIComponent(ics_lines.join('\r\n'))}`

    if ((isIOS && !isSafari)) {
      copyCalendar(dataUrl, host);
      return;
    }

    // save the calendar file localy
    saveFile(dataUrl, filename);
  
}

function generateDateTime(data,  targetCal, style='delimiters') {
  if (
    data.startTime &&
    data.startTime !== "" &&
    data.endTime &&
    data.endTime !== ""
  ) {
    // for the input, we assume GMT/UTC per default
    const newStartDate = new Date(
      data.startDate + "T" + data.startTime + ":00.000+00:00"
    );
    const newEndDate = new Date(
      data.endDate + "T" + data.endTime + ":00.000+00:00"
    );

    let newRepeatUntil = null
    if(data.repeatUntil)
    {
      newRepeatUntil = new Date(
        data.repeatUntil + "T" + '00:00' + ":00.000+00:00"
      );
    }

    const durationMS = newEndDate - newStartDate;
    const durationHours = Math.floor(durationMS / 1000 / 60 / 60);
    const durationMinutes = Math.floor(
      ((durationMS - durationHours * 60 * 60 * 1000) / 1000 / 60) % 60
    );
    const durationString = (function () {
      if (durationHours < 10) {
        return "0" + durationHours + ":" + ("0" + durationMinutes).slice(-2);
      }
      return durationHours + ":" + ("0" + durationMinutes).slice(-2);
    })();

    if (
      targetCal == "ical" ||
      (targetCal == "google" &&
        !/(GMT[+|-]\d{1,2}|Etc\/U|Etc\/Zulu|CET|CST6CDT|EET|EST|EST5EDT|MET|MST|MST7MDT|PST8PDT|WET)/i.test(
          data.timeZone
        ))
    ) {
      return {
        start: formatDateTime(newStartDate, style, true, true),
        end: formatDateTime(newEndDate, style, true, true),
        duration: durationString,
        repeatUntil: newRepeatUntil ? formatDateTime(newRepeatUntil, style, true, true) : null,
        allday: false,
      };
    }

    newStartDate.setTime(newStartDate.getTime());
    newEndDate.setTime(newEndDate.getTime());

    return {
      start: formatDateTime(newStartDate, style),
      end: formatDateTime(newEndDate, style),
      repeatUntil: newRepeatUntil ? formatDateTime(newRepeatUntil, style) : null,
      duration: durationString,
      allday: false,
    };
  }
}

// formating date time
function formatDateTime(
  datetime,
  style = "delimiters",
  includeTime = true,
  removeZ = false
) {
  const regex = (function () {
    // defines what gets cut off
    if (includeTime) {
      if (style == "clean") {
        return /(-|:|(\.\d{3}))/g;
      }
      return /(\.\d{3})/g;
    }
    if (style == "clean") {
      return /(-|T(\d{2}:\d{2}:\d{2}\.\d{3})Z)/g;
    }
    return /T(\d{2}:\d{2}:\d{2}\.\d{3})Z/g;
  })();
  const output = removeZ
    ? datetime.toISOString().replace(regex, "").replace("Z", "")
    : datetime.toISOString().replace(regex, "");
  return output;
}

// generate Event location
function getCalendarLocation(event) {
  if (event.type != 0) {
    return `${event.url ? 'OnLine: ' + event.url : null}`
  }

  let address = event.address_line_1;
  if (event.address_line_2) {
    address += `, ${event.address_line_2}`;
  }
  address += `, ${event.city}, ${event.postcode}`;
  return address;
}

function rewriteIcalText(content, truncate = true, inQuotes = false) {
  if (inQuotes) {
    content = content.replace(/"/g, '');
  } else {
    content = content.replace(/\\/g, '\\\\').replace(/(,|;)/g, '\\$1').replace(/\\\\n/g, '\\n');
  }
  if (truncate) {
    // adjusting for intended line breaks + making sure it does not exceed 75 characters per line
    content = content.replace(/.{60}/g, '$&' + '\r\n ');
  }
  return content;
}

function saveFile(file, filename) {
  try {
    const save = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    save.rel = 'noopener';
    save.href = file;
    
    if (isMobile) {
      save.target = '_self';
    } else {
      save.target = '_blank';
    }
    save.download = filename + '.ics';
    const evt = new MouseEvent('click', {
      view: window,
      button: 0,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(evt);
    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  } catch (e) {
    console.error(e);
  }
}

function copyCalendar(dataUrl, host) {

  copyToClipboard(dataUrl);
  
  host.$alert(
    `Unfortunatelt, iOS has some problems generating and opening the calendar file outside of Safari<br>
    We automatically copied a magical URL into your clipboard.<br>
    1. <strong>Open Safari</strong><br>
    2. <strong>Past</strong> the clipboard content and go
  `,'Open Safari', {
    confirmButtonText: "Close",
    showClose: false,
    customClass: "notification-modal",
    dangerouslyUseHTMLString:true
  });
  
}

function copyToClipboard(dataString){
  const tmpInput = document.createElement('input');
  document.body.append(tmpInput);
  const editable = tmpInput.contentEditable;
  const readOnly = tmpInput.readOnly;
  tmpInput.contentEditable = true;
  tmpInput.readOnly = false;
  tmpInput.value = dataString;
  if (isIOS) {
    var range = document.createRange();
    range.selectNodeContents(tmpInput);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    tmpInput.setSelectionRange(0, 999999);
  } else {
    tmpInput.select();
  }
  tmpInput.contentEditable = editable;
  tmpInput.readOnly = readOnly;
  document.execCommand('copy');
  tmpInput.remove();
}

function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}