<template>
  <el-card class="author-card box-card text-center" shadow="never">
    <user-avatar
      shape="circle"
      :isGlobalScot="!!(member && member.isExpert)"
      :authorExist="!!(member && member.id != null)"
      :size="74"
      :src="member? member.avatar : null"
      :alt="member ? member.name : '' + 'Profile picture'"
      :only-chevron="false"
    ></user-avatar>
    <div
      v-if="member && member.suspended"
      class="name-position user-not-active"
    >
      <h2>User is not currently active</h2>
    </div>
    <div v-else-if="member && member.id">
      <div class="name-position">
        <h2 class="text-truncate">{{ member.full_name }}</h2>
        <p class="text-truncate">{{ member.business }}</p>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="location-sectors">
        <div class="d-flex justify-content-center">
          <div><svg-map-pin-gradient></svg-map-pin-gradient></div>
          <p class="text-truncate">{{ member.location }}</p>
        </div>
        <div
          class="sectors d-flex justify-content-center sectors-text"
          v-if="member.sector.count"
        >
          <p class="text-truncate">{{ member.sector.names }}</p>
          <span v-if="member.sector.count > 2"
            >+{{ member.sector.count - 2 }} more</span
          >
        </div>
      </div>
      <div class="view-profile-link">
        <router-link
          :to="{
            name: profileLinkName,
            params: { id: member.id, showBackToSearch: true }
          }"
        >
          View profile
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="name-position">
        <h2 class="text-truncate">User no longer available</h2>
      </div>
    </div>
  </el-card>
</template>
<script>
import UserAvatar from "@/components/core/user-avatar"
import MapPinGradient from "@/components/assets/svg-map-pin-small";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import GsLogoSmall from "@/components/assets/svg-small-gs-logo-white";
export default {
  components: {
    "svg-map-pin-gradient": MapPinGradient,
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-small-gs-logo-white": GsLogoSmall,
    UserAvatar
  },
  props: {
    member: { type: Object }
  },
  computed: {
    profileLinkName() {
      if (this.member.isExpert) {
        return "GlobalScot Profile";
      } else if (this.member.isStaff) {
        return "Staff Profile";
      }
      return "NonGlobalScot Profile";
    }
  }
};
</script>
