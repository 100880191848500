<template>
  <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.4672 49.5499L61.3401 42.9919H52.1552L46.9272 32.4919L41.6982 42.9919H32.4722L40.3862 49.5529L36.4082 61.3629L46.9262 54.174L57.4032 61.3629L53.4672 49.5499Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M31.18 61.36C25.211 61.36 19.376 59.59 14.4129 56.2738C9.44983 52.9575 5.58159 48.2441 3.29733 42.7294C1.01308 37.2147 0.41541 31.1465 1.57991 25.2922C2.74441 19.4378 5.61879 14.0603 9.83954 9.83956C14.0603 5.61881 19.4378 2.74442 25.2922 1.57992C31.1465 0.415414 37.2147 1.01307 42.7294 3.29732C48.2441 5.58158 52.9575 9.44985 56.2738 14.4129C59.59 19.376 61.36 25.211 61.36 31.18"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.6191 45.612V39.051L11.4971 28.551L17.4071 19.366H32.4921V11.493L28.5551 8.86902L40.0061 2.31201"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.4878 10.854L48.9008 19.37H44.3078L39.7148 23.963"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
