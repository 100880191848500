<template>
  <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.958 17.1C19.0835 13.0166 20.5965 9.09771 23.2475 5.9893C25.8986 2.88089 29.5295 0.768412 33.542 0L34.977 3.1C31.747 4.318 28.906 8.557 28.59 11.48C30.3775 11.7634 32.0053 12.6754 33.1803 14.0519C34.3554 15.4284 35.0007 17.1791 35 18.989C35 23.629 31.232 26.25 27.418 26.25C23.021 26.25 18.958 22.889 18.958 17.1ZM0 17.1C0.125475 13.0167 1.63829 9.09796 4.28915 5.98957C6.94 2.88118 10.5707 0.768614 14.583 0L16.018 3.1C12.788 4.318 9.947 8.557 9.631 11.48C11.4187 11.7632 13.0467 12.6751 14.222 14.0516C15.3972 15.4282 16.0426 17.179 16.042 18.989C16.042 23.629 12.274 26.25 8.46 26.25C4.063 26.25 0 22.889 0 17.1Z"
      fill="#AF006E"
    />
  </svg>
</template><script>
export default {};
</script>
