<template>
  <div class="personal-details-page">
    <!--gs-personal-details-form v-if="isGsUser" />
    <non-gs-personal-details-form v-if="!isGsUser && !isStaffUser" />
    <staff-personal-details-form v-if="isStaffUser" /-->
    <gs-personal-details-form v-if="isExpert" />
    <non-gs-personal-details-form v-else-if="isTrader" />
    <staff-personal-details-form v-if="isStaff" />
  </div>
</template>

<script>
  import gsApi from '@/services/gs.api'
  import GsPersonalDetailsForm from "@/components/platform/edit-profile/gs-personal-details-form"
  import NonGsPersonalDetailsForm from "@/components/platform/edit-profile/non-gs-personal-details-form"
  import StaffPersonalDetailsForm from "@/components/platform/edit-profile/staff-personal-details-form"
  import {mapGetters} from 'vuex';
  export default {
    name: "personal-details-page",

    metaInfo: {
      title: "Personal details - Edit profile",
    },

    components: {
      GsPersonalDetailsForm,
      NonGsPersonalDetailsForm,
      StaffPersonalDetailsForm,
    },

    computed: {
      ...mapGetters(["isExpert", "isTrader", "isStaff"]),
      /*isGsUser() {
        return this.$store.state.user.isLogged && this.$store.state.user.roles[0] == 'expert' ? true : false
      },
      isStaffUser() {
        return this.$store.state.user.isLogged && this.$store.state.user.roles[0] == 'staff' ? true : false
      }*/
    }
  }
</script>
