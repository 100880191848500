<!-- NOTIFICATIONS -->
  <template>
    <notifications-form></notifications-form>
  </template>
  
  <script>
  import NotificationsForm from "@/components/platform/edit-profile/notifications-form";
  import { mapGetters } from "vuex";
  
  export default {
    name: "notifications",
    components:{
      NotificationsForm
    },
    metaInfo: {
      title: "Notifications - Settings"
    },
    computed: {
      ...mapGetters(["isExpert"]),
    },
  };
  </script>
  
  