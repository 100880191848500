<template>
  <div class="news-and-feed white-background-section">
    <div class="container">
      <div class="heading-text d-flex justify-content-between">
        <h2>Latest news & features</h2>
        <h2 class="view-pulse-feed view-page-link hidden-sm-and-down">
          <router-link :to="{ name: 'News and Features' }">
            View all news & features
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </h2>
      </div>
      <div>
        <div class="results-section">
          <div class="mt-4">
            <el-row v-if="articles.length > 0">
              <news-card v-for="article in articles" :key="article.id" :article="article" />
            </el-row>
            <el-row v-else>
              <el-col>
                <div class="no-event-text">
                  <h1>No news or features are available yet</h1>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <h2 class="view-pulse-feed view-page-link hidden-md-and-up mt-3">
        <router-link :to="{ name: 'News and Features' }">
          View all news & features
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </h2>
    </div>
  </div>
</template>

<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import NewsCard from "@/components/platform/news-features/news-card";

export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    NewsCard
  },
  props: ['articles']
};
</script>
