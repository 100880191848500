<template>
  <div
    class="resources-page body__content"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="search-section">
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <h1 class="mt-4 mb-2">Resources</h1>
            <p class="mb-3">Here you'll find useful references and documents from Scottish Government, Scottish Enterprise and other economic development organisations which may help you understand our priorities and how you as a GlobalScot can help us achieve our economic ambitions.</p>
          </el-col>
        </el-row>
        <el-form
          id="searchForm"
          :model="search"
          ref="searchForm"
          @submit.native.prevent
        >
          <el-row>
            <el-col :sm="24" :md="12">
              <el-form-item
                label="Keyword search"
                prop="keyword"
                class="hidden-sm-and-down"
              >
                <el-input
                  v-model="search.keyword"
                  placeholder="Search for file name, folder..."
                  id="keyword"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchSharedItems"
                />
              </el-form-item>
              <el-form-item label="" prop="keyword" class="hidden-md-and-up">
                <el-input
                  v-model="search.keyword"
                  @keyup.enter.native="searchSharedItems"
                  placeholder="Search for file name, folder..."
                  id="keyword"
                  auto-complete="no"
                  maxlength="255"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <div class="search-resources">
                <el-button
                  class="search-resources-button"
                  type="primary"
                  @click="searchSharedItems"
                >
                  Search
                </el-button>
              </div>
            </el-col>
            <el-col :sm="24" class="hidden-md-and-up">
              <el-button
                class="more-filters-btn"
                size="medium"
                @click="openResourcesFilter"
                >View More filters</el-button
              >
            </el-col>
          </el-row>
          <el-row class="hidden-sm-and-down">
            <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a sector</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="type_id" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">File type</span>
                  <span class="sr-only">Please select an file type</span>
                </template>
                <v-select
                  v-model="search.type_id"
                  placeholder="Choose from the following"
                  :options="fileTypes"
                  :reduce="fileType => fileType.id"
                  inputId="type_id"
                  autocomplete="false"
                  label="name"
                  class="half-content"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="6">
              <el-button
                type="primary"
                class="float-right update-results-btn"
                @click="searchSharedItems"
              >
                Update results
              </el-button>
              <el-button
                type="text"
                class="reset-btn float-right"
                @click="resetSearch"
                >Reset</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <hr />
    <div class="results-section" >
      <div class="zero-data text-center  mt-3 d-flex align-items-center" v-if="showZeroData">
      <div class="container">
        <el-row>
          <el-col :sm="24">
            <h3>
              We could not find any files with your search criteria. Please
              update your search and try again.
            </h3>
          </el-col>
        </el-row>
      </div>
    </div>
      <div class="container" v-else-if="searchResult.length || folders.length">
        <div v-if="searchWithCriteria && fromSearch" class="result-section result-files-section" >
          <el-row>
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                class="category-folder"
                v-for="(item, indexItem) in searchResult"
                :key="indexItem"
              >
                <component :is="item.is_folder? 'folder-item' : 'file-item'" :item="item" />
              </el-col>
            </el-row>
            <div class="d-flex justify-content-center">
              <el-pagination
                v-if="meta.total > 0"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="meta.current_page"
                :page-size="parseInt(meta.per_page)"
                layout="prev, pager, next"
                :total="meta.total"
                prev-text="< Previous"
                next-text="Next >"
              />
            </div>
        </div>
        <div v-else class="result-section">
          <div
            class="result-section-category"
            v-for="(cat, index) in folders"
            :key="index"
          >
            <h2>{{ cat.name }}</h2>
            <el-row>
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                class="category-folder"
                v-for="(folder, indexFolder) in cat.items"
                :key="indexFolder"
              >
                <folder-item :item="folder" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <resources-filter
      v-if="resourceFilterOpen"
      @close="closeResourcesFilter"
      @search="searchSharedItems(true)"
      @reset="resetSearch(true)"
      v-model="search"
      :sectors="sectors"
      :fileTypes="fileTypes"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import gsApi from "@/services/gs.api";
import FolderItem from "@/components/platform/resources/folder-item";
import FileItem from "@/components/platform/resources/file-item";
import ResourcesFilter from "@/components/slide-panel/panels/resources-filter";
export default {
  name: "resources",
  metaInfo: {
    title: "Resources"
  },
  components: {
    FolderItem,
    ResourcesFilter,
    FileItem
  },
  data() {
    return {
      mainLoader: false,
      showZeroData: false,
      search: {},
      folders: [],
      sectors: [],
      fileTypes: [],
      resourceFilterOpen: false,
      searchResult: [],
      meta: {
        total: 0,
        per_page: 12,
        current_page: 1
      },
      fromSearch: false
    };
  },
  computed: {
    ...mapState(["user", "resourceSearch"]),
    ...mapGetters(["isTrader"]),
    searchWithCriteria(){
      return (!!this.search.keyword || !!this.search.sector || !!this.search.type_id)
    }
  },
  created() {
    if (this.user.isLogged){
      if (this.isTrader){this.$router.push('/feed')}
      else{this.getRegisterMeta()}
    }

    let search = {
      keyword: this.resourceSearch.keyword,
      sector: this.resourceSearch.sector,
      type_id: this.resourceSearch.type_id,
      page: this.resourceSearch.page,
      per_page:12,
    };

    if (this.$route.query.hasOwnProperty("keyword")) {
      search.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.hasOwnProperty("sector")) {
      search.sector = parseInt(this.$route.query.sector);
    }
    if (this.$route.query.hasOwnProperty("type_id")) {
      search.type_id = parseInt(this.$route.query.type_id);
    }
    if (this.$route.query.hasOwnProperty("page")) {
      search.page = parseInt(this.$route.query.page);
    }

    this.search = search;
  },
  watch: {
    search: {
      handler(val) {
        let params = _.pickBy(val, function(o) {
          return o !== "" && o !== null && o !== undefined;
        });
        this.addParamsToLocation(params);
      },
      deep: true
    }
  },
  methods: {
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then(response => {
          this.sectors = response.data.groups.data;
          this.fileTypes = response.data.fileTypes.data;
          this.searchSharedItems();
        })
        .catch(error => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    searchSharedItems(isMobile = false){
      this.search.page = 1;
      this.fetchSharedFiles(isMobile)
    },
    resetSearch(isMobile) {
      if (isMobile) {
        this.closeResourcesFilter();
      }
      this.search = {
        keyword: null,
        sector: null,
        type_id: null,
        page:1,
      };
      this.fetchSharedFiles();
    },
    fetchSharedFiles(isMobile = false) {
      if (isMobile) {
        this.closeResourcesFilter();
      }
      if (!this.mainLoader) {
        this.mainLoader = true;
      }

      this.$store.commit("UPDATE_RESOURCE_SEARCH", this.search);

      let params = {
        type_id: this.search.type_id,
        group: this.search.sector,
        search: this.search.keyword,
      };


      params = this.searchWithCriteria 
        ? {...params, ...{include_all: true, per_page:this.meta.per_page, page:this.search.page}} 
        : {...params, ...{index:true}}
      
      gsApi.resource
        .search(params)
        .then(response => {

          if(this.searchWithCriteria){
            this.folders = [];
            this.searchResult = response.data.data
            this.meta = response.data.meta;
            this.showZeroData = this.searchResult.length ? false : true;
            this.fromSearch = true;
          }else{
            this.groupFoldersByCategory(response.data.data)
            .then(result => {
              this.searchResult = []
              this.folders = result;
              this.fromSearch = false
            })
            .finally(() => {
              this.showZeroData = this.folders.length > 0 ? false : true;
            });
          }  
        })
        .catch(() => {})
        .finally(() => {
          this.mainLoader = false;
        });
    },
    groupFoldersByCategory(items) {
      return new Promise(resolve => {
        const result = [];

        for (const item of items) {
          const categoryFound = result.find(cat => cat.id == item.category.id);
          if (categoryFound) {
            categoryFound.items.push(item);
          } else {
            result.push({
              id: item.category.id,
              name: item.category.name,
              items: [item]
            });
          }
        }
        resolve(result);
      });
    },
    openResourcesFilter() {
      this.resourceFilterOpen = true;
    },
    closeResourcesFilter() {
      this.resourceFilterOpen = false;
    },
    handleCurrentChange(page) {
      this.search.page = page;
      this.fetchSharedFiles();
    },
  }
};
</script>
