<template>
  <div id="cookie_directive_container" v-if="!acceptedCookieTerm">
    <div class="container d-flex">
      <el-row class="text-center">
        <el-col :xl="24" :lg="24" :md="24" :sm="24" class="cookie-section">
          <el-col class="text-section">
            This website uses cookies to ensure you get the best experience on our website. We also use this to collect analytics to better understand our visitors and offer them a better experience. You can accept all cookies at once.
            <el-button class="accept-terms" @click="acceptTerms">Accept</el-button>
          </el-col>
          <div id="close_cookie_directive_container"> <span @click="closeCookie">&nbsp;&nbsp;&nbsp;x</span></div>
        </el-col>
        <el-col :xl="24" :lg="24" :md="24" :sm="24" class="cookies-terms-btn text-center">
          <!-- <el-button class="accept-terms" @click="acceptTerms">Learn more</el-button> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import cookieMixin from '@/mixins/cookie-mixin'
export default {
  mixins: [cookieMixin],
  data() {
    return {
      acceptedCookieTerm: true,
    };
  },
  mounted() {
    this.acceptedCookieTerm = this.getCookie('globalscot-cookie-consent');
  },
  methods: {
    acceptTerms() {
      this.setCookie('globalscot-cookie-consent', true, 300);
      this.acceptedCookieTerm = true;
    },
    closeCookie () {
      this.acceptedCookieTerm = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.cookie-section{
  position: relative;
  .text-section{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
  }
  #close_cookie_directive_container{
    position: absolute;
    right: -15px;
    top: -8px;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    cursor: pointer;
  }
  a{
    font-weight: 700;
  }
  .accept-terms {
    font-size: 14px;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 0px 15px;
    background-color: $primary;
    border-color: $primary;
    padding: 10px 20px;
    text-transform: none;
    font-weight: 600;
    border-radius: 20px;

    &:hover {
      background-color: #e3e6ea;
      color: #606266;
      border-color: #e3e6ea;
    }
  }
}
#cookie_directive_container {
    background-color: rgba(0, 0, 0, .75);
    padding: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 25;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.75);
    .cookies-terms-btn {
      margin-top: 5px;
    }
}
</style>
