<template>
  <el-col :md="24" :lg="20" class="mb-4">
    <div class="address-details se-lead-paragraph-l">
      <span class="color-primary">
        {{ business.name }}
      </span>
      <span>
        Incorporated on {{ business.incorporation_date | moment('D MMMM YYYY') }}
      </span>
      <span>
        <span v-if="business.address_1">{{ business.address_1 }},</span>
        <span v-if="business.address_2">{{ business.address_2 }},<br/></span>
        <span v-if="business.town">{{ business.town }},</span>
        <span v-if="business.county">{{ business.county }},</span>
        <span v-if="business.post_code">{{ business.post_code }}</span>
      </span>
    </div>
    <div class="d-flex edit-company-actions">
      <el-button
        @click="businessEditToggle()"
        type="primary">
        <span v-if="!editMode">Edit details</span>
        <span v-else>Save details</span>
      </el-button>
      <el-button @click="removeFromBusinesses()">
        <span v-if="!editMode">Remove</span>
        <span v-else>Cancel</span>
      </el-button>
    </div>
    <el-form :model="editableBusiness" :rules="rules" ref="businessForm" v-if="editMode" id="businessForm" @submit.native.prevent @validate="handleBusinessFormFieldInput">
      <div class="address-details mb-3 mt-4">
        <el-row>
          <el-col :md="24" :lg="20">
            <el-form-item prop="address_1">
              <template slot="label">
                Address line 1
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('address_1')"
                      v-text="this.getInlineValidationErrorFor('address_1')"></span>
              </template>
              <el-input
                v-model="editableBusiness.address_1"
                id="address_1"
                autocomplete="false"
                maxlength="255"
                label="Please enter your company address line 1 in this field"
                aria-required="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" :lg="20">
            <el-form-item label="Address line 2" prop="address_2">
              <template slot="label">
                Address line 2
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('address_2')"
                      v-text="this.getInlineValidationErrorFor('address_2')"></span>
              </template>
              <el-input
                v-model="editableBusiness.address_2"
                id="address_2"
                autocomplete="false"
                maxlength="255"
                label="Please enter your company address line 2 in this field" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" :lg="20">
            <el-form-item prop="town">
              <template slot="label">
                City
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('town')"
                      v-text="this.getInlineValidationErrorFor('town')"></span>
              </template>
              <el-input
                v-model="editableBusiness.town"
                id="town"
                autocomplete="false"
                maxlength="100"
                label="Please enter your company city in this field"
                aria-required="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" :lg="20">
            <el-form-item prop="county">
              <template slot="label">
                County
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('county')"
                      v-text="this.getInlineValidationErrorFor('county')"></span>
              </template>
              <el-input
                v-model="editableBusiness.county"
                id="county"
                autocomplete="false"
                maxlength="100"
                label="Please enter your company county in this field" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" :lg="18">
            <el-form-item prop="post_code">
              <template slot="label">
                Postcode
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('post_code')"
                      v-text="this.getInlineValidationErrorFor('post_code')"></span>
              </template>
              <el-input
                v-model="editableBusiness.post_code"
                id="post_code"
                class="half-content"
                autocomplete="false"
                maxlength="10"
                label="Please enter your company postcode in this field"
                aria-required="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" :lg="20">
            <el-form-item prop="role">
              <template slot="label">
                Your role at company
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('role')"
                      v-text="this.getInlineValidationErrorFor('role')"></span>
              </template>
              <el-input
                v-model="editableBusiness.role"
                placeholder=""
                id="role"
                autocomplete="false"
                maxlength="255"
                label="Please enter your company role in this field"
                aria-required="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <div v-loading="isLoadingSubGroups">
          <el-row>
            <el-col :md="24" :lg="20" class="d-flex flex-column">
              <label for="group_ids">
                <span class="se-form-label-l-screen">
                Your company's sector
                </span>
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('group_ids')"
                      v-text="this.getInlineValidationErrorFor('group_ids')"></span>
              </label>
              <span class="se-form-label-l-screen color-light-gray mb-4">
                You can select multiple sectors
              </span>
              <el-form-item prop="group_ids" id="group_ids">
                <el-checkbox-group
                  v-model="editableBusiness.group_ids">
                  <el-row>
                    <el-col :md="24" :lg="12" class="mb-4" :key="group.id" v-for="group in groups">
                      <el-checkbox
                        name="edit_group_ids"
                        :label="group.id"
                        :key="group.id"
                        @change="selectedGroupsUpdatedForBusiness(group.id, $event)">
                        {{ group.name }}
                      </el-checkbox>
                    </el-col>
                  </el-row>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editableBusiness.group_ids.length > 0">
            <el-col :md="24" :lg="20">
              <el-form-item prop="edit_sub_group_ids">
                <template slot="label">
                  <span aria-hidden="true">Your company's sub sectors - Optional</span>
                  <span class="sr-only">Please select one or more sub sectors</span>
                  <span class="sr-only"
                        aria-live="polite"
                        :hidden="! this.hasInlineValidationErrorFor('sub_group_ids')"
                        v-text="this.getInlineValidationErrorFor('sub_group_ids')"></span>
                </template>
                <v-select
                  v-model="editableBusiness.sub_group_ids"
                  :options="subGroups"
                  :multiple="true"
                  :push-tags="true"
                  :clearable="false"
                  label="name"
                  autocomplete="false"
                  :reduce="subGroup => subGroup.id"
                  :select-on-tab="true"
                  :close-on-select="false" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </el-col>
</template>

<script>
import gsApi from '@/services/gs.api'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

export default {
  name: 'edit-business-test',
  mixins: [inlineValidationErrorsMixin],
  data () {
    return {
      editMode: false,
      isLoadingSubGroups: false,
      editableBusiness: {
        address_1: '',
        address_2: '',
        post_code: '',
        town: '',
        county: '',
        role: '',
        groups: [],
        subGroups: [],
        group_ids: [],
        sub_group_ids: []
      },
      rules: {
        address_1: [
          { required: true, message: 'Address line 1 is required', trigger: 'blur' },
          { max: 255, message: 'Address line 1 may not be greater than 255 characters', trigger: 'change' },
        ],
        address_2: [
          { max: 255, message: 'Address line 2 may not be greater than 255 characters', trigger: 'change' },
        ],
        town: [
          { required: true, message: 'City is required', trigger: 'blur' },
          { max: 100, message: 'City may not be greater than 100 characters', trigger: 'change' },
        ],
        county: [
          { max: 100, message: 'County may not be greater than 100 characters', trigger: 'change' },
        ],
        post_code: [
          { required: true, message: 'Postcode is required', trigger: 'blur' },
          { max: 10, message: 'Postcode may not be greater than 10 characters', trigger: 'change' },
        ],
        role: [
          { required: true, message: 'Role is required', trigger: 'blur' },
          { max: 255, message: 'Role may not be greater than 255 characters', trigger: 'change' },
        ],
        group_ids: [
          { type: 'array', required: true, message: 'Select at least one sector', trigger: 'change' }
        ],
      },
    }
  },
  props: {
    business: {
      type: Object,
      default: () => {}
    },
    groups: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    businessEditToggle () {
      if (this.editMode) {
        this.$refs['businessForm'].validate((valid) => {
          if (valid) {
            this.editMode = false
            this.$emit('update-business', this.editableBusiness)
            this.$alert('Company has been updated.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$nextTick(() => {
              document.getElementById("businessForm").getElementsByClassName('is-error')[0].scrollIntoView()
              let text = document.getElementById("businessForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        });
      } else {
        this.editMode = true
      }
    },
    selectedGroupsUpdatedForBusiness (groupId, checked) {
      if (checked) {
        this.isLoadingSubGroups = true

        gsApi.group.subGroups(groupId)
        .then(response => {
          this.isLoadingSubGroups = false
          this.subGroups.push(...response.data.data)
        })
      } else {
        // Removing selected sub groups where sub group is about to be removed below
        let subGroupsToBeRemoved = this.subGroups.filter(subGroup => subGroup.group_id === groupId)
        subGroupsToBeRemoved.forEach((subgroup) => {
          let index = this.editableBusiness.sub_group_ids.indexOf(subgroup.id)
          if (index > -1) {
            this.editableBusiness.sub_group_ids.splice(index, 1)
          }
        })

        // Removing sub groups with group_id of selected group
        this.subGroups = this.subGroups.filter(subGroup => subGroup.group_id !== groupId)
      }
    },
    setEditableBusiness () {
      this.editableBusiness.address_1 = this.business.address_1
      this.editableBusiness.address_2 = this.business.address_2
      this.editableBusiness.post_code = this.business.post_code
      this.editableBusiness.town = this.business.town
      this.editableBusiness.county = this.business.county
      this.editableBusiness.role = this.business.role
      this.editableBusiness.group_ids = JSON.parse(JSON.stringify(this.business.group_ids))
      this.editableBusiness.sub_group_ids = JSON.parse(JSON.stringify(this.business.sub_group_ids))
      this.subGroups = JSON.parse(JSON.stringify(this.business.sub_groups))
    },
    removeFromBusinesses(businesses) {
      if (this.editMode) {
        this.setEditableBusiness()
      } else {
        this.$emit('remove-business', this.editableBusiness)
      }

      this.editMode = false
    },
    handleBusinessFormFieldInput() {
      this.handleSingleFormFieldInput('businessForm');
    },
  },
  created () {
    this.setEditableBusiness()
    this.subGroups = JSON.parse(JSON.stringify(this.business.sub_groups))
  }
}
</script>
