<template>
  <div class="white-background-section pb-2">
    <div class="container">
      <div class="how-does-it-work-heading-text">
        <h1 class="how-title">{{howText.heading}}</h1>
        <p class="how-sub-text">{{howText.sub}}</p>
      </div>
      <div>
        <el-row>
          <el-col
            class="card-container"
            :sm="24"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="(card, index) in howSteps"
            :key="index"
          >
            <el-card class="box-card how-card-steps" shadow="always">
              <div class="how-card-steps">
                <div class="how-sub-text">
                  <h2 class="how-card-step">{{card.step}}</h2>
                  <p class="how-card-heading">{{card.heading}}</p>
                  <p class="how-card-sub">{{card.text}}</p>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      howText: {
        heading: "How does it work?",
        sub:
          "If your application is successful, we’ll automatically set up your profile for you so businesses can find you."
      },
      howSteps: [
        {
          step: "Step 1",
          heading: "A business member contacts you",
          text:
            "Businesses, professionals and entrepreneurs can submit a request for assistance. They’ll outline what kind of support they’re looking for from you. You can read the request and check out their profile to see if you can help."
        },
        {
          step: "Step 2",
          heading: "Working with the business ",
          text:
            "If you accept the request, you’ll be able to connect with the business directly to arrange how you’ll help. It’s up to both parties to manage the relationship after that."
        },
        {
          step: "Step 3",
          heading: "Wrapping up",
          text:
            "Once you’ve provided the assistance you agreed to, the request ends. You and the business you helped will have a chance to give feedback on how it went."
        }
      ]
    };
  }
};
</script>
<style>
.how-card-step {
  font-size: 35px;
  font-weight: 400;
  margin: 30px 0 30px 0;
}
.how-card-heading {
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 20px;
}
.how-card-sub {
  font-size: 19px;
}
</style>
