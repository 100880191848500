<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="signup-ngs-page page"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-${currentStep}`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div id="main-content" class="header__content__wrapper">
              <h2 class="header__title" v-html="heroText"></h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <el-row>
          <el-col :md="24" :lg="20" :xl="16">
            <!-- Step 0 Intro -->
            <div v-show="currentStep === 0" class="body__content">
              <h1 class="body__title">Join the GlobalScot network</h1>

              <p class="se-body-l-screen mb-4">
                GlobalScots are passionate about supporting Scottish businesses,
                culture and communities. This makes them a great resource for
                our partners in the public sector.
              </p>

              <p class="se-body-l-screen mb-4">
                GlobalScots can speak at events, lend their expertise to
                projects, help you find connections in the right places, or even
                just spread the word about the work you’re doing to their global
                networks.
              </p>

              <p class="se-body-l-screen mb-5">
                If you work for a Scottish public sector or government
                organisation, you can join the network as a member of Team
                Scotland today.
              </p>

              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="gotoStep(1)"
                >
                  Register now
                </el-button>
              </div>
            </div>
            <!-- Step 0 Intro End -->

            <!-- Step 1 Personal and Company details -->
            <div v-show="currentStep === 1" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(0)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 1"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step1Form"
                ref="step1Form"
                :model="step1Form.form"
                :rules="step1FormRules"
                autocomplete="false"
                @submit.native.prevent
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 2</span>
                  Personal and company details
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-4">Personal details</h2>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="title" class="is-no-asterisk">
                      <template slot="label">
                        <span aria-hidden="true"
                          >Title<span class="is-custom-asterisk">*</span></span
                        ><br />
                        <span class="sr-only">Please select a title</span>
                        <span class="el-form-item__info"
                          >This won't be publicly displayed on your
                          profile</span
                        >
                      </template>
                      <single-select
                        v-model="step1Form.form.title"
                        :options="step1Form.titles"
                        name="title"
                        class="half-content"
                      ></single-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="first_name">
                      <template slot="label"> First name </template>
                      <el-input
                        id="first_name"
                        v-model="step1Form.form.first_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your first name in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.first_name =
                            step1Form.form.first_name.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="last_name">
                      <template slot="label"> Last name </template>
                      <el-input
                        id="last_name"
                        v-model="step1Form.form.last_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your last name in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.last_name =
                            step1Form.form.last_name.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="contact_number" class="is-no-asterisk">
                      <template slot="label">
                        Contact number<span class="is-custom-asterisk">*</span
                        ><br />
                        <span class="el-form-item__info"
                          >This won't be publicly displayed on your
                          profile</span
                        >
                      </template>
                      <el-input
                        id="contact_number"
                        v-model="step1Form.form.contact_number"
                        class="w-lg-75"
                        maxlength="20"
                        autocomplete="false"
                        label="Please enter your contact number in this field"
                        aria-required="true"
                        @blur="
                          step1Form.form.contact_number =
                            step1Form.form.contact_number.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-4">
                    <el-form-item prop="organization_id" class="is-no-asterisk">
                      <template slot="label">
                        <span aria-hidden="true"
                          >Which organisation are you part of?
                          <span class="is-custom-asterisk">*</span></span
                        ><br />
                        <span class="sr-only"
                          >Please select which organisation you are part
                          of</span
                        >
                      </template>
                      <single-select
                        v-model="step1Form.form.organization_id"
                        :options="step1Form.organisations"
                        name="organization_id"
                        @input="onOrganisationChange($event)"
                      ></single-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row v-if="showCustomOrganisationField">
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="custom_organization">
                      <template slot="label"> Organisation </template>
                      <el-input
                        id="custom_organization"
                        v-model="step1Form.form.custom_organization"
                        autocomplete="false"
                        label="Please enter your organisation name"
                        aria-required="true"
                        @blur="
                          step1Form.form.custom_organization =
                            step1Form.form.custom_organization.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="17">
                    <el-form-item prop="expectations" class="is-no-asterisk">
                      <template slot="label">
                        Tell the community about your role and what you are
                        looking for from the network<span
                          class="is-custom-asterisk"
                          >*</span
                        >
                      </template>
                      <el-input
                        id="expectations"
                        v-model="step1Form.form.expectations"
                        type="textarea"
                        :rows="5"
                        maxlength="500"
                        label="Tell the community about your role and what you are looking for from the network"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :md="24" class="mt-n4 mb-4">
                    <span class="se-body-l-screen color-light-gray"
                      >You have
                      {{ 500 - step1Form.form.expectations.length }} characters
                      remaining</span
                    >
                  </el-col>
                </el-row>

                <h2 class="se-heading-m mb-2">
                  Where are you primarily based for work?
                </h2>

                <div class="row">
                  <div class="col-md-24 col-lg-18">
                    <el-form-item prop="country_id">
                      <template slot="label">
                        <span aria-hidden="true">Select a country</span>
                        <span class="sr-only">Please select a country</span>
                      </template>
                      <single-select
                        v-model="step1Form.form.country_id"
                        :options="step1Form.countries"
                        name="country_id"
                        @input="getStatesFromCountry"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div v-if="step1Form.states.length > 0" class="row">
                  <div class="col-md-24 col-lg-18">
                    <el-form-item prop="state_id">
                      <template slot="label">
                        <span aria-hidden="true">Select a City/State</span>
                        <span class="sr-only"
                          >Please select a City or State</span
                        >
                      </template>
                      <single-select
                        v-model="step1Form.form.state_id"
                        :options="step1Form.states"
                        name="state_id"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <h2 class="se-heading-m mb-2">
                  Company listed address (optional)
                </h2>
                <div
                  v-loading.fullscreen.lock="step1Form.addressesLoading"
                  class="d-flex flex-column align-items-stretch"
                  element-loading-background="rgba(255, 255, 255, 0.8)"
                >
                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                      <label for="mailing_post_code">
                        Post code/Zip code
                      </label>
                      <el-form-item prop="mailing_post_code">
                        <div class="d-flex">
                          <el-input
                            id="mailing_post_code"
                            v-model="step1Form.form.mailing_post_code"
                            class="half-flex-content"
                            maxlength="10"
                            autocomplete="false"
                            label="Please enter mailing post code in this field"
                            aria-required="true"
                            @blur="
                              step1Form.form.mailing_post_code =
                                step1Form.form.mailing_post_code.trim()
                            "
                            @input="step1Form.disableAddressLookup = false"
                            @keyup.enter.native="onFindAddress($event)"
                          />

                          <div class="mr-3"></div>

                          <el-form-item class="half-flex-content">
                            <el-button
                              size="medium"
                              :disabled="
                                step1Form.disableAddressLookup ||
                                step1Form.form.mailing_post_code.trim()
                                  .length == 0
                              "
                              @click="onFindAddress($event)"
                            >
                              Find address
                            </el-button>
                          </el-form-item>
                        </div>
                      </el-form-item>
                    </div>
                  </div>

                  <div
                    v-if="step1Form.availableMailingAddresses.length > 0"
                    class="row"
                  >
                    <div class="col-md-24 col-lg-20">
                      <label for="available_mailing_addresses">
                        <span aria-hidden="true">Addresses Found</span>
                        <span class="sr-only">Please select your address</span>
                      </label>
                      <el-form-item>
                        <single-select
                          id="available_mailing_addresses"
                          v-model="step1Form.selectedAvailableMailingAddress"
                          :options="step1Form.availableMailingAddresses"
                          name="available_mailing_addresses"
                          @input="onAvailableMailingAddressSelected($event)"
                        ></single-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                      <label for="mailing_address_1"> Address line 1 </label>
                      <el-form-item prop="mailing_address_1">
                        <el-input
                          id="mailing_address_1"
                          v-model="step1Form.form.mailing_address_1"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 1 in this field"
                          aria-required="true"
                          @blur="
                            step1Form.form.mailing_address_1 =
                              step1Form.form.mailing_address_1.trim()
                          "
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                      <label for="mailing_address_2">
                        Address line 2 - Optional
                      </label>
                      <el-form-item prop="mailing_address_2">
                        <el-input
                          id="mailing_address_2"
                          v-model="step1Form.form.mailing_address_2"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 2 in this field"
                          @blur="
                            step1Form.form.mailing_address_2 =
                              step1Form.form.mailing_address_2.trim()
                          "
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                      <label for="mailing_town"> Town, City or State </label>
                      <el-form-item prop="mailing_town">
                        <el-input
                          id="mailing_town"
                          v-model="step1Form.form.mailing_town"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing town, city or state in this field"
                          aria-required="true"
                          @blur="
                            step1Form.form.mailing_town =
                              step1Form.form.mailing_town.trim()
                          "
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                      <label for="mailing_county">
                        <span aria-hidden="true">Region - Optional</span>
                        <br />
                        <span class="el-form-item__info"
                          >Depending where you're based, this could be your
                          country, state, department or province</span
                        >
                      </label>
                      <el-form-item prop="mailing_county">
                        <el-input
                          id="mailing_county"
                          v-model="step1Form.form.mailing_county"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing region in this field"
                          @blur="
                            step1Form.form.mailing_county =
                              step1Form.form.mailing_county.trim()
                          "
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                      <label for="mailing_country_id">
                        <span aria-hidden="true">Country</span>
                        <span class="sr-only">Please select a country</span>
                      </label>
                      <el-form-item prop="mailing_country_id">
                        <single-select
                          id="mailing_country_id"
                          v-model="step1Form.form.mailing_country_id"
                          :options="step1Form.countries"
                          name="mailing_country_id"
                        ></single-select>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep1Form"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 1 Personal and Company details End -->

            <!-- Step 2 Create your account -->
            <div v-show="currentStep === 2" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(1)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 2"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                id="step2Form"
                ref="step2Form"
                :model="step2Form.form"
                :rules="step2FormRules"
                autocomplete="false"
                @submit.native.prevent
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 2</span>
                  Create your account
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-4">
                  Upload a profile photo - Optional
                </h2>

                <div v-if="step2Form.avatarFile" class="uploaded-file d-flex">
                  <el-avatar
                    shape="circle"
                    :size="140"
                    :src="avatarUrl"
                    alt="Profile picture"
                    class="step-3-avatar"
                  ></el-avatar>

                  <div class="flex-fill">
                    <div class="upload-actions">
                      <el-upload
                        ref="upload"
                        action=""
                        :multiple="false"
                        :drag="true"
                        list-type="picture-card"
                        class="selected-upload"
                        :auto-upload="false"
                        :on-change="onFileChange"
                      >
                        <div slot="default">
                          <el-button class="replace-button">
                            Replace
                          </el-button>
                        </div>

                        <div
                          v-if="step2Form.avatarFile"
                          slot="file"
                          slot-scope="{ file }"
                        ></div>
                      </el-upload>
                      <span
                        href="#"
                        class="remove-file-link"
                        @click="removeSelectedAvatar"
                        >Remove</span
                      >
                    </div>
                  </div>
                </div>

                <el-upload
                  ref="upload"
                  action=""
                  :multiple="false"
                  :drag="true"
                  list-type="picture-card"
                  class="initial-upload"
                  :class="{ 'upload-show': step2Form.avatarFile }"
                  :auto-upload="false"
                  :on-change="onFileChange"
                >
                  <div slot="default">
                    <div class="se-file-upload">
                      <img
                        src="/assets/img/file-drag-drop.svg"
                        alt="Drag & drop files here"
                        class="drag-drop-img"
                      />
                      <span class="or-text se-body-s-screen-16 m-4">or</span>
                      <el-button class="browse-button">
                        Browse files
                      </el-button>
                    </div>
                  </div>

                  <div
                    v-if="step2Form.avatarFile"
                    slot="file"
                    slot-scope="{ file }"
                  ></div>
                </el-upload>

                <div class="step-hr upload-hr"></div>

                <h2 class="se-heading-m mb-4">Login details</h2>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="email" class="is-no-asterisk">
                      <template slot="label">
                        Email address<span class="is-custom-asterisk">*</span
                        ><br />
                        <span class="el-form-item__info"
                          >This will not be displayed on your profile</span
                        >
                      </template>
                      <el-input
                        id="email"
                        v-model="step2Form.form.email"
                        type="email"
                        autocomplete="false"
                        label="Please enter your desired email address in this field"
                        aria-required="true"
                        @blur="
                          step2Form.form.email = step2Form.form.email.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="email_confirmation">
                      <template slot="label"> Confirm email address </template>
                      <el-input
                        id="email_confirmation"
                        v-model="step2Form.form.email_confirmation"
                        type="email"
                        autocomplete="false"
                        label="Please confirm the desired email address in this field"
                        aria-required="true"
                        @blur="
                          step2Form.form.email_confirmation =
                            step2Form.form.email_confirmation.trim()
                        "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="password">
                      <template slot="label"> Password </template>
                      <el-input
                        id="password"
                        v-model="step2Form.form.password"
                        :show-password="true"
                        autocomplete="false"
                        label="Please enter your desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <el-form-item prop="password_confirmation">
                      <template slot="label"> Confirm password </template>
                      <el-input
                        id="password_confirmation"
                        v-model="step2Form.form.password_confirmation"
                        :show-password="true"
                        autocomplete="false"
                        label="Please confirm the desired password in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-5">
                    <el-form-item prop="terms_agreed">
                      <el-checkbox-group v-model="step2Form.form.terms_agreed">
                        <el-checkbox label="confirmed" name="terms_agreed">
                          I agree to the
                          <a
                            :href="getCMSRoute('terms-conditions')"
                            class="text__link"
                            target="_blank"
                          >
                            Terms & conditions </a
                          >(including the GlobalScot acceptable use policy),
                          <a
                            :href="getCMSRoute('privacy-policy')"
                            class="text__link"
                            target="_blank"
                          >
                            privacy policy
                          </a>
                          and
                          <a
                            :href="getCMSRoute('cookie-policy')"
                            class="text__link"
                            target="_blank"
                          >
                            cookies policy
                          </a>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep2Form"
                >
                  Complete registration
                </el-button>
              </div>
            </div>
            <!-- Step 2 Create your account End -->

            <!-- Step 3 Verify your account -->
            <div v-show="currentStep === 3" class="body__content">
              <h1 class="se-heading-xl-40 mb-4">Check your email</h1>

              <el-row>
                <el-col :md="24" :lg="18">
                  <p class="se-body-l-screen-19 mb-4">
                    Thanks for registering with the GlobalScot network. We’ve
                    emailed you a link to verify your email address, which you
                    need to click to access the full network.<br />
                    <br />
                    Didn’t receive the email? Try checking your junk mail or we
                    can resend the email.
                  </p>
                </el-col>
              </el-row>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  :loading="isResendingEmailLoading"
                  @click="onResendEmail()"
                >
                  Resend email
                </el-button>
              </div>

              <p class="se-body-l-screen-19 mb-4">
                If you don’t receive your email within 1 hour,
                <a :href="getCMSRoute('contact-us')" class="text__link"
                  >contact us.</a
                >
              </p>
            </div>
            <!-- Step 4 Verify your account End -->
          </el-col>
        </el-row>
      </div>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropper-file="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import loqateApi from "@/services/loqate.api";
import gsApi from "@/services/gs.api";
import svgLinkPurple from "@/components/assets/svg-link-purple";
import CropperUpload from "@/components/cropper-upload/cropper-upload";
import validationErrors from "@/components/partials/validation-errors";
import { validate } from "email-validator";

export default {
  name: "SignupStaff",
  // metaInfo: {
  //   title: "Join as a staff member",
  //   meta: [{
  //     name: 'description',
  //     content: "Are you a business, entrepreneur or professional based in Scotland and looking for support? Join the free GlobalScot network as a staff member."
  //   }]
  // },
  components: {
    svgLinkPurple,
    validationErrors,
    "cropper-upload": CropperUpload,
  },
  data: function () {
    let validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else {
        callback();
      }
    };
    let validateEmailConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else if (value !== this.step2Form.form.email) {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else {
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[a-zA-Z0-9#?!@$%^&*-]{8,}$/;
      if (value === "") {
        callback(new Error("Enter a password"));
      } else if (reg.test(value) == false) {
        callback(
          new Error(
            "Password must be more than 8 characters long, should contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character (#?!@$%^&*-)."
          )
        );
      } else {
        callback();
      }
    };
    let validatePasswordConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Password doesn’t match. Make sure the password entered in both password fields is the same"
          )
        );
      } else if (value !== this.step2Form.form.password) {
        callback(
          new Error(
            "Password doesn’t match. Make sure the password entered in both password fields is the same"
          )
        );
      } else {
        callback();
      }
    };
    let validateContactNumber = (rule, value, callback) => {
      let reg =
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 7) {
        callback(new Error("Contact number may not be less than 7 characters"));
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Contact number field must be valid"));
      } else {
        callback();
      }
    };

    return {
      currentStep: 0,
      isResendingEmailLoading: false,
      validationErrors: [],
      mainLoader: false,
      signedUpId: null,
      signedUpEmail: null,
      inviteCode: "",
      inviteEmail: "",
      showCustomOrganisationField: false,

      cropperFile: null,
      showCropperDialog: false,

      step1Form: {
        // Step 1
        form: {
          title: "",
          first_name: "",
          last_name: "",
          contact_number: "",
          organization_id: null,
          custom_organization: "",
          expectations: "",
          country_id: null,
          state_id: null,
          mailing_address_1: "",
          mailing_address_2: "",
          mailing_town: "",
          mailing_county: "",
          mailing_country_id: "",
          mailing_post_code: "",
        },

        addressesLoading: false,
        disableAddressLookup: false,
        availableMailingAddresses: [],
        selectedAvailableMailingAddress: null,

        organisations: [],
        countries: [],
        states: [],

        titles: [
          { id: 1, label: "Mr" },
          { id: 2, label: "Mrs" },
          { id: 3, label: "Ms" },
          { id: 4, label: "Miss" },
          { id: 6, label: "Dr" },
          { id: 7, label: "Prof" },
          { id: 8, label: "Sir" },
          { id: 9, label: "Dame" },
          { id: 10, label: "Reverend" },
          { id: 11, label: "Duke" },
          { id: 12, label: "Duchess" },
          { id: 5, label: "Other" },
          { id: 13, label: "Mx" },
        ],
      },

      step1FormRules: {
        title: [
          {
            required: true,
            message: "Select a title from the dropdown",
            trigger: "change",
          },
        ],
        first_name: [
          { required: true, message: "Enter your first name", trigger: "blur" },
          {
            max: 64,
            message: "First name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Enter your last name", trigger: "blur" },
          {
            max: 64,
            message: "Last name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        contact_number: [{ validator: validateContactNumber, required: true }],
        organization_id: [
          {
            required: true,
            message: "Select an organisation from the dropdown",
            trigger: "change",
          },
        ],
        custom_organization: [
          {
            required: true,
            message: "Enter your organisation",
            trigger: "blur",
          },
        ],
        expectations: [
          {
            required: true,
            message:
              "Enter a description of what you are looking for from the network",
            trigger: "blur",
          },
          {
            max: 500,
            message: "May not be greater than 500 characters",
            trigger: "change",
          },
        ],
        country_id: [
          {
            required: true,
            message: "Select a country from the dropdown",
            trigger: "change",
          },
        ],
        state_id: [
          {
            required: true,
            message: "Select a state from the dropdown",
            trigger: "change",
          },
        ],
      },

      // Step 2
      step2Form: {
        form: {
          avatar: null,
          email: "",
          email_confirmation: "",
          password: "",
          password_confirmation: "",
          terms_agreed: [],
        },

        avatarFile: null,
      },

      step2FormRules: {
        email: [{ validator: validateEmail, required: true }],
        email_confirmation: [
          { validator: validateEmailConfirmation, required: true },
        ],
        password: [{ validator: validatePassword, required: true }],
        password_confirmation: [
          { validator: validatePasswordConfirmation, required: true },
        ],
        terms_agreed: [
          {
            type: "array",
            required: true,
            message:
              "Check the box to confirm you agree to our terms and conditions.",
            trigger: "change",
          },
        ],
      },
    };
  },

  computed: {
    heroText: function () {
      if (this.currentStep == 1) {
        return "Did you know our GlobalScots helped over 770 Scottish businesses in 2019?";
      } else if (this.currentStep == 2) {
        return "Did you know our GlobalScots helped over 770 Scottish businesses in 2019?";
      } else if (this.currentStep == 3) {
        return "Did you know our GlobalScots helped over 770 Scottish businesses in 2019?";
      } else if (this.currentStep == 4) {
        return "Registration complete! You're almost there.";
      } else {
        return "Did you know our GlobalScots helped over 770 Scottish businesses in 2019?";
      }
    },
    avatarUrl: function () {
      return this.step2Form.avatarFile.url;
    },
  },
  created() {
    this.inviteCode = this.$route.query.code;
    this.inviteEmail = this.$route.query.email;

    if (this.inviteEmail && this.inviteEmail !== "") {
      this.step2Form.form.email = this.inviteEmail;
      this.step2Form.form.email_confirmation = this.inviteEmail;
    }

    this.validateInviteCode();
  },

  methods: {
    onOrganisationChange(event) {
      let organisation = this.step1Form.organisations.find(
        (o) => o.id == event
      );

      if (organisation.name == "Other") {
        this.showCustomOrganisationField = true;
      } else {
        this.step1Form.form.custom_organization = "";
        this.showCustomOrganisationField = false;
      }
    },
    onResendEmail() {
      this.isResendingEmailLoading = true;
      gsApi.auth
        .resendVerification(this.signedUpId, this.signedUpEmail)
        .then((response) => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "A new email has been sent. Check your inbox.",
            "Success",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        })
        .catch(() => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },

    // Start of upload / cropper methods
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file;
        this.showCropperDialog = true;
      }
    },
    onCropperUpload(croppedFileData) {
      this.step2Form.avatarFile = croppedFileData;
      this.step2Form.form.avatar = croppedFileData.file;
      this.onCropperDialogClose();
    },
    onCropperDialogClose() {
      this.showCropperDialog = false;
      this.cropperFile = null;
    },
    removeSelectedAvatar() {
      this.step2Form.avatarFile = null;
      this.step2Form.form.avatar = null;
      this.cropperFile = null;
      this.$refs.upload.clearFiles();
    },
    validateAvatarUpload(file) {
      let type = file.raw && file.raw.type ? file.raw.type : file.type;
      let size = file.raw && file.raw.size ? file.raw.size : file.size;

      const isJPG = type === "image/jpeg";
      const isPNG = type === "image/png";
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert("Avatar must be in a jpeg or png format.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      } else if (!isLt6M) {
        this.$alert("Avatar size can not exceed 6MB.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    // End of upload / cropper methods

    submitStep1Form() {
      this.$refs["step1Form"].validate((valid) => {
        if (valid) {
          this.mainLoader = true;

          gsApi.staff
            .validateStepOne(this.step1Form.form)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(2);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step1Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step1Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitStep2Form() {
      this.$refs["step2Form"].validate((valid) => {
        if (valid) {
          const formData = new FormData();

          // Step 1
          formData.append("title", this.step1Form.form.title);
          formData.append(
            "first_name",
            this.$options.filters.domPurify(this.step1Form.form.first_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append(
            "last_name",
            this.$options.filters.domPurify(this.step1Form.form.last_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append("contact_number", this.step1Form.form.contact_number);
          formData.append(
            "organization_id",
            this.step1Form.form.organization_id
          );
          formData.append("expectations", this.step1Form.form.expectations);
          formData.append("country_id", this.step1Form.form.country_id);
          formData.append("state_id", this.step1Form.form.state_id);

          if (
            this.step1Form.form.custom_organization &&
            this.step1Form.form.custom_organization !== ""
          ) {
            formData.append(
              "custom_organization",
              this.step1Form.form.custom_organization
            );
          }

          if (this.step1Form.form.mailing_post_code !== "") {
            formData.append(
              "mailing_post_code",
              this.step1Form.form.mailing_post_code
            );
            formData.append(
              "mailing_address_1",
              this.step1Form.form.mailing_address_1
            );
            formData.append(
              "mailing_address_2",
              this.step1Form.form.mailing_address_2
            );
            formData.append("mailing_town", this.step1Form.form.mailing_town);
            formData.append(
              "mailing_county",
              this.step1Form.form.mailing_county
            );
            formData.append(
              "mailing_country_id",
              this.step1Form.form.mailing_country_id
            );
          }

          // Step 2
          formData.append("email", this.step2Form.form.email);
          formData.append(
            "email_confirmation",
            this.step2Form.form.email_confirmation
          );
          formData.append("password", this.step2Form.form.password);
          formData.append(
            "password_confirmation",
            this.step2Form.form.password_confirmation
          );

          if (this.step2Form.form.avatar) {
            formData.append("avatar", this.step2Form.form.avatar);
          }

          if (this.inviteCode) {
            formData.append("code", this.inviteCode);
          }

          this.mainLoader = true;

          gsApi.staff
            .register(formData)
            .then((response) => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.signedUpId = response.data.id;
              this.signedUpEmail = response.data.email;
              this.gotoStep(3);
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else if (
                error.response &&
                error.response.status == 403 &&
                error.response.data
              ) {
                this.$alert(
                  "The invite code is not valid. Please contact the GlobalScot team for further assistance.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
                this.gotoLink("/home");
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step2Form.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step2Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    getStatesFromCountry(countryId) {
      this.step1Form.form.state_id = null;
      this.mainLoader = true;

      gsApi.state.index(countryId).then((response) => {
        this.mainLoader = false;
        this.step1Form.states = response.data.data;
      });
    },
    validateInviteCode() {
      this.mainLoader = true;

      gsApi.users
        .checkInviteCode({ code: this.inviteCode })
        .then((response) => {
          if (response.data.valid) {
            this.getRegisterMeta();
          } else {
            this.mainLoader = false;
            this.$alert(
              "The invite code is not valid. Please contact the GlobalScot team for further assistance.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
            this.gotoLink("/home");
          }
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.gotoLink("/home");
        });
    },
    getRegisterMeta() {
      gsApi.country
        .index()
        .then((response) => {
          this.step1Form.countries = response.data.data;
          this.getOrganisations();
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.gotoLink("/home");
        });
    },
    getOrganisations() {
      gsApi.staff
        .organisations()
        .then((response) => {
          this.mainLoader = false;
          this.step1Form.organisations = response.data.data;
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
          this.gotoLink("/home");
        });
    },
    gotoStep(step) {
      this.currentStep = step || 0;

      if (this.currentStep == 1) {
        this.$announcer.set("Step 1 of 2 - Personal and company details");
      } else if (this.currentStep == 2) {
        this.$announcer.set("Step 2 of 2 -  Create your account");
      }

      window.scrollTo(0, 0);
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    onFindAddress(e) {
      this.findAddresses(this.step1Form.form.mailing_post_code);
    },
    findAddresses(value, containerId = "") {
      this.step1Form.addressesLoading = true;
      loqateApi
        .find(value, containerId, "")
        .then((response) => {
          this.step1Form.addressesLoading = false;

          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal",
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.step1Form.selectedAvailableMailingAddress = null;
              this.step1Form.form.mailing_address_1 = "";
              this.step1Form.form.mailing_address_2 = "";
              this.step1Form.form.mailing_town = "";
              this.step1Form.form.mailing_county = "";
              this.step1Form.form.mailing_country_id = null;

              this.step1Form.disableAddressLookup = true;
              this.step1Form.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.step1Form.availableMailingAddresses = [];
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.step1Form.addressesLoading = false;
          this.step1Form.availableMailingAddresses = [];
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    onAvailableMailingAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then((response) => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {
            this.step1Form.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.step1Form.form.mailing_address_1 = address.Line1;
            this.step1Form.form.mailing_address_2 = address.Line2;
            this.step1Form.form.mailing_post_code = address.PostalCode;
            this.step1Form.form.mailing_town = address.City;
            this.step1Form.form.mailing_county = address.AdminAreaName;

            let country = this.step1Form.countries.find(
              (c) =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.step1Form.form.mailing_country_id = country.id;
            }
          } else {
            this.$alert("No Postcode found. Please add it manually.", "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
          }
        })
        .catch((error) => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
  },
};
</script>

<style>
.step-3-avatar img {
  width: 140px;
  height: 140px;
}
</style>
