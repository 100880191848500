<template>
  <div class="signup-select-page page">
    <div class="signup-select-page__header small-header">
      <div class="header__content__bg"></div>
      <div class="header__image__bg signup-select-bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div class="header__content__wrapper">
              <h1 id="main-content" class="header__title">
                Register to access our support
              </h1>
              <h2 class="header__content-info mb-3">
                Our GlobalScots are passionate about sharing their knowledge,
                supporting businesses and promoting Scotland’s international
                success. Access this continent-spanning community and benefit
                from worldwide connections and latest insights.
              </h2>
              <p class="header__signin-link">Already registered?</p>
              <el-button
                class="btn-text join-home-btn"
                type="primary"
                @click="$router.push({ name: 'Login' })"
              >
                Sign in
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-select-page__body">
      <div class="container">
        <el-row>
          <el-col :md="24" :lg="24">
            <div class="signup-select-card">
              <div class="signup-select-card__content">
                <span class="se-heading-m signup-select-card__title"
                  >Get support for your business
                </span>

                <p class="se-body-l-screen">
                  Get free development support, business advice and access to
                  influential international contacts. <br /><br />
                  We can help if you’re:<br /><br />
                  • An ambitious company, entrepreneur or professional based in
                  Scotland<br />
                  • Planning to expand into international markets or grow your
                  business at home<br />
                  • Looking for new opportunities, connections and market
                  insights<br />
                  • Looking for support and advice from people who have done it
                  all before<br />
                  • Open to fresh perspectives and willing to learn<br />
                </p>

                <router-link
                  class="signup-select-btn el-button el-button--primary"
                  to="/sign-up-ngs"
                >
                  <span>Register now and grow your business</span>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--el-row>
          <el-col :md="24" :lg="24">
            <div class="signup-select-card">
              <div class="signup-select-card__content">
                <div class="signup-type-icon-wrapper">
                  <svg-gs-profile-icon></svg-gs-profile-icon>
                </div>

                <span class="se-heading-m signup-select-card__title"
                  >I want to become a GlobalScot</span
                >

                <p class="se-body-l-screen">
                  Keen to support Scotland’s internationally ambitious companies?<br /><br />
                  Become one of Scotland’s business ambassadors and join a worldwide network of passionate entrepreneurs and leaders. <router-link
                    :to="{ name: 'Become GlobalScot' }"
                    class="text__link"
                    >But what does being a GlobalScot mean? </router-link>

                  <br /><br />
                  You should be: <br />
                  <br />
                  • Internationally focused and experienced in key sectors for Scotland<br />
                  • Successful and inspirational, invested in Scotland’s global success<br />
                  •	Open to sharing knowledge, opportunities and contacts <br />
                  •	Aware of the altruistic nature of the role
                </p>

                <p class="se-body-l-screen signup-select-gs-readmore">
                  Think you’ve got the experience and network to support Scotland’s business future?
                </p>

                <router-link
                  class="signup-select-btn el-button el-button--primary"
                  to="/sign-up-gs"
                >
                  <span>Apply to GlobalScot</span>
                </router-link>
                <br>
              </div>
            </div>
          </el-col>
        </el-row-->
      </div>
    </div>
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
import SvgMemberProfileIcon from "@/components/assets/svg-member-profile-icon";
import SvgGsProfileicon from "@/components/assets/svg-gs-profile-icon";
export default {
  name: "SignupSelect",
  metaInfo: {
    title: "Join the GlobalScot network",
    meta: [
      {
        name: "description",
        content:
          "Are you a Scottish business looking for support or a business leader happy to offer your expertise? Join the GlobalScot network today.",
      },
    ],
  },
  components: {
    TopNavigation,
    "svg-member-profile-icon": SvgMemberProfileIcon,
    "svg-gs-profile-icon": SvgGsProfileicon,
  },
};
</script>

<style>
.festive-delay-text {
  font-size: 22px;
  margin-bottom: 25px;
  margin-left: 10px;
  font-style: italic;
  font-weight: 600;
  color: #bf3a27;
}

.join-home-btn {
  margin: 35px 0px 30px 0px;
}
</style>
