<script>
import { POINT_MARKER_ICON_CONFIG } from '@/components/map-view/config/map-config'

export default {
  name: 'map-marker',
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      mapMarker: null
    }
  },

  render() {},

  methods: {
    deleteMarker() {
      this.mapMarker.setMap(null)
    },
    createMarker() {
      const { Marker } = this.google.maps

      const icon = {
        url: this.marker.avatar,
        scaledSize: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(25, 25)
      }

      this.mapMarker = new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        icon: icon,
      })
      this.mapMarker.addListener('click', () => {
        this.$emit('onmarkerclick', { memberId: this.marker.memberId, marker: this.mapMarker, map: this.map })
      })

      this.$emit('onmapmarkeradded', this.mapMarker)
    }
  },
  mounted() {

    this.createMarker()
    this.$events.listen('deleteAllMarkers', this.deleteMarker)
  },
  watch: {
    marker(newMarker) {
      this.createMarker()
    }
  }
}
</script>
