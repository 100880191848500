<template>
  <slide-panel
    class="notifications-nav"
    :is-mobile="false"
    :is-notification="true"
    :slide-right="true"
    :close-as-text="false"
    title="Notifications"
    @panelclose="closeMenu"
  >
    <div>
      <div v-if="!isLoading && notifications.length" class="actions">
        <el-button @click="readAll"> Mark all as read </el-button>
        <span class="line">|</span>
        <el-button @click="deleteAll"> Delete all </el-button>
      </div>

      <div class="notifications-list">
        <div v-if="notifications.length" class="help-requests">
          <ul>
            <li
              v-for="notification in notifications"
              :key="notification.id"
              :class="{ 'new-not': !notification.is_read }"
              @click="read(notification)"
            >
              <router-link to="#" class="chat-message-notif">
                <div class="notif-block">
                  <div class="notif-img">
                    <div
                      v-if="notification.sender && notification.sender.avatar"
                      class="user-img"
                      :style="{
                        'background-image': `url( ${notification.sender.avatar} )`,
                      }"
                    ></div>
                    <div
                      v-else-if="
                        notification.action == 'profile-updated-by-admin'
                      "
                      class="user-img"
                      :style="{
                        'background-image': `url( ${notification.entity.avatar} )`,
                      }"
                    ></div>
                    <div
                      v-else-if="
                        notification.action == 'challenge-member-joined' ||
                        notification.action == 'challenge-updated' ||
                        notification.action ==
                          'challenge-participant-removed' ||
                        notification.action == 'challenge-invite'
                      "
                      class="user-img"
                      :style="{
                        'background-image': `url( ${notification.entity.mediaFile.small_thumb} )`,
                      }"
                    ></div>
                    <div
                      v-else-if="
                        notification.action === 'event-published' ||
                        notification.action === 'user-registered-for-event' ||
                        notification.action === 'event-cancelled'
                      "
                    ></div>
                    <div v-else class="user-img default"></div>
                  </div>
                  <span
                    v-if="
                      notification.action ==
                      'chat-message-notification-dispatched'
                    "
                    class="message-title"
                  >
                    <strong>{{ notification.sender.full_name }}:</strong>
                    {{ notification.entity ? notification.entity.body : "" }}
                  </span>
                  <span
                    v-else-if="
                      notification.action ==
                      'chat-message-file-notification-dispatched'
                    "
                    class="message-title"
                    v-html="notification.title"
                  ></span>
                  <span
                    v-else-if="
                      notification.action ==
                      'help-request-accepted-notification-dispatched'
                    "
                    class="message-title"
                  >
                    {{ notification.sender.full_name }} responded to your help
                    request
                  </span>
                  <span
                    v-else-if="
                      notification.action ==
                      'chat-low-engagement-notification-dispatched'
                    "
                    class="message-title"
                  >
                    Your chat with
                    <strong>{{ notification.sender.full_name }}</strong> has
                    gone quiet. Let us know if you're still working together.
                  </span>
                  <span
                    v-else-if="
                      notification.action ==
                      'help-request-declined-notification-dispatched'
                    "
                    class="message-title"
                  >
                    {{ notification.message }}
                  </span>
                  <span
                    v-else-if="
                      notification.action ==
                      'help-request-notification-dispatched'
                    "
                    class="message-title"
                  >
                    {{ notification.sender.full_name }} has submitted a help
                    request.
                  </span>
                  <span
                    v-else-if="
                      notification.action ==
                        'help-request-ended-notification-dispatched' ||
                      notification.action ==
                        'help-request-prompt-end-notification-dispatched'
                    "
                    class="message-title"
                  >
                    <!-- {{ notification.sender.full_name }} ended their request, '{{ notification.entity.help_request.subject }}'.-->
                    {{ notification.title }}
                  </span>
                  <span
                    v-else-if="notification.action == 'user-likes-your-post'"
                    class="message-title"
                    v-html="notification.title"
                  ></span>
                  <span
                    v-else-if="
                      notification.action == 'profile-updated-by-admin' ||
                      notification.action == 'activity-comment' ||
                      notification.action == 'challenge-member-joined' ||
                      notification.action == 'challenge-updated' ||
                      notification.action == 'challenge-participant-removed' ||
                      notification.action == 'challenge-invite' ||
                      notification.action ==
                        'help-request-no-feedback-notification' ||
                      notification.action ==
                        'help-request-ended-notification-dispatched'
                    "
                    class="message-title"
                  >
                    {{ notification.title }}
                  </span>
                  <span
                    v-else-if="notification.title != ''"
                    class="message-title"
                    >{{ notification.title }}</span
                  >
                  <span
                    v-else-if="notification.message != ''"
                    class="message-title"
                    >{{ notification.message }}</span
                  >
                </div>
              </router-link>
            </li>
          </ul>
        </div>

        <ul v-if="!isLoading && !notifications.length" class="mt-4">
          <li>
            <router-link to="#" class="chat-message-notif">
              <div class="notif-block">
                <div class="notif-img">
                  <svg-list></svg-list>
                </div>
                <h2 class="message-title">No new notifications</h2>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="isLoading"
      v-loading="isLoading"
      class="container notifications-loader"
    ></div>
    <div class="pagination-section">
      <el-button
        v-if="!onLastPage && !isLoading"
        class="load-more-btn"
        size="medium"
        @click="fetchNotifications()"
      >
        Load more
      </el-button>
    </div>
  </slide-panel>
</template>

<script>
import store from "@/store";
import gsApi from "@/services/gs.api";
import Bell from "@/components/assets/svg-bell";
import BlueList from "@/components/assets/svg-blue-list";
import SlidePanel from "@/components/slide-panel/slide-panel";
import Trap from "vue-focus-lock";
import { mapState } from "vuex";
import * as config from "@/config";
//import nonGsPersonalDetailsFormVue from '../../platform/edit-profile/non-gs-personal-details-form.vue';

export default {
  name: "NotificationsPanel",

  components: {
    "svg-bell": Bell,
    "svg-list": BlueList,
    "slide-panel": SlidePanel,
    Trap: Trap,
    "svg-bell": Bell,
    "svg-list": BlueList,
    "slide-panel": SlidePanel,
  },

  data: () => ({
    isLoading: true,
    notifications: [],
    page: 1,
    perPage: 20,
    onLastPage: false,
  }),

  created() {
    this.fetchNotifications();
  },

  methods: {
    closeMenu() {
      this.$emit("close");
    },

    fetchNotifications() {
      if (store.state.user.isLogged) {
        this.isLoading = true;
        gsApi.notifications
          .index({
            client_id: 2,
            page: this.page,
            per_page: this.perPage,
          })
          .then((response) => {
            this.isLoading = false;
            if (this.page == 1) {
              this.notifications = response.data.data;
            } else {
              this.notifications.push(...response.data.data);
            }
            store.commit(
              "UPDATE_NOTS",
              response.data.meta.total_not_read_count
            );
            if (
              response.data.meta.pagination.current_page ==
              response.data.meta.pagination.total_pages
            ) {
              this.onLastPage = true;
            } else {
              this.onLastPage = false;
              this.page++;
            }
          });
      }
    },

    readAll() {
      gsApi.notifications.readAll().then((response) => {
        store.commit("UPDATE_NOTS", null);
        this.page = 1;
        this.notifications = [];
        this.fetchNotifications();
      });
    },

    deleteAll() {
      gsApi.notifications.deleteAll().then((response) => {
        store.commit("UPDATE_NOTS", 0);
        this.page = 1;
        this.notifications = [];
        this.fetchNotifications();
      });
    },

    read(notification) {
      gsApi.notifications.read(notification.id).then((response) => {
        store.commit("UPDATE_NOTS", response.data.meta.total_not_read_count);
        this.redirectByAction(notification);
      });
    },

    redirectByAction(notification) {
      if (
        notification.action === "chat-message-notification-dispatched" ||
        notification.action === "chat-message-file-notification-dispatched" ||
        notification.action === "chat-low-engagement-notification-dispatched" ||
        notification.action === "help-request-no-feedback-notification" ||
        notification.action === "help-request-ended-notification-dispatched" ||
        notification.action ===
          "help-request-accepted-notification-dispatched" ||
        notification.action ===
          "help-request-prompt-end-notification-dispatched"
      ) {
        if (!notification.entity) {
          return;
        }

        let idChat = notification.entity.id;

        if (
          notification.action === "chat-message-notification-dispatched" ||
          notification.action === "chat-message-file-notification-dispatched" ||
          notification.action ===
            "help-request-accepted-notification-dispatched"
        ) {
          idChat = notification.entity.chat.id;
        }

        gsApi.chat
          .show(idChat)
          .then((response) => {
            if (response.data) {
              this.$store.commit("UPDATE_SELECTED_CHAT", response.data);
              if (this.$route.name != "My Chats") {
                this.closeMenu();
                this.$router.push({ name: "My Chats" });
              }
            } else {
              this.$alert("", `This chat is no longer available.`, {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
            }
          })
          .catch((exception) => {
            this.$alert(
              "",
              `Unfortunately we're having problems contacting our service. Please try again later.`,
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          })
          .finally(() => {
            this.closeMenu();
          });
      } else if (
        notification.action === "help-request-notification-dispatched" ||
        notification.action ===
          "help-request-no-response-notification-dispatched"
      ) {
        this.helpRequest = notification.entity;

        gsApi.globalscot
          .requestHelpValid(this.helpRequest.id)
          .then((response) => {
            if (response.data.valid) {
              this.$emit("request-notification", response.data.helpRequest);
              this.closeMenu();
            } else {
              this.$alert("", `This user is no longer available.`, {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
              this.closeMenu();
            }
          })
          .catch(() => {
            this.$alert(
              "",
              `Unfortunately we're having problems contacting our service. Please try again later.`,
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
            this.closeMenu();
          });
      } else if (
        notification.action === "challenge-member-joined" ||
        notification.action === "challenge-updated" ||
        notification.action === "challenge-participant-removed" ||
        notification.action === "challenge-invite"
      ) {
        this.$router.push({
          name: "Project",
          params: { id: notification.entity.id },
        });
        this.closeMenu();
      } else if (notification.action === "challenge-outcome-published") {
        this.$router.push({
          name: "News",
          params: { slug: notification.entity.slug },
        });
        this.closeMenu();
      } else if (
        notification.action === "challenge-updates" ||
        notification.action === "challenge-update-reply" ||
        notification.action === "challenge-update-reply-to-reply"
      ) {
        if (!notification.entity) return;

        let entity_id = null;

        if (notification.entity.post) {
          entity_id = notification.entity.post.id;
        } else {
          entity_id = notification.entity.id;
        }

        gsApi.post
          .show(entity_id)
          .then((response) => {
            if (response.data) {
              this.$emit("project-notification", response.data);
              this.$router.push({
                name: "Project",
                params: { id: response.data.challenge_id },
              });
              this.closeMenu();
            } else {
              this.$alert("", `This post is no longer available.`, {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
              this.closeMenu();
            }
          })
          .catch((exception) => {
            this.$alert(
              "",
              `Unfortunately we're having problems contacting our service. Please try again later.`,
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          })
          .finally(() => {
            this.closeMenu();
          });
      } else if (
        notification.action === "profile-updated-by-admin" ||
        notification.action === "user-follow"
      ) {
        if (
          notification.entity.roles.includes("trader") &&
          this.$route.name != "NonGlobalScot Profile" &&
          notification.entity.id != this.$route.params.id
        ) {
          this.$router.push({
            name: "NonGlobalScot Profile",
            params: { id: notification.entity.id },
          });
          this.closeMenu();
        } else if (
          notification.entity.roles.includes("expert") &&
          this.$route.name != "GlobalScot Profile" &&
          notification.entity.id != this.$route.params.id
        ) {
          this.$router.push({
            name: "GlobalScot Profile",
            params: { id: notification.entity.id },
          });
          this.closeMenu();
        } else if (
          notification.entity.roles.includes("staff") &&
          this.$route.name != "Staff Profile" &&
          notification.entity.id != this.$route.params.id
        ) {
          this.$router.push({
            name: "Staff Profile",
            params: { id: notification.entity.id },
          });
          this.closeMenu();
        }
      } else if (
        notification.action === "post-mentions" ||
        notification.action === "comment-mentions" ||
        notification.action === "activity-comment" ||
        notification.action === "activity-comment-reply" ||
        notification.action === "user-likes-your-post"
      ) {
        if (!notification.entity) return;

        let entity_id = null;

        if (notification.entity.post) {
          entity_id = notification.entity.post.id;
        } else {
          entity_id = notification.entity.id;
        }

        gsApi.post
          .show(entity_id)
          .then((response) => {
            if (response.data) {
              if (response.data.challenge_id !== null) {
                this.$emit("project-notification", response.data);
                this.$router.push({
                  name: "Project",
                  params: { id: response.data.challenge_id },
                });
              } else {
                this.$emit("pulse-notification", response.data);
                this.$router.push({ name: "Pulse" });
              }
            } else {
              this.$alert("", `This post is no longer available.`, {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
              this.closeMenu();
            }
          })
          .catch((exception) => {
            this.$alert(
              "",
              `Unfortunately we're having problems contacting our service. Please try again later.`,
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          })
          .finally(() => {
            this.closeMenu();
          });
      } else if (notification.action === "help-requests-paused-for-user") {
        window.open(getCMSRoute("contact-us"));
        this.closeMenu();
      } else if (
        notification.action === "help-request-reassigned-notification"
      ) {
        this.closeMenu();
      } else if (
        notification.action === "event-published" ||
        notification.action === "user-registered-for-event" ||
        notification.action === "event-registration-cancelled" ||
        notification.action === "event-cancelled" ||
        notification.action === "user-wait-listed-for-event"
      ) {
        this.$router.push({
          name: "Events",
          params: { slug: notification.entity.slug },
        });
        this.closeMenu();
      } else if (notification.action === "event-slot-booked") {
        this.$router.push({
          name: "Events",
          params: { id: notification.entity.eventDay.event_id },
        });

        this.closeMenu();
      } else if (notification.action === "event-slot-cancelled") {
        this.$router.push({
          name: "Events",
          params: { id: notification.entity.eventDay.event_id },
        });
        this.closeMenu();
      } else if (
        notification.action === "helprequest-inactive-for-last-6-weeks"
      ) {
        this.$store.commit("OPEN_END_HELPREQUEST_MODAL", {
          helpRequest: notification.entity,
        });
        this.closeMenu();
      } else {
        this.$router.push({ name: "Members" });
        this.closeMenu();
      }
    },
  },
};
</script>

<style lang="scss">
.load-more-btn {
  margin: 40px auto;
}
.actions .el-button:hover {
  background: transparent;
  color: #700e57;
}
</style>
