<template>
  <component v-bind:is="transformed"></component>
</template>
<script>
import gsApi from "@/services/gs.api";
import AuthorCard from "./author-card";
import store from '@/store';
export default {
  props: {
    content: String
  },
  computed: {
    transformed() {
      const template = `${this.displayUserInfoByTag()}`;
      return {
        template: template,
        components: {
          AuthorCard
        },
        props: this.$options.props,
        data() {
          return {
            loadingUser: false,
            taggedUser: null
          };
        },
        methods: {
          fetchUserData(id) {
            //if (this.taggedUser) return;
            this.loadingUser = true;
            gsApi.users
              .entityAuthor(id)
              .then(response => {
                this.taggedUser = response.data;
              })
              .catch(() => {})
              .finally(() => {
                this.loadingUser = false;
              });
          },
          searchByHashtag(hashtag) {
            if (this.$route.name === 'Pulse') {
              return store.dispatch('addHashtagFilter', hashtag);
            }

            this.$router.push({name: 'Pulse'}, function () {
                store.dispatch('addHashtagFilter', hashtag);
            });
          },
        }
      };
    }
  },
  methods: {
    displayUserInfoByTag: function() {
      const patternStrong = /<strong[\S\s]*?<\/strong>|<span[\S\s]*?<\/span>/g;

      let tagsArray = this.content.match(patternStrong);

      if (!tagsArray || !tagsArray.length) return `<div v-linkified>${this.content}</div>`;

      let template = this.content //this.HelperFunctions_modifyTextForLinks() ;

      for (let i = 0; i < tagsArray.length; i++) {
        template = template.replace( tagsArray[i], this.getTagTemplate(tagsArray[i]));
      }
      return `<div>${template}</div>`;
    },
    getTagTemplate(tag) {
      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(tag, "text/html");
      var el = htmlDoc.querySelector("strong") ?? htmlDoc.querySelector("span");

      if (el && el.dataset && el.dataset.userId && !el.dataset.hashtag) {
        return (
          "<el-popover " +
          'popper-class="author-card-popover" ' +
          'placement="top-start" ' +
          'width="290" ' +
          'trigger="hover" ' +
          '@show="fetchUserData(' +
          el.dataset.userId +
          ')" ' +
          ">" +
          "<div " +
          '  v-loading="this.loadingUser" ' +
          '  class="author-card-popover-loader" ' +
          ">" +
          "  <author-card " +
          '    v-if="this.taggedUser && !this.loadingUser" ' +
          '    :member="this.taggedUser" ' +
          "  ></author-card>" +
          "</div>" +
          '<span class="clickable" slot="reference">' +
          tag +
          "</span></el-popover>"
        );
      }

      if (el && el.dataset.hashtag) {
        return (`
          <span
            class="clickable"
            role="button"
            tabindex="0"
            aria-label="filter posts by hashtag ${el.dataset.mention}"
            @keypress.enter="searchByHashtag('${el.dataset.hashtag}')"
            @click.prevent="searchByHashtag('${el.dataset.hashtag}')"
          >${tag}</span>
        `);
      }

      return tag;
    },

    // Check	the	text	if	we	have	a	potential	link	with	this	Regex
    /*HelperFunctions_modifyTextForLinks: function() {
      var expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

      let urlArray = this.content.match(expression);
      if (!urlArray || !urlArray.length ) return this.content;

      let template = this.content;
      for (let i = 0; i < urlArray.length; i++) {
        template = template.replace( urlArray[i],  `<a class='linkified' target="_blank" href="${urlArray[i]}">${urlArray[i]}</a>`   );
      }
      return template;
    }*/
  }
};
</script>
