<template><svg width="261" height="35" viewBox="0 0 261 35" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
<path d="M62.4305 20.5293C62.0704 20.5293 61.7625 20.2116 61.7625 19.8294V16.3944C61.7625 16.0171 62.0657 15.6945 62.4305 15.6945H71.5405C71.9005 15.6945 72.2085 16.0122 72.2085 16.3944V28.2381C72.2085 28.6154 71.9005 28.938 71.5405 28.938H68.5133C68.1533 28.938 67.8453 28.6203 67.8453 28.2381V27.3943C67.8453 27.2205 67.6227 27.1312 67.5137 27.2751C66.3767 28.6451 64.3728 29.3699 62.3736 29.3699C56.0966 29.3699 50.876 24.0734 50.876 17.5807C50.876 11.093 56.0966 5.79163 62.3736 5.79163C65.8461 5.79163 69.0107 7.44955 71.1236 9.98111C71.3463 10.274 71.2894 10.7108 71.0146 10.9143L67.599 13.3317C67.2674 13.565 66.8789 13.5352 66.571 13.2175C65.5145 12.1106 64.0175 11.4107 62.3784 11.4107C59.1001 11.4107 56.4898 14.1458 56.4898 17.5807C56.4898 21.0157 59.1001 23.7508 62.3784 23.7508C64.2117 23.7508 65.8225 22.8176 66.8505 21.393C66.9357 21.2788 67.0163 21.0455 67.0163 20.8966V20.8072C67.0163 20.6633 66.8789 20.5144 66.7368 20.5144H62.4305V20.5293Z" fill="#333232"/>
<path d="M75.8462 28.9429C75.4862 28.9429 75.1782 28.6253 75.1782 28.243V6.93824C75.1782 6.56099 75.4814 6.23834 75.8462 6.23834H80.0388C80.3988 6.23834 80.7068 6.55603 80.7068 6.93824V22.7431C80.7068 23.0658 80.9578 23.3239 81.261 23.3239H90.4279C90.7879 23.3239 91.0959 23.6465 91.0959 24.0238V28.243C91.0959 28.6203 90.7879 28.9429 90.4279 28.9429H75.8462Z" fill="#333232"/>
<path d="M96.5671 17.5902C96.5671 21.0252 99.2058 23.7603 102.456 23.7603C105.791 23.7603 108.344 21.0252 108.344 17.5902C108.344 14.1552 105.791 11.4202 102.456 11.4202C99.2058 11.4202 96.5671 14.1552 96.5671 17.5902ZM90.958 17.5902C90.958 11.1025 96.207 5.80109 102.456 5.80109C108.79 5.80109 113.953 11.0975 113.953 17.5902C113.953 24.0829 108.79 29.3793 102.456 29.3793C96.207 29.3793 90.958 24.0829 90.958 17.5902Z" fill="#333232"/>
<path d="M126.981 19.6854H121.983C121.68 19.6854 121.429 19.9485 121.429 20.2662V23.0906C121.429 23.4083 121.68 23.6713 121.983 23.6713H126.981C128.483 23.6713 129.037 22.8275 129.037 21.6362C129.037 20.4746 128.483 19.6854 126.981 19.6854ZM126.318 11.5397H121.983C121.68 11.5397 121.429 11.8028 121.429 12.1205V14.5677C121.429 14.8854 121.68 15.1484 121.983 15.1484H126.01C127.512 15.1484 128.066 14.4188 128.066 13.3416C128.066 12.2644 127.483 11.5397 126.318 11.5397ZM116.54 28.9429C116.18 28.9429 115.872 28.6253 115.872 28.243V6.93824C115.872 6.56099 116.175 6.23834 116.54 6.23834H126.65C131.316 6.23834 133.675 8.88903 133.675 12.4977C133.675 14.5627 132.841 16.0171 131.51 16.9801C131.316 17.124 131.316 17.3871 131.539 17.5063C133.457 18.4395 134.651 20.0974 134.651 22.5396C134.651 26.2923 131.984 28.9429 127.597 28.9429H116.54Z" fill="#333232"/>
<path d="M147.982 20.3555C148.262 20.3555 148.427 20.0627 148.342 19.8046L146.371 14.2451C146.315 14.0415 146.04 14.0415 145.983 14.2451L143.984 19.8046C143.899 20.0676 144.069 20.3555 144.344 20.3555H147.982ZM143.178 6.88368C143.316 6.50643 143.704 6.24335 144.093 6.24335H148.257C148.645 6.24335 149.034 6.50643 149.171 6.88368L157.419 28.3027C157.528 28.5955 157.334 28.943 157.002 28.943H152.279C151.89 28.943 151.53 28.6799 151.388 28.3027L150.611 26.1781C150.502 25.8604 150.195 25.6271 149.863 25.6271H142.449C142.117 25.6271 141.809 25.8604 141.7 26.1781L140.923 28.3027C140.786 28.6799 140.421 28.943 140.033 28.943H135.31C134.978 28.943 134.784 28.5955 134.893 28.3027L143.178 6.88368Z" fill="#333232"/>
<path d="M160.177 28.9429C159.817 28.9429 159.509 28.6253 159.509 28.243V6.93824C159.509 6.56099 159.817 6.23834 160.177 6.23834H164.369C164.729 6.23834 165.037 6.55603 165.037 6.93824V22.7431C165.037 23.0658 165.288 23.3239 165.592 23.3239H174.758C175.119 23.3239 175.426 23.6465 175.426 24.0238V28.243C175.426 28.6203 175.119 28.9429 174.758 28.9429H160.177Z" fill="#333232"/>
<path d="M176.786 22.9462C176.757 22.5392 177.117 22.1321 177.506 22.1321H181.258C181.897 22.1321 182.035 22.2761 182.371 22.8866C182.76 23.6411 183.897 24.341 185.844 24.341C187.928 24.341 188.591 23.6113 188.591 22.9412C188.591 21.9832 187.506 21.3975 184.896 20.4692C181.452 19.2779 176.923 17.5306 176.923 12.8448C176.923 8.50638 180.453 5.80109 185.394 5.80109C190.448 5.80109 193.978 8.56595 194.253 12.6413C194.281 13.0483 193.921 13.4553 193.532 13.4553H189.671C189.146 13.4553 188.895 13.1922 188.729 12.671C188.397 11.713 187.341 10.8394 185.507 10.8394C183.617 10.8394 182.869 11.6237 182.869 12.3831C182.869 13.2866 183.868 13.7532 186.45 14.6814C189.449 15.7883 194.56 17.6498 194.56 22.6285C194.56 27.0513 190.894 29.3793 185.953 29.3793C181.035 29.3793 177.174 27.1059 176.786 22.9462Z" fill="#333232"/>
<path d="M212.535 21.1441C212.757 20.7669 213.146 20.6477 213.506 20.8264L217.367 22.6035C217.675 22.7177 217.812 23.1843 217.618 23.4771C215.647 27.0263 211.895 29.3841 207.674 29.3841C201.425 29.3841 196.176 24.0877 196.176 17.595C196.176 11.1023 201.425 5.80585 207.674 5.80585C211.895 5.80585 215.59 8.16367 217.589 11.6582C217.755 11.9511 217.646 12.4127 217.338 12.5616L213.506 14.3387C213.146 14.5422 212.757 14.428 212.535 14.0458C211.478 12.4723 209.702 11.4249 207.674 11.4249C204.424 11.4249 201.785 14.16 201.785 17.595C201.785 21.0299 204.424 23.765 207.674 23.765C209.73 23.7601 211.478 22.7127 212.535 21.1441Z" fill="#333232"/>
<path d="M224.003 17.5902C224.003 21.0252 226.642 23.7603 229.892 23.7603C233.227 23.7603 235.78 21.0252 235.78 17.5902C235.78 14.1552 233.227 11.4202 229.892 11.4202C226.647 11.4202 224.003 14.1552 224.003 17.5902ZM218.394 17.5902C218.394 11.1025 223.643 5.80109 229.892 5.80109C236.226 5.80109 241.389 11.0975 241.389 17.5902C241.389 24.0829 236.221 29.3793 229.892 29.3793C223.643 29.3793 218.394 24.0829 218.394 17.5902Z" fill="#333232"/>
<path d="M242.366 11.8872C242.006 11.8872 241.698 11.5695 241.698 11.1873V6.93824C241.698 6.56099 242.006 6.23834 242.366 6.23834H260.088C260.448 6.23834 260.756 6.56099 260.756 6.93824V11.1873C260.756 11.5645 260.448 11.8872 260.088 11.8872H254.56C254.252 11.8872 254.006 12.1503 254.006 12.468V28.243C254.006 28.6203 253.702 28.9429 253.338 28.9429H249.117C248.756 28.9429 248.449 28.6253 248.449 28.243V12.468C248.449 12.1503 248.197 11.8872 247.894 11.8872H242.366Z" fill="#333232"/>
<path d="M20.8269 14.4734C21.3243 14.4734 21.8217 14.3344 22.2671 14.0564L36.6451 5.01231C37.0714 4.74426 37.2135 4.16349 36.953 3.71675L35.636 1.41849C35.3802 0.971742 34.8259 0.822827 34.3995 1.09584L21.7554 9.04295C21.1822 9.40035 20.4716 9.40035 19.8984 9.04295L7.25425 1.09087C6.82788 0.822827 6.27361 0.966779 6.01779 1.41352L4.69605 3.71675C4.44023 4.16349 4.57762 4.74426 5.00398 5.01231L19.382 14.0515C19.8273 14.3344 20.3295 14.4734 20.8269 14.4734Z" fill="#0F476D"/>
<path d="M19.3816 21.393L5.00838 30.4321C4.58201 30.7002 4.44463 31.2809 4.70045 31.7277L6.01744 34.026C6.27326 34.4727 6.82754 34.6216 7.2539 34.3486L19.898 26.4015C20.4712 26.0441 21.1819 26.0441 21.7551 26.4015L34.3992 34.3486C34.8256 34.6166 35.3798 34.4727 35.6357 34.026L36.9526 31.7327C37.2085 31.2859 37.0711 30.7051 36.6447 30.4371L22.2667 21.393C21.3808 20.837 20.2723 20.837 19.3816 21.393Z" fill="#0F476D"/>
<path d="M14.104 17.7197C14.104 16.8064 13.6397 15.9526 12.896 15.481L2.68213 9.0628C2.25576 8.79475 1.70149 8.9387 1.44567 9.38545L0.12867 11.6837C-0.12715 12.1305 0.0102346 12.7112 0.4366 12.9793L7.32477 17.3127C7.61849 17.4964 7.61849 17.9381 7.32477 18.1218L0.4366 22.4602C0.0102346 22.7282 -0.12715 23.309 0.12867 23.7607L1.44567 26.059C1.70149 26.5057 2.25576 26.6497 2.68213 26.3816L12.896 19.9634C13.6397 19.4918 14.104 18.6331 14.104 17.7197Z" fill="#0F476D"/>
<path d="M34.3187 17.3177L41.2116 12.9793C41.638 12.7112 41.7801 12.1305 41.5195 11.6837L40.2025 9.38545C39.9467 8.9387 39.3924 8.79475 38.9661 9.0628L28.7475 15.481C27.6389 16.176 27.1557 17.7346 27.8853 19.0451C28.1032 19.4422 28.4301 19.7599 28.8091 19.9932L38.9661 26.3767C39.3924 26.6447 39.9467 26.5008 40.2025 26.054L41.5195 23.7558C41.7753 23.309 41.638 22.7283 41.2116 22.4602L34.3139 18.1268C34.025 17.9431 34.025 17.5013 34.3187 17.3177Z" fill="#0F476D"/>
</svg></template><script>export default {}</script>
