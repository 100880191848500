<template>
  <div class="d-flex folder align-items-center">
    <div class="icon clickable" @click="viewItem">
      <component v-bind:is="item.svg_icon"></component>
    </div>
    <div class="name clickable" @click="viewItem">
      <div class="font-weight-bold mb-1">
        {{ item.title }}
      </div>

      <small>{{item.total_files}}{{ item.total_files > 1 ? " files" : " file" }} </small>
      <span v-if="item.total_folders">|</span>
      <small v-if="item.total_folders" >{{item.total_folders}}{{ item.total_folders > 1 ? " folders" : " folder" }} </small>
    </div>
  </div>
</template>
<script>

import * as icons from '../../assets';
export default {
  name: 'folder-item',
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  components: {
    SvgFolder: icons.SvgFolder,
  },
  methods: {
    viewItem() {
      this.$router.push({
          name: 'Resource',
          params: { id: this.item.id },
        });
    },
  },
};
</script>
