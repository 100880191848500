<template>
  <focus-lock>
    <el-dialog
      id="cancelEventAttendance"
      ref="cancelEventAttendance"
      @opened="dialogOpen('cancelEventAttendance')"
      aria-describedby="dialogDescription"
      custom-class="events-general-dialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="Please confirm you want to sign up for this event"
      @close="onClose($event)"
    >
      <div class="body">
        <h2 class="event-dialog-title">Can’t make this event?</h2>
        <p class="event-dialog-text" v-text="dialogText"></p>
        <div class="button-wrap d-flex events-dialog-buttons">
          <el-button class="events-dialog-cancel" @click="onClose($event)">
            Cancel
          </el-button>
          <el-button
            class="events-dialog-confirm"
            type="primary"
            @click.prevent="cancelEventAttendance()"
            :loading="mainLoader"
          >
            I can't attend
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import store from '@/store'
import gsApi from '@/services/gs.api'

export default {
  name: 'CancelEventAttendanceDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: null
    },
    is121Host: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mainLoader: false,
      dialogVisible: false
    }
  },

  computed: {
    dialogText () {
      return this.is121Category
        ? this.textFor121Event
        : 'If you can’t attend this event, we can give your space to someone else. You’ll be removed from the attendance list.';
    },
    is121Category() {
      return this.event.category === 0;
    },
    textFor121Event() {
      return this.is121Host
        ? 'Are you sure you wish to remove yourself from this event. All users will lose the 1-2-1 slots they have booked with you.'
        : 'If you can’t attend this event, we can give your space to someone else. You’ll be removed from the attendance list and you’ll lose any 1-2-1 slots you’ve booked.';
    }
  },

  methods: {
    onClose (e) {
      this.dialogVisible = false
      this.$emit('closed')
    },

    cancelEventAttendance () {
      this.mainLoader = true

      gsApi.events.cancelAttendance(this.$route.params.slug)
        .then(response => {
          this.$emit('cancelledAttendance');
        }).catch(error => {
        this.mainLoader = false
        if (error.response && error.response.status == 422) {
          this.$alert('Unable to cancel event attendance.', 'Error', {
            confirmButtonText: 'Close',
            showClose: false,
            customClass: 'notification-modal'
          })
        } else if (error.response && error.response.status == 403) {
          this.$alert('You have already cancelled your attendance to this event.', 'Error', {
            confirmButtonText: 'Close',
            showClose: false,
            customClass: 'notification-modal'
          })
        } else {
          this.$alert('Unable to cancel event attendance, please try again later.', 'Error', {
            confirmButtonText: 'Close',
            showClose: false,
            customClass: 'notification-modal'
          })
        }
      }).finally(() => {
        this.mainLoader = false
        this.onClose()
      })
    },
  },

  watch: {
    visible (value) {
      this.dialogVisible = this.visible
    }
  },
  created () {
    this.dialogVisible = this.visible
  }
}
</script>

<style lang="scss">
.events-general-dialog {
  .body {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .event-dialog-title {
    font-style: normal;
    font-weight: 420;
    font-size: 2.1875rem;
    line-height: 45px;
    /* identical to box height, or 129% */

    text-align: center;

    color: #000000;
    @media screen and(max-width: 425px) {
      font-size: 1.4rem;
    }
  }

  .event-dialog-text {
    font-style: normal;
    font-weight: 390;
    font-size: 1.5rem;
    line-height: 34px;
    text-align: center;
    color: #000000;
    padding-top: 1.375rem;
    max-width: 586px;
    width: 100%;
  }

  .events-dialog-buttons {
    width: 100%;

    .events-dialog-cancel {
      width: 100%;
      max-width: 231px;
    }

    .events-dialog-confirm {
      width: 100%;
      max-width: 231px;
    }

    @media screen and(max-width: 425px) {
      flex-direction: column;
      width: 100%;

      .events-dialog-confirm {
        width: 100%;
        margin: 0.625rem 0 0;
      }
    }
  }

  .sign-up-buttons {
    padding-top: 2rem;
    @media screen and(max-width: 425px) {
      padding-top: 1rem
    }
  }
}

</style>
