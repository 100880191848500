<!-- Login Component -->
<template>
  <div class="login-page page">
    <div class="login-page__header">
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div class="header__content__wrapper">
              <h1 class="header__title">Sign in to your GlobalScot account</h1>
              <h2 class="header__content-info">
                Get the most out of the support available by contributing to the
                noticeboard, reading our news and feature articles, and
                connecting with GlobalScots.
              </h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="login-page__body">
      <div class="container">
        <el-row>
          <el-col :md="24" :lg="12">
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
            <el-form
              id="loginForm"
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
              @validate="handleLoginFormFieldInput"
            >
              <div class="body__content">
                <h2 id="main-content" class="body__title">Sign in</h2>

                <div class="register-link-wrapper hidden-sm-and-down">
                  <span class="register-link-description">
                    Don't have an account?
                  </span>

                  <router-link
                    :to="{ path: '/sign-up-select' }"
                    class="se-form-label-l-screen text__link"
                  >
                    <span>Register now</span>
                  </router-link>
                </div>

                <el-form-item prop="email">
                  <template slot="label">
                    Email Address
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('email')"
                      v-text="getInlineValidationErrorFor('email')"
                    ></span>
                  </template>
                  <el-input
                    id="email"
                    v-model="loginForm.email"
                    type="email"
                    placeholder="Your email address"
                    label="Please enter your email address in this field"
                    aria-required="true"
                  />
                </el-form-item>

                <el-form-item prop="pass">
                  <template slot="label">
                    Password
                    <span
                      class="sr-only"
                      aria-live="polite"
                      :hidden="!hasInlineValidationErrorFor('pass')"
                      v-text="getInlineValidationErrorFor('pass')"
                    ></span>
                  </template>
                  <el-input
                    id="pass"
                    v-model="loginForm.pass"
                    type="password"
                    auto-complete="off"
                    placeholder="Your password"
                    label="Please enter your password in this field"
                    aria-required="true"
                  />
                </el-form-item>

                <router-link
                  to="/forgot-password"
                  class="se-form-label-l-screen text__link forgot-link align-self-start"
                >
                  <span>Forgot password?</span>
                </router-link>

                <div class="login-button-wrapper">
                  <el-button
                    type="primary"
                    native-type="submit"
                    :loading="isLoading"
                    @click.prevent="submitForm('loginForm')"
                  >
                    Sign in
                  </el-button>
                </div>

                <div class="register-link-wrapper hidden-md-and-up">
                  <span class="register-link-description">
                    Don't have an account?
                  </span>

                  <router-link
                    :to="{ path: '/sign-up-select' }"
                    class="se-form-label-l-screen text__link"
                  >
                    <span>Register</span>
                  </router-link>
                </div>

              </div>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
    <suspended-dialog
      v-if="showSuspendedDialog"
      :visible="showSuspendedDialog"
      @closed="showSuspendedDialog = false"
    />
    <suspended-account-by-admin-dialog
      v-if="showSuspendedAccountByAdmindialog"
      :visible="showSuspendedAccountByAdmindialog"
      @closed="showSuspendedAccountByAdmindialog = false"
    />
    <closed-account-dialog
      v-if="showClosedAccountDialog"
      :visible="showClosedAccountDialog"
      @closed="showClosedAccountDialog = false"
    />
    <closed-account-by-admin-dialog
      v-if="showClosedAccountByAdminDialog"
      :visible="showClosedAccountByAdminDialog"
      @closed="showClosedAccountByAdminDialog = false"
    />
    <multi-factor-confirmation 
      v-if="showMultiFactorConfirmationDialog && selectedMultiFactorMethod"
      :visible="showMultiFactorConfirmationDialog"
      :method="selectedMultiFactorMethod"
      :available-methods-count="getAvailableMethods.length"
      @continue-with-login="continueWithLogin"
      @closed="showMultiFactorConfirmationDialog = false"
      @on-select-different-method="handleSelectDifferentMethod"
    />
    <multi-factor-method-selector
      v-if="showDifferentMethodDialog"
      :visible="showDifferentMethodDialog"
      :available-methods="selectedMultiFactorMethod ? getAvailableMethods.filter(m=> m.id != selectedMultiFactorMethod.id) : getAvailableMethods"
      @on-select-multi-factor-method="handleSelectMultiFactorMethod"
      @on-close="handleOnClose"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import gsApi from "@/services/gs.api";
import SuspendedDialog from "@/components/landing/SuspendedDialog";
import SuspendedAccountByAdminDialog from "@/components/landing/SuspendedAccountByAdminDialog";
import validationErrors from "@/components/partials/validation-errors";
import ClosedAccountDialog from "@/components/landing/ClosedAccountDialog";
import ClosedAccountByAdminDialog from "@/components/landing/ClosedAccountByAdminDialog";
import MultiFactorConfirmation from '@/components/landing/MultiFactorConfirmation'
import MultiFactorMethodSelector from '@/components/landing/MultiFactorMethodSelector'
import { validate } from "email-validator";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import enumHelper from "@/helpers/enumHelper";

export default {
  name: "Login",
  components: {
    validationErrors,
    SuspendedDialog,
    ClosedAccountDialog,
    ClosedAccountByAdminDialog,
    SuspendedAccountByAdminDialog,
    MultiFactorConfirmation,
    MultiFactorMethodSelector
  },
  mixins: [inlineValidationErrorsMixin],
  metaInfo: {
    title: "Sign in to your GlobalScot account",
    meta: [
      {
        name: "description",
        content:
          "Get the most out of the GlobalScot network by contributing to the noticeboard, reading our news and feature articles, and connecting with GlobalScots.",
      },
    ],
  },
  computed: {
    ...mapState(["app"]),
    getAvailableMethods(){
      return this.response?.data?.availableMfaMethods?.data
    }
  },
  created() {
    if (store.state.user.isLogged) {
      this.$router.push("/feed");
    }

    this.showSuspendedDialog = this.app.loggedOutBySuspension;
    this.showClosedAccountDialog = this.app.loggedOutByClosedAccount;
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input your password"));
      } else {
        callback();
      }
    };
    let validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input your email"));
      } else if (validate(value) == false) {
        callback(new Error("Please enter a valid email address"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        email: "",
        pass: "",
      },
      rules: {
        email: [{ validator: validateEmail, required: true }],
        pass: [{ validator: validatePass, required: true }],
      },
      isLoading: false,
      validationErrors: [],
      showSuspendedDialog: false,
      showSuspendedAccountByAdmindialog: false,
      showClosedAccountDialog: false,
      showClosedAccountByAdminDialog: false,
      showMultiFactorConfirmationDialog:false,
      response: {},
      selectedMultiFactorMethod: null,
      showDifferentMethodDialog:false
    };
  },
  methods: {
    submitForm(formName) {
      this.isLoading = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          gsApi.auth
            .login(this.loginForm.email, this.loginForm.pass)
            .then((response) => {
              if (response.data.activated === true) {
                store.commit("USER_LOGIN", response.data);
                this.validationErrors = [];
                this.response = response;
                // check if the user has already some configured MFA
                if(response.data?.availableMfaMethods?.data.length && response.data.mfa_setup_completed){
                  // show the MFA method depend on the default one
                  this.selectedMultiFactorMethod = response.data.availableMfaMethods.data.find(m => m.is_default)
                  // if the selected method is mobile : we have to send an sms to the user
                  if(this.selectedMultiFactorMethod.two_factor_type >= enumHelper.MFAType.PHONE){
                    gsApi.mfa.generateMultiFactorCode(
                      {
                        two_factor_type: this.selectedMultiFactorMethod.two_factor_type
                      }
                    ).catch(()=>{});
                  }

                  this.isLoading = false
                  this.showMultiFactorConfirmationDialog = true;
                  return;

                }else if( 
                    ((response.data.roles.includes('staff') || response.data.roles.includes('admin') ) && (!response.data.has_mfa_enabled || !response.data.mfa_setup_completed ))
                    ||
                    ( (response.data.roles.includes('expert') || response.data.roles.includes('trader') ) &&  !response.data.mfa_setup_completed )
                  )
                {
                    this.$router.push({name: 'MFA Setup'});
                    return;
                }
                  
                this.continueWithLogin();
              }
              else {
                this.isLoading = false;
                store.commit("USER_LOGIN_UNVERIFIED", response.data);
                this.$router.push({ name: "Verify Email Address" });
              }
            })
            .catch((error) => {
              console.log(error)
              this.validationErrors = [];
              this.isLoading = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else if (
                error.response &&
                error.response.status == 403 &&
                error.response.data
              ) {
                if (
                  error.response.data.error.message ==
                  "Your account has been closed."
                ) {
                  store.commit(
                    "SET_CLOSED_ACCOUNT_EMAIL_ADDRESS",
                    this.loginForm.email
                  );
                  this.showClosedAccountDialog = true;
                } else if (
                  error.response.data.error.message ==
                  "Your account has been closed by admin."
                ) {
                  this.showClosedAccountByAdminDialog = true;
                } else if (
                  error.response.data.error.message ==
                  "Your account has been suspended by admin."
                ) {
                  this.showSuspendedAccountByAdmindialog = true;
                } else {
                  store.commit(
                    "SET_SUSPNEDED_ACCOUNT_EMAIL_ADDRESS",
                    this.loginForm.email
                  );
                  this.showSuspendedDialog = true;
                }
              } else {
                const h = this.$createElement;
                if (
                  error.response &&
                  error.response.status == 401 &&
                  error.response.data
                ) {
                  if (
                    error.response.data.error.message ==
                    "Invalid login or password."
                  ) {
                    this.$alert(
                      h("p", null, [
                        h(
                          "a",
                          {
                            attrs: {
                              href: "forgot-password",
                            },
                          },
                          "Forgotten password?"
                        ),
                      ]),
                      `Sorry, it looks like you've entered the wrong email address or password. Please try again.`,
                      {
                        confirmButtonText: "Close",
                        showClose: false,
                        customClass: "notification-modal",
                      }
                    );
                  } else {
                    this.$alert(
                      "",
                      `Oops, you've entered incorrect login details too many times in a row. Wait 15 minutes before trying again, or reset your password now`,
                      {
                        confirmButtonText: "Close",
                        showClose: false,
                        customClass: "notification-modal",
                      }
                    );
                  }
                } else {
                  this.$alert(
                    "",
                    `Unfortunately we're having problems contacting our service. Please try again later.`,
                    {
                      confirmButtonText: "Close",
                      showClose: false,
                      customClass: "notification-modal",
                    }
                  );
                }
              }
            });
        } else {
          this.isLoading = false;
          this.validationErrors = [];
          this.$refs.loginForm.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("loginForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    continueWithLogin() {

        if (store.state.redirectUrl || this.$route.query.redirect) {
          var redirectUrl = store.state.redirectUrl
            ? store.state.redirectUrl
            : this.$route.query.redirect;
          store.state.redirectUrl = null;
          this.$router.push(redirectUrl);
        } else {
          if (!this.response.data.intro_viewed && this.response.data.imported_user) {
            this.$router.push("/edit-profile/personal-details");
          } else {
            this.$router.push("/feed");
          }
        }
      
      this.isLoading = false;
    },
    handleSelectDifferentMethod(){
      this.showMultiFactorConfirmationDialog = false;
      this.showDifferentMethodDialog = true
    },
    handleSelectMultiFactorMethod(method){
      if(method.two_factor_type >= enumHelper.MFAType.PHONE){
        gsApi.mfa.generateMultiFactorCode({two_factor_type: method.two_factor_type})
      }
      
      this.selectedMultiFactorMethod = method;
      this.showDifferentMethodDialog = false;
      this.showMultiFactorConfirmationDialog = true;
    },
    handleOnClose(){
      this.showMultiFactorConfirmationDialog = true;
      this.showDifferentMethodDialog = false;
    },
    handleLoginFormFieldInput() {
      this.handleSingleFormFieldInput("loginForm");
    },
  },
};
</script>
