<template>
  <div>
    <div class="back-to-search-section">
      <div class="container">
        <router-link :to="{ name: 'Projects' }" class="back-to-search-link">
          <span
            ><svg-white-arrow-left class="mr-1"></svg-white-arrow-left> Back to
            projects</span
          >
        </router-link>
      </div>
    </div>
    <div class="image-container">
      <div class="container">
        <el-image
          :src="image"
          :alt="altText"
          fit="cover"
          class="hidden-sm-and-down"
          :style="{ 'object-position': `${focusPoint.x}% ${focusPoint.y}%` }"
        ></el-image>
      </div>
      <el-image
        :src="image"
        :alt="altText"
        fit="cover"
        class="hidden-md-and-up"
        :style="{ 'object-position': `${focusPoint.x}% ${focusPoint.y}%` }"
      ></el-image>
    </div>
  </div>
</template>

<script>
import WhiteArrowLeft from "@/components/assets/svg-white-arrow-left";

export default {
  name: "ProjectHeader",
  components: {
    "svg-white-arrow-left": WhiteArrowLeft,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    altText: {
      type: String,
      default: null,
    },
    focusPoint: {
      type: Object,
      default: () => {
        return { x: 50, y: 50 };
      },
    },
  },
  created() {
    let body = document.body;
    body.classList.add("white-bg");
  },
};
</script>
