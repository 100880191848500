import store from "../store";
import Vue from "vue";
import gsApi from "@/services/gs.api";
const noAuthRoutes = [
  "Login",
  "Signup Select Type",
  "Signup NGS",
  "Signup GS",
  "Signup GS Complete",
  "Signup NCHN",
  "Signup NCHN Complete",
  "Forgot Password",
  "Verify Email Address",
];

const authRoutes = [
  "Feed",
  "Members",
  "Pulse",
  "Events and Webinars",
  "Events",
  "News",
  "News and Features",
  "Projects",
  "Project",
  "GlobalScot Profile",
  "NonGlobalScot Profile",
  "Staff Profile",
  "Personal Details",
  "Edit Profile",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Settings",
  "Account Details",
  "Update Password",
  "Resources",
  "Resource",
  "My Chats",
  "Chat",
  "MFA Setup",
  "Notifications"
];

export default (to, from, next) => {
  let currentTimeZone = Vue.moment.tz.guess();
  store.dispatch("appAuthLoading").then(() => {
    // && not a public access route
    const user = store.state.user;
    if (
      user.id &&
      !user.activated &&
      to.name != "Verify Email Address"
    ) {
      next({ name: "Verify Email Address" });
      return
    } else if (
      !user.isLogged &&
      !user.id &&
      ["GlobalScot Profile", "NonGlobalScot Profile", "Staff Profile"].includes(
        to.name
      )
    ) {
      next({ name: "Login" });
      return
    } else if (
      (!user.id || user.activated) &&
      to.name == "Verify Email Address"
    ) {
      next({ name: user.isLogged ? "Feed" : "Login" });
      return
    } else if (user.isLogged && noAuthRoutes.includes(to.name)) {
      next({ name: "Feed" });
      return
    } else if (user.isLogged && to.name == "Reset Password") {
      next();
      return
    } else if (!user.isLogged && authRoutes.includes(to.name)) {

      store.commit("SAVE_REDIRECT_URL", to.fullPath);
      next({ name: "Login" });
      return
      
    } else if (
      user &&
      user.isLogged &&
      ["Events and Webinars", "Events"].includes(to.name) &&
      currentTimeZone != "" &&
      user.timezone != currentTimeZone
    ) {
      let currentUser = JSON.parse(JSON.stringify(user));
      gsApi.users
        .updateTimeZone({ timezone: currentTimeZone })
        .then(() => {
          currentUser.timezone = currentTimeZone;
          store.commit("USER_LOGIN", currentUser);
          next();
          return
        })
        .catch(() => {
          next();
          return
        });
    }
    else if( to.name != 'Login' && user.isLogged && (user.has_mfa_enabled && !user.login_mfa_confirmed && user.mfa_setup_completed) ){
      gsApi.auth.logout(user.token).then(() => {
        store.commit("USER_LOGOUT");
        next({ name: 'Login' });
        return
      });
    }
    else if (to.name != 'MFA Setup' && user.isLogged &&
    (((store.getters.isAdmin || store.getters.isStaff) &&  (!user.has_mfa_enabled || !user.mfa_setup_completed))
    ||
    ((store.getters.isExpert || store.getters.isTrader) &&  !user.mfa_setup_completed)))
    {

      next({ name: 'MFA Setup' });
      return
    }
    else {
      next();
      return
    }
  });
};
