<template>
  <div class="news-page body__content" v-loading.fullscreen.lock="mainLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
  	<div class="search-section">
  		<div class="container">
  			<el-row>
	  			<el-col :sm="24">
		  			<h1>News & Features</h1>
            <div class="search-btn-container">
              <ul class="search-buttons">
                <li v-for="(sort, index) in sorts" :key="index" >
               <el-button
                v-bind:class="{ 'active-state': search.type == sort.name }"
                class="search-btn"
                @click="handleSortChange(sort)">
                <p>{{sort.name}}</p>
                </el-button>
              </li>
            </ul>
          </div>
					</el-col>
				</el-row>

			  <el-form id="searchForm" :model="search" ref="searchForm" @submit.native.prevent>
	        <el-row>
	          <el-col :sm="24" :md="12">
	            <el-form-item label="Keyword search" prop="keyword" class="hidden-sm-and-down">
		            <el-input v-model="search.keyword"
		                      placeholder="Search for keywords, location, people..."
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          	<el-form-item label="" prop="keyword" class="hidden-md-and-up">
		            <el-input v-model="search.keyword"
							  @keyup.enter.native="searchArticles"
		                      placeholder="Search for keywords, location, people..."
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12">
              <div class="search-news">
	            <el-button class="search-news-button" type="primary" @click="searchArticles">
	              Search
	            </el-button>
              </div>
	          </el-col>
	        </el-row>
	        <el-row class="hidden-sm-and-down">
	          <!--el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="filter" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Filter</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.filter"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="true"
                  :clearable="false"
                  :reduce="filterOption => filterOption.key"
                  inputId="filter"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col-->
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sortby" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sort by</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.sortby"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sortOption => sortOption.name"
                  inputId="sortby"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a group</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
	          	<el-button type="primary" class="float-right update-results-btn" @click="searchArticles">
	              Update results
	            </el-button>
	           	<el-button type="text" class="reset-btn float-right" @click="resetSearch" >Reset</el-button>
	          </el-col>
	        </el-row>
			<el-row class="hidden-md-and-up">
	        	<el-col :sm="24">
              <el-form-item prop="view" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="search.filter"
                  :value="search.filter"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="filterOption => filterOption.key"
				          label="name"
                  inputId="view"
                  autocomplete="false"
                  @input="searchArticles"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col>
	         	<el-col :sm="24" class="hidden-md-and-up">
	          	<el-button class="more-filters-btn" size="medium" @click="openArticlesFilter">View More filters</el-button>
	        	</el-col>
	        </el-row>
	      </el-form>
  		</div>
  	</div>

  	<div class="zero-data text-center mt-5" v-if="showZeroData" :id="showZeroData ? 'main-content' : ''">
  		<div class="container">
		  	<el-row>
		  		<el-col :sm="24">
	  				<p>We could not find any news and features with your search criteria. Please update your search and try again.</p>
	  			</el-col>
	  		</el-row>
  		</div>
  	</div>

  	<div class="results-section" v-if="articles.length > 0" :id="articles.length > 0 ? 'main-content' : ''">
	  	<div class="container">
	  		<div class="mt-4">
		  		<el-row>
		  			<news-card
						v-for="article in articles"
						:key="article.id"
						:article="article" />
		  		</el-row>
	  		</div>
	  	</div>
  	</div>

    <div class="pagination-help-section">
        <div class="container">
          <el-button class="load-more-btn" size="medium" @click="fetchArticles" v-if="!onLastPage">Load more</el-button>
        </div>
    </div>

    <news-filter v-if="articlesFilterOpen" @close="closeArticlesFilter" @search="searchArticles(true)" @reset="resetSearch(true)" v-model="search" :sectors="sectors" :sortOptions="sortOptions"></news-filter>
  </div>
</template>

<script>
	import store from '../../store'
	import router from '../../router'
	import { Loading } from 'element-ui'
	import gsApi from '@/services/gs.api'
	import NewsCard from '@/components/platform/news-features/news-card'
	import NewsFilter from '@/components/slide-panel/panels/news-filter'

  export default {
    name: 'news-and-features',
    metaInfo: {
      title: "News & features"
    },
  	components: {
		  'news-card': NewsCard,
		  'news-filter': NewsFilter
    },
    data() {
      return {
      	mainLoader: false,
      	page: 1,
      	perPage: 6,
      	totalResults: 0,
      	showZeroData: false,
      	onLastPage: false,
      	search: {
          type: 'All',
          sortby: null,
      		keyword: '',
      		filter: null,
      		sector: null,
      	},
        filterOptions: [
          {
          	key: 0,
          	name: 'News'
          },
          {
          	key: 1,
          	name: 'Feature'
          },
          {
          	key: 2,
          	name: 'Spotlight'
          },
          {
          	key: 3,
          	name: 'Case Study'
          },
          {
          	key: 4,
          	name: 'Project Outcome'
          },
          {
          	key: null,
          	name: 'All'
          }
        ],
        sortOptions: [
          {
          	key: 0,
          	name: 'A-Z'
          },
          {
          	key: 1,
          	name: 'Z-A'
          },
          {
          	key: 2,
          	name: 'Latest'
          }
        ],
        articles: [],
        sectors: [],
        articlesFilterOpen: false,
        sorts:[
          {label: "All", name: "All"},
          {label: "News", name: "News"},
          {label: "Spotlight", name: "Spotlight"},
          {label: "Case Study", name:'Case Study'},
          {label: "Feature", name:'Feature'},
          {label: "Project Outcome", name:'Project Outcome'}
        ]
      }
    },
    methods: {
      getRegisterMeta() {
        this.mainLoader = true

        gsApi.globalscot.meta.index()
          .then(response => {
            this.sectors = response.data.groups.data
            this.fetchArticles()
        }).catch(error => {
		      this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
      },
      resetSearch(isMobile) {
      	if (isMobile) {
      		this.closeArticlesFilter()
      	}

      	this.search = {
          type: 'All',
      		sortby: null,
      		keyword: '',
      		filter: null,
      		sector: null,
      	}

      	this.searchArticles()
      },

      searchArticles(isMobile) {
      	if (isMobile) {
      		this.closeArticlesFilter()
      	}

		    this.page = 1
      	this.articles = []
      	this.fetchArticles()
      },

      fetchArticles() {
      	if (!this.mainLoader) {
      		this.mainLoader = true
      	}

        //-- get selected search type
        let current_type = this.filterOptions.find(f=> f.name == this.search.type);
        this.search.filter = current_type ? current_type.key : null;

      	let params = {
          page: this.page,
          per_page: this.perPage,
      		group: this.search.sector,
          search: this.search.keyword,
          type_id: this.search.filter,
          sort_by: this.search.sortby,
          status_id: 1
		    }

		    gsApi.articles.search(params)
          .then(response => {
            this.mainLoader = false

            this.totalResults = response.data.meta.pagination.total

            if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
              this.onLastPage = false
              this.page++
            } else {
              this.onLastPage = true
            }

            this.articles = this.articles.concat(response.data.data)

            this.showZeroData = this.articles.length > 0 ? false : true
          }).catch(error => {
            this.mainLoader = false
          })
      },
      openArticlesFilter() {
        this.articlesFilterOpen = true;
      },
      closeArticlesFilter() {
        this.articlesFilterOpen = false;
      },
      handleSortChange(sort){

        this.search.type = sort.name
       	this.searchArticles()
      }
    },
    created() {
      /*if (!store.state.user.isLogged) {
          router.push('/login')
      }*/
      if(store.state.user.isLogged){
        this.getRegisterMeta()
      }
    },
  }
</script>
