import Vue from "vue";
import Router from "vue-router";
import ACLMiddleware from "@/middleware/acl";

//import components
import Index from "../pages/index";
import Network from "../pages/landing/about-network";
import Become from "../pages/landing/becoming-a-globals";
import Feed from "../pages/platform/feed";
import Members from "../pages/platform/members";
import Projects from "../pages/landing/projects";
import Project from "../pages/platform/projects/project";
import NewsAndFeatures from "../pages/platform/news-and-features";
import EventsAndWebinars from "../pages/platform/events-and-webinars";
import Events from "../pages/platform/events-webinars/events";
import News from "../pages/platform/news-features/news";
import VerifyEmail from "../pages/landing/verify-email";
import EditProfile from "../pages/platform/edit-profile/edit-profile";
import PersonalDetails from "../pages/platform/edit-profile/personal-details";
import Settings from "../pages/platform/settings/settings";
import AccountDetails from "../pages/platform/settings/account-details";
import Notifications from "../pages/platform/settings/notifications";
import Companies from "../pages/platform/edit-profile/companies";
import PlansAmbitions from "../pages/platform/edit-profile/plans-ambitions";
import ExperienceExpertise from "../pages/platform/edit-profile/experience-expertise";
//import Followers from "../pages/platform/edit-profile/followers";
//import Following from "../pages/platform/edit-profile/following";
import GsProfile from "../pages/platform/gs-profile";
import NgsProfile from "../pages/platform/ngs-profile";
import StaffProfile from "../pages/platform/staff-profile";
import Chats from "../pages/platform/chats/chats";
import Resources from "../pages/platform/resources";
import ResourceView from "../pages/platform/resources/view";
// Updated UIs
import Login from "../pages/landing/login";
import ForgotPassword from "../pages/landing/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import SignupSelect from "../pages/landing/signup-select";
import SignupNgs from "../pages/landing/signup-ngs";
import SignupNchn from "../pages/landing/signup-nchn";
import SignupGs from "../pages/landing/signup-gs";
import SignupStaff from "../pages/landing/signup-staff";
import SignupGsComplete from "../pages/landing/signup-gs-complete";
import SignupNgsComplete from "../pages/landing/signup-ngs-complete";
import SignupStaffComplete from "../pages/landing/signup-staff-complete";
import MFASetup from "../pages/landing/mfa-setup";
import SignupVerify from "../pages/landing/signup-verify";
import PageNotFound from "../pages/error/page-not-found";
import ActivateNewAddressEmail from "../pages/landing/activate-new-address-email";

// Pulse
import Pulse from "../pages/landing/pulse";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: Index,
      redirect: "login",
      children: [
        {
          path: "login",
          component: Login,
          name: "Login",
          meta: { gtm: "Sign in to your GlobalScot account" },
        },
        {
          path: "sign-up-select",
          component: SignupSelect,
          name: "Signup Select Type",
          meta: { gtm: "Join the GlobalScot network" },
        },
        {
          path: "sign-up-ngs",
          component: SignupNgs,
          name: "Signup NGS",
          meta: { gtm: "Join as a business member" },
        },
        {
          path: "sign-up-ngs/complete",
          component: SignupNgsComplete,
          name: "Signup NGS Complete",
        },
        {
          path: "sign-up-gs",
          component: SignupGs,
          name: "Signup GS",
          meta: { gtm: "Apply to become a GlobalScot" },
        },
        {
          path: "sign-up-gs/complete",
          component: SignupGsComplete,
          name: "Signup GS Complete",
        },
        {
          path: "staff-sign-up",
          component: SignupStaff,
          name: "Signup Staff",
        },
        {
          path: "staff-sign-up/complete",
          component: SignupStaffComplete,
          name: "Signup Staff Complete",
        },
        {
          path: "sign-up-nchn",
          component: SignupNchn,
          name: "Signup NCHN",
          meta: { gtm: "Join as a business member" },
        },
        {
          path: "sign-up-nchn/complete",
          component: SignupNgsComplete,
          name: "Signup NCHN Complete",
        },
        {
          path: "sign-up-verify",
          component: SignupVerify,
          name: "Verify Email Address",
        },
        {
          path: "mfa-setup",
          component: MFASetup,
          name: "MFA Setup",
          props: true,
        },
        {
          path: "feed",
          component: Feed,
          name: "Feed",
          props: true,
        },
        {
          path: "community-feed",
          component: Pulse,
          name: "Pulse",
        },
        {
          path: "members",
          component: Members,
          name: "Members",
          props: true,
        },
        {
          path: "projects",
          component: Projects,
          name: "Projects",
          meta: { title: "Projects | GlobalScot" },
          props: true,
        },
        {
          path: "projects/:id",
          component: Project,
          name: "Project",
          meta: { title: "Project | GlobalScot" },
          props: true,
        },
        {
          path: "news",
          component: NewsAndFeatures,
          name: "News and Features",
          props: true,
        },
        {
          path: "news/:slug",
          component: News,
          name: "News",
          props: true,
        },
        {
          path: "events",
          component: EventsAndWebinars,
          name: "Events and Webinars",
          props: true,
        },
        {
          path: "events/:slug",
          component: Events,
          name: "Events",
          props: true,
        },
        {
          path: "resources",
          component: Resources,
          name: "Resources",
        },
        {
          path: "resources/:id",
          component: ResourceView,
          name: "Resource",
          props: true,
        },
        {
          path: "/edit-profile",
          component: EditProfile,
          name: "Edit Profile",
          redirect: "/edit-profile/personal-details",
          children: [
            {
              path: "/edit-profile/personal-details",
              component: PersonalDetails,
              name: "Personal Details",
            },
            {
              path: "/edit-profile/companies",
              component: Companies,
              name: "Companies",
            },
            {
              path: "/edit-profile/plans-ambitions",
              component: PlansAmbitions,
              name: "Plans and Ambitions",
            },
            {
              path: "/edit-profile/experience-expertise",
              component: ExperienceExpertise,
              name: "Experience and Expertise",
            },
           /*  {
              path: "/edit-profile/following",
              component: Following,
              name: "Following",
            },
            {
              path: "/edit-profile/followers",
              component: Followers,
              name: "Followers",
            }, */
          ],
        },
        {
          path: "/settings",
          component: Settings,
          name: "Settings",
          redirect: "/settings/account-details",
          children: [
            {
              path: "/settings/account-details",
              component: AccountDetails,
              name: "Account Details",
            },
            {
              path: "/settings/notifications",
              component: Notifications,
              name: "Notifications",
            },
            /*{
              path: "/settings/account-password",
              component: AccountPassword,
              name: "Update Password"
            }*/
          ],
        },
        {
          path: "/verify-email",
          component: VerifyEmail,
          name: "Verify Email",
          props: true,
        },
        {
          path: "/forgot-password",
          component: ForgotPassword,
          name: "Forgot Password",
        },
        {
          path: "/auth/reset/:id/:code",
          component: ResetPassword,
          name: "Reset Password",
        },
        {
          path: "/gs/profile/:id",
          name: "GlobalScot Profile",
          component: GsProfile,
        },
        {
          path: "/ngs/profile/:id",
          name: "NonGlobalScot Profile",
          component: NgsProfile,
        },
        {
          path: "/staff/profile/:id",
          name: "Staff Profile",
          component: StaffProfile,
        },
        {
          path: "/chats",
          name: "My Chats",
          component: Chats,
        },
        {
          path: "/about-network",
          name: "About Network",
          component: Network,
          meta: { gtm: "About the GlobalScot network" },
        },
        {
          path: "/become-a-global-scot",
          name: "Become GlobalScot",
          component: Become,
          meta: { gtm: "Becoming a GlobalScot" },
        },
        {
          path: "/activate-email",
          name: "Activate User Email",
          component: ActivateNewAddressEmail,
          meta: { gtm: "Email Activation" },
        },
      ],
    },
    {
      path: "/*",
      name: "NotFound",
      component: PageNotFound,
    },

    // Redirects
    {
      path: "/~/media/documents/globalscot-company-brochure-2015.ashx",
      redirect: "home",
    },
    {
      path: "/BecomingaGlobalScot/getting-involved.aspx",
      redirect: "home",
    },
    {
      path: "/becomingaglobalscot/getting-involved.aspx",
      redirect: "home",
    },
    {
      path: "/accessing-globalscot",
      redirect: "home",
    },
    {
      path: "/accessing-globalscot/:slug",
      redirect: "home",
    },
    {
      path: "/ArchiveandResources/PhilipCarmichael.aspx",
      redirect: "home",
    },
    {
      path: "/asia-conference",
      redirect: "home",
    },
    {
      path: "/company-register",
      redirect: "home",
    },
    {
      path: "/Confirm",
      redirect: "home",
    },
    {
      path: "/DesktopModules/Admin/RadEditorProvider/DialogHandler.aspx",
      redirect: "home",
    },
    {
      path: "/downloads/GlobalScot%20Company%20Brochure%202018.pdf",
      redirect: "home",
    },
    {
      path: "/GlobalScot",
      redirect: "home",
    },
    {
      path: "/globalscot/allanrooney",
      redirect: "home",
    },
    {
      path: "/globalscot/hughsutherland",
      redirect: "home",
    },
    {
      path: "/globalscot/markgoldschmidt",
      redirect: "home",
    },
    {
      path: "/GS_ScottishOrganisationForm",
      redirect: "home",
    },
    {
      path: "/GS_SearchGlobalScots.aspx",
      redirect: "home",
    },
    {
      path: "/h/6454189.html",
      redirect: "home",
    },
    {
      path: "/Home/FullForm/:slug",
      redirect: "home",
    },
    {
      path: "/Home/home.aspx",
      redirect: "home",
    },
    {
      path: "/home/index",
      redirect: "home",
    },
    {
      path: "/Home/NotApprovedToUseGlobalScotService?msg=none",
      redirect: "home",
    },
    {
      path: "/Home/QuestionsForm/:slug",
      redirect: "home",
    },
    {
      path: "/Home/ThankYou",
      redirect: "home",
    },
    {
      path: "/index.html",
      redirect: "home",
    },
    {
      path: "/market-plans",
      redirect: "home",
    },
    {
      path: "/members/",
      redirect: "home",
    },
    {
      path: "/more-about-becoming-a-globalscot",
      redirect: "home",
    },
    {
      path: "/public/index.aspx",
      redirect: "home",
    },
    {
      path: "/staff-register",
      redirect: "home",
    },
    {
      path: "/swagger",
      redirect: "home",
    },
    {
      path: "/search",
      redirect: "home",
    },
    {
      path: "/sitesearch",
      redirect: "home",
    },
    {
      path: "/about-globalscot",
      redirect: "about",
    },
    {
      path: "/AboutGlobalScot/BuildingAGlobalNetwork.aspx",
      redirect: "about",
    },
    {
      path: "/aboutglobalscot/buildingaglobalnetwork.aspx",
      redirect: "about",
    },
    {
      path: "/AboutGlobalScot/TheMembership.aspx",
      redirect: "about-network",
    },
    {
      path: "/home/accessibility",
      redirect: "accessibility",
    },
    {
      path: "/home/casestudies",
      redirect: "case-studies",
    },
    {
      path: "/home/casestudy/:slug",
      redirect: "case-studies",
    },
    {
      path: "/home/codeofconduct",
      redirect: "code-of-conduct",
    },
    {
      path: "/contact-us",
      redirect: "contact-us",
    },
    {
      path: "/Contact-Us.aspx",
      redirect: "contact-us",
    },
    {
      path: "/home/Contact",
      redirect: "contact-us",
    },
    {
      path: "/home/cookiepolicy",
      redirect: "cookie-policy",
    },
    {
      path: "/home/register",
      redirect: "sign-up-select",
    },
    {
      path: "/register",
      redirect: "sign-up-select",
    },
    {
      path: "/register/:slug",
      redirect: "sign-up-select",
    },
    {
      path: "/registration/:slug",
      redirect: "sign-up-select",
    },
    {
      path: "/Account/:slug",
      redirect: "login",
    },
    {
      path: "/dashboard/:slug",
      redirect: "login",
    },
    {
      path: "/forgotten-password",
      redirect: "login",
    },
    {
      path: "/General/loggedoff.aspx",
      redirect: "login",
    },
    {
      path: "/GlobalScotList",
      redirect: "login",
    },
    {
      path: "/GlobalScotList/:slug",
      redirect: "login",
    },
    {
      path: "/GS_Login.aspx",
      redirect: "login",
    },
    {
      path: "/my-dashboard",
      redirect: "login",
    },
    {
      path: "/profiles/:slug",
      redirect: "login",
    },
    {
      path: "/RequestStatus/:slug",
      redirect: "login",
    },
    {
      path: "/home/PrivacyPolicy",
      redirect: "privacy-policy",
    },
    {
      path: "/PrivacyPolicy",
      redirect: "privacy-policy",
    },
    {
      path: "/sitemap",
      redirect: "site-map",
    },
    {
      path: "/disclaimer",
      redirect: "terms-conditions",
    },
    {
      path: "/home/TermsAndConditions",
      redirect: "terms-conditions",
    },
    {
      path: "/legal",
      redirect: "terms-conditions",
    },
    {
      path: "*",
      redirect: "NotFound",
    },
  ],
});

router.beforeEach(ACLMiddleware);

export default router;
