<template>
    <div class="white-background-section">
      <div class="container">
        <h1 class="text-center who-are-we-looking-for-title">{{eligibleText.heading}}</h1>
        <el-row class="mt-3">
          <el-col :md="24" :lg="12">
            <p class="py-3 who-are-we-looking-for-sub-title">{{eligibleText.sub_1}}</p>
            <ul class="eligible-list">
              <li v-for="point in eligibleBulletPoints" :key="point.text">{{ point.text }}</li>
            </ul>
          </el-col>
          <el-col :md="24" :lg="12">
            <p class="py-3 who-are-we-looking-for-sub-title">{{eligibleText.sub_2}}</p>
            <ul class="eligible-list">
              <li v-for="point in eligibleNoteBulletPoints" :key="point.text">{{ point.text }}</li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>      
  <!--div>
    <div>
      <h1 class="eligible-title">{{eligibleText.heading}}</h1>
      <p class="eligible-sub">{{eligibleText.sub}}</p>
    </div>
    <div>
      <div>
        <ul class="eligible-list">
          <li v-for="point in eligibleBulletPoints" :key="point.text">{{ point.text }}</li>
        </ul>
        <div class="notes-wrap">
          <p class="eligible-sub">{{eligibleNote}}</p>
          <ul class="eligible-list">
            <li v-for="point in eligibleNoteBulletPoints" :key="point.text">{{ point.text }}</li>
          </ul>
        </div>
      </div>
    </div>
  </!--div-->
</template>
<script>
export default {
  components: {},
  data() {
    return {
      eligibleText: {
        heading: "Who are we looking for?",
        sub_1: "GlobalScots can come from all areas of the business world. You should be:",
        sub_2: "We’re open-minded about the sectors our GlobalScots work in, but we're particularly interested in:",
      },
      eligibleBulletPoints: [
        {
          text: 'Based in International Markets'
        },
        {
          text: "A senior or influential figure in your company, sector or market with significant experience and connections"
        },
        {
          text: "Open to sharing your knowledge, expertise and insights free of charge "
        },
        {
          text: 'Able to demonstrate strong entrepreneurial, scaling, funding and investment expertise'
        },
        {
          text: "Open to making introductions to your network where appropriate "
        },
        {
          text: "Able to inspire and motivate Scotland's ambitious business leaders"
        }
      ],
      
      eligibleNoteBulletPoints: [
        {
          text: "Fintech/ Pharma/ biotech"
        },
        {
          text: "Medtech, healthtech, healthcare"
        },
        {
          text: "Advanced manufacturing (AI, Big Data)"
        },
        {
          text: "Automotive"
        },
        {
          text: "Edtech and AI "
        },
        {
          text: "Energy transition"
        },
        {
          text: "Renewable technologies - hydrogen, heat, offshore wind"
        },
        {
          text: "Food & drink"
        },
        {
          text: "Lifestyle products"
        },
      ]
    };
  }
};
</script>
<style lang="scss">
.eligible-sub {
  font-size: 19px;
}
.notes-wrap {
  .eligible-sub,
  li {
    font-weight: 500 !important;
  }
}
</style>
