<template><svg width="177" height="30" viewBox="0 0 177 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M139.005 0.45752H123.175V4.29322H128.728V19.8081H133.451V4.29322H137.516L139.004 0.45752" fill="#004280"/>
<path d="M151.342 16.1735C149.873 16.3159 148.402 15.9304 147.191 15.0859L146.018 18.5207C147.745 19.4843 149.681 20.0149 151.658 20.0669C156.352 20.0669 158.814 17.6908 158.814 13.9695C158.814 10.5627 156.581 8.64534 152.889 7.61454C151.4 7.18472 150.57 6.67028 150.57 5.69621C150.57 4.75195 151.34 4.03558 152.974 4.03558C154.256 4.08496 155.51 4.42774 156.639 5.03753V5.00869H156.668L157.984 1.57396L157.955 1.54511C157.551 1.27224 157.119 1.04215 156.667 0.858553V0.829705C156.523 0.772011 156.352 0.715279 156.18 0.657585C156.118 0.625341 156.05 0.605731 155.98 0.599889C155.866 0.571042 155.751 0.542196 155.665 0.514311C154.692 0.259583 153.689 0.134458 152.684 0.142183C148.505 0.142183 146.129 2.71823 146.129 5.95295C146.129 8.87227 147.875 10.9627 151.653 11.9926C153.428 12.4791 154.143 13.1089 154.143 14.2253C154.086 15.4273 153.027 16.1715 151.339 16.1715" fill="#004280"/>
<path d="M144.587 0.45752H139.864V19.8081H144.587V0.45752Z" fill="#004280"/>
<path d="M44.5985 0.37207H3.12029L23.8469 13.1965L44.5985 0.37207ZM0 2.2904V27.9383L20.7536 15.1139L0 2.2904ZM26.9941 15.1139L47.7477 27.9383V2.2904L26.9941 15.1139ZM3.12029 29.8567H44.5985L23.8738 17.0322L3.12029 29.8567Z" fill="#004280"/>
<path d="M164.911 11.6792H172.096V19.7516H176.79V0.343262H172.096V7.81466H164.911V0.343262H160.188V19.7516H164.911V11.6792Z" fill="#004280"/>
<path d="M112.011 24.2458H107.374V25.3622H109.008V29.9133H110.382V25.3622H111.556L112.014 24.2458" fill="#004280"/>
<path d="M60.1711 27.0797C60.1794 27.3174 60.1368 27.5541 60.0461 27.7739C59.9553 27.9938 59.8186 28.1916 59.6452 28.3543C59.4717 28.517 59.2654 28.6406 59.0401 28.717C58.8149 28.7934 58.576 28.8206 58.3393 28.7971H57.8816V25.3354H58.3393C58.5782 25.3127 58.8192 25.3416 59.046 25.4201C59.2727 25.4986 59.48 25.6249 59.6537 25.7905C59.8274 25.9561 59.9635 26.1571 60.0527 26.3798C60.142 26.6026 60.1824 26.8419 60.1711 27.0816V27.0797ZM61.574 27.0816C61.5743 26.6847 61.4929 26.2919 61.335 25.9278C61.1771 25.5636 60.9461 25.2357 60.6562 24.9646C60.3663 24.6935 60.0237 24.4848 59.6498 24.3516C59.2759 24.2184 58.8786 24.1634 58.4826 24.1902H56.5354V29.9721H58.4816C58.8772 29.9964 59.2736 29.9398 59.6465 29.8056C60.0194 29.6715 60.361 29.4626 60.6504 29.1918C60.9398 28.9211 61.1709 28.5941 61.3296 28.2309C61.4882 27.8677 61.5711 27.476 61.5731 27.0797L61.574 27.0816Z" fill="#004280"/>
<path d="M80.3805 28.797H78.265V24.2458H76.8881V29.9133H79.9507L80.3805 28.797Z" fill="#004280"/>
<path d="M69.9032 29.9133L72.0504 24.2458H70.6763L69.2166 28.0815L67.7858 24.2458H66.4108L68.5262 29.9133H69.9032Z" fill="#004280"/>
<path d="M73.7389 25.3334H76.115L76.5448 24.2458H72.3649V29.9133H76.115V28.8258H73.7389V27.5661H75.914V26.4498H73.7389V25.3334Z" fill="#004280"/>
<path d="M63.3482 25.3334H65.7521L66.1531 24.2458H61.9741V29.9133H65.7243V28.8258H63.3482V27.5661H65.5521V26.4498H63.3482V25.3334Z" fill="#004280"/>
<path d="M98.6724 25.3622H101.048L101.478 24.2458H97.2983V29.9133H101.02V28.797H98.6724V27.5661H100.848V26.4498H98.6724V25.3622Z" fill="#004280"/>
<path d="M90.7135 26.221C90.7228 25.9779 90.6818 25.7356 90.5931 25.5091C90.5044 25.2826 90.37 25.0768 90.1981 24.9046C89.5462 24.4017 88.7271 24.1662 87.9076 24.2459H86.5345V29.9134H87.9086V28.2249C88.7324 28.3035 89.5543 28.0571 90.1991 27.5384C90.3708 27.366 90.5052 27.16 90.5937 26.9333C90.6822 26.7067 90.723 26.4642 90.7135 26.221ZM89.1673 26.7364C88.808 27.0102 88.3562 27.1334 87.9076 27.0797V25.3623C88.3562 25.3086 88.808 25.4318 89.1673 25.7056C89.2328 25.7731 89.2822 25.8545 89.312 25.9437C89.3418 26.0329 89.3512 26.1277 89.3394 26.221C89.3616 26.3143 89.3573 26.412 89.3271 26.503C89.2969 26.5941 89.2419 26.6749 89.1683 26.7364H89.1673Z" fill="#004280"/>
<path d="M91.1433 29.9133V24.2458H92.7463L93.8915 26.2498L95.0367 24.2458H96.6714V29.9133H95.3252V26.2498L93.8934 28.597L92.4905 26.2498V29.9133H91.1433Z" fill="#004280"/>
<path d="M153.261 24.2458H148.623V25.3622H150.258V29.9133H151.632V25.3622H152.834L153.264 24.2458" fill="#004280"/>
<path d="M128.586 24.2458H123.948V25.3622H125.583V29.9133H126.957V25.3622H128.159L128.589 24.2458" fill="#004280"/>
<path d="M176.791 28.797H174.644V24.2458H173.27V29.9133H176.333L176.79 28.797" fill="#004280"/>
<path d="M117.708 24.2458H116.333V29.9133H117.708V24.2458Z" fill="#004280"/>
<path d="M154.978 24.2458H153.604V29.9133H154.978V24.2458Z" fill="#004280"/>
<path d="M130.303 25.3622H132.679L133.109 24.2458H128.929V29.9133H132.679V28.797H130.303V27.5661H132.507V26.4498H130.303V25.3622Z" fill="#004280"/>
<path d="M138.089 29.9134L136.2 27.8807C136.54 27.7945 136.854 27.6276 137.116 27.3941C137.446 27.058 137.631 26.6057 137.631 26.1345C137.631 25.6633 137.446 25.2109 137.116 24.8748C136.455 24.3917 135.64 24.1676 134.825 24.245H133.451V29.9125H134.825V28.1663L136.4 29.9125L138.089 29.9134ZM134.825 25.3046C135.27 25.2544 135.717 25.366 136.085 25.6191C136.148 25.6822 136.197 25.7587 136.226 25.8429C136.256 25.927 136.267 26.0168 136.257 26.1056C136.263 26.1941 136.251 26.2829 136.221 26.3666C136.192 26.4502 136.145 26.527 136.085 26.5922C135.718 26.85 135.273 26.9714 134.825 26.9354V25.3046Z" fill="#004280"/>
<path d="M106.286 29.9133L104.082 27.5094C103.71 27.1084 103.313 26.6507 103.024 26.2786H102.996C103.024 26.6796 103.024 27.1084 103.024 27.4527V29.8855H101.735V24.2458H102.823L104.769 26.3353C105.026 26.6238 105.455 27.0796 105.713 27.3931H105.742C105.713 27.0498 105.713 26.7065 105.713 26.3623V24.2468H107.029V29.9143H106.285" fill="#004280"/>
<path d="M122.86 29.9133L120.656 27.5094C120.284 27.1084 119.887 26.6507 119.598 26.2786H119.569C119.598 26.6796 119.598 27.1084 119.598 27.4527V29.8855H118.309V24.2458H119.396L121.342 26.3353C121.631 26.6238 122.029 27.0796 122.287 27.3931H122.315C122.287 27.0498 122.287 26.7065 122.287 26.3623V24.2468H123.603V29.9143H122.859" fill="#004280"/>
<path d="M143.012 29.9133L140.808 27.5094C140.436 27.1084 140.039 26.6507 139.751 26.2786H139.722C139.751 26.6796 139.751 27.1084 139.751 27.4527V29.8855H138.432V24.2458H139.52L141.466 26.3353C141.755 26.6238 142.153 27.0796 142.41 27.3931H142.439C142.41 27.0498 142.41 26.7065 142.41 26.3623V24.2468H143.727V29.9143H143.011" fill="#004280"/>
<path d="M166.456 29.9133L164.252 27.5094C163.88 27.1084 163.483 26.6507 163.195 26.2786H163.166C163.195 26.6796 163.195 27.1084 163.195 27.4527V29.8855H161.905V24.2458H162.993L164.939 26.3353C165.227 26.6238 165.625 27.0796 165.883 27.3931H165.912C165.883 27.0498 165.883 26.7065 165.883 26.3623V24.2468H167.2V29.9143H166.455" fill="#004280"/>
<path d="M168.861 29.8854C168.947 29.6566 169.291 28.7402 169.319 28.6835H171.036C171.065 28.7411 171.408 29.6566 171.494 29.8854H172.954L170.75 24.2747H169.692L167.488 29.8854H168.862H168.861ZM170.036 26.5074C170.087 26.3877 170.126 26.263 170.151 26.1353C170.176 26.2443 170.214 26.3499 170.265 26.4497L170.294 26.5353C170.294 26.5353 170.582 27.2796 170.637 27.5084H169.693C169.72 27.3661 170.034 26.5074 170.034 26.5074H170.036Z" fill="#004280"/>
<path d="M145.475 29.8854C145.56 29.6566 145.905 28.7402 145.933 28.6835H147.65C147.679 28.7411 148.022 29.6566 148.108 29.8854H149.567L147.363 24.2747H146.306L144.102 29.8854H145.476H145.475ZM146.65 26.5074C146.701 26.3877 146.739 26.263 146.764 26.1353C146.79 26.2443 146.828 26.3499 146.879 26.4497L146.908 26.5353C146.908 26.5353 147.196 27.2796 147.251 27.5084H146.307C146.334 27.3661 146.648 26.5074 146.648 26.5074H146.65Z" fill="#004280"/>
<path d="M160.102 27.0508C160.086 27.3756 159.975 27.6886 159.782 27.9508C159.59 28.213 159.325 28.413 159.02 28.5261C158.715 28.6391 158.383 28.6602 158.066 28.5868C157.75 28.5133 157.461 28.3486 157.237 28.1129C157.013 27.8772 156.863 27.5809 156.805 27.2608C156.748 26.9406 156.786 26.6107 156.914 26.3118C157.042 26.0129 157.255 25.7581 157.527 25.5791C157.798 25.4001 158.116 25.3046 158.442 25.3046C158.666 25.3073 158.888 25.3549 159.094 25.4447C159.3 25.5344 159.486 25.6645 159.64 25.8273C159.795 25.9901 159.916 26.1822 159.995 26.3923C160.075 26.6025 160.111 26.8264 160.102 27.0508ZM161.505 27.022C161.522 26.4125 161.356 25.8119 161.03 25.297C160.703 24.7821 160.231 24.3764 159.672 24.1316C159.114 23.8868 158.495 23.8142 157.895 23.923C157.295 24.0317 156.741 24.317 156.304 24.7423C155.867 25.1675 155.567 25.7134 155.442 26.3102C155.317 26.9069 155.373 27.5274 155.603 28.0922C155.832 28.6571 156.225 29.1406 156.731 29.4809C157.237 29.8213 157.833 30.003 158.443 30.0028C158.841 30.0148 159.237 29.9466 159.609 29.8024C159.98 29.6581 160.319 29.4406 160.604 29.1629C160.89 28.8852 161.117 28.553 161.272 28.1858C161.427 27.8187 161.506 27.4242 161.505 27.0258V27.022Z" fill="#004280"/>
<path d="M84.8172 27.0508C84.8008 27.3756 84.6895 27.6886 84.4971 27.9508C84.3047 28.213 84.0395 28.413 83.7346 28.5261C83.4296 28.6391 83.0982 28.6602 82.7813 28.5868C82.4645 28.5133 82.1761 28.3486 81.952 28.1129C81.7278 27.8772 81.5777 27.5809 81.5203 27.2608C81.4629 26.9406 81.5006 26.6107 81.6288 26.3118C81.757 26.0129 81.9701 25.7581 82.2416 25.5791C82.5132 25.4001 82.8313 25.3046 83.1565 25.3046C83.3811 25.3073 83.6029 25.3549 83.8088 25.4447C84.0147 25.5344 84.2005 25.6645 84.3553 25.8273C84.5101 25.9901 84.6307 26.1822 84.71 26.3923C84.7893 26.6025 84.8258 26.8264 84.8172 27.0508ZM86.1913 27.022C86.2079 26.4125 86.0424 25.8119 85.7158 25.297C85.3893 24.7821 84.9166 24.3764 84.3581 24.1316C83.7997 23.8868 83.181 23.8142 82.5811 23.923C81.9812 24.0317 81.4273 24.317 80.9904 24.7423C80.5535 25.1675 80.2534 25.7134 80.1284 26.3102C80.0035 26.9069 80.0593 27.5274 80.2889 28.0922C80.5185 28.6571 80.9113 29.1406 81.4172 29.4809C81.923 29.8213 82.519 30.003 83.1287 30.0028C83.5269 30.0148 83.9235 29.9466 84.2948 29.8024C84.6662 29.6581 85.0047 29.4406 85.2904 29.1629C85.5761 28.8852 85.8031 28.553 85.9578 28.1858C86.1126 27.8187 86.192 27.4242 86.1913 27.0258V27.022Z" fill="#004280"/>
<path d="M62.0596 16.1734C60.0688 16.1219 58.1373 15.4848 56.5066 14.3417L56.9643 18.7504C58.6275 19.6421 60.4869 20.1048 62.3741 20.0966C67.0685 20.0966 69.5301 17.7206 69.5301 13.9993C69.5301 10.5925 67.2973 8.67513 63.6049 7.64432C62.1164 7.2145 61.2865 6.70006 61.2865 5.72599C61.2865 4.78173 62.0558 4.06536 63.6905 4.06536C64.9725 4.11475 66.2262 4.45752 67.355 5.06732V5.03847H67.3839L68.7002 1.60374L68.6714 1.5749C68.2668 1.30203 67.835 1.07195 67.3829 0.888338V0.85949C67.2396 0.801796 67.0685 0.745064 66.8963 0.68737C66.8341 0.655126 66.7662 0.635516 66.6963 0.629675C66.5819 0.600828 66.4675 0.571982 66.3819 0.544096C65.409 0.289368 64.4067 0.164243 63.401 0.171968C59.2221 0.171968 56.846 2.74801 56.846 5.98273C56.846 8.90206 58.5922 10.9925 62.3712 12.0223C64.1463 12.5089 64.8617 13.1387 64.8617 14.2551C64.7761 15.4292 63.7164 16.1734 62.0568 16.1734" fill="#004280"/>
<path d="M102.536 10.1925C102.582 10.9614 102.47 11.7317 102.208 12.4558C101.945 13.18 101.537 13.8428 101.009 14.4035C100.481 14.9642 99.8437 15.411 99.1366 15.7164C98.4294 16.0218 97.6672 16.1793 96.8969 16.1793C96.1266 16.1793 95.3644 16.0218 94.6573 15.7164C93.9501 15.411 93.3128 14.9642 92.7847 14.4035C92.2565 13.8428 91.8486 13.18 91.586 12.4558C91.3235 11.7317 91.2118 10.9614 91.2578 10.1925C91.2118 9.42359 91.3235 8.65338 91.586 7.92922C91.8486 7.20505 92.2565 6.54224 92.7847 5.98152C93.3128 5.42079 93.9501 4.97401 94.6573 4.66862C95.3644 4.36323 96.1266 4.2057 96.8969 4.2057C97.6672 4.2057 98.4294 4.36323 99.1366 4.66862C99.8437 4.97401 100.481 5.42079 101.009 5.98152C101.537 6.54224 101.945 7.20505 102.208 7.92922C102.47 8.65338 102.582 9.42359 102.536 10.1925ZM107.317 10.1348C107.315 8.78024 107.042 7.43969 106.515 6.19216C105.987 4.94464 105.215 3.81537 104.244 2.87088C103.273 1.9264 102.122 1.1858 100.861 0.69273C99.5991 0.199659 98.2515 -0.0359161 96.8974 -0.000116023C91.5126 -0.000116023 86.449 3.95001 86.449 10.1329C86.5228 12.8509 87.6544 15.4327 89.6029 17.3291C91.5514 19.2254 94.163 20.2865 96.882 20.2865C99.601 20.2865 102.213 19.2254 104.161 17.3291C106.11 15.4327 107.241 12.8509 107.315 10.1329L107.317 10.1348Z" fill="#004280"/>
<path d="M122.516 0.45752H106.658V4.32207H112.211V19.837H116.935V4.32207H120.999L122.516 0.45752Z" fill="#004280"/>
<path d="M85.1037 19.3794L86.0768 14.9706L85.4999 15.2851C85.3623 15.3634 85.2186 15.4303 85.0701 15.4851L84.5546 15.6851C83.5444 15.9979 82.4918 16.1523 81.4344 16.1428C77.7419 16.1428 75.0226 13.7956 75.0226 10.2455C75.0226 6.66747 77.7996 4.3799 81.2343 4.3799C82.6336 4.36851 84.0128 4.71331 85.2422 5.38185L85.2999 5.18184V0.969211C83.8248 0.428423 82.2618 0.167541 80.691 0.199954C75.7101 0.199954 70.2715 3.63468 70.2715 10.1907C70.2715 16.7168 75.6563 20.1237 80.691 20.1237C82.1973 20.1992 83.7019 19.9452 85.0998 19.3794" fill="#004280"/>
</svg></template><script>export default {}</script>
