<template>
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 0H0V19.3333H5.2V27L14.625 19.3333H26V0ZM24.05 17.3333H13.975L7.15 22.8567V17.337H1.95V2.00367H24.05V17.3333Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="22.4348"
        y1="7.19724"
        x2="-4.16529"
        y2="26.8104"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#AF006E" />
        <stop offset="0.517454" stop-color="#EA6576" />
        <stop offset="1" stop-color="#F7F248" />
      </linearGradient>
    </defs>
  </svg>
</template><script>
export default {};
</script>
