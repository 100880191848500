<template>
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.396 19.1531C50.396 23.8982 48.5119 28.4491 45.1578 31.8057C41.8037 35.1622 37.2541 37.0495 32.509 37.053C22.633 37.053 14.7209 28.7162 14.7209 18.8252C14.7231 14.2648 16.5112 9.88643 19.7023 6.62843C22.8934 3.37043 27.2335 1.49177 31.793 1.39503C32.051 1.38303 32.306 1.37818 32.567 1.37818C34.9073 1.36564 37.2269 1.81643 39.3921 2.7046C41.5574 3.59277 43.5254 4.90064 45.1828 6.55299C46.8402 8.20534 48.1541 10.1695 49.0488 12.332C49.9436 14.4946 50.4014 16.8128 50.396 19.1531V19.1531Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M31.793 1.39917C22.485 11.4812 22.485 24.633 31.793 37.043"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M33.3409 1.39917C42.6489 11.4812 42.6489 24.6301 33.3409 37.0371"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path d="M17.6699 28.824H47.5639" stroke="#AF006E" stroke-width="2" stroke-linejoin="round" />
    <path d="M17.3369 9.61499H47.6409" stroke="#AF006E" stroke-width="2" stroke-linejoin="round" />
    <path d="M15.001 18.998H50.001" stroke="#AF006E" stroke-width="2" stroke-linejoin="round" />
    <path
      d="M11.9769 53.5611C15.7656 53.5611 18.8369 50.4897 18.8369 46.7011C18.8369 42.9124 15.7656 39.8411 11.9769 39.8411C8.18827 39.8411 5.11694 42.9124 5.11694 46.7011C5.11694 50.4897 8.18827 53.5611 11.9769 53.5611Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.559 53.5611C36.3476 53.5611 39.419 50.4897 39.419 46.7011C39.419 42.9124 36.3476 39.8411 32.559 39.8411C28.7703 39.8411 25.699 42.9124 25.699 46.7011C25.699 50.4897 28.7703 53.5611 32.559 53.5611Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.14 53.5611C56.9287 53.5611 60 50.4897 60 46.7011C60 42.9124 56.9287 39.8411 53.14 39.8411C49.3514 39.8411 46.28 42.9124 46.28 46.7011C46.28 50.4897 49.3514 53.5611 53.14 53.5611Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.14 53.5413C50.8836 53.5474 48.684 54.2501 46.8421 55.5535C45.0001 56.8569 43.6056 58.6974 42.849 60.8232C42.0941 58.6974 40.6992 56.8575 38.8561 55.5566C37.0131 54.2557 34.8123 53.5577 32.5564 53.5583C30.3005 53.559 28.1001 54.2583 26.2578 55.5603C24.4155 56.8623 23.0217 58.7028 22.268 60.8291C21.5115 58.7027 20.1172 56.8617 18.2754 55.5571C16.4335 54.2526 14.234 53.5483 11.977 53.5403C9.0982 53.6318 6.36201 54.8148 4.3235 56.8496C2.28499 58.8844 1.0968 61.6184 1 64.4971H64.116C64.019 61.6188 62.8308 58.8851 60.7926 56.8506C58.7543 54.816 56.0185 53.633 53.14 53.5413Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
