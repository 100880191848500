export default {
  methods: {
    getCMSHome() {
      return process.env.VUE_APP_CMS_BASE_URL;
    },
    getCMSRoute(route = '') {
      return `${process.env.VUE_APP_CMS_BASE_URL}/${route}`;
    },
  },
};
