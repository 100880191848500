<template>
  <!-- TODO: for flexibility this component should not determine columns within -->
  <el-col :sm="24" :md="12" :lg="8" :xl="6" class="">
    <el-card class="member-card box-card text-center h-100">
      <user-avatar shape="circle" :isGlobalScot="helpers.isExpert(member)" :authorExist="!!member"
      :size="74" :src="member.avatar" :alt="member ? member.fullname : '' + 'Profile picture'" :only-chevron="false"
      ></user-avatar>


      <div class="name-position">
        <h2 class="text-truncate">{{ member.full_name }}</h2>

         <div class="d-flex justify-content-center" v-if="!isStaff">
                      <p class="text-truncate">{{ primaryBusiness.role }}
                        <svg-vertical-line class="vertical-line" aria-labeledby="vertical line"></svg-vertical-line>
                     {{ primaryBusiness.name }}</p>
        </div>

        <p class="text-truncate" v-else>{{ organisationName }}</p>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="location-sectors">
        <div class="d-flex justify-content-center">
          <div><svg-map-pin-small></svg-map-pin-small></div>
          <p class="text-truncate">{{ location }}</p>
        </div>
        <div class="sectors d-flex justify-content-center sectors-text">
          <p class="text-truncate">{{ sectorsText }}</p>
          <span v-if="member.groups.data.length > 2">+{{ member.groups.data.length - 2 }} more</span>
        </div>
      </div>
      <div class="view-profile-link" v-if="showProfileLink">
        <router-link
          :to="{ name: profileLinkName, params: { id: member.id, showBackToSearch: true } }"
        >
          <span>
            View
            <span class="sr-only">
              {{ member.full_name }}'s
            </span>
            profile
          </span>
          <svg-purple-arrow-right class="arrow" />
        </router-link>
      </div>
    </el-card>
  </el-col>
</template>

<script>
  import { mapState } from "vuex"
  import MapPinSmall from '@/components/assets/svg-map-pin-small'
  import GsLogoSmall from '@/components/assets/svg-small-gs-logo-white'
  import PurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
  import VerticalLine from '@/components/assets/svg-vertical-line'
  import UserAvatar from '@/components/core/user-avatar'
  import helpers from '@/helpers/index'
  export default {
    name: 'member-card',
    components: {
      'svg-map-pin-small': MapPinSmall,
      'svg-small-gs-logo-white': GsLogoSmall,
      'svg-purple-arrow-right': PurpleArrowRight,
      'svg-vertical-line': VerticalLine,
      UserAvatar
    },
    props: {
      member: {
        type: Object,
        required: true
      },
      showProfileLink: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState(["user"]),
      primaryBusiness() {
        if(this.member.businesses.data){
          let primary = this.member.businesses.data.find(b => b.is_primary);
          return primary ? primary : this.member.businesses.data[0];
        }else{
           let primary = this.member.businesses.find(b => b.is_primary);
          return primary ? primary : this.member.businesses.data[0];
        }
      },
      sectorsText() {
        if (this.isStaff) {
          return "";
        }

        let sectors = this.member.groups.data;

          if (sectors.length >= 2 ) {
                    return sectors[0].name + ", " + sectors[1].name;
                  } else if (sectors.length == 1  ) {
                    return sectors[0].name;
                  } else {
                    return "";
                  }
      },
      isGlobalScot() {
        return this.member.expertProfile && this.member.roles.includes("expert");
      },
      isStaff() {
        return this.member.staffProfile && this.member.roles.includes("staff");
      },
      profileLinkName() {
        if (this.isGlobalScot) {
          return 'GlobalScot Profile'
        } else if (this.isStaff) {
          return 'Staff Profile'
        }
        return 'NonGlobalScot Profile'
      },
      location() {
        if (this.isGlobalScot || this.isStaff) {
          let profileKey = this.isGlobalScot ? 'expertProfile' : 'staffProfile';
          if (this.member[profileKey].state && this.member[profileKey].country) {
            return this.member[profileKey].state.name + ', ' + this.member[profileKey].country.name
          } else if (this.member[profileKey].country) {
            return this.member[profileKey].country.name
          } else if (this.member[profileKey].state) {
            return this.member[profileKey].state.name
          }

          return ''
        } else {
          let area = this.primaryBusiness.address.county
            ? this.primaryBusiness.address.county
            : this.primaryBusiness.address.town;
          return area + ", " + this.primaryBusiness.address.country.name;
        }
      },
      organisationName() {
        let organization = ''
        if (this.member.staffProfile.organization.name) {
          organization = this.member.staffProfile.organization.name == "Other" ? this.member.staffProfile.custom_organization : this.member.staffProfile.organization.name
        }
        return organization
      },
    },
    data(){
      return {
        helpers
      }
    }

  };
</script>
<style lang="scss" scoped>
.vertical-line {
  height: 25px;
  margin: -3px 5px;
  position: relative;
}
</style>
