import colors from "@/components/map-view/config/colors";

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT
} = colors;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER
};

const POINT_MARKER_ICON_CONFIG = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 0.7,
  strokeWeight: 4,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1
};

const mapConfig = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  // backgroundColor: COLORS.LANDSCAPE,
  mapTypeControl: false,
  zoomControlOptions: {
    style: "SMALL"
  },
  zoom: 5,
  minZoom: 2,
  maxZoom: 8,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180
    }
  },
  styles: [
    // {
    //   featureType: "landscape",
    //   stylers: [
    //     { hue: COLORS.LANDSCAPE },
    //   ]
    // },
    // {
    //   featureType: "poi",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "road.highway",
    //   stylers: [
    //     { hue: COLORS.LANDSCAPE },
    //     { saturation: -19.8 },
    //     { lightness: -1.8 },
    //     { gamma: 1 }
    //   ]
    // },
    // {
    //   featureType: "road.arterial",
    //   stylers: [
    //     { hue: COLORS.LANDSCAPE },
    //     { saturation: 72.4 },
    //     { lightness: -32.6 },
    //     { gamma: 1 }
    //   ]
    // },
    // {
    //   featureType: "road.local",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "transit",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.province",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.locality",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.province",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.land_parcel",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.neighborhood",
    //   stylers: [{ visibility: "off" }]
    // },
    // {
    //   featureType: "administrative.country",
    //   elementType: "geometry.stroke",
    //   stylers: [{ visibility: "on" }, { color: COLORS.BORDERS }]
    // },
    // {
    //   featureType: "administrative",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }]
    // },
    {
      featureType: "water",
      stylers: [
        { hue: COLORS.WATER },
        { lightness: 5 },
      ]
    }
  ]
};

export { mapConfig, POINT_MARKER_ICON_CONFIG };
