<template>
  <el-form v-loading="loader"
    class="company-form"
    id="companyForm"
    ref="companyForm"
    :model="form"
    :rules="rules"
    autocomplete="false"
    @validate="handleCompanyFormFieldInput"
  >
    <el-form-item prop="name" class="form-group mb-4">
      <template slot="label">
        Company name
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('name')"
              v-text="this.getInlineValidationErrorFor('name')"></span>
      </template>
      <el-input
        v-model="form.name"
        @blur="form.name = form.name.trim()"
        id="name"
        autocomplete="off"
        label="Please enter your company name"
        aria-required="true"
      />
    </el-form-item>
    <el-form-item
      prop="role"
      class="form-group mb-4"
    >
      <template slot="label">
        Your role at the company
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('role')"
              v-text="this.getInlineValidationErrorFor('role')"></span>
      </template>
      <el-input
        v-model="form.role"
        @blur="form.role = form.role.trim()"
        id="role"
        autocomplete="off"
        label="Please enter your role at the company"
        aria-required="true"
      />
    </el-form-item>
    <el-row>
      <el-col :sm="6">
        <el-button @click="onSubmit">Add company</el-button>
      </el-col>
      <el-col :sm="6">
        <el-button @click="onCloseForm">Cancel</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import gsApi from '@/services/gs.api'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  mixins: [inlineValidationErrorsMixin],
  data() {
    return {
      loader:false,
      form : {
        name:"",
        role:"",
        new_company:true
      },
      rules: {
        name: [
          {
            required: true,
            message: "Enter your company name",
            trigger: "blur"
          }
        ],
        role: [
          {
            required: true,
            message: "Enter your role within the company",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Role may not be greater than 255 characters",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs.companyForm.validate(valid => {
        if (valid) {
            this.loader = true;
            gsApi.globalscot.user
            .addCompany({businesses : [this.form], other_businesses:[]})
            .then(({data}) => {
              this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
              this.$alert('You successfully added your company', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              this.onCloseForm();
            })
            .catch((error) => {
              if (error.response.status == 422) {
                let firstError =
                  error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              } else {
                this.$alert('Sorry, your request could not be processed', `Error ${error.response.status} - ${error.response.data.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
            .finally(() => {
              this.loader = false
            })
        }
      });
    },
    onCloseForm() {
      this.form = {
        name:"",
        role:"",
        new_company:true
      }
      this.$emit("onCloseForm")
    },
    handleCompanyFormFieldInput() {
      this.handleSingleFormFieldInput('companyForm');
    },
  }
};
</script>
