<template>
  <div class="white-background-section mb-5">
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between">
        <h2>Meet the newest GlobalScots</h2>
        <h2 class="view-members-link view-page-link hidden-sm-and-down">
          <router-link :to="{ name: 'Members' }">
            Search the network
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </router-link>
        </h2>
      </div>
      <el-row>
        <member-card v-for="member in members" :key="member.id" :member="member" />
      </el-row>
      <h2 class="view-members-link view-page-link hidden-md-and-up mt-3">
        <router-link :to="{ name: 'Members' }">
          Search the network
          <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
        </router-link>
      </h2>
    </div>
  </div>
</template>
<script>
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import MemberCard from "@/components/platform/members/member-card";

export default {
  components: {
    "svg-purple-arrow-right": PurpleArrowRight,
    MemberCard
  },
  props: ["members"]
};
</script>
<style>
</style>
