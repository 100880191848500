<template>
 <div>
  <ckeditor
    v-model="model"
    :config="editorConfig"
    :editor="editor"
  />
 </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import MentionCustomization from "@/modules/ckeditor/custom-mention-output";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
  name: "PulseEditor",
  model: {
    prop: "content",
    event: "input",
  },
  props: {
    content: String,
    placeholder: String,
    projectId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
        extraPlugins: [MentionCustomization],
        placeholder: this.placeholder,
        basicEntities: false,
        entities_greek: false,
        entities_latin: false,
        entities_additional: "",
        toolbar: [],
        language: "en",
        mention: {
          dropdownLimit: 20,
          feeds: [
            {
              marker: "@",
              feed: this.searchUsers,
              minimumCharacters: 1,
              itemRenderer: this.mentionItemRenderer
            },
            {
              marker: "#",
              feed: this.addHashTag,
              minimumCharacters: 1,
            }
          ],

        },
        wordCount: {
          onUpdate: (stats) => {
            this.$emit("characters", stats.characters);
          },
        },
        removePlugins: ['MediaEmbedToolbar']
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.content;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    searchUsers(queryText) {
      return new Promise((resolve) => {
        gsApi.members
          .tagsSearch({
            search: queryText,
            member_type: "All",
            challenge_id: this.projectId
            //per_page: 4
          })
          .then((response) => {
            let users = [];
            for (let i = response.data.data.length - 1; i >= 0; i--) {
              let user = response.data.data[i];
              const avatarSrc = user.avatar ? user.avatar : "/assets/img/default-image.png"
              users.push({
                id: "@" + user.full_name,
                user_id: user.id,
                name: user.full_name,
                avatarSrc: avatarSrc
              });
            }
            resolve(users);
          });
      });
    },
    addHashTag(text) {
      let hashtag = {
        id: "#" + text,
        user_id: text,
        name: text,
      }
      return [hashtag];
    },
    mentionItemRenderer(item) {
      const itemElement = document.createElement('span');
      const avatar = document.createElement('img');
      const userNameElement = document.createElement('span');

      itemElement.classList.add('mention__item');

      avatar.src = item.avatarSrc;
      avatar.onerror = function () {
        this.src= "/assets/img/default-image.png";
      }

      userNameElement.classList.add('mention__item__user-name');
      userNameElement.textContent = item.name;

      itemElement.appendChild(avatar);
      itemElement.appendChild(userNameElement);

      return itemElement;
    }
  },
};
</script>

<style lang="scss">
.ck-mentions .mention__item {
  display: block;
  position: relative;
}

.ck-mentions .mention__item img {
  border-radius: 100%;
  height: 30px;
}

.ck-mentions .mention__item span {
  margin-left: .5em;
  font-family: brandon-grotesque;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.ck-mentions .mention__item.ck-on span {
  color: var(--ck-color-base-background);
}

.ck-mentions .mention__item .mention__item__full-name {
  color: hsl(0, 0%, 45%);
}

.ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
  color: hsl(0, 0%, 40%);
}
</style>
