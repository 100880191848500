// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import linkify from "vue-linkify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import ElementUI from "element-ui";
import VueEvents from "vue-events";
import VueNumeric from "vue-numeric";
import VueAnnouncer from "vue-announcer";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import vSelect from "vue-select";
import lineClamp from "vue-line-clamp";
import VueMeta from "vue-meta";
import VueGtm from "vue-gtm";
import InfiniteLoading from "vue-infinite-loading";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ICS from "vue-ics";
import app from "./app";
import router from "./router";
import store from "./store";
import "tributejs/dist/tribute.css";
import FocusLock from "@/components/common/FocusLock.vue";
import DialogOpenedMixin from "./mixins/DialogOpenedMixin";
import CMSRouteMixin from "./mixins/cms-route-mixin.js";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import DOMPurify from "dompurify";
Vue.component(VueCropper);

// import './styles/element-ui/index.css'

//import styles
import "./styles/index.scss";

Vue.config.productionTip = false;

ElementUI.Select.computed.readonly = function () {
  return !(this.filterable || this.multiple) && !this.visible;
};

import OpenIndicator from "@/components/assets/svg-v-select-open-indicator";
import Deselect from "@/components/assets/svg-v-select-open-deselect";
vSelect.props.components.default = () => ({ OpenIndicator, Deselect });

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: process.env.VUE_APP_GOOGLE_TAG_MANAGER_AUTH,
    gtm_preview: process.env.VUE_APP_GOOGLE_TAG_MANAGER_PREVIEW,
    gtm_cookies_win: "x",
  },
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.use(ElementUI, { locale });
Vue.use(VueAnnouncer, {}, router);
Vue.use(VueEvents);
Vue.use(VueNumeric);
Vue.use(lineClamp);
Vue.use(VueMoment, { moment });
Vue.use(VueMeta);
Vue.use(InfiniteLoading, {});
Vue.use(CKEditor);
Vue.use(ICS, {
  uidDomain: "",
  prodId: "global-scot",
});
Vue.mixin(DialogOpenedMixin);
Vue.mixin(CMSRouteMixin);
Vue.component("FocusLock", FocusLock);
Vue.component("VSelect", vSelect);
Vue.component("SingleSelect", () =>
  import("./components/partials/single-select.vue")
);
Vue.component("Checkbox", () => import("./components/partials/checkbox.vue"));
Vue.component("CheckboxGroup", () =>
  import("./components/partials/checkbox-group.vue")
);
Vue.directive("linkified", linkify);
// Vue.use( CKEditor )

Vue.filter("domPurify", function (value, params = {}) {
  if (!value) return "";

  DOMPurify.setConfig( { ...{ ADD_ATTR: ["target"], FORBID_TAGS: ['style'], FORBID_ATTR: ['style'] }, ...params });
  return  DOMPurify.sanitize(value);

});

router.beforeEach(function (to, from, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.params.notify) {
    Vue.prototype.$notify[to.params.notify.type]({
      title: to.params.notify.title,
      message: to.params.notify.message,
      duration: to.params.notify.duration,
    });
  }

  // We need to update the document title if possible before the history change event or GTM won't detect
  if (to && to.meta && to.meta.gtm) {
    document.title = to.meta.gtm;
  } else if (to && to.name) {
    document.title = to.name;
  }
  next();
});

new Vue({
  el: "#app",
  store,
  router,
  components: { app },
  template: "<app/>",
});
