<template>
  <div
    class="files-list-bar el-col-24 el-col-lg-10 h-100"
    v-loading.lock="filesLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="el-row m-0 h-100">
      <div class="el-col-24 h-100">
        <div class="el-row m-0 h-100">
          <div
            class="files-list-bar-header el-col-lg-7 el-col-24 d-flex align-items-center justify-content-between p-2"
          >
            <span>Files</span>
            <span @click="hideBar"><svg-close /></span>
          </div>
          <div class="files-list-bar-body m-0 el-col-lg-7 el-col-24">
            <div
              class="el-col-24 files-list-bar-body-item d-flex align-content-between"
              v-for="(file, index) in files"
              :key="index"
            >
              <div
                class="el-col-6 text-center p-0 d-flex align-items-center justify-content-center"
              >
                <img :src="helpers.getFileIcon(file.name)" />
              </div>
              <div class="file-info">
                <div>
                  <span class="file-name">{{ file.name }}</span>
                  <a
                    :href="file.url"
                    class="svg-download-file pl-3"
                    download
                    target="_blank"
                  >
                    <svg-download-file />
                  </a>
                </div>
                <div>{{ getFileData(file) }}</div>
              </div>
            </div>
            <infinite-loading @infinite="getFiles" :identifier="infiniteId">
              <div slot="spinner"></div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import SvgCloseCross from "@/components/assets/svg-close-cross";
import SvgDownloadFile from "@/components/assets/svg-download-file";
import helpers from "@/helpers/index";
export default {
  components: {
    "svg-close": SvgCloseCross,
    "svg-download-file": SvgDownloadFile
  },
  props: {
    selectedChatId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    helpers,
    files: [],
    filesLoader: false,
    image_mime_type_arr: ["image/jpeg", "image/png", "image/svg+xml"],
    infiniteId: +new Date(),
    current_page: 1
  }),
  mounted() {
    // this.getFilesListBarTag().onscroll = this.scroll;
  },
  computed: {
    isImage() {
      return mime_type => {
        return this.image_mime_type_arr.includes(mime_type);
      };
    },
    getFileData() {
      return file => {
        return `${helpers.getFileType(
          file.mime_type
        )}, ${helpers.getFormatedFileSize(file.size)}`; // 'PDF, 3.2mb, 8 pages'
      };
    }
  },
  methods: {
    hideBar() {
      this.$emit("hide-bar");
    },

    getFilesListBarTag() {
      return document.querySelectorAll(".files-list-bar")[0];
    },

    getFiles($state) {
      this.filesLoader = true;
      gsApi.chat.file
        .index(this.selectedChatId, { page: this.current_page, per_page: 5 })
        .then(response => {
          this.filesLoader = false;

          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            //const file_arr = response.data.data.reverse();
            this.files.push(...response.data.data);
            this.current_page += 1;
            if (response.data.meta.pagination.current_page >= response.data.meta.pagination.total_pages) {
              $state.complete();
            }else{
              $state.loaded();
            }
          } else {
            $state.complete();
          }
        });
    }

    // scroll() {
    //     this.paginateWhenScrollIsTop();
    // },

    // paginateWhenScrollIsTop() {
    //     const has_next_page = this.$parent.$parent.selectedChat.filesObj.meta.pagination.current_page < this.$parent.$parent.selectedChat.filesObj.meta.pagination.total_pages;
    //     const filesListBarTag = this.getFilesListBarTag();
    //     const paginate_position = filesListBarTag.offsetTop - filesListBarTag.scrollTop === 0;

    //     if (paginate_position && has_next_page) {
    //         const page = this.$parent.$parent.selectedChat.filesObj.meta.pagination.current_page + 1;
    //         this.$parent.$parent.getChatFiles(page);
    //     }
    // },
  },
  watch: {
    selectedChatId(newVal, oldVal) {
        this.files = [];
        this.infiniteId += 1;
        this.current_page = 1;
    }
  }
  /*created() {
            this.getFiles()
        }*/
};
</script>
