import { render, staticRenderFns } from "./personal-details.vue?vue&type=template&id=5f5d895c"
import script from "./personal-details.vue?vue&type=script&lang=js"
export * from "./personal-details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports