import _ from 'lodash';
import ar from "element-ui/src/locale/lang/ar";

const removeScroll = (tagName) => {
    document.getElementsByTagName(tagName)[0].classList.add('no-scroll');
};

const removeScrollOnClass = (className) => {
    document.querySelectorAll(className)[0].classList.add('no-scroll');
};

const allowScrollOnClass = (className) => {
    document.querySelectorAll(className)[0].classList.remove('no-scroll');
};

const allowScroll = (tagName) => {
    document.getElementsByTagName(tagName)[0].classList.remove('no-scroll');
};

const setHeightFullScreen = (tagName) => {
    const tag = document.getElementsByTagName(tagName)[0];

    tag.height = '100vh';
    tag.width = '100vw';
};

const fadeScreen = () => {
    const div = document.createElement("div");
    div.id = 'fade-screen';
    div.style.background = "#000";
    div.style.opacity = "0.5";
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.position = 'fixed';
    div.style['z-index'] = '10';
    document.body.appendChild(div);
};

const burnScreen = () => {
    document.getElementById('fade-screen').remove();
};

const checkIfOutsideClick = (event, class_name) => {
    return event.target && !_.isUndefined(event.target.className) &&
        !_.isObject(event.target.className) && !event.target.className.split(' ').includes(class_name) ||
        _.isObject(event.target.className) && (event.target.className.baseVal && event.target.className.baseVal !== class_name)
};

const onOutsideClick = (class_name, event, callback) => {
    let isOutsideClick = false;
    let arr = [];

    if (!Array.isArray(class_name)) {
        class_name = [class_name];
    }

    _.each(class_name, (name) => {
        arr.push(checkIfOutsideClick(event, name));
    });

    if (!arr.includes(false)) {
        isOutsideClick = true;
    }

    if (isOutsideClick) {
        callback();
    }
};

export {
    removeScroll,
    removeScrollOnClass,
    allowScrollOnClass,
    allowScroll,
    setHeightFullScreen,
    fadeScreen,
    burnScreen,
    onOutsideClick
};
