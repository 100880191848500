<template>
  <div
    class="company-item"
    v-loading="loader">
    <div class="company-header d-flex">
      <div class="company-details">
        <span class="company-name">
          {{ company.name }}
        </span>
        <span class="small">{{company.role}}</span>
      </div>
      <div class="company-actions">
        <button class="is-primary" v-if="company.is_primary">
          Primary
        </button>
        <button
          class="mark-as-primary"
          v-else
          @click="markAsPrimary($event)"
        >
          Mark as primary
        </button>
        <button
          class="details-show"
          @click="showMore = !showMore"
        >
          {{ showMore ? 'Hide details' : 'Show details' }}
        </button>
      </div>
    </div>
    <div class="company-body" v-if="showMore">
      <el-form
        class="company-form"
        id="companyForm"
        ref="companyForm"
        :model="form"
        :rules="rules"
        @submit.prevent.native="onSubmit"
        autocomplete="false"
        @validate="handleCompanyFormFieldInput"
      >
        <el-form-item
          prop="name"
          class="form-group mb-4">
          <template slot="label">
            Company name
            <span class="sr-only"
                  aria-live="polite"
                  :hidden="! this.hasInlineValidationErrorFor('name')"
                  v-text="this.getInlineValidationErrorFor('name')"></span>
          </template>
          <el-input
            v-model="form.name"
            @blur="form.name = form.name.trim()"
            id="name"
            autocomplete="off"
            label="Please enter your company name"
            aria-required="true"
          />
        </el-form-item>
        <el-form-item
          prop="role"
          class="form-group mb-4">
          <template slot="label">
            Your role at the company
            <span class="sr-only"
                  aria-live="polite"
                  :hidden="! this.hasInlineValidationErrorFor('role')"
                  v-text="this.getInlineValidationErrorFor('role')"></span>
          </template>
          <el-input
            v-model="form.role"
            @blur="form.role = form.role.trim()"
            id="role"
            autocomplete="off"
            label="Please enter your role at the company"
            aria-required="true"
          />
        </el-form-item>
        <div class="d-flex form-actions">
          <el-button
            class="el-button btn-cancel"
            @click="onCancel($event)"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            class="btn-submit">
            Save changes
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="step-hr" v-if="showDivider"></div>
  </div>
</template>

<script>
import gsApi from '@/services/gs.api'
import { mapMutations, mapGetters } from 'vuex'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

export default {
  mixins: [inlineValidationErrorsMixin],

  name: "gs-companies-company-item",

  props: {
    company: {
      type: Object,
      default: () => {}
    },

    showDivider: {
      type: Boolean,
      default: true
    }
  },

  data() {
      return {
        form: {},
        showMore: false,
        loader: false,
        rules: {
          name: [
            {
              required: true,
              message: "Enter your company name",
              trigger: "blur"
            }
          ],
          role: [
            {
              required: true,
              message: "Enter your role within the company",
              trigger: "blur"
            },
            {
              max: 255,
              message: "Role may not be greater than 255 characters",
              trigger: "change"
            }
          ]
        }
      }
  },

  methods: {
    markAsPrimary(e) {
      this.loader = true

      gsApi.globalscot.user
        .updateCompanyAsPrimary(this.form.id)
        .then(({data}) => {
          this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
          this.$alert(`You successfully set "${this.form.name}" as your primary company.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.showMore = false
        })
        .catch((response) => {
          if (response.status == 422) {
            let firstError =
              response.data.errors[Object.keys(response.data.errors)[0]]
            this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

    onSubmit(e) {
      this.$refs.companyForm.validate(valid => {
          if (valid) {
            this.loader = true

            gsApi.globalscot.user
            .updateCompany(this.form)
            .then(({data}) => {
              this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
              this.$alert('You successfully updated your company', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              this.showMore = false
            })
            .catch((response) => {
              if (response.status == 422) {
                let firstError =
                  response.data.errors[Object.keys(response.data.errors)[0]]
                this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              } else {
                this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
            .finally(() => {
              this.loader = false
            })
          } else {
            this.$nextTick(() => {
              document
                .getElementById("companyForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView()

              let text = document
                .getElementById("companyForm")
                .getElementsByClassName("el-form-item__error")[0].textContent

              this.$announcer.set(text)
            })
          }
      })
    },

    onCancel(e) {
      this.form = JSON.parse(JSON.stringify(this.company))
      this.showMore = false
    },
    handleCompanyFormFieldInput() {
      this.handleSingleFormFieldInput('companyForm')
    }
  },

  created () {
    this.form = JSON.parse(JSON.stringify(this.company))
  }
}
</script>

<style lang="scss">
.company-item {
  //margin-top: 25px;
  //margin-bottom: 50px;
  &:last-child {
    .step-hr {
      display: none;
    }
  }
  .company-header {
    justify-content: space-between;
    align-items: center;
    .company-details {
      display: grid;
      line-height: 28px;
      .company-name {
        color: $primary;
      }
    }
    button {
      font-family: brandon-grotesque;
      &.is-primary {
        font-weight: 420;
        font-size: 16px;
        color: $white;
        background-color: #EA6576;
        border-radius: 19px;
        padding: 1px 16px;
        margin-right: 75px;
      }
      &.mark-as-primary {
        font-weight: 420;
        font-size: 19px;
        text-decoration: underline;
        color: #700E57;
        margin-right: 55px;
      }
      &.details-hidden {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.details-show {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.remove {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
</style>
