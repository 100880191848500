<!-- Navigation Bar Component -->
<template>
  <nav
    class="main-navigation-menu-bar hidden-md-and-down top-nav-bar"
    aria-label="main navigation"
  >
    <ul class="navbar p-navbar">
      <li>
        <router-link :to="{ name: 'Home' }">Home</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Get Business Support' }"
          >Get business support</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'Case Studies Public' }"
          >Case studies</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'Business Events Webinars' }"
          >Events & webinars</router-link
        >
      </li>
      <li id="sub-menu-container">
        <a
          href="javascript:void(0)"
          id="about-link"
          :class="{ 'router-link-exact-active': isAboutLinkActive() }"
          >About us
          <svg-white-chevron-down class="ml-2"></svg-white-chevron-down
        ></a>
        <ul id="sub-nav" class="sub-nav">
          <li>
            <router-link
              :to="{ name: 'About Network' }"
              id="about-network-link"
              class="sub-nav-item"
              >About the network</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Become GlobalScot' }"
              id="become-globalscot-link"
              class="sub-nav-item"
              >Become a GlobalScot</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'About' }"
              id="about-organisation-link"
              class="sub-nav-item"
              >About our organisation</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <a :href="getCMSRoute('contact-us')">Contact us</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Logo from "@/components/assets/svg-gs-logo-full";
import LogoSmall from "@/components/assets/svg-gs-logo-only";
import DropDown from "@/components/assets/svg-dropdown";
import SuccessBorder from "@/components/assets/svg-success-border";
import Collapse from "@/components/assets/svg-mobile-menu";
import { mixin as onClickOutside } from "vue-on-click-outside";
import RolePermissionMixin from "@/mixins/RolePermissionMixin";
import WhiteChevronDown from "@/components/assets/svg-white-chevron-down";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";

export default {
  mixins: [onClickOutside, RolePermissionMixin],
  components: {
    "svg-logo": Logo,
    "svg-logo-small": LogoSmall,
    "svg-dropdown": DropDown,
    "svg-success-border": SuccessBorder,
    "svg-collapse": Collapse,
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-white-chevron-down": WhiteChevronDown
  },
  data: function() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      isCollapsed: true,
      isCollapsedNot: false,
      isDropDown: false,
      request: {},
      requestModalOpen: false
    };
  },
  methods: {
    isAboutLinkActive() {
      return (
        this.$route.path === "/about-network" ||
        this.$route.path === "/become-a-global-scot" ||
        this.$route.path === "/about"
      );
    },
    handleSelect(key, keyPath) {
      this.isDropDown = false;
    },
    showNot() {
      this.isCollapsedNot = true;
      // document.getElementById("notSidenav").style.transform = "translate(0)";
    },
    closeNot() {
      if (this.isCollapsedNot === true) {
        this.isCollapsedNot = false;
        // document.getElementById("notSidenav").style.transform = "translate(100%)";
      }
    },
    subMenuFocusCheck() {
      let subNav = document.getElementById("sub-nav");
      var activeElementClass = document.activeElement.id;
      //console.log('hello')

      if (subNav) {
        if (
          activeElementClass == "about-link" ||
          activeElementClass == "about-network-link" ||
          activeElementClass == "become-globalscot-link" ||
          activeElementClass == "about-organisation-link"
        ) {
          subNav.style.display = "block";
        } else {
          subNav.style.display = "none";
        }
      }
    }
  },
  mounted() {
    document.addEventListener(
      "keyup",
      e => {
        this.subMenuFocusCheck();
      },
      false
    );
  }
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(10px);
  opacity: 0;
}
</style>
