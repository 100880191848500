<template>
  <div class="signup-verify-page page">

    <div :class="`signup-verify-page__header`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <div class="row">
          <div class="col-md-24 col-lg-12">
            <div class="header__content__wrapper">
              <h2 class="header__title">
                Did you know our GlobalScots helped over 770 Scottish businesses in 2019?
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="signup-verify-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-14">
            <!-- Verify email return -->
            <div class="body__content">
              <h1 class="se-heading-xl-40 mb-4">
                Verify your email to continue
              </h1>

              <p class="se-body-l-screen-19 mb-4">
                You’ve not verified your email address yet. We’ve emailed you a new link to verify your email
                address.<br />
                <br />Didn’t receive the email? Try checking your junk mail or we can resend the email.
              </p>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <el-button type="primary"
                           native-type="submit"
                           @click="onResendEmail()"
                           :loading="isLoading">
                  Resend email
                </el-button>
              </div>

              <p class="se-body-l-screen-19 mb-4">
                If you don’t receive your email within 1 hour, <a :href="getCMSRoute('contact-us')" class="text__link"><b>contact us</b></a>.
              </p>

            </div>
            <!-- Verify email return End -->

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store'
import { mapState } from "vuex"
import gsApi from '@/services/gs.api'

export default {
  name: 'signup-verify',
  metaInfo: {
    title: "Verify Account"
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    onResendEmail () {
      this.isLoading = true
      gsApi.auth.resendVerification(this.user.id, this.user.email)
      .then((response) => {
          this.isLoading = false
          this.$alert('A new email has been sent. Check your inbox.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      }).catch(() => {
          this.isLoading = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      })
    }
  }
}
</script>
