<!-- About Component -->

<template>
  <div class="about-page">
    <el-row :gutter="0" class="m-0">
      <top-navigation></top-navigation>
      <el-col :span="24" class="article-featured px-0">
        <div class>
          <div class="become-page__header small-header">
            <div class="header__content__bg"></div>
            <div class="header__image__bg become-gs-bg"></div>
            <div class="header__content container">
              <el-row class="pb-3">
                <el-col :md="24" :lg="12">
                  <div class="header__content__wrapper">
                    <h1 id="main-content" class="header__title">
                      {{ text.headingText }}
                    </h1>
                    <h2 class="header__content-info">
                      {{ text.headingSub }}
                    </h2>
                  </div>
                </el-col>
              </el-row>
              <!--el-row class="pb-3">
                <el-button
                  class="btn-text"
                  type="primary"
                  size="small"
                  @click="$router.push({ name: 'Signup GS' })"
                >
                  Get Started</el-button
                >
              </!--el-row-->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="white-background-section">
      <div class="container">
        <el-row>
          <el-col>
            <div class="why-quote">
              <svg-quote-line></svg-quote-line>
              <svg-quote-open></svg-quote-open>
              <div class="quote-text">
                <p>{{ text.headingQuote }}</p>
                <p class="author">{{ text.author }}</p>
              </div>
              <svg-quote-close class="close-quote"></svg-quote-close>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div>
      <what-does-it-involve></what-does-it-involve>
    </div>
    <!--div>
        <how-does-it-work></how-does-it-work>
      </div-->
    <div>
      <how-can-you-help></how-can-you-help>
    </div>
    <div>
      <how-do-i-become></how-do-i-become>
    </div>
  </div>
</template>

<script>
import TopNavigation from "../../layout/top-navigation";
import WhatDoesItInvolve from "../../pages/landing/becoming/what-does-it-invlolve";
import HowDoesItWork from "../../pages/landing/becoming/how-does-it-work";
import HowCanYouHelp from "../../pages/landing/becoming/how-can-you-help";
import HowDoIBecome from "../../pages/landing/becoming/how-do-i-become";
import QuoteLineSvg from "@/components/assets/svg-quote-line";
import QuoteOpenSvg from "@/components/assets/svg-quote-open";
import QuoteCloseSvg from "@/components/assets/svg-quote-close";

export default {
  name: "become-a-global-scot",
  metaInfo: {
    title: "Becoming a GlobalScot",
    meta: [
      {
        name: "description",
        content:
          "Have you got the skills, experience and connections to support Scottish businesses? Find out more about becoming a GlobalScot."
      }
    ]
  },
  components: {
    WhatDoesItInvolve,
    TopNavigation,
    HowDoesItWork,
    HowCanYouHelp,
    HowDoIBecome,
    "svg-quote-line": QuoteLineSvg,
    "svg-quote-open": QuoteOpenSvg,
    "svg-quote-close": QuoteCloseSvg
  },
  data() {
    return {
      text: {
        headingText: "Becoming a GlobalScot",
        headingSub:
          "GlobalScot is an altruistic, values-driven international network founded on giving back time, expertise and insights to help others, free of charge. In your role, you’ll be involved with some of Scotland’s most exciting and ambitious companies – acting as mentor, sounding board and strategic advisor to the next generation of Scottish business leaders. It’s an incredibly rewarding challenge.",
        headingQuote:
          "I take great satisfaction in working with Scottish companies. These are exceptionally talented and professional people who work at the cutting edge of their industries. For me, it’s exciting and a privilege to be a member of their respective teams",
        author: "Mark Goldschmidt, Partner at Holland & Knight"
      }
    };
  }
};
</script>
<style lang="scss">
.quote-text {
  font-size: 19px;
  margin-top: 30px;
  font-weight: 390;
  line-height: 28px;
}
.author {
  margin-top: 10px;
  color: #af006e;
  max-width: 800px;
}
.why-quote {
  margin: 30px;
  display: grid;
  grid-template-columns: 0.1fr 0.1fr 1.5fr 0.1fr;
}
</style>
