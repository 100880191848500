<template>
  <slide-panel @panelclose="closeMenu" title="Filter">
    <el-form id="mobileSearchForm" :model="value" ref="mobileSearchForm" class="members-filter" @submit.native.prevent>
      <el-row>
        <el-col :sm="24" :md="12" :lg="6">
          <el-form-item prop="country" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">Country</span>
              <span class="sr-only">Please select a country</span>
            </template>
            <v-select
              v-model="value.selectedCountry"
              placeholder="Choose from the following"
              :options="countries"
              :searchable="true"
              :clearable="false"
              :reduce="country => country.id"
              inputId="country"
              autocomplete="false"
              label="name"
              class="half-content" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="6">
          <el-form-item prop="sector" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">Sector</span>
              <span class="sr-only">Please select a sector</span>
            </template>
            <v-select
              v-model="value.sector"
              placeholder="Choose from the following"
              :options="sectors"
              :searchable="true"
              :clearable="false"
              :reduce="sector => sector.id"
              inputId="sector"
              autocomplete="false"
              label="name"
              class="half-content" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="6">
          <el-form-item prop="sortBy" class="is-no-asterisk">
            <template slot="label">
              <span aria-hidden="true">Sort By</span>
              <span class="sr-only">Sort by</span>
            </template>
            <v-select
              v-model="value.sortBy"
              placeholder="Choose from the following"
              :options="sortOptions"
              :searchable="true"
              :clearable="true"
              inputId="sortBy"
              autocomplete="false"
              label="name"
              class="half-content" />
          </el-form-item>
        </el-col>
        <el-col :sm="24">
          <div class="d-flex mt-4">
            <span class="reset-btn float-right" @click="$emit('reset')">Reset</span>
            <el-button type="primary" class="float-right update-results-btn flex-fill ml-4" @click="onSearch">
              Search
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </slide-panel>
</template>

<script>
  import store from '@/store'
  import { mapState } from "vuex"
  import SlidePanel from '@/components/slide-panel/slide-panel'

  export default {
    name: 'projects-filter',
    props: {
      value: {
        type: Object,
        required: true
      },
      sectors: {
        type: Array,
        required: true
      },
      countries: {
        type: Array,
        required: true
      },
      sortOptions: {
        type: Array,
        required: true
      },
    },
    components: {
      'slide-panel': SlidePanel
    },
    computed: {
      ...mapState(['user']),
    },
    methods: {
      onSearch() {
        this.$emit('search')
      },
      closeMenu() {
        this.$emit('close')
      },
    },
  }
</script>
