<template>
  <div>
    <div>
      <h1 class="other-way-to-help-title">{{helpText.heading}}</h1>
      <p class="other-way-to-help-sub">{{helpText.sub}}</p>
    </div>
    <div>
      <div>
        <ul class="other-way-to-help-list">
          <li v-for="point in helpBulletPoints" :key="point.text">{{ point.text }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      helpText: {
        heading: "Other ways you can help",
        sub: "In addition to providing business support, many of our GlobalScots undertake activities to support the network or Scotland as a whole. These could include:",
      },
     helpBulletPoints: [
        {
          text: "Publishing market insights and reports"
        },
        {
          text: "Speaking at events"
        },
        {
          text: "Taking part in collaborative challenges and projects"
        },
        {
          text: "Encouraging inward investment by promoting Scotland as a business destination"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.eligible-sub {
  font-size: 19px;
}
.notes-wrap {
  .eligible-sub, li {
    font-weight: 500 !important;
  }
}
</style>
