<template>
  <div
    class="company-item"
    v-loading="loader"
  >
    <div class="company-header d-flex">
      <div class="company-details">
        <span class="company-name">
          {{ company.name }}
        </span>
        <span class="company-address">
          {{ companyAddress(company) }}
        </span>
      </div>
      <div class="company-actions">
        <button class="is-primary" v-if="company.is_primary">
          Primary
        </button>
        <button
          class="mark-as-primary"
          v-else
          @click="markAsPrimary($event)"
        >
          Mark as primary
        </button>
        <button
          class="details-show"
          @click="showMore = !showMore"
        >
          {{ showMore ? 'Hide details' : 'Show details' }}
        </button>
      </div>
    </div>
    <div class="company-body" v-if="showMore">
      <non-gs-company-form :form="form" :groups="groups" :subGroups="subGroups" @onCancel="cancel" :editMode="true" ></non-gs-company-form>
    </div>
    <div class="step-hr" v-if="showDivider"></div>
  </div>
</template>

<script>
import gsApi from '@/services/gs.api'
import { mapMutations, mapGetters } from 'vuex'
import NonGsCompanyForm from './non-gs-company-form'
export default {
  name: "gs-companies-company-item",
  components:{
    NonGsCompanyForm
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },

    groups: {
      type: Array,
      default: () => []
    },

    showDivider: {
      type: Boolean,
      default: true
    },

    subGroups: {
      type: Array,
      default: () => []
    },
  },

  data() {
      return {
        form: {
          id: null,
          name: '',
          address_1: '',
          address_2: '',
          post_code: '',
          town: '',
          county: '',
          role: '',
          group_ids: [],
          sub_group_ids: []
        },
        showMore: false,
        loader: false,
      }
  },

  methods: {
    markAsPrimary(e) {
      this.loader = true

      gsApi.member.user
        .updateCompanyAsPrimary(this.form.id)
        .then(({data}) => {
          this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
          this.$alert(`You successfully set "${this.company.name}" as your primary company.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          this.showMore = false
        })
        .catch((response) => {
          if (response.status == 422) {
            let firstError =
              response.data.errors[Object.keys(response.data.errors)[0]]
            this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
    cancel(e) {
      this.initForm()
      this.showMore = false
    },

    companyAddress({ address }) {
      if (!address) return '';
      return `${address.address_1}, ${address.town}, ${address.post_code}`
    },

    initForm() {
      this.form.id = this.company.id
      this.form.role = this.company.role
      this.form.name = this.company.name
      this.form.address_1 = this.company.address.address_1
      this.form.address_2 = this.company.address.address_2
      this.form.post_code = this.company.address.post_code
      this.form.town = this.company.address.town
      this.form.county = this.company.address.county

      this.form.group_ids = []
      this.form.sub_group_ids = []

      this.company.groups.data.forEach(group => {
        if (group.name != 'Member') {
          this.form.group_ids.push(group.id)
          this.form.sub_group_ids = this.form.sub_group_ids.concat(group.subGroups.data.map(subGroup => subGroup.id))
        }
      })
    }
  },

  created () {
    this.initForm()
  }
}
</script>
