<template>
  <div class="col-md-24 col-lg-20 mb-4">
    <div class="address-details se-lead-paragraph-l">
      <span class="color-primary">
        {{ business.name }}
      </span>
      <span>
        {{ business.role }}
      </span>
    </div>

    <div class="d-flex edit-company-actions">
      <el-button
        @click="businessEditToggle()"
        type="primary">
        <span v-if="!editMode">Edit details</span>
        <span v-else>Save details</span>
      </el-button>
      <el-button @click="removeFromBusinesses()">
        <span v-if="!editMode">Remove</span>
        <span v-else>Cancel</span>
      </el-button>
    </div>

    <el-form :model="editableBusiness"
             :rules="rules"
             ref="businessForm"
             v-if="editMode"
             id="businessForm"
             @submit.native.prevent
             @validate="handleBusinessFormFieldInput">
      <div class="address-details mb-3 mt-4">
        <div class="row">
          <div class="col-md-24 col-lg-20">
            <el-form-item prop="name">
              <template slot="label">
                Name of company
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('name')"
                      v-text="this.getInlineValidationErrorFor('name')"></span>
              </template>
              <el-input
                v-model.trim="editableBusiness.name"
                id="name"
                autocomplete="false"
                maxlength="255"
                label="Please enter company name in this field"
                aria-required="true" />
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-24 col-lg-20">
            <el-form-item prop="role">
              <template slot="label">
                Your role at company
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('role')"
                      v-text="this.getInlineValidationErrorFor('role')"></span>
              </template>
              <el-input
                v-model.trim="editableBusiness.role"
                id="role"
                autocomplete="false"
                maxlength="255"
                label="Please enter company role in this field"
                aria-required="true" />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  name: 'manage-business',
  mixins: [inlineValidationErrorsMixin],
  data () {
    return {
      editMode: false,
      editableBusiness: {
        name: '',
        role: ''
      },
      rules: {
        name: [
          { required: true, message: 'Name is required', trigger: 'blur' },
          { max: 255, message: 'Name may not be greater than 255 characters', trigger: 'change' },
        ],
        role: [
          { required: true, message: 'Role is required', trigger: 'blur' },
          { max: 255, message: 'Role may not be greater than 255 characters', trigger: 'change' },
        ]
      },
    }
  },
  props: {
    business: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    businessEditToggle () {
      if (this.editMode) {
        this.$refs['businessForm'].validate((valid) => {
          if (valid) {
            this.editMode = false
            this.$emit('update-business', this.editableBusiness)
            this.$alert('Company has been updated.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$nextTick(() => {
              document.getElementById("businessForm").getElementsByClassName('is-error')[0].scrollIntoView()
              let text = document.getElementById("businessForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        });
      } else {
        this.editMode = true
      }
    },
    setEditableBusiness () {
      this.editableBusiness.name = this.business.name
      this.editableBusiness.role = this.business.role
    },
    removeFromBusinesses() {
      if (this.editMode) {
        this.setEditableBusiness()
      } else {
        this.$emit('remove-business', this.editableBusiness)
      }

      this.editMode = false
    },
    handleBusinessFormFieldInput() {
      this.handleSingleFormFieldInput('businessForm');
    },
  },
  created () {
    this.setEditableBusiness()
  }
}
</script>
