<template>
  <focus-lock>
    <el-dialog
      :show-close="!codeLoading"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog new-ui"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div class="body">
        <h2 class="mb-2">
          {{ step1 ? "Verify code" : "Enter Code" }}
        </h2>
        <p v-if="step2" class="mb-3">
          An email with a 6-digit verification code was sent to this email
          address ending in {{ emailFormatted }}.
        </p>
        <el-row v-if="step1">
          <el-col>
            <p class="mb-3">
              Multi-factor authentication is pending verification. Before we add
              this email address to your account, we need to verify it.
            </p>
          </el-col>
          <el-col>
            <el-form
              id="validateEmailForm"
              v-loading="codeLoading"
              ref="validateEmailForm"
              :model="form"
              autocomplete="false"
              class="mt-0"
              @submit.native.prevent
            >
              <el-form-item
                label="Email address"
                prop="two_factor_method"
                :rules="[
                  {
                    required: true,
                    message: 'Email Address field is required',
                    trigger: 'blur',
                  },
                  {
                    type: 'email',
                    message: 'Please provide a valid email address',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <template slot="label">
                  <slot name="textarea-label"> Email address </slot>
                  <span
                    class="sr-only"
                    aria-live="polite"
                    :hidden="!hasInlineValidationErrorFor('two_factor_method')"
                    v-text="getInlineValidationErrorFor('two_factor_method')"
                  ></span>
                </template>
                <el-input
                  id="two_factor_method"
                  v-model="form.two_factor_method"
                  aria-required="true"
                  autocomplete="off"
                  @keyup.enter.native="sendCode()"
                ></el-input>
                <template #error="{ error }">
                  <div aria-describedby="two_factor_method" class="el-form-item__error">{{ error }}</div>
                </template>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col class="mt-4">
            <el-row>
              <el-col :xs="24" :sm="12">
                <el-button
                  class="mb-2 w-100 mx-0 mr-1"
                  :disabled="codeLoading"
                  @click="onClose()"
                >
                  Cancel
                </el-button>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-button
                  :loading="codeLoading"
                  type="primary"
                  class="mb-2 w-100 ml-1 mr-0"
                  @click="sendCode()"
                >
                  Verify
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row v-if="step2" class="w-100">
          <el-form
            ref="emailAuthenticatorForm"
            v-loading="codeLoading"
            :model="confirmationForm"
            autocomplete="false"
            :rules="rules"
            class="mt-0"
            @submit.native.prevent
          >
            <el-form-item
              prop="code"
              class="is-no-asterisk mt-2 mb-0"
              :error="verificationCodeError"
            >
              <template slot="label">
                <slot name="textarea-label"> Verification Code </slot>
                <br />
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!hasInlineValidationErrorFor('code')"
                  v-text="getInlineValidationErrorFor('code')"
                ></span>
              </template>
              <el-input
                id="code"
                v-model="confirmationForm.code"
                name="verification code"
                placeholder="Verification code"
                :max-length="6"
                aria-required="true"
                @keyup.enter.native="submitConfirmation()"
              ></el-input>
              <template #error="{ error }">
                <div aria-describedby="code" class="el-form-item__error">{{ error }}</div>
              </template>
            </el-form-item>
            <el-row class="mt-4">
              <el-col :xs="24" :sm="12">
                <el-button
                  class="mt-3 mb-3 submit-btn mx-auto w-100"
                  @click="sendCode(true)"
                >
                  Send again
                </el-button>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-button
                  class="mt-3 mb-3 submit-btn mx-auto w-100"
                  type="primary"
                  @click="submitConfirmation"
                >
                  Submit
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
  </focus-lock>
</template>
<script>
import gsApi from "@/services/gs.api";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import enumHelper from "@/helpers/enumHelper";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      step1: true,
      step2: false,
      form: {
        two_factor_method: null,
        two_factor_type: enumHelper.MFAType.EMAIL,
      },
      confirmationForm: {
        code: null,
        enable: true,
        two_factor_type: enumHelper.MFAType.EMAIL,
      },
      codeLoading: false,
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    emailFormatted() {
      if (!this.form.two_factor_method) return "";

      let $em = this.form.two_factor_method.split("@");
      let $name = $em[0];
      const $length = Math.floor($name.length / 2);
      return `${$name.substring(0, $length)}${"*".repeat($length)}@${[
        ...$em,
      ].pop()}`;
    },
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },

  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    async sendCode(sendAgain = false) {
      let isValid = false;
      if (!sendAgain) {
        this.$refs.validateEmailForm.validate(async (valid) => {
          isValid = valid;
        });
      }

      if (!isValid && !sendAgain) return;

      this.codeLoading = true;
      // Enable Two Factor Phone Authentication
      const codeReponse = await gsApi.mfa
        .multiFactorAuthentification(this.form)
        .catch((error) => {
          this.codeLoading = false;

          this.$alert(error.response.data.error.message, "", {
            confirmButtonText: "Try again",
            customClass: "notification-modal message-modal",
          });

          return false;
        });

      if (!codeReponse) {
        this.step1 = true;
        this.step2 = false;
        return;
      }

      this.step1 = false;
      this.step2 = true;

      this.codeLoading = false;
    },
    submitConfirmation() {
      this.$refs.emailAuthenticatorForm.validate(async (valid) => {
        if (valid) {
          this.codeLoading = true;

          const twoFactorConfirmedResponse = await gsApi.mfa
            .confirmedMultiFactorPhoneAuthentication(this.confirmationForm)
            .catch((error) => {
              this.verificationCodeError =
                error?.response?.data?.error?.message;

              return false;
            });

          if (!twoFactorConfirmedResponse) {
            this.codeLoading = false;
            return false;
          }
          this.$emit("refresh-user-multifactor-methods");

          this.verificationCodeError = null;
          // refresh the user data to get all available methods
          this.onClose();

          this.$alert(
            `<i class="el-icon el-icon-circle-check"></i>
                <p class='title mb-5 mt-2'>Multi-factor authentication enabled</p>
                <p class='method-name mb-2' >EMAIL</p>
                <p class='message'>Verification has successfully been added to your account</p>
                `,
            "",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "Close",
              showClose: true,
              customClass: "notification-modal message-modal",
            }
          );
        }
      });
    },
  },
};
</script>
