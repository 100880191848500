<template>
  <el-form v-loading="loader"
    class="company-form"
    id="companyForm"
    ref="companyForm"
    :model="form"
    :rules="rules"
    autocomplete="false"
    @validate="handleOtherCompanyFormFieldInput"
  >
    <el-form-item prop="name" class="form-group mb-4">
      <template slot="label">
        Company name
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('name')"
              v-text="this.getInlineValidationErrorFor('name')"></span>
      </template>
      <el-input
        v-model="form.name"
        @blur="form.name = form.name.trim()"
        id="name"
        autocomplete="off"
        label="Please enter your company name"
        aria-required="true"
      />
    </el-form-item>
    <el-row>
      <el-col :sm="6">
        <el-button @click="onSubmit">Add company</el-button>
      </el-col>
      <el-col :sm="6">
        <el-button @click="onCloseConnectionForm">Cancel</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import gsApi from '@/services/gs.api'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  mixins: [inlineValidationErrorsMixin],
  data() {
    return {
      loader:false,
      form : {
        name : "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Enter your company name",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs.companyForm.validate(valid => {
        if (valid) {
            this.loader = true;
            gsApi.globalscot.user
            .addOtherCompany({businesses : [], other_businesses : [this.form]})
            .then(({data}) => {
              this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
              this.$alert('You successfully added your company', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              this.$emit("onCloseConnectionForm");
            })
            .catch((error) => {
              if (error.response.status == 422) {
                let firstError =
                  error.response.data.errors[Object.keys(error.response.data.errors)[0]]
                this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              } else {
                this.$alert('Sorry, your request could not be processed', `Error ${error.response.data.error.status_code} - ${error.response.data.error.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
            .finally(() => {
              this.loader = false
            })
        }
      });
    },
    onCloseConnectionForm(){
      this.form.name="";
      this.$emit("onCloseConnectionForm");
    },
    handleOtherCompanyFormFieldInput() {
      this.handleSingleFormFieldInput('companyForm');
    },
  }
};
</script>
