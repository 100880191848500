export default {
  methods: {
    setCookie(key, value, expiryDays) {
      const date = new Date()
      date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000))
      const expires = `expires=${date.toUTCString()}`
      document.cookie = `${key}=${value};${expires};path=/`
    },
    getCookie(key) {
      const name = `${key}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return false
    },
  }
}
