<template>
  <svg width="70" height="65" viewBox="0 0 70 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.478 37.0676H21.543L9.79999 48.8057V37.0676H1V1.85059H56.761V20.9258"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.283 51.7407H49.424L62.631 63.4797V51.7407H68.5009V28.2627H33.283V51.7407Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
