<template>
  <div class="projects-page news-page body__content" v-loading.fullscreen.lock="mainLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
  	<div class="search-section">
  		<div class="container">
  			<el-row class="project-global-filters">
	  			<el-col :sm="24">
            <div class="search-btn-container hidden-sm-and-down">
              <ul class="search-buttons">
                <li>
                  <el-button
                    aria-label="Show all projects"
                    @click="handleGlobalFilter('All')"
                    v-bind:class="{ 'active-filter': globalFilter === 'All' }"
                    class="search-btn">
                    <p>All Projects</p>
                  </el-button>
                </li>
                <li>
                  <el-button
                    aria-label="Show only my projects"
                    @click="handleGlobalFilter('Own')"
                    v-bind:class="{ 'active-filter': globalFilter === 'Own' }"
                    class="search-btn">
                    <p>My Projects</p>
                  </el-button>
                </li>
              </ul>
            </div>
					</el-col>
				</el-row>
        <el-row class="active-ended-filters">
          <el-col :sm="24">
            <div class="search-btn-container hidden-sm-and-down">
              <ul class="search-buttons">
                <li>
                  <el-button
                    aria-label="Show active projects"
                    @click="handleSubGlobalFilter('Active')"
                    v-bind:class="{ 'active-filter': search.subType === 'Active' }"
                    class="search-btn">
                    <p>Active</p>
                  </el-button>
                </li>
                <li>
                  <el-button
                    aria-label="Show ended projects"
                    @click="handleSubGlobalFilter('Ended')"
                    v-bind:class="{ 'active-filter': search.subType === 'Ended' }"
                    class="search-btn">
                    <p>Ended</p>
                  </el-button>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
			  <el-form class="mt-4" id="searchForm" :model="search" ref="searchForm" @submit.native.prevent>
	        <el-row>
	          <el-col :sm="24" :md="12">
              <p class="color-purple-2 mb-1 fs-19">Keyword search</p>
	            <el-form-item label="" prop="keyword" class="hidden-sm-and-down label-">
		            <el-input v-model="search.keyword"
		                      placeholder="Search projects by keyword"
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          	<el-form-item label="" prop="keyword" class="hidden-md-and-up">
		            <el-input v-model="search.keyword"
                    @keyup.enter.native="searchProjects"
                    placeholder="Search for keyboards, location, people..."
                    id="keyword"
                    auto-complete="no"
                    maxlength="255">
		            </el-input>
	          	</el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" class="hidden-sm-and-down">
	            <el-button type="primary" @click="searchProjects">
	              Search
	            </el-button>
	          </el-col>
	        </el-row>
          <el-row>
              <el-col :span="24">
                <p class="color-purple-2 mb-1 fs-19">Filters</p>
              </el-col>
          </el-row>
	        <el-row class="hidden-sm-and-down">
            <el-col :sm="24" :md="10" :lg="6">
              <el-form-item prop="title" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Country</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.selectedCountry"
                  placeholder="Choose from the following"
                  :options="countries"
                  :searchable="true"
                  :clearable="false"
                  :reduce="country => country.code"
                  label="name"
                  inputId="country_id"
                  autocomplete="false" />
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="10" :lg="6">
              <el-form-item prop="title" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a group</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col>
            <el-col :sm="24" :md="10" :lg="6">
              <el-form-item prop="sortBy" class="is-no-asterisk" id="sortBy">
                <template slot="label">
                  <span aria-hidden="true">Sort by</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.sortBy"
                  :value="search.sortBy"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :searchable="true"
                  :clearable="true"
                  label="name"
                  inputId="sortBy"
                  autocomplete="false" />
              </el-form-item>
            </el-col>
	          <el-col class="d-flex align-items-center" :sm="24" :md="12" :lg="6">
	           	<span class="reset-btn mt-4 d-inline-block" @click="resetSearch">Reset</span>
	          	<el-button type="primary" class="float-right update-results-btn" @click="searchProjects">
	              Update results
	            </el-button>
	          </el-col>
	        </el-row>
			<el-row class="hidden-md-and-up">
	        	<el-col :sm="24">
              <el-form-item prop="title" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="globalFilter"
                  :value="globalFilter"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="filterOption => filterOption.key"
				          label="name"
                  inputId="view"
                  autocomplete="false"
                  @input="searchProjects"
                  class="half-content" />
              </el-form-item>
	          </el-col>
            <el-col :sm="24">
                <el-form-item prop="subFilter" class="is-no-asterisk">
                  <template slot="label">
                    <span aria-hidden="true">Status</span>
                    <span class="sr-only">Please select a view type</span>
                  </template>
                  <v-select
                    v-model="search.subType"
                    placeholder="Choose from the following"
                    :options="subFilterOptions"
                    :searchable="true"
                    :clearable="false"
                    :reduce="subFilter => subFilter.key"
                    inputId="status"
                    autocomplete="false"
                    label="name"
                    @input="searchProjects"
                    class="half-content" />
                </el-form-item>
	          </el-col>
	         	<el-col :sm="24" class="hidden-md-and-up">
	          	<el-button class="more-filters-btn" size="medium" @click="openProjectsFilter">View More filters</el-button>
	        	</el-col>
	        </el-row>
	      </el-form>
  		</div>
  	</div>

    <div>
      <do-you-have-an-idea></do-you-have-an-idea>
    </div>

  	<div class="zero-data text-center my-5" v-if="showZeroData">
  		<div class="container">
		  	<el-row>
		  		<el-col :sm="24">
	  				<p class="fs-20 my-5">We could not find any projects with your search criteria. Please update your search and try again.</p>
	  			</el-col>
	  		</el-row>
  		</div>
  	</div>
  	<div class="results-section" v-if="projects.length > 0">
	  	<div class="container" id="main-content">
	  		<div class="mt-4">
		  		<el-row>
		  			<project-card
						v-for="project in projects"
						:key="project.id"
						:project="project" />
		  		</el-row>
	  		</div>
	  	</div>
  	</div>

    <div class="pagination-help-section">
        <div class="container">
          <el-button class="load-more-btn" size="medium" @click="fetchProjects" v-if="!onLastPage">Load more</el-button>
        </div>
    </div>

    <projects-filter
      v-if="projectsFilterOpen"
      @close="closeProjectsFilter"
      @search="searchProjects(true)"
      @reset="resetSearch(true)"
      v-model="search"
      :subFilters="subFilterOptions"
      :sortOptions="sortOptions"
      :sectors="sectors"
      :countries="countries"></projects-filter>
  </div>
</template>

<script>
	import axios from 'axios'
	import store from '../../store'
	import router from '../../router'
	import { Loading } from 'element-ui'
	import gsApi from '@/services/gs.api'
	import * as config from '../../config'
	import ProjectCard from '@/components/platform/projects/project-card'
  import ProjectsFilter from '@/components/slide-panel/panels/projects-filter'
  import DoYouHaveAnIdea from "@/components/partials/do-you-have-an-idea"

  export default {
  	name: 'projects',
  	components: {
		  'project-card': ProjectCard,
      'projects-filter': ProjectsFilter,
      DoYouHaveAnIdea
    },
    data() {
      return {
      	mainLoader: false,
      	page: 1,
      	perPage: 6,
      	totalResults: 0,
      	showZeroData: false,
      	onLastPage: false,
      	search: {
          type: 'All',
          subType: 'Active',
          sortBy: null,
      		keyword: '',
      		filter: null,
      		sector: null,
          selectedCountry: null
      	},
        filterOptions: [
          {
          	key: 'All',
          	name: 'All Projects'
          },
          {
          	key: 'Own',
          	name: 'My Projects'
          },
        ],
        subFilterOptions: [
          { key: 'Active', name: 'Active' },
          { key: 'Ended', name: 'Ended' },
        ],
        sortOptions: [
          { id: 1, name: 'Publish date - newest', order_by: 'published_at', order: 'descending'},
          { id: 2, name: 'Publish date - oldest', order_by: 'published_at', order: 'ascending'},
          { id: 3, name: 'End date - closest', order_by: 'challenge_end_at', order: 'ascending'},
          { id: 4, name: 'End date - farthest', order_by: 'challenge_end_at', order: 'descending'},
        ],
        projects: [],
        sectors: [],
        countries: [],
        projectsFilterOpen: false,
        globalFilter: 'All',
      }
    },
    computed: {
      orderBy() {
        return (this.search.sortBy && this.search.sortBy.hasOwnProperty('order_by'))
          ? this.search.sortBy.order_by : 'created_at';
      },
      order() {
        return (this.search.sortBy && this.search.sortBy.hasOwnProperty('order'))
          ? this.search.sortBy.order : 'descending';
      }
    },
    methods: {
      handleGlobalFilter(value){
        this.globalFilter = value;
        this.page = 1
        this.projects = []
        this.fetchProjects()
      },
      handleSubGlobalFilter(value){
        this.search.subType = value;
        this.page = 1
        this.projects = []
        this.fetchProjects()
      },
      getRegisterMeta() {
        this.mainLoader = true

        gsApi.globalscot.meta.index()
          .then(response => {
            this.sectors = response.data.groups.data
            this.countries = response.data.countries.data
            this.fetchProjects()
        }).catch(error => {
		      this.mainLoader = false
          this.$notify.error({title: 'Error', message: 'An unknown error occurred. Please try again later.'})
        })
      },
      resetSearch(isMobile) {
      	if (isMobile) {
      		this.closeProjectsFilter()
      	}

      	this.search = {
          type: 'All',
          subType: this.search.subType,
      		sortBy: null,
      		keyword: '',
      		filter: null,
      		sector: null,
          selectedCountry: null,
      	}

      	this.searchProjects()
      },

      searchProjects(isMobile) {
      	if (isMobile) {
      		this.closeProjectsFilter()
      	}

		    this.page = 1
      	this.projects = []
      	this.fetchProjects()
      },

      fetchProjects() {
      	if (!this.mainLoader) {
      		this.mainLoader = true
      	}

      	let params = {
          order_by: this.orderBy,
          order: this.order,
          page: this.page,
          per_page: this.perPage,
      		group: this.search.sector,
          search: this.search.keyword,
          type_id: this.search.filter,
          country_code: this.search.selectedCountry,
          status_id: this.search.subType === 'Active' ? [1, 2] : [3],
		    }

        if(this.globalFilter === 'Own'){
          params.member = store.state.user.id
        }

		    // temporary code
		    if (this.search.type == 'Spotlight') {
          params.type_id = 2
        } else if (this.search.type == 'News') {
          params.type_id = 0
        }

		    gsApi.projects.search(params)
          .then(response => {
            this.mainLoader = false
            this.totalResults = response.data.meta.pagination.total

            if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
              this.onLastPage = false
              this.page++
            } else {
              this.onLastPage = true
            }

            this.projects = this.projects.concat(response.data.data)

            this.showZeroData = this.projects.length > 0 ? false : true
          }).catch(error => {
            this.mainLoader = false
          })
      },
      openProjectsFilter() {
        this.projectsFilterOpen = true;
      },
      closeProjectsFilter() {
        this.projectsFilterOpen = false;
      },
    },
    created() {
      document.title = this.$route.meta.title;

      /*if (!store.state.user.isLogged) {
          router.push('/login')
      } else if (store.getters.isTrader) {
          router.push('/feed')
      }*/
      if (store.state.user.isLogged){
        if (store.getters.isTrader){router.push('/feed')}
        else{this.getRegisterMeta()}
      }
    },
  }
</script>

<style lang="scss">
.project-global-filters .search-btn-container {
  margin-top: 0;
  .search-buttons {
    .search-btn {
      background-color: #EAEAEA;
      border-radius: 14px;
      margin-right: 14px;
    }
    .active-filter {
      background: $primary;
      border-radius: 14px;
    }
    .active-filter p {
      color: #FFFFFF;
    }
  }
}

.active-ended-filters .search-btn-container {
  margin-top: 0;
  .search-buttons {
    .search-btn {
      background-color: #EAEAEA;
      border-radius: 14px;
      margin-right: 14px;
    }
    .active-filter {
      background: #701257;
      border-radius: 14px;
    }
    .active-filter p {
      color: #FFFFFF;
    }
  }
}
</style>
