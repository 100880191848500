<template>
  <div class="purple-help-section">
    <div class="get-in-touch">
      <div class="get-in-touch-text">
        <h1>Need to get in touch with the GlobalScot Team?</h1>
      </div>
      <div>
        <a :href="getCMSRoute('contact-us')">
          <el-button
            type="primary"
            class="btn-text get-in-contact-button"
            size="small"
            >Contact GlobalScot Team</el-button
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
