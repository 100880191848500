<template>
  <div class="validation-errors-block" id="formErrorBlock">
    <h2>There is a problem</h2>
    <el-button
      v-for="(error, index) in errors"
      :key="index"
      type="text"
      @click="scrollTo(error.id)">
      <span>{{ error.message }}</span>
    </el-button>
  </div>
</template>

<script>
  export default {
    props: ['errors'],
    methods: {
      scrollTo(id) {
        let label = document.querySelector(`label[for='${id}']`);
        label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        let input = document.getElementById(id)
        if(input){
          input.focus()
        }
      }
    }
  }
</script>
