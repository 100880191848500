<template>
	<div class="col-md-24 col-lg-20 mb-4">
	  <div class="address-details se-lead-paragraph-l">
	    <span class="color-primary">
	      {{ business.name }}
	    </span>
	  </div>

	  <div class="d-flex edit-company-actions">
	    <el-button
	      @click="businessEditToggle()"
	      type="primary">
	      <span v-if="!editMode">Edit details</span>
	      <span v-else>Save details</span>
	    </el-button>
	    <el-button @click="removeFromBusinesses()">
	      <span v-if="!editMode">Remove</span>
	      <span v-else>Cancel</span>
	    </el-button>
	  </div>

	  <el-form :model="editableBusiness" :rules="rules" ref="otherBusinessForm" v-show="editMode" id="otherBusinessForm" @submit.native.prevent>
	    <div class="address-details mb-3 mt-4">
	      <div class="row">
	        <div class="col-md-24 col-lg-20">
	          <el-form-item label="Name of company" prop="other_business_name">
	            <el-input
                v-model.trim="editableBusiness.name"
                id="other_business_name"
                maxlength="255"
                autocomplete="false"
                label="Please enter company name in this field"
                aria-required="true" />
	          </el-form-item>
	        </div>
	      </div>
	    </div>
	  </el-form>
	</div>
</template>

<script>
export default {
  name: 'manage-other-business',
  data () {
    return {
      editMode: false,
      editableBusiness: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: 'Name is required', trigger: 'blur' },
          { max: 255, message: 'Name may not be greater than 255 characters', trigger: 'change' },
        ],
      }
    }
  },
  props: {
    business: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    businessEditToggle () {
      if (this.editMode) {
        this.$refs['otherBusinessForm'].validate((valid) => {
          if (valid) {
            this.editMode = false
            this.$emit('update-other-business', this.editableBusiness)
            this.$alert('Company has been updated.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$nextTick(() => {
              document.getElementById("otherBusinessForm").getElementsByClassName('is-error')[0].scrollIntoView()
              this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
          }
        });
      } else {
        this.editMode = true
      }
    },
    setEditableBusiness () {
      this.editableBusiness.name = this.business.name
    },
    removeFromBusinesses() {
      if (this.editMode) {
        this.setEditableBusiness()
      } else {
        this.$emit('remove-other-business', this.editableBusiness)
      }

      this.editMode = false
    },
  },
  created () {
    this.setEditableBusiness()
  }
}
</script>
