<template>
    <div id="main-panel" :class="{ 'mobile-slide-panel': isMobile, 'desktop-slide-panel': !isMobile, 'slide-left': !slideRight, 'slide-right': slideRight }" class="slide-panel">
      <div class="slide-panel-header justify-content-between d-flex mb-3" v-if="closeAsText">
        <h1>{{ title }}</h1>
        <a href="/close-notifications" id="slide-panel-close" class="close-as-text" @click.prevent="closeMenu()">Close</a>
      </div>
      <div class="slide-panel-header-small justify-content-between d-flex mb-3" v-else>
        <div class="d-flex align-items-center">
          <el-badge v-if="isNotification" :class="{'zero-notifications': !$store.state.unreadNots}" :value="$store.state.unreadNots" :max="99">
             <span aria-live="assertive" class="sr-only"> {{$store.state.unreadNots}} Notification unread </span>
            <svg-notification class="notification-icon"></svg-notification>
          </el-badge>
          <h1>{{ title }}</h1>
        </div>
        <a href="/close-notifications" id="slide-panel-close" @click.prevent="closeMenu()"><svg-close/></a>
      </div>

      <slot></slot>
    </div>
</template>

<script>
  import SvgCloseCross from '@/components/assets/svg-close-cross'
  import Notification from '@/components/assets/svg-notification'

  export default {
  	name: 'slide-panel',
    components: {
      'svg-close': SvgCloseCross,
      'svg-notification': Notification
    },
    props: {
      title: {
        type: String,
        required: true
      },
      isMobile: {
        type: Boolean,
        default: true
      },
      slideRight: {
        type: Boolean,
        default: false
      },
      closeAsText: {
        type: Boolean,
        default: true
      },
      isNotification: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        overlay: this.createOverlay(),
      }
    },
    methods: {
      createOverlay() {
        let overlay = document.createElement('div')
        overlay.setAttribute('class', 'slide-panel-overlay')
        overlay.addEventListener('click', () => {
          this.closeMenu()
        })

        return overlay
      },
      showOverlay() {
        document.body.appendChild(this.overlay)
      },
      hideOverlay() {
        document.body.removeChild(this.overlay)
      },
      closeMenu() {
        if (this.slideRight) {
          var element = document.getElementById("main-panel");
          element.classList.remove("slide-right-now");
        } else {
          var element = document.getElementById("main-panel");
          element.classList.remove("slide-left-now");
        }

        setTimeout(() => {
          this.$emit('panelclose')
        }, 200)
      },
    },
    beforeDestroy() {
      this.hideOverlay()
    },
    mounted() {
      document.getElementById("slide-panel-close").focus();

      setTimeout(() => {
        if (this.slideRight) {
          var element = document.getElementById("main-panel");
          element.classList.add("slide-right-now");
        } else {
          var element = document.getElementById("main-panel");
          element.classList.add("slide-left-now");
        }
      }, 10)
    },
    created() {
      this.showOverlay()
    }
  }
</script>
