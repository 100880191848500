<template>
  <focus-lock>
    <el-dialog
      ref="mediaDialog" @opened="dialogOpen('mediaDialog')"
      custom-class="cropper-dialog"
      :close-on-click-modal="false"
      :before-close="onCropperDialogClose"
      :show-close="false"
      :visible.sync="visible">
      <el-row>
          <el-col :sm="14" :md="16" :lg="18" class="text-center mb-2">
            <vue-cropper
              v-if="cropperFile"
              :src="cropperFile.url"
              ref="cropper"
              alt="Source Image"
              :aspect-ratio="aspectRatio"
              preview=".preview"
            >
            </vue-cropper>
          </el-col>
          <el-col :sm="10" :md="8" :lg="6" class="text-center">
            <section class="preview-area">
              <p><b>Preview</b></p>
                <div :class="previewClass" >
                <div class="preview"></div>
              </div>
            </section>
          </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer d-flex justify-content-end">
          <el-button type="secondary" @click="onCropperDialogClose">Cancel</el-button>
          <el-button v-loading.lock="fileUploading" type="primary"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          :disabled="fileUploading"
          @click="upload">Upload</el-button>
      </span>
    </el-dialog>
  </focus-lock>
</template>

<script>
import VueCropper from 'vue-cropperjs'

export default {
  components: {
    VueCropper,
  },
  props: {
    cropperFile: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    aspectRatio:{
      type: Number,
      default:1
    },
    previewClass:{
      type:String,
      default: 'preview-outer'
    }
  },
  data() {
    return {
      fileUploading: false
    };
  },
  methods: {
    onCropperDialogClose() {
      this.$emit("close");
    },
    upload() {
      this.fileUploading = true

      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit("upload", {'file': blob, 'url': this.$refs.cropper.getCroppedCanvas().toDataURL()})
      })
    }
  }
};
</script>

<style lang="scss">
.preview-outer {
  height: 140px;
  width: 140px;
  line-height: 140px;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  background: #C0C4CC;
  font-size: 14px;
  overflow: hidden;
  &.rectangle{
    height: 80px;
    width: 140px;
    border-radius:0
  }
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview {
  width: 140px;
  height: 140px;
  object-fit: cover;
  display: block;
  vertical-align: middle;
}
</style>
