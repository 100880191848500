<template>
  <div v-loading.fullscreen.lock="loading">
      <ul class="recent-activity-buttons" ref="recent-activity-buttons">
        <li v-if="isProfileOwner">
          <el-button
            v-bind:class="{ 'active-state': currentTabName === helpRequestsTab.name }"
            class="search-btn"
            @click="currentTabName = helpRequestsTab.name">
            <p>Help Requests</p>
          </el-button>
        </li>
        <li>
          <el-button
            v-bind:class="{ 'active-state': currentTabName === recentActivityTab.name }"
            class="search-btn"
            @click="currentTabName = recentActivityTab.name">
            <p>Activity history</p>
          </el-button>
        </li>
      <li v-if="isProfileOwner && currentTabName === helpRequestsTab.name" class="w-100 w-100 d-flex justify-content-end view-page-link">
        <router-link class="py-3 " if="scope.row.chat_id != null && scope.row.status != 1" to="/chats">
          Go to message <svg-purple-arrow-right class="arrow" />
          <span class="sr-only">Go to message</span>
        </router-link>
      </li>
      </ul>

      <el-row v-if="isProfileOwner && currentTabName === helpRequestsTab.name">
        <member-helprequest-table></member-helprequest-table>
      </el-row>

      <el-row v-if="currentTabName === recentActivityTab.name">
        <recent-activity :isProfileOwner="isProfileOwner"></recent-activity>
      </el-row>

  </div>
</template>

<script>
import RecentActivity from './recent-activity'
import MemberHelprequestTable from './member-helprequest-table';
import SvgPurpleArrowRight from '@/components/assets/svg-purple-arrow-right'
export default {
  name: 'member-activity',
  props: {
    isProfileOwner: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecentActivity,
    MemberHelprequestTable,
    SvgPurpleArrowRight
  },
  created(){
    this.currentTabName = this.isProfileOwner ? "helpRequests" : "recentActivity" 
  },
  data() {
    return {
      loading: false,
      helpRequestsTab: {
        name: 'helpRequests',
        data:[]
      },
      recentActivityTab: {
        name: 'recentActivity',
      },
      currentTabName: 'helpRequests',
    };
  },

};
</script>
