<template>
  <div class="news-page body__content" v-loading.fullscreen.lock="mainLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
  	<div class="search-section">
  		<div class="container">
  			<el-row>
	  			<el-col :sm="24">
		  			<h1>Events & Webinars</h1>
            <el-col>
              <div class="search-btn-container hidden-sm-and-down">
                <ul class="search-buttons">
                  <li v-for="(sort, index) in sorts" >
                    <el-button
                      v-bind:class="{ 'active-state': search.type == sort.label }"
                      class="search-btn"
                      @click="handleSortChange(sort)">
                      <p>{{sort.name}}</p>
                    </el-button>
                  </li>
                </ul>
              </div>

              <div class="search-btn-container hidden-sm-and-down" v-if="search.type == 'Event'">
                <ul class="sub-search-buttons">
                  <li v-for="(eventSort, index) in eventSorts" >
                    <el-button
                      v-bind:class="{ 'active-state': search.eventType == eventSort.label }"
                      class="search-btn"
                      @click="handleEventFilterChange(eventSort)">
                      <p>{{eventSort.name}}</p>
                    </el-button>
                  </li>
                </ul>
              </div>

              <div class="search-btn-container hidden-sm-and-down" v-if="search.type == 'Webinar'">
                <ul class="sub-search-buttons">
                  <li v-for="(webinarSort, index) in webinarSorts" >
                    <el-button
                      v-bind:class="{ 'active-state': search.webinarType == webinarSort.label }"
                      class="search-btn"
                      @click="handleWebinarSortChange(webinarSort)">
                      <p>{{webinarSort.name}}</p>
                    </el-button>
                  </li>
                </ul>
              </div>
            </el-col>
					</el-col>
				</el-row>
			  <el-form id="searchForm" :model="search" ref="searchForm" @submit.native.prevent>
	        <el-row>
	          <el-col :sm="24" :md="12">
	            <el-form-item label="Keyword search" prop="keyword" class="hidden-sm-and-down">
		            <el-input v-model="search.keyword"
		                      placeholder="Search for keywords, location, people..."
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          	<el-form-item label="" prop="keyword" class="hidden-md-and-up">
		            <el-input v-model="search.keyword"
							  @keyup.enter.native="searchEvents"
		                      placeholder="Search for keywords, location, people..."
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" >
              <div class="search-events">
	            <el-button class="search-events-button" type="primary" @click="searchEvents">
	              Search
	            </el-button>
              </div>
	          </el-col>
	        </el-row>
	        <el-row class="hidden-sm-and-down">
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sortby" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sort by</span>
                  <span class="sr-only">Choose from the following</span>
                </template>
                <v-select
                  v-model="search.sortby"
                  placeholder="Choose from the following"
                  :options="sortOptions"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sortOption => sortOption.name"
                  inputId="sortby"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a group</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
	          	<el-button type="primary" class="float-right update-results-btn" @click="searchEvents">
	              Update results
	            </el-button>

	           	<el-button type="text" class="reset-btn float-right" @click="resetSearch" >Reset</el-button>

	          </el-col>
	        </el-row>
			<el-row class="hidden-md-and-up">
	        	<el-col :sm="24">
              <el-form-item prop="view" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="search.type"
                  :value="search.type"
                  placeholder="Choose from the following"
                  :options="filterOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="filterOption => filterOption.key"
				          label="name"
                  inputId="view"
                  autocomplete="false"
                  @input="searchEvents"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col>
	         	<el-col :sm="24" class="hidden-md-and-up">
	          	<el-button class="more-filters-btn" size="medium" @click="openEventsFilter">View More filters</el-button>
	        	</el-col>
	        </el-row>
	      </el-form>
  		</div>
  	</div>

  	<div class="zero-data text-center mt-5" v-if="showZeroData">
  		<div class="container">
		  	<el-row>
		  		<el-col :sm="24">
	  				<p>We could not find any events, webinars or podcasts with your search criteria. Please update your search and try again.</p>
	  			</el-col>
	  		</el-row>
  		</div>
  	</div>

  	<div id="main-content" class="results-section" v-if="events.length > 0">
	  	<div class="container">
	  		<div class="mt-4">
		  		<el-row>
		  			<events-card
						v-for="event in events"
						:key="event.id"
						:event="event" />
		  		</el-row>
	  		</div>
	  	</div>
  	</div>

    <div class="pagination-help-section">
        <div class="container">
          <el-button class="load-more-btn" size="medium" @click="fetchEvents" v-if="!onLastPage">Load more</el-button>
        </div>
    </div>

    <news-filter v-if="eventsFilterOpen" @close="closeEventsFilter" @search="searchEvents(true)" @reset="resetSearch(true)" v-model="search" :sectors="sectors" :sortOptions="sortOptions"></news-filter>
  </div>
</template>

<script>
	import store from '../../store'
	import router from '../../router'
	import { Loading } from 'element-ui'
	import gsApi from '@/services/gs.api'
	import * as config from '../../config'
	import EventsCard from '@/components/platform/events-webinars/events-card'
	import NewsFilter from '@/components/slide-panel/panels/news-filter'

  export default {
    name: 'events-and-webinars',
    metaInfo: {
      title: "Events & webinars"
    },
  	components: {
		  'events-card': EventsCard,
		  'news-filter': NewsFilter
    },
    data() {
      return {
      	mainLoader: false,
      	page: 1,
      	perPage: 6,
      	totalResults: 0,
      	showZeroData: false,
      	onLastPage: false,
      	search: {
          type: 'All',
          webinarType: 4,
          eventType: 'all',
          sortby: null,
      		keyword: '',
      		filter: null,
      		sector: null,
      	},
        filterOptions: [
          {
            key: 'All',
            name: 'All'
          },
          {
          	key: 'Event',
          	name: 'Events'
          },
          {
          	key: 'Webinar',
          	name: 'Webinars'
          },
          {
            key: 'Podcast',
            name: 'Podcasts'
          },
        ],
        sortOptions: [
          {
          	key: 0,
          	name: 'A-Z'
          },
          {
          	key: 1,
          	name: 'Z-A'
          },
          {
          	key: 2,
          	name: 'Latest'
          }
        ],
        events: [],
        sectors: [],
        userTypeGroups: [],
        eventsFilterOpen: false,
        sorts: [
          {label: "All", name: "All"},
          {label: "Event", name: "Events"},
          {label: "Webinar", name: "Webinars"},
          {label: "Podcast", name: "Podcasts"}
        ],
        webinarSorts: [ // TODO: get form meta endpoint
          {label: 4, name: "Upcoming"},
          {label: 5, name: "Watch Now"},
        ],
        eventSorts: [
          {label: "all", name: "All events"},
          {label: "my", name: "My events"},
        ],
      }
    },
    methods: {
      getRegisterMeta() {
        this.mainLoader = true

        gsApi.globalscot.meta.index()
          .then(response => {
            this.sectors = response.data.groups.data
            this.userTypeGroups = response.data.userTypeGroups.data
            this.fetchEvents()
        }).catch(error => {
		      this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
      },
      resetSearch(isMobile) {
      	if (isMobile) {
      		this.closeEventsFilter()
      	}

      	this.search = {
          type: 'All',
      		sortby: null,
      		keyword: '',
      		filter: null,
      		sector: null,
          eventType: 'all',
        }

      	this.searchEvents()
      },

      searchEvents(isMobile) {
      	if (isMobile) {
      		this.closeEventsFilter()
      	}

		    this.page = 1
      	this.events = []
      	this.fetchEvents()
      },
      fetchEvents() {
      	if (!this.mainLoader) {
      		this.mainLoader = true
      	}

      	const webinar_sort = this.search.type === 'Webinar' ? this.search.webinarType : null;
      	const registered_user_id = (this.search.type === 'Event' && this.search.eventType === 'my') ? this.$store.state.user.id : null;

      	let params = {
          page: this.page,
          per_page: this.perPage,
      		group: this.search.sector ? [this.search.sector] : null,
          search: this.search.keyword,
          type_id: this.search.filter,
          sort_by: this.search.sortby,
          webinar_sort: webinar_sort,
          status_id: 1,
          registered_user_id: registered_user_id,
          user_type_group_id: this.getUserTypeGroup()
		    }

		    // temporary code
		    if (this.search.type == 'Event') {
          params.type_id = 0
        } else if (this.search.type == 'Webinar') {
          params.type_id = 1
        } else if (this.search.type == 'Podcast') {
          params.type_id = 2
        }

		    gsApi.events.search(params)
          .then(response => {
            this.mainLoader = false

            this.totalResults = response.data.meta.pagination.total

            if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
              this.onLastPage = false
              this.page++
            } else {
              this.onLastPage = true
            }

            this.events = this.events.concat(response.data.data)

            this.showZeroData = this.events.length > 0 ? false : true
          }).catch(error => {
            this.mainLoader = false
          })
      },
      getUserTypeGroup(){
        
        let role = this.$store.state.user.roles.length ? this.$store.state.user.roles[0] : null;
        let groupName = "GlobalScot"
        if(role == "trader")
        {
          groupName = "Member"
        }else if (role == "staff"){
          groupName = "Team Scotland"
        }

        const group = this.userTypeGroups.find(g=>g.name == groupName);
        return group ? [group.id] : null;

      },
      openEventsFilter() {
        this.eventsFilterOpen = true;
      },
      closeEventsFilter() {
        this.eventsFilterOpen = false;
      },
      handleSortChange(sort) {
        this.search.type = sort.label
        this.searchEvents()
      },
      handleWebinarSortChange(sort) {
        this.search.webinarType = sort.label
        this.searchEvents()
      },
      handleEventFilterChange(sort) {
        this.search.eventType = sort.label;
        this.searchEvents();
      }
    },
    created() {
      /*if (!store.state.user.isLogged) {
          router.push('/login')
      }*/
      if(store.state.user.isLogged){
        this.getRegisterMeta()
      }
    },
  }
</script>
<style lang="scss">
.search-btn:hover {
  background: transparent !important;
  color: black !important;
}
</style>
