<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <div class="whosin-heading-text">
          <h1 class="whosin-title">{{whosInText.heading}}</h1>
          <p class="whos-in-sub">{{whosInText.sub}}</p>
          <div>
            <member-card v-for="member in members" :key="member.id" :member="member" :showProfileLink="false" />
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import MemberCard from "@/components/platform/members/member-card";
export default {
  components: {
    MemberCard
  },
  data() {
    return {
      whosInText: {
        heading: "See who's in the network",
        sub:
          "Achieving your ambition is easier with the right contacts. Have a look at some of the GlobalScots you could connect with."
      }
    };
  },
  props: ["members"]
};
</script>
