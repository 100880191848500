<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <el-col>
          <h1 class="involve-title text-center">
            {{ text.heading }}
          </h1>
        </el-col>
        <el-col>
          <p class="involve-sub-title">
            We know you’re busy and want to offer the most value with the time that you have. You can choose who to help, the level of support that you offer and how long you commit. Here are a few ways you could help Scottish companies.
          </p>
        </el-col>
      </el-row>
      <what-you-get />
      <el-row>
        <div class="involve-heading-text mt-5">
          <div class="involve-data">
            <div>
              <el-col
                class
                :md="24"
                :lg="12"
              >
                <div class="left-text">
                  <p class="involve-sub-text">
                    {{ text.listStart }}
                  </p>
                  <div class>
                    <ul class="involve-list">
                      <li
                        v-for="point in bulletPoints"
                        :key="point.text"
                      >
                        {{ point.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </div>
            <div>
              <el-col
                :md="24"
                :lg="12"
              >
                <div class="invlove-img mt-4">
                  <img
                    alt=""
                    src="/assets/img/radred.png"
                  />
                </div>
              </el-col>
            </div>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import WhatYouGet from "@/components/platform/home/what-you-get";
export default {
  components: {
    WhatYouGet
  },
  data() {
    return {
      text: {
        heading: "What's involved?",
        listStart: "Many of our GlobalScots also:"
      },
      bulletPoints: [
        { text: "Offer strategic insights to the wider Scottish ecosystem including Scottish Development International, the Scottish Government, accelerators, universities and more" },
        { text: "Promote Scotland to international businesses as an attractive business location" },
        { text: "Speak at events, on webinars or on podcasts" },
        { text: "Publish market insights and reports"}
      ]
    };
  }
};
</script>
<style>
.left-text {
  margin-bottom: 50px;
}
</style>
