<template>
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.395 23.2817C14.3949 23.6336 14.4641 23.982 14.5987 24.3071C14.7333 24.6322 14.9306 24.9275 15.1794 25.1763C15.4282 25.4251 15.7236 25.6225 16.0487 25.7571C16.3738 25.8917 16.7222 25.9608 17.074 25.9607H19.753L27.79 33.9978V25.9568H46.543C47.2535 25.9565 47.9347 25.674 48.4371 25.1716C48.9394 24.6693 49.2218 23.988 49.222 23.2776V4.52564C49.2218 3.8152 48.9394 3.13394 48.4371 2.63159C47.9347 2.12924 47.2535 1.84694 46.543 1.84668H17.074C16.7222 1.84655 16.3738 1.91571 16.0487 2.05029C15.7236 2.18488 15.4282 2.38231 15.1794 2.6311C14.9306 2.8799 14.7333 3.17515 14.5987 3.50024C14.4641 3.82533 14.3949 4.17379 14.395 4.52564V23.2817Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.9 44.7517C55.5987 44.7517 58.597 41.7533 58.597 38.0547C58.597 34.356 55.5987 31.3577 51.9 31.3577C48.2014 31.3577 45.203 34.356 45.203 38.0547C45.203 41.7533 48.2014 44.7517 51.9 44.7517Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.616 55.4697H41.184C41.184 52.6277 42.313 49.902 44.3226 47.8923C46.3322 45.8827 49.0579 44.7537 51.9 44.7537C54.742 44.7537 57.4677 45.8827 59.4773 47.8923C61.487 49.902 62.616 52.6277 62.616 55.4697V55.4697Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7151 44.7517C15.4137 44.7517 18.4121 41.7533 18.4121 38.0547C18.4121 34.356 15.4137 31.3577 11.7151 31.3577C8.01642 31.3577 5.01807 34.356 5.01807 38.0547C5.01807 41.7533 8.01642 44.7517 11.7151 44.7517Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.432 55.4697H1C1 52.6277 2.129 49.902 4.13864 47.8923C6.14828 45.8827 8.87394 44.7537 11.716 44.7537C14.5581 44.7537 17.2837 45.8827 19.2934 47.8923C21.303 49.902 22.432 52.6277 22.432 55.4697V55.4697Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.808 52.7888C35.5066 52.7888 38.505 49.7904 38.505 46.0918C38.505 42.3931 35.5066 39.3948 31.808 39.3948C28.1093 39.3948 25.111 42.3931 25.111 46.0918C25.111 49.7904 28.1093 52.7888 31.808 52.7888Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.524 63.5059H21.092C21.092 60.6638 22.221 57.9381 24.2307 55.9285C26.2403 53.9188 28.966 52.7898 31.808 52.7898C34.6501 52.7898 37.3758 53.9188 39.3854 55.9285C41.395 57.9381 42.524 60.6638 42.524 63.5059V63.5059Z"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.432 7.20874H41.101"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.432 12.5657H41.101"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.432 17.9236H41.101"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
