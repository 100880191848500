var render = function render(){var _vm=this,_c=_vm._self._c;return _c('focus-lock',[_c('el-dialog',{attrs:{"show-close":true,"visible":_vm.dialogVisible,"custom-class":"multi-factor-dialog","close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":function($event){return _vm.onClose()}}},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"mb-5"},[_vm._v("How would you like to authenticate ?")]),_vm._l((_vm.availableMethods),function(item,index){return _c('div',{key:index,staticClass:"w-100 d-flex box-selector p-2 justify-content-center align-items-center",class:{
          'is-first': index == 0,
          'is-last': index == _vm.availableMethods.length - 1,
        },on:{"click":function($event){return _vm.$emit('on-select-multi-factor-method', item)}}},[_c(item.two_factor_type == _vm.enumHelper.MFAType.GOOGLE
              ? 'svg-google-authentication'
              : item.two_factor_type == _vm.enumHelper.MFAType.PHONE
              ? 'svg-phone-authentication'
              : 'svg-mail-authentication',{tag:"component",attrs:{"width":"24","height":"24"}}),_c('p',{staticClass:"w-100 text-left ml-3"},[_vm._v(" "+_vm._s(_vm.getLabelText(item))+" ")]),(item.is_default)?_c('i',{staticClass:"el-icon-success text-success"}):_vm._e()],1)}),_c('el-button',{staticClass:"mt-5 w-50 mx-0 mr-1",on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" Go Back ")]),_c('p',{staticClass:"my-2 text-left w-100 small"},[_c('a',{staticClass:"linkified-underline",attrs:{"href":_vm.getCMSRoute('contact-us')}},[_vm._v(" Need Help? ")])])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }