<template>
  <div
    class="cluster-member-card cluster-info-window-card box-card text-center"
  >
    <div class="members-page body__content" v-loading.lock="loading">
      <div v-for="(member, index) in members" :key="index">
        <hr class="cluster-hr" v-if="index > 0" />
        <div class="d-flex justify-content-between">
          <div class="avatar">
            <user-avatar
              shape="circle"
              :isGlobalScot="helpers.isExpert(member)"
              :authorExist="!!member"
              width="40px"
              :size="40"
              :src="member.avatar"
              :alt="member ? member.full_name : '' + 'Profile picture'"
            ></user-avatar>
          </div>
          <div class="name-position">
            <div class="d-flex">
              <h2 class="text-truncate">{{ member.full_name }}</h2>
            </div>

            <div class="d-flex">
              <p class="text-truncate">{{ companyPositionText(member) }}</p>
              <div class="vertical-line">
                <svg-vertical-line
                  aria-labeledby="vertical line"
                ></svg-vertical-line>
              </div>
              <p class="text-truncate">{{ companyNameText(member) }}</p>
            </div>
          </div>
          <div class="view-profile-link hidden-sm-and-down">
            <div @click="goToProfile(member)">
              View profile
              <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
            </div>
          </div>
        </div>
        <div class="view-profile-link hidden-md-and-up">
          <div @click="goToProfile(member)">
            View profile
            <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
          </div>
        </div>
      </div>

      <div></div>

      <div class="info-close" @click="closeInfoWindow()">
        <svg-info-close></svg-info-close>
      </div>
      <div v-if="showPagination" class="pagination-container">
        <el-button
          class="load-more-btn inline-btn"
          size="medium"
          @click="loadPrev"
          :disabled="disablePrev"
          >Previous</el-button
        >
        <el-button
          class="load-more-btn inline-btn"
          size="medium"
          @click="loadNext"
          :disabled="disableNext"
          >Next</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import gsApi from "@/services/gs.api";
import InfoClose from "@/components/assets/svg-info-close";
import MapPinGradient from "@/components/assets/svg-map-pin-gradient";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import VerticalLine from "@/components/assets/svg-vertical-line";
import UserAvatar from "@/components/core/user-avatar";
import helpers from "@/helpers/index";
export default {
  name: "member-infowindow",
  components: {
    "svg-info-close": InfoClose,
    "svg-map-pin-gradient": MapPinGradient,
    "svg-purple-arrow-right": PurpleArrowRight,
    "svg-vertical-line": VerticalLine,
    UserAvatar
  },
  props: {
    stateIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      helpers,
      loading: true,
      members: [],
      meta: {
        per_page: 5,
        page: 1
      },
      pagination: {}
    };
  },
  computed: {
    disableNext() {
      return this.pagination.total_pages === this.pagination.current_page;
    },
    disablePrev() {
      return this.pagination.current_page === 1;
    },
    showPagination() {
      return this.pagination.total > this.pagination.per_page;
    }
  },
  methods: {
    getClusterMembers() {
      this.loading = true;

      let params = {
        per_page: this.meta.per_page,
        page: this.meta.page,
        state_ids: this.stateIds
      }

      gsApi.members
        .membersForStates(params)
        .then(response => {
          this.members = response.data.data;
          this.updatePagination(response.data.meta.pagination);
          this.loading = false;
          this.$emit("realigninfowindow");
        })
        .catch(error => {
          this.closeInfoWindow();
        });
    },
    companyPositionText(member) {
      let primary = member.businesses.data.find(b => b.is_primary);
      primary = primary ? primary : member.businesses.data[0];
      //console.log(primary)

      return primary.role;
    },

    companyNameText(member) {
      let primary = member.businesses.data.find(b => b.is_primary);
      primary = primary ? primary : member.businesses.data[0];
      return primary.name;
    },
    closeInfoWindow() {
      this.$emit("closeinfowindow");
    },
    goToProfile(member) {
      if (member.suspended) {
        this.$alert("User is not currently active.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
        return;
      } else {
        router.push({
          name: "GlobalScot Profile",
          params: { id: member.id, showBackToSearch: true }
        });
      }
    },
    updatePagination(pagination) {
      this.$set(this, "pagination", pagination);
    },
    loadNext() {
      this.meta.page++;
      this.getClusterMembers();
    },
    loadPrev() {
      this.meta.page--;
      this.getClusterMembers();
    }
  },
  created() {
    this.getClusterMembers();
  }
};
</script>
<style>
.vertical-line {
  margin: 10px;
}
</style>
