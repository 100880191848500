var render = function render(){var _vm=this,_c=_vm._self._c;return _c('slide-panel',{attrs:{"title":"Menu"},on:{"panelclose":_vm.closeMenu}},[_c('div',{staticClass:"hr mb-3"}),_c('div',{staticClass:"profile-section justify-content-between d-flex",on:{"click":function($event){return _vm.goToProfile()}}}),_c('ul',{staticClass:"menu-items mt-3"},[_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'Home', params: { isActiveFilter: 1 } }}},[_vm._v(" "+_vm._s(_vm.user.isLogged ? "Feed" : "Home")+" ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'About Network' }}},[_vm._v(" About Network ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'Become GlobalScot' }}},[_vm._v(" Become a GlobalScot ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'About' }}},[_vm._v(" About our organisation ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'Case Studies Public' }}},[_vm._v(" Case studies ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'Business Events Webinars' }}},[_vm._v(" Events & webinars ")])],1),_c('li',{staticClass:"el-menu-item",on:{"click":_vm.closeMenu}},[_c('a',{attrs:{"href":_vm.getCMSRoute('contact-us')}},[_vm._v(" Contact us ")])])]),_c('div',{staticClass:"hr mt-3 mb-3"}),_c('ul',{staticClass:"menu-links"},[_c('li',{on:{"click":_vm.closeMenu}},[(
          !_vm.user.isLogged &&
          !_vm.userRequiresVerification &&
          _vm.$route.path !== '/sign-up-select' &&
          _vm.$route.name !== 'NotFound' &&
          _vm.$route.name !== 'Reset Password'
        )?_c('router-link',{staticClass:"el-button el-button--primary el-button--medium",attrs:{"to":"/sign-up-select","tabindex":"0"}},[_vm._v(" Register ")]):_vm._e()],1),_c('li',{on:{"click":_vm.closeMenu}},[(
          !_vm.user.isLogged &&
          !_vm.userRequiresVerification &&
          _vm.$route.path !== '/login' &&
          _vm.$route.name !== 'NotFound' &&
          _vm.$route.name !== 'Reset Password'
        )?_c('a',{staticClass:"el-button el-button--medium",attrs:{"role":"button","tabindex":"0"},on:{"click":_vm.redirectToLogin}},[_vm._v(" Log in ")]):_vm._e()]),_c('li',{on:{"click":_vm.closeMenu}},[(
          (_vm.user.isLogged || _vm.userRequiresVerification) &&
          _vm.$route.name !== 'Reset Password'
        )?_c('el-button',{staticClass:"el-button el-button--medium",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.logout($event)}}},[_vm._v(" Logout ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }