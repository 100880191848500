<template>
  <div >
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
  name: 'map-loader',
  props: {
    mapConfig: Object,
    apiKey: String
  },

  data() {
    return {
      google: null,
      map: null
    };
  },

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    });
    this.google = googleMapApi;
    this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;

      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      this.$emit('maploaded', {map: this.map})

    }
  }
};
</script>

<style scoped>
  .google-map {
    width: 100%;
    min-height: 850px;
    position: relative;
    top: 23px;
  }
</style>
