import { render, staticRenderFns } from "./other-way-you-can-help.vue?vue&type=template&id=3381ae5e"
import script from "./other-way-you-can-help.vue?vue&type=script&lang=js"
export * from "./other-way-you-can-help.vue?vue&type=script&lang=js"
import style0 from "./other-way-you-can-help.vue?vue&type=style&index=0&id=3381ae5e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports