<template><svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4.5C12 2.566 17.148 1 23 1V17.5C17.148 17.5 12 19.066 12 21C12 19.066 6.851 17.5 1 17.5V1C6.851 1 12 2.566 12 4.5Z" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 4.5V20.875" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 6.73999C7.40298 6.02095 5.21445 5.60345 3 5.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 9.73999C7.40298 9.02095 5.21445 8.60345 3 8.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 12.74C7.40298 12.0209 5.21445 11.6035 3 11.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 15.74C7.40298 15.0209 5.21445 14.6035 3 14.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 12.74C16.597 12.0211 18.7856 11.6036 21 11.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 15.74C16.597 15.0211 18.7856 14.6036 21 14.5" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 1V9.5L19 7.5L17 9.5V1.5" stroke="#AF006E" stroke-width="1.5" stroke-linejoin="round"/>
</svg></template><script>export default {}</script>
