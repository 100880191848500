<template>
  <footer class="footer-section">
    <div class="purple-bar">
      <div class="container">
        <el-row>
          <el-col :sm="24" :md="12" class="left-feed-back-link">
            <a href="https://www.surveymonkey.co.uk/r/GSBETA" target="_blank" aria-label="Opens in a new tab">
              Give feedback about this website
              <svg-external-white class="external-link"></svg-external-white>
            </a>
          </el-col>
          <el-col :sm="24" :md="12" class="right-feed-back-link">
            <a href="https://www.surveymonkey.co.uk/r/GSBETA" target="_blank" aria-label="Opens in a new tab">
              Is there something wrong with this page?
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container footer-inner">
      <el-row>
        <el-col :sm="24" :md="12">
          <ul class="footer-links">
            <li>
              <a :href="getCMSRoute('get-support-for-your-business')">
                Get support for your business
              </a>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'Signup Select Type' }">
                Register
              </router-link>
            </li> -->
            <!-- 
              <li>
                <a :href="getCMSRoute('become-a-globalscot')">
                  How to become a GlobalScot
                </a>
              </li>
            -->
            <li>
              <a
                target="_blank"
                rel="noopener noreferer"
                href="https://support.globalscot.com/portal/en-gb/kb/globalscot"
                aria-label="Opens in a new tab"
              >
                FAQs
              </a>
            </li>
          </ul>
        </el-col>
        <el-col :sm="24" :md="12">
          <div class="social-icons">
            <a
              aria-label="facebook icon"
              href="https://www.facebook.com/scottishenterprise"
              target="_blank"
            >
              <svg-facebook
                class="social-icon"
                title="Facebook icon"
              ></svg-facebook>
            </a>
            <a
              aria-label="linkedin icon"
              href="https://www.linkedin.com/showcase/globalscot/"
              target="_blank"
            >
              <svg-linkedin
                class="social-icon"
                title="Linkedin icon"
              ></svg-linkedin>
            </a>
            <a
              aria-label="twitter icon"
              href="https://twitter.com/scotent"
              target="_blank"
            >
              <svg-twitter
                class="social-icon"
                title="Twitter icon"
              ></svg-twitter>
            </a>
          </div>
        </el-col>

        <el-col :sm="24">
          <div class="horizontal-links">
            <a :href="getCMSRoute('accessibility')"> Accessibility </a>
            <a :href="getCMSRoute('cookie-policy')"> Cookies </a>
            <a :href="getCMSRoute('privacy-policy')"> Privacy </a>
            <a :href="getCMSRoute('terms-conditions')"> Terms </a>
            <a :href="getCMSRoute('code-of-conduct-for-users')"> Code of conduct </a>
            <!-- <a :href="getCMSRoute('site-map')"> Sitemap </a> -->
          </div>

          <div class="d-flex se-logos">
            <a href="https://www.scottish-enterprise.com/" target="_blank" aria-label="Opens in a new tab">
              <img
                src="/assets/img/se-logo.jpeg"
                class="se-logo"
                alt="Scottish Enterprise Logo"
              />
            </a>
            <a href="https://www.sdi.co.uk/" target="_blank" aria-label="Opens in a new tab">
              <img
                src="/assets/img/scottish-development-logo.png"
                class="sd-logo"
                alt="Scottish Development International Logo"
              />
            </a>
          </div>
        </el-col>
      </el-row>
    </div>
  </footer>
</template>

<script>
import svgFacebook from "@/components/assets/svg-facebook";
import svgLinkedin from "@/components/assets/svg-linkedin";
import svgTwitter from "@/components/assets/svg-twitter";
import svgExternalWhite from "@/components/assets/svg-link";
import svgSeLogo from "@/components/assets/svg-se-logo";
import svgScottishDevelopmentLogo from "@/components/assets/svg-scottish-development-logo";

export default {
  components: {
    "svg-facebook": svgFacebook,
    "svg-linkedin": svgLinkedin,
    "svg-twitter": svgTwitter,
    "svg-se-logo": svgSeLogo,
    "svg-external-white": svgExternalWhite,
    "svg-scottish-development-logo": svgScottishDevelopmentLogo,
  },
};
</script>
