<template>
  <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.042 9.15C15.9165 13.2334 14.4035 17.1523 11.7525 20.2607C9.10141 23.3691 5.47046 25.4816 1.458 26.25L0.0229988 23.15C3.253 21.932 6.094 17.693 6.41 14.77C4.62246 14.4866 2.99473 13.5746 1.81966 12.1981C0.6446 10.8216 -0.000652313 9.07086 0 7.261C0 2.621 3.768 0 7.582 0C11.979 0 16.042 3.361 16.042 9.15ZM35 9.15C34.8745 13.2333 33.3617 17.152 30.7109 20.2604C28.06 23.3688 24.4293 25.4814 20.417 26.25L18.982 23.15C22.212 21.932 25.053 17.693 25.369 14.77C23.5813 14.4868 21.9533 13.5749 20.778 12.1984C19.6028 10.8218 18.9574 9.07101 18.958 7.261C18.958 2.621 22.726 0 26.54 0C30.937 0 35 3.361 35 9.15Z"
      fill="#AF006E"
    />
  </svg>
</template><script>
export default {};
</script>
