<template>
  <slide-panel title="Menu" @panelclose="closeMenu">
    <div class="hr mb-3"></div>
    <div
      class="profile-section justify-content-between d-flex"
      @click="goToProfile()"
    >
      <div class="d-flex">
        <el-avatar
          v-if="user.avatar"
          shape="circle"
          :size="30"
          :src="user.avatar"
          alt="Profile picture"
        ></el-avatar>
        <el-avatar
          v-else
          shape="circle"
          :size="30"
          alt=""
          src="/assets/img/default-image.png"
        ></el-avatar>
        <p>View profile</p>
      </div>
      <a href="#">
        <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right>
      </a>
    </div>
    <div class="hr mt-3"></div>

    <ul class="menu-items mt-3">
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Feed', params: { isActiveFilter: 1 } }">
          <span><svg-feed></svg-feed></span>Dashboard
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Pulse' }">
          <span><svg-pulse></svg-pulse></span>Noticeboard
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Members' }">
          <span><svg-find-expert></svg-find-expert></span>Members
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Events and Webinars' }">
          <span><svg-news></svg-news></span>Events & webinars
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'News and Features' }">
          <span><svg-news-purple></svg-news-purple></span>News & features
        </router-link>
      </li>
      <li v-if="!isTrader" class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Resources' }">
          <span><svg-resources-purple></svg-resources-purple></span>Resources
        </router-link>
      </li>
      <li class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'My Chats' }">
          <span><svg-chat class="chat-icon"></svg-chat></span>Messages
        </router-link>
      </li>
      <li v-if="isExpert" class="el-menu-item" @click="closeMenu">
        <router-link :to="{ name: 'Projects' }">
          <span><svg-news-purple></svg-news-purple></span>Projects
        </router-link>
      </li>
    </ul>

    <div class="hr mt-3 mb-3"></div>

    <ul class="menu-links">
      <li @click="closeMenu">
        <router-link to="/settings" @click="closeMenu"> Settings </router-link>
      </li>
      <!--<li @click="closeMenu">
        <router-link :to="{ name: 'Become GlobalScot' }">
          Become a GlobalScot
        </router-link>
      </li>-->
      <li @click="closeMenu">
        <a :href="getCMSRoute('contact-us')"> Help </a>
      </li>
      <li @click="closeMenu">
        <a :href="getCMSRoute('code-of-conduct-for-users')"> Legal </a>
      </li>
      <li v-if="isExpert" @click="openNominateGSModal()">
        <a
          id="nominate-globalscot"
          role="button"
          class="sub-nav-item nominate-globalscot clickable"
          tabindex="0"
          >Nominate a GlobalScot</a
        >
      </li>
    </ul>

    <div class="logout-link">
      <a href="#" @click="logout"
        >Logout <svg-purple-arrow-right class="arrow"></svg-purple-arrow-right
      ></a>
    </div>
  </slide-panel>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import Feed from "@/components/assets/svg-feed";
import Event from "@/components/assets/svg-event";
import News from "@/components/assets/svg-news";
import NewsPurple from "@/components/assets/svg-news-purple";
import Pulse from "@/components/assets/svg-pulse";
import Chat from "@/components/assets/svg-chat-menu";
import FindExpert from "@/components/assets/svg-find-expert";
import SlidePanel from "@/components/slide-panel/slide-panel";
import Challenges from "@/components/assets/svg-challenges-menu";
import PurpleArrowRight from "@/components/assets/svg-purple-arrow-right";
import SvgResourcesPurple from "@/components/assets/svg-resources-purple";
import RolePermissionMixin from "@/mixins/RolePermissionMixin";
import gsApi from "@/services/gs.api";
import helpers from "@/helpers/index";

export default {
  name: "MobileMenu",
  components: {
    "svg-feed": Feed,
    "svg-chat": Chat,
    "svg-event": Event,
    "svg-news": News,
    "svg-news-purple": NewsPurple,
    "svg-pulse": Pulse,
    "svg-challenges": Challenges,
    "svg-find-expert": FindExpert,
    "svg-purple-arrow-right": PurpleArrowRight,
    "slide-panel": SlidePanel,
    SvgResourcesPurple,
  },
  mixins: [RolePermissionMixin],
  data() {
    return {
      isTrader: false,
      isExpert: false,
    };
  },
  mounted() {
    this.checkRole();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    checkRole() {
      if (this.isRole("trader")) this.isTrader = true;

      if (this.isRole("expert")) this.isExpert = true;
    },
    goToProfile() {
      if (helpers.isExpert(this.user)) {
        this.closeMenu();
        this.$router.push({
          name: "GlobalScot Profile",
          params: { id: this.user.id },
        });
      } else if (helpers.isMember(this.user)) {
        this.closeMenu();
        this.$router.push({
          name: "NonGlobalScot Profile",
          params: { id: this.user.id },
        });
      } else {
        this.closeMenu();
        this.$router.push({
          name: "Staff Profile",
          params: { id: this.user.id },
        });
      }
    },
    closeMenu() {
      this.$emit("close");
    },
    openNominateGSModal() {
      this.closeMenu();

      store.commit("OPEN_NOMINATE_GS_MODAL");
      document.getElementById("content").setAttribute("aria-hidden", "true");
    },
    logout() {
      gsApi.auth.logout(this.user.token).then(() => {
        store.commit("USER_LOGOUT");
        this.$nextTick(() => {
          this.$router.push({ name: "Login" });
        });
      });
    },
  },
};
</script>
