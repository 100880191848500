<template>
  <div class="white-background-section pb-2">
    <div class="container">
      <el-row>
        <div class="join-heading-text">
          <h1 class="join-title">{{joinText.heading}}</h1>
        </div>
        <div class="join-body">
          <el-col :md="24" :lg="12">
            <div class="join-organisations">
              <h2>{{bodyText.organisations.heading}}</h2>
              <p>{{bodyText.organisations.sub}}</p>
              <p class="join-link">
                <router-link :to="{ name: 'Signup NGS' }">{{bodyText.organisations.link}}</router-link>
              </p>
            </div>
          </el-col>
          <el-col :md="24" :lg="12">
            <div class="join-experts">
              <h2>{{bodyText.experts.heading}}</h2>
              <p>{{bodyText.experts.sub}}</p>
              <p class="join-link">
                <router-link :to="{ name: 'Become GlobalScot' }">{{bodyText.experts.link}}</router-link>
              </p>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      joinText: {
        heading: "Who can join?"
      },
      bodyText: {
        organisations: {
          heading: "Scottish organisations looking for support",
          sub:
            "We welcome business owners, entrepreneurs or professionals who are based in Scotland and are looking for business support. We’re open to businesses in any sector and of any size – from startups to SMEs and large corporations.",
          link:"Find out more about becoming a business member"
        },
        experts: {
          heading: "Worldwide business experts who can offer support",
          sub:
            "We also welcome passionate and experienced business professionals from around the world. These professionals use their skills, experience and connections to support Scottish businesses. They’re based all around the globe.",
          link: "Find out more about becoming a GlobalScot"
        }
      }
    };
  }
};
</script>
<style>
.join-body h2 {
  font-size: 30px;
  line-height: 43px;
  font-weight: 420;
  margin-bottom: 30px;
  color: black;
}
.join-body p {
  font-weight: 390;
  font-size: 19px;
  line-height: 28px;
}
.join-link {
  margin-top: 30px;
}
.join-experts,
.join-organisations {
  margin: 50px 0 50px 0;
}
.join-heading-text {
  margin-top: 50px;
}
</style>
