<!-- App Component -->

<template>
  <div id="app">
    <div class="sr-only">
      <vue-announcer />
    </div>
    <v-skip-links ref="skiplinks"></v-skip-links>
    <router-view v-if="app.authIsReady" />
    <div v-loading.fullscreen.lock="!app.authIsReady"></div>
  </div>
</template>

<script>
    import gsApi from '@/services/gs.api'
    import store from "./store"
    import router from '@/router'
    import { mapState } from "vuex"
    import * as config from "./config"
    import SkipLinks from "@/components/partials/skip-links";

    export default {
        name: 'app',
        metaInfo: {
          title: 'Home',
          titleTemplate: '%s - GlobalScot'
        },
        components: {
          'v-skip-links': SkipLinks
        },
        data () {
            return {
                isReady: false
            }
        },
        computed: {
            ...mapState(['app'])
        },
        methods: {
            setFocusToSkiplinks () {
                this.$refs.skiplinks.focusSelf();
            },
            setRouteAnnouncement (pagetitle) {
                this.$announcer.set(`Navigated to ${pagetitle}`)
            },
            setAriaCurrent () {
                this.$nextTick(() => {
                    const oldCurrents = this.$el.querySelectorAll("[aria-current]")
                    oldCurrents && oldCurrents.forEach(current => { current.removeAttribute("aria-current") })

                    const newCurrents = this.$el.querySelectorAll(".router-link-exact-active")
                    newCurrents && newCurrents.forEach(current => { current.setAttribute("aria-current", "page") })
                })
            }
        },
        watch: {
            $route (to) {
                this.$nextTick(() => {
                    const title = to.meta.title
                    this.setRouteAnnouncement(title)
                    this.setFocusToSkiplinks()
                    this.setAriaCurrent()
                })

                if (store.state.user.isLogged) {
                  gsApi.notifications.index({
                    client_id: 2
                  }).then(function (response) {
                    store.commit("UPDATE_NOTS", response.data.meta.total_not_read_count);
                    store.commit("UPDATE_CHATS", response.data.meta.total_unread_chats_count);
                  })
                }
            }
        }
    }
</script>
<style lang="scss">
.announcer{
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  border: 0!important;
  left: 1px!important;
}
</style>
