import axios from 'axios'
import * as config from '@/config'

let instance = axios.create({
  baseURL: 'https://api.addressy.com/',
  timeout: 15000
})

export default {
  find (value, container = '', countries = {}) {
    return instance({
      url: '/Capture/Interactive/Find/v1.10/json3.ws',
      method: 'get',
      params: {
        Key: process.env.VUE_APP_LOQATE_API_KEY,
        Text: value,
        Container: container,
        Countries: countries
      }
    })
  },
  retrieve (id) {
    return instance({
      url: '/Capture/Interactive/Retrieve/v1.00/json3.ws',
      method: 'get',
      params: {
        Key: process.env.VUE_APP_LOQATE_API_KEY,
        Id: id
      }
    })
  },
}
