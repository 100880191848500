<template>
  <div
    class="section-companies"
    v-loading="loader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <h2>Your companies</h2>
    <span>
      Your primary company will be shown in your profile, along with your name
      and location.
    </span>

    <company-item
      :company="company"
      :show-divider="index != businesses.length - 1"
      v-for="(company, index) in businesses"
      :key="company.id"
    />
    <gs-company-form
      v-if="showAddNewCompanyForm"
      @onCloseForm="closeForm"
    ></gs-company-form>
    <el-button @click="addNewCompany" v-if="!showAddNewCompanyForm"
      >Add a new company</el-button
    >
    <hr class="my-4" />
    <h2>Companies you have a strong connection with</h2>
    <span
      >These are other companies that you could introduce network members to if
      appropriate. They could include companies you've worked with previously or
      were a board member of.
    </span>
    <other-company-item
      :company="company"
      :show-divider="index != otherBusinesses.length - 1"
      v-for="(company, index) in otherBusinesses"
      :key="company.id"
    />
    <gs-other-company-form
      v-if="showAddNewConnectionForm"
      @onCloseConnectionForm="closeConnectionForm"
    ></gs-other-company-form>
    <el-button
      class="mt-4"
      @click="addNewConnection"
      v-if="!showAddNewConnectionForm"
      >Add a new connection</el-button
    >
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import { mapGetters, mapState } from "vuex";
import CompanyItem from "@/components/platform/edit-profile/gs-companies-company-item";
import GsCompanyForm from "./gs-company-form.vue";
import GsOtherCompanyForm from "./gs-other-company-form";
import OtherCompanyItem from "./gs-other-company-item";

export default {
  name: "gs-companies",
  components: {
    CompanyItem,
    GsCompanyForm,
    GsOtherCompanyForm,
    OtherCompanyItem,
  },

  data() {
    return {
      selectedCompanyId: 0,
      otherCompanies: [],
      address: {},
      loader: false,
      addressLoader: false,
      disableAddressLookup: false,
      availableMailingAddresses: [],
      countries: [],
      showAddNewCompanyForm: false,
      showAddNewConnectionForm: false,
    };
  },

  methods: {
    onFindAddress(e) {
      this.findAddresses(this.address.post_code);
    },

    findAddresses(value, containerId = "") {
      this.addressLoader = true;
      loqateApi
        .find(value, containerId, "")
        .then((response) => {
          this.addressLoader = false;

          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal",
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.address.address_1 = "";
              this.address.address_2 = "";
              this.address.town = "";
              this.address.county = "";
              this.address.country_id = null;

              this.disableAddressLookup = true;
              this.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.availableMailingAddresses = [];
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.addressLoader = false;
          this.availableMailingAddresses = [];
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    onAvailableAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then((response) => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {

            this.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.address.address_1 = address.Line1;
            this.address.address_2 = address.Line2;
            this.address.post_code = address.PostalCode;
            this.address.town = address.City;
            this.address.county = address.AdminAreaName;

            let country = this.countries.find(
              (c) =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.address.country_id = country.id;
            }
          } else {
            this.$alert(
              "No Postcode found. Please add it manually.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },

    getMeta() {
      gsApi.globalscot.meta
        .index()
        .then((response) => {
          this.countries = response.data.countries.data;
        })
        .catch(() => {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    addNewCompany() {
      this.showAddNewCompanyForm = true;
    },
    closeForm(newCompany) {
      this.showAddNewCompanyForm = false;
    },
    addNewConnection() {
      this.showAddNewConnectionForm = true;
    },
    closeConnectionForm(newCompany) {
      this.showAddNewConnectionForm = false;
    },
  },

  computed: {
    ...mapGetters(["user", "businesses", "otherBusinesses"]),
  },
};
</script>

<style lang="scss"></style>
