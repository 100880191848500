<template>
  <div>
    <el-card class="box-card member-card questions-card" shadow="always">
      <div class="ra-message-header">
        <div>
            <user-avatar
              shape="circle"
              :isGlobalScot="isGlobalScot"
              :authorExist="true"
              width="40px"
              :size="40"
              :src="message.avatar"
              :alt="`${message.name} Profile picture`"
            ></user-avatar>
        </div>
        <div>
          <h2>{{ message.name }}</h2>

          <p>{{message.created_at_time_diff}}</p>
        </div>
      </div>

      <p v-line-clamp="3" class="sub-text" v-html="$options.filters.domPurify(message.comment)"></p>
    </el-card>
  </div>
</template>

<script>
import UserAvatar from "@/components/core/user-avatar";
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  components:{
    UserAvatar
  },
  computed: {
    isGlobalScot() {
      return this.message.is_GlobalScot === 1;
    }
  }
};
</script>

<style lang="scss" scoped>
  .ra-message-header {
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    margin-bottom: 5%;
  }
  .sub-text {
    line-height: 24px;
    min-height: 75px;
    font-size: 19px;
    font-weight: 390;
  }
</style>
