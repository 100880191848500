<template>
  <el-row class="background-idea-section py-4 m-0">
    <div class="container">
      <el-col :sm="24" :md="24">
        <div class="idea-data align-items-center">
          <h2 class="idea-text-body mr-5">
            {{ text.body }}
          </h2>
          <a :href="getCMSRoute('contact-us')">
            <el-button type="primary" class="idea-contact-btn mt-md-0 mt-4">
              Contact GlobalScot team
            </el-button>
          </a>
        </div>
      </el-col>
    </div>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      text: {
        body: "Do you have an idea for a project?",
      },
    };
  },
};
</script>
