<template>
  <div class="white-background-section">
    <div class="container">
      <!--h1 class="can-you-help-title">{{helpText.heading}}</h1>
      <p class="can-you-help-sub">{{helpText.sub}}</p>
      <div>
        <div>
          <ul class="can-you-help-list">
            <li v-for="point in helpBulletPoints" :key="point.text">{{ point.text }}</li>
          </ul>
        </div>
      </div-->
      <div class="pt-1">
        <!--why-should-i></!--why-should-i-->
        <am-i-eligible></am-i-eligible>
        <whats-expected></whats-expected>
        <!--other-way-you-can-help /-->
      </div>
    </div>
  </div>
</template>
<script>
import WhatsExpected from "./whats-expected";
import WhyShouldI from "./why-should-i";
import AmIEligible from "./am-i-eligible";
import OtherWayYouCanHelp from './other-way-you-can-help'
export default {
  components: {
    WhatsExpected,
    WhyShouldI,
    AmIEligible,
    OtherWayYouCanHelp
  },
  data() {
    return {
      helpText: {
        heading: "How else you can help",
        sub:
          "As well as supporting and working directly with Scottish businesses, many of our GlobalScots undertake activities to support the network or Scotland as a whole. This could include:"
      },
      helpBulletPoints: [
        {
          text: "Publishing market insights and reports"
        },
        {
          text: "Speaking at events"
        },
        {
          text: "Taking part in collaborative challenges and projects "
        },
        {
          text:
            "Encouraging inward investment by promoting Scotland as a great place to do business"
        }
      ]
    };
  }
};
</script>
<style>
.how-can-you-help-sub {
  font-size: 19px;
}
</style>
