<template>

  <map-loader
    :mapConfig="mapConfig"
    apiKey="AIzaSyBjRje1YuLkRUsqKQUd0JigwEp4vP-_esU"
    @maploaded="onMapLoaded($event)">
    <template slot-scope="{ google, map }">
      <map-marker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        @onmarkerclick="onMarkerClick($event)"
        @onmapmarkeradded="onMapMarkerAdded($event)" />
    </template>
  </map-loader>

</template>

<script>
import Vue from 'vue'
import gsApi from '@/services/gs.api'
import MapLoader from '@/components/map-view/map-loader'
import MapMarker from '@/components/map-view/map-marker'
import { mapConfig } from '@/components/map-view/config/map-config'
import MemberInfowindow from '@/components/map-view/member-infowindow'
import ClusterMemberInfowindow from '@/components/map-view/cluster-member-infowindow'
import MarkerClusterer from '@google/markerclustererplus'

export default {
  name: 'map-view',
  components: {
    'map-loader': MapLoader,
    'map-marker': MapMarker,
  },
  props: {
    markers: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      infoWindow: null,
      clusterMap: null,
      clusterMarker: null,
      currentMap: null,
      currentMarker: null,
      mapMarkers: [],
      clusterer: null
    }
  },
  computed: {
    mapConfig() {
      return {
        ...mapConfig,
      }
    },
    mapCenter() {
      return this.markers.length > 0 ? this.markers[0].position : null
    }
  },
  methods: {
    onMapLoaded(event) {
      if (this.markers.length > 0) {
        this.setMapBounds(event.map) // Ensure all markers can be seen on load
        this.addMarkerContainer(event.map) // Allows css to be added to markers
      }
    },
    setMapBounds(map) {
      let bounds = new google.maps.LatLngBounds()

      let markerBounds = []
      this.markers.forEach((marker) => {
       markerBounds.push({lat: marker.position.lat, lng: marker.position.lng})
      })

      markerBounds.forEach((markerBound) => {
       bounds.extend(markerBound)
      })

      map.fitBounds(bounds)
    },
    addMarkerContainer(map) {
      let myoverlay = new google.maps.OverlayView()
      myoverlay.draw = function () {
        this.getPanes().markerLayer.id='markerLayer'
      }
      myoverlay.setMap(map)
    },
    onMarkerClick(event) {
      let infoWindowInstance = this.createInfoWindowInstance(event.memberId)
      infoWindowInstance.$mount()

      this.infoWindow && this.infoWindow.close()

      this.infoWindow = new google.maps.InfoWindow({
        content: infoWindowInstance.$el
      })
      this.currentMarker = event.marker
      this.currentMap = event.map

      this.currentMap.setCenter(this.currentMarker.getPosition())

      this.infoWindow.open(event.map, event.marker)
    },
    createStateClusterMarkerInfo(stateIds, map, marker) {

      let infoWindowInstance = this.createClusterInfoWindowInstance(stateIds)

      infoWindowInstance.$mount()

      this.infoWindow && this.infoWindow.close()

      this.infoWindow = new google.maps.InfoWindow({
        content: infoWindowInstance.$el
      })

      this.clusterMap = map
      this.clusterMarker = marker
      this.infoWindow.open(this.clusterMap)
    },
    onCountryClusterMarkerClick(event) {
      event.map.setZoom(6)
      event.map.panTo(event.marker.position)

    },
    createInfoWindowInstance(memberId) {
      let infoWindow = Vue.extend(MemberInfowindow)

      let instance = new infoWindow({
        propsData: {
          memberId: memberId
        }
      })

      instance.$on("realigninfowindow", () => {
        this.infoWindow.setPosition(this.currentMarker.getPosition())
        this.infoWindow.open(this.currentMap)
        this.infoWindow.set("pixelOffset", new google.maps.Size(0,-25))
      })

      instance.$on("closeinfowindow", () => {
        this.infoWindow.close()
        this.infoWindow = null
      })

      return instance
    },
    createClusterInfoWindowInstance(stateIds) {
      let infoWindow = Vue.extend(ClusterMemberInfowindow)

      let instance = new infoWindow({
        propsData: {
          stateIds: stateIds
        }
      })

      instance.$on("realigninfowindow", () => {
        this.infoWindow.setPosition(this.clusterMarker.getCenter())
        this.infoWindow.open(this.clusterMap)
      })

      instance.$on("closeinfowindow", () => {
        this.infoWindow.close()
        this.infoWindow = null
      })

      return instance
    },
    onMapMarkerAdded(marker) {
      this.mapMarkers.push(marker)
      if (this.markers.length === this.mapMarkers.length) {
        this.initMarkerClusterer(marker.map)
      }
    },
    clearMapMarkers() {
      this.$set(this, 'mapMarkers', [])
      if (this.clusterer) {
        this.clusterer.clearMarkers()
      }
    },
    initMarkerClusterer(map) {
      const style = [MarkerClusterer.withDefaultStyle({
        url: '/assets/img/cluster-marker.svg',
        width: 50,
        height: 50,
        anchorText: [0, 0],
        anchorIcon: [0, 0],
        textColor: '#fff',
        textSize: 16,
      })]

      const options = {
        maxZoom: 8,
        gridSize: 50,
        styles: style,
      }
      this.clusterer = new MarkerClusterer(map, this.mapMarkers, options)
      this.addMarkerClustererEvents()
      this.setMapBounds(map)
    },
    addMarkerClustererEvents() {
      const vm = this
      google.maps.event.addListener(this.clusterer, "click", function(c) {
        if (c.getMap().getZoom() === 8) { // at zoom 8 show members by state
          const markers = c.getMarkers()
          const stateIds = [...new Set(markers.map(({ marker }) => marker.stateId))];
          vm.createStateClusterMarkerInfo(stateIds, c.getMap(), c)
        }
      });
    }
  },
  mounted() {
    this.$events.listen('deleteAllMarkers', this.clearMapMarkers)
  },
}
</script>
