<template>
  <article>
    <el-col :sm="24" :md="12" :lg="8" :xl="8" class="news-card-col">
      <el-card class="news-card box-card text-center">
        <div class="news-img">
          <el-image
            :alt="altText"
            :src="imageForEvent"
            fit="none"
            :style="{
              'object-position': `${event.metadata?.focus_point.x}% ${event.metadata?.focus_point.y}%`,
            }"
          ></el-image>

          <span class="news-type"
            >{{ event.type_name }}: {{ event.category_name }}</span
          >
        </div>
        <div class="title-position">
          <h2 class="text-truncate">{{ event.title }}</h2>
          <p class="text-truncate mt-0">
            <span v-if="event.start_date" >{{ event.start_date }}</span><span v-if="event.start_date && !isSameDay"> - {{ event.end_date }}</span>
          </p>
          <p class="text-truncate">{{ event.description }}</p>
        </div>
        <div class="read-more-btn-container">
          <el-button
            type="primary"
            class="read-more-btn"
            @click="readMore(event.slug)"
            >Read more<span class="sr-only"
              >about {{ event.title }}</span
            ></el-button
          >
        </div>
      </el-card>
    </el-col>
  </article>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "events-card",
  components: {},
  props: {
    event: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eventTypes: {
        events: "Events",
        webinars: "Webinars",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    imageForEvent() {
      return this.event.mediaFile && this.event.mediaFile.large_thumb
        ? this.event.mediaFile.large_thumb
        : null;
    },
    altText() {
      return this.event.mediaFile && this.event.mediaFile.alt_attribute
        ? this.event.mediaFile.alt_attribute
        : null;
    },
    isSameDay() {
      return Vue.moment(this.event.start_date).isSame(this.event.end_date, 'day')
    }
  },
  methods: {
    readMore(slug) {
      if (this.isPublic) {
        this.$router.push({ name: "Public Events", params: { slug: slug } });
      } else {
        this.$router.push({ name: "Events", params: { slug: slug } });
      }
    },
  },
};
</script>
