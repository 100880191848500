<template>
  <div class="white-background-section">
    <div class="container">
      <el-row>
        <el-col
          class="card-container"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
          v-for="(card, index) in boxCards"
          :key="index"
        >
          <el-card class="box-card" shadow="always">
            <div class="what-you-get-card">
              <div class="what-you-get-card-icon">
                <svg-community-feed class="icon" v-if="index === 0"></svg-community-feed>
                <svg-events class="icon" v-if="index === 1"></svg-events>
                <svg-messages class="icon" v-if="index === 2"></svg-messages>
                <svg-diverse-community class="icon" v-if="index === 3"></svg-diverse-community>
              </div>
              <div>
                <h3 class="what-you-get-sub-text">{{card.text}}</h3>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CommunityFeedIcon from "@/components/assets/svg-community-feed";
import DiverseCommunityIcon from "@/components/assets/svg-diverse-community";
import EventsIcon from "@/components/assets/svg-events";
import MessagingIcon from "@/components/assets/svg-messaging";

export default {
  components: {
    "svg-community-feed": CommunityFeedIcon,
    "svg-diverse-community": DiverseCommunityIcon,
    "svg-events": EventsIcon,
    "svg-messages": MessagingIcon,
  },
  data() {
    return {
      boxCards: [
        {
          text: "Business coaching, organisational development and leadership advice"
        },
        {
          text: "Real time insights into your sectors or geographic markets based on your experience"
        },
        {
          text: "Arranging introductions to your contacts and networks, including potential suppliers, distributors and customers"
        },
        {
          text: "Strategic development and applying strategic thinking to business problems"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.card-container {
  padding-bottom: 30px;
}
.what-you-get-sub-text {
  font-size: 20px;
  font-weight: normal;
  color: black;
  line-height: 20px;
  text-align: center;
}
.what-you-get-card {
  padding: 10px;
}
.what-you-get-card-icon {
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  .icon {
    height: 50px;
    width: 50px;
  }
}
</style>
