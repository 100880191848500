<template>
  <Trap>
  <slide-panel class="create-chat-slide-panel" @panelclose="closeMenu" :isMobile="false" :slideRight="true" :closeAsText="false" title="Add/remove people" v-loading.lock="panelLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
    <div class="mt-5">
			<el-form :model="searchForm" ref="memberSearchForm" id="memberSearchForm" @submit.native.prevent>
		    <el-form-item prop="search">
		      <template slot="label">
		        <span aria-hidden="true" class="new-chat-label">Search and add members</span>
		        <span class="sr-only">search for a member</span>
		      </template>
					<v-select ref="first_name" label="first_name" :filterable="false" :options="options" @search="onSearch" v-model="searchForm.selectedMember" @input="onAddMember($event)" class="members-select">
				    <template slot="no-options" slot-scope="search">
				      <div v-if="search.search.trim().length == 0">Start typing to search for a member.</div>
				      <div v-else>No results found for <em>{{ search.search }}</em>.</div>
				    </template>
				    <template slot="option" slot-scope="option">
				      <div class="d-flex v-select-member">
				        <div class="mt-2"><member-avatar :member="option" :size="40"></member-avatar></div>
				        <div class="d-flex flex-column text-section">
				        	<div class="name ml-2 text-left">{{ option.first_name }} {{ option.last_name }}</div>
				        	<div class="company ml-2">{{ option.primary_company.name }}</div>
				      	</div>
				        <a href="#" class="ml-auto align-self-center add-link">Add</a>
				      </div>
				    </template>
				  </v-select>
				</el-form-item>
			</el-form>
    </div>

    <div class="mt-5">

      <span class="sr-only">Existing members</span>

      <div v-if="members.length > 0">
        <div tabindex="0" class="existing-member d-flex align-items-center py-3" v-for="member in members">
          <member-avatar :member="member" :size="40"></member-avatar>
          <div class="member-item-name pl-3 mr-2">{{member.first_name}} {{member.last_name}}</div>
          <a href="javascript:void(0)"
             aria-label="remove chat member"
             @click="removeMember(member)"
             class="member-item-remove ml-auto flex-shrink-0 cursor-pointer">
            Remove
          </a>
        </div>
      </div>
      <div v-else class="no-members-added">Search for members to add to this chat.</div>
  	</div>

    <el-alert
      v-if="memberAddError"
      title="Unable to add member, please try again later."
      type="error">
    </el-alert>
    <el-alert
      v-if="memberRemoveError"
      title="Unable to remove member, please try again later."
      type="error">
    </el-alert>
  </slide-panel>
  </Trap>
</template>

<script>
  import _ from 'lodash'
  import gsApi from '@/services/gs.api'
  import SlidePanel from '@/components/slide-panel/slide-panel'
  import SvgCloseCross from '@/components/assets/svg-close-cross'
  import MemberAvatar from '@/pages/platform/chats/avatars/member-avatar'
  import Trap from 'vue-focus-lock';
  import FocusLock from '../../common/FocusLock.vue'

  export default {
  	name: 'manage-chat-panel',
    components: {
  		'slide-panel': SlidePanel,
      'svg-close': SvgCloseCross,
      'member-avatar': MemberAvatar,
      'Trap' : Trap,
      FocusLock
    },
    props: {
	    members: {
        type: Array,
        default: []
	    },
	   	selectedChatId: {
        type: Number,
        required: true
	    },
    },
    data: () => ({
      memberAddError: false,
      memberRemoveError: false,
    	panelLoader: false,
    	createChatLoading: false,
    	searchForm: {
    		selectedMember: null,
    	},
      search_member: '',
      options: []
    }),
    computed: {
      existingMemberIds() {
        let memberIds = []
        _.each(this.members, (member) => {
            memberIds.push(member.id)
        })
        return memberIds
      },
    },
    methods: {
    	onAddMember(member) {
    		this.panelLoader = true

        this.searchForm.selectedMember = null // Workaround v-select to select a user after removal as in (add Sean, Remove Sean and then add Sean again).

        gsApi.chat.addMember(this.selectedChatId, { member_id: member.id })
        .then((response) => {
      	  this.panelLoader = false

        	this.$store.commit('UPDATE_SELECTED_CHAT', response.data)
        	this.$events.emit('updateSelectedChatList', response.data)
	      }).catch(error => {
          this.panelLoader = false

          this.memberAddError = true
        })
    	},
    	onSearch(search, loading) {
    		this.search(loading, search, this)
    	},
    	search: _.debounce((loading, search, vm) => {
    		if (search.trim().length > 0) {
          loading(true)

          gsApi.chat.getNewMembersList({
            search: search.trim(),
	          excluded_ids: vm.existingMemberIds
          })
          .then((response) => {
	        	loading(false)

	          if (response && response.data) {
	            vm.options = response.data.data
	          } else {
	          	vm.options = []
	          }
	        })
      	} else {
      		vm.options = []
      	}
   		}, 300),
	    closeMenu() {
	      this.$emit('close')
	    },
	    removeMember(member) {
	    	if (this.members.length > 1) {
          this.panelLoader = true

          gsApi.chat.removeMember(this.selectedChatId, member.id)
          .then((response) => {
	        	this.panelLoader = false

	          this.$store.commit('UPDATE_SELECTED_CHAT', response.data)
	          this.$events.emit('updateSelectedChatList', response.data)
	        }).catch(error => {
            this.panelLoader = false
            this.memberRemoveError = true
          })
      	}
	    },
  	},

    created() {

        this.$nextTick(() => {
          const input = this.$refs.first_name.$el.querySelector('input')
          input.focus()
        })

    }
  }
</script>
