<template>
    <div class="el-row m-0">
        <div class="members-list-bar el-col-24 el-col-lg-7 px-3">
            <div class="el-row m-0 h-100">
                <div class="el-col-24 el-col-lg-7 manage-chat-members m-0 py-1">
                    <span class="pt-1 d-inline-block manage-chat-members-title">Create new conversation</span>
                    <span @click="resetAll(); $parent.hideChatsCreateBar()" class="svg-close float-right pr-4"><svg-close/></span>
                </div>
                <div class="el-col-24 search-add-member">
                    <span class="font-bold add-member-title">Search and add members</span>

                    <div class="search-member">
                        <el-form class="mt-2">
                            <el-form-item>
                                <el-input @input="filterMemberListByName" placeholder="" v-model="search_member"/>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="add-member d-flex flex-column px-2">
                        <div class="add-member-item d-flex align-items-center py-2"
                             v-for="member in filtered_new_member_arr">
                            <member-avatar :member="member"></member-avatar>
                            <div class="member-item-name-position pl-3">

                                <div class="name">{{member.first_name}} {{member.last_name}}</div>
                                <div class="position" v-if="member.primary_company">{{member.primary_company.name}}
                                </div>
                            </div>
                            <div @click="addMember(member)" class="member-item-add ml-auto pb-4">
                                Add
                            </div>
                        </div>
                    </div>
                </div>

                <div class="existing-members el-col-24">
                    <div class="existing-members-title mb-4">Existing members</div>

                    <div class="existing-member-items">
                        <div class="existing-member-item d-flex align-items-center py-3" v-for="member in member_arr">
                            <member-avatar :member="member"></member-avatar>
                            <div class="member-item-name pl-3">{{member.first_name}} {{member.last_name}}</div>
                            <div @click="removeMember(member)" class="member-item-remove ml-auto">Remove</div>
                        </div>
                    </div>
                </div>

                <div class="existing-members el-col-24">
                    <div class="create-conversation-button">
                        <button @click="saveMembers" class="el-button el-button--primary pull-right" type="button">
                            Create conversation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    import gsApi from '@/services/gs.api'
    import SvgCloseCross from '@/components/assets/svg-close-cross'
    import MemberAvatar from './avatars/member-avatar'

    export default {
        components: {
            'svg-close': SvgCloseCross,
            'member-avatar': MemberAvatar,
        },
        data: () => ({
            search_member: '',
            member_arr: [],
            new_member_arr: [],
            filtered_new_member_arr: [],
        }),
        created() {
            this.getNewMembers();
        },
        methods: {
            getNewMembers() {
              gsApi.chat.getNewMembersList()
              .then((response) => {
                  if (response && response.data) {
                      this.new_member_arr = response.data.data
                      this.filtered_new_member_arr = response.data.data
                  }
              })
            },

            addMember(member) {
                const index = _.findIndex(this.new_member_arr, {id: member.id});
                this.member_arr.push(member);
                this.new_member_arr.splice(index, 1);
            },

            saveMembers() {
                if (this.member_arr.length > 0) {
                    let member_ids = [];
                    _.each(this.member_arr, (memberObj) => {
                        member_ids.push(memberObj.id);
                    });
                    gsApi.chat.create({ type: 'group', members: member_ids })
                    .then((response) => {
                        if (response && response.data) {
                            this.$parent.fetchChatList()
                            this.$parent.hideChatsCreateBar()
                        }
                    })
                    .catch(error => {
                      this.mainLoader = false;
                      if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.existingChat
                      ) {
                        this.$store.commit(
                          "UPDATE_SELECTED_CHAT",
                          error.response.data.existingChat
                        );
                        this.$router.push({ name: "My Chats" });
                      }
                    });
                }
            },

            removeMember(member) {
                const index = _.findIndex(this.member_arr, {id: member.id});
                this.member_arr.splice(index, 1);
                this.new_member_arr.push(member);
            },

            filterMemberListByName() {
                let filtered_member_arr = [];
                _.each(this.new_member_arr, (memberObj) => {
                    if (_.includes(memberObj.first_name.toLowerCase(), this.search_member.toLowerCase()) ||
                        _.includes(memberObj.last_name.toLowerCase(), this.search_member.toLowerCase())) {
                        filtered_member_arr.push(memberObj);
                    }
                });

                this.filtered_new_member_arr = filtered_member_arr;
            },

            resetAll() {
                this.search_member = '';
                this.member_arr = [];
                this.new_member_arr = [];
            },
        }
    }
</script>

<style>
    .create-conversation-button {
        margin-top: 45px;
    }
</style>
