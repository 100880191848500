<template>
  <div class="white-background-section">
    <div class="container">
      <div class="benefit-heading-text">
        <h1 class="benefit-title">{{benefitText.heading}}</h1>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      benefitText: {
        heading: "How Scottish buisnesses have already benefited"
      }
    };
  }
};
</script>
