<template>
  <div class="skiplinks">
    <h2 class="sr-only">Accessibility links</h2>
    <nav class="skiplinks_items">
      <a class="skiplinks_item sr-only" href="#main-content" @click.prevent="skipToMainContent">
        Skip to main content
      </a>
      <a class="skiplinks_item sr-only" href="/accessibility" @click.prevent="goToAccessibility">
        Accessibility information
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'skip-links',

  data () {
    return {
      mainContentId: 'main-content',
      fallbackContentId: 'content',
    }
  },

  methods: {
    focusSelf() {
      this.$el.setAttribute('tabindex', '-1');
      this.$el.focus();
      this.$el.removeAttribute('tabindex');
    },
    skipToMainContent() {
      let element = document.getElementById(this.mainContentId);
      if (!element) {
        element = document.getElementById(this.fallbackContentId);
      }
      element.setAttribute('tabindex', '-1'); // makes the element focusable
      element.classList.add('outline-none');
      element.scrollIntoView();
      element.focus();
    },
    goToAccessibility () {
      this.$router.push({name: 'Accessibility'});
    },
  },
}
</script>

<style lang="scss">
.outline-none {
  outline: none;
}
</style>
