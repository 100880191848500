<!-- Index Component -->
<template>
  <div class="wrapper new-ui">
    <!-- <v-beta-bar v-if="showHeader && !landingNavigation" /> -->
    <v-l-header
      v-if="
        showHeader &&
        ((landingNavigation && !user.isLogged) ||
          (landingNavigation && !platformNavigation))
      "
      @onOpenMobileMenuLanding="openMobileMenuLanding"
    ></v-l-header>
    <v-p-header
      v-if="showHeader && platformNavigation && user.isLogged"
      @onOpenMobileMenu="openMobileMenu"
    ></v-p-header>
    <v-notification-dialog
      v-if="notificationDialogOpen"
    ></v-notification-dialog>
    <v-content></v-content>
    <v-join-hub v-if="showHeader && joinHub && !user.isLogged"></v-join-hub>
    <v-ready-to-join
      v-if="showHeader && readyToJoin && !user.isLogged"
    ></v-ready-to-join>
    <v-not-sure-globalscot
      v-if="showHeader && notSureGlobalscotCheck && !user.isLogged"
    ></v-not-sure-globalscot>
    <v-get-in-touch v-if="isGetInTouchRoute"></v-get-in-touch>
    <v-footer v-if="showHeader && footerExists"></v-footer>
    <mobile-menu
      v-if="showHeader && platformNavigation && user.isLogged && mobileMenuOpen"
      @close="closeMobileMenu"
      @logout="logout"
    ></mobile-menu>
    <!-- <mobile-menu-landing
      v-if="
        showHeader &&
        !platformNavigation &&
        !user.isLogged &&
        mobileMenuOpenLanding
      "
      @close="closeMobileMenuLanding"
      @logout="logout"
    ></mobile-menu-landing> -->
    <!-- <v-cookies v-if="showHeader && ((landingNavigation && !user.isLogged) || (landingNavigation && !platformNavigation ))"></v-cookies> -->
  </div>
</template>

<script>
import store from "../store";
import { mapState } from "vuex";
import gsApi from "@/services/gs.api";
import Footer from "../layout/footer";
import Content from "../layout/content";
import JoinHub from "../layout/join-hub";
import ReadyToJoin from "../layout/ready-to-join";
import NotSureGlobalscot from "../layout/not-sure-globalscot";
import GetInTouch from "../layout/get-in-touch";
import PlatformHeader from "../layout/p-header";
import TopNavigation from "../layout/top-navigation";
import LandingHeader from "../layout/l-header";
import BetaBar from "@/components/partials/beta-bar";
import CookieConsent from "@/components/partials/cookie-consent";
import MobileMenu from "@/components/slide-panel/panels/mobile-menu";
import MobileMenuLanding from "@/components/slide-panel/panels/mobile-menu-landing";
import NotificationDialog from "@/components/partials/notification-dialog";

const activeLandingNavigationRoutes = [
  // "Home",
  // "Get Business Support",
  "Login",
  "Join",
  "Signup Select Type",
  "Expert Join",
  "ExpertSignup",
  "About Us",
  "About",
  "About Network",
  "Become GlobalScot",
  "Find Expert",
  "Article",
  "Projects",
  "Project",
  "Artcles and Events",
  "Faq",
  "How Points Work",
  "Verify Email",
  "Forgot Password",
  "Reset Password",
  "Site Map",
  "Accessibility",
  "Landing Article",
  "Landing Event",
  "Expert Profile",
  "How Payment Works",
  "Verify Email Address",
  "Signup NGS",
  "Signup GS",
  "Signup Staff",
  "Signup GS Complete",
  "Signup NCHN",
  "Signup NCHN Complete",
  "Signup NGS Complete",
  "Signup Staff Complete",
  "Case Studies Public",
  "Business Events Webinars",
  "Public Events",
  "Public Articles",
  "Activate User Email",
  "MFA Setup",
];

const activeFooterRoutes = [
  "Home",
  "Get Business Support",
  "Login",
  "Join",
  "Signup Select Type",
  "ExpertSignup",
  "Expert Join",
  "About Us",
  "About",
  "Become GlobalScot",
  "About Network",
  "Artcles and Events",
  "Faq",
  "How Points Work",
  "Verify Email",
  "Forgot Password",
  "Reset Password",
  "Site Map",
  "Accessibility",
  "Landing Article",
  "Landing Event",
  "How Payment Works",
  "Verify Email Address",
  "Signup NGS",
  "Signup GS",
  "Signup Staff",
  "Signup GS Complete",
  "Signup NCHN",
  "Signup NCHN Complete",
  "Signup NGS Complete",
  "Signup Staff Complete",
  "Case Studies Public",
  "Business Events Webinars",

  "Feed",
  "Pulse",
  "Projects",
  "Project",
  "Members",
  "Events and Webinars",
  "Events",
  "Public Events",
  "Public Articles",
  "News and Features",
  "News",
  "GlobalScot Profile",
  "NonGlobalScot Profile",
  "Edit Profile",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Account Details",
  "Notifications",
  "Update Password",
  "Activate User Email",
  /*"Following",
  "Followers",*/
  "Resource",
  "Resources",
];

const activePlatformNavigation = [
  "Feed",
  "Pulse",
  "Members",
  "News and Features",
  "Events and Webinars",
  "News",
  "Events",
  "Public Events",
  "Public Articles",
  "Find Expert",
  "My Bookings",
  "Challenges",
  "Projects",
  "Project",
  "Challenges Details",
  "Expertise",
  "Account",
  "Edit Profile",
  "Settings",
  "Account Details",
  "Notifications",
  "Update Password",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Article",
  "Event",
  "Reward",
  "Expert Profile",
  "GlobalScot Profile",
  "NonGlobalScot Profile",
  "Staff Profile",
  "Message with User",
  "My Messages",
  "Expert New Article",
  "My Chats",
  /*"Following",
  "Followers",*/
  "Resources",
  "Resource",
];

const activeGetinInTouchRoutes = [
  "Login",
  "Signup Select Type",
  "Forgot Password",
  "Reset Password",
  "Verify Email Address",
  "Signup NGS",
  "Signup GS",
  "Signup Staff",
  "Signup GS Complete",
  "Signup NGS Complete",
  "Signup Staff Complete",
  "Signup NCHN",
  "Signup NCHN Complete",
  "Accessibility",
  "Site Map",
  "Feed",
  "Pulse",
  "Projects",
  "Project",
  "Members",
  "Events and Webinars",
  "Events",
  "News and Features",
  "News",
  "GlobalScot Profile",
  "NonGlobalScot Profile",
  "Edit Profile",
  "Personal Details",
  "Companies",
  "Plans and Ambitions",
  "Experience and Expertise",
  "Profile",
  "Account Details",
  "Notifications",
  "Update Password",
  "Activate User Email",
  /*"Following",
  "Followers",*/
  "Resource",
  "Resources",
];

export default {
  components: {
    "v-beta-bar": BetaBar,
    "v-l-header": LandingHeader,
    "v-p-header": PlatformHeader,
    "v-content": Content,
    "v-footer": Footer,
    "v-join-hub": JoinHub,
    "v-ready-to-join": ReadyToJoin,
    "v-not-sure-globalscot": NotSureGlobalscot,
    "v-get-in-touch": GetInTouch,
    "v-cookies": CookieConsent,
    "mobile-menu": MobileMenu,
    "mobile-menu-landing": MobileMenuLanding,
    "top-navigation": TopNavigation,
    "v-notification-dialog": NotificationDialog,
  },
  data: function () {
    return {
      mobileMenuOpen: false,
      mobileMenuOpenLanding: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    isGetInTouchRoute() {
      return activeGetinInTouchRoutes.includes(this.$route.name);
    },
    platformNavigation() {
      return activePlatformNavigation.includes(this.$route.name);
    },
    landingNavigation() {
      return activeLandingNavigationRoutes.includes(this.$route.name);
    },
    footerExists() {
      return activeFooterRoutes.includes(this.$route.name);
    },
    joinHub() {
      return (
        this.$route.path === "/home" ||
        this.$route.path === "/get-support-for-your-buisness" ||
        this.$route.path === "/articles-and-events" ||
        this.$route.path === "/faq" ||
        this.$route.name === "Rewards" ||
        this.$route.name === "Business Events Webinars" ||
        this.$route.name === "Public Events" ||
        this.$route.name === "Case Studies Public" ||
        this.$route.name === "Public Articles"
      );
    },
    readyToJoin() {
      return (
        this.$route.path === "/contact-us" ||
        this.$route.path === "/about-network" ||
        this.$route.path === "/about"
      );
    },
    notSureGlobalscotCheck() {
      return this.$route.path === "/become-a-global-scot";
    },
    showHeader() {
      return true;
    },
    notificationDialogOpen() {
      let modals = store.state.modals;
      return (
        modals.pulse.isOpen ||
        modals.request.isOpen ||
        modals.requestDeclined.isOpen ||
        modals.project.isOpen ||
        modals.endHelpRequest.isOpen ||
        modals.nominateGSModal.isOpen
      );
    },
  },
  methods: {
    openMobileMenu() {
      this.mobileMenuOpen = true;
    },
    openMobileMenuLanding() {
      this.mobileMenuOpenLanding = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    closeMobileMenuLanding() {
      this.mobileMenuOpenLanding = false;
    },
    logout() {
      gsApi.auth.logout(store.state.token).then(() => {
        store.commit("USER_LOGOUT");
        this.$nextTick(() => {
          this.$router.push({ name: "Login" });
        });
      });
    },
  },
};
</script>
