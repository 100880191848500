<template>
  <div class="member-item-image-wrapper">
    <user-avatar
      shape="circle"
      :is-global-scot="member.is_global_scot"
      :author-exist="!!member"
      :size="size"
      :width="`${size}px`"
      :src="member.avatar"
      alt="Profile picture"
    ></user-avatar>
  </div>
</template>

<script>
import helpers from "@/helpers/index";
import UserAvatar from "@/components/core/user-avatar";
export default {
  components:{
    UserAvatar
  },
  props: {
    'size': {
      type: Number,
      required: true,
    },
    'member': {
      type: Object,
      required: true,
    }
  },
  data(){return{helpers}},
  computed: {
    style() {
      return `width: ${this.size}px; height: ${this.size}px;`
    },
    member_letters() {
      return (
        this.member.first_name.substring(0, 1) + this.member.last_name.substring(0, 1)
      ).toUpperCase();
    }
  },
  methods: {
    errorHandler() {
      return true
    },
  }
}
</script>

<style>
    [member-item-image-letters]:before {
        content: attr(member-item-image-letters);
        display: inline-block;
        font-size: 19px;
        font-family: brandon-grotesque, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: bold;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background: #BF3A27;
        vertical-align: middle;
        color: white;
    }

    .members-avatars-group-big [member-item-image-letters]:before {
        height: 74px;
        width: 74px;
        line-height: 74px;
        font-size: 35px;
    }

    .member-item-image-wrapper img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
</style>
