<template>
  <div class="members-page body__content" v-loading.fullscreen.lock="(mainLoader || mainMapLoader)" element-loading-background="rgba(255, 255, 255, 0.8)">
  	<div class="search-section">
  		<div class="container">
  			<el-row>
	  			<el-col :sm="24">
		  			<h1>Search for members</h1>
            <div class="search-btn-container hidden-sm-and-down">
             <ul class="search-buttons">
              <li v-for="(sort, index) in sorts" :key="index" >
                <el-button
                 v-bind:class="{ 'active-state': search.sortBy == sort.name }"
                class="search-btn"
                @click="handleSortChange(sort)">
                <p>{{sort.label}}</p>
                </el-button>
              </li>
            </ul>
            </div>
					</el-col>
				</el-row>
			  <el-form id="searchForm" :model="search" ref="searchForm" @submit.native.prevent>
	        <el-row>
	          <el-col :sm="24" :md="12">
	            <el-form-item label="Keyword search" prop="keyword" class="hidden-sm-and-down">
		            <el-input v-model="search.keyword"
                  placeholder="Search for name, location, company..."
                  id="keyword"
                  auto-complete="no"
                  maxlength="255"
                  @keyup.enter.native="searchMembers"
                />
	          	</el-form-item>
	          	<el-form-item label="" prop="keyword" class="hidden-md-and-up">
		            <el-input v-model="search.keyword"
		            					@keyup.enter.native="searchMembers"
		                      placeholder="Search for name, location, company..."
		                      id="keyword"
		                      auto-complete="no"
		                      maxlength="255">
		            </el-input>
	          	</el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12"  >
              <div class="search-members">
	            <el-button class="search-members-button" type="primary" @click="searchMembers">
	              Search
	            </el-button>
              </div>
	          </el-col>
	        </el-row>
	        <el-row class="hidden-sm-and-down">
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="country" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Country</span>
                  <span class="sr-only">Please select a country</span>
                </template>
                <v-select
                  v-model="search.country"
                  placeholder="Choose from the following"
                  :options="countries"
                  :searchable="true"
                  :clearable="false"
                  :reduce="country => country.id"
                  inputId="country"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
                </v-select>
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="sector" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Sector</span>
                  <span class="sr-only">Please select a sector</span>
                </template>
                <v-select
                  v-model="search.sector"
                  placeholder="Choose from the following"
                  :options="sectors"
                  :searchable="true"
                  :clearable="false"
                  :reduce="sector => sector.id"
                  inputId="sector"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
              <el-form-item prop="expertise" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">Expertise</span>
                  <span class="sr-only">Please select an expertise</span>
                </template>
                <v-select
                  v-model="search.expertise"
                  placeholder="Choose from the following"
                  :options="expertises"
                  :searchable="true"
                  :clearable="false"
                  :reduce="expertise => expertise.id"
                  inputId="expertise"
                  autocomplete="false"
                  label="name"
                  class="half-content" />
              </el-form-item>
	          </el-col>
	          <el-col :sm="24" :md="12" :lg="6">
	          	<el-button type="primary" class="float-right update-results-btn" @click="searchMembers">
	              Update results
	            </el-button>
	           <el-button type="text" class="reset-btn float-right" @click="resetSearch" >Reset</el-button>
            </el-col>
	        </el-row>
	        <el-row class="hidden-md-and-up">
	        	<el-col :sm="24">
              <el-form-item prop="title" class="is-no-asterisk">
                <template slot="label">
                  <span aria-hidden="true">View</span>
                  <span class="sr-only">Please select a view type</span>
                </template>
                <v-select
                  v-model="search.sortBy"
                  :value="search.sortBy"
                  placeholder="Choose from the following"
                  :options="viewOptions"
                  :searchable="false"
                  :clearable="false"
                  :reduce="viewOption => viewOption.key"
                  inputId="view"
                  autocomplete="false"
                  @input="searchMembers"
                  class="half-content" />
              </el-form-item>
	          </el-col>
	         	<el-col :sm="24" class="hidden-md-and-up">
	          	<el-button class="more-filters-btn" size="medium" @click="openMembersFilter">More filters</el-button>
	        	</el-col>
	        </el-row>
      	</el-form>
  		</div>
  	</div>

  	<div class="zero-data text-center mt-5" v-if="showZeroData">
  		<div class="container">
		  	<el-row>
		  		<el-col :sm="24">
	  				<p>We could not find any members with your search criteria. Please update your search and try again.</p>
	  			</el-col>
	  		</el-row>
  		</div>
  	</div>

  	<div id="view-by" class="container view-by-section" v-if="!showZeroData">
      <el-form>
        <el-row>
          <el-col :sm="24" :md="24" :lg="6">
            <el-form-item prop="title" class="is-no-asterisk">
              <template slot="label">
                <span aria-hidden="true">Sort Results</span>
                <span class="sr-only">Please select a country</span>
              </template>
              <v-select
                v-model="sortResult"
                placeholder="Choose from the following"
                :options="sortResults"
                :searchable="true"
                :clearable="false"
                inputId="sortResult"
                autocomplete="false"
                label="name"
                class="half-content"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12">
            <div class="results-text">Results <span>{{ totalResults }}</span></div>
          </el-col>
          <el-col :sm="24" :md="12" :lg="6">
              <div>
              <ul class="map-list-btn">
                <li>
                  <el-button @click="onViewChange('list')" label="list" class="list-button" :class="{'active-view-button': this.search.view == 'list' }">
                    <div class="d-flex"><svg-list-sort></svg-list-sort> <span class="sort-text">List</span></div>
                  </el-button>
                  </li>
                  <li>
                    <el-button label="map" class="map-button" :class="{'active-view-button': this.search.view == 'map' }" v-if="search.sortBy == 'Expert'" @click="onViewChange('map')">
                      <div class="d-flex"><svg-map-sort></svg-map-sort> <span class="sort-text">Map</span></div>
                    </el-button>
                  </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-form>
  	</div>

  	<div class="results-section" v-if="members.length > 0 && search.view == 'list'">
	  	<div class="container">
	  		<div class="mt-4">
		  		<el-row class="card-deck">
		  			<member-card :id="`member_${member.id}`"
              v-for="member in members"
              :key="member.id"
              :member="member" />
		  		</el-row>
	  		</div>
	  	</div>

	  	<div class="pagination-help-section">
	  		<div class="container">
		  		<el-card class="help-card">
		  			<h2>Can’t find the right person? We can help you find the support you need. <a :href="getCMSRoute('contact-us')" aria-label="Click to open contact form in a new tab" class="se-tel">Get in touch</a></h2>
		  		</el-card>
		  		<el-button class="load-more-btn" size="medium" @click="fetchMembers()" v-if="!onLastPage">Load more</el-button>
	  		</div>
	  	</div>
  	</div>

    <div class="results-section" v-if="members.length > 0 && search.view == 'map'">
	  	<div>
	  		<div>
          <div class="map-section" v-if="loadMap && search.view == 'map'">
  	      	<map-view class="map" :markers="markers"></map-view>
        </div>

            </div>
          </div>
  	</div>

  	<members-filter v-if="membersFilterOpen" @close="closeMembersFilter" @search="searchMembers(true)" @reset="resetSearch(true)" v-model="search" :countries="countries" :sectors="sectors" :expertises="expertises"></members-filter>
	</div>
</template>

<script>
  import {mapState} from "vuex"
	import store from '../../store'
	import gsApi from '@/services/gs.api'
	import MapView from '@/components/map-view/map-view'
	import MapSort from '@/components/assets/svg-map-sort'
	import ListSort from '@/components/assets/svg-list-sort'
	import MemberCard from '@/components/platform/members/member-card'
	import MembersFilter from '@/components/slide-panel/panels/members-filter'
  import _ from 'lodash'

  export default {
    name: 'members',
    metaInfo: {
      title: "Members"
    },
  	components: {
  		'map-view': MapView,
  		'svg-map-sort': MapSort,
  		'svg-list-sort': ListSort,
  		'member-card': MemberCard,
    	'members-filter': MembersFilter
    },
    data() {
      return {
      	mainLoader: false,
        mainMapLoader: false,
      	page: 1,
      	perPage: 12,
      	totalResults: 0,
      	showZeroData: false,
      	onLastPage: false,
        loadMap: false,
      	search: {},
        viewOptions: [
          {
            key: 'All',
            label: 'All'
          },
          {
            key: 'Expert',
            label: 'GlobalScots'
          }
        ],
      	markers: [],
      	members: [],
      	countries: [],
      	sectors: [],
      	expertises: [],
        membersFilterOpen: false,
        sorts:[
          {label: "GlobalScots", name: "Expert"},
           {label: "All", name: "All"},
        ],
        currentZoom: 2,
        currentBounds: null,
        sortResults: [
          { id: 1, name: 'A-Z City location', order_by: 'state_name', order: 'asc'},
          { id: 2, name: 'Z-A City location', order_by: 'state_name', order: 'desc'},
          { id: 3, name: 'Most recently joined date', order_by: 'created_at', order: 'desc'},
          { id: 4, name: 'Oldest joined date', order_by: 'created_at', order: 'asc'},
          { id: 5, name: 'A-Z Surname', order_by: 'last_name', order: 'asc'},
          { id: 6, name: 'Z-A Surname', order_by: 'last_name', order: 'desc'},
          { id: 7, name: 'A-Z First name', order_by: 'first_name', order: 'asc'},
          { id: 8, name: 'Z-A First name', order_by: 'first_name', order: 'desc'},
        ],
        sortResult: null,
        scrollTo: null,
      }
    },
    computed: {
      ...mapState(['user', 'scrollToMember']),
    },
    created(){
      let search = {
        sortBy: this.$store.state.search.sortBy ?? 'Expert',
        keyword: this.$store.state.search.keyword,
        country: this.$store.state.search.country,
        sector: this.$store.state.search.sector,
        expertise: this.$store.state.search.expertise,
        view: this.$store.state.search.view,
        order_by: this.$store.state.search.order_by,
        order: this.$store.state.search.order,
        page: this.$store.state.search.page,
      }
      if(this.$route.query.hasOwnProperty('sortBy')){
        search.sortBy = this.$route.query.sortBy
      }
      if(this.$route.query.hasOwnProperty('keyword')){
        search.keyword = this.$route.query.keyword
      }
      if(this.$route.query.hasOwnProperty('country')){
        search.country = parseInt(this.$route.query.country)
      }
      if(this.$route.query.hasOwnProperty('sector')){
        search.sector = parseInt(this.$route.query.sector)
      }
      if(this.$route.query.hasOwnProperty('expertise')){
        search.expertise = parseInt(this.$route.query.expertise)
      }
      if(this.$route.query.hasOwnProperty('view')){
        search.view = this.$route.query.view
        // search.view = 'list' // Temp until we fix the map
      }
      if(this.$route.query.hasOwnProperty('order_by')){
        search.order_by = this.$route.query.order_by
      }
      if(this.$route.query.hasOwnProperty('order')){
        search.order = this.$route.query.order
      }
      if(this.$route.query.hasOwnProperty('page')){
        search.page = this.$route.query.page
      }

      this.search = search

      if(store.state.user.isLogged){
        this.getRegisterMeta()
        this.fetchMapMarkers()
      }

    },
    watch: {
      search: {
        handler(val){
          let params = _.pickBy(val, function(o) { return o !== null && o !== undefined })
          this.addParamsToLocation(params)
        },
        deep: true
      },

      sortResult(val) {
        this.search.order_by = val.order_by
        this.search.order = val.order
        this.searchMembers()
      }
    },
    methods: {
      addParamsToLocation(params) {
        history.pushState(
          {},
          null,
          this.$route.path +
          '?' +
          Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
              )
          })
          .join('&')
          )
      },
    	onViewChange(viewType) {
       this.search.view = viewType

      	if (this.search.view == 'map') {
          if (!this.loadMap) {
            this.fetchMapMarkers()
          }
      	}
        this.searchMembers()
    	},
    	generateMarkers(markerData) {
        this.clearAllMarkers()
        markerData.data.forEach((clusterMarker) => {

          if (clusterMarker.lat && clusterMarker.lng) {

            let marker = {
              id: clusterMarker.id,
              position: {lat: parseFloat(clusterMarker.lat), lng: parseFloat(clusterMarker.lng)},
              stateId: clusterMarker.state_id,
              avatar: clusterMarker.avatar,
              memberId: clusterMarker.user_id,
              name: clusterMarker.fullname

            }
            this.markers.push(marker)
          }
        })
    	},
      clearAllMarkers() {
        this.$events.emit('deleteAllMarkers')
        this.markers = []
      },
      getRegisterMeta() {
        this.mainLoader = true

        gsApi.globalscot.meta.index()
        .then(response => {
          this.countries = response.data.countries.data
          this.sectors = response.data.groups.data
          this.expertises = response.data.expertise.data
          const page = this.$store.state.search.page;
          this.fetchMembers(this.perPage * page) 
          
        }).catch(error => {
          this.mainLoader = false
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
      },
      resetSearch(isMobile) {
      	if (isMobile) {
      		this.closeMembersFilter()
      	}

      	this.search = {
      		sortBy: 'Expert',
      		keyword: '',
      		country: null,
      		sector: null,
      		expertise: null,
      		view: 'list',
          order_by: '',
          order: ''
      	}

        this.sortResult = null

      	this.searchMembers()
      },
      searchMembers(isMobile) {

        this.search.page = 1;
        this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)
      	if (isMobile) {
      		this.closeMembersFilter()
      	}

        if (this.search.sortBy == "All") {
          this.search.view = 'list'
        }

      	this.members = []
        // this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)
        this.fetchMembers()
        this.fetchMapMarkers()
      },
      fetchMembers(perPage = null) {
      	if (!this.mainLoader) {
      		this.mainLoader = true
      	}

        this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)

      	let params = {
      		page: perPage == null ? this.search.page : 1,
      		per_page: perPage ? perPage : this.perPage,
      		member_type: this.search.sortBy,
      		country: this.search.country,
      		group: this.search.sector,
      		expertise: this.search.expertise,
      		search: this.search.keyword,
          order_by: this.search.order_by,
          order: this.search.order
      	}

      	gsApi.members.search(params)
        .then(response => {
          this.mainLoader = false
          this.totalResults = response.data.meta.pagination.total

          if (response.data.meta.pagination.current_page !== response.data.meta.pagination.total_pages) {
          	this.onLastPage = false
            this.search.page = response.data.meta.pagination.current_page + 1;
            this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)
          } else {
          	this.onLastPage = true
          }

          //this.members = this.members.concat(response.data.data )
           // workaround to avoid showing duplicate members
          const currentMembers = _.map(this.members,"id")

          this.members = this.members.concat(response.data.data.filter( el => {
            return !currentMembers.includes(el.id)
          }))

          if(this.scrollToMember){
            this.$nextTick(()=>{
              this.scrollToElement(this.scrollToMember)
            })
          }
          
          this.showZeroData = this.members.length > 0 ? false : true
        }).catch(error => {
          this.mainLoader = false
        })
      },
      fetchMapMarkers() {
        this.mainMapLoader = true

        let params = {
          member_type: this.search.sortBy,
          country_id: this.search.country,
          group_id: this.search.sector,
          expertise_id: this.search.expertise,
          search: this.search.keyword,
          view: this.search.view,
          order_by: this.search.order_by,
          order: this.search.order,
        }

        gsApi.members.mapSearch(params)
          .then(response => {
            if (response.data) {
              this.loadMap = true
              this.generateMarkers(response.data)
            } else {
              this.markers = []
            }

            this.mainMapLoader = false
          }).catch(error => {
            this.mainMapLoader = false
          })
      },
      openMembersFilter() {
        this.membersFilterOpen = true;
      },
      closeMembersFilter() {
        this.membersFilterOpen = false;
      },
      handleSortChange(sort) {
        this.search.sortBy = sort.name
        this.searchMembers()
      },
      scrollToElement(elementRef) {
          const el = document.getElementById(elementRef);
          if (el) {
            el.scrollIntoView({behavior: 'smooth'});
            this.$store.commit('SET_SCROLL_TO_MEMBER',null)
          }
      }
    },
    beforeRouteLeave(to, from, next) {

      if( !['GlobalScot Profile', 'NonGlobalScot Profile'].includes(to.name)){
        this.search.page = 1;
        this.$store.commit('UPDATE_MEMBER_SEARCH', this.search)
      }
      next()
      
    },
  }
</script>
<style >

.map-list-btn{
  list-style: none;
  display: inline-flex;
}



</style>
