<template>
  <div class="white-background-section">
    <div class="container">
      <div class="heading-text mb-30 py-0 d-flex justify-content-between">
        <h1 class="mission-title">{{howDoesItWorkText.heading}}</h1>
      </div>
      <div>
        <el-row>
          <el-col
            class="card-container"
            :sm="24"
            :md="12"
            :lg="6"
            :xl="6"
            v-for="(card, index) in boxCards"
            :key="index"
          >
            <el-card class="box-card benefit-card-steps" shadow="always">
              <div class="benefit-card-steps">
                <div class="benefit-sub-text">
                  <h2 class="card-step">{{card.step}}</h2>
                  <p class="card-heading">{{card.heading}}</p>
                  <p class="card-sub">{{card.text}}</p>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import Grow from "@/components/assets/svg-grow";
import Support from "@/components/assets/svg-support";
import Secure from "@/components/assets/svg-secure";

export default {
  components: {
    "svg-grow": Grow,
    "svg-support": Support,
    "svg-secure": Secure
  },
  data() {
    return {
      howDoesItWorkText: {
        heading: "How does it work?"
      },
      boxCards: [
        {
          step: "Step 1",
          heading: "Register",
          text:
            "Tell us about yourself and your business. We’ll use this to create your profile on the network."
        },
        {
          step: "Step 2",
          heading: "Search for GlobalScots",
          text:
            "Our handy search tool lets you find exactly the connections you need. Search by sector, location, expertise and more."
        },
        {
          step: "Step 3",
          heading: "Submit a request",
          text:
            "Once you’ve found the right GlobalScot, submit a request. Tell them a bit about what you’re looking for and what you’re hoping to achieve."
        },
        {
          step: "Step 4",
          heading: "Work together",
          text:
            "If the GlobalScot accepts your request, you’ll be able to message them and start working together. At the end, you can give feedback to let us know how it went."
        }
      ]
    };
  }
};
</script>
<style>
.card-step {
  font-size: 35px;
  font-weight: 400;
  margin: 30px 0 30px 0;
  width: 200px;
}
.card-heading {
  font-weight: bold;
  margin: 30px 0 30px 0;
  font-size: 20px;
  width: 200px;
}
.card-sub {
  width: 200px;
  font-size: 20px;
}
</style>
