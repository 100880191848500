<!-- SETTINGS -->

<template>
  <div class="settings-page">
    <div class="container">
      <el-row>
        <el-col :md="7" class="profile-sections">
          <h2>Settings</h2>
          <ul class="section-tabs">
            <li>
              <router-link to="/settings/account-details">Account details</router-link>
            </li>
          </ul>
          <ul class="section-tabs">
            <li>
              <router-link to="/settings/notifications">Notifications</router-link>
            </li>
          </ul>
        </el-col>
        <el-col :md="17" class="profile-forms">
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "settings-page",

    metaInfo: {
      title: "Settings",
    },
  }
</script>
