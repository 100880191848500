<template>
  <div class="expected-title">
    <h1>{{expectedText.heading}}</h1>
    <p class="expected-sub-text">
      Being a GlobalScot is an active role and you’ll be expected to participate where you can. We’ll review membership each year to make sure the network stays vibrant and responsive to the needs of the business ecosystem in Scotland.
    </p>
    <p class="expected-sub-text">
      Read our <a :href="getCMSRoute('code-of-conduct-for-users')">Code of Conduct</a> for some guidance around what we expect. 
    </p>

  </div>
</template>
<script>
export default {
  data() {
    return {
      expectedText: {
        heading: "What's expected of me?"
      }
    };
  }
};
</script>
<style>
.expected-title h1 {
  padding-top: 20px;
  font-weight: 500;
  margin: auto;
  color: black;
  font-size: 35px;
  margin-top: 30px;
}
.expected-sub-text {
  font-size: 19px;
  margin-top: 30px;
  font-weight: 390;
  line-height: 28px;
}
</style>
