<template>
  <div
    class="company-item"
    v-loading="loader">
    <div class="company-header d-flex">
      <div class="company-details">
        <span class="company-name">
          {{ company.name }}
        </span>
      </div>
      <div class="company-actions">
        <button
          class="mark-as-primary"
          @click="removeConnection()"
        >
          Remove
        </button>
      </div>
    </div>
    <div class="step-hr" v-if="showDivider"></div>
  </div>
</template>

<script>
import gsApi from '@/services/gs.api'

export default {
  name: "gs-other-company-item",
  props: {
    company: {
      type: Object,
      default: () => {}
    },

    showDivider: {
      type: Boolean,
      default: true
    }
  },

  data() {
      return {
        loader: false,
      }
  },

  methods: {
    removeConnection() {
      this.loader = true

      gsApi.globalscot.user
        .deleteOtherBusiness(this.company)
        .then(({data}) => {
          this.$store.commit('UPDATE_BUSINESSES', data.businesses.data)
          this.$alert(`You successfully deleted your connection ${this.company.name}.`, 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch((error) => {
          if (error.response.status == 422) {
            let firstError =
              error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert(firstError[0], 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$alert('Sorry, your request could not be processed', `Error ${error.response.status} - ${error.response.data.message}`, { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },
}
</script>

<style lang="scss">
.company-item {
  //margin-top: 25px;
  //margin-bottom: 50px;
  &:last-child {
    .step-hr {
      display: none;
    }
  }
  .company-header {
    justify-content: space-between;
    align-items: center;
    .company-details {
      display: grid;
      line-height: 28px;
      .company-name {
        color: $primary;
      }
    }
    button {
      font-family: brandon-grotesque;
      &.is-primary {
        font-weight: 420;
        font-size: 16px;
        color: $white;
        background-color: #EA6576;
        border-radius: 19px;
        padding: 1px 16px;
        margin-right: 75px;
      }
      &.mark-as-primary {
        font-weight: 420;
        font-size: 19px;
        text-decoration: underline;
        color: #700E57;
        margin-right: 55px;
      }
      &.details-hidden {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.details-show {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
      &.remove {
        font-weight: 420;
        font-size: 19px;
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
</style>
