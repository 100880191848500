<template><svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1515 6.00003C16.533 6.00003 16 6.62546 16 7.13957V53.8605C16 54.457 16.5488 55 17.1515 55H52.8485C53.4512 55 54 54.4569 54 53.8605V17.3954C54 17.0927 53.8763 16.79 53.6581 16.5763L43.2945 6.32053C43.0808 6.11576 42.784 5.9978 42.4849 6.00003H17.1515ZM18.303 8.2791H41.3333V17.3954C41.3333 17.9919 41.8821 18.5349 42.4848 18.5349H51.697V52.7209H18.303V8.2791ZM43.6364 9.88157L50.0779 16.2561H43.6364V9.88157ZM22.9091 25.3724C22.2726 25.3724 21.7576 25.8821 21.7576 26.5119C21.7576 27.1418 22.2726 27.6515 22.9091 27.6515H47.0909C47.7274 27.6515 48.2424 27.1418 48.2424 26.5119C48.2424 25.8821 47.7274 25.3724 47.0909 25.3724H22.9091ZM22.9091 34.4887C22.2726 34.4887 21.7576 34.9983 21.7576 35.6282C21.7576 36.2581 22.2726 36.7677 22.9091 36.7677H47.0909C47.7274 36.7677 48.2424 36.2581 48.2424 35.6282C48.2424 34.9983 47.7274 34.4887 47.0909 34.4887H22.9091ZM22.9091 43.6049C22.2726 43.6049 21.7576 44.1146 21.7576 44.7445C21.7576 45.3743 22.2726 45.884 22.9091 45.884H47.0909C47.7274 45.884 48.2424 45.3743 48.2424 44.7445C48.2424 44.1146 47.7274 43.6049 47.0909 43.6049H22.9091Z" fill="url(#paint0_linear)"/>
<path d="M37 11.5V49L1 48.5L1.5 1H26.5L37 11.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.07354e-05 48.9576C5.07354e-05 49.5338 0.472573 50.0001 1.05645 50.0001H36.9436C37.5275 50.0001 38 49.5338 38 48.9576V10.8326C38 10.5567 37.8887 10.293 37.6908 10.0977L27.7677 0.305175C27.5698 0.109863 27.3026 0 27.023 0H1.0564C0.472542 0 0 0.466307 0 1.0425L5.07354e-05 48.9576ZM35.8897 47.9175H2.11032V2.08249H25.3334V11.4575C25.3334 12.0337 25.8059 12.5 26.3897 12.5H35.8897V47.9175ZM35.0238 10.4175H27.4435V2.93687L35.0238 10.4175Z" fill="url(#paint1_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.8773 41.6674C32.7085 41.6674 33.2132 40.7666 32.7728 40.0732L24.1165 26.4282C23.7033 25.7763 22.7434 25.7763 22.3278 26.4257L17.8623 33.4301L14.6115 28.4374C14.1959 27.7978 13.2459 27.7978 12.8328 28.4399L5.317 40.0661C4.86921 40.7595 5.3739 41.6677 6.20759 41.6677L31.8773 41.6674ZM13.7354 30.9474L16.9862 35.9474C17.4043 36.5895 18.3568 36.5846 18.7675 35.9377L23.2231 28.9577L29.9624 39.5827H8.12697L13.7354 30.9474Z" fill="url(#paint2_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.7777 21.875C14.7777 20.1489 13.3601 18.75 11.611 18.75C9.86192 18.75 8.44434 20.1489 8.44434 21.875C8.44434 23.6011 9.86192 25 11.611 25C13.3601 25 14.7777 23.6011 14.7777 21.875ZM11.611 22.9175C12.1949 22.9175 12.6674 22.4512 12.6674 21.875C12.6674 21.2988 12.1949 20.8325 11.611 20.8325C11.0296 20.8325 10.5571 21.2988 10.5571 21.875C10.5571 22.4512 11.0296 22.9175 11.611 22.9175Z" fill="url(#paint3_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="48.7894" y1="19.0617" x2="4.42075" y2="45.4078" gradientUnits="userSpaceOnUse">
<stop stop-color="#AF006E"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="32.7894" y1="13.3282" x2="-12.0421" y2="39.4168" gradientUnits="userSpaceOnUse">
<stop stop-color="#AF006E"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="29.1256" y1="30.1317" x2="13.6177" y2="51.1083" gradientUnits="userSpaceOnUse">
<stop stop-color="#AF006E"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="13.9092" y1="20.416" x2="7.66576" y2="25.2603" gradientUnits="userSpaceOnUse">
<stop stop-color="#AF006E"/>
<stop offset="0.517454" stop-color="#EA6576"/>
<stop offset="1" stop-color="#F7F248"/>
</linearGradient>
</defs>
</svg></template><script>export default { }</script>
