<template>
  <div
    class="signup-ngs-page page"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-${currentStep}`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div class="header__content__wrapper" id="main-content">
              <h2 class="header__title" v-html="heroText"></h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <el-row>
          <el-col :md="24" :lg="20" :xl="16">
            <!-- Step 0 Intro -->
            <div v-show="currentStep === 0" class="body__content">
              <h2 class="body__title">
                Registering without a Companies House number
              </h2>
              <p class="se-body-l-screen mb-4">
                Our main registration form only allows companies with a
                Companies House number associated with a Scottish company to
                register. If you think you’re eligible to register but don’t
                have an appropriate Companies House number (for example, your
                company is not yet registered at Companies House, you work for a
                company that’s registered outside Scotland but has a main
                trading address in Scotland, or your company isn’t required to
                be registered at Companies House) you can fill in our manual
                registration form.
              </p>
              <p class="se-body-l-screen mb-4">
                We’ll need to review the information you provide to make sure
                you’re eligible for GlobalScot support. Once you’ve submitted
                your form, we’ll get back to you within 5 days.
              </p>
              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="gotoStep(1)"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 0 Intro End -->

            <!-- Step 1 Personal and Company details -->
            <div v-show="currentStep === 1" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(0)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 1"
                :errors="validationErrors"
              ></validation-errors>


              <el-form
                :model="step1Form.form"
                :rules="step1FormRules"
                ref="step1Form"
                id="step1Form"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step1Form')"
              >

                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Personal and company details
                </h1>

                <p class="se-body-l-screen mb-4">
                  In this section, tell us about you and the companies that
                  you'd like our GlobalScots to support.
                </p>

                <div class="step-hr" />

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="reasons">
                      <span aria-hidden="true"
                        >My business does not have a Scottish Companies House number (starting with ‘S’) because:<span class="is-custom-asterisk">*</span></span
                      ><br />
                      <label class="sr-only" for="reasons"
                        >Please select a reason
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor('step1Form', 'reason')
                          "
                          v-text="
                            getInlineValidationErrorFor('step1Form', 'reason')
                          "
                        ></span>
                      </label>
                      <span class="el-form-item__info"
                        >This won't be publicly displayed on your profile</span
                      >
                    </label>
                    <el-form-item prop="reason" class="is-no-asterisk">
                      <single-select
                        id="reasons"
                        :options="step1Form.reasons"
                        v-model="step1Form.form.reason"
                        name="reason"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <!--ul class="se-ul-large-dots">
                  <li>Our main trading address is in Scotland but we’re registered elsewhere</li>
                  <li>Our company is pre-revenue and not yet registered</li>
                  <li>We’re not required to be registered at Companies House</li>
                  <li>Other</li>
                </!--ul-->

                <div class="step-hr" />

                <h2 class="se-heading-m mb-4">Personal details</h2>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="title">
                      <span aria-hidden="true"
                        >Title<span class="is-custom-asterisk">*</span></span
                      ><br />
                      <label class="sr-only" for="title"
                        >Please select a title
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor('step1Form', 'title')
                          "
                          v-text="
                            getInlineValidationErrorFor('step1Form', 'title')
                          "
                        ></span>
                      </label>
                      <span class="el-form-item__info"
                        >This won't be publicly displayed on your profile</span
                      >
                    </label>
                    <el-form-item prop="title" class="is-no-asterisk">
                      <single-select
                        id="title"
                        :options="step1Form.titles"
                        v-model="step1Form.form.title"
                        name="title"
                        class="half-content"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="first_name">
                      First name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'first_name'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'first_name')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="first_name">
                      <el-input
                        v-model="step1Form.form.first_name"
                        @blur="
                          step1Form.form.first_name = step1Form.form.first_name.trim()
                        "
                        id="first_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your first name in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="last_name">
                      Last name
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'last_name')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'last_name')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="last_name">
                      <el-input
                        v-model="step1Form.form.last_name"
                        @blur="
                          step1Form.form.last_name = step1Form.form.last_name.trim()
                        "
                        id="last_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your last name in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="email">
                      Email address<span class="is-custom-asterisk">*</span
                      ><br />
                      <span class="el-form-item__info"
                        >This will not be displayed on your profile</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step1Form', 'email')
                        "
                        v-text="
                          getInlineValidationErrorFor('step1Form', 'email')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="email" class="is-no-asterisk">
                      <el-input
                        v-model="step1Form.form.email"
                        @blur="
                          step1Form.form.email = step1Form.form.email.trim()
                        "
                        id="email"
                        type="email"
                        autocomplete="false"
                        label="Please enter your desired email address in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16">
                    <label for="email_confirmation"
                      >Confirm email address
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'email_confirmation'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step2Form',
                            'email_confirmation'
                          )
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="email_confirmation">
                      <el-input
                        v-model="step1Form.form.email_confirmation"
                        @blur="
                          step1Form.form.email_confirmation = step1Form.form.email_confirmation.trim()
                        "
                        id="email_confirmation"
                        type="email"
                        autocomplete="false"
                        label="Please confirm the desired email address in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-4">
                    <label for="contact_number">
                      Contact number<span class="is-custom-asterisk">*</span
                      ><br />
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step1Form',
                            'contact_number'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step1Form',
                            'contact_number'
                          )
                        "
                      ></span>
                    </label>
                    <span class="el-form-item__info"
                      >This won't be publicly displayed on your profile</span
                    >
                    <el-form-item prop="contact_number" class="is-no-asterisk">
                      <el-input
                        v-model="step1Form.form.contact_number"
                        @blur="
                          step1Form.form.contact_number = step1Form.form.contact_number.trim()
                        "
                        id="contact_number"
                        class="w-lg-75"
                        maxlength="20"
                        autocomplete="false"
                        label="Please enter your contact number in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="step-hr"></div>
                <div
                  id="businesses-section"
                  v-if="step1Form.form.businesses.length > 0"
                >
                  <h2 class="se-heading-m mb-4">Edit company details</h2>

                  <el-row>
                    <manage-business-nchn
                      :business="business"
                      :groups="newCompanyForm.groups"
                      :key="index"
                      @update-business="onUpdateBusiness(business, $event)"
                      @remove-business="onRemoveBusiness(business, $event)"
                      v-for="(business, index) in step1Form.form.businesses"
                    />
                  </el-row>
                  <div class="step-hr mt-4" />
                </div>

                <el-form
                  id="newCompanyForm"
                  :model="newCompanyForm.company"
                  :rules="newCompanyFormRules"
                  ref="newCompanyForm"
                  autocomplete="false"
                  @submit.native.prevent
                  @validate="handleFormFieldInput('newCompanyForm')"
                >
                  <h2
                    class="se-heading-m mb-4"
                    v-if="step1Form.form.businesses.length == 0"
                  >
                    Add a current company
                  </h2>
                  <h2
                    class="se-heading-m mb-4"
                    v-if="step1Form.form.businesses.length > 0"
                  >
                    Details for additional company
                  </h2>

                  <div
                    v-if="
                      step1Form.form.businesses.length > 0 &&
                        !newCompanyForm.additionalCompanyFormOpen
                    "
                  >
                    <p class="se-body-l-screen mb-4">
                      If you want add more than one company, you can add
                      additional companies here.
                    </p>
                    <el-row class="mt-2 mb-4">
                      <el-col :md="24" :lg="15" class="d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="openAdditionalCompanyForm"
                        >
                          Add another company
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>

                  <div
                    v-if="
                      step1Form.form.businesses.length == 0 ||
                        newCompanyForm.additionalCompanyFormOpen
                    "
                  >
                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item
                          class="company-house-form-item is-no-asterisk"
                          prop="name"
                        >
                          <template slot="label"
                            >Company name<span class="is-custom-asterisk"
                              >*</span
                            ><br />
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'input_company_name'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'input_company_name'
                                )
                              "
                            ></span>
                          </template>

                          <el-input
                            v-model="newCompanyForm.company.name"
                            @blur="
                              newCompanyForm.company.name = newCompanyForm.company.name.trim()
                            "
                            id="company_name"
                            label="Please enter your company name in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <h2 class="se-heading-m my-4">Scottish trading address</h2>
                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item prop="address_1">
                          <template slot="label">
                            Address line 1
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'address_1'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'address_1'
                                )
                              "
                            ></span>
                          </template>
                          <el-input
                            v-model="newCompanyForm.company.address_1"
                            @blur="
                              newCompanyForm.company.address_1 = newCompanyForm.company.address_1.trim()
                            "
                            id="address_1"
                            maxlength="255"
                            autocomplete="false"
                            label="Please enter your company address line 1 in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item prop="address_2">
                          <template slot="label">
                            Address line 2
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'address_2'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'address_2'
                                )
                              "
                            ></span>
                          </template>
                          <el-input
                            v-model="newCompanyForm.company.address_2"
                            @blur="
                              newCompanyForm.company.address_2 = newCompanyForm.company.address_2.trim()
                            "
                            id="address_2"
                            maxlength="255"
                            autocomplete="false"
                            label="Please enter your company address line 2 in this field"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item prop="town">
                          <template slot="label">
                            Town/City
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'town'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'town'
                                )
                              "
                            ></span>
                          </template>
                          <el-input
                            v-model="newCompanyForm.company.town"
                            @blur="
                              newCompanyForm.company.town = newCompanyForm.company.town.trim()
                            "
                            id="town"
                            maxlength="100"
                            autocomplete="false"
                            label="Please enter your company town/city in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :md="24" :lg="18">
                        <el-form-item prop="post_code">
                          <template slot="label">
                            Postcode
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'post_code'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'post_code'
                                )
                              "
                            ></span>
                          </template>
                          <el-input
                            v-model="newCompanyForm.company.post_code"
                            @blur="
                              newCompanyForm.company.post_code = newCompanyForm.company.post_code.trim()
                            "
                            id="post_code"
                            class="half-content"
                            maxlength="10"
                            autocomplete="false"
                            label="Please enter your company postcode in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :md="24" :lg="20">
                        <el-form-item prop="role">
                          <template slot="label"
                            >Your role at company
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'role'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'role'
                                )
                              "
                            ></span>
                          </template>
                          <el-input
                            v-model="newCompanyForm.company.role"
                            @blur="
                              newCompanyForm.company.role = newCompanyForm.company.role.trim()
                            "
                            id="role"
                            autocomplete="false"
                            maxlength="255"
                            label="Please enter your company role in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <div v-loading="newCompanyForm.groupsLoading">
                      <el-row>
                        <el-col :md="24" :lg="20" class="d-flex flex-column">
                          <label for="id-group-label">
                            <span class="se-form-label-l-screen">
                              Your company's sector<span
                                class="is-custom-asterisk"
                                >*</span
                              >
                            </span>
                            <span
                              class="sr-only"
                              aria-live="polite"
                              :hidden="
                                !hasInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'group_ids'
                                )
                              "
                              v-text="
                                getInlineValidationErrorFor(
                                  'newCompanyForm',
                                  'group_ids'
                                )
                              "
                            ></span>
                          </label>
                          <span
                            class="se-form-label-l-screen color-light-gray mb-4"
                          >
                            You can select multiple sectors
                          </span>

                          <el-form-item prop="group_ids" class="is-no-asterisk">
                            <checkbox-group
                              aria-label="Your company's sector, Please check one or more sectors"
                              id="id-group-label"
                              aria-labelledby="id-group-label"
                              name="group_ids"
                              :groups="newCompanyForm.groups"
                              v-model="newCompanyForm.company.group_ids"
                              @input="updateGroupIds"
                              @clicked="selectedGroupsUpdated"
                            ></checkbox-group>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-row
                        v-if="newCompanyForm.company.edit_sub_groups.length > 0"
                      >
                        <el-col :md="24" :lg="20">
                          <el-form-item prop="sub_group_ids">
                            <template slot="label">
                              <span aria-hidden="true"
                                >Your company's sub sectors - Optional</span
                              >
                              <span class="sr-only"
                                >Please select one or more sub sectors</span
                              >
                            </template>
                            <v-select
                              v-model="newCompanyForm.company.sub_group_ids"
                              :options="newCompanyForm.company.edit_sub_groups"
                              :multiple="true"
                              :clearable="false"
                              label="name"
                              :reduce="subGroup => subGroup.id"
                              inputId="sub_group_ids"
                              autocomplete="false"
                              :select-on-tab="true"
                              :close-on-select="false"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                    <el-row class="mt-4 mb-4">
                      <el-col :md="24" :lg="15" class="d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="submitNewCompanyForm"
                        >
                          <span>Add company</span>
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-form>

                <div class="step-hr mt-4" />

                <h2 class="se-heading-m mb-2">Mailing Address</h2>
                <p class="se-body-l-screen mb-4">
                  Please select your preferred mailing address. We'll use this
                  if we need to get in touch by post.
                </p>

                <el-row>
                  <el-radio-group
                    v-model="step1Form.mailingOption"
                    @change="onMailingOptionSelected"
                    aria-label="Select an address"
                  >
                    <el-col
                      :md="24"
                      class="mb-4"
                      v-for="(business, index) in step1Form.form.businesses"
                      :key="index"
                    >
                      <el-radio :label="business.name">
                        <span class="color-primary">{{ business.name }}</span
                        ><br />
                        <span v-if="business.address_1"
                          >{{ business.address_1 }},</span
                        >
                        <span v-if="business.address_2"
                          >{{ business.address_2 }},<br
                        /></span>
                        <span v-if="business.town">{{ business.town }},</span>
                        <span v-if="business.post_code">{{
                          business.post_code
                        }}</span>
                      </el-radio>
                    </el-col>
                    <el-col :md="24" class="mb-4">
                      <el-radio label="other">Other</el-radio>
                    </el-col>
                  </el-radio-group>
                </el-row>

                <div
                  v-if="step1Form.mailingOption == 'other'"
                  class="d-flex flex-column align-items-stretch"
                >
                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_post_code">
                        Post code
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_post_code'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_post_code'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_post_code">
                        <div class="d-flex">
                          <el-input
                            class="half-flex-content"
                            v-model="step1Form.form.mailing_post_code"
                            @blur="
                              step1Form.form.mailing_post_code = step1Form.form.mailing_post_code.trim()
                            "
                            @input="step1Form.disableAddressLookup = false"
                            maxlength="10"
                            id="mailing_post_code"
                            autocomplete="false"
                            label="Please enter mailing post code in this field"
                            aria-required="true"
                            @keyup.enter.native="onFindAddress($event)"
                          />

                          <div class="mr-3"></div>

                          <el-form-item class="half-flex-content">
                            <el-button
                              size="medium"
                              @click="onFindAddress($event)"
                              :disabled="
                                step1Form.disableAddressLookup ||
                                  step1Form.form.mailing_post_code.trim()
                                    .length == 0
                              "
                              >Find address</el-button
                            >
                          </el-form-item>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div
                    v-if="step1Form.addressesLoading"
                    v-loading="step1Form.addressesLoading"
                    class="address-loader"
                  ></div>

                  <el-row v-if="step1Form.availableMailingAddresses.length > 0">
                    <el-col :md="24" :lg="20">
                      <el-form-item>
                        <template slot="label">
                          <span aria-hidden="true">Addresses Found</span>
                          <span class="sr-only">Please select an address</span>
                          <span
                            class="sr-only"
                            aria-live="polite"
                            :hidden="
                              !hasInlineValidationErrorFor(
                                'step1Form',
                                'available_mailing_addresses'
                              )
                            "
                            v-text="
                              getInlineValidationErrorFor(
                                'step1Form',
                                'available_mailing_addresses'
                              )
                            "
                          ></span>
                        </template>
                        <single-select
                          :options="step1Form.availableMailingAddresses"
                          v-model="step1Form.selectedAvailableMailingAddress"
                          name="available_mailing_addresses"
                          @input="onAvailableMailingAddressSelected($event)"
                        ></single-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_address_1">
                        Address line 1
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_1'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_1'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_address_1">
                        <el-input
                          v-model="step1Form.form.mailing_address_1"
                          @blur="
                            step1Form.form.mailing_address_1 = step1Form.form.mailing_address_1.trim()
                          "
                          id="mailing_address_1"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 1 in this field"
                          aria-required="true"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="20">
                      <label for="mailing_address_2">
                        Address line 2
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_2'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_address_2'
                            )
                          "
                        ></span>
                      </label>
                      <el-form-item prop="mailing_address_2">
                        <el-input
                          v-model="step1Form.form.mailing_address_2"
                          @blur="
                            step1Form.form.mailing_address_2 = step1Form.form.mailing_address_2.trim()
                          "
                          id="mailing_address_2"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 2 in this field"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="18">
                      <label for="mailing_town">
                        Town/City
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_town'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_town'
                            )
                          "
                        ></span>
                      </label>
                      <span class="is-custom-asterisk">*</span><br />
                      <el-form-item prop="mailing_town">
                        <el-input
                          v-model="step1Form.form.mailing_town"
                          @blur="
                            step1Form.form.mailing_town = step1Form.form.mailing_town.trim()
                          "
                          id="mailing_town"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing town or city in this field"
                          aria-required="true"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :md="24" :lg="18">
                      <label for="mailing_country">
                        <span aria-hidden="true">Country</span>
                        <span class="sr-only"
                          >Please select mailing country</span
                        >
                        <span
                          class="sr-only"
                          aria-live="polite"
                          :hidden="
                            !hasInlineValidationErrorFor(
                              'step1Form',
                              'mailing_country_id'
                            )
                          "
                          v-text="
                            getInlineValidationErrorFor(
                              'step1Form',
                              'mailing_country_id'
                            )
                          "
                        ></span>
                      </label>
                      <el-form-item prop="mailing_country_id">
                        <single-select
                          :options="step1Form.countries"
                          v-model="step1Form.form.mailing_country_id"
                          name="mailing_country_id"
                          id="mailing_country"
                        ></single-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep1Form"
                >
                  Continue
                </el-button>
              </div>
            </div>
            <!-- Step 1 Personal and Company details End -->

            <!-- Step 2 Plans and ambitions -->
            <div v-show="currentStep === 2" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(1)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 2"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                :model="step2Form.form"
                :rules="step2FormRules"
                ref="step2Form"
                id="step2Form"
                autocomplete="false"
                @submit.native.prevent
                @validate="handleFormFieldInput('step2Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{ currentStep }} / 3</span>
                  Plans and ambitions
                </h1>

                <el-row>
                  <el-col :md="24" :lg="18">
                    <p class="se-body-l-screen mb-4">
                      In this section, we want to you tell us more about your
                      businesses and what you’re looking for from the network.
                      This will help our GlobalScots understand how they can
                      help. If you’ve added more than one company, you should
                      cover all of them in these questions.
                    </p>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4" />

                <el-row>
                  <el-col :md="24" :lg="17">
                    <label for="ambitions">
                      Tell us a bit more about what your company (or companies)
                      does and what your ambitions are.<span
                        class="is-custom-asterisk"
                        >*</span
                      ><br />
                      <span class="el-form-item__info"
                        >For example, you could tell us about your product or
                        service, company size, achievements, and what you want
                        to do next.</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'ambitions')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'ambitions')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="ambitions" class="is-no-asterisk">
                      <el-input
                        v-model="step2Form.form.ambitions"
                        type="textarea"
                        :rows="5"
                        maxlength="500"
                        id="ambitions"
                        label="Please enter a bit more about what your company or companies does and what your ambitions are in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :md="24" class="mt-n4 mb-4">
                    <span class="se-body-l-screen color-light-gray"
                      >You have
                      {{ 500 - step2Form.form.ambitions.length }} characters
                      remaining</span
                    >
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="14">
                    <label for="aspects">
                      <span aria-hidden="true"
                        >What aspect(s) of business do you need help with?</span
                      >
                      <span class="sr-only"
                        >Please select one or more aspects your business needs
                        help with</span
                      >
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'aspects')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'aspects')
                        "
                      ></span>
                    </label>
                    <span class="is-custom-asterisk">*</span><br />
                    <el-form-item prop="aspects">
                      <v-select
                        v-model="step2Form.form.aspects"
                        :options="step2Form.aspects"
                        :multiple="true"
                        :clearable="false"
                        label="slug"
                        id="aspects"
                        :reduce="aspect => aspect.id"
                        inputId="aspects"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="17">
                    <label for="regions">
                      Which regions would you like to expand into? - Optional<br />
                      <span class="sr-only"
                        >Please select one or more regions you would like to
                        expand into</span
                      >
                      <span class="el-form-item__info">
                        You can select up to 5. This will help us match you to
                        GlobalScots in your target areas.<br />
                        You can change these at any time.
                      </span>
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor('step2Form', 'regions')
                        "
                        v-text="
                          getInlineValidationErrorFor('step2Form', 'regions')
                        "
                      ></span>
                    </label>
                    <el-form-item prop="regions">
                      <v-select
                        id="regions"
                        v-model="step2Form.form.regions"
                        :options="step2Form.regions"
                        :multiple="true"
                        :clearable="false"
                        label="slug"
                        :reduce="region => region.id"
                        inputId="regions"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                        :selectable="() => step2Form.form.regions.length < 5"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="step-hr" />

                <el-row >
                  <el-col :md="24" :lg="18">

                    <el-form-item id="is_russian_self_declared" prop="is_russian_self_declared">
                      <el-checkbox-group v-model="step2Form.form.is_russian_self_declared" v-for="(russianSelfDeclaration,index) in step2Form.russianSelfDeclarations" :key="index">
                        <el-checkbox class="pb-3"
                          :name="russianSelfDeclaration.key"
                          :label="russianSelfDeclaration.key"
                          >
                          {{russianSelfDeclaration.text}}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :md="24" :lg="16" class="mb-5">
                    <label for="terms_agreed">
                      <span
                        class="sr-only"
                        aria-live="polite"
                        :hidden="
                          !hasInlineValidationErrorFor(
                            'step2Form',
                            'terms_agreed'
                          )
                        "
                        v-text="
                          getInlineValidationErrorFor(
                            'step2Form',
                            'terms_agreed'
                          )
                        "
                      ></span>
                    </label>
                    <el-form-item id="terms_agreed" prop="terms_agreed">
                      <el-checkbox-group v-model="step2Form.form.terms_agreed">
                        <el-checkbox
                          label="confirmed"
                          name="terms_agreed"
                          id="terms_agreed"
                        >
                          I agree to the
                          <a
                            :href="getCMSRoute('terms-conditions')"
                            class="text__link"
                            target="_blank"
                            >Terms & conditions</a
                          >(including the GlobalScot acceptable use policy),
                          <a
                            :href="getCMSRoute('privacy-policy')"
                            class="text__link"
                            target="_blank"
                            >privacy policy</a
                          >
                          and
                          <a
                            :href="getCMSRoute('cookie-policy')"
                            class="text__link"
                            target="_blank"
                            >cookies policy</a
                          >
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="submitStep2Form"
                >
                  Complete registration
                </el-button>
              </div>
            </div>
            <!-- Step 2 Plans and ambitions End -->

            <!-- Step 3 form submited -->
            <div v-show="currentStep === 3" class="body__content">
              <h1 class="se-heading-xl-40 mb-4">Thanks for submitting your application</h1>

              <p
                class="se-body-l-screen-19 mb-4"
              >We’ll review the information you’ve provided and get in touch in 3-5 days to discuss the next steps.</p>

              <p class="se-body-l-screen-19 mb-4">
                If you need to speak to us about your application, just
                <a :href="getCMSRoute('contact-us')" class="text__link">get in touch.</a>
              </p>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <a :href="getCMSHome()">
                  <el-button type="primary">Back to homepage</el-button>
                </a>
              </div>
            </div>
            <!-- Step 3 form submited End -->
          </el-col>
        </el-row>
      </div>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropperFile="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import loqateApi from "@/services/loqate.api";
import svgLinkPurple from "@/components/assets/svg-link-purple";
import CropperUpload from "@/components/cropper-upload/cropper-upload";
import manageBusinessNchn from "@/components/partials/nchn-register-manage-business";
import validationErrors from "@/components/partials/validation-errors";
import { validate } from "email-validator";

export default {
  name: "signup-ngs",
  metaInfo: {
    title: "Join as a business member",
    meta: [
      {
        name: "description",
        content:
          "Are you a business, entrepreneur or professional based in Scotland and looking for support? Join the free GlobalScot network as a business member."
      }
    ]
  },
  components: {
    svgLinkPurple,
    manageBusinessNchn,
    validationErrors,
    "cropper-upload": CropperUpload
  },
  data: function() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else {
        callback();
      }
    };
    const validateContactNumber = (rule, value, callback) => {
      let reg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 7) {
        callback(new Error("Contact number may not be less than 7 characters"));
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Contact number field must be valid"));
      } else {
        callback();
      }
    };
    const validateAgreement = (rule, value, callback) => {
      if(value.length < 3) {
        callback(new Error("You cannot continue without checking all boxes"));
      }
      else{
        callback();
      }
    };
    const validateEmailConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else if (value !== this.step1Form.form.email) {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else {
        callback();
      }
    };

    return {
      currentStep: 0,
      validationErrors: [],
      mainLoader: false,
      isResendingEmailLoading: false,
      signedUpId: null,
      signedUpEmail: null,
      cropperFile: null,
      showCropperDialog: false,

      newCompanyForm: {
        company: {
          name: "",
          address_1: "",
          address_2: "",
          post_code: "",
          town: "",
          role: "",
          group_ids: [],
          sub_group_ids: [],
          edit_sub_groups: [],
          is_mailing_address: false,
          groups_loading: false
        },
        additionalCompanyFormOpen: false,
        isAddressCorrect: true,
        companyLoading: false,
        groupsLoading: false,
        groups: [],
        subGroups: [],
        inlineValidationErrors: []
      },

      newCompanyFormRules: {
        name: [
          { required: true, message: "Enter a company name", trigger: "blur" }
        ],
        address_1: [
          {
            required: true,
            message: "Enter the first line of your address",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Address line 1 may not be greater than 255 characters",
            trigger: "change"
          }
        ],
        address_2: [
          {
            max: 255,
            message: "Address line 2 may not be greater than 255 characters",
            trigger: "change"
          }
        ],
        town: [
          { required: true, message: "Enter a town/city", trigger: "blur" },
          {
            max: 100,
            message: "Town/City may not be greater than 100 characters",
            trigger: "change"
          }
        ],
        post_code: [
          { required: true, message: "Enter a postcode", trigger: "blur" },
          {
            max: 10,
            message: "Postcode may not be greater than 10 characters",
            trigger: "change"
          }
        ],
        role: [
          { required: true, message: "Enter a role", trigger: "blur" },
          {
            max: 255,
            message: "Role may not be greater than 255 characters",
            trigger: "change"
          }
        ],
        group_ids: [
          {
            type: "array",
            required: true,
            message: "Select a sector",
            trigger: "change"
          }
        ]
      },

      step1Form: {
        // Step 1
        form: {
          title: "",
          first_name: "",
          last_name: "",
          contact_number: "",
          businesses: [],
          has_mailing_address: true,
          mailing_address_1: "",
          mailing_address_2: "",
          mailing_post_code: "",
          mailing_town: "",
          mailing_country_id: null,
          email: "",
          email_confirmation:"",
          reason:null
        },

        currentCompanyHouseAddress: {
          name: "",
          address_1: "",
          address_2: "",
          post_code: "",
          town: "",
        },

        mailingOption: null,

        titles: [
          { id: 1, label: "Mr" },
          { id: 2, label: "Mrs" },
          { id: 3, label: "Ms" },
          { id: 4, label: "Miss" },
          { id: 6, label: "Dr" },
          { id: 7, label: "Prof" },
          { id: 8, label: "Sir" },
          { id: 9, label: "Dame" },
          { id: 10, label: "Reverend" },
          { id: 11, label: "Duke" },
          { id: 12, label: "Duchess" },
          { id: 5, label: "Other" },
          { id: 13, label: "Mx" }
        ],
        reasons:[
          { id: 1, label: "Our main trading address is in Scotland but we're registered elsewhere" },
          { id: 2, label: "Our company is pre-revenue and not yet registered" },
          { id: 3, label: "We're not required to be registered at Companies House" },
          { id: 4, label: "Other" },
        ],
        usePrimaryMailingAddress: false,
        useOtherMailingAddress: true,
        countries: [],
        addressesLoading: false,
        disableAddressLookup: false,
        selectedAvailableMailingAddress: null,
        availableMailingAddresses: [],
        inlineValidationErrors: []
      },

      step1FormRules: {
        title: [
          {
            required: true,
            message: "Select a title from the dropdown",
            trigger: "change"
          }
        ],
        first_name: [
          { required: true, message: "Enter your first name", trigger: "blur" },
          {
            max: 64,
            message: "First name may not be greater than 64 characters",
            trigger: "blur"
          }
        ],
        last_name: [
          { required: true, message: "Enter your last name", trigger: "blur" },
          {
            max: 64,
            message: "Last name may not be greater than 64 characters",
            trigger: "blur"
          }
        ],
        email: [{ validator: validateEmail, required: true }],
        email_confirmation: [
          { validator: validateEmailConfirmation, required: true },
        ],
        contact_number: [{ validator: validateContactNumber, required: true }],
        mailing_post_code: [
          { required: true, message: "Enter a postcode", trigger: "blur" },
          {
            max: 10,
            message: "Postcode may not be greater than 10 characters",
            trigger: "blur"
          }
        ],
        mailing_address_1: [
          {
            required: true,
            message: "Enter the first line of your address",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Address line 1 may not be greater than 255 characters",
            trigger: "blur"
          }
        ],
        mailing_address_2: [
          {
            max: 255,
            message: "Address line 2 may not be greater than 255 characters",
            trigger: "blur"
          }
        ],
        mailing_town: [
          { required: true, message: "Enter a town or city", trigger: "blur" },
          {
            max: 100,
            message: "Town or City may not be greater than 100 characters",
            trigger: "blur"
          }
        ],
        mailing_country_id: [
          { required: true, message: "Enter a country", trigger: "change" }
        ],
        reason: [
          {
            required: true,
            message: "Select a reason from the dropdown",
            trigger: "change"
          }
        ],
      },

      step2Form: {
        // Step 2
        form: {
          ambitions: "",
          aspects: [],
          regions: [],
          is_russian_self_declared:[],
          terms_agreed: []
        },
        aspects: [],
        regions: [],
        inlineValidationErrors: [],
        russianSelfDeclarations:[
          {
            key:'code_conduct_readed',
            text:'I have read the Code of Conduct.'
          },
          {
            key:'ask_invertment_with',
            text:'I also understand that as part of my registration Scottish Enterprise may ask about the nature of trading and/or investment links with Russia and Belarus.'
          },
          {
            key:'no_subject_to_uk_sanctions',
            text:'I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.'
          },
        ],
      },

      step2FormRules: {
        ambitions: [
          {
            required: true,
            message: "Tell us more about your company",
            trigger: "blur"
          },
          {
            max: 500,
            message: "Ambitions may not be greater than 500 characters",
            trigger: "change"
          }
        ],
        aspects: [
          {
            type: "array",
            required: true,
            message: "Select an aspect of business from the dropdown",
            trigger: "change"
          }
        ],
        is_russian_self_declared: [
          { validator: validateAgreement, required: true }
        ],
        terms_agreed: [
          {
            type: "array",
            required: true,
            message:
              "Check the box to confirm you agree to our terms and conditions.",
            trigger: "change"
          }
        ]
      },
    };
  },
  computed: {
    heroText: function() {
      if (this.currentStep == 1) {
        return '"GlobalScot has had such a huge impact on our business - frankly, it could have been the difference between us making it or not." <p class="mt-3">David Capaldi – Opinurate</p>';
      } else if (this.currentStep == 2) {
        return "From AI and green tech to sculpture and space exploration, our community’s knowledge is always growing.";
      } else if (this.currentStep == 3) {
        return "The GlobalScot network has been supporting businesses in Scotland for almost 20 years";
      } else if (this.currentStep == 4) {
        return "Registration complete! You're almost there.";
      } else {
        return "Over 20 years of ambition and impact. One shared passion for Scotland’s success.";
      }
    },
  },
  methods: {
    onCompanyAddressCorrectChange(correct) {
      if (correct) {
        this.newCompanyForm.company.address_1 = this.currentCompanyHouseAddress.address_1;
        this.newCompanyForm.company.address_2 = this.currentCompanyHouseAddress.address_2;
        this.newCompanyForm.company.town = this.currentCompanyHouseAddress.town;
        this.newCompanyForm.company.post_code = this.currentCompanyHouseAddress.post_code;
      }
    },
    openAdditionalCompanyForm() {
      this.newCompanyForm.additionalCompanyFormOpen = true;
    },
    onUpdateBusiness(business, updatedBusiness) 
    {

      business.name = updatedBusiness.name;
      business.address_1 = updatedBusiness.address_1;
      business.address_2 = updatedBusiness.address_2;
      business.post_code = updatedBusiness.post_code;
      business.town = updatedBusiness.town;
      business.role = updatedBusiness.role;
      business.group_ids = updatedBusiness.group_ids;
      business.sub_group_ids = updatedBusiness.sub_group_ids;
      business.edit_sub_groups = updatedBusiness.edit_sub_groups;

      this.$alert("Company has been updated.", "Success", {
        confirmButtonText: "Close",
        showClose: false,
        customClass: "notification-modal"
      });
    },
    onRemoveBusiness(business) {
      this.$confirm(
        "Are you sure you want to remove this company?",
        "Remove company",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "modal-confirm-button",
          cancelButtonClass: "modal-cancel-button",
          type: "warning"
        }
      )
        .then(() => {
          if (business.is_mailing_address) {
            this.step1Form.mailingOption = "other";
            this.step1Form.form.has_mailing_address = true;
          }

          this.step1Form.form.businesses.splice(
            this.step1Form.form.businesses.indexOf(business),
            1
          );

          if (this.step1Form.form.businesses.length === 0) {
            this.newCompanyForm.additionalCompanyFormOpen = false;
          }

          this.$alert("Company has been removed.", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .catch(() => {});
    },
    onMailingOptionSelected() {
      if (this.step1Form.mailingOption == "other") {
        this.step1Form.form.has_mailing_address = true;

        if (this.step1Form.form.businesses.length > 0) {
          this.step1Form.form.businesses.forEach(business => {
            business.is_mailing_address = false;
          });
        }
      } else {
        this.step1Form.form.has_mailing_address = false;

        let company = this.step1Form.form.businesses.find(
          business => business.name == this.step1Form.mailingOption
        );
        if (company) {
          company.is_mailing_address = true;
        }
      }
    },
    // End of upload / cropper methods
    submitStep1Form() {
      this.$refs["step1Form"].validate(valid => {
        if (valid) {
          if (this.step1Form.form.businesses.length === 0) {
            document.getElementById("newCompanyForm").scrollIntoView();
            this.$alert(
              "Please add at least one company before proceeding.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
            return;
          }

          this.mainLoader = true;
          gsApi.member
            .validateNchnStepOne(this.step1Form.form)
            .then(response => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.newCompanyForm.additionalCompanyFormOpen = false;
              this.gotoStep(2);
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step1Form.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step1Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitNewCompanyForm() {
      if (this.step1Form.form.businesses.length < 3) {
        this.$refs["newCompanyForm"].validate(valid => {
          if (valid) {
            if (
              this.step1Form.form.businesses.find(
                business =>
                  business.name.toLowerCase() ==
                  this.newCompanyForm.company.name.toLowerCase()
              )
            ) {
              this.$alert(
                "You have already added a company with same name",
                "Error",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
              return;
            }

            this.newCompanyForm.company.sub_groups = JSON.parse(
              JSON.stringify(this.newCompanyForm.company.edit_sub_groups)
            );

            this.step1Form.form.businesses.push(this.newCompanyForm.company);

            if (this.step1Form.form.businesses.length > 0) {
              this.step1Form.mailingOption = this.step1Form.form.businesses[0].name;
              this.step1Form.form.businesses[0].is_mailing_address = true;
              this.step1Form.form.has_mailing_address = false;
            }

            this.newCompanyForm.company = {
              name: "",
              address_1: "",
              address_2: "",
              post_code: "",
              town: "",
              role: "",
              group_ids: [],
              sub_group_ids: [],
              incorporation_date: "",
              company_found: false,
              edit_sub_groups: [],
              groups_loading: false,
              is_mailing_address: false
            };

            this.currentCompanyHouseAddress = {
              name: "",
              address_1: "",
              address_2: "",
              post_code: "",
              town: "",
            };

            this.newCompanyForm.isAddressCorrect = true;
            this.newCompanyForm.additionalCompanyFormOpen = false;

            this.$nextTick(() => {
              this.$refs["newCompanyForm"].resetFields();
              document.getElementById("businesses-section").scrollIntoView();
              this.$alert("Company has been added.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              });
            });
          } else {
            this.$nextTick(() => {
              document
                .getElementById("newCompanyForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView();
              let text = document
                .getElementById("newCompanyForm")
                .getElementsByClassName("el-form-item__error")[0].textContent;
              this.$announcer.set(text);
            });
          }
        });
      } else {
        this.$alert("You can only add a maximum of 3 companies.", "Warning", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
      }
    },
    submitStep2Form() {
      this.$refs["step2Form"].validate(valid => {
        if (valid) {
          const formData = new FormData();

          // Step 1
          formData.append("title", this.step1Form.form.title);
          formData.append(
            "first_name",
            this.$options.filters.domPurify(this.step1Form.form.first_name, {
              ALLOWED_TAGS: []
            })
          );
          formData.append(
            "last_name",
            this.$options.filters.domPurify(this.step1Form.form.last_name, {
              ALLOWED_TAGS: []
            })
          );
          formData.append("email", this.step1Form.form.email);
          formData.append(
            "email_confirmation",
            this.step1Form.form.email_confirmation
          );
          formData.append("contact_number", this.step1Form.form.contact_number);
          formData.append("reason", this.step1Form.reasons.find(r => r.id == this.step1Form.form.reason).label);

          this.step1Form.form.businesses.forEach((business, index) => {
            formData.append(`businesses[${index}][name]`, business.name);
            formData.append(`businesses[${index}][role]`, business.role);
            formData.append(
              `businesses[${index}][address_1]`,
              business.address_1
            );
            formData.append(
              `businesses[${index}][address_2]`,
              business.address_2
            );
            formData.append(
              `businesses[${index}][post_code]`,
              business.post_code
            );
            formData.append(`businesses[${index}][town]`, business.town);
            formData.append(
              `businesses[${index}][is_mailing_address]`,
              business.is_mailing_address
            );

            business.group_ids.forEach(groupId => {
              formData.append(`businesses[${index}][group_ids][]`, groupId);
            });
            formData.append(`businesses[${index}][sub_group_ids]`, []);
            business.sub_group_ids.forEach(groupId => {
              formData.append(`businesses[${index}][sub_group_ids][]`, groupId);
            });
          });

          formData.append(
            "has_mailing_address",
            this.step1Form.form.has_mailing_address
          );

          if (this.step1Form.form.has_mailing_address) {
            formData.append(
              "mailing_address_1",
              this.step1Form.form.mailing_address_1
            );
            formData.append(
              "mailing_address_2",
              this.step1Form.form.mailing_address_2
            );
            formData.append(
              "mailing_post_code",
              this.step1Form.form.mailing_post_code
            );
            formData.append("mailing_town", this.step1Form.form.mailing_town);
            formData.append(
              "mailing_country_id",
              this.step1Form.form.mailing_country_id
            );
          }

          // Step 2
          formData.append("ambitions", this.step2Form.form.ambitions);

          formData.append("aspects", []);
          this.step2Form.form.aspects.forEach(aspect => {
            formData.append("aspects[]", aspect);
          });
          formData.append("regions", []);
          this.step2Form.form.regions.forEach(region => {
            formData.append("regions[]", region);
          });

          this.step2Form.form.is_russian_self_declared.forEach(item => {
            formData.append("is_russian_self_declared[]", item);
          })

          this.mainLoader = true;

          gsApi.member
            .registerNchn(formData)
            .then(response => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.signedUpId = response.data.id;
              this.signedUpEmail = response.data.email;
              this.gotoStep(3);
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step2Form.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step2Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    onResendEmail() {
      this.isResendingEmailLoading = true;
      gsApi.auth
        .resendVerification(this.signedUpId, this.signedUpEmail)
        .then(response => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "A new email has been sent. Check your inbox.",
            "Success",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        })
        .catch(() => {
          this.isResendingEmailLoading = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.member.meta
        .index()
        .then(response => {
          this.mainLoader = false;

          this.newCompanyForm.groups = response.data.groups.data;
          this.step2Form.aspects = response.data.aspects.data;
          this.step2Form.regions = response.data.regions.data;
          this.step1Form.countries = response.data.countries.data;
        })
        .catch(error => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
          this.gotoLink("/home");
        });
    },
    updateGroupIds(groupIds) {
      this.$refs.newCompanyForm.validateField("group_ids");
      this.newCompanyForm.company.group_ids = groupIds;
    },
    selectedGroupsUpdated(groupId) {
      if (this.newCompanyForm.company.group_ids.includes(groupId)) {
        this.newCompanyForm.groupsLoading = true;

        gsApi.group.subGroups(groupId).then(response => {
          this.newCompanyForm.groupsLoading = false;

          response.data.data.forEach(subgroup => {
            this.newCompanyForm.company.edit_sub_groups.push(subgroup);
          });
        });
      } else {
        // Removing selected sub groups where sub group is about to be removed below
        let subGroupsToBeRemoved = this.newCompanyForm.company.edit_sub_groups.filter(
          item => item.group_id == groupId
        );
        subGroupsToBeRemoved.forEach(subgroup => {
          if (this.newCompanyForm.company.sub_group_ids.includes(subgroup.id)) {
            let index = this.newCompanyForm.company.sub_group_ids.indexOf(
              subgroup.id
            );
            this.newCompanyForm.company.sub_group_ids.splice(index, 1);
          }
        });

        // Removing sub groups with group_id of selected group
        let newSubgroups = this.newCompanyForm.company.edit_sub_groups.filter(
          item => item.group_id !== groupId
        );
        this.newCompanyForm.company.edit_sub_groups = newSubgroups;
      }
    },
    onFindAddress(e) {
      this.findAddresses(this.step1Form.form.mailing_post_code);
    },
    findAddresses(value, containerId = "") {
      this.step1Form.addressesLoading = true;
      loqateApi
        .find(value, containerId, "")
        .then(response => {
          this.step1Form.addressesLoading = false;
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.step1Form.disableAddressLookup = true;
              this.step1Form.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        })
        .catch(error => {
          this.step1Form.addressesLoading = false;
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    onAvailableMailingAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then(response => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {
            this.step1Form.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.step1Form.form.mailing_address_1 = address.Line1;
            this.step1Form.form.mailing_address_2 = address.Line2;
            this.step1Form.form.mailing_post_code = address.PostalCode;
            this.step1Form.form.mailing_town = address.City;

            let country = this.step1Form.countries.find(
              c =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.step1Form.form.mailing_country_id = country.id;
            }
          } else {
            this.$alert(
              "No Postcode found. Please add it manually.",
              "Warning",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        })
        .catch(error => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Warning",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    gotoStep(step) {
      this.currentStep = step || 0;
      if (this.currentStep == 1) {
        this.$announcer.set("Step 1 of 2 - Personal and company details");
      } else if (this.currentStep == 2) {
        this.$announcer.set("Step 2 of 2 - Plans and ambitions");
      } else if (this.currentStep == 3) {
        this.$announcer.set("Verify your account");
      }
      window.scrollTo(0, 0);
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    handleFormFieldInput(formName) {
      this[formName].inlineValidationErrors = [];
      this.$refs[formName].fields.forEach(field => {
        if (field.validateState === "error") {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this[formName].inlineValidationErrors.push(obj);
        }
      });
    },
    hasInlineValidationErrorFor(formName, fieldName) {
      return this.getInlineValidationErrorFor(formName, fieldName).length > 0;
    },
    getInlineValidationErrorFor(formName, fieldName) {
      const validationError = this[formName].inlineValidationErrors.find(
        error => error.id === fieldName
      );
      if (!validationError) {
        return "";
      }

      return validationError.message;
    }
  },
  created() {
    this.getRegisterMeta();
  }
};
</script>

<style lang="scss">
.step_3 {
  .el-checkbox {
    align-items: unset;
    .el-checkbox__inner {
      margin-top: 5px;
    }
  }
}
.step-3-avatar {
  img {
    width: 140px;
    height: 140px;
  }
}
</style>