<template>
  <el-col :sm="24" :md="12" :lg="8" :xl="8" class="news-card-col">
    <el-card class="news-card box-card text-center relative">
      <div class="news-img">
        <el-image :alt="altText" :src="imageForEvent" fit="cover"></el-image>
        <span class="news-type">{{ event.type_name }}: {{ event.category_name }}</span>
        <activity-menu-dropdown v-if="isProfileOwner" classes="activity-menu-position" @delete="$emit('deleteActivity', activity.id)" />
      </div>
      <div class="title-position">
        <h2 class="text-truncate">{{ event.title }}</h2>
        <p class="text-truncate mt-0"><span>{{ event.start_date }}</span><span v-if="!isSameDay"> - {{ event.end_date }}</span></p>
        <p class="text-truncate">{{ event.description }}</p>
      </div>
      <div class="read-more-btn-container">
        <el-button type="primary" class="read-more-btn" @click="readMore(event.slug)">Read more<span class="sr-only">about {{ event.title }}</span></el-button>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import { mapState } from "vuex";
import ActivityMenuDropdown from "@/components/partials/recent-activity-cards/activity-menu-dropdown.vue";

export default {
  name: 'event-card',

  components: {
    'activity-menu-dropdown': ActivityMenuDropdown
  },
  
  props: {
    activity: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    isProfileOwner: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      eventTypes: {
        events: "Events",
        webinars: "Webinars",
      },
      event: this.activity.trackable
    };
  },
  computed: {
    ...mapState(["user"]),
    imageForEvent() {
      return this.event.mediaFile && this.event.mediaFile.large_thumb
        ? this.event.mediaFile.large_thumb
        : null;
    },
    altText() {
      return (this.event.mediaFile && this.event.mediaFile.alt_attribute) ? this.event.mediaFile.alt_attribute: null
    },
    isSameDay() {
      return Vue.moment(this.event.start_date).isSame(this.event.end_date, 'day')
    }
  },
  methods: {
    readMore(slug) {
      if (this.isPublic) {
        this.$router.push({ name: "Public Events", params: { slug: slug } });
      } else {
        this.$router.push({ name: "Events", params: { slug: slug } });
      }
    }
  },
};
</script>
