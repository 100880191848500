<!--Project Page-->
<template>
  <div
    v-loading.fullscreen.lock="mainLoader"
    class="project-detail-page"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <project-header
      v-if="project && project.id"
      :image="imageForProject"
      :alt-text="altText"
      :focus-point="project.metadata?.focus_point"
    ></project-header>
    <div
      v-if="project && project.id"
      class="project-content content-container"
    >
      <div
        id="main-content"
        class="container"
      >
        <el-row>
          <el-col
            :md="24"
            :lg="16"
            class="left-content"
          >
            <h2 class="project-title">
              {{ project.title }}
            </h2>
            <div
              v-if="!project.has_ended"
              class="d-md-flex mt-3 align-items-center justify-content-between"
            >
              <div class="type-project">
                <strong>Project</strong> <span class="ml-3">Added:</span> <span>{{ project.created_at }}</span>
              </div>
              <el-button
                v-if="!project.is_member"
                type="primary"
                class="view-profile-btn mt-3 mt-md-0"
                @click="dialogs.joinProject = true"
              >
                Join project
              </el-button>
              <el-button
                v-if="project.is_member"
                type="primary"
                class="view-profile-btn mt-3 mt-md-0"
                @click="dialogs.leaveProject = true"
              >
                Leave project
              </el-button>
            </div>
            <hr class="content-hr" />
            <div
              class="project-description"
              v-html="$options.filters.domPurify(description)"
            ></div>
            <!--div v-html="content" class="project-content"></div-->
            <div class="foot-buttons d-flex">
              <el-button
                v-if="project.is_member && project.has_ended && project.article && project.article.id && !project.member_has_exited_project"
                type="primary"
                class="mt-3 mr-4"
                @click="viewProjectOutcome"
              >
                View project outcome
              </el-button>
              <el-button
                v-if="project.has_ended && project.is_member && !project.has_left_feedback && project.feedback_enabled"
                icon="plus"
                type="primary"
                class="mt-3"
                @click="feedbackModalVisible = true"
              >
                Give feedback
              </el-button>
            </div>
          </el-col>
          <el-col
            v-if="project.id && !project.is_member"
            :md="24"
            :lg="8"
            role="complementary"
          >
            <h4 class="related-project-title mb-3">
              Other projects
            </h4>
            <el-row v-if="relatedItems.length > 0">
              <el-col
                v-for="relatedItem in relatedItems"
                :key="relatedItem.did"
                :sm="24"
                :md="12"
                :lg="24"
              >
                <project-card
                  :key="relatedItem.id"
                  :project="relatedItem"
                />
              </el-col>
            </el-row>
            <el-row v-else>
              <el-col :md="24">
                <p>No other projects found</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            v-if="project.id && project.is_member"
            :md="24"
            :lg="8"
            :gutter="10"
            role="complementary"
          >
            <div
              v-if="project.has_ended"
              class="type-project"
            >
              <strong>Project</strong> <span class="ml-3">Added:</span> <span>{{ project.created_at }}</span>
            </div>
            <h4
              v-if="project.has_ended"
              class="related-project-title my-4 text-primary"
              :class="{'left-project-text': (project.member_has_exited_project && project.member_exited_project_at)}"
            >
              {{ endedAt }}
            </h4>
            <h4
              v-else
              class="related-project-title my-3"
            >
              <span v-if="project.challenge_end_at != null">Ends {{ endsAt }}</span>
              <span v-else>Ongoing</span>
            </h4>
            <el-row v-if="project.id">
              <el-col
                class="text-center"
                :sm="8"
              >
                <div class="project-indicator rounded py-4 bg-light">
                  <p class="mb-2">
                    Members
                  </p>
                  <h2
                    class="fs-24 show-members-panel"
                    @click="showMembersPanel = true"
                  >
                    {{ project.members.length }}
                  </h2>
                </div>
              </el-col>
              <el-col
                class="text-center"
                :sm="8"
              >
                <div class="project-indicator rounded py-4 bg-light">
                  <p class="mb-2">
                    Entries
                  </p>
                  <h2 class="fs-24">
                    {{ posts.length }}
                  </h2>
                </div>
              </el-col>
              <el-col
                class="text-center"
                :sm="8"
              >
                <div class="project-indicator rounded py-4 bg-light">
                  <p class="mb-2">
                    Your Entries
                  </p>
                  <h2 class="fs-24">
                    {{ selfPosts.length }}
                  </h2>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row
        v-if="project.is_member && project.members.length >= 1"
        :gutter="0"
        class="m-0"
      >
        <el-col
          :span="24"
          class="p-0"
        >
          <div class="full-width-bar mt-5 bg-grayish py-4">
            <div class="container">
              <el-row :gutter="0">
                <el-col
                  :span="24"
                  class="d-md-flex text-center align-items-center justify-content-center"
                >
                  <members-avatar
                    v-if="project.members"
                    :members="project.members"
                    :size="32"
                    class="d-inline-block mb-5 mb-sm-0 mr-3"
                  ></members-avatar>
                  <h2
                    v-if="!project.has_ended"
                    class="text-white fs-16"
                  >
                    Want to discuss the project? Collaborate with participants in the project working group
                  </h2>
                  <h2
                    v-else
                    class="text-white fs-16"
                  >
                    <span v-if="project.member_has_exited_project && project.member_exited_project_at">You no longer have access to this project chat. You can still see all previous messages and any attached files.</span>
                    <span v-else>The chat for this project is now closed. You can still see all previous messages and any attached files.</span>
                  </h2>
                  <el-button
                    plain
                    class="mt-md-0 mt-3 d-inline-block ml-md-5 text-white border-white bg-initial"
                    @click="openGroupChat"
                  >
                    Open group chat
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="project.is_member"
        :gutter="0"
        class="bg-gray-2 pulse-page m-0"
      >
        <div class="container">
          <el-col :span="24">
            <div class="mt-4 pulse">
              <el-row :gutter="0">
                <el-col
                  :sm="{span: 22, offset: 1}"
                  :md="{span: 16, offset: 4}"
                  :xs="{span: 22, offset: 1}"
                >
                  <h2
                    v-if="project.has_ended"
                    class="fs-24 my-3 text-primary"
                  >
                    Project archive
                  </h2>
                  <h2
                    v-else
                    class="fs-24 my-3 text-primary"
                  >
                    Project progress updates
                  </h2>
                  <p
                    v-if="project.has_ended"
                    class="fs-18 mt-3 mb-4 fw-330"
                  >
                    <span v-if="project.member_has_exited_project && project.member_exited_project_at">New activity updates are only available to current project members. You can still access previous updates and any attached files.</span>
                    <span v-else>This project has now ended. You can't post any new updates, but you can still access all the previous updates from the proejct and any attached file.</span>
                  </p>
                  <p
                    v-else
                    class="fs-18 mt-3 mb-4 fw-330"
                  >
                    Share an update on your progress and upload documents you’ve produced. If you’re looking for advice or input from other project members, you can use the working
                    group chat.
                  </p>
                  <el-card
                    v-if="!project.has_ended"
                    v-show="!submittingPost"
                    class="mt-2 mt-sm-0 clickable p-1 p-sm-2"
                    @click.native="submittingPost = true"
                  >
                    <el-row class="d-sm-flex align-items-center">
                      <el-col class="d-flex align-items-center">
                        <div class="avatar d-flex">
                          <user-avatar
                            shape="circle"
                            :is-global-scot="helpers.isExpert(user)"
                            :author-exist="!!user"
                            width="45px"
                            :size="45"
                            :src="user.avatar"
                            :alt="user ? user.fullname : '' + 'Profile picture'"
                          ></user-avatar>
                        </div>
                        <div class="content ml-3 w-100">
                          <p class="d-block fs-17">
                            Post an update on the project
                          </p>
                        </div>
                      </el-col>
                      <el-col
                        :span="8"
                        :xs="24"
                        :sm="8"
                        :xl="8"
                      >
                        <hr class="hidden-sm-and-up my-3" />
                        <el-row
                          :gutter="0"
                          class="d-flex align-items-center"
                        >
                          <el-col
                            class="text-center d-flex align-items-center justify-content-center"
                            :xs="3"
                            :sm="12"
                          >
                            <svg-add-media class="w-100"></svg-add-media>
                          </el-col>
                          <el-col
                            class="hidden-sm-and-up"
                            :span="9"
                          >
                            <a
                              class="gs-link fs-16 ml-1"
                              href="#"
                            >Add post Photo/Video</a>
                          </el-col>

                          <el-col
                            class="text-center d-flex align-items-center justify-content-center"
                            :xs="3"
                            :sm="12"
                          >
                            <svg-add-post class="w-75 w-sm-100"></svg-add-post>
                          </el-col>
                          <el-col
                            class="hidden-sm-and-up"
                            :span="9"
                          >
                            <a
                              class="gs-link fs-16 ml-1"
                              href="#"
                            >Attachments</a>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-row
                    v-show="!submittingPost"
                    class="hidden-sm-and-down"
                  >
                    <el-col
                      class="d-flex align-items-center mt-4"
                      :span="24"
                    >
                      <div class="pulse-sort d-inline-block">
                        <span class="mr-2 fs-20">Sort by</span>
                        <v-select
                          v-model="selectedSort"
                          placeholder="Select"
                          :options="sortOptions"
                          :searchable="false"
                          :clearable="false"
                          :reduce="option => option.value"
                          class="d-inline-block min-width-200"
                          autocomplete="false"
                          @input="fetchPosts"
                        />
                        </v-select>
                      </div>
                      <div class="checkbox-container d-flex align-items-center">
                        <label
                          :for="showOnlyMyPosts"
                          class="fs-20 mx-3"
                        >Show only my posts</label>
                        <el-checkbox
                          id="showOnlyMyPosts"
                          v-model="showOnlyMyPosts"
                          class="mt-1 hidden-label"
                          label="Pulse"
                          @change="fetchPosts"
                        >
                          Hidden
                        </el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <div
                    v-loading.lock="loadingFeed"
                    class="pulse-feed"
                    element-loading-background="#EAEAEA"
                  >
                    <div
                      v-show="submittingPost"
                      class="overlay vh-100 vw-100 position-fixed"
                      @click="confirmCloseForm"
                    ></div>

                    <project-post-form
                      v-if="submittingPost && !project.has_ended"
                      :project-id="project.id"
                      class="px-3"
                      :submitting-post="submittingPost"
                      :edit-mode="editMode"
                      :post="postToEdit"
                      @closing="closeForm"
                      @submitted="resetAllFiltersAndSorting"
                    >
                    </project-post-form>
                    <project-post
                      v-for="(post, index) in posts"
                      :id="`post-${post.id}`"
                      :key="`${post.id}-${index}`"
                      :post="post"
                      :index="index"
                      :open-reply-for-post="replyPostId"
                      @editPost="onEditPost"
                      @deletePost="onRemovePost"
                    >
                    </project-post>
                    <el-row
                      v-if="!loadingFeed && !posts.length"
                      :gutter="1"
                    >
                      <el-col
                        :span="24"
                        class="p-5"
                      >
                        <h2>No results found for the given search criteria.</h2>
                      </el-col>
                    </el-row>
                    <el-row
                      v-if="nextLink"
                      :gutter="1"
                    >
                      <el-col
                        class="mt-4 text-center"
                        :span="24"
                      >
                        <hr class="d-block" />
                        <a
                          class="fs-19 gs-link mt-3 d-inline-block clickable"
                          @click="showMore"
                        >Show more</a>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>

    <div
      v-if="project && project.id"
      class="dialogs"
    >
      <focus-lock>
        <el-dialog
          ref="projectJoinDialog"
          custom-class="project-dialog"
          :visible.sync="dialogs.joinProject"
          :show-close="false"
          title="Do you want to join this project? You'll be able to join the project chat, see progress updates and post your own updates. You can leave the project any time."
          @opened="dialogOpen('projectJoinDialog')"
        >
          <el-row>
            <el-col
              :span="24"
              class="text-center"
            >
              <h1 class="mt-0">
                Do you want to join this project?
              </h1>
              <p class="fs-19">
                You'll be able to join the project chat, see progress updates and post your own updates. You can leave the project any time.
              </p>
            </el-col>
          </el-row>
          <span
            slot="footer"
            class="dialog-footer d-flex justify-content-end"
          >
            <el-button
              type="secondary"
              @click="dialogs.joinProject = false"
            >Cancel</el-button>
            <el-button
              v-loading.lock="joinProjectLoading"
              type="primary"
              element-loading-background="rgba(255, 255, 255, 0.8)"
              :disabled="joinProjectLoading"
              @click="joinProject"
            >Join project</el-button>
          </span>
        </el-dialog>
      </focus-lock>
      <focus-lock>
        <el-dialog
          ref="projectLeaveDialog"
          :show-close="false"
          custom-class="project-dialog"
          :visible.sync="dialogs.leaveProject"
          title="Do you want to leave this project? You won't be able to see or post new messages in the chat, and you won't be able to see or post new progress updates. You'll still be able to view previous messages and progress updates.You can only re-join the project by contacting us directly."
          @opened="dialogOpen('projectLeaveDialog')"
        >
          <el-row>
            <el-col
              :span="24"
              class="text-center"
            >
              <h1 class="mt-0">
                Do you want to leave this project?
              </h1>
              <p class="fs-19">
                You won't be able to see or post new messages in the chat, and you won't be able to see or post new progress updates. You'll still be able to view previous messages and progress updates.
              </p>
              <p class="fs-19">
                You can only re-join the project by contacting us directly.
              </p>
            </el-col>
          </el-row>
          <span
            slot="footer"
            class="dialog-footer d-flex justify-content-end"
          >
            <el-button
              type="secondary"
              @click="dialogs.leaveProject = false"
            >Cancel</el-button>
            <el-button
              v-loading.lock="leaveProjectLoading"
              type="primary"
              element-loading-background="rgba(255, 255, 255, 0.8)"
              :disabled="leaveProjectLoading"
              @click="leaveProject"
            >Leave project</el-button>
          </span>
        </el-dialog>
      </focus-lock>
    </div>

    <feedback
      v-if="project && project.id"
      title="Project feedback"
      :visible="feedbackModalVisible"
      module="Challenge"
      entity="Challenge"
      :entity-i-d="project.id"
      @close="feedbackModalVisible = false"
      @submitted="reload()"
    >
      <template slot="header">
        <div class="container">
          <el-row>
            <el-col
              :xs="19"
              :sm="21"
              :md="21"
              :lg="22"
            >
              <div class="user-info">
                <p class="feedback-public-notice">
                  Your feedback won't appear publicly. We’ll use it to monitor the quality of your experience across the network.
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <template slot="title">
        Overall, how would you rate your experience with this project?
      </template>
      <template slot="textarea-label">
        Please leave a short review of your experience with this project (Optional)
      </template>
    </feedback>

    <members-panel
      v-if="showMembersPanel"
      :members="project.members"
      @close="showMembersPanel = false"
    />
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import store from "../../../store";
import router from "../../../router";
import { Loading } from "element-ui";
import gsApi from "@/services/gs.api";
import * as config from "../../../config";
import Feedback from "@/components/feedback/feedback";
import ProjectCard from "@/components/platform/projects/project-card";
import ProjectHeader from "@/components/partials/project-header";
import ListShowMore from "@/components/partials/list-show-more";
import MapPinGradient from "@/components/assets/svg-map-pin-gradient";
import GsGlobeGradient from "@/components/assets/svg-gs-globe-gradient";
import MembersAvatar from "@/pages/platform/chats/avatars/members-avatar";
import ProjectPost from "@/components/platform/projects/project-post";
import ProjectPostForm from "@/components/platform/projects/project-post-form";
import SvgAddMedia from "@/components/assets/svg-add-media";
import SvgAddPost from "@/components/assets/svg-add-post";
import UserAvatar from '@/components/core/user-avatar'
import helpers from '@/helpers/index'
import MembersPanel from "@/components/slide-panel/panels/project-members-panel";

export default {
  name: "Project",
  components: {
    "project-card": ProjectCard,
    "project-header": ProjectHeader,
    "svg-map-pin-gradient": MapPinGradient,
    "svg-gs-globe-gradient": GsGlobeGradient,
    ProjectPost,
    ProjectPostForm,
    ListShowMore,
    MembersAvatar,
    SvgAddMedia,
    SvgAddPost,
    Feedback,
    UserAvatar,
    MembersPanel,
  },
  data() {
    return {
      helpers,
      feedbackModalVisible: false,
      project: null,
      mainLoader: true,
      relatedItems: [],
      nextLink: null,
      loadingFeed: false,
      submittingPost: false,
      showOnlyMyPosts: false,
      showMembersPanel: false,
      // sectors: [],
      expertise: [],
      categories: [
        {
          name: "All",
          id: 0,
        },
      ],
      replyPostId: this.$route.params.id ? this.$route.params.id : 0,
      user: {},
      posts: [],
      sortCombinations: {
        newest: { order_by: "created_at", order: "descending" },
        oldest: { order_by: "created_at", order: "ascending" },
        popularity: { order_by: "comments_count", order: "descending" },
        least_popular: { order_by: "comments_count", order: "ascending" },
      },
      sortOptions: [
        {
          value: "newest",
          label: "Newest",
        },
        {
          value: "oldest",
          label: "Oldest",
        },
        {
          value: "popularity",
          label: "Popularity",
        },
        {
          value: "least_popular",
          label: "Least Popular",
        },
      ],
      selectedSort: "newest",
      dialogs: {
        joinProject: false,
        leaveProject: false,
      },
      joinProjectLoading: false,
      leaveProjectLoading: false,
      postToEdit: null,
      editMode: false
    };
  },
  computed: {
    selectedSortCombination() {
      return this.sortCombinations[this.selectedSort];
    },
    selfPosts() {
      if (this.posts.length) {
        return _.filter(this.posts, (o) => {
          return o.author && o.author.id === this.user.id;
        });
      }
      return [];
    },
    location() {
      return this.project.spotlightUser.country &&
        this.project.spotlightUser.state
        ? this.project.spotlightUser.state.name +
            ", " +
            this.project.spotlightUser.country.name
        : "";
    },
    memberFor() {
      let memberFor = moment(
        this.project.spotlightUser.created_at.date
      ).fromNow(true);
      return memberFor;
    },
    imageForProject() {
      return this.project.mediaFile && this.project.mediaFile.path
        ? this.project.mediaFile.path
        : null;
    },
    altText() {
      return this.project.mediaFile && this.project.mediaFile.alt_attribute
        ? this.project.mediaFile.alt_attribute
        : null;
    },
    isSpotlight() {
      return this.project.type_name == "Spotlight";
    },
    avatar() {
      return this.project.spotlightUser && this.project.spotlightUser.avatar
        ? this.project.spotlightUser.avatar
        : "";
    },
    sectors() {
      // let sectors = this.project.spotlightUser.groups.data
      // return sectors
    },
    expertises() {
      return this.project.spotlightUser && this.project.spotlightUser.expertise
        ? this.project.spotlightUser.expertise
        : [];
    },
    endedAt() {
      if (
        this.project.member_has_exited_project &&
        this.project.member_exited_project_at
      ) {
        return "You’re no longer a member of this project.";
      } else if (this.project.stopped_at) {
        return (
          "Project ended " +
          moment(this.project.stopped_at, "DD/MM/YYYY").format("DD/MM/YYYY")
        );
      } else {
        return (
          "Project ended " +
          moment(this.project.challenge_end_at, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          )
        );
      }
    },
    endsAt() {
      if (!this.project.has_ended) {
        return moment(this.project.challenge_end_at, "DD/MM/YYYY").fromNow();
      } else {
        if (
          this.project.member_has_exited_project &&
          this.project.member_exited_project_at
        ) {
          return "You’re no longer a member of this project.";
        } else if (this.project.stopped_at) {
          return moment(this.project.stopped_at, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          );
        } else {
          return moment(this.project.challenge_end_at, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          );
        }
      }
    },
    description(){
      let cloneDiv = document.createElement("div");
      cloneDiv.innerHTML = this.project.description;
      return cloneDiv.innerHTML.replaceAll('&nbsp;','')
    }
  },
  watch: {
    $route(to, from) {
      if (store.state.user.isLogged) {
        if (store.getters.isTrader) {
          router.push("/feed");
        } else {
          this.user = store.state.user;
          this.fetchProject();
        }
      }
    }
  },
  created() {
    /*if (!store.state.user.isLogged) {
                router.push('/login')
            } else if (store.getters.isTrader) {
                router.push('/feed')
            } else {
                this.user = store.state.user
                this.fetchProject()
            }*/
    if (store.state.user.isLogged) {
      if (store.getters.isTrader) {
        router.push("/feed");
      } else {
        this.user = store.state.user;
        this.fetchProject();
      }
    }
  },
  methods: {
    reload() {
      window.location.reload();
    },
    openGroupChat() {
      this.$store.commit("UPDATE_SELECTED_CHAT", this.project.chat);
      this.$router.push({ name: "My Chats" });
    },
    viewProjectOutcome() {
      let article_id = this.project.article.id;
      this.$router.push({ name: "News", params: { id: article_id } });
    },
    joinProject() {
      this.joinProjectLoading = true;
      gsApi.projects.join(this.project.id).then((response) => {
        this.fetchProject().then((r) => {
          // this.project = r.data
          this.joinProjectLoading = false;
          this.dialogs.joinProject = false;
        });
      });
    },
    leaveProject() {
      this.leaveProjectLoading = true;
      gsApi.projects.leave(this.project.id).then((response) => {
        this.fetchProject().then((r) => {
          // this.project = r.data
          this.leaveProjectLoading = false;
          this.dialogs.leaveProject = false;
        });
      });
    },
    closeForm() {
      this.submittingPost = false;
    },
    fetchPosts() {
      this.loadingFeed = true;
      this.posts = [];

      gsApi.post
        .index({
          order_by: this.selectedSortCombination.order_by,
          order: this.selectedSortCombination.order,
          challenge_id: this.project.id,
          challenge_participant_id: this.user.id,
          user_id: this.showOnlyMyPosts ? this.user.id : null,
        })
        .then((response) => {
          this.posts = response.data.data;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
            if (
              response.data.meta.pagination.current_page ===
              response.data.meta.pagination.total_pages
            ) {
              this.nextLink = null;
            }
          }
          // this.getPostById()
          this.loadingFeed = false;
        });
    },
    showMore() {
      if (this.nextLink) {
        this.loadingFeed = true;
        gsApi.post
          .index(
            {
              order_by: this.selectedSortCombination.order_by,
              order: this.selectedSortCombination.order,
              challenge_id: this.project.id,
              user_id: this.showOnlyMyPosts ? this.user.id : null,
            },
            this.nextLink
          )
          .then((response) => {
            this.posts = this.posts.concat(response.data.data);
            if (response.data.meta.pagination.links) {
              this.nextLink = response.data.meta.pagination.links.next;
              if (
                response.data.meta.pagination.current_page ===
                response.data.meta.pagination.total_pages
              ) {
                this.nextLink = null;
              }
            }
            this.loadingFeed = false;
          });
      }
    },
    resetFilters() {
      this.filters = {
        sectors: [],
        expertise: [],
        category_id: 0,
      };
      this.fetchPosts();
    },
    resetAllFiltersAndSorting() {
      this.resetFilters();
      this.selectedGlobalFilter = "all";
      this.selectedSort = "newest";
      this.fetchPosts();
    },
    confirmCloseForm() {
      this.$confirm(" Are you sure you want to cancel this post?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.closeForm();
        })
        .catch(() => {});
    },
    viewProfile() {
      this.$router.push({
        name: "GlobalScot Profile",
        params: { id: this.project.spotlightUser.id, showBackToSearch: false },
      });
    },
    getRelatedItems() {
      let params = {
        item_id: this.$route.params.id,
        type: "Modules\\Challenge\\Entities\\Challenge",
        entity_type: "challenge__challenges",
        take: 3,
      };

      gsApi.articles
        .getRelatedItems(params)
        .then((response) => {
          this.relatedItems = response.data.data;
        })
        .catch((error) => {
          this.relatedItems = [];
        });
    },
    fetchProject() {
      this.mainLoader = true;
      return gsApi.projects
        .getById(this.$route.params.id)
        .then((response) => {
          this.mainLoader = false;
          this.project = response.data;

          this.fetchPosts();

          if (this.project.type_name !== "Spotlight") {
            this.getRelatedItems();
          }
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$router.push({ name: "Projects" });
        });
    },
    onEditPost(post){
      this.postToEdit = post;
      this.editMode = true;
      this.submittingPost = true;
    },
    //--- Remove the post from the list
    onRemovePost(index){
      this.$delete(this.posts,index)
    }
  }
};
</script>
