<template>
  <Trap
    :disabled="!isMobile"
    class="h-100 trap-loop"
  >
    <div class="h-100">
      <el-col
        v-if="selectedChat.id"
        v-loading.lock="messagesLoader"
        :md="24"
        :lg="17"
        class="selected-chat p-0"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      >
        <div
          v-if="!showMessagesZeroData"
          class="m-0 h-100"
        >
          <el-row class="m-0">
            <div class="el-col-24 back-to-chat-list px-5 align-items-center">
              <span
                class="cursor-pointer"
                @click="backToChatList()"
              >
                <go-to-back />
                <span class="ml-2 back-to-chat-list-text">back to all chats</span>
              </span>
            </div>
            <a
              v-if="showBackToActivityHistory"
              aria-label="go back to activity history"
              href="javascript:void(0)"
              class="back-to-search-link linkified pull-right mr-4 py-1 d-block"
              @click="goBackToActivityHistory"
            >
              <span><svg-purple-arrow-left class="mr-1"></svg-purple-arrow-left> Back to activity history</span>
            </a>
            <el-col
              :md="24"
              :lg="17"
              class="selected-chat-header w-100"
            >
              <div class="d-flex selected-chat-header-info">
                <user-avatar
                  v-if="selectedChat.type == 'challenge'"
                  shape="circle"
                  :is-global-scot="false"
                  :author-exist="true"
                  :size="74"
                  width="74px"
                  avatar-classes="flex-shrink-0"
                  :src="selectedChat.challenge.media"
                  alt="Profile picture"
                ></user-avatar>
                <user-avatar
                  v-else-if="isHelpRequest"
                  shape="circle"
                  :is-global-scot="displayHelpRequestChevron"
                  :author-exist="displayHelpRequestChevron"
                  :size="74"
                  width="74px"
                  avatar-classes="flex-shrink-0"
                  :src="getHelpRequestMemberAvatar"
                  alt="Profile picture"
                ></user-avatar>
                <members-avatar
                  v-else
                  :size="74"
                  :members="selectedChat.members"
                  class="flex-shrink-0"
                ></members-avatar>

                <div class="full-name">
                  {{ chatName }}
                </div>
                <div
                  v-if="isHelpRequest && selectedChat.isAuthor && selectedChat.helpRequest.accepted_at"
                  class="d-flex gs-indicator"
                >
                  <svg-world-star class="mr-1"></svg-world-star>
                  GlobalScot
                </div>
                <div
                  id="give-feedback"
                  class="ml-auto flex-shrink-0 d-flex give-feedback"
                >
                  <div
                    v-if="isHelpRequest"
                    class="d-flex align-items-center md-hide px-2"
                  >
                    <span
                      v-if="selectedChat.helpRequest.status !== 4"
                      class="chat-type mr-2"
                    >Help Req.</span>
                    <span
                      v-if="selectedChat.helpRequest.status === 4"
                      class="badge badge-warning"
                    >Reassigned</span>
                    <span
                      v-if="isHavingEngagementPartner"
                      class="badge badge-dark"
                    >Engagement Partner</span>
                  </div>

                  <el-button
                    v-if="!isHelpRequest && selectedChat.isAuthor"
                    icon="plus"
                    type="primary"
                    class="action-btn hidden-md-and-down"
                    @click="manageChatPanelOpen = true"
                  >
                    Add/Remove
                    chat members
                  </el-button>

                  <!--                                 <el-button icon="plus" type="primary" class="action-btn hidden-lg-and-down" v-if="isHelpRequest && !selectedChat.helpRequest.has_ended && selectedChat.helpRequest.status !== 4" @click="manageChatPanelOpen = true">Manage chat members</el-button> -->

                  <el-button
                    v-if="isHelpRequest && selectedChat.isAuthor && !selectedChat.helpRequest.has_ended"
                    icon="plus"
                    type="primary"
                    class="action-btn hidden-md-and-down"
                    @click="endRequestDialogVisible = true"
                  >
                    End request
                  </el-button>
                  <el-tooltip
                    :id="selectedChat.id"
                    :key="selectedChat.id"
                    class="item"
                    effect="dark"
                    content="Prompt this member to end the request if you think it’s complete"
                    placement="bottom"
                  >
                    <el-button
                      v-if="isHelpRequest && !selectedChat.isAuthor && !selectedChat.helpRequest.has_ended && !selectedChat.helpRequest.has_been_requested_to_end"
                      icon="plus"
                      type="primary"
                      class="action-btn hidden-md-and-down"
                      @click="promptEndRequestDialogVisible = true"
                    >
                      Prompt to end request
                    </el-button>
                  </el-tooltip>
                  <el-button
                    v-if="isHelpRequest && selectedChat.helpRequest.has_ended && selectedChat.isAuthor && !selectedChat.helpRequest.has_feedback"
                    icon="plus"
                    type="primary"
                    class="action-btn hidden-md-and-down"
                    @click="openFeedbackModal = true"
                  >
                    Give feedback
                  </el-button>

                  <span
                    class="hidden-md-and-down"
                    @click="showFilePanel"
                  >
                    <a href="javascript:void(0)">
                      <span class="sr-only"> Linkopens view file panel</span>
                      <svg-view-file class="svg-view-file" /></a>
                  </span>

                  <div class="nav-main my-profile hidden-lg-and-up">
                    <nav
                      class="nav-global"
                      tabindex="1"
                    >
                      <ul role="menu">
                        <li>
                          <a
                            href="javascript:void(0)"
                            tabindex="0"
                            @keyup.enter="subMenuFocusCheck"
                          >
                            <span class="sr-only">Link - opens dropdown menu</span>
                            <svg-view-file class="svg-view-file" /></a>

                          <ul
                            id="sub-menu-chat"
                            class="el-menu el-menu--popup el-menu--popup-bottom-start"
                          >
                            <div>
                              <li v-if="selectedChat.isAuthor && !isHelpRequest">
                                <a
                                  tabindex="0"
                                  href="javascript:void(0)"
                                  @keyup.enter="manageChatPanelOpen = true"
                                  @click="manageChatPanelOpen = true"
                                >Manage chat members <div class="sr-only"> Manage chat memebers link </div></a>
                              </li>
                              <li v-if="isHelpRequest && selectedChat.isAuthor && !selectedChat.helpRequest.has_ended">
                                <a
                                  tabindex="0"
                                  href="javascript:void(0)"
                                  @keyup.enter="endRequestDialogVisible = true"
                                  @click="endRequestDialogVisible = true"
                                >End request <div class="sr-only"> End request link </div></a>
                              </li>
                              <li v-if="isHelpRequest && !selectedChat.isAuthor && !selectedChat.helpRequest.has_ended && !selectedChat.helpRequest.has_been_requested_to_end">
                                <a
                                  tabindex="0"
                                  href="javascript:void(0)"
                                  @keyup.enter="promptEndRequestDialogVisible = true"
                                  @click="promptEndRequestDialogVisible = true"
                                >Prompt to end request <div class="sr-only"> Prompt to end link </div></a>
                              </li>
                              <li v-if="isHelpRequest && selectedChat.helpRequest.has_ended && selectedChat.isAuthor && !selectedChat.helpRequest.has_feedback">
                                <a
                                  tabindex="0"
                                  href="javascript:void(0)"
                                  @keyup.enter="openFeedbackModal = true"
                                  @click="openFeedbackModal = true"
                                >Give feedback <div class="sr-only"> Give feedback link </div></a>
                              </li>
                              <li>
                                <a
                                  tabindex="0"
                                  href="javascript:void(0)"
                                  @keyup.enter="showFilePanel"
                                  @click="showFilePanel"
                                >View shared files <div class="sr-only"> View shared files link </div></a>
                              </li>
                            </div>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <div
                    class="position"
                    v-html="$options.filters.domPurify(chatPosition)"
                  ></div>
                  <p
                    v-if="selectedChat.helpRequest"
                    class="mt-1 mb-3 font-weight-bold fs-16 help-reauest-remark"
                  >
                    All Help Requests will automatically close after 6 weeks of inactivity
                  </p>
                </div>
              
                <div
                  id="fake-space"
                  class="ml-auto flex-shrink-0"
                ></div>
              </div>
            </el-col>
          </el-row>

          <div class="el-row m-0 h-100">
            <div
              :class="openFilePanel ? 'el-col-lg-14' : 'el-col-lg-24'"
              class="el-col-24 p-0 h-100"
            >
              <div
                id="messages-container"
                ref="messagesContainer"
                class="d-flex flex-column pt-3 chat-messages-body"
              >
                <infinite-loading
                  direction="top"
                  :identifier="infiniteId"
                  @infinite="loadMessages"
                >
                  <div slot="spinner"></div>
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
                <message
                  v-for="(message) in messages"
                  :key="`message-${message.id}`"
                  :message="message"
                  :engagement-partner="getEngagementPartner"
                  @file-message-uploaded="onFileMessageUploaded($event)"
                  @file-message-uploaded-error="onFileMessageUploadedError($event)"
                  @leave-feedback="openFeedbackModal = true"
                  @end-request="endRequestDialogVisible = true"
                >
                </message>
                <div class="padding-faker"></div>
              </div>
              <div class="el-row m-0">
                <div
                  :class="openFilePanel ? 'el-col-lg-custom' : 'el-col-lg-17'"
                  class="d-flex send-message el-col-24 px-4"
                >
                  <el-form @submit.prevent.native="sendMessage()">
                    <el-form-item>
                      <div class="d-flex">
                        <div class="send-message-input-wrapper flex-grow-1">
                          <resize-auto>
                            <template #default="{ resize }">
                              <el-input
                                id="type-message"
                                ref="chatTextArea"
                                v-model="message"
                                label="type your message"
                                rows="1"
                                class="textarea rounded w-100"
                                type="textarea"
                                placeholder="Type your message..."
                                :disabled="sendingMessagesIsDisabled()"
                                @input.native="resize"
                                @focus="handleFocus"
                                @keydown.enter.exact.prevent
                                @keyup.enter.exact.prevent="newline"
                              />
                            </template>
                          </resize-auto>

                          <span class="svg-purple-boulevard">
                            <span
                              :class="{'isDisabled': sendingMessagesIsDisabled()}"
                              @click="$refs.file.click()"
                            >
                              <input
                                id="message-disabled"
                                ref="file"

                                type="file"
                                class="d-none"
                                :class="{'isDisabled': sendingMessagesIsDisabled()}"
                                :disabled="sendingMessagesIsDisabled()"
                                @change="onFileUpload"
                              />
                              <svg-purple-boulevard />
                            </span>
                          </span>
                        </div>

                        <el-button
                          type="primary"
                          :loading="sendMessageLoading"
                          :disabled="sendingMessagesIsDisabled()"
                          @click="sendMessage()"
                        >
                          Send
                        </el-button>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>

            <files-list-bar
              v-if="openFilePanel"
              :selected-chat-id="selectedChat.id"
              @hide-bar="hideFilePanel"
            />
          </div>
        </div>
      </el-col>
      <el-col
        v-if="showMessagesZeroData"
        :md="24"
        :lg="17"
        class="text-center start-chat-message hidden-md-and-down"
      >
        <div>
          <svg-chat-message />
        </div>
        <h4>No Chats found</h4>
        <p>Update your search criteria or</p>
        <p>clear your search below</p>
        <el-button
          type="primary"
          @click="clearSearch()"
        >
          Clear search
        </el-button>
      </el-col>

      <manage-chat-panel
        v-if="manageChatPanelOpen"
        :selected-chat-id="selectedChat.id"
        :members="selectedChat.members"
        @close="manageChatPanelOpen = false"
      />

      <feedback
        v-if="isHelpRequest"
        title="Engagement feedback"
        :visible="openFeedbackModal"
        module="User"
        entity="HelpRequest"
        :entity-i-d="selectedChat.helpRequest.id"
        @close="openFeedbackModal = false"
        @submitted="onFeedbackSubmitted"
      >
        <template slot="header">
          <div class="container">
            <el-row>
              <el-col
                :xs="5"
                :sm="3"
                :md="3"
                :lg="2"
              >
                <user-avatar
                  shape="circle"
                  :is-global-scot="helpers.isExpert(selectedChat.helpRequest.expert)"
                  :author-exist="!!selectedChat.helpRequest.expert"
                  :size="74"
                  width="74px"
                  :src="selectedChat.helpRequest.expert ? selectedChat.helpRequest.expert.avatar : null"
                  alt="Profile picture"
                ></user-avatar>
              </el-col>
              <el-col
                :xs="19"
                :sm="21"
                :md="21"
                :lg="22"
              >
                <div class="user-info">
                  <div class="d-flex">
                    <h2>{{ selectedChat.helpRequest.expert.full_name }}</h2>
                    <div class="d-flex gs-text">
                      <svg-gs-profile-icon class="mr-2"></svg-gs-profile-icon>
                      GlobalScot
                    </div>
                  </div>
                  <div
                    v-if="selectedChat.helpRequest.accepted_at && gsPrimaryBusiness && gsPrimaryBusiness.role"
                    class="mt-1 feedback-modal-role-business"
                  >
                    <p>{{ gsPrimaryBusiness.role }}</p>
                    <svg-vertical-divider class="mx-3 hidden-xs-only"></svg-vertical-divider>
                    <p>{{ gsPrimaryBusiness.name }}</p>
                  </div>
                  <p class="feedback-public-notice">
                    Your feedback won't appear publicly on their profile. We’ll use it to
                    monitor the quality of engagements and support across the network.
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
        <template slot="title">
          Overall, how would you rate your experience of working with {{
            selectedChat.helpRequest.expert.first_name }}?
        </template>
        <template slot="textarea-label">
          Please leave a short review of your experience with {{
            selectedChat.helpRequest.expert.first_name }} (Optional)
        </template>
      </feedback>
      <focus-lock>
        <el-dialog
          v-if="isHelpRequest"
          ref="endRequestDialog"
          v-loading="endRequestLoader"
          :show-close="false"
          custom-class="end-request-modal"
          :visible.sync="endRequestDialogVisible"
          :title="`Want to end this request? Once the request has ended, you’ll be given chance to review your experience with ${
            selectedChat.helpRequest.expert.full_name}. If you’d like their support again in the future, just submit
          another help request.`"
          @opened="dialogOpen('endRequestDialog')"
        >
          <div class="text-center success-dialog">
            <h2 class="mt-3">
              Want to end this request?
            </h2>
            <p class="mt-4 mb-1">
              Once the request has ended, you’ll be given chance to review your experience with {{
                selectedChat.helpRequest.expert.full_name }}. If you’d like their support again in the future, just submit
              another help request.
            </p>
            <el-row>
              <el-col
                :sm="24"
                :md="{span: 16, offset: 4}"
              >
                <el-form
                  id="endRequestForm"
                  ref="endRequestForm"
                  :model="endRequestForm"
                  class="mt-4"
                  @submit.native.prevent
                >
                  <el-form-item
                    prop="edit_sub_group_ids"
                    class="flex-grow-1 flex-shrink-0 mr-3 w-100 select-form-item"
                  >
                    <template slot="label">
                      <span aria-hidden="true">Reason for ending request</span>
                      <span class="sr-only">Please select a reason for ending this request</span>
                    </template>
                    <v-select
                      v-model="endRequestForm.reason"
                      :value="endRequestForm.reason"
                      :options="endRequestReasons"
                      :searchable="false"
                      :clearable="false"
                      autocomplete="false"
                      :reduce="reasonOption => reasonOption"
                      :select-on-tab="true"
                      :close-on-select="true"
                    />
                  </el-form-item>
                  <el-form-item
                    v-if="endRequestForm.reason === 'Other'"
                    label="Please add details (Optional)"
                  >
                    <el-input v-model="endRequestForm.otherReason"></el-input>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <div class="d-flex actions justify-content-center mb-4 mt-3">
              <el-button
                @click="endRequestDialogVisible = false"
              >
                Cancel
              </el-button>
              <el-button
                type="primary"
                @click="endRequest"
              >
                End request
              </el-button>
            </div>
          </div>
        </el-dialog>
      </focus-lock>
      <focus-lock>
        <el-dialog
          v-if="isHelpRequest"
          ref="promptEndRequestDialog"
          v-loading="promptEndRequestLoader"
          :show-close="false"
          custom-class="end-request-modal"
          :visible.sync="promptEndRequestDialogVisible"
          title="Want to end this request? If you think you’ve provided the required support or can’t help this business any more, you can prompt them to end the request."
          @opened="dialogOpen('promptEndRequestDialog')"
        >
          <div class="text-center success-dialog">
            <h2 class="mt-3">
              Want to end this request?
            </h2>
            <p class="mt-4 mb-1">
              If you think you’ve provided the required support or can’t help this business any more, you can prompt them to
              end the request.
            </p>
            <div class="d-flex actions justify-content-center mb-4 mt-5">
              <el-button
                @click="promptEndRequestDialogVisible = false"
              >
                Cancel
              </el-button>
              <el-button
                type="primary"
                @click="promtEndRequest"
              >
                Prompt request closure
              </el-button>
            </div>
          </div>
        </el-dialog>
      </focus-lock>
      <focus-lock>
        <el-dialog
          v-if="openFilePreviewModal && fileMessage"
          ref="confirmUploadFileDialog"
          :close-on-click-modal="false"
          custom-class="file-preview-modal"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="openFilePreviewModal"
          @opened="dialogOpen('confirmUploadFileDialog')"
        >
          <div class="text-center success-dialog">
            <h2 class="mb-4">
              File Preview
            </h2>
            <div class="upload-card complete">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <img :src="helpers.getFileIcon(fileMessage.file.name)" />
                </div>
                <div class="d-flex flex-column ml-3 text-content">
                  <div class="file-name">
                    <a
                      href="javascript:;"
                      class="svg-download-file"
                    >{{ fileMessage.file.name }}</a>
                  </div>
                  <div class="status">
                    {{ helpers.covertFileSize(fileMessage.file.size) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex actions justify-content-center mb-4 mt-5">
              <el-button
                @click="clearFilePreview()"
              >
                Cancel
              </el-button>
              <el-button
                v-loading="disableUploadButton"
                :disabled="disableUploadButton"
                type="primary"
                @click="sendFile"
              >
                Send
              </el-button>
            </div>
          </div>
        </el-dialog>
      </focus-lock>
    </div>
  </Trap>
</template>

<script>
import store from '@/store'
import {mapGetters, mapState} from 'vuex'
import * as config from '@/config'
import gsApi from '@/services/gs.api'
import FilesListBar from './files-list-bar'
import SvgWorldStar from '@/components/assets/svg-globe'
import SvgPurpleBoulevard from '@/components/assets/svg-purple-boulevard'
import SvgViewFile from '@/components/assets/svg-view-file'
import SvgDownloadFile from '@/components/assets/svg-download-file'
import ManageChatPanel from '@/components/slide-panel/panels/manage-chat-panel'
import SvgGoToBack from '@/components/assets/svg-go-to-back'
import MembersAvatar from './avatars/members-avatar'
import MemberAvatar from './avatars/member-avatar'
import SvgChatMessage from '@/components/assets/svg-chat-message'
import Feedback from '@/components/feedback/feedback'
import Message from '@/pages/platform/chats/partials/message'
import helpers from '@/helpers/index'
import UserAvatar from "@/components/core/user-avatar";
import ResizeAuto from "@/components/partials/resize-auto";
import SvgGsProfileicon from '@/components/assets/svg-gs-profile-icon';
import PurpleArrowLeft from '@/components/assets/svg-purple-arrow-left';
import SvgVerticalDivider from '@/components/assets/svg-vertical-divider';
import Trap from 'vue-focus-lock';
const ALLOWED_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'video/ogg',
  'video/mpeg',
  'video/webm',
  'video/mp4',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet'
]
const MAX_FILE_SIZE = 200 * 1024 * 1024 // 200mb

export default {
  components: {
    'manage-chat-panel': ManageChatPanel,
    'files-list-bar': FilesListBar,
    'svg-world-star': SvgWorldStar,
    'svg-purple-boulevard': SvgPurpleBoulevard,
    'svg-view-file': SvgViewFile,
    'svg-download-file': SvgDownloadFile,
    'members-avatar': MembersAvatar,
    'svg-chat-message': SvgChatMessage,
    'member-avatar': MemberAvatar,
    'go-to-back': SvgGoToBack,
    'feedback': Feedback,
    'message': Message,
    'svg-gs-profile-icon':SvgGsProfileicon,
    ResizeAuto,
    UserAvatar,
    'Trap' : Trap,
    'svg-purple-arrow-left': PurpleArrowLeft,
    'svg-vertical-divider': SvgVerticalDivider
  },
  data: () => ({
    helpers,
    openFeedbackModal: false,
    messagesLoader: false,
    openFilePanel: false,
    atMobileRes: false,
    disableUploadButton: false,
    manageChatPanelOpen: false,
    message: '',
    messages: [],
    showMessagesZeroData: false,
    sendMessageLoading: false,
    endRequestDialogVisible: false,
    promptEndRequestDialogVisible: false,
    endRequestReasons: [],
    endRequestForm: {
      reason: null,
      otherReason: ''
    },
    endRequestLoader: false,
    promptEndRequestLoader: false,
    infiniteId: +new Date(),
    meta: {
      page: 1,
      totalPages: 1,
      perPage: 20,
      fullyPaginated: false
    },
    isCollapsed: true,
    isCollapsedNot: false,
    isDropDown: false,
    image_mime_type_arr: ['image/jpeg', 'image/png', 'image/svg+xml'],
    fileMessage:null,
    openFilePreviewModal: false
  }),
  watch: {
    selectedChat: {
      immediate: true,
      deep: true,
      handler(newChat, oldChat) {
        // console.log(newChat)
        // console.log(oldChat)
        if (newChat.id) {
          this.$nextTick(() => {
            //this.getChatMessagesBodyTag().onscroll = this.scroll
            document.getElementById("fake-space").style.width = document.getElementById('give-feedback').getBoundingClientRect().width + 'px'
          })

          this.endRequestReasons = this.isHelpRequest ? this.selectedChat.helpRequest.ending_reasons : []
          this.endRequestForm.reason = this.isHelpRequest ? this.selectedChat.helpRequest.ending_reasons[0] : null

          this.showMessagesZeroData = false
          this.messages = []
          this.meta = {
            page: 1,
            totalPages: 1,
            perPage: 20,
            fullyPaginated: false
          }
          this.infiniteId+= 1;
          //---->this.loadMessages(this.meta.page)
        } else {
          this.showMessagesZeroData = true
        }
      }
    }
  },
  created() {
    this.checkRes()
    window.onresize = this.checkRes
    this.$nextTick(()=>{
      this.scrollToBottom();
    })
  },
  mounted() {
    this.$events.on("add-message", message => this.addMessage(message))
  },
  beforeDestroy() {
    this.$events.off("add-message");
  },
  computed: {
    ...mapState(['selectedChat']),
    ...mapGetters(['navigatedFrom']),
    showBackToActivityHistory() {
      return this.navigatedFrom.name && this.navigatedFrom.name === 'activity-history';
    },
    isHelpRequest() {
      return this.selectedChat.type == 'help_request'
    },
    isMobile() {
      return this.atMobileRes
    },
    chatName() {
      let name = '';

      if (this.selectedChat.type == "single_ngs" || this.selectedChat.type == "single_gs" || this.selectedChat.type == "help_request") {
        if (this.selectedChat.type == "help_request") {
          if (this.selectedChat.helpRequest.expert && this.selectedChat.helpRequest.expert.id !== store.state.user.id) {
            name = `${this.selectedChat.helpRequest.expert.first_name} ${this.selectedChat.helpRequest.expert.last_name}`
          } else if (this.selectedChat.helpRequest.expert && this.selectedChat.helpRequest.expert.id == store.state.user.id) {
            name = `${this.selectedChat.helpRequest.trader.first_name} ${this.selectedChat.helpRequest.trader.last_name}`
          } else {
            name = `${this.selectedChat.members[0].first_name} ${this.selectedChat.members[0].last_name}`
          }
        } else {
          name = `${this.selectedChat.members[0].first_name} ${this.selectedChat.members[0].last_name}`
        }
      } else if (this.selectedChat.type == "challenge") {
        name = this.selectedChat.challenge.title
      } else if (this.selectedChat.type == "group_ngs" || this.selectedChat.type == "group_gs") {
        name = this.selectedChat.members[0].first_name + ' ' + this.selectedChat.members[0].last_name
        if (this.selectedChat.members.length > 2) {
          name = this.selectedChat.members[0].first_name + ' ' + this.selectedChat.members[0].last_name + ', ' + this.selectedChat.members[1].first_name + ' ' + this.selectedChat.members[1].last_name + ' +' + (this.selectedChat.members.length - 2) + ' more'
        } else if (this.selectedChat.members.length > 1) {
          name = this.selectedChat.members[0].first_name + ' ' + this.selectedChat.members[0].last_name + ', ' + this.selectedChat.members[1].first_name + ' ' + this.selectedChat.members[1].last_name
        }
      }

      return name
    },
    chatPosition() {
      let position = ''

      if (this.selectedChat.type == "single_ngs" || this.selectedChat.type == "single_gs" || this.selectedChat.type == "help_request" || this.selectedChat.type == "challenge") {
        if (this.selectedChat.type == "help_request") {
          if (this.selectedChat.helpRequest.accepted_at && this.selectedChat.helpRequest.expert && this.selectedChat.helpRequest.expert.id !== store.state.user.id && this.selectedChat.helpRequest.expert.businesses) {
            let primary = this.selectedChat.helpRequest.expert.businesses.data.find(b => (b.is_primary))
            primary = primary ? primary : this.selectedChat.helpRequest.expert.businesses.data[0]
            position = this.formattedRoleAndBusiness(primary.role, primary.name);
          } else if (this.selectedChat.helpRequest.accepted_at && this.selectedChat.helpRequest.expert && this.selectedChat.helpRequest.expert.id == store.state.user.id && this.selectedChat.helpRequest.trader.roles) {
            if (this.selectedChat.helpRequest.trader.roles.includes('staff')) {
              position = this.formattedRoleAndBusiness('Staff', this.selectedChat.members[0].primary_company);
            } else {
              let primary = this.selectedChat.helpRequest.trader.businesses.data.find(b => (b.is_primary))
              primary = primary ? primary : this.selectedChat.helpRequest.trader.businesses.data[0]

              position = this.formattedRoleAndBusiness(primary.role, primary.name);
            }
          } else {
            position = ''
          }
        } else {
          if (this.selectedChat.type == "challenge") {
            let participantWord = this.selectedChat.challenge.participants == 1 ? ' Participant' : ' Participants'
            position = this.selectedChat.challenge.participants + participantWord
          } else if (this.selectedChat.members[0].roles && this.selectedChat.members[0].roles.includes('staff') && this.selectedChat.members[0].primary_company) {
            position = this.selectedChat.members[0].primary_company;
          } else if (this.selectedChat.members[0].primary_company) {
            position = this.formattedRoleAndBusiness(this.selectedChat.members[0].primary_company.role, this.selectedChat.members[0].primary_company.name);
          } else {
            position = ''
          }
        }
      }

      return position
    },
    isImage() {
      return (mime_type) => {
        return this.image_mime_type_arr.includes(mime_type);
      };
    },
    gsPrimaryBusiness() {
      if (this.selectedChat.helpRequest.expert.businesses) {
        let primary = this.selectedChat.helpRequest.expert.businesses.data.find(b => (b.is_primary))
        primary = primary ? primary : this.selectedChat.helpRequest.expert.businesses.data[0]

        return primary
      }

      return null;
    },
    isHavingEngagementPartner() {
      if (this.isHelpRequest) {
        return (
          this.selectedChat.helpRequest &&
            this.selectedChat.helpRequest.engagement_partners.length > 0
        );
      }
      return false;
    },
    getEngagementPartner(){
      if(!this.isHelpRequest) return [];
      return this.selectedChat.helpRequest.engagement_partners;
    },
    getHelpRequestMemberAvatar(){
      if (this.selectedChat.helpRequest && this.selectedChat.helpRequest.trader && this.selectedChat.helpRequest.trader.id === store.state.user.id) {
        return this.selectedChat.helpRequest.expert.avatar;
      }
      return this.selectedChat.helpRequest && this.selectedChat.helpRequest.trader ? this.selectedChat.helpRequest.trader.avatar : "";
    },
    displayHelpRequestChevron(){
      if (this.selectedChat.helpRequest && this.selectedChat.helpRequest.trader && this.selectedChat.helpRequest.trader.id === store.state.user.id) {
        return helpers.isExpert(this.selectedChat.helpRequest.expert);
      }
      return this.selectedChat.helpRequest && this.selectedChat.helpRequest.trader ?  helpers.isExpert(this.selectedChat.helpRequest.trader) : false;
    },
  },
  methods: {
    sendingMessagesIsDisabled(){
      return (this.isHelpRequest && this.selectedChat.helpRequest.has_ended)
        || (this.selectedChat.challenge && this.selectedChat.challenge.has_ended)
        || this.disableUploadButton || this.checkUserAvailibility() || this.checkUserAvailibility("deleted")
    },
    checkUserAvailibility(availibility="suspended"){ // availibility : deleted || suspended
      if(this.selectedChat.type === 'challenge' ||  this.selectedChat.type === 'group_ngs' || this.selectedChat.type === 'group_gs'){
        return false;
      }
      //-- single_ngs , single_gs , help_request
      if(this.selectedChat.members.length === 1){
        if(availibility === 'suspended'){
          return this.selectedChat.members[0].suspended;
        }else{
          return this.selectedChat.members[0].deleted;
        }
      }
      return false;
    },
    onFeedbackSubmitted() {
      // in here we will need to set a value on the helpRequest obj that lets us know feedback has been recieved, this will need to happen on backend as well similar to has_ended
      //add a mutator in the store index.js file, you will see other for has_ended etc, this will be the same but for the feedback value that you set on the backend.
      //FINALLY: in here at line: 30ish you will see a give feedback button, this will need to hide when the store value you set is set to true, i.e feedbackGiven = true hide button
      //FINNALY: in the message.vue you will need to hide the leave feedback link as well, i had to do this for the end request link so it should be a simialr process

      this.$store.commit('FEEDBACK_HAS_BEEN_RECIEVED')
    },
    endRequest() {
      this.endRequestLoader = true
      const chatId = this.selectedChat.id;
      gsApi.member.requestHelpEnd({
        help_request_id: this.selectedChat.helpRequest.id,
        ending_reason: this.endRequestForm.reason === 'Other' ? 'Other - ' + this.endRequestForm.otherReason : this.endRequestForm.reason
      }).then((response) => {
        this.$store.commit('END_CHAT_BY_HELP_REQUEST')
        this.endRequestLoader = false
        this.endRequestDialogVisible = false
        this.$emit('new-message', {
          chat_id: chatId,
          message: {
            author: {
              first_name: response.data.last_message.author,
            },
            body: response.data.last_message.body,
          }
        });
      }).catch((error) => {
        this.endRequestLoader = false
        //handle-error
      })
    },
    promtEndRequest() {
      this.promptEndRequestLoader = true
      const chatId = this.selectedChat.id;
      gsApi.globalscot.requestHelpEnd({
        help_request_id: this.selectedChat.helpRequest.id
      })
        .then((response) => {
          this.$store.commit('REQUEST_TO_END_CHAT')
          this.promptEndRequestLoader = false
          this.promptEndRequestDialogVisible = false
          this.$emit('new-message', {
            chat_id: chatId,
            message: {
              author: {
                first_name: response.data.last_message.author,
              },
              body: response.data.last_message.body,
            }
          });
        }).catch((error) => {
          this.promptEndRequestLoader = false
          //handle-error
        })
    },
    validateFileUpload(file) {
      if (ALLOWED_TYPES.indexOf(file.type) == -1) {
        this.$alert('The file must be a file of type: jpg, png, jpeg, pdf, mp4, ogv, webm, ppt, pptx, doc, docx, xls, xlsx, odt, odp, ods.', 'Error', {
          confirmButtonText: 'Close',
          showClose: false,
          customClass: 'notification-modal'
        });
        return false
      }
      if (MAX_FILE_SIZE < file.size) {
        this.$alert('File size must be less than 200MB.', 'Error', {
          confirmButtonText: 'Close',
          showClose: false,
          customClass: 'notification-modal'
        });
        return false
      }
      return true
    },
    backToChatList() {
      this.$store.commit('UPDATE_SELECTED_CHAT', {
        id: null,
        name: null,
        type: null,
        members: [],
      })
    },
    clearSearch() {
      this.$events.emit('refreshChatList', {})
    },
    loadMessages($state) {
      this.messagesLoader = true

      gsApi.chat.message.index(this.selectedChat.id, { page: this.meta.page, per_page: this.meta.perPage})
        .then((response) => {
          this.messagesLoader = false

          if (response.data) {
            /*if (this.meta.page !== 1) {
              removeScrollOnClass('.chat-messages-body')
            }*/

            const messageArr = response.data.data.reverse()

            if (response.data.meta.pagination.current_page == response.data.meta.pagination.total_pages && this.selectedChat.helpRequest) {
              response.data.data[0].isHelpRequestMessage = true
            }

            this.messages.unshift(...messageArr)


            //allowScrollOnClass('.chat-messages-body')

            if (this.meta.page === 1) {
              if(this.checkUserAvailibility()){
                this.addMessage({body:"User is not currently active, you can not send this user a message.", is_my:false,author:{first_name:this.selectedChat.members[0].first_name, last_name:this.selectedChat.members[0].last_name, suspended:true} });
              }else if(this.checkUserAvailibility("deleted")){
                this.addMessage({body:"User is no longer active, you can not send this user a message.", is_my:false,author:{first_name:this.selectedChat.members[0].first_name, last_name:this.selectedChat.members[0].last_name, deleted:true} });
              }
              this.$nextTick(() => {
                this.scrollToBottom()
              })
            }

            this.meta = {
              page: this.meta.page + 1,
              totalPages: response.data.meta.pagination.total_pages,
              perPage: response.data.meta.pagination.per_page
            }

            if (response.data.meta.pagination.current_page >= response.data.meta.pagination.total_pages) {
              this.meta.fullyPaginated = true
              $state.complete();
            } else {
              $state.loaded();
            }
          }
        })
    },
    scrollToBottom() {
      var content = this.$refs.messagesContainer;
      if(content){
        content.scrollTop = content.scrollHeight
      }
    },
    checkRes() {
      this.atMobileRes = window.innerWidth < 1082

      if (this.selectedChat.id) {
        this.$nextTick(() => {
          document.getElementById("fake-space").style.width = document.getElementById('give-feedback').getBoundingClientRect().width + 'px'
        })
      }
    },
    sendMessage(message) {
      const chatId = this.selectedChat.id;
      if (!message) {
        message = this.message
      }
      if (message.trim().length) {
        this.sendMessageLoading = true

        gsApi.chat.message.create(chatId, { body: message.trim() })
          .then((response) => {
            this.sendMessageLoading = false

            if (response && response.data) {
              this.addMessage(response.data);
              this.$emit('new-message', {
                chat_id: chatId,
                message: response.data
              });
            }

            this.message = '';
            if(this.$refs.chatTextArea.$el.firstChild){
              this.$refs.chatTextArea.$el.firstChild.style.height = "0px"}
          }).catch(()=>{
            this.sendMessageLoading = false;
          });
      }
    },
    addMessage(message) {

    // check if the message is already added to the list to avoid duplicate messages
    if(this.messages.find(msg => msg.id == message.id)) return;

      this.messages.push(message)

      if (!this.meta.fullyPaginated) {
        this.messages.shift()
      }

      this.$nextTick(() => {
        this.scrollToBottom()
        //this.getChatMessagesBodyTag().scrollTop = this.getChatMessagesBodyTag().scrollHeight
      })
    },
    onFileUpload(e) {
      let file = e.target.files[0]
      this.disableUploadButton = true;
      if (!this.validateFileUpload(file)) {
        if(this.$refs.file) { this.$refs.file.value = null; }
        this.disableUploadButton = false;
        return;
      }
      this.fileMessage = {
        selectedChatId: this.selectedChat.id,
        file: file,
        fileToBeUploaded: true,
        state: 'Uploading...',
        progress: 0,
        is_my: true,
        author: {
          first_name: store.state.user.first_name,
          last_name: store.state.user.last_name,
          avatar: store.state.user.avatar
        }
      }
      this.disableUploadButton = false;
      this.openFilePreviewModal = true;
    },
    sendFile(){
      this.disableUploadButton = true;
      this.messages.push(this.fileMessage);
      this.$nextTick(() => {
        this.scrollToBottom();
      })
      this.openFilePreviewModal= false;
    },
    onFileMessageUploaded(message) {
      this.messages.pop() // remove fake file message (Need updated when pusher is added!)
      this.messages.push(message)

      if (!this.meta.fullyPaginated) {
        this.messages.shift()
      }

      this.$emit('new-message', {
        chat_id: this.selectedChat.id,
        message: message
      });

      this.clearFilePreview()
    },
    onFileMessageUploadedError(message) {
      this.messages.pop() // remove fake file message (Need updated when pusher is added!)
      this.clearFilePreview();
    },
    clearFilePreview(){
      if(this.$refs.file) { this.$refs.file.value = null; }
      this.disableUploadButton = false
      this.openFilePreviewModal= false;
      this.fileMessage = null;
    },
    /*scroll() {
        if (this.selectedChat) {
          this.paginateWhenScrollIsTop();
        }
      },
      paginateWhenScrollIsTop() {
        const has_next_page = this.meta.page < this.meta.totalPages
        const selectedChatTag = this.getChatMessagesBodyTag()
        const paginate_position = selectedChatTag.offsetTop - selectedChatTag.scrollTop === 0

        // if (paginate_position) {
        //   console.log("SCROLL HAS REACHED THE TOP AND ==========================")
        //   console.log("messages count " + this.messages.length)
        //   console.log("current page: " + this.meta.page)
        //   console.log("total page: " + this.meta.totalPages)
        //   console.log("per page: " + this.meta.perPage)
        // }

        if (paginate_position && has_next_page) {
          // console.log("NOW WE PAGINATE")

          this.meta.page++
          this.loadMessages(this.meta.page, false)
        }
      },
      getChatMessagesBodyTag() {
        return document.querySelectorAll('.chat-messages-body')[0];
      },*/
    showFilePanel() {
      this.openFilePanel = true;
      // this.$parent.scrollToBottom('files-list-bar', 10000);
    },
    hideFilePanel() {
      this.openFilePanel = false;
    },
    newline(){
      this.message = `${this.message}\n`
    },
    subMenuFocusCheck() {
      let subNav = document.getElementById('sub-menu-chat')

      if (subNav.style.opacity == 0) {
        subNav.style.opacity = 1
      } else {
        subNav.style.opacity = 0
      }
    },
    handleFocus(){
      this.$refs.chatTextArea.focus();
    },
    goBackToActivityHistory() {
      this.$store.dispatch('updateScrollToActivityHistory', {
        tabName: 'helpRequests',
      });
      this.$router.go(-1);
    },
    formattedRoleAndBusiness(role, businessName) {
      return `<p>${role}</p>
                    <svg class="mx-2 hidden-xs-only" width="2" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 20l1 1V1H0v19z" fill="#000"/></svg>
                <p>${businessName}</p>
        `;
    },
  },
}
</script>

<style lang="scss">
.give-feedback {
  .el-menu--horizontal {
    border-bottom: 0;
    width: 49px;
    height: 76px;
    > .el-submenu.is-active {
      .el-submenu__title {
        border-bottom: 0;
      }
    }
  }
  .el-submenu__title {
    padding: 0;
  }
  .el-submenu__icon-arrow {
    display: none;
  }
}

.nav-global a {
  text-decoration: none;
}

.nav-global ul li {
  color: #005bbb;
  float: left;
  display: block;
  margin: 0;
}

.nav-global ul li:hover {
  background-color: #fff;
}

/* style the first level slightly different */

.nav-global > ul > li {
  margin: 0;
  width: auto;
  background: #fff;
  color: #005bbb;
}

.nav-global ul li a {
  color: #005bbb;
  display: block;
}

.nav-global ul li a:hover {
  color: #fff;
}

.nav-global li:hover > a {
  color: #fff;
}

/* ----------------------------------------------- secondary nav ----------------------------------------------- */

.nav-global:focus > ul > li > ul {
  overflow: visible;
  opacity: 1;
}

.nav-global > ul > li:focus > ul {
  overflow: visible;
  opacity: 1;
}

.nav-global:focus > ul > li {
  background-color: #003e51;
}

.nav-global:focus > ul > li > a {
  color: white;
}

.nav-global ul li ul {
  position: absolute;
  overflow: hidden;
  margin: 0;
  opacity: 0;
  z-index: 2006;
  margin-left: -150px;
}

.nav-global ul li:hover > ul {
  max-height: 1000px;
  overflow: visible;
  opacity: 1;
}

.nav-global ul li ul li {
  background-color: white;
}

.nav-global ul li ul li a {
  color: #000;
  padding: 0;
  line-height: 30px;
  text-indent: 15px;
}

.nav-global ul li ul li a:hover {
  color: $primary;
}
#type-message::placeholder {
  color: black !important;
}
</style>
