<template>
  <div class="personal-details-page">
    <gs-companies v-if="isExpert" />
    <non-gs-companies v-if="!isExpert" />
  </div>
</template>

<script>
  import gsApi from '@/services/gs.api'
  import GsCompanies from "@/components/platform/edit-profile/gs-companies"
  import NonGsCompanies from "@/components/platform/edit-profile/non-gs-companies"
  import {mapGetters} from 'vuex';

  export default {
    name: "companies-page",

    metaInfo: {
      title: "Companies - Edit profile",
    },

    components: {
      GsCompanies,
      NonGsCompanies
    },

    computed: {
      ...mapGetters(["isExpert"]),
      /*isGsUser() {
        return this.$store.state.user.roles[0] == 'expert' ? true : false
      }*/
    }
  }
</script>
