<template>
  <Trap class="h-100 trap-loop">
    <div
      v-loading.fullscreen.lock="mainLoader"
      class="container-fluid chats h-100 p-0"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-row class="h-100 m-0">
        <chats-list-bar
          ref="chats-list-bar"
          aria-visibility="hidden"
          area-hidden="true"
          :existing-chat="existingChat"
          @chats-loaded="onChatsLoaded($event)"
          @new-chat="newChatPanelOpen = true"
        />

        <selected-chat
          v-if="selectedChat && hasChats"
          ref="selected-chat"
          @new-message="handleNewMessage"
        />

        <el-col
          v-if="!hasChats && chatsLoaded"
          :span="12"
          :offset="3"
          class="text-center start-chat-message"
        >
          <div>
            <svg-chat-message />
          </div>
          <h4>You haven't started any chats yet</h4>
          <p>If you start a help request or Direct message</p>
          <p>another business they're appear in your chats</p>
          <el-button type="primary" @click="newChatPanelOpen = true">
            Search network members
          </el-button>
        </el-col>

        <new-chat-panel
          v-if="newChatPanelOpen"
          @close="newChatPanelOpen = false"
        />
      </el-row>
    </div>
  </Trap>
</template>

<script>
import store from "@/store";
import Pusher from "pusher-js";
import gsApi from "@/services/gs.api";
import { mapState } from "vuex";
import SvgChatMessage from "@/components/assets/svg-chat-message";
import {
  removeScroll,
  allowScroll,
  setHeightFullScreen,
} from "@/helpers/style";
import NewChatPanel from "@/components/slide-panel/panels/new-chat-panel";
import SelectedChat from "./selected-chat";
import ChatsListBar from "./chats-list-bar";
import Trap from "vue-focus-lock";
export default {
  name: "ChatsPage",
  metaInfo: {
    title: "Chats",
  },
  components: {
    "new-chat-panel": NewChatPanel,
    "svg-chat-message": SvgChatMessage,
    "selected-chat": SelectedChat,
    "chats-list-bar": ChatsListBar,
    Trap: Trap,
  },
  data: () => ({
    mainLoader: true,
    newChatPanelOpen: false,
    chatsLoaded: false,
    hasChats: false,
    existingChat: null,
  }),
  computed: {
    ...mapState(["selectedChat"]),
  },
  mounted() {
    removeScroll("body");
    setHeightFullScreen("body");
  },
  beforeDestroy() {
    allowScroll("body");

    this.$store.commit("UPDATE_SELECTED_CHAT", {
      id: null,
      name: null,
      type: null,
      members: [],
    });

    this.$store.dispatch("updateNavigatedFrom", {
      name: null,
    });
  },
  created() {
    this.initPusher();
    if (this.$route.params.chat_id) {
      this.existingChat = this.$route.params.chat_id;
    } else if (this.selectedChat) {
      this.existingChat = this.selectedChat.id;
    }
  },
  methods: {
    onChatsLoaded(hasChats) {
      this.mainLoader = false;
      this.chatsLoaded = true;

      if (!this.hasChats && hasChats) {
        this.hasChats = true;
      }
    },
    handleNewMessage(payload) {
      this.$refs["chats-list-bar"].pushLatestActiveChatToTop(payload);
    },
    initPusher() {
      let url = `${process.env.VUE_APP_API_URL}auth/pusher`;
      Pusher.log = function (msg) {
        console.log(msg);
      };

      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_PUBLIC_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        forceTLS: true,
        authEndpoint: url,
        auth: {
          headers: {
            ApiKey: process.env.VUE_APP_API_KEY,
            Authorization: store.state.user.token,
          },
        },
      });
      this.pusher.logToConsole = true;
      this.pusher.channel_auth_endpoint = url;
      this.pusherHandler();
    },
    pusherHandler() {
      let channel = this.pusher.subscribe(
        `private-chats.${store.state.user.id}`
      );
      channel.bind("new-message", (response) => {
        // If there is no existing chat, we need to refresh the chat list.
        if (this.selectedChat.id === null) {
          this.$events.emit("refreshChatList");
          return;
        }

        // Do not handle pusher for participants who should no longer see project updates.
        if (
          this.selectedChat.challenge &&
          this.selectedChat.challenge.has_ended
        ) {
          return;
        }

        if (
          response.chat_id &&
          response.message &&
          (response.message.type === "default" ||
            response.message.type === "help_request_end_requested")
        ) {
          if (response.chat_id === this.selectedChat.id) {
            if (response.message.has_file && !response.message.file) {
              this.$events.emit("push-latest-chat-to-top", {
                chat_id: response.chat_id,
                message: response.message,
              });
              return;
            }
            this.$events.emit("add-message", response.message);
            // mark the new message as read
            this.markMessageAsRead(response.message, response.chat_id);
          }
          this.$events.emit("push-latest-chat-to-top", {
            chat_id: response.chat_id,
            message: response.message,
          });
        } else if (
          response.chat_id &&
          response.message &&
          response.message.type === "help_request_ended"
        ) {
          if (response.chat_id === this.selectedChat.id) {
            this.$store.commit("END_CHAT_BY_HELP_REQUEST");
          }
          this.$events.emit("push-latest-chat-to-top", {
            chat_id: response.chat_id,
            message: response.message,
          });
        }
      });
      channel.bind("pusher:subscription_error", (status) => {
        if (status == 408 || status == 503) {
          this.initPusher();
        }
      });
    },
    markMessageAsRead(message, chat_id) {
      gsApi.chat.message.read(chat_id, message.id).catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.trap-loop {
  [data-lock] {
    height: 100%;
  }
}
</style>
