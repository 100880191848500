<template>
	<div id="mailing-address-section" v-loading.lock="loader">
	  <h2>Mailing address</h2>
	  <span>
	    Please select your preferred mailing address. We’ll use this if we need to get in touch by post.
	  </span>

	  <validation-errors v-if="validationErrors.length" :errors="validationErrors" class="mt-5"></validation-errors>

	  <el-form
	    :model="mailingForm.form"
	    :rules="mailingForm.rules"
	    ref="mailingForm"
	    id="mailingForm"
	    autocomplete="false"
      @submit.prevent.native="onSubmit($event)"
      @validate="handleMailingFormFieldInput">
	    <el-row>
	      <el-radio-group v-model="mailingOption" @change="onMailingOptionSelected" aria-label="Select an address">
	        <el-col :md="24" class="mb-4" v-for="(business, index) in businesses" :key="index">
	          <el-radio :label="business.address.id">
	            <span class="color-primary">{{ business.name }}</span><br/>
	            <span v-if="business.address.address_1">{{ business.address.address_1 }},</span>
	            <span v-if="business.address.address_2">{{ business.address.address_2 }},</br></span>
	            <span v-if="business.address.town">{{ business.address.town }},</span>
	            <span v-if="business.address.county">{{ business.address.county }},</span>
	            <span v-if="business.address.post_code">{{ business.address.post_code }}</span>
	          </el-radio>
	        </el-col>
	        <el-col :md="24" class="mb-4">
	          <el-radio label="other">Other</el-radio>
	        </el-col>
	      </el-radio-group>
	    </el-row>

	    <div v-if="mailingOption == 'other'" class="d-flex flex-column align-items-stretch">
	      <el-row>
	        <el-col :md="24" :lg="20">
	          <el-form-item prop="post_code">
              <template slot="label">
                Post code
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('post_code')"
                      v-text="this.getInlineValidationErrorFor('post_code')"></span>
              </template>
	            <div class="d-flex">
	              <el-input
	                class="half-flex-content"
	                v-model="mailingForm.form.post_code"
                  @blur="mailingForm.form.post_code = mailingForm.form.post_code.trim()"
	                @input="disableAddressLookup = false"
	                maxlength="10"
	                id="post_code"
	                autocomplete="false"
	                label="Please enter mailing post code in this field"
	                aria-required="true"
	                @keypress.enter.native="onFindAddress($event)"/>

	              <div class="mr-3"></div>

	              <el-form-item class="half-flex-content">
	                <el-button
	                  size="medium"
	                  @click="onFindAddress($event)"
	                  :disabled="disableAddressLookup || mailingForm.form.post_code.trim().length == 0">Find address</el-button>
	              </el-form-item>
	            </div>
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <div v-if="addressesLoading" v-loading="addressesLoading" class="address-loader"></div>

	      <el-row v-if="availableMailingAddresses.length > 0">
	        <el-col :md="24" :lg="20">
	          <el-form-item>
	            <template slot="label">
	              <span aria-hidden="true">Addresses Found</span>
	              <span class="sr-only">Please select an address</span>
	            </template>
	            <single-select
	              :options="availableMailingAddresses"
	              v-model="selectedAvailableMailingAddress"
	              name="available_mailing_addresses"
	              @input="onAvailableMailingAddressSelected($event)"
	            ></single-select>
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <el-row>
	        <el-col :md="24" :lg="20">
	          <el-form-item prop="address_1">
              <template slot="label">
                Address line 1
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('address_1')"
                      v-text="this.getInlineValidationErrorFor('address_1')"></span>
              </template>
	            <el-input
	              v-model="mailingForm.form.address_1"
                @blur="mailingForm.form.address_1 = mailingForm.form.address_1.trim()"
	              id="address_1"
	              maxlength="255"
	              autocomplete="false"
	              label="Please enter mailing address line 1 in this field"
	              aria-required="true" />
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <el-row>
	        <el-col :md="24" :lg="20">
	          <el-form-item prop="address_2">
              <template slot="label">
                Address line 2
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('address_2')"
                      v-text="this.getInlineValidationErrorFor('address_2')"></span>
              </template>
	            <el-input
	              v-model="mailingForm.form.address_2"
                @blur="mailingForm.form.address_2 = mailingForm.form.address_2.trim()"
	              id="address_2"
	              maxlength="255"
	              autocomplete="false"
	              label="Please enter mailing address line 2 in this field" />
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <el-row>
	        <el-col :md="24" :lg="18">
	          <el-form-item prop="town">
              <template slot="label">
                Town or City
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('town')"
                      v-text="this.getInlineValidationErrorFor('town')"></span>
              </template>
	            <el-input
	              v-model="mailingForm.form.town"
                @blur="mailingForm.form.town = mailingForm.form.town.trim()"
	              id="town"
	              maxlength="100"
	              autocomplete="false"
	              label="Please enter mailing town or city in this field"
	              aria-required="true" />
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <el-row>
	        <el-col :md="24" :lg="18">
	          <el-form-item prop="county">
              <template slot="label">
                County
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('county')"
                      v-text="this.getInlineValidationErrorFor('county')"></span>
              </template>
	            <el-input
	              v-model="mailingForm.form.county"
                @blur="mailingForm.form.county = mailingForm.form.county.trim()"
	              id="county"
	              maxlength="100"
	              autocomplete="false"
	              label="Please enter mailing county in this field" />
	          </el-form-item>
	        </el-col>
	      </el-row>

	      <el-row>
	        <el-col :md="24" :lg="18">
	          <el-form-item prop="country_id">
	            <template slot="label">
	              <span aria-hidden="true">Country</span>
	              <span class="sr-only">Please select mailing country</span>
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('country_id')"
                      v-text="this.getInlineValidationErrorFor('country_id')"></span>
	            </template>
	            <single-select
	              :options="countries"
	              v-model="mailingForm.form.country_id"
	              name="country_id"
	            ></single-select>
	          </el-form-item>
	        </el-col>
	      </el-row>
	    </div>

	    <hr class="mb-4">

	    <div class="d-flex form-actions mb-5">
	      <el-button
	        class="el-button btn-cancel"
	        @click="$router.push({name: 'NonGlobalScot Profile', params: {id: user.id}})"
	      >
	        Cancel
	      </el-button>
	      <el-button
	        type="primary"
	        class="btn-submit"
          native-type="submit">
	        Save changes
	      </el-button>
	    </div>
	  </el-form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import gsApi from '@/services/gs.api'
import loqateApi from '@/services/loqate.api'
import validationErrors from '@/components/partials/validation-errors'
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

export default {
  mixins: [inlineValidationErrorsMixin],
  name: "non-gs-mailing-address",

  components: {
    validationErrors
  },

  props: {
  	countries: {
  		type: Array,
  		default: []
  	}
  },

  data() {
    return {
      loader: false,
      validationErrors: [],
      mailingOption: null,
      addressesLoading: false,
      disableAddressLookup: false,
      availableMailingAddresses: [],
      selectedAvailableMailingAddress: null,
      existingCustomMailingAddressId: null,
      mailingForm: {
        form: {
          has_mailing_address: false,
          address_1: '',
          address_2: '',
          post_code: '',
          town: '',
          county: '',
          country_id: null,
        },
        rules: {
          post_code: [
            { required: true, message: 'Enter a postcode', trigger: 'blur' },
            { max: 10, message: 'Postcode may not be greater than 10 characters', trigger: 'blur' },
          ],
          address_1: [
            { required: true, message: 'Enter the first line of your address', trigger: 'blur' },
            { max: 255, message: 'Address line 1 may not be greater than 255 characters', trigger: 'blur' },
          ],
          address_2: [
            { max: 255, message: 'Address line 2 may not be greater than 255 characters', trigger: 'blur' },
          ],
          town: [
            { required: true, message: 'Enter a town or city', trigger: 'blur' },
            { max: 100, message: 'Town or City may not be greater than 100 characters', trigger: 'blur' },
          ],
          county: [
            { required: true, message: 'Enter a county', trigger: 'blur' },
            { max: 100, message: 'County may not be greater than 100 characters', trigger: 'blur' },
          ],
          country_id: [
            { required: true, message: 'Enter a country', trigger: 'change' },
          ],
        }
      },
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'businesses',
    ]),
  },

  methods: {
    onSubmit(event) {
      this.$refs['mailingForm'].validate((valid) => {
        if (valid) {
          const formData = new FormData()

          formData.append('has_mailing_address', this.mailingForm.form.has_mailing_address)

          if (this.mailingForm.form.has_mailing_address) {
            formData.append('address_1', this.mailingForm.form.address_1)
            formData.append('address_2', this.mailingForm.form.address_2)
            formData.append('post_code', this.mailingForm.form.post_code)
            formData.append('town', this.mailingForm.form.town)
            formData.append('county', this.mailingForm.form.county)
            formData.append('country_id', this.mailingForm.form.country_id)
          } else {
            formData.append('business_mailing_address_id', this.mailingOption)
          }

          if (this.existingCustomMailingAddressId) {
            formData.append('existing_custom_mailing_id', this.existingCustomMailingAddressId)
          }

          this.loader = true

          gsApi.member.user.updateMailingAddress(formData)
            .then(response => {
              this.loader = false
              this.validationErrors = []
              this.$store.commit('UPDATE_MAILING_ADDRESS', response.data)
              this.$alert('You\'ve successfully updated your mailing address', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' })

              if (this.mailingForm.form.has_mailing_address) {
                this.existingCustomMailingAddressId = this.user.mailing_address_id
                this.disableAddressLookup = false
                this.availableMailingAddresses = []
                this.selectedAvailableMailingAddress = null
              } else {
                this.availableMailingAddresses = []
                this.selectedAvailableMailingAddress = null
                this.existingCustomMailingAddressId = null
                this.mailingForm.form = {
                  has_mailing_address: false,
                  address_1: '',
                  address_2: '',
                  post_code: '',
                  town: '',
                  county: '',
                  country_id: null,
                }
              }
            })
            .catch(error => {
              this.validationErrors = []
              this.loader = false

              if (error.response && error.response.status == 422 && error.response.data) {
                let errors = error.response.data.errors;

                for (let [key, value] of Object.entries(errors)) {
                  let obj = {}
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }

                document.getElementById("mailing-address-section").scrollIntoView({ behavior: 'smooth', block: 'start' })
              } else {
                this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              }
            })
        } else {
            this.validationErrors = [];

            this.$refs.mailingForm.fields.forEach(field => {
              if(field.validateState == 'error') {
                let obj = {}
                obj.id = field.labelFor;
                obj.message = field.validateMessage;
                this.validationErrors.push(obj);
              }
            });

            this.$nextTick(() => {
              document.getElementById("mailing-address-section").scrollIntoView({ behavior: 'smooth', block: 'start' })
              let text = document.getElementById("mailingForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        });
    },
    onMailingOptionSelected() {
      if (this.mailingOption == 'other') {
        this.mailingForm.form.has_mailing_address = true
      } else {
        this.validationErrors = [];
        this.mailingForm.form.has_mailing_address = false
      }
    },
    onFindAddress (e) {
      e.preventDefault()
      this.findAddresses(this.mailingForm.form.post_code)
    },
    findAddresses (value, containerId='') {
      this.addressesLoading = true

      loqateApi.find(value, containerId, '')
      .then(response => {
        this.addressesLoading = false

        if (response.data && response.data.Items && response.data.Items.length > 0) {
          if (response.data.Items[0].Error) {
            this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else if (response.data.Items[0].Type != "Address") {
            this.findAddresses(value, response.data.Items[0].Id)
          } else if (!response.data.Items[0].Error) {
            this.disableAddressLookup = true
            this.availableMailingAddresses = response.data.Items
          }
        } else {
          this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }
      })
      .catch(error => {
        this.addressesLoading = false
        this.$alert('Failed to find any addresses using postcode provided. Please enter mailing address details instead.', 'No addresses found', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      })
    },
    onAvailableMailingAddressSelected (address) {
      this.retrieveAddress(address.Id)
    },
    retrieveAddress (id) {
      loqateApi.retrieve(id)
      .then(response => {
        if (response.data && response.data.Items && response.data.Items.length > 0 && !response.data.Items[0].Error) {
          this.disableAddressLookup = true

          let address = response.data.Items[0]
          this.mailingForm.form.address_1 = address.Line1
          this.mailingForm.form.address_2 = address.Line2
          this.mailingForm.form.post_code = address.PostalCode
          this.mailingForm.form.town = address.City
          this.mailingForm.form.county = address.AdminAreaName

          let country = this.countries.find(c => (c.code == address.CountryIso2 || c.code == address.CountryIso3))
          if (country) {
            this.mailingForm.form.country_id = country.id
          }
        } else {
          this.$alert('No Postcode found. Please add it manually.', 'Warning', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }
      })
      .catch(error => {
        this.$alert('Failed to automatically use the selected address. Please enter mailing address details instead.', 'Warning', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
      })
    },
    handleMailingFormFieldInput() {
      this.handleSingleFormFieldInput('mailingForm');
    },
  },

  created() {
    let businessAddressIds = this.businesses.map(b => b.address.id)

    if (businessAddressIds.includes(this.user.mailing_address_id)) {
      this.mailingOption = this.user.mailing_address_id
    } else {
      this.mailingOption = 'other'

      this.existingCustomMailingAddressId = this.user.mailing_address_id

      this.mailingForm.form = {
        has_mailing_address: true,
        address_1: this.user.address.address_1,
        address_2: this.user.address.address_2,
        post_code: this.user.address.post_code,
        town: this.user.address.town,
        county: this.user.address.county,
        country_id: this.user.address.country.id,
      }
    }
  }
}
</script>
