<template>
  <div
    class="section-account-details"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <h2>Account Details</h2>
    <el-form
      :model="form"
      id="submitForm"
      class="update-email-form"
      ref="submitForm"
      @submit.native.prevent
    >
      <el-row>
        <el-col :xs="24" :sm="4" :md="4" :lg="4">Email address</el-col>
        <el-col :xs="20" :sm="16" :md="16" :lg="16">
          <div v-if="!edit.email">
            <p>
              <span class="mr-2">
                {{
                  user.awaiting_verification_email
                    ? user.awaiting_verification_email
                    : user.email
                }}</span
              >
              <span
                v-if="user.awaiting_verification_email"
                class="tag-verification"
                >Awaiting verification</span
              >
            </p>
          </div>
          <div v-else>
            <el-form-item prop="email">
              <el-input
                v-model="form.email"
                type="email"
                name="email"
                placeholder="Email"
              />
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4">
          <el-button
            size="small"
            class="pull-right btn-edit"
            @click="
              edit.email = !edit.email;
              if (edit.email) initializeForm();
            "
            >{{ edit.email ? "Cancel" : "Edit" }}</el-button
          >
        </el-col>
      </el-row>
      <div class="form-actions" v-if="edit.email">
        <el-button
          type="primary"
          class="btn-submit"
          @click="saveEmailForm($event)"
          :disabled="!form.email.length"
        >
          Save Changes
        </el-button>
      </div>
    </el-form>
    <el-divider></el-divider>
    <el-row>
      <el-col :xs="24" :sm="4" :md="4" :lg="4">Password</el-col>
      <el-col :xs="20" :sm="16" :md="16" :lg="16">
        <div v-if="!edit.password">*************</div>
        <div v-else>
          <account-password></account-password>
        </div>
      </el-col>
      <el-col :xs="4" :sm="4" :md="4" :lg="4"
        ><el-button
          size="small"
          class="pull-right btn-edit"
          @click="edit.password = !edit.password"
          >{{ edit.password ? "Cancel" : "Edit" }}</el-button
        ></el-col
      >
    </el-row>
    <el-divider></el-divider>

    <el-row>
      <el-col :xs="20" :sm="20" :md="20" :lg="20">
        Multi-factor authentication
      </el-col>
      <el-col :xs="4" :sm="4" :md="4" :lg="4">
        <el-button
          v-if="!user.availableMfaMethods?.data?.length"
          size="small"
          class="pull-right btn-edit"
          @click="showMultiFactorAuthentication = true"
        >
          Enable
        </el-button>
        <p v-else class="text-center">Active</p>
      </el-col>
    </el-row>

    <div
      v-if="user.availableMfaMethods?.data?.length"
      v-loading="loadingTwoFactorMethods"
    >
      <el-divider></el-divider>
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.EMAIL)"
        :type="enumHelper.MFAType.EMAIL"
        :loading="waiting.includes(enumHelper.MFAType.EMAIL)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.GOOGLE)"
        :type="enumHelper.MFAType.GOOGLE"
        :loading="waiting.includes(enumHelper.MFAType.GOOGLE)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />
      <multi-factor-method
        :method="getAuthenticationByType(enumHelper.MFAType.PHONE)"
        :type="enumHelper.MFAType.PHONE"
        :loading="waiting.includes(enumHelper.MFAType.PHONE)"
        @change="handleChange"
        @remove="handleRemove"
        @setup="handleSetup"
        @mark-as-default="handleMarkAsDefault"
      />

      <el-dialog
        id="delete-activity-dialog"
        ref="confirmDeleteDialog"
        @opened="dialogOpen('confirmDeleteDialog')"
        :show-close="false"
        custom-class="confirm-dialog"
        :visible.sync="showConfirmMFADeleteDialog"
        v-if="showConfirmMFADeleteDialog"
      >
        <el-row>
          <el-col :span="24" class="text-center">
            <h1 class="mt-0">Delete Confirmation</h1>
            <p class="fs-19">Are you sure you want to delete this method ?</p>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer d-flex ">
          <el-button
            type="secondary"
            class="w-50"
            :disabled="blockToDelete"
            @click="
              () => {
                showConfirmMFADeleteDialog = false;
                methodToDelete = null;
              }
            "
            >Cancel</el-button
          >
          <el-button
            v-loading.lock="blockToDelete"
            type="primary"
            class="w-50"
            element-loading-background="rgba(255, 255, 255, 0.8)"
            :disabled="blockToDelete"
            @click="confirmMFADelete(methodToDelete)"
            >OK</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-divider></el-divider>
    <el-row :gutter="12">
      <el-col :span="12">
        <el-card shadow="never">
          <h2>Suspend account</h2>
          <p>
            Suspening your account will temporarily hide your profile from the
            network and pause all notifications. You can reactive your account
            any time by logging in
          </p>
          <el-button
            @click="showSuspendConfirmDialog = true"
            class="btn-close-suspend-account"
            type="primary"
            >Suspend my account</el-button
          >
        </el-card>
        <suspend-account-dialog
          :visible="showSuspendConfirmDialog"
          @closed="showSuspendConfirmDialog = false"
        ></suspend-account-dialog>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <h2>Close account</h2>
          <p>
            Closing your account will remove your profile, data and activity
            from the network. We’ll permanently delete all your data after 30
            days
          </p>
          <el-button
            @click="showClosedAccountDialog = true"
            class="btn-close-suspend-account"
            type="primary"
            >Close my account</el-button
          >
        </el-card>
        <closed-account-dialog
          :visible="showClosedAccountDialog"
          @closed="showClosedAccountDialog = false"
        ></closed-account-dialog>
      </el-col>
    </el-row>

    <enable-multi-factor-authentication-dialog
      v-if="showMultiFactorAuthentication"
      :visible="showMultiFactorAuthentication"
      :hide-button="buttonsHidden"
      @closed="showMultiFactorAuthentication = false"
      @onSetupMultiFactorAuthentication="handleSetupMultiFactoryAuthentication"
    />

    <setup-google-authentication
      v-if="showGoogleAuthentication"
      :visible="showGoogleAuthentication"
      @closed="showGoogleAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />

    <setup-phone-authentication
      v-if="showPhoneAuthentication"
      :visible="showPhoneAuthentication"
      :contact-number="user?.expertProfile?.contact_number"
      @closed="showPhoneAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />

    <setup-email-authentication
      v-if="showEmailAuthentication"
      :visible="showEmailAuthentication"
      @closed="showEmailAuthentication = false"
      @refresh-user-multifactor-methods="onRefreshUserMultifactorMethods"
    />
  </div>
</template>
<script>
import gsApi from "@/services/gs.api";
import validationErrors from "@/components/partials/validation-errors";
import AccountPassword from "./account-password";
import ClosedAccountDialog from "@/components/platform/settings/ClosedAccountDialog";
import SuspendAccountDialog from "@/components/platform/settings/SuspendAccountDialog";
import EnableMultiFactorAuthenticationDialog from "@/components/landing/EnableMultiFactorAuthenticationDialog";
import SetupGoogleAuthentication from "@/components/landing/SetupGoogleAuthentication";
import SetupPhoneAuthentication from "@/components/landing/SetupPhoneAuthentication";
import SetupEmailAuthentication from "@/components/landing/SetupEmailAuthentication";
import MultiFactorMethod from "@/components/partials/multi-factor-method";
import { mapGetters } from "vuex";
import enumHelper from "@/helpers/enumHelper";

export default {
  name: "account-details",
  metaInfo: {
    title: "Account details - Settings",
  },
  components: {
    validationErrors,
    AccountPassword,
    ClosedAccountDialog,
    SuspendAccountDialog,
    MultiFactorMethod,
    SetupGoogleAuthentication,
    SetupPhoneAuthentication,
    SetupEmailAuthentication,
    EnableMultiFactorAuthenticationDialog,
  },
  computed: {
    ...mapGetters(["user", "isAdmin", "isStaff"]),
    contactNumber() {
      return this.user.expertProfile
        ? this.user.expertProfile.contact_number
        : "";
    },
    getAuthenticationByType() {
      return (type) => {
        if (!this.user.availableMfaMethods?.data?.length) return null;

        return this.user.availableMfaMethods.data.find(
          (m) => m.two_factor_type == type
        );
      };
    },
  },
  data() {
    return {
      enumHelper,
      validationErrors: [],
      mainLoader: false,
      showSuspendConfirmDialog: false,
      showClosedAccountDialog: false,
      blockforAction: false,
      form: {
        email: "",
      },
      edit: {
        name: false,
        email: false,
        contactNumber: false,
        address: false,
        password: false,
      },
      showMultiFactorAuthentication: false,
      showGoogleAuthentication: false,
      showEmailAuthentication: false,
      showPhoneAuthentication: false,
      loadingTwoFactorMethods: false,
      waiting: [],
      buttonsHidden: {
        google: false,
        phone: false,
        email: false,
      },
      showConfirmMFADeleteDialog: false,
      methodToDelete: null,
      blockToDelete: false
    };
  },
  methods: {
    initializeForm() {
      this.form = {
        email: this.user.awaiting_verification_email
          ? this.user.awaiting_verification_email
          : this.user.email,
      };
    },
    saveEmailForm() {
      if (this.user.awaiting_verification_email) {
        if (this.form.email == this.user.email) {
          this.$alert(
            "Please verify your new email address first before you can change back to your old email or use a different new email address",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );

          return;
        } else if (this.user.awaiting_verification_email == this.form.email) {
          this.$alert(
            "This email address is already awaiting verification, please verify it or use a different new email address",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );

          return;
        }
      } else if (this.user.email == this.form.email) {
        this.$alert(
          "This is already your current email,  please use a different new email address",
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          }
        );
        return;
      }

      this.mainLoader = true;

      const data = {};
      data.awaiting_verification_email = this.form.email;

      gsApi.users
        .updateEmail(data)
        .then((response) => {
          this.mainLoader = false;
          this.validationErrors = [];
          this.$store.commit("UPDATE_USER_EMAIL", {
            awaiting_verification_email: this.form.email,
          });
          this.edit.email = false;
          this.$alert(
            "You successfully updated your email , an activation email has been sent to your new address",
            "Success",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          ).then(() => {
            this.$refs.submitForm.resetFields();
          });
        })
        .catch((error) => {
          this.validationErrors = [];
          this.mainLoader = false;

          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data
          ) {
            let errors = error.response.data.errors;
            for (let [key, value] of Object.entries(errors)) {
              this.validationErrors.push(value[0]);
            }

            this.$alert(
              this.validationErrors.join(),
              "Sorry, your request could not be processed",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(error.response.data.message, "Error", {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            });
          }
        });
    },
    async handleRemove(method) {
      // can't delete a method if the user has only one method enabled
      if (
        (this.isAdmin || this.isStaff) &&
        this.user.availableMfaMethods.data.length == 1
      ) {
        this.$confirm(
          `<i class="el-icon el-icon-warning-outline text-danger"></i>
                <p class='title mb-5 mt-2'>Setup alternative authentication method</p>
                <p class='method-name mb-2' >Please setup an alternative authentication method before removing one. You must have at least one authentication method setup at all times</p>
                `,
          "",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Setup",
            cancelButtonText: "Cancel",
            confirmButtonClass: "modal-confirm-button ml-2 w-100",
            cancelButtonClass: "modal-cancel-button mr-2 w-100",
            showClose: true,
            customClass: "notification-modal message-modal",
          }
        )
          .then(() => {
            const btnToHide =
              method.two_factor_type == enumHelper.MFAType.GOOGLE
                ? "google"
                : method.two_factor_type == enumHelper.MFAType.PHONE
                ? "phone"
                : "email";

            this.$set(this.buttonsHidden, btnToHide, true);

            this.$nextTick(() => {
              this.showMultiFactorAuthentication = true;
            });
          })
          .catch(() => {});
        return;
      }

      this.methodToDelete = method;
      this.showConfirmMFADeleteDialog = true;
    },
    async confirmMFADelete(method) {
      this.waiting.push(parseInt(method.two_factor_type))
      this.blockToDelete = true
      const response = await gsApi.mfa.delete(method.id).catch((error) => {
        return false;
      });
      const index = this.waiting.findIndex((i) => i == method.two_factor_type);
      this.waiting.splice(index, 1);
      this.blockToDelete = false
      this.methodToDelete = null
      this.showConfirmMFADeleteDialog = false
      if (response) {
        this.onRefreshUserMultifactorMethods();
      }
    },
    handleChange(method) {
      switch (parseInt(method.two_factor_type)) {
        case enumHelper.MFAType.GOOGLE:
          this.handleGoogleAuthentication();
          break;
        case enumHelper.MFAType.PHONE:
          this.handlePhoneAuthentication();
          break;
        case enumHelper.MFAType.EMAIL:
          this.handleEmailAuthentication();
          break;
      }
    },
    handleSetupMultiFactoryAuthentication(type) {
      switch (type) {
        case enumHelper.MFAType.GOOGLE:
          this.handleGoogleAuthentication();
          break;
        case enumHelper.MFAType.PHONE:
          this.handlePhoneAuthentication();
          break;
        case enumHelper.MFAType.EMAIL:
          this.handleEmailAuthentication();
          break;
      }
    },
    handleGoogleAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showGoogleAuthentication = true;
    },
    handlePhoneAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showPhoneAuthentication = true;
    },
    handleEmailAuthentication() {
      this.showMultiFactorAuthentication = false;
      this.showEmailAuthentication = true;
    },
    handleSetup(two_factor_type) {
      if (two_factor_type == enumHelper.MFAType.GOOGLE) {
        this.handleGoogleAuthentication();
        return true;
      }

      if (two_factor_type == enumHelper.MFAType.PHONE) {
        this.handlePhoneAuthentication();
        return true;
      }

      if (two_factor_type == enumHelper.MFAType.EMAIL) {
        this.handleEmailAuthentication();
        return true;
      }
    },
    async handleMarkAsDefault(method) {
      this.loadingTwoFactorMethods = true;
      const response = await gsApi.mfa
        .markAsDefault(method.id)
        .catch((error) => {
          this.loadingTwoFactorMethods = false;
          return false;
        });

      if (response) {
        this.onRefreshUserMultifactorMethods();
      }
    },
    async onRefreshUserMultifactorMethods() {
      // refresh the user available methods
      this.loadingTwoFactorMethods = true;
      const response = await gsApi.mfa.multiFactorMethods().catch(() => {
        this.loadingTwoFactorMethods = false;
        return false;
      });

      if (response) {
        this.$store.commit("UPDATE_MULTI_FACTOR_METHODS", response.data);
      }
      this.loadingTwoFactorMethods = false;
    },
  },
  created() {
    this.initializeForm();
  },
};
</script>
<style lang="scss">
.btn-edit {
  &.el-button--small {
    min-height: 0 !important;
    padding: 5px 15px !important;
    min-width: 100px;
  }
}
.el-message-box__message p {
  line-height: 32px;
}
.tag-verification {
  background-color: #fdf6ec;
  border: 1px solid #faecd8;
  color: #e6a23c;
  padding: 0 10px;
}
.tag-success {
  background-color: #f0f9eb;
  border-color: 1px solid #e1f3d8;
  color: #67c23a;
  padding: 0 10px;
}

.btn-close-suspend-account {
  margin-top: 10px;
  background: transparent !important;
  border: transparent !important;
  padding: 10px 10px !important;
  min-height: auto;
  color: #af006e !important;
  &:hover {
    color: #700e57 !important;
  }
}
</style>
