<template><svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M11.6112 25.0114H22.6112C22.6309 23.0483 22.4367 21.0889 22.0319 19.1679C21.5271 17.6487 17.8763 16.632 14.0557 15.1897V12.1342C14.0557 12.1342 15.2779 11.3776 15.2779 8.46752C16.5001 8.46752 16.5001 6.02308 15.2779 6.02308C15.2779 5.73708 16.5954 3.97219 16.1946 2.35641C15.6152 0.0341906 8.67667 0.0341906 8.09734 2.35641C5.20189 1.77463 6.72234 5.63319 6.72234 6.02308V8.46752C6.68966 9.25867 6.90867 10.0398 7.34789 10.6986C7.78711 11.3574 8.42392 11.8601 9.16678 12.1342V15.1897C5.77145 16.4804 1.69411 17.6487 1.19056 19.1679C0.785782 21.0889 0.591526 23.0483 0.611224 25.0114H11.6112Z" stroke="#AF006E" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M25.6667 25.0118H28.7222C28.7419 23.0487 28.5477 21.0893 28.1429 19.1683C27.6381 17.6491 23.9873 16.0213 20.1667 14.579V12.7457C20.1667 12.7457 21.3889 11.9891 21.3889 9.07903C22.6111 9.07903 22.6111 6.63458 21.3889 6.63458C21.3889 6.34858 22.7064 4.35871 22.3056 2.74293C22.0342 1.65515 19.7083 -0.0682423 17.875 1.15398" stroke="#AF006E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<circle cx="25.0556" cy="23.8332" r="9.16667" fill="white"/>
<circle cx="25.0555" cy="23.8331" r="7.94444" fill="#EA6576"/>
<rect x="24.4446" y="18.333" width="1.22222" height="11" fill="white"/>
<rect x="30.5557" y="23.2222" width="1.22222" height="11" transform="rotate(90 30.5557 23.2222)" fill="white"/>
<defs>
<clipPath id="clip0">
<rect width="29.3333" height="25.6227" fill="white"/>
</clipPath>
</defs>
</svg></template><script>export default {}</script>
