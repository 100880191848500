<template>
  <focus-lock>
    <el-dialog
      :show-close="true"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div class="body">
        <h2 class="mb-2">
          Authentication methods
        </h2>
        <p class="pt-3">
          How would you like to authenticate ?
        </p>
        <div class="d-flex flex-column mt-4">
          <el-button
            v-if="!hideButton.email"
            type="primary"
            class="mb-2 w-100 mx-0"
            @click="setupMultiFactorAuthentication(enumHelper.MFAType.EMAIL)"
          >
            Email
          </el-button>
          <el-button 
            v-if="!hideButton.google"
            type="primary"
            class="mb-2 w-100 mx-0"
            @click="setupMultiFactorAuthentication(enumHelper.MFAType.GOOGLE)"
          >
            Google Authenticator
          </el-button>
          <el-button
            v-if="!hideButton.phone"
            type="primary"
            class="w-100 mx-0"
            @click="setupMultiFactorAuthentication(enumHelper.MFAType.PHONE)"
          >
            Phone
          </el-button>


        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import enumHelper from '@/helpers/enumHelper'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hideButton:{
      type:Object,
      default: () => {
        return {
          google:false,
          phone:false,
          email:false
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      enumHelper
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    }
  },
  created() {
    this.dialogVisible = this.visible;
  },

  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    setupMultiFactorAuthentication(type){
      this.$emit('onSetupMultiFactorAuthentication', type)
    }
  }
};
</script>
