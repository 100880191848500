<template>
  <div class="feed-page">
    <feed-components></feed-components>
  </div>
</template>

<script>
import feedComponents from "../../components/partials/feed-components";
import gsApi from "@/services/gs.api";
import store from "@/store";

export default {
  name: "feed",
  metaInfo: {
    title: "Dashboard"
  },
  components: {
    feedComponents
  },
  created() {
    if (this.$route.query.help_request) {
      this.fetchHelpRequest();
    }
  },
  methods: {
    fetchHelpRequest() {
      gsApi.globalscot
        .requestHelpValid(this.$route.query.help_request)
        .then(response => {
          if (response.data.valid) {
            this.openRequestModal(response.data.helpRequest);
          } else {
            this.$alert("", `This user is no longer available.`, {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            });
          }
        })
        .catch(() => {
          this.$alert(
            "",
            `Unfortunately we're having problems contacting our service. Please try again later.`,
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    openRequestModal(request) {
      store.commit("OPEN_REQUEST_NOTIFICATION_MODAL", {
        request: request,
        isOpen: true
      });
      document.getElementById("content").setAttribute("aria-hidden", "true");
      this.$router.replace({'query': null});
    }
  }
};
</script>
