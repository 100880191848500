<template>
  <div
    class="signup-ngs-page page"
    v-loading.fullscreen.lock="mainLoader"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div :class="`signup-ngs-page__header step-${currentStep}`">
      <div class="header__content__image"></div>
      <div class="header__content__bg"></div>
      <div class="header__content container">
        <el-row>
          <el-col :md="24" :lg="12">
            <div class="header__content__wrapper" id="main-content">
              <h2 class="header__title" v-html="heroText"></h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="signup-ngs-page__body">
      <div class="container">
        <div class="row">
          <div class="col-md-24 col-lg-20 col-xl-16">
            <!-- Step 0 Intro -->
            <div v-show="currentStep === 0" class="body__content">
              <h1 class="body__title">Thanks for your interest in becoming a GlobalScot</h1>

              <p
                class="se-body-l-screen mb-4"
              >The application should take no more than 15 minutes. We’ll ask you about your business experience, what support you can offer and why you want to become a GlobalScot. All fields are mandatory unless noted as optional.</p>

              <!--p
                class="se-body-l-screen-medium mb-4"
              >We are currently seeking GlobalScots across mainland Europe to align with the priority countries outlined in the 'Trading Nation' Export Growth Plan in the following sectors:
                <ul class="sectors-list">
                  <li>Food &amp; Drink</li>
                  <li>Medtech/Health tech</li>
                  <li>AI and Edtech (the latter also in the US)</li>
                </ul>
              </p-->

              <p
                class="se-body-l-screen mb-4"
              >If successful, all the information you provide will be publicly visible on your profile, except for your contact details.</p>

              <div class="submit-button-wrapper">
                <el-button
                  type="primary"
                  native-type="submit"
                  @click="gotoStep(1)"
                >Start application</el-button>
              </div>

              <div class="contact-phone d-none d-lg-block">
                <h2 class="se-heading-m mb-2">Need to speak to someone?</h2>
                <p class="se-body-l-screen mb-3">
                  If you’ve got any questions or need help registering, just get in
                  touch.
                </p>
                <div class="pt-3">
                  <a :href="getCMSRoute('contact-us')">
                    <el-button type="primary" class="idea-contact-btn mt-md-0 mt-4">
                      Contact Us
                    </el-button>
                  </a>
                </div>
              </div>
            </div>
            <!-- Step 0 Intro End -->

            <!-- Step 1 Personal and Company details -->
            <div v-show="currentStep === 1" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(0)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 1"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                :model="step1Form.form"
                :rules="step1Form.rules"
                ref="step1Form"
                id="step1Form"
                @submit.native.prevent
                autocomplete="false"
                @validate="handleFormFieldInput('step1Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{currentStep}} / 3</span>
                  Personal and contact details
                </h1>

                <div class="step-hr"></div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                   <label for="title">
                        <span aria-hidden="true">Title<span class="is-custom-asterisk">*</span></span><br />
                        <label class="sr-only" for="title">Please select a title</label>
                        <span class="el-form-item__info">This won't be publicly displayed on your profile</span>
                        <span class="sr-only"
                             aria-live="polite"
                             :hidden="! hasInlineValidationErrorFor('step1Form','title')"
                             v-text="getInlineValidationErrorFor('step1Form','title')"></span>
                    </label>
                    <el-form-item prop="title" class="is-no-asterisk">

                      <single-select
                        :options="titles"
                        v-model="step1Form.form.title"
                        name="title"
                        id="title"
                        class="half-content"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                  <label for="first_name">
                        First name
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step1Form','first_name')"
                              v-text="getInlineValidationErrorFor('step1Form','first_name')"></span>
                   </label>
                    <span class="is-custom-asterisk">*</span></span><br />
                    <el-form-item prop="first_name">
                      <el-input
                        type="text"
                        v-model="step1Form.form.first_name"
                        @blur="step1Form.form.first_name = step1Form.form.first_name.trim()"
                        id="first_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your first name in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                   <label for="last_name">
                       Last name
                       <span class="sr-only"
                             aria-live="polite"
                             :hidden="! hasInlineValidationErrorFor('step1Form','last_name')"
                             v-text="getInlineValidationErrorFor('step1Form', 'last_name')"></span>
                      </label>
                        <span class="is-custom-asterisk">*</span></span><br />
                    <el-form-item prop="last_name">
                      <el-input
                        v-model="step1Form.form.last_name"
                        @blur="step1Form.form.last_name = step1Form.form.last_name.trim()"
                        id="last_name"
                        maxlength="64"
                        autocomplete="false"
                        label="Please enter your last name in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                  <label for="nationality_id">
                        <span aria-hidden="true">
                          Which nationality would you describe yourself as?
                          <span
                            class="is-custom-asterisk"
                          >*</span>
                        </span>
                        <br />
                        <span class="sr-only">Please select a nationality</span>
                        <span
                          class="el-form-item__info"
                        >This won’t be publicly displayed on your profile. We’ll only use this information to assess how diverse our network is, and it won’t affect our assessment of your application.</span>
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step1Form', 'nationality_id')"
                              v-text="getInlineValidationErrorFor('step1Form', 'nationality_id')"></span>
                      </label>
                    <el-form-item prop="nationality_id" class="is-no-asterisk">
                      <single-select
                        id="nationality_id"
                        :options="nationalities"
                        v-model="step1Form.form.nationality_id"
                        name="nationality_id"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                  <label for="contact_number">
                        Contact number
                        <span class="is-custom-asterisk">*</span>
                        <br />
                        <span
                          class="el-form-item__info"
                        >This won't be publicly displayed on your profile</span>
                      </label>
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step1Form', 'contact_number')"
                            v-text="getInlineValidationErrorFor('step1Form', 'contact_number')"></span>
                    <el-form-item prop="contact_number" class="is-no-asterisk">
                      <el-input
                        v-model="step1Form.form.contact_number"
                        @blur="step1Form.form.contact_number = step1Form.form.contact_number.trim()"
                        id="contact_number"
                        class="w-lg-75"
                        maxlength="20"
                        autocomplete="false"
                        label="Please enter your contact number in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                  <label for="email">
                        Email address
                        <span class="is-custom-asterisk">*</span>
                        <br />
                        <span
                          class="el-form-item__info"
                        >This won't be publicly displayed on your profile</span>
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step1Form','email')"
                              v-text="getInlineValidationErrorFor('step1Form','email')"></span>
                  </label>
                    <el-form-item prop="email" class="is-no-asterisk">
                      <el-input
                        type="email"
                        v-model="step1Form.form.email"
                        @blur="step1Form.form.email = step1Form.form.email.trim()"
                        id="email"
                        autocomplete="false"
                        label="Please enter your desired email address in this field. This won't be publicly displayed on your profile"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-16">
                   <label for="email_confirmation">
                        Confirm email address
                        <span class="is-custom-asterisk">*</span>
                        <span class="sr-only"
                           aria-live="polite"
                           :hidden="! hasInlineValidationErrorFor('step1Form', 'email_confirmation')"
                           v-text="getInlineValidationErrorFor('step1Form', 'email_confirmation')"></span>
                      </label>
                    <el-form-item prop="email_confirmation" class="is-no-asterisk">
                      <el-input
                        type="email"
                        v-model="step1Form.form.email_confirmation"
                        @blur="step1Form.form.email_confirmation = step1Form.form.email_confirmation.trim()"
                        id="email_confirmation"
                        autocomplete="false"
                        label="Please confirm the desired email address in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-2">Preferred mailing address</h2>
                <p class="se-body-l-screen mb-4">This won’t be publicly displayed on your profile.</p>
                <p
                  class="se-body-l-screen mb-4"
                >We’ll use this if we need to get in touch by post, or if we need to send you physical materials.</p>

                <div
                  class="d-flex flex-column align-items-stretch"
                  v-loading.fullscreen.lock="step1Form.addressesLoading"
                  element-loading-background="rgba(255, 255, 255, 0.8)"
                >
                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                     <label for="mailing_post_code">
                        Post code/Zip code
                        <span class="is-custom-asterisk">*</span>
                        <span class="sr-only"
                             aria-live="polite"
                             :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_post_code')"
                             v-text="getInlineValidationErrorFor('step1Form', 'mailing_post_code')"></span>
                      </label>
                      <el-form-item  prop="mailing_post_code">
                        <div class="d-flex">
                          <el-input
                            class="half-flex-content"
                            v-model="step1Form.form.mailing_post_code"
                            @blur="step1Form.form.mailing_post_code = step1Form.form.mailing_post_code.trim()"
                            @input="step1Form.disableAddressLookup = false"
                            maxlength="10"
                            id="mailing_post_code"
                            autocomplete="false"
                            label="Please enter mailing post code in this field"
                            aria-required="true"
                            @keyup.enter.native="onFindAddress($event)"
                          />

                          <div class="mr-3"></div>

                          <el-form-item class="half-flex-content">
                            <el-button
                              size="medium"
                              @click="onFindAddress($event)"
                              :disabled="step1Form.disableAddressLookup || step1Form.form.mailing_post_code.trim().length == 0"
                            >Find address</el-button>
                          </el-form-item>
                        </div>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row" v-if="step1Form.availableMailingAddresses.length > 0">
                    <div class="col-md-24 col-lg-20">
                       <label for="available_mailing_addresses">
                          <span aria-hidden="true">Addresses Found</span>
                          <span class="sr-only">Please select your address</span>
                          <span class="sr-only"
                               aria-live="polite"
                               :hidden="! hasInlineValidationErrorFor('step1Form', 'available_mailing_addresses')"
                               v-text="getInlineValidationErrorFor('step1Form', 'available_mailing_addresses')"></span>
                        </label>
                      <el-form-item>
                        <single-select
                        id="available_mailing_addresses"
                          :options="step1Form.availableMailingAddresses"
                          v-model="step1Form.selectedAvailableMailingAddress"
                          name="available_mailing_addresses"
                          @input="onAvailableMailingAddressSelected($event)"
                        ></single-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                     <label for="mailing_address_1">
                        Address line 1
                        <span class="is-custom-asterisk">*</span>
                       <span class="sr-only"
                             aria-live="polite"
                             :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_address_1')"
                             v-text="getInlineValidationErrorFor('step1Form', 'mailing_address_1')"></span>
                      </label>
                      <el-form-item  prop="mailing_address_1">
                        <el-input
                          v-model="step1Form.form.mailing_address_1"
                          @blur="step1Form.form.mailing_address_1 = step1Form.form.mailing_address_1.trim()"
                          id="mailing_address_1"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 1 in this field"
                          aria-required="true"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-20">
                   <label for="mailing_address_2">
                        Address line 2 - Optional
                        <span class="sr-only"
                           aria-live="polite"
                           :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_address_2')"
                           v-text="getInlineValidationErrorFor('step1Form', 'mailing_address_2')"></span>
                      </label>
                      <el-form-item  prop="mailing_address_2">
                        <el-input
                          v-model="step1Form.form.mailing_address_2"
                          @blur="step1Form.form.mailing_address_2 = step1Form.form.mailing_address_2.trim()"
                          id="mailing_address_2"
                          maxlength="255"
                          autocomplete="false"
                          label="Please enter mailing address line 2 in this field"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                     <label for="mailing_town">
                        Town, City or State
                        <span class="is-custom-asterisk">*</span>
                        <span class="sr-only"
                           aria-live="polite"
                           :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_town')"
                           v-text="getInlineValidationErrorFor('step1Form', 'mailing_town')"></span>
                      </label>
                      <el-form-item  prop="mailing_town">
                        <el-input
                          v-model="step1Form.form.mailing_town"
                          @blur="step1Form.form.mailing_town = step1Form.form.mailing_town.trim()"
                          id="mailing_town"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing town, city or state in this field"
                          aria-required="true"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                    <label for="mailing_county">
                          <span aria-hidden="true">Region - Optional</span>
                          <br />
                          <span
                            class="el-form-item__info"
                          >Depending where you're based, this could be your country, state, department or province</span>
                          <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_county')"
                            v-text="getInlineValidationErrorFor('step1Form', 'mailing_county')"></span>
                        </label>
                      <el-form-item prop="mailing_county">
                        <el-input
                          v-model="step1Form.form.mailing_county"
                          @blur="step1Form.form.mailing_county = step1Form.form.mailing_county.trim()"
                          id="mailing_county"
                          maxlength="100"
                          autocomplete="false"
                          label="Please enter mailing region in this field"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-24 col-lg-18">
                      <label for="mailing_country_id">
                          <span aria-hidden="true">Country</span>
                          <span class="sr-only">Please select a country</span>
                          <span class="is-custom-asterisk">*</span>
                          <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step1Form', 'mailing_country_id')"
                              v-text="getInlineValidationErrorFor('step1Form', 'mailing_country_id')"></span>
                        </label>
                      <el-form-item prop="mailing_country_id">
                        <single-select
                          id="mailing_country_id"
                          :options="countries"
                          v-model="step1Form.form.mailing_country_id"
                          name="mailing_country_id"
                        ></single-select>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>

              <div class="submit-button-wrapper mt-4">
                <el-button type="primary" native-type="submit" @click="submitStep1Form">Continue</el-button>
              </div>
            </div>
            <!-- Step 1 Personal and Company details End -->

            <!-- Step 2 About you -->
            <div v-show="currentStep === 2" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(1)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 2"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                :model="step2Form.form"
                :rules="step2Form.rules"
                ref="step2Form"
                id="step2Form"
                @submit.native.prevent
                autocomplete="false"
                @validate="handleFormFieldInput('step2Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{currentStep}} / 3</span>
                  About You
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-2">Where are you primarily based for work?</h2>

                <div class="row">
                  <div class="col-md-24 col-lg-18">
                  <label for="country_id">
                        <span aria-hidden="true">Select a country</span>
                        <span class="sr-only">Please select a country</span>
                        <span class="is-custom-asterisk">*</span><br />
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step2Form', 'country_id')"
                              v-text="getInlineValidationErrorFor('step2Form', 'country_id')"></span>
                  </label>
                    <el-form-item prop="country_id">
                      <single-select
                        id="country_id"
                        :options="countries"
                        v-model="step2Form.form.country_id"
                        name="country_id"
                        @input="getStatesFromCountry"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row" v-if="states.length > 0">
                  <div class="col-md-24 col-lg-18">
                  <label for="state_id">
                        <span aria-hidden="true">Select a City/State</span>
                        <span class="sr-only">Please select a City or State</span>
                        <span class="is-custom-asterisk">*</span><br />
                        <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step2Form', 'state_id')"
                            v-text="getInlineValidationErrorFor('step2Form', 'state_id')"></span>
                   </label>
                    <el-form-item prop="state_id">
                      <single-select
                        id="state_id"
                        :options="states"
                        v-model="step2Form.form.state_id"
                        name="state_id"
                      ></single-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <div id="businesses-section" v-if="step2Form.form.businesses.length > 0">
                  <h2 class="se-heading-m mb-4">Edit company details</h2>

                  <div class="row">
                    <manage-business
                      :business="business"
                      @update-business="onUpdateBusiness(business, $event)"
                      @remove-business="onRemoveBusiness(business, $event)"
                      :key="index"
                      v-for="(business, index) in step2Form.form.businesses"
                    />
                  </div>
                  <div class="step-hr mt-4" />
                </div>

                <div id="new-company-form-container">
                  <el-form
                    :model="newCompanyForm.company"
                    :rules="newCompanyFormRules"
                    ref="newCompanyForm"
                    id="newCompanyForm"
                    @submit.native.prevent
                    autocomplete="false"
                    @validate="handleFormFieldInput('newCompanyForm')"
                  >
                    <h2
                      class="se-heading-m mb-2"
                      v-if="step2Form.form.businesses.length == 0"
                    >Which company (or companies) do you currently work for or own?</h2>
                    <h2
                      class="se-heading-m mb-2"
                      v-if="step2Form.form.businesses.length > 0"
                    >Add another company you currently work for or own</h2>

                    <p
                      class="se-body-l-screen mb-4"
                      v-if="step2Form.form.businesses.length == 0"
                    >This could also include a company you’re a board member of. We’ll take the first company as your primary one.</p>
                    <p
                      class="se-body-l-screen mb-4"
                      v-if="step2Form.form.businesses.length > 0"
                    >If you want add more than one company, you can add additional companies here.</p>

                    <div
                      v-if="step2Form.form.businesses.length > 0 && !newCompanyForm.additionalCompanyFormOpen"
                    >
                      <div class="row mt-2 mb-4">
                        <div class="col-md-24 col-lg-15 d-flex">
                          <el-button
                            class="w-lg-auto"
                            @click="openAdditionalCompanyForm"
                          >Add another company</el-button>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="step2Form.form.businesses.length == 0 || newCompanyForm.additionalCompanyFormOpen"
                    >
                      <div class="row">
                        <div class="col-md-24 col-lg-18">
                         <label for="name">
                          <span aria-hidden="true">Name of company</span>
                          <span class="sr-only">Name of company</span>
                          <span class="is-custom-asterisk">*</span>
                          <span class="sr-only"
                                aria-live="polite"
                                :hidden="! hasInlineValidationErrorFor('newCompanyForm','name')"
                                v-text="getInlineValidationErrorFor('newCompanyForm', 'name')"></span>
                        </label>
                          <el-form-item prop="name">
                            <el-input
                              v-model="newCompanyForm.company.name"
                              @blur="newCompanyForm.company.name = newCompanyForm.company.name.trim()"
                              id="name"
                              maxlength="255"
                              autocomplete="false"
                              label="Please enter company name in this field"
                              aria-required="true"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-24 col-lg-18">
                          <label for="role">
                          <span aria-hidden="true">Your role at the company</span>
                          <span class="sr-only">Your role at the company</span>
                          <span class="is-custom-asterisk">*</span>
                          <span class="sr-only"
                                aria-live="polite"
                                :hidden="! hasInlineValidationErrorFor('newCompanyForm', 'role')"
                                v-text="getInlineValidationErrorFor('newCompanyForm', 'role')"></span>
                        </label>
                          <el-form-item  prop="role">
                            <el-input
                              v-model="newCompanyForm.company.role"
                              @blur="newCompanyForm.company.role = newCompanyForm.company.role.trim()"
                              id="role"
                              maxlength="255"
                              autocomplete="false"
                              label="Please enter company role in this field"
                              aria-required="true"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row mt-4 mb-4">
                        <div class="col-md-24 col-lg-15 d-flex">
                          <el-button class="w-lg-auto" @click="submitNewCompanyForm">Add company</el-button>
                        </div>
                      </div>
                    </div>
                  </el-form>
                </div>

                <div class="step-hr mt-4"></div>

                <div
                  id="other-businesses-section"
                  v-show="step2Form.form.other_businesses.length > 0"
                >
                  <h2 class="se-heading-m mb-4">Edit company connection details</h2>

                  <div class="row">
                    <manage-other-business
                      :business="business"
                      @update-other-business="onUpdateOtherBusiness(business, $event)"
                      @remove-other-business="onRemoveOtherBusiness(business, $event)"
                      :key="index"
                      v-for="(business, index) in step2Form.form.other_businesses"
                    />
                  </div>
                  <div class="step-hr mt-4" />
                </div>

                <el-form
                  :model="otherCompanyForm.company"
                  :rules="otherCompanyForm.rules"
                  ref="otherCompanyForm"
                  id="otherCompanyForm"
                  @submit.native.prevent
                  autocomplete="false"
                  @validate="handleFormFieldInput('otherCompanyForm')"
                >
                  <h2
                    class="se-heading-m mb-2"
                    v-if="step2Form.form.other_businesses.length == 0"
                  >What other companies do you have a strong connection with?</h2>
                  <h2
                    class="se-heading-m mb-2"
                    v-if="step2Form.form.other_businesses.length > 0"
                  >Add another company you have a strong connection with?</h2>

                  <p
                    class="se-body-l-screen mb-4"
                    v-if="step2Form.form.other_businesses.length == 0"
                  >These are other companies that you could introduce network members to if appropriate. They could include companies you’ve worked with previously or were a board member of.</p>
                  <p
                    class="se-body-l-screen mb-4"
                    v-if="step2Form.form.other_businesses.length > 0"
                  >If you want add more than one company you have a strong connection with, you can add additional company connections here.</p>

                  <div
                    v-if="step2Form.form.other_businesses.length > 0 && !otherCompanyForm.additionalCompanyFormOpen"
                  >
                    <div class="row mt-2 mb-4">
                      <div class="col-md-24 col-lg-15 d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="openAdditionalOtherCompanyForm"
                        >Add another connection</el-button>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="step2Form.form.other_businesses.length == 0 || otherCompanyForm.additionalCompanyFormOpen"
                  >
                    <div class="row">
                      <div class="col-md-24 col-lg-18">
                        <label for="name">
                          <span aria-hidden="true">Name of company - Optional</span>
                          <span class="sr-only">Name of company</span>
                          <span class="sr-only"
                                aria-live="polite"
                                :hidden="! hasInlineValidationErrorFor('otherCompanyForm', 'name')"
                                v-text="getInlineValidationErrorFor('otherCompanyForm', 'name')"></span>
                        </label>
                        <el-form-item
                          prop="name"
                        >
                          <el-input
                            v-model="otherCompanyForm.company.name"
                            @blur="otherCompanyForm.company.name = otherCompanyForm.company.name.trim()"
                            id="name"
                            maxlength="255"
                            autocomplete="false"
                            label="Please enter company name in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row mt-4 mb-4">
                      <div class="col-md-24 col-lg-15 d-flex">
                        <el-button
                          class="w-lg-auto"
                          :disabled="!otherCompanyForm.company.name.length"
                          @click="submitOtherCompanyForm"
                        >Add connection</el-button>
                      </div>
                    </div>
                  </div>
                </el-form>

                <div class="step-hr mt-4" />

                <h2 class="se-heading-m mb-2">What’s your connection to Scotland?</h2>

                <p class="se-body-l-screen mb-4">
                  <label :for="step2Form.form.scotland_connections">
                    You can select more than one
                    <span class="is-custom-asterisk">*</span>
                    <span class="sr-only"
                          aria-live="polite"
                          :hidden="! hasInlineValidationErrorFor('step2Form', 'scotland_connections')"
                          v-text="getInlineValidationErrorFor('step2Form', 'scotland_connections')"></span>
                  </label>
                </p>

                <div class="row">
                  <div class="col-md-24 col-lg-20 d-flex flex-column">

                    <el-form-item prop="scotland_connections">
                      <el-checkbox-group
                        v-model="step2Form.form.scotland_connections"
                        aria-label="Please check one or more connections you have to Scotland"
                      >
                        <div class="row">
                          <div
                            class="col-md-24 col-lg-12 mb-4"
                            v-for="scotlandconnection in scotlandConnections"
                          >
                            <el-checkbox
                              name="scotland_connection_ids"
                              :label="scotlandconnection.id"
                              :key="scotlandconnection.id"
                            >{{ scotlandconnection.label }}</el-checkbox>
                          </div>
                        </div>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                   <label for="scotland_connection">
                   Tell us a bit more about your connection to Scotland - Optional
                    </label>
                    <el-form-item prop="ambitions">
                      <el-input
                        v-model="step2Form.form.scotland_connection"
                        type="textarea"
                        maxlength="500"
                        :rows="5"
                        id="scotland_connection"
                        autocomplete="false"
                        label="Please optionally enter a bit more about your connection to Scotland in this field"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-24 mt-n4 mb-4">
                    <span
                      class="se-body-l-screen color-light-gray"
                    >You have {{500 - step2Form.form.scotland_connection.length}} characters remaining</span>
                  </div>
                </div>

                <div class="step-hr mt-4" />

                <h2
                  class="se-heading-m mb-2"
                >Tell us a bit more about yourself and your business experience.</h2>

                <p class="se-body-l-screen mb-4">
                  We’re looking for a short bio to help us and other users get to know you. You could talk about your career history, why you got into your line of work or career highlights. You can also include personal details.
                  <a href="javascript:void(0);"
                    class="text__link"
                    @click="openExampleModal"
                  >See example</a>
                  <span class="is-custom-asterisk">*</span>
                </p>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="experience">
                      Please enter a short bio to help us and other users get to know you in this field
                      <span class="is-custom-asterisk">*</span>
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step2Form', 'experience')"
                            v-text="getInlineValidationErrorFor('step2Form', 'experience')"></span>
                    </label>
                    <el-form-item prop="experience">
                      <experience-editor v-model="step2Form.form.experience" :max-length='5000' />
                      <!-- <el-input
                        v-model="step2Form.form.experience"
                        type="textarea"
                        maxlength="1500"
                        :rows="5"
                        id="experience"
                        aria-required="true"
                      /> -->
                    </el-form-item>
                  </div>
   <!--                <div class="col-24 mt-n4 mb-4">
                    <span
                      class="se-body-l-screen color-light-gray"
                    >You have {{500 - step2Form.form.experience.length}} characters remaining</span>
                  </div> -->
                </div>
                <h2
                  class="se-heading-m mb-2"
                >Summarise your profile</h2>

                <p class="se-body-l-screen mb-4">
                  We’re looking for a short, one-sentence headline about what kind of expertise you offer.
                  <a href="javascript:void(0);"
                    class="text__link"
                    @click="openExampleSummaryModal"
                  >See example</a>
                  <span class="is-custom-asterisk">*</span>
                </p>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="profile_summary">
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step2Form', 'profile_summary')"
                            v-text="getInlineValidationErrorFor('step2Form', 'profile_summary')"></span>
                    </label>
                    <el-form-item prop="profile_summary">
                      <el-input
                        v-model="step2Form.form.profile_summary"
                        type="textarea"
                        maxlength="280"
                        :rows="5"
                        id="profile_summary"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-24 mt-n4 mb-4">
                    <span
                      class="se-body-l-screen color-light-gray"
                    >You have {{280 - step2Form.form.profile_summary.length}} characters remaining</span>
                  </div>
                </div>


                <div class="step-hr mt-4" />

                <div id="educations-section" v-show="step2Form.form.educations.length > 0">
                  <h2 class="se-heading-m mb-4">Edit education details</h2>

                  <div class="row">
                    <manage-education
                      :education="education"
                      @update-education="onUpdateEducation(education, $event)"
                      @remove-education="onRemoveEducation(education, $event)"
                      :key="index"
                      v-for="(education, index) in step2Form.form.educations"
                    />
                  </div>
                  <div class="step-hr mt-4" />
                </div>

                <el-form
                  :model="newEducationForm.education"
                  :rules="newEducationForm.rules"
                  ref="newEducationForm"
                  id="newEducationForm"
                  @submit.native.prevent
                  autocomplete="false"
                  @validate="handleFormFieldInput('newEducationForm')"
                >
                  <h2
                    class="se-heading-m mb-2"
                    v-if="step2Form.form.educations.length == 0"
                  >Which university or college did you attend?</h2>
                  <h2
                    class="se-heading-m mb-2"
                    v-if="step2Form.form.educations.length > 0"
                  >Add another university or college you attended</h2>

                  <div
                    v-if="step2Form.form.educations.length > 0 && !newEducationForm.additionalEducationFormOpen"
                  >
                    <p
                      class="se-body-l-screen mb-4"
                    >If you want add more than one education, you can add additional educations here.</p>
                    <div class="row mt-2 mb-4">
                      <div class="col-md-24 col-lg-15 d-flex">
                        <el-button
                          class="w-lg-auto"
                          @click="openAdditionalEducationForm"
                        >Add another education</el-button>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="step2Form.form.educations.length == 0 || newEducationForm.additionalEducationFormOpen"
                  >
                    <div class="row">
                      <div class="col-md-24 col-lg-18">
                      <label for="name">
                      This can be a college or university anywhere in the world
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('newEducationForm', 'name')"
                              v-text="getInlineValidationErrorFor('newEducationForm', 'name')"></span>
                      </label>
                        <el-form-item
                          prop="name"
                          class="is-no-asterisk"
                        >
                          <el-input
                            v-model="newEducationForm.education.name"
                            @blur="newEducationForm.education.name = newEducationForm.education.name.trim()"
                            id="name"
                            maxlength="255"
                            autocomplete="false"
                            label="Please enter a university or college name in this field"
                            aria-required="true"
                          />
                        </el-form-item>
                      </div>
                    </div>
                    <div class="row mt-4 mb-4">
                      <div class="col-md-24 col-lg-15 d-flex">
                        <el-button
                          class="w-lg-auto"
                          :disabled="!newEducationForm.education.name.length"
                          @click="submitNewEducationForm"
                        >Add education</el-button>
                      </div>
                    </div>
                  </div>
                </el-form>

                <div class="step-hr mt-4" />

                <div class="submit-button-wrapper mt-4">
                  <el-button type="primary" native-type="submit" @click="submitStep2Form">Continue</el-button>
                </div>
              </el-form>
            </div>
            <!-- Step 2 About you -->

            <!-- Step 3 About you -->
            <div v-show="currentStep === 3" class="body__content">
              <el-button
                type="text"
                size="medium"
                class="step-goback-button align-self-start color-black"
                @click="gotoStep(2)"
              >
                <span aria-hidden="true">&lt; Back</span>
                <span class="sr-only">Go back a step</span>
              </el-button>

              <validation-errors
                v-if="validationErrors.length && currentStep === 3"
                :errors="validationErrors"
              ></validation-errors>

              <el-form
                :model="step3Form.form"
                :rules="step3Form.rules"
                ref="step3Form"
                id="step3Form"
                @submit.native.prevent
                autocomplete="false"
                @validate="handleFormFieldInput('step3Form')"
              >
                <h1 class="se-heading-m step-title">
                  <span class="color-primary">Step {{currentStep}} / 3</span>
                  Your business experience and expertise
                </h1>

                <div class="step-hr"></div>

                <h2 class="se-heading-m mb-2">
                  Which sectors do you have experience in?
                  <span class="is-custom-asterisk">*</span>
                </h2>

                <div v-loading="step3Form.groupsLoading">
                  <div class="row">
                    <div class="col-md-24 col-lg-20 d-flex flex-column">
                      <label class="se-form-label-l-screen mb-4" for="group_ids">
                        <span>You can select more than one</span>
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step3Form', 'group_ids')"
                              v-text="getInlineValidationErrorFor('step3Form', 'group_ids')"></span>
                      </label>
                      <el-form-item id="group_ids" prop="group_ids">
                        <el-checkbox-group
                          v-model="step3Form.form.group_ids"
                        >
                          <div class="row">
                            <div id="form3_group_ids" class="col-md-24 col-lg-12 mb-4" v-for="group in groups">
                              <el-checkbox
                                name="form3_group_ids"
                                :label="group.id"
                                :key="group.id"
                                @change="experienceSectorsChanged(group.id)"
                              >{{ group.name }}</el-checkbox>
                            </div>
                          </div>
                        </el-checkbox-group>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row" v-if="this.step3Form.subGroups.length > 0">
                    <div class="col-md-24 col-lg-20">
                      <el-form-item prop="sub_group_ids">
                        <template slot="label">
                          <span
                            aria-hidden="true"
                          >Which sub-sectors do you have experience in? - Optional</span>
                          <span class="sr-only">Please select one or more sub sectors</span>
                        </template>
                        <v-select
                          v-model="step3Form.form.sub_group_ids"
                          :options="step3Form.subGroups"
                          :multiple="true"
                          :clearable="false"
                          label="name"
                          :reduce="subGroup => subGroup.id"
                          inputId="sub_group_ids"
                          autocomplete="false"
                          :select-on-tab="true"
                          :close-on-select="false"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-2">Your connections and expertise</h2>

                <div class="row">
                  <div class="col-md-24 col-lg-20 d-flex flex-column">
                    <span class="se-form-label-l-screen mt-3">
                      Which other countries do you have connections in or knowledge of?
                      <span
                        class="is-custom-asterisk"
                      >*</span>
                    </span>
                    <span
                      class="se-form-label-l-screen color-light-gray mb-4"
                    >These are places where you have strong connections and a deep knowledge of locations, markets and business regulations</span>
                     <label for="connected_countries">
                        <span
                          class="sr-only"
                        >Please select one or more countries you have a connection with</span>
                       <span class="sr-only"
                             aria-live="polite"
                             :hidden="! hasInlineValidationErrorFor('step3Form', 'connected_countries')"
                             v-text="getInlineValidationErrorFor('step3Form', 'connected_countries')"></span>
                      </label>
                    <el-form-item prop="connected_countries" class="is-no-asterisk">

                      <v-select
                        v-model="step3Form.form.connected_countries"
                        :options="countries"
                        :multiple="true"
                        :clearable="false"
                        label="name"
                        :reduce="country => country.id"
                        inputId="connected_countries"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                        id="connected_countries"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-20 d-flex flex-column">
                    <span class="se-form-label-l-screen mt-3">
                      Your business expertise and skills
                      <span class="is-custom-asterisk">*</span>
                    </span>
                    <label for="expertise">
                        <span
                          class="sr-only"
                        >Please select one or more of your business expertise and skills</span>
                        <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step3Form', 'expertise')"
                            v-text="getInlineValidationErrorFor('step3Form', 'expertise')"></span>
                      </label>
                    <el-form-item prop="expertise" class="is-no-asterisk">
                      <v-select
                        id="expertise"
                        v-model="step3Form.form.expertise"
                        :options="expertises"
                        :multiple="true"
                        :clearable="false"
                        label="name"
                        :reduce="expertise => expertise.id"
                        inputId="expertises"
                        autocomplete="false"
                        :select-on-tab="true"
                        :close-on-select="false"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <label for="activities">
                  <h2 class="se-heading-m mb-2">
                    What types of activities are you interested in?
                    <span class="is-custom-asterisk">*</span>
                  </h2>

                  <span class="sr-only"
                        aria-live="polite"
                        :hidden="! hasInlineValidationErrorFor('step3Form','activities')"
                        v-text="getInlineValidationErrorFor('step3Form', 'activities')"></span>
                </label>

                <div class="row">
                  <div class="col-md-24 col-lg-20 d-flex flex-column">
                    <span
                      class="se-form-label-l-screen mb-4 mt-3"
                    >Select as many as you like. You’ll still be in control of how much time you give, and you can change these at any time.</span>
                    <el-form-item prop="activities">
                      <el-checkbox-group
                        id="activities"
                        v-model="step3Form.form.activities"
                        aria-label="Please check one or more types of activities you are interested in"
                      >
                        <div class="row">
                          <div class="col-md-24 col-lg-12 mb-4" v-for="activity in activities">
                            <el-checkbox
                              name="form3_activity_ids"
                              :label="activity.id"
                              :key="activity.id"
                            >{{ activity.label }}</el-checkbox>
                          </div>
                        </div>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <el-form-item prop="other_activities">
                      <template slot="label">Other - please describe</template>
                      <el-input
                        v-model="step3Form.form.other_activities"
                        type="textarea"
                        maxlength="500"
                        :rows="5"
                        id="other_activities"
                        autocomplete="false"
                        label="Please enter other activities in this field"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-24 mt-n4 mb-4">
                    <span
                      class="se-body-l-screen color-light-gray"
                    >You have {{500 - step3Form.form.other_activities.length}} characters remaining</span>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-4">
                  Where did you hear about the GlobalScot network?
                  <span
                    class="is-custom-asterisk"
                  >*</span>
                </h2>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <el-form-item prop="referrer_id" class="is-no-asterisk">
                      <template slot="label">
                        <span class="sr-only">Please select an option about how you heard about the GlobalScot network</span>
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step3Form', 'referrer_id')"
                              v-text="getInlineValidationErrorFor('step3Form', 'referrer_id')"></span>
                      </template>
                      <single-select
                        id="referrer_id"
                        :options="referrers"
                        v-model="step3Form.form.referrer_id"
                        name="referrer_id"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row" v-if="step3Form.form.referrer_id">
                  <div class="col-md-24 col-lg-16">
                    <el-form-item prop="referrer_text">
                      <template slot="label">
                        {{ getReferrerLabelById(step3Form.form.referrer_id) }}
                        <span class="sr-only"
                              aria-live="polite"
                              :hidden="! hasInlineValidationErrorFor('step3Form', 'referrer_text')"
                              v-text="getInlineValidationErrorFor('step3Form', 'referrer_text')"></span>
                      </template>
                      <el-input
                        type="text"
                        v-model="step3Form.form.referrer_text"
                        @blur="step3Form.form.referrer_text = step3Form.form.referrer_text.trim()"
                        id="referrer_text"
                        maxlength="255"
                        autocomplete="false"
                        label="Please enter information about the referrer in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="step-hr mt-4"></div>

                <h2 class="se-heading-m mb-4">
                  And finally, why do you want to become a GlobalScot?
                  <span
                    class="is-custom-asterisk"
                  >*</span>
                </h2>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                  <label for="motivations">
                        Now that we know more about you, your expertise and your connections, we’d like to know a bit more about what motivated you to apply.
                        <br />
                        <span
                          class="el-form-item__info"
                        >This won’t be publicly displayed on your profile</span>
                        <span class="sr-only"
                          aria-live="polite"
                          :hidden="! hasInlineValidationErrorFor('step3Form', 'motivations')"
                          v-text="getInlineValidationErrorFor('step3Form', 'motivations')"></span>
                      </label>
                    <el-form-item prop="motivations" class="is-no-asterisk">

                      <el-input
                        v-model="step3Form.form.motivations"
                        type="textarea"
                        :rows="5"
                        maxlength="1500"
                        id="motivations"
                        autocomplete="false"
                        label="Please enter the reasons that motivated you to apply in this field"
                        aria-required="true"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-24 mt-n4 mb-4">
                    <span
                      class="se-body-l-screen color-light-gray"
                    >You have {{1500 - step3Form.form.motivations.length}} characters remaining</span>
                  </div>
                </div>

                <label for="support_file">
                  <h2 class="se-heading-m mb-2">
                    Supporting document - Optional
                  </h2>

                  <span aria-hidden="true">Please upload a document that you feel will help support your application to become a GlobalScot. </span><br />
                  <label class="sr-only" for="title">Please upload a document that you feel will help support your application to become a GlobalScot. </label>
                        <span class="el-form-item__info">This won’t be publicly displayed on your profile</span>
                  <span class="sr-only"
                        aria-live="polite"
                        :hidden="! hasInlineValidationErrorFor('step3Form','support_file')"
                        v-text="getInlineValidationErrorFor('step3Form', 'support_file')"></span>
                </label>
                <el-row class="mt-3" >
                  <el-col :md="24" :lg="18">
                    <el-upload
                      action=""
                      ref="upload"
                      :multiple="false"
                      :drag="true"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="onSupportFileChange"
                      :show-file-list="false">

                      <div slot="default">
                        <div class="se-file-upload">
                          <img src="/assets/img/file-drag-drop.svg" alt="Drag & drop your file" class="drag-drop-img">
                          <span class="or-text se-body-s-screen-16 m-4">or</span>
                          <el-button class="browse-button">{{ step3Form.form.support_file ? 'Replace File' : 'Find file' }}</el-button>
                        </div>
                      </div>
                      <div slot="file" slot-scope="{file}"></div>
                    </el-upload>
                  </el-col>
                  <el-col v-if="step3Form.form.support_file" :md="24" :lg="18" class='suppor-file-preview'>
                    <div class="d-flex align-items-center" :style="{ gap: '15px' }">
                      <div class="icon"><img :src="helpers.getFileIcon(step3Form.form.support_file.name)" /></div>
                      <div class="w-100">{{ step3Form.form.support_file.name }} ({{ getReadableFilesize(step3Form.form.support_file.size) }})</div>
                      <div class="remove-btn clickable" @click='removeSelectedAvatar'>Remove</div>
                    </div>
                  </el-col>
                </el-row>

                <div class="step-hr mt-4"></div>

                <el-row >
                  <el-col :md="24" :lg="18">
                    <el-form-item id="is_russian_self_declared" prop="is_russian_self_declared">
                      <el-checkbox-group v-model="step3Form.form.is_russian_self_declared" v-for="(russianSelfDeclaration,index) in step3Form.russianSelfDeclarations" :key="index">
                        <el-checkbox class="pb-3"
                          :name="russianSelfDeclaration.key"
                          :label="russianSelfDeclaration.key"
                          >
                          {{russianSelfDeclaration.text}}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="terms_agreed">
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step3Form', 'terms_agreed')"
                            v-text="getInlineValidationErrorFor('step3Form', 'terms_agreed')"></span>
                    </label>
                    <el-form-item prop="terms_agreed">
                      <el-checkbox-group id="terms_agreed" v-model="step3Form.form.terms_agreed">
                        <el-checkbox label="confirmed" name="terms_agreed">
                          I agree to the
                          <a :href="getCMSRoute('privacy-policy')" class="text__link" target="_blank">privacy policy</a> and
                          <a :href="getCMSRoute('cookie-policy')" class="text__link" target="_blank">cookies policy</a>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-24 col-lg-20">
                    <label for="globalscot_terms_agreed">
                      <span class="sr-only"
                            aria-live="polite"
                            :hidden="! hasInlineValidationErrorFor('step3Form', 'globalscot_terms_agreed')"
                            v-text="getInlineValidationErrorFor('step3Form', 'globalscot_terms_agreed')"></span>
                    </label>
                    <el-form-item prop="globalscot_terms_agreed">
                      <el-checkbox-group id="globalscot_terms_agreed" v-model="step3Form.form.globalscot_terms_agreed">
                        <el-checkbox label="confirmed" name="globalscot_terms_agreed">
                          I understand what’s required of me as a GlobalScot and agree to the
                          <a :href="getCMSRoute('terms-conditions')" class="text__link" target="_blank"> GlobalScot terms & conditions.</a>
                          This includes the GlobalScot acceptable use policy.
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                </div>

                <div class="submit-button-wrapper mt-4">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="submitStep3Form"
                  >Submit application</el-button>
                </div>
              </el-form>
            </div>
            <div v-show="currentStep === 4" class="body__content">
              <h1 class="se-heading-xl-40 mb-4">Thanks for submitting your application</h1>

              <p
                class="se-body-l-screen-19 mb-4"
              >We’ll review the information you’ve provided and get in touch in 3-5 days to discuss the next steps.</p>

              <p class="se-body-l-screen-19 mb-4">
                If you need to speak to us about your application, just
                <a :href="getCMSRoute('contact-us')" class="text__link">get in touch.</a>
              </p>

              <div class="d-flex align-items-start justify-content-start mb-4">
                <a :href="getCMSHome()">
                  <el-button type="primary">Back to homepage</el-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import loqateApi from "@/services/loqate.api";
import manageBusiness from "@/components/partials/gs-register-manage-business";
import manageOtherBusiness from "@/components/partials/gs-register-manage-other-business";
import manageEducation from "@/components/partials/gs-register-manage-education";
import validationErrors from "@/components/partials/validation-errors";
import ExperienceEditor from '@/components/common/ExperienceEditor'
import helpers from "@/helpers/index";
import {validate} from 'email-validator'
import filesize from "filesize";
const ALLOWED_DOCUMENT_TYPES = [
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
  "image/jpeg",
  "image/png",
  'text/csv'
];
export default {
  name: 'signup-gs',
  metaInfo: {
    title: "Apply to become a GlobalScot",
    meta: [{
      name: 'description',
      content: "Are you a successful business person with connections to Scotland and keen to offer your expertise to Scottish businesses? Apply to become a GlobalScot today."
    }]
  },
  components: {
    manageBusiness,
    manageOtherBusiness,
    manageEducation,
    validationErrors,
    ExperienceEditor
  },
  data: function() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter a valid email address"));
      } else if (validate(value) == false) {
        callback(new Error("Enter a valid email address"));
      } else {
        callback();
      }
    };
    const validateEmailConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else if (value !== this.step1Form.form.email) {
        callback(
          new Error(
            "Email address doesn’t match. Make sure the email address in both email fields match."
          )
        );
      } else {
        callback();
      }
    };
    const validateContactNumber = (rule, value, callback) => {
      let reg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
      if (value === "") {
        callback(new Error("Enter a valid contact number"));
      } else if (value.length < 7) {
        callback(
          new Error("Contact number may not be less than 7 characters")
        );
      } else if (value.length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Enter a valid contact number"));
      } else {
        callback();
      }
    };
    const validateAgreement = (rule, value, callback) => {
        if(value.length < 3) {
          callback(new Error("You cannot continue without checking all boxes"));
        }
        else{
          callback();
        }
    }
    return {
      helpers,
      showPreview:false,
      currentStep: 0,
      mainLoader: false,
      validationErrors: [],
      aspects: [],
      countries: [],
      states: [],
      groups: [],
      nationalities: [],
      referrers: [],
      scotlandConnections: [],
      expertises: [],
      activities: [],
      regions: [],
      subGroups: [],
      titles: [
        {id: 1, label: 'Mr'},
        {id: 2, label: 'Mrs'},
        {id: 3, label: 'Ms'},
        {id: 4, label: 'Miss'},
        {id: 6, label: 'Dr'},
        {id: 7, label: 'Prof'},
        {id: 8, label: 'Sir'},
        {id: 9, label: 'Dame'},
        {id: 10, label: 'Reverend'},
        {id: 11, label: 'Duke'},
        {id: 12, label: 'Duchess'},
        {id: 5, label: 'Other'},
        {id: 13, label: "Mx"},
      ],
      step1Form: {
        form: {
          title: "",
          first_name: "",
          last_name: "",
          nationality_id: null,
          contact_number: "",
          email: "",
          email_confirmation: "",
          has_mailing_address: true,
          mailing_address_1: "",
          mailing_address_2: "",
          mailing_post_code: "",
          mailing_town: "",
          mailing_county: "",
          mailing_country_id: null
        },
        rules: {
          title: [
            {
              required: true,
              message: "Select a title from the dropdown",
              trigger: "change"
            }
          ],
          first_name: [
            {
              required: true,
              message: "Enter your first name",
              trigger: "blur"
            },
            {
              max: 64,
              message: "First name may not be greater than 64 characters",
              trigger: "blur"
            }
          ],
          last_name: [
            {
              required: true,
              message: "Enter your last name",
              trigger: "blur"
            },
            {
              max: 64,
              message: "Last name may not be greater than 64 characters",
              trigger: "blur"
            }
          ],
          nationality_id: [
            {
              required: true,
              message: "Select a nationality",
              trigger: "change"
            }
          ],
          email: [{ validator: validateEmail, required: true }],
          email_confirmation: [
            { validator: validateEmailConfirmation, required: true }
          ],
          contact_number: [
            { validator: validateContactNumber, required: true }
          ],
          mailing_post_code: [
            { required: true, message: "Enter a postcode", trigger: "blur" },
            {
              max: 10,
              message:
                "Post code/Zip code may not be greater than 10 characters",
              trigger: "blur"
            }
          ],
          mailing_address_1: [
            {
              required: true,
              message: "Enter the first line of your address",
              trigger: "blur"
            },
            {
              max: 255,
              message: "Address line 1 may not be greater than 255 characters",
              trigger: "blur"
            }
          ],
          mailing_address_2: [
            {
              max: 255,
              message: "Address line 2 may not be greater than 255 characters",
              trigger: "blur"
            }
          ],
          mailing_town: [
            {
              required: true,
              message: "Enter a town or city",
              trigger: "blur"
            },
            {
              max: 100,
              message:
                "Town, City or State may not be greater than 100 characters",
              trigger: "blur"
            }
          ],
          mailing_county: [
            {
              max: 100,
              message: "Region may not be greater than 100 characters",
              trigger: "blur"
            }
          ],
          mailing_country_id: [
            {
              required: true,
              message: "Select a country from the dropdown",
              trigger: "change"
            }
          ]
        },
        addressesLoading: false,
        disableAddressLookup: false,
        selectedAvailableMailingAddress: null,
        availableMailingAddresses: [],
        inlineValidationErrors: [],
      },

      step2Form: {
        // Step 2
        form: {
          country_id: null,
          state_id: null,
          scotland_connections: [],
          scotland_connection: "",
          experience: "",
          businesses: [],
          other_businesses: [],
          educations: [],
          profile_summary:""
        },
        aspects: [],
        businessAspects: [],
        regions: [],
        rules: {
          country_id: [
            {
              required: true,
              message: "Select an option from the dropdown",
              trigger: "change"
            }
          ],
          state_id: [
            {
              required: true,
              message: "Enter a state or city",
              trigger: "change"
            }
          ],
          scotland_connections: [
            {
              type: "array",
              required: true,
              message:
                "Select an option to confirm your connection to Scotland",
              trigger: "change"
            }
          ],
          scotland_connection: [
            {
              max: 500,
              message: "May not be greater than 500 characters",
              trigger: "change"
            }
          ],
          experience: [
            {
              required: true,
              message:
                "Enter a description of your current and previous business experience. You can use the example provided if you need some guidance.",
              trigger: "blur"
            },
            {
              max: 5000,
              message:
                "Business Experience may not be greater than 5000 characters",
              trigger: "change"
            }
          ],
          profile_summary:[
            {
              required: true,
              message:
                "Enter a summary for you profile. You can use the example provided if you need some guidance.",
              trigger: "blur"
            },
            {
              max: 280,
              message:
                "Profile summary may not be greater than 280 characters",
              trigger: "change"
            }
          ]
        },
        inlineValidationErrors: [],
      },

      newCompanyForm: {
        company: {
          name: "",
          role: ""
        },
        additionalCompanyFormOpen: false,
        inlineValidationErrors: [],
      },

      newCompanyFormRules: {
        name: [
          {
            required: true,
            message: "Enter the name of the company",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Name may not be greater than 255 characters",
            trigger: "change"
          }
        ],
        role: [
          {
            required: true,
            message: "Enter your role within the company",
            trigger: "blur"
          },
          {
            max: 255,
            message: "Role may not be greater than 255 characters",
            trigger: "change"
          }
        ]
      },

      otherCompanyForm: {
        company: {
          name: ""
        },
        additionalCompanyFormOpen: false,
        rules: {
          name: [
            { message: "Name is required", trigger: "blur" },
            {
              max: 255,
              message: "Name may not be greater than 255 characters",
              trigger: "change"
            }
          ]
        },
        inlineValidationErrors: [],
      },

      newEducationForm: {
        education: {
          name: ""
        },
        additionalEducationFormOpen: false,
        rules: {
          name: [
            { required: true, message: "Name is required", trigger: "blur" },
            {
              max: 255,
              message: "Name may not be greater than 255 characters",
              trigger: "change"
            }
          ]
        },
        inlineValidationErrors: [],
      },

      // Step 3
      step3Form: {
        form: {
          group_ids: [],
          sub_group_ids: [],
          connected_countries: [],
          expertise: [],
          activities: [],
          other_activities: "",
          motivations: "",
          referrer_id: null,
          referrer_text: "",
          terms_agreed: [],
          globalscot_terms_agreed: [],
          is_russian_self_declared:[],
          support_file: null
        },
        rules: {
          group_ids: [
            {
              type: "array",
              required: true,
              message: "Select a sector",
              trigger: "change"
            }
          ],
          connected_countries: [
            {
              type: "array",
              required: true,
              message:
                "Enter a country that you have strong connections in or to",
              trigger: "change"
            }
          ],
          expertise: [
            {
              type: "array",
              required: true,
              message: "Select your skills and expertise from the dropdown",
              trigger: "change"
            }
          ],
          activities: [
            {
              type: "array",
              required: true,
              message: "Select at least one activity",
              trigger: "change"
            }
          ],
          other_activities: [
            {
              max: 500,
              message: "May not be greater than 500 characters",
              trigger: "change"
            }
          ],
          referrer_id: [
            {
              required: true,
              message: "Tell us how you heard about the network",
              trigger: "change"
            }
          ],
          referrer_text: [
            {
              required: true,
              message: "Add some more detail about where you heard about us",
              trigger: "blur"
            }
          ],
          motivations: [
            {
              required: true,
              message: "Tell us why you want to become a GlobalScot",
              trigger: "blur"
            },
            {
              max: 1500,
              message: "May not be greater than 1500 characters",
              trigger: "change"
            }
          ],
          is_russian_self_declared: [
            { validator: validateAgreement, required: true }
          ],
          terms_agreed: [
            {
              type: "array",
              required: true,
              message: "Please confirm you agree with our policies",
              trigger: "change"
            }
          ],
          globalscot_terms_agreed: [
            {
              type: "array",
              required: true,
              message:
                "Please confirm you agree with the GlobalScot terms & conditions",
              trigger: "change"
            }
          ]
        },
        subGroups: [],
        groupsLoading: false,
        inlineValidationErrors: [],
        russianSelfDeclarations:[
            {
              key:'code_conduct_readed',
              text:'I have read the Code of Conduct.'
            },
            {
              key:'ask_invertment_with',
              text:'I also understand that as part of my registration Scottish Enterprise may ask about the nature of trading and/or investment links with Russia and Belarus.'
            },
            {
              key:'no_subject_to_uk_sanctions',
              text:'I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.'
            },
        ],
      }
    };
  },
  computed: {
    heroText: function() {
      if (this.currentStep == 1) {
        return '“GlobalScots are people who want to give something back and I’m delighted to be a part of that.” <p class="mt-3">GlobalScot Allan Rooney</p>'
      } else if (this.currentStep == 2) {
        return 'From AI and green tech to sculpture and space exploration, our community’s knowledge is always growing.'
      } else if (this.currentStep == 3) {
        return 'The GlobalScot network has been supporting businesses in Scotland for almost 20 years'
      } else if (this.currentStep == 4) {
        return "Application complete! We’ll let you know soon."
      } else {
        return 'Over 20 years of ambition and impact. One shared passion for Scotland’s success.'
      }
    },
  },
  methods: {
    getReadableFilesize(sizeInBytes) {
      return filesize(parseInt(sizeInBytes), { base: 10 });
    },
    onUpdateBusiness(business, updatedBusiness) {
      business.name = updatedBusiness.name;
      business.role = updatedBusiness.role;
    },
    onRemoveBusiness(business) {
      this.$confirm(
        "Are you sure you want to remove this company?",
        "Remove company",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "modal-confirm-button",
          cancelButtonClass: "modal-cancel-button",
          type: "warning"
        }
      )
        .then(() => {
          this.step2Form.form.businesses.splice(
            this.step2Form.form.businesses.indexOf(business),
            1
          );

          if (this.step2Form.form.businesses.length === 0) {
            this.newCompanyForm.additionalCompanyFormOpen = false;
          }
          this.$alert("Company has been removed.", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .catch(() => {});
    },
    onUpdateOtherBusiness(business, updatedBusiness) {
      business.name = updatedBusiness.name;
    },
    onRemoveOtherBusiness(business) {
      this.$confirm(
        "Are you sure you want to remove this company?",
        "Remove company",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "modal-confirm-button",
          cancelButtonClass: "modal-cancel-button",
          type: "warning"
        }
      )
        .then(() => {
          this.step2Form.form.other_businesses.splice(
            this.step2Form.form.other_businesses.indexOf(business),
            1
          );

          if (this.step2Form.form.other_businesses.length === 0) {
            this.otherCompanyForm.additionalCompanyFormOpen = false;
          }

          this.$alert("Company has been removed.", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .catch(() => {});
    },
    onUpdateEducation(education, updatedEducation) {
      education.name = updatedEducation.name;
    },
    onRemoveEducation(education) {
      this.$confirm(
        "Are you sure you want to remove this education?",
        "Remove education",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "modal-confirm-button",
          cancelButtonClass: "modal-cancel-button",
          type: "warning"
        }
      )
        .then(() => {
          this.step2Form.form.educations.splice(
            this.step2Form.form.educations.indexOf(education),
            1
          );

          if (this.step2Form.form.educations.length === 0) {
            this.newEducationForm.additionalEducationFormOpen = false;
          }
          this.$alert("Education has been removed.", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          });
        })
        .catch(() => {});
    },
    openAdditionalCompanyForm() {
      this.newCompanyForm.additionalCompanyFormOpen = true;
    },
    openAdditionalOtherCompanyForm() {
      this.otherCompanyForm.additionalCompanyFormOpen = true;
    },
    openAdditionalEducationForm() {
      this.newEducationForm.additionalEducationFormOpen = true;
    },
    openExampleModal() {
      const h = this.$createElement;

      this.$msgbox({
        title: "",
        message: h("p", null, [
          h(
            "h2",
            null,
            "Here’s an example of the type and detail of information we’d expect to see in this section:"
          ),
          h(
            "p",
            null,
            "Experienced Marketing Director for both corporate and start-up enterprises. I’m an expert in business communications, operational management, editorial direction and product/process design, however my specialities lie in creative direction and transformational change. I have worked in several countries across Singapore, Malaysia and France."
          ),
          h(
            "p",
            null,
            "As Chief Marketing Officer and Head of Communications at KM, I created the product-led marketing approach that scaled the company from £10m to £50m in just under 2 years and have been an integral part of the management team since 2014. I am directly responsible for our team of marketers, developing and executing all marketing communications activity across our global portfolio of clients. We work with both B2B and B2C clients across several sectors, however with a focus on fashion, retail, creative and technology industries."
          ),
          h(
            "p",
            null,
            "Prior to KM, I served as the Chief Marketing Officer for a fast-growing start-up based in Paris where I lead a small team, delivering bespoke marketing solutions to brand names such as A, B and C."
          ),
          h(
            "p",
            null,
            "I also serve as an Advisor and Mentor to the Chartered Institute of Marketing. I currently serve on the board of LM, a company that specialises in communication technology."
          )
        ]),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
        cancelButtonClass: "cancel-btn",
        customClass: "experience-example-modal"
      }).catch(() => {});
    },
    openExampleSummaryModal() {
      const h = this.$createElement;

      this.$msgbox({
        title: "",
        message: h("p", null, [
          h(
            "h2",
            null,
            "Here’s an example of the type and detail of information we’d expect to see in this section:"
          ),
          h(
            "p",
            null,
            "Digital innovator for the marine and tech sectors, with experience of investor pitching and moving a Scottish business to the USA."
          ),
        ]),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
        cancelButtonClass: "cancel-btn",
        customClass: "experience-example-modal"
      }).catch(() => {});
    },
    getStatesFromCountry(countryId) {
      this.step2Form.form.state_id = null;
      this.mainLoader = true;

      gsApi.state.index(countryId).then(response => {
        this.mainLoader = false;
        this.states = response.data.data;
      });
    },
    getReferrerLabelById(referrerId) {
      let label = ''

      const referrer = this.referrers.find(referrer => referrer.id == referrerId)

      if (referrer) {
        label = referrer.type_label
      }

      return label
    },
    submitStep1Form() {
      this.$refs["step1Form"].validate(valid => {
        if (valid) {
          this.mainLoader = true;

          gsApi.globalscot
            .validateStepOne(this.step1Form.form)
            .then(response => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(2);
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step1Form.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step1Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitOtherCompanyForm() {
      if (this.step2Form.form.other_businesses.length < 5) {
        this.$refs["otherCompanyForm"].validate(valid => {
          if (valid) {
            this.step2Form.form.other_businesses.push(
              this.otherCompanyForm.company
            );

            this.otherCompanyForm.company = {
              name: ""
            };
            this.otherCompanyForm.additionalCompanyFormOpen = false;

            this.$nextTick(() => {
              this.$refs["otherCompanyForm"].resetFields();
              document
                .getElementById("other-businesses-section")
                .scrollIntoView();
              this.$alert("Company has been added.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              });
            });
          } else {
            this.$nextTick(() => {
              document
                .getElementById("otherCompanyForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView();
              let text = document
                .getElementById("otherCompanyForm")
                .getElementsByClassName("el-form-item__error")[0].textContent;
              this.$announcer.set(text);
            });
          }
        });
      } else {
        this.$alert(
          "You can only add a maximum of 5 company connections.",
          "Error",
          {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal"
          }
        );
      }
    },
    submitNewEducationForm() {
      if (this.step2Form.form.educations.length < 10) {
        this.$refs["newEducationForm"].validate(valid => {
          if (valid) {
            this.step2Form.form.educations.push(
              this.newEducationForm.education
            );

            this.newEducationForm.education = {
              name: ""
            };
            this.newEducationForm.additionalEducationFormOpen = false;

            this.$nextTick(() => {
              this.$refs["newEducationForm"].resetFields();
              document.getElementById("educations-section").scrollIntoView();
              this.$alert("Education has been added.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              });
            });
          } else {
            this.$nextTick(() => {
              document
                .getElementById("newEducationForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView();
              let text = document
                .getElementById("newEducationForm")
                .getElementsByClassName("el-form-item__error")[0].textContent;
              this.$announcer.set(text);
            });
          }
        });
      } else {
        this.$alert("You can only add a maximum of 10 educations.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
      }
    },
    submitNewCompanyForm() {
      if (this.step2Form.form.businesses.length < 10) {
        this.$refs["newCompanyForm"].validate(valid => {
          if (valid) {
            this.step2Form.form.businesses.push(this.newCompanyForm.company);

            this.newCompanyForm.company = {
              name: "",
              role: ""
            };
            this.newCompanyForm.additionalCompanyFormOpen = false;

            this.$nextTick(() => {
              this.$refs["newCompanyForm"].resetFields();
              document.getElementById("businesses-section").scrollIntoView();
              this.$alert("Company has been added.", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              });
            });
          } else {
            this.$nextTick(() => {
              document
                .getElementById("newCompanyForm")
                .getElementsByClassName("is-error")[0]
                .scrollIntoView();
              let text = document
                .getElementById("newCompanyForm")
                .getElementsByClassName("el-form-item__error")[0].textContent;
              this.$announcer.set(text);
            });
          }
        });
      } else {
        this.$alert("You can only add a maximum of 10 companies.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal"
        });
      }
    },
    submitStep2Form() {
      this.$refs["step2Form"].validate(valid => {
        if (valid) {
          if (this.step2Form.form.businesses.length === 0) {
            document
              .getElementById("new-company-form-container")
              .scrollIntoView();
            this.$alert(
              "Please add at least one company before proceeding",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
            return;
          }

          this.mainLoader = true;
          const formData = new FormData();
          // Step 2
          formData.append("country_id", this.step2Form.form.country_id);
          formData.append("state_id", this.step2Form.form.state_id);

          this.step2Form.form.businesses.forEach((business, index) => {
            formData.append(`businesses[${index}][name]`, business.name);
            formData.append(`businesses[${index}][role]`, business.role);
          });

          formData.append(`other_businesses`, []);
          this.step2Form.form.other_businesses.forEach((business, index) => {
            formData.append(`other_businesses[${index}][name]`, business.name);
          });

          this.step2Form.form.scotland_connections.forEach(
            (scotlandConnection, index) => {
              formData.append(
                `scotland_connections[${index}]`,
                scotlandConnection
              );
            }
          );
          formData.append(
            "scotland_connection",
            this.step2Form.form.scotland_connection
          );
          formData.append("experience", this.$options.filters.domPurify(this.step2Form.form.experience, {ALLOWED_TAGS: ['p','u','i','li','ul','ol','a','strong']}));
          formData.append("profile_summary", this.$options.filters.domPurify(this.step2Form.form.profile_summary, {ALLOWED_TAGS: []}));

          formData.append(`educations`, []);
          this.step2Form.form.educations.forEach((education, index) => {
            formData.append(`educations[${index}][name]`, education.name);
          });
          gsApi.globalscot
            .validateStepTwo(formData)
            .then(response => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.newCompanyForm.additionalCompanyFormOpen = false;
              this.otherCompanyForm.additionalCompanyFormOpen = false;
              this.newEducationForm.additionalEducationFormOpen = false;
              this.gotoStep(3);
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step2Form.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step2Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    submitStep3Form() {
      this.$refs["step3Form"].validate(valid => {
        if (valid) {
          const formData = new FormData();

          // Step 1
          formData.append("title", this.step1Form.form.title);
          formData.append("first_name", this.$options.filters.domPurify(this.step1Form.form.first_name, {ALLOWED_TAGS: []}));
          formData.append("last_name", this.$options.filters.domPurify(this.step1Form.form.last_name, {ALLOWED_TAGS: []} ));
          formData.append("contact_number", this.step1Form.form.contact_number);
          formData.append(
            "has_mailing_address",
            this.step1Form.form.has_mailing_address
          );

          if (this.step1Form.form.has_mailing_address) {
            formData.append(
              "mailing_address_1",
              this.step1Form.form.mailing_address_1
            );
            formData.append(
              "mailing_address_2",
              this.step1Form.form.mailing_address_2
            );
            formData.append(
              "mailing_post_code",
              this.step1Form.form.mailing_post_code
            );
            formData.append("mailing_town", this.step1Form.form.mailing_town);
            formData.append(
              "mailing_county",
              this.step1Form.form.mailing_county
            );
            formData.append(
              "mailing_country_id",
              this.step1Form.form.mailing_country_id
            );
          }

          formData.append("nationality_id", this.step1Form.form.nationality_id);
          formData.append("email", this.step1Form.form.email);
          formData.append(
            "email_confirmation",
            this.step1Form.form.email_confirmation
          );

          // Step 2
          formData.append("country_id", this.step2Form.form.country_id);
          formData.append("state_id", this.step2Form.form.state_id);

          this.step2Form.form.businesses.forEach((business, index) => {
            formData.append(`businesses[${index}][name]`, business.name);
            formData.append(`businesses[${index}][role]`, business.role);
          });

          formData.append(`other_businesses`, []);
          this.step2Form.form.other_businesses.forEach((business, index) => {
            formData.append(`other_businesses[${index}][name]`, business.name);
          });

          this.step2Form.form.scotland_connections.forEach(
            (scotlandConnection, index) => {
              formData.append(
                `scotland_connections[${index}]`,
                scotlandConnection
              );
            }
          );
          formData.append(
            "scotland_connection",
            this.step2Form.form.scotland_connection
          );
          formData.append("experience", this.$options.filters.domPurify(this.step2Form.form.experience, {ALLOWED_TAGS: ['p','u','i','li','ul','ol','a','strong']}));
          formData.append("profile_summary", this.$options.filters.domPurify(this.step2Form.form.profile_summary, {ALLOWED_TAGS: []}));

          formData.append(`educations`, []);
          this.step2Form.form.educations.forEach((education, index) => {
            formData.append(`educations[${index}][name]`, education.name);
          });

          // Step 3
          this.step3Form.form.group_ids.forEach((groupId, index) => {
            formData.append(`group_ids[${index}]`, groupId);
          });
          formData.append(`sub_group_ids`, []);
          this.step3Form.form.sub_group_ids.forEach((subGroupId, index) => {
            formData.append(`sub_group_ids[${index}]`, subGroupId);
          });
          this.step3Form.form.connected_countries.forEach(
            (connectedCountry, index) => {
              formData.append(
                `connected_countries[${index}]`,
                connectedCountry
              );
            }
          );
          this.step3Form.form.expertise.forEach((expertise, index) => {
            formData.append(`expertise[${index}]`, expertise);
          });
          this.step3Form.form.activities.forEach((activity, index) => {
            formData.append(`activities[${index}]`, activity);
          });
          formData.append(
            "other_activities",
            this.step3Form.form.other_activities
          );
          formData.append("referrer_id", this.step3Form.form.referrer_id);
          formData.append("referrer_text", this.step3Form.form.referrer_text);
          formData.append("motivations", this.step3Form.form.motivations);

          this.step3Form.form.is_russian_self_declared.forEach(item => {
            formData.append("is_russian_self_declared[]", item);
          })

          if(this.step3Form.form.support_file)
          {
            formData.append("support_file", this.step3Form.form.support_file.raw);
          }

          this.mainLoader = true;

          gsApi.globalscot
            .register(formData)
            .then(response => {
              this.validationErrors = [];
              this.mainLoader = false;
              this.gotoStep(4);
            })
            .catch(error => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal"
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.step3Form.fields.forEach(field => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("step3Form")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    onSupportFileChange(file){

      if(!this.validateFileUpload(file)) return;

      this.step3Form.form.support_file = file
    },
    validateFileUpload(file) {

        let type = (file.raw && file.raw.type) ? file.raw.type : file.type
        let size = (file.raw && file.raw.size) ? file.raw.size : file.size

        const isLt6M = size / 1024 / 1024 < 6;

        if (ALLOWED_DOCUMENT_TYPES.indexOf(type) == -1) {

          this.$alert('The file must be a file of type: pdf, ppt, pptx, doc, docx, xls, xlsx, csv, odt, odp, ods, jpeg, png', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          return false;
        }

        if (!isLt6M) {
          this.$alert('Your supporting documentation cannot exceed 6MB.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          return false;
        }

        return true;
    },
    removeSelectedAvatar(){
      this.step3Form.form.support_file = null
      this.$refs.upload.clearFiles()
    },
    experienceSectorsChanged(groupId) {
      if (this.step3Form.form.group_ids.includes(groupId)) {
        this.step3Form.groupsLoading = true;

        gsApi.group.subGroups(groupId)
        .then(response => {
          this.step3Form.groupsLoading = false;

          response.data.data.forEach(subgroup => {
            this.step3Form.subGroups.push(subgroup);
          });
        });
      } else {
        // Removing selected sub groups where sub group is about to be removed below
        let subGroupsToBeRemoved = this.step3Form.subGroups.filter(
          item => item.group_id == groupId
        );
        subGroupsToBeRemoved.forEach(subgroup => {
          if (this.step3Form.form.sub_group_ids.includes(subgroup.id)) {
            let index = this.step3Form.form.sub_group_ids.indexOf(subgroup.id);
            this.step3Form.form.sub_group_ids.splice(index, 1);
          }
        });

        // Removing sub groups with group_id of selected group
        let newSubgroups = this.step3Form.subGroups.filter(
          item => item.group_id !== groupId
        );
        this.step3Form.subGroups = newSubgroups;
      }
    },
    onFindAddress(e) {
      this.findAddresses(this.step1Form.form.mailing_post_code);
    },
    findAddresses(value, containerId = "") {
      this.step1Form.addressesLoading = true;
      loqateApi
        .find(value, containerId, "")
        .then(response => {
          this.step1Form.addressesLoading = false;

          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal"
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.step1Form.selectedAvailableMailingAddress = null;
              this.step1Form.form.mailing_address_1 = "";
              this.step1Form.form.mailing_address_2 = "";
              this.step1Form.form.mailing_town = "";
              this.step1Form.form.mailing_county = "";
              this.step1Form.form.mailing_country_id = null;

              this.step1Form.disableAddressLookup = true;
              this.step1Form.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.step1Form.availableMailingAddresses = [];
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        })
        .catch(error => {
          this.step1Form.addressesLoading = false;
          this.step1Form.availableMailingAddresses = [];
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    onAvailableMailingAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then(response => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {
            this.step1Form.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.step1Form.form.mailing_address_1 = address.Line1;
            this.step1Form.form.mailing_address_2 = address.Line2;
            this.step1Form.form.mailing_post_code = address.PostalCode;
            this.step1Form.form.mailing_town = address.City;
            this.step1Form.form.mailing_county = address.AdminAreaName;

            let country = this.countries.find(
              c =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.step1Form.form.mailing_country_id = country.id;
            }
          } else {
            this.$alert(
              "No Postcode found. Please add it manually.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal"
              }
            );
          }
        })
        .catch(error => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
        });
    },
    gotoStep(step) {
      this.currentStep = step || 0;
      if (this.currentStep == 1) {
        this.$announcer.set("Step 1 of 3 - Personal and company details");
      } else if (this.currentStep == 2) {
        this.$announcer.set("Step 2 of 3 - About You");
      } else if (this.currentStep == 3) {
        this.$announcer.set(
          "Step 3 of 3 -  Your business experience and expertise"
        );
      } else if (this.currentStep == 4) {
        this.$announcer.set("Thanks for submitting your application");
      }
      window.scrollTo(0, 0);
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    getRegisterMeta() {
      this.mainLoader = true;

      gsApi.globalscot.meta
        .index()
        .then(response => {
          this.mainLoader = false;

          this.groups = response.data.groups.data;
          this.aspects = response.data.aspects.data;
          this.regions = response.data.regions.data;
          this.nationalities = response.data.nationalities.data;
          this.referrers = response.data.referrers.data;
          this.countries = response.data.countries.data;
          this.scotlandConnections = response.data.scotlandConnections.data;
          this.expertises = response.data.expertise.data;
          this.activities = response.data.activities.data;
        })
        .catch(error => {
          this.mainLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal"
            }
          );
          this.gotoLink("/home");
        });
    },
    handleFormFieldInput(formName) {
      this[formName].inlineValidationErrors = [];
      this.$refs[formName].fields.forEach(field => {
        if (field.validateState === 'error') {
          let obj = {};
          obj.id = field.labelFor;
          obj.message = field.validateMessage;
          this[formName].inlineValidationErrors.push(obj);
        }
      });
    },
    hasInlineValidationErrorFor(formName, fieldName) {
      return this.getInlineValidationErrorFor(formName, fieldName).length > 0;
    },
    getInlineValidationErrorFor(formName, fieldName) {
      const validationError = this[formName].inlineValidationErrors.find(error => error.id === fieldName);
      if (! validationError) {
        return '';
      }

      return validationError.message;
    },
  },
  created() {
    this.getRegisterMeta();

    if (this.$route.query.email) {
      this.step1Form.form.email = this.$route.query.email
    }
  }
};
</script>
