<template>
  <focus-lock>
    <el-dialog
      ref="enableMultiFactoryAuth"
      :show-close="true"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div class="body">
        <h2 class="mb-5">Google Authenticator</h2>
        <div v-if="step1">
          <p class="mb-4">
            Multi-factor authentication is pending verification. Before we
            enable this method for multifactor authentication on your account,
            you’ll need to install the Google Authenticator app on your
            smartphone or tablet device.
          </p>
          <p class="small mb-4">
            If you already have the Google Authenticator app on your smartphone
            or tablet device, you just need to click the “+” in the lowerright
            corner (iOS) or open the settings for the app and click “Add
            account” (Android). Then proceed to the next section of this
            walkthrough.
          </p>

          <el-row class="mt-4">
            <el-col :xs="24" :sm="12">
              <el-button
                :disabled="codeLoading"
                class="w-100 mx-0 mb-2 mr-1"
                @click="onClose()"
              >
                Cancel
              </el-button>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-button
                :disabled="codeLoading"
                :loading="codeLoading"
                type="primary"
                class="w-100 mb-2 ml-1 mr-0"
                @click="getSarted()"
              >
                Get Started
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div v-if="step2">
          <p>Firstly, using the Google Autheticator app scan this code:</p>
          <div class="text-center py-3" v-html="googleQRCode.svg"></div>
          <div v-if="googleQRCode && googleQRCode.url">
            <p>Or enter this key:</p>
            <p>{{ googleQRCode.url }}</p>
          </div>

          <p>Enter the 6-digit passcode the app gives you here:</p>
          <el-form
            id="googleAuthenticatorForm"
            ref="googleAuthenticatorForm"
            v-loading="codeLoading"
            :model="form"
            autocomplete="false"
            :rules="rules"
            class="mt-0"
            @submit.native.prevent
          >
            <el-form-item
              prop="code"
              class="is-no-asterisk mt-2 mb-0"
              :error="verificationCodeError"
            >
              <template slot="label">
                <slot name="textarea-label"> Verification Code </slot>
                <br />
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!hasInlineValidationErrorFor('code')"
                  v-text="getInlineValidationErrorFor('code')"
                ></span>
              </template>
              <el-input
                id="code"
                v-model="form.code"
                name="verification code"
                placeholder="Verification code"
                :max-length="6"
                aria-required="true"
                @keyup.enter.native="submitActivation()"
              ></el-input>
              <template #error="{ error }">
                <div aria-describedby="code" class="el-form-item__error">{{ error }}</div>
              </template>
            </el-form-item>
            <el-button
              class="mt-3 mb-3 submit-btn mx-auto"
              type="primary"
              @click="submitActivation"
            >
              Activate
            </el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </focus-lock>
</template>
<script>
import gsApi from "@/services/gs.api";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
export default {
  mixins: [inlineValidationErrorsMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      step1: true,
      step2: false,
      googleQRCode: null,
      codeLoading: false,
      form: {
        code: null,
      },
      verificationCodeError: null,
      rules: {
        code: [
          {
            required: true,
            message: "Verification code is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },

  methods: {
    onClose() {
      this.dialogVisible = false;
      this.$emit("closed");
    },
    async getSarted() {
      this.codeLoading = true;
      // Enable the Two Factor Authentication
      const authReponse = await gsApi.mfa
        .twoFactorGoogleAuthentication()
        .catch(() => {
          this.codeLoading = false;
          return false;
        });

      if (!authReponse) return;

      // Generate the QR Code
      const qrResponse = await gsApi.mfa.generateQRCode().catch(() => {
        this.codeLoading = false;
        return false;
      });

      if (!qrResponse) return;

      const secretParam = qrResponse.data.url
        ? new URLSearchParams(new URL(qrResponse.data.url).search)
        : null;

      this.googleQRCode = {
        svg: qrResponse.data.svg,
        url: secretParam ? secretParam.get("secret") : null,
      };

      this.step1 = false;
      this.step2 = true;

      this.codeLoading = false;
    },
    submitActivation() {
      this.$refs.googleAuthenticatorForm.validate(async (valid) => {
        if (valid) {
          this.codeLoading = true;

          const twoFactorConfirmedResponse = await gsApi.mfa
            .confirmedTwoFactorGoogleAuthentication(this.form)
            .catch((error) => {
              this.verificationCodeError =
                error?.response?.data?.errors?.code[0];

              return false;
            });

          if (!twoFactorConfirmedResponse) {
            this.codeLoading = false;
            return;
          }

          this.$emit("refresh-user-multifactor-methods");

          this.verificationCodeError = null;
          // refresh the user data to get all available methods
          this.onClose();

          this.$alert(
            `<i class="el-icon el-icon-circle-check"></i>
                <p class='title mb-5 mt-2'>Multi-factor authentication enabled</p>
                <p class='method-name mb-2' >GOOGLE AUTHENTICATOR</p>
                <p class='message'>Verification has successfully been added to your account</p>
                `,
            "",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "Close",
              showClose: true,
              customClass: "notification-modal message-modal",
            }
          );
        }
      });
    },
  },
};
</script>
