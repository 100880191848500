<template>
  <div class="why-should-title">
    <div>
      <h1>{{whyText.heading}}</h1>
      <p class="why-should-sub-text">{{whyText.sub}}</p>
    </div>
    <div class="why-quote">
      <svg-quote-line></svg-quote-line>
      <svg-quote-open></svg-quote-open>
      <div class="quote-text">
        <p>{{quote.text}}</p>
        <p class="author">{{quote.author}}</p>
      </div>
      <svg-quote-close class="close-quote"></svg-quote-close>
    </div>
  </div>
</template>
<script>
import QuoteLineSvg from "@/components/assets/svg-quote-line";
import QuoteOpenSvg from "@/components/assets/svg-quote-open";
import QuoteCloseSvg from "@/components/assets/svg-quote-close";
export default {
  components: {
    "svg-quote-line": QuoteLineSvg,
    "svg-quote-open": QuoteOpenSvg,
    "svg-quote-close": QuoteCloseSvg
  },
  data() {
    return {
      whyText: {
        heading: "Why should I become a GlobalScot?",
        sub:
          "GlobalScot is an altruistic, values-driven network founded on giving back and helping others.In your role, you’ll be involved with some of Scotland’s most exciting and ambitious companies – acting as mentor, sounding board and advisor to the next generation of Scottish business leaders. It’s an incredibly rewarding challenge"
      },
      quote: {
        text:
          "I take great satisfaction in working with Scottish companies. These are exceptionally talented and professional people who work at the cutting edge of their industries. For me, it’s exciting and a privilege to be a member of their respective teams.",
        author: "Mark Goldschmidt, Partner at Holland & Knight"
      }
    };
  }
};
</script>
<style>
.why-should-title h1 {
  padding-top: 20px;
  font-weight: 500;
  margin: auto;
  color: black;
  font-size: 35px;
  margin-top: 30px;
}
.why-should-sub-text,
.quote-text {
  font-size: 19px;
  margin-top: 30px;
  font-weight: 390;
  line-height: 28px;
}
.author {
  margin-top: 10px;
  color: #af006e;
  max-width: 800px;
}
.why-quote {
  margin: 30px;
  display: grid;
  grid-template-columns: 0.1fr 0.1fr 1.5fr 0.1fr;
}
.close-quote {
  margin-top: 80px;
}
</style>
