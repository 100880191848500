<template>
  <el-col :sm="24" :md="12" :lg="colSize" :xl="colSize" class="project-card-col">
    <el-card class="project-card box-card text-center">
      <div class="project-img">
        <el-image :src="imageForProject" :alt="altText" fit="cover"></el-image>
        <activity-menu-dropdown v-if="isProfileOwner" classes="activity-menu-position" @delete="$emit('deleteActivity', activity.id)" />
        <div v-if="project.type_name == projectTypes.spotlight" class="avatar">
          <user-avatar
            shape="circle"
            :isGlobalScot="project.spotlightUser.is_global_scot"
            :authorExist="!!project.spotlightUser"
            :size="140"
            width="140px"
            :src="avatar"
            :alt="project.spotlightUser ? project.spotlightUser.first_name : '' + 'Profile picture'"
          ></user-avatar>
        </div>
      </div>
      <div class="title-position">
        <h2 class="text-truncate">{{ project.title }}</h2>
        <h3 class="mt-3 color-purple-2 fs-20 fw-500">
          <span v-if="project.challenge_end_at != null || project.has_ended">
            <span v-if="!project.has_ended">Ends</span>
            <span v-else>Ended </span>
            {{ endsAt }}
          </span>
          <span v-else>
            Ongoing
          </span>
        </h3>
      </div>
      <div class="read-more-btn-container">
        <el-button type="primary" class="read-more-btn" @click="readMore(project.id)">View project</el-button>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapState } from "vuex"
import moment from 'moment'
import UserAvatar from "@/components/core/user-avatar";
import ActivityMenuDropdown from "@/components/partials/recent-activity-cards/activity-menu-dropdown.vue";

export default {
  name: "project-card",
  components: {
    UserAvatar,
    'activity-menu-dropdown': ActivityMenuDropdown
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    isProfileOwner: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      projectTypes: {
        project: "project",
        spotlight: "Spotlight",
        feature: "Feature",
        event: "Event",
        casestudy: "Case Study"
      },
      project: this.activity.trackable
    }
  },
  computed: {
    ...mapState(["user"]),
    colSize() {
      return this.isPublic ? 12 : 8
    },
    imageForProject() {
      return this.project.mediaFile && this.project.mediaFile.large_thumb
        ? this.project.mediaFile.large_thumb
        : null
    },
    altText() {
      return (this.project.mediaFile && this.project.mediaFile.alt_attribute) ? this.project.mediaFile.alt_attribute: null
    },
    avatar() {
      return this.project.spotlightUser && this.project.spotlightUser.avatar
        ? this.project.spotlightUser.avatar
        : ""
    },
    endsAt() {
      if (!this.project.has_ended) {
        return moment(this.project.challenge_end_at, "DD/MM/YYYY").fromNow()
      } else {
        if (this.project.member_has_exited_project && this.project.member_exited_project_at) {
          return moment(this.project.member_exited_project_at, "DD/MM/YYYY").format('DD/MM/YYYY')
        } else if (this.project.stopped_at) {
          return moment(this.project.stopped_at, "DD/MM/YYYY").format('DD/MM/YYYY')
        } else {
          return moment(this.project.challenge_end_at, "DD/MM/YYYY").format('DD/MM/YYYY')
        }
      }
    }
  },
  methods: {
    readMore(id) {
      this.$router.push({ name: "Project", params: { id: id } })
    }
  }
}
</script>
