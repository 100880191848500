<template>
  <div
    class="section-companies"
    v-loading="loader"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div class="non-gs-companies" v-if="!loader">
      <h2>Your companies</h2>
      <span>
        Your primary company will be shown in your profile, along with your name
        and location.
      </span>

      <div
        class="company-items"
        v-if="subGroups.length > 0">
        <company-item
          :company="company"
          :groups="groups"
          :countries="countries"
          :sub-groups="subGroups"
          :show-divider="index != businesses.length-1"
          v-for="(company, index) in businesses"
          :key="company.id"
        />
      </div>

      <non-gs-company-form v-if="showAddNewCompanyForm"
      :form="form" :groups="groups" :subGroups="subGroups" @onCancel="cancel" :editMode="false" ></non-gs-company-form>
      <el-button @click="showAddNewCompanyForm=true" v-else >Add a new company</el-button>
      <hr class="my-4">
      <mailing-address :countries="countries"></mailing-address>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import gsApi from '@/services/gs.api'
import MailingAddress from '@/components/platform/edit-profile/non-gs-mailing-address'
import CompanyItem from '@/components/platform/edit-profile/non-gs-companies-company-item'
import NonGsCompanyForm from './non-gs-company-form.vue'

export default {
  name: "non-gs-companies",

  components: {
    CompanyItem,
    MailingAddress,
    NonGsCompanyForm,
  },

  data() {
    return {
      loader: false,
      showAddNewCompanyForm:false,
      groups: [],
      subGroups: [],
      countries: [],
      form:{},
      newCompany: {
          id: null,
          company_house_no:"",
          input_company_house_no:"",
          incorporation_date:"",
          name: '',
          address_1: '',
          address_2: '',
          post_code: '',
          town: '',
          county: '',
          role: '',
          group_ids: [],
          sub_group_ids: [],
          is_mailing_address : false
      },
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'businesses',
    ]),
  },

  methods: {
    loadMeta() {
      this.loader = true

      gsApi.member.meta.index()
        .then(({ data }) => {
          this.groups = data.groups.data
          this.groups.forEach(group => {
            this.subGroups = this.subGroups.concat(group.subGroups.data)
          })
          this.countries = data.countries.data
        }).catch(() => {
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        }).finally(() => {
          this.loader = false
        })
    },
    cancel(){
      this.showAddNewCompanyForm = false;
      this.initForm();
    },
    initForm(){
      this.form = Object.assign({}, this.newCompany);
    }
  },

  created() {
    this.initForm()
    this.loadMeta();
  }
}
</script>
