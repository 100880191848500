<template>
  <div
    :class="{
      'members-avatars-group': true,
      'is-group': members.length > 1,
      'is-big': size > 50
    }"
  >
    <member-avatar
      v-if="members.length > 0"
      :member="members[0]"
      :size="size"
    ></member-avatar>
    <member-avatar
      v-if="members.length > 1"
      :member="members[1]"
      :size="size"
    ></member-avatar>
    <span
      v-if="members.length > 2"
      class="d-inline-block text-center member-count"
    >
      +{{ members.length - 2 }}
    </span>
  </div>
</template>

<script>
import MemberAvatar from "./member-avatar";
import helpers from "@/helpers/index";
export default {
  props: {
    size: {
      type: Number,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  },
  components: {
    "member-avatar": MemberAvatar
  },
  data() {
    return {
      helpers
    };
  }
};
</script>

<style>
.members-avatars-group {
  position: relative;
  width: 40px;
}

.members-avatars-group .member-item-image-wrapper {
  position: absolute;
}

.members-avatars-group
  .members-avatars-group
  .member-item-image-wrapper:first-child {
  left: 0;
}

.is-group {
  width: 40px;
  text-align: center;
}

.is-big {
  text-align: center;
  width: 74px;
}

.is-group .member-item-image-wrapper:first-child {
  left: -10px;
}
.is-group .member-item-image-wrapper:nth-child(2) {
  left: 3px;
}

/*    .members-avatars-group.members-avatars-group-big .member-item-image-wrapper:nth-child(2) {
        left: 37px;
    }*/

.members-avatars-group .member-item-image-wrapper > img {
  margin: 0 !important;
}

.members-avatars-group .member-count {
  position: relative;
  top: 25px;
  padding: 0 10px;
  background: #d9d9d9;
  font-size: 17px;
  font-weight: 500;
  border-radius: 30px;
}

.is-big .member-count {
  top: 57px;
  font-size: 20px;
  padding: 0px 16px;
}
</style>
