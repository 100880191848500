<template>
  <div class="d-flex select-education mb-3">
    <el-input type="text" class="education-input" v-model="inputValue" :disabled="!editMode"></el-input>
    <el-button type="default" @click="editMode = true" v-if="!editMode">Edit</el-button>
    <el-button type="default" @click="editMode = false" v-if="editMode">Save</el-button>
    <el-button type="primary" class="ml-1" @click="removeEducation(index)">Remove</el-button>
  </div>
</template>

<script>

  export default {
    props: ['value', 'index', 'remove'],
    computed: {
      inputValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        }
      }
    },
    data: function () {
      return {
        editMode: false
      }
    },
    methods: {
      removeEducation(index) {
        this.$confirm('Are you sure you want to delete this education item?', 'Remove education', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmButtonClass: 'modal-confirm-button',
          cancelButtonClass: 'modal-cancel-button',
          type: 'warning'
        })
        .then(() => {
          this.remove(index)
        })
      }
    }
  }
</script>
