<template>
  <slide-panel 
    :isMobile="false" 
    :slideRight="true" 
    :closeAsText="false" 
    title="Existing Members"
    class="project-members-slide-panel"
    @panelclose="closeMenu" 
  >
    <el-row
      v-for="(member,index) in members"
      :key="index"
    >
      <el-col class="mt-3">
        <div class="members">
          <member-avatar :member="member" :size="40"></member-avatar>
          <h4 class="ml-3 mt-2">{{ member.fullname }}</h4>
        </div>
      </el-col>
    </el-row>

  </slide-panel>
</template>

<script>
  import _ from 'lodash'
  import store from '@/store'
  import gsApi from '@/services/gs.api'
  import SlidePanel from '@/components/slide-panel/slide-panel'
  import SvgCloseCross from '@/components/assets/svg-close-cross'
  import MemberAvatar from '@/pages/platform/chats/avatars/member-avatar'

  export default {
  	name: 'project-members-panel',

    components: {
  		SlidePanel,
      SvgCloseCross,
      MemberAvatar,
    },

    props: {
      members: {
        type: Array,
        default: []
      }
    },

    data() {
      return {

      }
    },

    computed: {

    },

    methods: {
	    closeMenu() {
	      this.$emit('close')
	    }
  	}
  }
</script>
