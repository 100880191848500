<template>
  <focus-lock>
    <el-dialog
      :show-close="true"
      :visible.sync="dialogVisible"
      custom-class="multi-factor-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose()"
    >
      <div class="body">
        <h2 class="mb-5">How would you like to authenticate ?</h2>
        <div
          v-for="(item, index) in availableMethods"
          :key="index"
          class="w-100 d-flex box-selector p-2 justify-content-center align-items-center"
          :class="{
            'is-first': index == 0,
            'is-last': index == availableMethods.length - 1,
          }"
          @click="$emit('on-select-multi-factor-method', item)"
        >
          <component
            :is="
              item.two_factor_type == enumHelper.MFAType.GOOGLE
                ? 'svg-google-authentication'
                : item.two_factor_type == enumHelper.MFAType.PHONE
                ? 'svg-phone-authentication'
                : 'svg-mail-authentication'
            "
            width="24"
            height="24"
          />
          <p class="w-100 text-left ml-3">
            {{ getLabelText(item) }}
          </p>
          <i v-if="item.is_default" class="el-icon-success text-success"></i>
        </div>
        <el-button class="mt-5 w-50 mx-0 mr-1" @click="onClose()">
          Go Back
        </el-button>
        <p class="my-2 text-left w-100 small">
          <a class="linkified-underline" :href="getCMSRoute('contact-us')"> Need Help? </a>
        </p>
      </div>
    </el-dialog>
  </focus-lock>
</template>

<script>
import SvgGoogleAuthentication from "@/components/assets/svg-google-authentication";
import SvgPhoneAuthentication from "@/components/assets/svg-phone-authentication";
import SvgMailAuthentication from "@/components/assets/svg-mail-authentication";
import enumHelper from "@/helpers/enumHelper";
export default {
  components: {
    SvgGoogleAuthentication,
    SvgPhoneAuthentication,
    SvgMailAuthentication,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    availableMethods: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      enumHelper,
    };
  },
  computed: {
    getLabelText() {
      return (method) => {
        if (method.two_factor_type == enumHelper.MFAType.PHONE) {
          return `Text Message – number ending in ${method.two_factor_method}`;
        }
        if (method.two_factor_type == enumHelper.MFAType.EMAIL) {
          return `Email – address ${method.two_factor_method}`;
        }

        return "Google Authenticator";
      };
    },
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
  },
  created() {
    this.dialogVisible = this.visible;
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
  },
};
</script>
<style lang="scss">
.box-selector {
  border: 1px solid #ccc;
  border-bottom: none;
  cursor: pointer;
  &.is-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &.is-last {
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .el-icon-success {
    font-size: 1.2rem;
  }
}
</style>
