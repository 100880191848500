<template>
    <div
      class="section-notifications-settings"
      v-loading.fullscreen.lock="mainLoader"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <h2>Notifications for email</h2>

      <el-form
        :model="form"
        id="submitForm"
        class="update-notifications-form"
        ref="submitForm"
        @submit.native.prevent
      >
        <h3>Mandatory</h3>

        <el-row>
          <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
            <p>Help Request</p>
            <p class="mt-3">Receive emails about help requests you are invloved in (e.g. new help requests)</p>
          </el-col>
          <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
            <el-checkbox class="float-right" disabled checked>
              <p>
                Email
              </p>
            </el-checkbox>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
            <p>Chat messages</p>
            <p class="mt-3">New chat message received</p>
          </el-col>
          <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
            <el-checkbox class="float-right" disabled checked>
              <p>
                Email
              </p>
            </el-checkbox>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h3 class="mt-4">Optional</h3>

        <div v-for="(notification, index) in notifications" :key="index">
          <el-row>
            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
              <p>{{ notification.title }}</p>
              <p class="mt-3">{{ notification.description }}</p>
            </el-col>

            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="form[notification.key]"
                class="float-right"
                @change="handleCheckboxChange()"
              >
                <p>{{ notification.type }}</p>
              </el-checkbox>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </div>
      </el-form>
    </div>
  </template>
  <script>

  import store from '@/store';
  import gsApi from "@/services/gs.api";
  import { mapGetters } from "vuex";
  
  export default {
    name: "notifications-form",
    metaInfo: {
      title: "Notifications - Settings"
    },
    computed: {
      ...mapGetters(["user", 'isAdmin', 'isStaff']),
      notifications() {
        const selectableNotifications = [
          {
            key:'event_notifications_enabled',
            type:'Email',
            title: 'Events',
            description: 'Receive email notifications about events you are involved with'
          },
          {
            key:'feed_notifications_enabled',
            type:'Email',
            title: 'Noticeboard',
            description: 'Receive emails about generic Noticeboard posts, except where you are specifically tagged'
          }
        ]

        if(this.user.staffProfile) {
          selectableNotifications.push(
            {
              key:'project_notifications_enabled',
              type:'Email',
              title: 'Projects',
              description: 'Receive email notifications about projects you are involved with'
            },
          )
        }

        if(this.user.expertProfile) {
          selectableNotifications.push(
            {
              key:'project_notifications_enabled',
              type:'Email',
              title: 'Projects',
              description: 'Receive email notifications about projects you are involved with'
            },
            {
              key:'weekly_digest_notifications_enabled',
              type:'Email',
              title: 'Weekly Updates',
              description: 'A weekly email containing a digest of your open or pending requests not yet completed, events you are signed up to and relevant community updates'
            },
          )
        }

        return selectableNotifications
      }
    },
    data() {
      return {
        mainLoader: false,
        form: {
          event_notifications_enabled: store.state.user.event_notifications_enabled ? store.state.user.event_notifications_enabled : 0,
          project_notifications_enabled: store.state.user.project_notifications_enabled ? store.state.user.project_notifications_enabled : 0,
          feed_notifications_enabled: store.state.user.feed_notifications_enabled ? store.state.user.feed_notifications_enabled : 0,
          weekly_digest_notifications_enabled: store.state.user.weekly_digest_notifications_enabled ? store.state.user.weekly_digest_notifications_enabled: 0,
        },
      };
    },
    methods: {
      async handleCheckboxChange() {
        this.mainLoader = true;

        const response = await gsApi.users
        .updateNotificationPreferences(this.form)
        .then(() => {
          this.mainLoader = false;
          store.commit('USER_NOTIFICATION_PREFERENCES_UPDATED', this.form);
          this.$alert('You successfully updated your notification preferences', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        })
        .catch((error) => {
          this.mainLoader = false;
          this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
        });
      },
    },
  };
  </script>

  <style lang="scss">
    .section-notifications-settings {
      h3 {
        font-size: 20px;
        font-weight: 420;
        color: black;
        margin-bottom: 35px;
      }

      .update-notifications-form {
        padding-bottom: 30px;
      }

      .is-disabled {
        .el-checkbox__inner {
          opacity: 0.4;
        }
      }
    }
  </style>
  