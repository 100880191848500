<template>
  <el-card
    v-loading.lock="blockToDelete"
    class="mt-4 pulse-post-card"
  >
    <div>
      <el-row class="px-4">
        <el-col
          :sm="3"
          :xl="2"
          :xs="6"
        >
          <el-popover
            popper-class="author-card-popover"
            placement="top-start"
            width="290"
            trigger="hover"
            @show="fetchUserData"
          >
            <div
              v-loading="loadingUser"
              class="author-card-popover-loader"
            >
              <author-card
                v-if="!loadingUser"
                :member="postAuthor"
              ></author-card>
            </div>
            <user-avatar
              slot="reference"
              shape="circle"
              :is-global-scot="post.author && post.author.is_global_scot"
              :author-exist="!!post.author"
              width="45px"
              :size="45"
              :src="post.author_avatar_url"
              :alt="post.author ? post.author.name : '' + 'Profile picture'"
            ></user-avatar>
          </el-popover>
        </el-col>
        <el-col
          :sm="6"
          :xl="7"
          :xs="18"
        >
          <el-popover
            v-if="post.author"
            popper-class="author-card-popover"
            placement="top-start"
            width="290"
            trigger="hover"
            @show="fetchUserData"
          >
            <div
              v-loading="loadingUser"
              class="author-card-popover-loader"
            >
              <author-card
                v-if="postAuthor"
                :member="postAuthor"
              ></author-card>
            </div>
            <p
              slot="reference"
              class="clickable"
            >
              <strong v-if="post.author">{{ post.author.name }}</strong>
            </p>
          </el-popover>
          <span class="pulse-time-ago">{{ post.created_at_time_diff }}</span>
        </el-col>
        <el-col
          :sm="1"
          :xl="1"
          :xs="1"
          class="pull-right"
        >
          <el-dropdown
            class="el-dropdown-hover"
            :class="{ 'el-dropdown-grey-background': listActionShown }"
            @visible-change="getListActionStatus"
          >
            <span class="el-dropdown-link"><span class="sr-only">{{
              post.author && user.id == post.author.id
                ? "manage post menu"
                : "report post menu"
            }}</span><svg-three-dots></svg-three-dots>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              :class="{
                'only-one-child': post.author && user.id != post.author.id,
              }"
            >
              <el-dropdown-item
                v-if="post.author && user.id != post.author.id"
                @click.native="reportPost()"
              >
                Report
              </el-dropdown-item>
              <el-dropdown-item
                v-if="post.author && user.id == post.author.id"
                @click.native="editPost(post)"
              >
                Edit
              </el-dropdown-item>
              <el-dropdown-item
                v-if="user.id == post.author.id"
                @click.native="showProjectPostDeleteConfirmDialog = true"
              >
                Delete
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row class="px-4">
        <el-col :span="24">
          <div
            v-linkified
            class="mt-3 pb-3 content"
            v-html="$options.filters.domPurify(post.content)"
          ></div>
        </el-col>
      </el-row>
      <el-row v-if="post.links && post.links.data.length">
        <el-col>
          <div
            
            class="mt-3 content"
          >
            <a
              v-for="(item, index) in post.links.data"
              :key="index"
              :href="item.url"
              target="_blank"
              :aria-label="item.title"
            >
              <div class="link-preview d-block d-flex flex-column">
                <img
                  v-if="item.image"
                  class="preview-link-image d-block"
                  :src="item.image"
                  :alt="item.title"
                />
                <span
                  class="preview-url-title d-block px-4 mt-2"
                  v-text="item.title ? item.title : item.url"
                ></span>
              </div>
            </a>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="post.mediaFile">
        <el-col :span="24">
          <img
            v-if="post.mediaFile.media_type === 'image'"
            class="w-100 d-block mb-0"
            :src="post.mediaFile.path"
            :alt="post.mediaFile.original_name"
          />
          <video
            v-if="post.mediaFile.media_type === 'video'"
            class="w-100 d-block mb-0"
            :src="post.mediaFile.path"
            controls
          ></video>
        </el-col>
      </el-row>
      <el-row
        v-if="post.documentFiles.data.length"
        class="px-4 mt-3"
      >
        <el-col
          :span="24"
          class="mb-3"
        >
          <!-- content -->
          <div
            v-for="(documentFile, documentIndex) in post.documentFiles.data"
            :key="documentIndex
            "
            v-loading="isDownloading(documentFile.id)"
            class="attached-document clickable d-inline-block px-2 py-2 rounded w-100"
            @click="download(documentFile)"
          >
            <div
              
              class="d-flex align-items-center"
            >
              <el-image
                class="mr-2"
                :class="{'is-svg' : !documentFile.is_image }"
                :src="getDocumentPreview(documentFile)"
              ></el-image>
              <div class="document-info ml-3 w-100">
                <div class="download align-items-center">
                  <a
                    role="button"
                    class="gs-link fs-19"
                  >{{ documentFile.original_name }}</a>
                  <svg-download-file class="ml-2 pull-right"></svg-download-file>
                </div>
                <p>
                  {{ documentFile.extension.toUpperCase() }},
                  {{ getReadableFilesize(documentFile.size) }}
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="px-4">
      <el-row v-if="post.documentFiles.data.length || !post.mediaFile">
        <el-col :span="24">
          <hr class="mt-3" />
        </el-col>
      </el-row>
      <!-- Likes -->
      <el-row class="pt-2 likeable">
        <el-col :span="12">
          <svg-likes-counter></svg-likes-counter>
          <span class="likes-counter">{{
            `${post.likes.likes} Like${post.likes.likes > 1 ? "s" : ""}`
          }}</span>
        </el-col>
        <el-col
          :span="12"
          class="d-flex justify-content-end"
        >
          <el-button
            v-if="!post.likes.has_liked"
            v-loading="likeablePostLoad"
            :disabled="likeablePostLoad"
            size="small"
            class="clickable svg-like like"
            @click="likeablePost('like')"
          >
            <svg-like></svg-like> <small>Like</small>
          </el-button>
          <el-button
            v-else-if="post.likes.has_liked"
            v-loading="likeablePostLoad"
            :disabled="likeablePostLoad"
            type="primary"
            size="small"
            class="clickable svg-like unlike"
            @click="likeablePost('unlike')"
          >
            <svg-unlike />
            <small>Liked</small>
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-if="commentsMeta.pagination"
        class="mt-4"
      >
        <el-col
          class="d-flex"
          :span="12"
        >
          <svg-message-dialog
            class="clickable"
            @click.native="replying = !replying"
          ></svg-message-dialog>
          <span
            class="ml-2 clickable"
            @click="replying = !replying"
          >{{
            commentsMeta.pagination.total | formatRepliesCount
          }}</span>
        </el-col>
        <el-col
          :span="12"
          class="text-right"
        >
          <a
            v-if="!replying"
            class="gs-link clickable fs-19"
            @click="replying = true"
          >
            Reply
            <svg-arrow-right class="ml-2"></svg-arrow-right>
          </a>

          <a
            v-if="replying"
            class="clickable fs-19"
            @click="replying = false"
          >&times;</a>
        </el-col>
        <el-col
          class="mt-2"
          :span="24"
        >
          <pulse-comment-form
            v-if="replying && !post.author.suspended"
            :reply-to-id="post.id"
            :module="'post'"
            :entity="'post'"
            :project-id="projectId"
            @replied="fetchComments"
          ></pulse-comment-form>
          <pulse-comment
            v-for="(comment, index) in comments"
            v-if="replying"
            :key="`${comment.id}-${index}`"
            :allow-mention="true"
            :comment="comment"
            :project-id="projectId"
            @replied="fetchComments"
            @commentDeleted="fetchComments"
            @commentUpdated="fetchComments"
          ></pulse-comment>
        </el-col>
        <el-col
          v-if="replying && nextLink"
          class="mt-4 text-center"
          :span="24"
        >
          <hr class="d-block" />
          <a
            class="fs-19 gs-link mt-3 d-inline-block clickable"
            @click="showMore"
          >Show more</a>
        </el-col>
      </el-row>
    </div>
    <focus-lock>
      <el-dialog
        ref="confirmDeleteDialog"
        :show-close="false"
        custom-class="confirm-dialog"
        :visible.sync="showProjectPostDeleteConfirmDialog"
        title="Warning. Are you sure you want to delete this post?"
        @opened="dialogOpen('confirmDeleteDialog')"
      >
        <el-row>
          <el-col
            :span="24"
            class="text-center"
          >
            <h1 class="mt-0">
              Warning
            </h1>
            <p class="fs-19">
              Are you sure you want to delete this post?
            </p>
          </el-col>
        </el-row>
        <span
          slot="footer"
          class="dialog-footer d-flex justify-content-end"
        >
          <el-button
            type="secondary"
            @click="showPulseDeleteConfirmDialog = false"
          >Cancel</el-button>
          <el-button
            v-loading.lock="blockToDelete"
            type="primary"
            element-loading-background="rgba(255, 255, 255, 0.8)"
            :disabled="blockToDelete"
            @click="deletePost"
          >Yes, delete post</el-button>
        </span>
      </el-dialog>
    </focus-lock>
  </el-card>
</template>
<script>
import UserAvatar from "@/components/core/user-avatar"
import AuthorCard from "@/components/platform/pulse/author-card";
import PulseComment from "@/components/platform/pulse/pulse-comment";
import PulseCommentForm from "@/components/platform/pulse/pulse-comment-form";
import SvgDownloadFile from "@/components/assets/svg-download-file";
import SvgMessageDialog from "@/components/assets/svg-chat-purple";
import SvgArrowRight from "@/components/assets/svg-arrow-right";
import gsApi from "@/services/gs.api";
import _ from "lodash";
import filesize from "filesize";
import helpers from "@/helpers/index";
import {mapGetters} from 'vuex';
import SvgThreeDots from "@/components/assets/svg-three-dots";
import SvgLike from "@/components/assets/svg-like";
import SvgUnlike from "@/components/assets/svg-unlike";
import SvgLikesCounter from "@/components/assets/svg-likes-counter";

export default {
  name: "ProjectPost",
  components: {
    PulseComment,
    PulseCommentForm,
    SvgDownloadFile,
    SvgMessageDialog,
    SvgArrowRight,
    AuthorCard,
    UserAvatar,
    SvgThreeDots,
    SvgLike,
    SvgUnlike,
    SvgLikesCounter
  },
  filters: {
    formatRepliesCount(count) {
      if (parseInt(count) === 1) {
        return count + " reply";
      }

      return count + " replies";
    }
  },
  props: {
    post: {},
    openReplyForPost: false,
    index: null,
    projectPostId: null,
    showComments: false
  },
  data() {
    return {
      helpers,
      comments: [],
      commentsMeta: {},
      replying: false,
      nextLink: null,
      loadingUser: false,
      postAuthor: null,
      listActionShown: false,
      showProjectPostDeleteConfirmDialog: false,
      likeablePostLoad : false,
      blockToDelete: false,
      downloading:[]
    };
  },
  computed: {
    ...mapGetters(["user"]),
    tags() {
      return _.shuffle(
        _.concat(this.post.sectors.data, this.post.expertise.data)
      );
    },
    projectId() {
      return parseInt(this.$route.params.id);
    },
    getDocumentPreview() {
      return(document) => {
        
        return document.is_image && document.medium_thumb ? document.medium_thumb :
          helpers.getFileIcon(document.extension, true)
      }
    },
    isDownloading(){
      return id => this.downloading.includes(id)
    }
  },
  watch: {
    projectPostId: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchComments();
      }
    },
  },
  mounted() {
    this.openReplyForPostIdSelected();
    this.fetchComments();
  },
  methods: {
    getReadableFilesize(sizeInBytes) {
      return filesize(parseInt(sizeInBytes), { base: 10 });
    },
    fetchComments() {
      gsApi.comment
        .index({
          module: "Post",
          entity: "Post",
          id: this.post.id,
          order_by: "commentable__commentables.created_at",
          order: "desc",
          per_page: 10
        })
        .then(response => {
          this.comments = response.data.data;
          this.commentsMeta = response.data.meta;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
          }

          if(this.showComments) {
            this.replying = true
          }
        });
    },
    showMore() {
      if (this.nextLink !== null) {
        gsApi.comment.index({}, this.nextLink).then(response => {
          this.comments = this.comments.concat(response.data.data);
          this.commentsMeta = response.data.meta;
          if (response.data.meta.pagination.links) {
            this.nextLink = response.data.meta.pagination.links.next;
          }
          if (
            response.data.meta.pagination.current_page ===
            response.data.meta.pagination.total_pages
          ) {
            this.nextLink = null;
          }
        });
      }
    },
    openReplyForPostIdSelected() {
      if (this.openReplyForPost === this.post.id) {
        this.replying = true;
        let post = document.getElementById(`post-${this.openReplyForPost}`);
        post.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    fetchUserData() {
      if (this.postAuthor) return;
      this.loadingUser = true;
      gsApi.users
        .entityAuthor(this.post.author.id)
        .then(response => {
          this.postAuthor = response.data;
        })
        .catch(error => {
          if (error.response && error.response.status == 422 && error.response.data) {
            let firstError = error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            this.$alert('Sorry, your request could not be processed', firstError[0], { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          }
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    getListActionStatus(value) {
      this.listActionShown = value;
    },
    editPost(post) {
      this.$emit("editPost", post);
    },
    deletePost() {
      this.blockToDelete = true;

      gsApi.post
        .delete(this.post.id)
        .then((response) => {
          this.$emit("deletePost", this.index);
          this.showProjectPostDeleteConfirmDialog = false;
          this.blockToDelete = false;
          this.$alert("You successfully deleted your post", "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .catch((error) => {
          this.blockToDelete = false;

          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    likeablePost(action) {
      this.likeablePostLoad = true;
      let data = {
        module: "Post",
        entity: "Post",
        id: this.post.id,
      };
      gsApi.likeable[action](data)
        .then((response) => {
          this.post.likes = response.data;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data
          ) {
            let firstError =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ];
            this.$alert(
              "Sorry, your request could not be processed",
              firstError[0],
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          } else {
            this.$alert(
              "An unknown error occurred. Please try again later.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .finally(() => {
          this.likeablePostLoad = false;
        });
    },
    download(file) {

      let self = this;
      this.downloading.push(file.id)
      
      const url = `${process.env.VUE_APP_API_URL.replace(/\/$/, '')}/media/file/download/${file.id}`;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "arraybuffer";
      xhr.setRequestHeader("ApiKey", process.env.VUE_APP_API_KEY);

      if (this.user.isLogged) {
        xhr.setRequestHeader("Authorization", this.user.token);
      }
      
      xhr.send();

      xhr.onload = function() {
        if (this.status === 200) {
          const filename = file.original_name;

          let blob = new Blob([xhr.response], {
            type: xhr.getResponseHeader("content-type")
          });
          const objectUrl = URL.createObjectURL(blob);

          let link = document.createElement("a");
          link.href = objectUrl;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(blob);
        }
        self.downloading = self.downloading.filter(id => id != file.id)
      };
      
      
    },
  }

};
</script>
<style lang="scss">
.author-card-popover {
  &.el-popover {
    min-height: 140px;
    padding: 0;
    min-width: 308px;
  }
  .author-card-popover-loader{
    width: 100%;
    height: fit-content;
    min-height: 140px;
  }
}
</style>
