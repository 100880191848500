<template>
  <div
    v-loading="downloading"
    class="d-flex file align-items-center"
  >
    <div
      v-if="item.svg_icon"
      class="icon"
    >
      <component :is="item.svg_icon"></component>
    </div>
    <div class="name d-flex flex-column">
      <a
        class="pb-1 pb-1 d-flex align-items-center clickable"
        role="button"
        @click="download(item)"
      >{{ item.title }} <svg-download-file class="ml-2"></svg-download-file></a>
      <small>{{
        `${getReadableFilesize(item.filesize)} | Uploaded ${item.uploaded_at}`
      }}</small>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import filesize from "filesize";
import * as icons from "../../assets";
import SvgDownloadFile from "@/components/assets/svg-download-file";

export default {
  name: "FileItem",
  components: {
    SvgImage: icons.SvgImage,
    SvgPdf: icons.SvgPdf,
    SvgExcel: icons.SvgExcel,
    SvgDoc: icons.SvgDoc,
    SvgPpt: icons.SvgPpt,
    SvgAudio: icons.SvgAudio,
    SvgText: icons.SvgText,
    SvgFile: icons.SvgFile,
    SvgVideo: icons.SvgVideo,
    SvgDownloadFile
  },
  props: {
    item: {
      type: Object,
      requied: true,
      default: null
    }
  },
  data() {
    return {
      downloading: false
    };
  },
  computed: {
    ...mapState(["user"]),
    getReadableFilesize() {
      return sizeInBytes => filesize(Number(sizeInBytes), { base: 10 });
    }
  },

  methods: {
    download(item) {
      let self = this;
      this.downloading = true;

      const url = `${process.env.VUE_APP_API_URL.replace(/\/$/, '')}/resources/download/${item.id}`;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "arraybuffer";
      xhr.setRequestHeader("ApiKey", process.env.VUE_APP_API_KEY);

      if (this.user.isLogged) {
        xhr.setRequestHeader("Authorization", this.user.token);
      }
      
      xhr.send();

      xhr.onload = function() {
        if (this.status === 200) {
          const filename = item.original_name;

          let blob = new Blob([xhr.response], {
            type: xhr.getResponseHeader("content-type")
          });
          const objectUrl = URL.createObjectURL(blob);

          let link = document.createElement("a");
          link.href = objectUrl;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(blob);
        }
        self.downloading = false;
      };
      
      xhr.onloadend = function() {
        self.downloading = false;
      };
    },
    createObjectFromUrl(response) {
      return response ? URL.createObjectURL(response) : null;
    }
  }
};
</script>
