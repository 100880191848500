<template>
  <div class="section-experience-expertise" v-loading="mainLoader || metaLoader" element-loading-background="rgba(255, 255, 255, 0.8)">
    <h2 v-if="!metaLoader">Plans and ambitions</h2>
    <validation-errors v-if="validationErrors.length" :errors="validationErrors"></validation-errors>
    <el-form :model="form"
             id="submitForm"
             class="plans-ambitions-form"
             ref="submitForm" :rules="rules"
             @submit.prevent.native="submitForm($event)"
             v-if="!metaLoader"
             @validate="handlePlansAmbitionsFormInput">
      <el-form-item prop="ambitions" class="is-no-asterisk form-group ambitions w-62-5">
        <span class="characters-length">You have {{5000 - (form.ambitions.length)}} characters remaining</span>
        <template slot="label">
          <span aria-hidden="true">Tell us a bit more about what your company (or companies) does and what your ambitions are.</span><br/>
          <span class="sr-only">Please enter an ambitions to help us and other users get to know you in this field</span>
          <span class="el-form-item__info experience-header d-block">For example, you could tell us about your product or service, company size, achievements, and what you want to do next. </span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('ambitions')"
                v-text="this.getInlineValidationErrorFor('ambitions')"></span>
        </template>
        <el-input
          v-model="form.ambitions"
          placeholder="Ambitions"
          type="textarea"
          :rows="5"
          maxlength="5000"
          id="ambitions"
          label="Please enter a bit more about what your company or companies does and what your ambitions are in this field"
          aria-required="true" />
      </el-form-item>
      <el-form-item prop="aspects" class="w-62-5">
        <template slot="label">
          <span aria-hidden="true">What aspect(s) of business do you need help with?</span>
          <span class="sr-only">Please select one or more aspect(s) of your business</span>
          <span class="sr-only"
                aria-live="polite"
                :hidden="! this.hasInlineValidationErrorFor('aspects')"
                v-text="this.getInlineValidationErrorFor('aspects')"></span>
        </template>
        <v-select
          v-model="form.aspects"
          :options="aspects"
          :multiple="true"
          :clearable="false"
          label="slug"
          :reduce="aspect => aspect.id"
          inputId="aspects"
          autocomplete="false"
          :select-on-tab="true"
          :close-on-select="false" />
      </el-form-item>
      <el-form-item prop="regions" class="regions w-62-5 is-no-asterisk">
      <template slot="label">
        <span aria-hidden="true">Which region (or regions) would you like to expand into? - Optional</span><br>
        <span class="sr-only">Please select one or more regions</span>
        <span class="el-form-item__info d-block">This will help us match you to GlobalScots in your target areas. You can change these at any time.</span>
        <span class="sr-only"
              aria-live="polite"
              :hidden="! this.hasInlineValidationErrorFor('regions')"
              v-text="this.getInlineValidationErrorFor('regions')"></span>
      </template>
      <v-select
        v-model="form.regions"
        :options="regions"
        :multiple="true"
        :clearable="false"
        label="slug"
        :reduce="region => region.id"
        inputId="regions"
        autocomplete="false"
        :select-on-tab="true"
        :close-on-select="false"
        :selectable="() => form.regions.length < 5" />
      </el-form-item>
      <div class="step-hr mb-4"></div>
      <div class="d-flex form-actions">
        <el-button
          class="el-button btn-cancel"
          @click="$router.push({name: 'NonGlobalScot Profile', params: {id: user.id}})">
          Cancel
        </el-button>
        <el-button
          type="primary"
          class="btn-submit"
          native-type="submit">
          Save changes
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  import gsApi from '@/services/gs.api'
  import validationErrors from '@/components/partials/validation-errors'
  import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'

  export default {
    mixins: [inlineValidationErrorsMixin],
    name: "plans-ambitions",

    metaInfo: {
      title: "Plans and ambitions - Edit profile",
    },

    components: {
      validationErrors
    },

    computed: {
      ...mapState(['user']),
    },

    data() {
      return {
        validationErrors: [],
        mainLoader: false,
        metaLoader: true,
        aspects: [],
        regions: [],
        form: {
          ambitions: this.$store.state.user.traderProfile && this.$store.state.user.traderProfile.ambitions ? this.$store.state.user.traderProfile.ambitions : '',
          aspects: [],
          regions: []
        },
        rules: {
          ambitions: [
            { required: true, message: 'Ambitions is required', trigger: 'blur' },
            { max: 5000, message: 'Ambitions may not be greater than 5000 characters', trigger: 'change' },
          ],
          aspects: [
            { type: 'array', required: true, message: 'Select at least one aspect', trigger: 'change' }
          ],
        }
      }
    },
    created() {
      if(this.$store.state.user.isLogged){
        this.getRegisterMeta();
        this.fetchCurrentAspects();
        this.fetchCurrentRegions();
      }
    },
    methods: {
      submitForm() {
        this.$refs['submitForm'].validate((valid) => {
          if (valid) {
            const data = {};

            data.ambitions = this.form.ambitions;
            data.aspects = this.form.aspects;
            data.regions = this.form.regions;

            this.mainLoader = true

            gsApi.member.user.updatePlansAmbitions(data)
              .then(response => {
                this.$store.commit('UPDATE_MEMBER_PLANS_AMBITIONS', response.data);
                this.mainLoader = false;
                this.validationErrors = [];
                this.$alert('You successfully updated your profile', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
              })
              .catch(error => {
                this.validationErrors = [];
                this.mainLoader = false

                if (error.response && error.response.status == 422 && error.response.data) {
                  let errors = error.response.data.errors;
                  for (let [key, value] of Object.entries(errors)) {
                    let obj = {}
                    obj.id = key;
                    obj.message = value[0];
                    this.validationErrors.push(obj);
                  }
                  window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                  this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
                }
              })
          } else {
            this.validationErrors = [];
            this.$refs.submitForm.fields.forEach(field => {
              if(field.validateState == 'error') {
                let obj = {}
                obj.id = field.labelFor;
                obj.message = field.validateMessage;
                this.validationErrors.push(obj);
              }
            });
            this.$nextTick(() => {
              document.getElementById('formErrorBlock').scrollIntoView({ behavior: 'smooth', block: 'start' })
              let text = document.getElementById("submitForm").getElementsByClassName('el-form-item__error')[0].textContent
              this.$announcer.set(text)
            })
          }
        })
      },
      getRegisterMeta() {
        gsApi.globalscot.meta.index()
          .then(response => {
            this.metaLoader = false
            this.regions = response.data.regions.data
            this.aspects = response.data.aspects.data
          }).catch(() => {
            this.metaLoader = false
            this.$alert('An unknown error occurred. Please try again later.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          })
      },
      fetchCurrentRegions() {
        let user = this.$store.state.user;

        if(user.traderProfile.regions.data) {
          user.traderProfile.regions.data.forEach(item => {
            this.form.regions.push(item.id)
          })
        }
      },
      fetchCurrentAspects() {
        let user = this.$store.state.user;

        if(user.traderProfile.aspects.data) {
          user.traderProfile.aspects.data.forEach(item => {
            this.form.aspects.push(item.id)
          })
        }
      },
      handlePlansAmbitionsFormInput() {
        this.handleSingleFormFieldInput('submitForm');
      }
    }
  }
</script>
