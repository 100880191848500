<template>
  <svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 61.356H62.698"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7311 61.3571V53.3091H3.68311V61.3571"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.8251 61.357V42.579H19.7781V61.357"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.92 61.357V31.849H35.8721V61.357"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.016 61.357V21.119H51.968V61.357"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.70605 34.5311L55.9911 2.34106"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.65 13.071L55.991 2.341L45.261 1"
      stroke="#AF006E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template><script>
export default {};
</script>
