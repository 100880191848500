<!-- About Component -->
<template>
  <div class="about-page about-network-page">
    <div>
      <el-row :gutter="0" class="m-0">
        <top-navigation></top-navigation>
        <el-col :span="24" class="article-featured px-0">
          <div class>
            <div class="about-page__header small-header">
              <div class="header__content__bg"></div>
              <div class="header__image__bg about-network-bg"></div>
              <div class="header__content container">
                <el-row>
                  <el-col :md="24" :lg="12">
                    <div class="header__content__wrapper">
                      <h1 id="main-content" class="header__title">{{text.headingText}}</h1>
                      <h2 class="header__content-info">{{text.headingSub}}</h2>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div>
        <who-can-join></who-can-join>
      </div>
      <div>
        <how-does-it-work></how-does-it-work>
      </div>
      <div>
        <who-is-in-the-network :members="users"></who-is-in-the-network>
      </div>
<!--       <div>
        <how-others-benefit></how-others-benefit>
      </div> -->
    </div>
  </div>
</template>

<script>
import gsApi from "@/services/gs.api";
import TopNavigation from "../../layout/top-navigation";
import HowOthersBenefit from "../../pages/landing/about/how-others-benefit";
import HowDoesItWork from "../../pages/landing/about/how-does-it-work";
import WhoIsInTheNetwork from "../../pages/landing/about/who-is-in-the-network";
import WhoCanJoin from "../../pages/landing/about/who-can-join";
import { mapState } from "vuex";
export default {
  name: "about-network",
  metaInfo: {
    title: "About the GlobalScot network",
    meta: [{
      name: 'description',
      content: "The GlobalScot network allows Scottish businesses, entrepreneurs and professionals to connect with business leaders around the world."
    }]
  },
  computed: {
    ...mapState(["user"])
  },
  components: {
    TopNavigation,
    HowOthersBenefit,
    WhoIsInTheNetwork,
    WhoCanJoin,
    HowDoesItWork
  },
  data() {
    return {
      text: {
        headingText: "About the network",
        headingSub:
          "The GlobalScot network celebrates its 20th anniversary in 2021 and is even more relevant today than ever. The network offers a place for Scottish businesses, entrepreneurs and professionals to connect with some of the highly valuable connections and friends Scotland has around the world.",
      },
      users: []
    };
  },
  created() {
    this.getPublicInfo();
  },
  methods: {
    getPublicInfo() {
      gsApi.users
        .public()
        .then(response => {
          this.users = response.data.users.data.slice(0, 4);

          this.mainLoader = false;
        })
        .catch(error => {
          this.mainLoader = false;
        });
    }
  }
};
</script>
