<template>
	<div class="col-md-24 col-lg-20 mb-4">
	  <div class="address-details se-lead-paragraph-l">
	    <span class="color-primary">
	      {{ education.name }}
	    </span>
	  </div>

	  <div class="d-flex edit-company-actions">
	    <el-button
	      @click="educationEditToggle()"
	      type="primary">
	      <span v-if="!editMode">Edit details</span>
	      <span v-else>Save details</span>
	    </el-button>
	    <el-button @click="removeFromEducations()">
	      <span v-if="!editMode">Remove</span>
	      <span v-else>Cancel</span>
	    </el-button>
	  </div>

	  <el-form :model="editableEducation"
             :rules="rules"
             ref="editEducationForm"
             v-show="editMode"
             id="editEducationForm"
             @submit.native.prevent
             @validate="handleEditEducationFormFieldInput">
	    <div class="address-details mb-3 mt-4">
	      <div class="row">
	        <div class="col-md-24 col-lg-20">
	          <el-form-item prop="name">
              <template slot="label">
                Name of university/college
                <span class="sr-only"
                      aria-live="polite"
                      :hidden="! this.hasInlineValidationErrorFor('name')"
                      v-text="this.getInlineValidationErrorFor('name')"></span>
              </template>
	            <el-input
                v-model.trim="editableEducation.name"
                id="name"
                maxlength="255"
                autocomplete="false"
                label="Please enter a university or college name in this field"
                aria-required="true" />
	          </el-form-item>
	        </div>
	      </div>
	    </div>
	  </el-form>
	</div>
</template>

<script>
import inlineValidationErrorsMixin from '@/mixins/inlineValidationErrorsMixin'
export default {
  name: 'manage-education',
  mixins: [inlineValidationErrorsMixin],
  data () {
    return {
      editMode: false,
      editableEducation: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: 'Name is required', trigger: 'blur' },
          { max: 255, message: 'Name may not be greater than 255 characters', trigger: 'change' },
        ],
      },
    }
  },
  props: {
    education: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    educationEditToggle () {
      if (this.editMode) {
        this.$refs['editEducationForm'].validate((valid) => {
          if (valid) {
            this.editMode = false
            this.$emit('update-education', this.editableEducation)
            this.$alert('Education has been updated.', 'Success', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
          } else {
            this.$nextTick(() => {
              document.getElementById("editEducationForm").getElementsByClassName('is-error')[0].scrollIntoView()
              this.$alert('There are errors in the form, please fix these errors and try again.', 'Error', { confirmButtonText: 'Close', showClose: false, customClass: 'notification-modal' });
            })
          }
        });
      } else {
        this.editMode = true
      }
    },
    setEditableEducation () {
      this.editableEducation.name = this.education.name
    },
    removeFromEducations() {
      if (this.editMode) {
        this.setEditableEducation()
      } else {
        this.$emit('remove-education', this.editableEducation)
      }

      this.editMode = false
    },
    handleEditEducationFormFieldInput() {
      this.handleSingleFormFieldInput('editEducationForm');
    },
  },
  created () {
    this.setEditableEducation()
  }
}
</script>
