<template>
  <div
    class="gs-personal-details-container"
    v-loading="mainLoader"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div class="personal-details" v-if="!mainLoader">
      <h2>Personal details</h2>
      <el-form
        id="submitForm"
        :model="form"
        :rules="rules"
        ref="submitForm"
        @submit.prevent.native="submitGsForm"
        @validate="handleSubmitFormFieldInput"
      >
        <el-row>
          <el-col :sm="24">
            <el-form-item
              prop="image"
              v-loading="removeAvatarLoader"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            >
              <div class="d-flex upload-photo" v-if="currentAvatar">
                <el-avatar
                  v-if="currentAvatar"
                  shape="circle"
                  :size="140"
                  :src="currentAvatar"
                  alt="Profile picture"
                ></el-avatar>

                <el-upload
                  action=""
                  ref="upload"
                  :multiple="false"
                  list-type="picture-card"
                  class="selected-upload"
                  :auto-upload="false"
                  :on-change="onFileChange"
                  :disabled="avatarLoader"
                >
                  <div slot="default">
                    <el-button class="replace-button">{{
                      customAvatar ? "Replace" : "Browse files"
                    }}</el-button>
                  </div>

                  <div slot="file" slot-scope="{ file }"></div>
                </el-upload>
                <span
                  class="primary-text-underline"
                  @click="removeSelectedAvatar"
                  v-if="customAvatar"
                  >Remove</span
                >
              </div>
              <span class="upload-photo-text"
                >Upload a profile photo - Optional</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="avatarLoader">
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <el-progress :percentage="percentage" color="#AF006E"></el-progress>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col :xl="12" :lg="12" :md="18" :sm="18">
            <validation-errors
              v-if="validationErrors.length"
              :errors="validationErrors"
            ></validation-errors>
            <el-form-item prop="title" class="is-no-asterisk form-group title">
              <template slot="label">
                <span aria-hidden="true"
                  >Title<span class="is-custom-asterisk">*</span></span
                ><br />
                <span class="sr-only">Please select a title</span>
                <span class="el-form-item__info"
                  >This won't be publicly displayed on your profile</span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('title')"
                  v-text="this.getInlineValidationErrorFor('title')"
                ></span>
              </template>
              <v-select
                id="title"
                v-model="form.title"
                placeholder="Please select"
                :options="titles"
                :searchable="false"
                :clearable="false"
                :reduce="(title) => title.id"
                inputId="title"
                class="half-content"
                autocomplete="false"
              >
              </v-select>
            </el-form-item>
            <el-form-item prop="first_name" class="form-group first-name">
              <template slot="label">
                First name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('first_name')"
                  v-text="this.getInlineValidationErrorFor('first_name')"
                ></span>
              </template>
              <el-input
                v-model="form.first_name"
                @blur="form.first_name = form.first_name.trim()"
                placeholder="Your first name"
                id="first_name"
                maxlength="64"
                auto-complete="no"
                label="Please enter your first name in this field"
                aria-required="true"
              />
            </el-form-item>
            <el-form-item prop="last_name" class="form-group last-name">
              <template slot="label">
                Last name
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('last_name')"
                  v-text="this.getInlineValidationErrorFor('last_name')"
                ></span>
              </template>
              <el-input
                v-model="form.last_name"
                @blur="form.last_name = form.last_name.trim()"
                placeholder="Your last name"
                id="last_name"
                maxlength="64"
                auto-complete="no"
                label="Please enter your last name in this field"
                aria-required="true"
              />
            </el-form-item>
            <el-form-item
              prop="contact_number"
              class="is-no-asterisk form-group contact-number"
            >
              <template slot="label">
                Contact number<span class="is-custom-asterisk">*</span><br />
                <span class="el-form-item__info"
                  >This won't be publicly displayed on your profile</span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('contact_number')"
                  v-text="this.getInlineValidationErrorFor('contact_number')"
                ></span>
              </template>
              <el-input
                v-model="form.contact_number"
                @blur="form.contact_number = form.contact_number.trim()"
                placeholder="Contact number"
                id="contact_number"
                maxlength="20"
                auto-complete="no"
                label="Please enter your contact number in this field"
                aria-required="true"
              />
            </el-form-item>
            <el-form-item
              prop="nationality_id"
              class="is-no-asterisk form-group"
            >
              <template slot="label">
                <span aria-hidden="true"
                  >Which nationality would you describe yourself as?<span
                    class="is-custom-asterisk"
                    >*</span
                  ></span
                ><br />
                <span class="sr-only">Please select a nationality</span>
                <span class="el-form-item__info"
                  >This won’t be publicly displayed on your profile.</span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('nationality_id')"
                  v-text="this.getInlineValidationErrorFor('nationality_id')"
                ></span>
              </template>
              <v-select
                v-model="form.nationality_id"
                placeholder="Please select"
                :options="nationalities"
                :searchable="true"
                :clearable="false"
                :reduce="(nationality) => nationality.id"
                label="name"
                inputId="nationality_id"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col :xl="12" :lg="12" :md="18" :sm="18">
            <span class="group-header"
              >Where are you primarily based for work?</span
            >
            <el-form-item prop="country_id" class="is-no-asterisk form-group">
              <template slot="label">
                <span aria-hidden="true"
                  >Select a country<span class="is-custom-asterisk"
                    >*</span
                  ></span
                >
                <span class="sr-only">Please select a country</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('country_id')"
                  v-text="this.getInlineValidationErrorFor('country_id')"
                ></span>
              </template>
              <v-select
                v-model="form.country_id"
                placeholder="Please select"
                :options="countries"
                :searchable="true"
                :clearable="false"
                :reduce="(country) => country.id"
                label="name"
                inputId="country_id"
                @input="getStatesFromCountry"
                autocomplete="false"
              />
            </el-form-item>
            <el-form-item prop="state_id" class="is-no-asterisk form-group">
              <template slot="label">
                <span aria-hidden="true"
                  >Select a City/State<span class="is-custom-asterisk"
                    >*</span
                  ></span
                >
                <span class="sr-only"
                  >Please select city or state in this field</span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('state_id')"
                  v-text="this.getInlineValidationErrorFor('state_id')"
                ></span>
              </template>
              <v-select
                v-model="form.state_id"
                placeholder="Please select"
                :options="states"
                :searchable="true"
                :clearable="false"
                :reduce="(state) => state.id"
                label="name"
                inputId="state_id"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row
          v-loading.fullscreen.lock="addressesLoading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <el-col :xl="12" :lg="12" :md="18" :sm="18">
            <span class="group-header">Preferred mailing address</span>
            <span class="address-visible"
              >This won’t be publicly displayed on your profile.</span
            >
            <el-form-item
              label="Post code/Zip code"
              class="mt-4"
              prop="mailing_post_code"
            >
              <div class="d-flex">
                <el-input
                  class="half-flex-content"
                  v-model="form.address.post_code"
                  @blur="form.address.post_code = form.address.post_code.trim()"
                  maxlength="30"
                  id="mailing_post_code"
                  autocomplete="false"
                  label="Please enter mailing post code in this field"
                  aria-required="true"
                  @input="disableAddressLookup = false"
                  @keypress.enter.native="onFindAddress($event)"
                />

                <div class="mr-3"></div>

                <el-form-item class="half-flex-content">
                  <el-button
                    size="medium"
                    @click="onFindAddress($event)"
                    :disabled="
                      disableAddressLookup ||
                      form.address.post_code.trim().length == 0
                    "
                    >Find address</el-button
                  >
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item v-if="availableMailingAddresses.length > 0">
              <template slot="label">
                <span aria-hidden="true">Addresses Found</span>
                <span class="sr-only">Please select your address</span>
              </template>
              <v-select
                v-model="selectedAvailableMailingAddress"
                placeholder="Please select"
                :options="availableMailingAddresses"
                :searchable="true"
                :clearable="false"
                label="Text"
                inputId="available_mailing_addresses"
                autocomplete="false"
                @input="onAvailableMailingAddressSelected($event)"
              />
            </el-form-item>
            <el-form-item prop="address.address_1">
              <template slot="label">
                Address line 1
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="
                    !this.hasInlineValidationErrorFor('address.address_1')
                  "
                  v-text="this.getInlineValidationErrorFor('address.address_1')"
                ></span>
              </template>
              <el-input
                v-model="form.address.address_1"
                @blur="form.address.address_1 = form.address.address_1.trim()"
                id="address.address_1"
                maxlength="255"
                autocomplete="false"
                label="Please enter mailing address line 1 in this field"
                aria-required="true"
              />
            </el-form-item>
            <el-form-item prop="address.address_2">
              <template slot="label">
                Address line 2 - Optional
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="
                    !this.hasInlineValidationErrorFor('address.address_2')
                  "
                  v-text="this.getInlineValidationErrorFor('address.address_2')"
                ></span>
              </template>
              <el-input
                v-model="form.address.address_2"
                @blur="form.address.address_2 = form.address.address_2.trim()"
                id="mailing_address_2"
                maxlength="255"
                autocomplete="false"
                label="Please enter mailing address line 2 in this field"
              />
            </el-form-item>
            <el-form-item prop="address.town">
              <template slot="label">
                Town, City or State
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('address.town')"
                  v-text="this.getInlineValidationErrorFor('address.town')"
                ></span>
              </template>
              <el-input
                v-model="form.address.town"
                @blur="form.address.town = form.address.town.trim()"
                id="address.town"
                maxlength="100"
                autocomplete="false"
                label="Please enter mailing town, city or state in this field"
                aria-required="true"
              />
            </el-form-item>
            <el-form-item prop="address.county">
              <template slot="label">
                Region - Optional
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('address.county')"
                  v-text="this.getInlineValidationErrorFor('address.county')"
                ></span>
              </template>
              <el-input
                v-model="form.address.county"
                @blur="form.address.county = form.address.county.trim()"
                id="address.county"
                maxlength="100"
                autocomplete="false"
                label="Please enter mailing region in this field"
              />
            </el-form-item>
            <el-form-item prop="address.country_id">
              <template slot="label">
                <span aria-hidden="true">Country</span>
                <span class="sr-only">Please select a country</span>
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="
                    !this.hasInlineValidationErrorFor('address.country_id')
                  "
                  v-text="
                    this.getInlineValidationErrorFor('address.country_id')
                  "
                ></span>
              </template>
              <v-select
                v-model="form.address.country_id"
                placeholder="Please select"
                :options="countries"
                :searchable="true"
                :clearable="false"
                :reduce="(country) => country.id"
                label="name"
                inputId="address.country_id"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>

        <el-row>
          <el-col :xl="16" :lg="16" :md="24" :sm="24">
            <el-form-item
              prop="profile_summary"
              class="is-no-asterisk form-group profile_summary"
            >
              <span class="characters-length"
                >You have {{ 280 - form.profile_summary?.length }} characters
                remaining</span
              >
              <template slot="label">
                <span aria-hidden="true"
                  >Summarise your profile<span class="is-custom-asterisk"
                    >*</span
                  ></span
                ><br />
                <span class="sr-only"
                  >Please enter a short one-sentence headline about what kind of
                  expertise your offer in this field</span
                >
                <span class="el-form-item__info experience-header d-block"
                  >We’re looking for a short, one-sentence headline about what
                  kind of expertise you offer.
                  <a class="text__link d-block" @click="openExampleSummaryModal"
                    >See example</a
                  ></span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('profile_summary')"
                  v-text="this.getInlineValidationErrorFor('profile_summary')"
                ></span>
              </template>
              <el-input
                v-model="form.profile_summary"
                type="textarea"
                id="profile_summary"
                aria-required="true"
                auto-complete="no"
                :rows="5"
                maxlength="280"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col :xl="16" :lg="16" :md="24" :sm="24">
            <el-form-item
              prop="experience"
              class="is-no-asterisk form-group experience"
            >
              <template slot="label">
                <span aria-hidden="true"
                  >Tell us a bit more about yourself and your business
                  experience.<span class="is-custom-asterisk">*</span></span
                ><br />
                <span class="sr-only"
                  >Please enter a short bio to help us and other users get to
                  know you in this field</span
                >
                <span class="el-form-item__info experience-header d-block"
                  >We’re looking for a short bio to help us and other users get
                  to know you. You could talk about your career history, why you
                  got into your line of work or career highlights. You can also
                  include personal details.
                  <a class="text__link d-block" @click="openExampleModal"
                    >See example</a
                  ></span
                >
                <span
                  class="sr-only"
                  aria-live="polite"
                  :hidden="!this.hasInlineValidationErrorFor('experience')"
                  v-text="this.getInlineValidationErrorFor('experience')"
                ></span>
              </template>
              <experience-editor v-model="form.experience" :max-length="5000" />
              <!-- <el-input v-model="form.experience"
                        type="textarea"
                        id="experience"
                        aria-required="true"
                        auto-complete="no"
                        :rows="5"
                        maxlength="5000">
              </el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-row>
          <el-col :xl="16" :lg="16" :md="24" :sm="24">
            <el-form-item
              label="Which university or college did you attend?"
              prop="educations"
              class="education"
            >
              <div class="d-flex">
                <el-input
                  v-model="form.educations_text"
                  @blur="form.educations_text = form.educations_text.trim()"
                  placeholder=""
                  @keypress.enter.native="addEducation($event)"
                  id="educations"
                  class="w-80"
                  auto-complete="no"
                >
                </el-input>
                <el-button
                  type="default"
                  class="add-another"
                  @click="addEducation($event)"
                  >Add another</el-button
                >
              </div>
              <span class="el-form-item__info"
                >Name of university/college - Optional</span
              >
            </el-form-item>
            <div>
              <education-item
                :key="i"
                v-for="(education, i) in form.educations"
                v-model="education.name"
                :index="i"
                :remove="removeEducation"
              />
            </div>
          </el-col>
        </el-row>
        <div class="step-hr mb-4"></div>
        <el-form-item
          id="is_russian_self_declared"
          prop="is_russian_self_declared"
        >
          <span
            class="sr-only"
            aria-live="polite"
            :hidden="
              !this.hasInlineValidationErrorFor('is_russian_self_declared')
            "
            v-text="
              this.getInlineValidationErrorFor('is_russian_self_declared')
            "
          ></span>

          <el-checkbox-group
            v-model="form.is_russian_self_declared"
            v-for="(russianSelfDeclaration, index) in russianSelfDeclarations"
            :key="index"
          >
            <el-checkbox
              class="pb-3"
              :name="russianSelfDeclaration.key"
              :label="russianSelfDeclaration.key"
            >
              {{ russianSelfDeclaration.text }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <div class="step-hr mb-4"></div>
        <div class="d-flex form-actions">
          <el-button
            class="btn-cancel"
            @click="
              $router.push({
                name: 'GlobalScot Profile',
                params: { id: user.id },
              })
            "
          >
            Cancel
          </el-button>
          <el-button type="primary" native-type="submit" class="btn-submit">
            Save changes
          </el-button>
        </div>
      </el-form>
    </div>

    <cropper-upload
      v-if="cropperFile"
      :visible="showCropperDialog"
      :cropperFile="cropperFile"
      @upload="onCropperUpload"
      @close="onCropperDialogClose"
    ></cropper-upload>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import gsApi from "@/services/gs.api";
import loqateApi from "@/services/loqate.api";
import CropperUpload from "@/components/cropper-upload/cropper-upload";
import educationItem from "@/pages/platform/edit-profile/education-item";
import ExperienceEditor from "@/components/common/ExperienceEditor";
import validationErrors from "@/components/partials/validation-errors";
import inlineValidationErrorsMixin from "@/mixins/inlineValidationErrorsMixin";
import helpers from '@/helpers/index'
export default {
  mixins: [inlineValidationErrorsMixin],
  name: "personal-details",

  components: {
    educationItem,
    validationErrors,
    ExperienceEditor,
    "cropper-upload": CropperUpload,
  },

  computed: {
    ...mapState(["user"]),
  },
  data() {
    const validateContactNumber = (rule, value, callback) => {
      let reg =
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

      let length = value.length;

      if (value === "") {
        callback(new Error("Contact number is required"));
      } else if (length < 7) {
        callback(new Error("Contact number may not be less than 7 characters"));
      } else if (length > 20) {
        callback(
          new Error("Contact number may not be greater than 20 characters")
        );
      } else if (reg.test(value) == false) {
        callback(new Error("Contact number field must be valid"));
      } else {
        callback();
      }
    };
    const validateAgreement = (rule, value, callback) => {
      if (value.length < 2) {
        callback(new Error("You cannot continue without checking all boxes"));
      } else {
        callback();
      }
    };

    return {
      characterCounter: 0,
      percentage: 1,
      cropperFile: null,
      showCropperDialog: false,
      validationErrors: [],
      mainLoader: false,
      avatarLoader: false,
      removeAvatarLoader: false,
      currentAvatar: store.state.user.avatar ? store.state.user.avatar : "",
      customAvatar: store.state.user.hasCustomAvatar,
      nationalities: [],
      countries: [],
      states: [],
      form: {
        title: store.state.user.title ? store.state.user.title : null,
        first_name: store.state.user.firstName
          ? store.state.user.firstName
          : "",
        last_name: store.state.user.lastName ? store.state.user.lastName : "",
        avatar: null,
        contact_number: store.state.user.expertProfile
          ? store.state.user.expertProfile.contact_number
          : null,
        nationality_id: null,
        country_id: null,
        state_id: null,
        experience: store.state.user.expertProfile.experience ?? "",
        profile_summary: store.state.user.expertProfile.profile_summary ?? "",
        educations: store.state.user.expertProfile
          ? JSON.parse(
              JSON.stringify(store.state.user.expertProfile.educations.data)
            )
          : "",
        educations_text: "",
        address: {
          post_code: store.state.user.address.post_code
            ? store.state.user.address.post_code
            : "",
          address_1: store.state.user.address.address_1
            ? store.state.user.address.address_1
            : "",
          address_2: store.state.user.address.address_2
            ? store.state.user.address.address_2
            : "",
          town: store.state.user.address.town
            ? store.state.user.address.town
            : "",
          county: store.state.user.address.county
            ? store.state.user.address.county
            : "",
          country_id: store.state.user.address.country
            ? store.state.user.address.country.id
            : null,
        },
        is_russian_self_declared: [],
      },

      rules: {
        title: [
          {
            required: true,
            message: "Please select a title",
            trigger: "change",
          },
        ],
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
          {
            max: 64,
            message: "First name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last name is required", trigger: "blur" },
          {
            max: 64,
            message: "Last name may not be greater than 64 characters",
            trigger: "blur",
          },
        ],
        contact_number: [{ validator: validateContactNumber, required: true }],
        nationality_id: [
          {
            required: true,
            message: "Nationality is required",
            trigger: "change",
          },
        ],
        country_id: [
          { required: true, message: "Country is required", trigger: "change" },
        ],
        state_id: [
          { required: true, message: "State is required", trigger: "change" },
        ],
        experience: [
          {
            required: true,
            message: "Business Experience is required",
            trigger: "blur",
          },
          {
            max: 5000,
            message:
              "Business Experience may not be greater than 5000 characters",
            trigger: "change",
          },
        ],
        profile_summary: [
          {
            required: true,
            message: "Profile summary is required",
            trigger: "blur",
          },
          {
            max: 5000,
            message: "Profile Summary may not be greater than 280 characters",
            trigger: "change",
          },
        ],
        address: {
          post_code: [
            {
              required: true,
              message: "Post code/Zip code is required",
              trigger: "blur",
            },
            {
              max: 30,
              message:
                "Post code/Zip code may not be greater than 30 characters",
              trigger: "blur",
            },
          ],
          address_1: [
            {
              required: true,
              message: "Address line 1 is required",
              trigger: "blur",
            },
            {
              max: 255,
              message: "Address line 1 may not be greater than 255 characters",
              trigger: "blur",
            },
          ],
          address_2: [
            {
              max: 255,
              message: "Address line 2 may not be greater than 255 characters",
              trigger: "blur",
            },
          ],
          town: [
            {
              required: true,
              message: "Town, City or State is required",
              trigger: "blur",
            },
            {
              max: 100,
              message:
                "Town, City or State may not be greater than 100 characters",
              trigger: "blur",
            },
          ],
          county: [
            {
              max: 100,
              message: "Region may not be greater than 100 characters",
              trigger: "blur",
            },
          ],
          country_id: [
            {
              required: true,
              message: "Country is required",
              trigger: "change",
            },
          ],
        },
        is_russian_self_declared: [
          { validator: validateAgreement, required: true },
        ],
      },
      titles: [
        { id: 1, label: "Mr" },
        { id: 2, label: "Mrs" },
        { id: 3, label: "Ms" },
        { id: 4, label: "Miss" },
        { id: 6, label: "Dr" },
        { id: 7, label: "Prof" },
        { id: 8, label: "Sir" },
        { id: 9, label: "Dame" },
        { id: 10, label: "Reverend" },
        { id: 11, label: "Duke" },
        { id: 12, label: "Duchess" },
        { id: 5, label: "Other" },
        { id: 13, label: "Mx" },
      ],
      addressesLoading: false,
      disableAddressLookup: false,
      selectedAvailableMailingAddress: null,
      availableMailingAddresses: [],
      russianSelfDeclarations: [
        {
          key: "ask_invertment_with",
          text: "I also understand that as part of my registration Scottish Enterprise may ask about the nature of trading and/or investment links with Russia and Belarus.",
        },
        {
          key: "no_subject_to_uk_sanctions",
          text: "I confirm, on behalf of my organisation/company, and to comply with UK government sanctions legislation that we are not subject to UK sanctions and to comply with Scottish Government guidance that there are no links or trade between the company/organisation in Scotland and Russia or Belarus.",
        },
      ],
    };
  },
  created() {
    this.getRegisterMeta();

    if (store.state.user.expertProfile) {
      this.getStatesFromCountry(
        store.state.user.expertProfile.country.id,
        false
      );
    }
  },
  methods: {
    // Start of upload / cropper methods
    removeSelectedAvatar() {
      this.removeAvatarLoader = true;

      gsApi.member.user
        .removeAvatar()
        .then((response) => {
          this.removeAvatarLoader = false;

          this.currentAvatar = response.data.avatar;
          this.customAvatar = response.data.has_custom_avatar;

          store.commit("USER_AVATAR_CHANGE", response.data);
          this.$alert(response.data.message, "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .catch((error) => {
          this.removeAvatarLoader = false;
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    onFileChange(file) {
      if (this.validateAvatarUpload(file)) {
        this.cropperFile = file;
        this.showCropperDialog = true;
      }
    },
    onCropperUpload(croppedFileData) {
      this.onAvatarUpload(croppedFileData.file);
    },
    onAvatarUpload(file) {
      const formData = new FormData();

      formData.append("avatar", file);

      this.showCropperDialog = false;
      this.cropperFile = null;

      this.percentage = 1;
      this.avatarLoader = true;
      let interval = 0;
      gsApi.member.user
        .updateAvatar(formData, (progress) => {
          interval = setInterval(() => {
            if (this.percentage < helpers.randomNumber(94, 99)) {
              this.percentage += 1;
            }
          }, 15);
        })
        .then((response) => {
          this.currentAvatar = response.data.avatar;
          this.customAvatar = response.data.has_custom_avatar;

          store.commit("USER_AVATAR_CHANGE", response.data);
          this.$alert(response.data.message, "Success", {
            confirmButtonText: "Close",
            showClose: false,
            customClass: "notification-modal",
          });
        })
        .catch((error) => {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        })
        .finally(() => {
          this.avatarLoader = false;
          clearInterval(interval);
        });
    },
    onCropperDialogClose() {
      this.showCropperDialog = false;
      this.cropperFile = null;
    },
    validateAvatarUpload(file) {
      let type = file.raw && file.raw.type ? file.raw.type : file.type;
      let size = file.raw && file.raw.size ? file.raw.size : file.size;

      const isJPG = type === "image/jpeg";
      const isPNG = type === "image/png";
      const isLt6M = size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG) {
        this.$alert("Avatar must be in a jpeg or png format.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      } else if (!isLt6M) {
        this.$alert("Avatar size can not exceed 6MB.", "Error", {
          confirmButtonText: "Close",
          showClose: false,
          customClass: "notification-modal",
        });
      }

      return (isJPG || isPNG) && isLt6M;
    },
    findAddresses(value, containerId = "") {
      this.addressesLoading = true;
      loqateApi
        .find(value, containerId, "")
        .then((response) => {
          this.addressesLoading = false;

          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0
          ) {
            if (response.data.Items[0].Error) {
              this.$alert(
                "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
                "No addresses found",
                {
                  confirmButtonText: "Close",
                  showClose: false,
                  customClass: "notification-modal",
                }
              );
            } else if (response.data.Items[0].Type != "Address") {
              this.findAddresses(value, response.data.Items[0].Id);
            } else if (!response.data.Items[0].Error) {
              this.form.address.selectedAvailableMailingAddress = null;
              this.form.address.address_1 = "";
              this.form.address.address_2 = "";
              this.form.address.town = "";
              this.form.address.county = "";
              this.form.address.country_id = null;

              this.disableAddressLookup = true;
              this.availableMailingAddresses = response.data.Items;
            }
          } else {
            this.availableMailingAddresses = [];
            this.$alert(
              "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
              "No addresses found",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.addressesLoading = false;
          this.availableMailingAddresses = [];
          this.$alert(
            "Failed to find any addresses using postcode provided. Please enter mailing address details instead.",
            "No addresses found",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    onAvailableMailingAddressSelected(address) {
      this.retrieveAddress(address.Id);
    },
    onFindAddress(e) {
      e.preventDefault();
      this.findAddresses(this.form.address.post_code);
    },
    retrieveAddress(id) {
      loqateApi
        .retrieve(id)
        .then((response) => {
          if (
            response.data &&
            response.data.Items &&
            response.data.Items.length > 0 &&
            !response.data.Items[0].Error
          ) {
            this.disableAddressLookup = true;

            let address = response.data.Items[0];
            this.form.address.address_1 = address.Line1;
            this.form.address.address_2 = address.Line2;
            this.form.address.post_code = address.PostalCode;
            this.form.address.town = address.City;
            this.form.address.county = address.AdminAreaName;

            let country = this.countries.find(
              (c) =>
                c.code == address.CountryIso2 || c.code == address.CountryIso3
            );
            if (country) {
              this.form.address.country_id = country.id;
            }
          } else {
            this.$alert(
              "Failed to automatically use the selected address. Please enter mailing address details instead.",
              "Error",
              {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              }
            );
          }
        })
        .catch((error) => {
          this.$alert(
            "Failed to automatically use the selected address. Please enter mailing address details instead.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    addEducation(e) {
      e.preventDefault();

      if (!this.form.educations_text) return;

      this.form.educations.push({
        name: this.form.educations_text,
      });

      this.form.educations_text = "";
    },
    removeEducation(index) {
      this.form.educations = this.form.educations.filter(
        (item, i) => i != index
      );
    },
    openExampleModal() {
      const h = this.$createElement;

      this.$msgbox({
        message: h("p", null, [
          h(
            "h2",
            null,
            "Here’s an example of the type and detail of information we’d expect to see in this section:"
          ),
          h(
            "p",
            null,
            "Experienced Marketing Director for both corporate and start-up enterprises. I’m an expert in business communications, operational management, editorial direction and product/process design, however my specialities lie in creative direction and transformational change. I have worked in several countries across Singapore, Malaysia and France."
          ),
          h(
            "p",
            null,
            "As Chief Marketing Officer and Head of Communications at KM, I created the product-led marketing approach that scaled the company from £10m to £50m in just under 2 years and have been an integral part of the management team since 2014. I am directly responsible for our team of marketers, developing and executing all marketing communications activity across our global portfolio of clients. We work with both B2B and B2C clients across several sectors, however with a focus on fashion, retail, creative and technology industries."
          ),
          h(
            "p",
            null,
            "Prior to KM, I served as the Chief Marketing Officer for a fast-growing start-up based in Paris where I lead a small team, delivering bespoke marketing solutions to brand names such as A, B and C."
          ),
          h(
            "p",
            null,
            "I also serve as an Advisor and Mentor to the Chartered Institute of Marketing. I currently serve on the board of LM, a company that specialises in communication technology."
          ),
        ]),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
        cancelButtonClass: "cancel-btn",
        customClass: "experience-example-modal",
      }).catch(() => {});
    },
    openExampleSummaryModal() {
      const h = this.$createElement;

      this.$msgbox({
        message: h("p", null, [
          h(
            "h2",
            null,
            "Here’s an example of the type and detail of information we’d expect to see in this section:"
          ),
          h(
            "p",
            null,
            "Digital innovator for the marine and tech sectors, with experience of investor pitching and moving a Scottish business to the USA."
          ),
        ]),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
        cancelButtonClass: "cancel-btn",
        customClass: "experience-example-modal",
      }).catch(() => {});
    },
    getRegisterMeta() {
      gsApi.globalscot.meta
        .index()
        .then((response) => {
          if (store.state.user.expertProfile.nationality) {
            this.form.nationality_id =
              store.state.user.expertProfile.nationality.id;
          }
          if (store.state.user.expertProfile.country) {
            this.form.country_id = store.state.user.expertProfile.country.id;
          }
          if (store.state.user.expertProfile.state) {
            this.form.state_id = store.state.user.expertProfile.state.id;
          }
          if (store.state.user.is_russian_self_declared) {
            this.form.is_russian_self_declared = [
              "ask_invertment_with",
              "no_subject_to_uk_sanctions",
            ];
          }
          this.nationalities = response.data.nationalities.data;
          this.countries = response.data.countries.data;
        })
        .catch(() => {
          this.$alert(
            "An unknown error occurred. Please try again later.",
            "Error",
            {
              confirmButtonText: "Close",
              showClose: false,
              customClass: "notification-modal",
            }
          );
        });
    },
    submitGsForm() {
      let self = this;
      this.$refs["submitForm"].validate((valid) => {
        if (valid) {
          const formData = new FormData();

          formData.append("title", this.form.title);
          formData.append(
            "first_name",
            this.$options.filters.domPurify(this.form.first_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append(
            "last_name",
            this.$options.filters.domPurify(this.form.last_name, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append("contact_number", this.form.contact_number);
          formData.append("nationality_id", this.form.nationality_id);
          formData.append("country_id", this.form.country_id);
          formData.append("state_id", this.form.state_id);
          formData.append(
            "experience",
            this.$options.filters.domPurify(this.form.experience, {
              ALLOWED_TAGS: ["p", "u", "i", "li", "ul", "ol", "a", "strong"],
            })
          );
          formData.append(
            "profile_summary",
            this.$options.filters.domPurify(this.form.profile_summary, {
              ALLOWED_TAGS: [],
            })
          );
          formData.append("address_1", this.form.address.address_1);
          formData.append("address_2", this.form.address.address_2);
          formData.append("post_code", this.form.address.post_code);
          formData.append("town", this.form.address.town);
          formData.append("county", this.form.address.county);
          formData.append("mailing_country_id", this.form.address.country_id);

          formData.append(`educations`, []);
          this.form.educations.forEach((education, index) => {
            formData.append(`educations[${index}][name]`, education.name);
          });

          this.form.is_russian_self_declared.forEach((item) => {
            formData.append("is_russian_self_declared[]", item);
          });

          this.mainLoader = true;

          gsApi.globalscot.user
            .updatePersonalInfo(formData)
            .then((response) => {
              self.$store.commit("UPDATE_GS_PERSONAL_DETAILS", response.data);
              this.mainLoader = false;
              this.validationErrors = [];
              this.$alert("You successfully updated your profile", "Success", {
                confirmButtonText: "Close",
                showClose: false,
                customClass: "notification-modal",
              });
            })
            .catch((error) => {
              this.validationErrors = [];
              this.mainLoader = false;

              if (
                error.response &&
                error.response.status == 422 &&
                error.response.data
              ) {
                let errors = error.response.data.errors;
                for (let [key, value] of Object.entries(errors)) {
                  let obj = {};
                  obj.id = key;
                  obj.message = value[0];
                  this.validationErrors.push(obj);
                }
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                this.$alert(
                  "There are errors in the form, please fix these errors and try again.",
                  "Error",
                  {
                    confirmButtonText: "Close",
                    showClose: false,
                    customClass: "notification-modal",
                  }
                );
              }
            });
        } else {
          this.validationErrors = [];
          this.$refs.submitForm.fields.forEach((field) => {
            if (field.validateState == "error") {
              let obj = {};
              obj.id = field.labelFor;
              obj.message = field.validateMessage;
              this.validationErrors.push(obj);
            }
          });
          this.$nextTick(() => {
            document
              .getElementById("formErrorBlock")
              .scrollIntoView({ behavior: "smooth", block: "start" });
            let text = document
              .getElementById("submitForm")
              .getElementsByClassName("el-form-item__error")[0].textContent;
            this.$announcer.set(text);
          });
        }
      });
    },
    getStatesFromCountry(countryId, eraseStateId = true) {
      if (eraseStateId) {
        this.form.state_id = null;
      }

      this.mainLoader = true;

      gsApi.state.index(countryId).then((response) => {
        this.mainLoader = false;
        this.states = response.data.data;
      });
    },
    handleSubmitFormFieldInput() {
      this.handleSingleFormFieldInput("submitForm");
    },
  },
};
</script>
