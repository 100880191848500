<template>
  <div class="show-more-list-wrap">
    <ul>
      <li :key="index" v-for="(item, index) in visibleItems">
        <slot :item="item" />
      </li>
    </ul>
    <a
      href="#"
      class="show-more-link"
      @click.prevent="showMoreLess"
      v-if="exceededLimit"
    >
      {{ showMore ? "Show less" : "Show more" }}
    </a>
  </div>
</template>

<script>
export default {
  name: "list-show-more-component",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 5,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showMore: false,
    };
  },

  computed: {
    exceededLimit() {
      return this.items.length > this.limit;
    },

    visibleItems() {
      if (!this.exceededLimit) {
        return this.items;
      }

      return this.showMore ? this.items : this.items.slice(0, this.limit);
    },
  },

  methods: {
    showMoreLess() {
      if (!this.showModal) {
        this.showMore = !this.showMore;
        return;
      }
      const h = this.$createElement;
      let arrayElemet = [];
 
      for (let item of this.items) {
        const text = item.label ? item.label : (item.role ? `${item.name} | ${item.role}` : item.name)

        arrayElemet.push(h("li", [h('span', null, text)]));
      }

      this.$msgbox({
        message: h("p", null, [
        h("h2", null, this.title ?? null),
        h('div', [
        h('ul', arrayElemet)
        ])
        ]),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
        cancelButtonClass: "cancel-btn",
        customClass: "show-more-items-modal",
      }).catch(() => {});
    },
  },
};
</script>
